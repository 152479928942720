import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

/**
 * Import hooks.
 */
import { useScreenWidth, useBasketContext } from "../../../hooks";

/**
 * Import components.
 */
import { Accordion, Table, Quantity } from "../../core";

/**
 * Interface.
 */
import { IProductsAccordion } from "../types";
import { Item } from "../../../models/item";
import { useState } from "react";
interface ITableRow {
  title?: string;
  name?: string;
  description?: string | null;
  price: React.ReactNode;
  quantity: React.ReactNode;
}

interface IProductPriceInput {
  sku: string;
  originalPrice: number;
  onPriceOverride: (sku: string, price: number) => void;
}

const roundPrice = (price: number): number => {
  return Math.round(price * 100) / 100;
};

const ProductPriceInput = ({
  sku,
  originalPrice,
  onPriceOverride,
}: IProductPriceInput): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = useState<string | undefined>(
    originalPrice.toString(),
  );
  const defaultClasses = "bg-transparent text-primary font-bold";
  const [inputClasses, setInputClasses] = useState(defaultClasses);
  const floatVal = parseFloat(price || "0");

  const handleProductPriceChange = (value: string) => {
    setPrice((parseFloat(value) || 0).toString());
    if (value.charAt(value.length - 1) === "." && !price?.includes("."))
      setPrice(value);
  };

  const handleBlur = () => {
    if (floatVal !== originalPrice) onPriceOverride(sku, floatVal);
    setInputClasses(defaultClasses);
  };

  return (
    <span className="font-bold text-primary inline-flex item-center">
      £
      <input
        type="text"
        className={cx("inline-block px-1", inputClasses)}
        ref={inputRef}
        value={price ? price : ""}
        onFocus={() => {
          setInputClasses("text-black");
        }}
        onChange={(e) => handleProductPriceChange(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setPrice(roundPrice(floatVal || 0).toString());
            if (inputRef.current) inputRef.current.blur();
          }
        }}
      />
    </span>
  );
};

/**
 * Accordion of product options.
 */
export const ProductsAccordion = ({
  id,
  title,
  image,
  products,
}: IProductsAccordion): React.ReactElement => {
  const { isMobile } = useScreenWidth();

  /**
   * i18n translation.
   */
  const [t] = useTranslation("jobs");

  /**
   * Basket context.
   */
  const { addItem, deleteItem, getItem, updateItemPrice } = useBasketContext();

  const [priceOverrides, setPriceOverrides] = useState<{
    [sku: string]: number;
  }>({});

  /**
   * Handle qty change
   */
  const handleQtyChange = (product: Item, qty: number) => {
    if (!qty) deleteItem(product.sku);
    addItem({
      title: product.title,
      sku: product.sku,
      quantity: qty,
      price:
        priceOverrides[product.sku as keyof typeof priceOverrides] ||
        product.price ||
        0,
    });
  };

  const handlePriceOverride = (sku: string, newPrice: number) => {
    const copy = priceOverrides;
    copy[sku] = newPrice;
    setPriceOverrides(copy);
    // also amend price of existing items in basket
    updateItemPrice(sku, newPrice);
  };

  const tableRows: ITableRow[] = products.map((product) => {
    return {
      quantity: (
        <Quantity
          start={product.quantity || 0}
          updateQuantity={(qty) => handleQtyChange(product, qty)}
        />
      ),
      title: product.title || "",
      description: product.description,
      price: (
        <ProductPriceInput
          originalPrice={getItem(product.sku)?.price || product.price || 0}
          sku={product.sku}
          onPriceOverride={handlePriceOverride}
        />
      ),
    };
  });

  return (
    <div key={`accordion-${id}`} className="max-w-screen-sm md:px-16 mx-auto">
      <Accordion title={title} icon={image ? image : null} isOpen={true}>
        <div className="py-6">
          <Table
            tableClasses="table-fixed"
            columns={[
              {
                name: t(
                  "create.steps.selectProductOptions.tableTitles.quantity",
                ),
                width: !isMobile ? "120px" : "",
              },
              {
                name: t("create.steps.selectProductOptions.tableTitles.option"),
                width: !isMobile ? "120px" : "",
              },
              {
                name: t(
                  "create.steps.selectProductOptions.tableTitles.description",
                ),
                truncate: true,
              },
              {
                name: t("create.steps.selectProductOptions.tableTitles.price"),
                width: !isMobile ? "150px" : "",
              },
            ]}
            rows={tableRows}
            alternate={{
              enable: true,
            }}
          />
        </div>
      </Accordion>
    </div>
  );
};
