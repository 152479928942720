import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

/**
 * Import api.
 */
import { createBasket, processBasketForInvoiceCustomer } from "../../../../api";

/**
 * Import components.
 */
import {
  ShowSelectedAddress,
  ProductGroup,
  CustomerNoEmail,
} from "../components";
import {
  FormInput,
  FormTextarea,
  FormButton,
  DatePicker,
  LoadingSpinner,
} from "../../../core";
import { SuccessModal } from "../../../common/components";

/**
 * Import hooks.
 */
import {
  useBasketContext,
  useAuthContext,
  useCatalogueContext,
  useMultiFormContext,
} from "../../../../hooks";

/**
 * Import validation schema and initial values.
 */
import {
  weeeCollectionDetailsSchema,
  weeeCollectionDetailsInitialValues,
} from "../validation";

/**
 * Import types.
 */
import { IWEEEFormState, IWEEECollectionDetailsStep } from "../types";

export const CollectionDetails: React.FC = () => {
  /**
   * Translations.
   */
  const [t] = useTranslation("jobs");

  /**
   * Get current user company.
   */
  const { getCurrentUserCurrentCompany } = useAuthContext();
  const { companyCode } = getCurrentUserCurrentCompany() ?? {};

  /**
   * Basket context.
   */
  const { hasItems, items } = useBasketContext();

  /**
   * Multi form state.
   */
  const { formState } = useMultiFormContext<IWEEEFormState>();
  const { boroughAvailability, selectedAddress } = formState;

  /**
   * State.
   */
  const [success, setSuccess] = useState(false);

  /**
   * Get WEEE Kerbside items from catalogue.
   */
  const { catalogueItems, catalogueId } = useCatalogueContext();
  const weeeCatalogue = catalogueItems?.find(
    (item) => item.sku === "WEEEKERBSIDE",
  );

  /**
   * Handle submit.
   */
  const handleSubmit = async (values: IWEEECollectionDetailsStep) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      collectionDate,
      description,
    } = values;

    // Create a basket
    const basket = await createBasket({
      catalogueId,
      items: items.map((item) => {
        return { sku: item.sku, qty: item.quantity };
      }),
      companyCode,
      deliveryAddress: {
        line1: selectedAddress.line1,
        line2: selectedAddress.line2 || "",
        city: selectedAddress.city,
        county: selectedAddress.county || "",
        postcode: selectedAddress.postcode,
      },
      date: new Date(collectionDate).toISOString(),
      contact: {
        firstName,
        lastName,
        phoneNumber,
        email: email || undefined,
      },
      description,
    });

    // invoice basket
    const { basketToken, orderRef } = basket;
    await processBasketForInvoiceCustomer(basketToken, orderRef);

    // success
    setSuccess(true);
  };

  return (
    <div className="max-w-screen-xl px-4 py-8 lg:px-16 ml-auto mr-auto">
      <Formik
        initialValues={weeeCollectionDetailsInitialValues}
        validationSchema={weeeCollectionDetailsSchema}
        onSubmit={async (values) => await handleSubmit(values)}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          dirty,
          isValid,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <ShowSelectedAddress />
            <div className="lg:flex flex-wrap -mx-6">
              <div className="w-full pb-12 xl:w-1/4 px-6">
                <h3 className="text-primary font-semibold mb-6">
                  {t("weeeKerbside.titles.collectionDate")}
                </h3>
                <div className="h-64 mb-8 lg:mb-0 lg:h-auto">
                  <DatePicker
                    className="DayPickerInput-Overlay w-full flex justify-center items-center relative z-50"
                    numberOfMonths={1}
                    isRanged={false}
                    disabledDays={(day) =>
                      !boroughAvailability.includes(
                        dayjs(day).format("YYYY-MM-DD"),
                      )
                    }
                    onValueChange={(newDate) => {
                      setFieldValue(
                        "collectionDate",
                        dayjs(newDate.from).format("YYYY-MM-DD"),
                      );
                    }}
                  />
                </div>
              </div>
              <div className="w-full xl:w-1/2 px-6 mb-6">
                <h3 className="text-primary font-semibold mb-6">
                  {t("weeeKerbside.titles.electricalItemDetails")}
                </h3>
                <div className="flex flex-wrap flex-col sm:flex-row items-start mb-8 md:-mx-10">
                  {weeeCatalogue &&
                    weeeCatalogue.children &&
                    weeeCatalogue.children.map((group: any, index) => (
                      <div
                        className="flex w-full mb-6 md:mb-0 md:w-1/2 px-2 py-2 lg:py-4 xl:px-6"
                        key={index}
                      >
                        <ProductGroup group={group} limit={5} />
                      </div>
                    ))}
                </div>
                <div>
                  <FormTextarea
                    name="description"
                    classes="bg-gray-200 border-gray-500"
                    placeholder={t(
                      "weeeKerbside.form.placeholders.description",
                    )}
                    label={{
                      text: t("weeeKerbside.form.labels.description"),
                    }}
                    floatingLabel={false}
                    validate={true}
                    maxLength={499}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 xl:w-1/4 px-6">
                <h3 className="text-primary font-semibold mb-6">
                  {t("weeeKerbside.titles.contactDetails")}
                </h3>
                <div className="mb-4">
                  <FormInput
                    name="firstName"
                    classes="bg-gray-200 border-gray-500"
                    placeholder={t("weeeKerbside.form.placeholders.firstName")}
                    label={{
                      text: t("weeeKerbside.form.labels.firstName"),
                      classes: "bg-gray-200",
                    }}
                    error={{
                      classes: "bg-gray-200",
                    }}
                    floatingLabel={false}
                    validate={true}
                  />
                </div>
                <div className="mb-4">
                  <FormInput
                    name="lastName"
                    classes="bg-gray-200 border-gray-500"
                    placeholder={t("weeeKerbside.form.placeholders.lastName")}
                    label={{
                      text: t("weeeKerbside.form.labels.lastName"),
                      classes: "bg-gray-200",
                    }}
                    error={{
                      classes: "bg-gray-200",
                    }}
                    floatingLabel={false}
                    validate={true}
                  />
                </div>
                <div className="mb-4">
                  <FormInput
                    name="phoneNumber"
                    classes="bg-gray-200 border-gray-500"
                    placeholder={t(
                      "weeeKerbside.form.placeholders.phoneNumber",
                    )}
                    label={{
                      text: t("weeeKerbside.form.labels.phoneNumber"),
                      classes: "bg-gray-200",
                    }}
                    error={{
                      classes: "bg-gray-200",
                    }}
                    floatingLabel={false}
                    validate={true}
                  />
                </div>
                <div className="mb-4">
                  <CustomerNoEmail
                    name="customerNoEmail"
                    label={t("weeeKerbside.form.labels.customerNoEmail")}
                  />
                </div>
                {values.customerNoEmail === false && (
                  <div>
                    <FormInput
                      name="email"
                      classes="bg-gray-200 border-gray-500"
                      placeholder={t("weeeKerbside.form.placeholders.email")}
                      label={{
                        text: t("weeeKerbside.form.labels.email"),
                        classes: "bg-gray-200",
                      }}
                      error={{
                        classes: "bg-gray-200",
                      }}
                      floatingLabel={false}
                      validate={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full px-6 mt-6 text-right">
              <FormButton
                name="submit"
                type="submit"
                text={t("weeeKerbside.buttons.submit")}
                className="btn btn-icon w-32"
                variant="secondary"
                disabled={!dirty || !isValid || !hasItems || isSubmitting}
                onClick={handleSubmit}
              />
            </div>
            {/* Spinner */}
            {isSubmitting && (
              <div className="fixed w-full h-full bg-gray-600 z-50 top-0 left-0 bg-opacity-50">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                  <LoadingSpinner
                    classes={{
                      circle: "text-white opacity-100",
                      bee: "text-white",
                      text: "text-white opacity-100",
                    }}
                    isLoading={isSubmitting}
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
      <SuccessModal
        title={t("modal.headings.weeeCreateSuccess")}
        buttonOne={{
          label: t("modal.buttons.labels.backToDashboard"),
          to: "/",
        }}
        visible={success}
      />
    </div>
  );
};
