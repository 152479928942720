import React, { useRef, useState, useCallback } from "react";
import ReactDOM from "react-dom";

/**
 * Hook return object interface.
 */
interface IUsePortalReturn {
  Portal: React.FC<IPortal>;
  closePortal: () => void;
  openPortal: () => void;
  togglePortal: () => void;
  isOpen: boolean;
}

interface IPortal {
  children?: React.ReactNode;
  className?: string;
  element?: keyof JSX.IntrinsicElements | "fragment";
}

/**
 * Hook configuration object interface.
 */
interface IUsePortalConfig {
  bindTo?: HTMLElement;
}

/**
 * Hook type.
 */
type TUsePortal = (config?: IUsePortalConfig) => IUsePortalReturn;

/**
 * usePortal hook.
 *
 * Create react portals on the fly.
 */
export const usePortal: TUsePortal = (config = {}) => {
  /**
   * Configuration.
   */
  const { bindTo } = config;

  /**
   * State.
   */
  const [isOpen, setIsOpen] = useState(true);

  /**
   * Portal element bind reference.
   */
  const elToBind = useRef(bindTo || document.body);

  /**
   * Close opened portal.
   */
  const closePortal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  /**
   * Open portal.
   */
  const openPortal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  /**
   * Open portal.
   */
  const togglePortal = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  /**
   * Portal component that is rendered on the DOM.
   */
  const Portal = useCallback(({ children, className, element }: IPortal) => {
    if (element === "fragment") {
      return ReactDOM.createPortal(<>{children}</>, elToBind.current);
    }

    return ReactDOM.createPortal(
      React.createElement(element || "div", { className }, children),
      elToBind.current,
    );
  }, []);

  return {
    Portal,
    closePortal,
    openPortal,
    togglePortal,
    isOpen,
  };
};
