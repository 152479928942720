import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
  getCatalogueOrderTypes,
  createOrderType,
  patchOrderType,
} from "../../../api/catalogues";
import { useTranslation } from "react-i18next";
import { toasts } from "helpers/toasts";
import {
  Button,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  Field,
  Table,
  theme,
} from "@clearabee/ui-library";
import { ErrorMessage } from "components/common/components";
import {
  createOrderTypeIDInitialValues,
  createOrderTypeIDValidation,
} from "./validation";
import type { IOrderType } from "../../../api/catalogues";
import { FormikHelpers } from "formik";

export const OrderTypeIDs = (): React.ReactElement => {
  const [orderType, setOrderType] = useState<IOrderType>(
    createOrderTypeIDInitialValues,
  );
  const [editing, setEditing] = useState<boolean>(false);
  const [translate] = useTranslation("catalogues");

  // CREATE
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    (values: Omit<IOrderType, "id" | "createdOn" | "allowPriceOverride">) => {
      const { resourceId, ...rest } = values;

      const updatedValues = {
        ...rest,
        ...(!!resourceId && Number(resourceId) !== 0
          ? {
              resourceId,
            }
          : {}),
      };

      return createOrderType(updatedValues);
    },
    {
      onError: () => {
        toasts.error({
          message: translate("orderType.postError"),
        });
      },
      onSuccess: () => {
        toasts.success({
          message: translate("orderType.postSuccess"),
        });
      },
    },
  );

  // READ
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["readOrderTypeIDs"],
    () => getCatalogueOrderTypes(),
    {
      retry: 0,
      onError: () => {
        toasts.error({
          message: translate("orderType.getError"),
        });
      },
    },
  );

  // UPDATE
  const { mutateAsync: patchAsync, isLoading: isPatchLoading } = useMutation(
    (payload: IOrderType) => {
      const { id, ...patch } = payload;

      return patchOrderType(id, patch);
    },
    {
      onError: () => {
        toasts.error({
          message: translate("orderType.patchError"),
        });
      },
      onSuccess: () => {
        toasts.success({
          message: translate("orderType.patchSuccess"),
        });
      },
    },
  );

  const handleFormSubmit = async (
    values: IOrderType,
    { resetForm }: FormikHelpers<IOrderType>,
  ) => {
    if (editing) {
      await patchAsync({ ...values, id: orderType.id });
    } else {
      await mutateAsync(values);
    }

    setEditing(false);
    setOrderType(createOrderTypeIDInitialValues);
    refetch();
    resetForm();
  };

  if (error) return <ErrorMessage />;

  return (
    <div className="max-w-screen-lg mx-auto py-10 relative">
      <Panel
        styles={{
          paddingTop: theme.spacing.medium,
          paddingBottom: theme.spacing.medium,
        }}
      >
        <Heading fontSize="large" color="brand">
          {editing
            ? translate("orderType.editOrderType")
            : translate("orderType.addNewOrderType")}
        </Heading>
        <div className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />

        <Form
          initialValues={createOrderTypeIDInitialValues}
          validationSchema={createOrderTypeIDValidation}
          onSubmit={handleFormSubmit}
        >
          {({ errors, isValid, setFieldValue, setFieldTouched }) => {
            useEffect(() => {
              setFieldValue("name", orderType.name);
              setFieldTouched("name", false);

              setFieldValue("parentRef", orderType.parentRef);
              setFieldValue("bcJobType", orderType.bcJobType);
              setFieldValue("resourceId", orderType.resourceId);
            }, [orderType]);

            return (
              <div className="flex flex-col lg:flex-row gap-x-6">
                <Field
                  styles={{ flex: 1 }}
                  name="name"
                  label={translate("orderType.placeholders.name")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("orderType.placeholders.name")}
                    />
                  )}
                </Field>

                <Field
                  styles={{ flex: 1 }}
                  name="parentRef"
                  label={translate("orderType.placeholders.parentRef")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "orderType.placeholders.parentRef",
                      )}
                    />
                  )}
                </Field>

                <Field
                  styles={{ flex: 1 }}
                  name="bcJobType"
                  label={translate("orderType.placeholders.bigChangeJobType")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "orderType.placeholders.bigChangeJobType",
                      )}
                    />
                  )}
                </Field>

                <Field
                  styles={{ flex: 1 }}
                  name="resourceId"
                  label={translate("orderType.placeholders.resourceId")}
                >
                  {({ field }) => (
                    <Input.Text
                      type="number"
                      min="0"
                      {...field}
                      placeholder={translate(
                        "orderType.placeholders.resourceId",
                      )}
                    />
                  )}
                </Field>
                <div className="flex items-center gap-2">
                  {editing && (
                    <Button
                      color={"negative"}
                      type="submit"
                      size="small"
                      onClick={() => {
                        setOrderType(createOrderTypeIDInitialValues);
                        setEditing(false);
                      }}
                    >
                      {translate("orderType.cancel")}
                    </Button>
                  )}

                  <Button
                    color="accent"
                    type="submit"
                    size="small"
                    disabled={
                      Object.keys(errors).length > 0 ||
                      isMutationLoading ||
                      isPatchLoading ||
                      !isValid
                    }
                  >
                    {editing
                      ? translate("orderType.update")
                      : translate("orderType.add")}
                  </Button>
                </div>
              </div>
            );
          }}
        </Form>
      </Panel>
      <div className="relative flex flex-1 min-h-96">
        {isLoading || isFetching ? (
          <Icon.Loading
            color="brand"
            styles={{ margin: `${theme.spacing.xlarge} auto` }}
          />
        ) : (
          <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <Table.Header
              headings={[
                translate("orderType.headings.id"),
                translate("orderType.headings.name"),
                translate("orderType.headings.parentRef"),
                translate("orderType.headings.bcJobType"),
                translate("orderType.headings.resourceId"),
                translate("orderType.headings.priceOverride"),
                translate("table.headings.action"),
              ]}
            />
            <Table.Body>
              {data?.items.map((item, index) => (
                <Table.Row key={`table-row-${item.id}`}>
                  <Table.Cell.Text className="truncate">
                    {item.id}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.name}
                  </Table.Cell.Text>
                  <Table.Cell.Text>{item.parentRef ?? ""}</Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.bcJobType ?? ""}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {item.resourceId ?? ""}
                  </Table.Cell.Text>
                  <Table.Cell>
                    {item.allowPriceOverride ? (
                      <Icon.Tick size="small" color="accent.darker" />
                    ) : (
                      <Icon.Close size="small" color="negative" />
                    )}
                  </Table.Cell>
                  {/* Edit order-type */}
                  <Table.Cell>
                    <Button
                      size="xsmall"
                      as="button"
                      className="inline-block text-center"
                      onClick={() => {
                        window.scrollTo(0, 0);

                        setEditing(true);
                        setOrderType({
                          ...createOrderTypeIDInitialValues,
                          ...data?.items[index],
                        });
                      }}
                    >
                      {translate("table.actions.edit")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};
