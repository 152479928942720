"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRgba = void 0;
var hexToRgba = function (hex, alpha) {
    if (alpha === void 0) { alpha = 1; }
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
};
exports.hexToRgba = hexToRgba;
