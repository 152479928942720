/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useReducer,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";

/**
 * Import types.
 */
import { IMultiFormState, IMultiFormStateMethods, IMultiForm } from "./types";

/**
 * Initial variable state.
 */
const initState: IMultiFormState<any> = {
  activeStep: 0,
  formState: {},
  isLoading: false,
  steps: [],
};

/**
 * Initial method state.
 */
const initMethods: IMultiFormStateMethods<any> = {
  nextStep: () => {},
  previousStep: () => {},
  pushState: () => {},
  setLoading: () => {},
  reset: () => {},
  setStep: () => {},
};

/**
 * Multi form context.
 */
export const multiFormContext = createContext({
  ...initState,
  ...initMethods,
});

/**
 * Export out providers and consumers.
 */
export const { Provider: MultiFormProvider, Consumer: MultiFormConsumer } =
  multiFormContext;

/**
 * Provider state reducer.
 */
const reducer = (
  state: IMultiFormState<any>,
  nextState: Partial<IMultiFormState<any>>,
) => ({ ...state, ...nextState });

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const MultiForm: React.FC<IMultiForm<any>> = ({
  steps,
  initialValues,
  scrollToTop = true,
  onPushState,
  onStepChange,
  wrapperComponent: Wrapper,
  isLoading: propIsLoading,
}) => {
  const initValuesRef = useRef(initialValues);

  /**
   * Data state reducer.
   */
  const [state, dispatch] = useReducer(reducer, {
    ...initState,
    formState: initValuesRef.current,
  });

  /**
   * Master form loading state.
   */
  const [isLoading, setLoading] = useState(propIsLoading || false);

  /**
   * Top form div reference.
   */
  const topFormRef = useRef<HTMLDivElement>(null);

  /**
   * -----------------------
   * Private methods.
   * -----------------------
   */

  /**
   * Scroll to top of form.
   */
  const scrollTop = () => {
    if (scrollToTop && topFormRef.current) {
      topFormRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  /**
   * -----------------------
   * Public methods.
   * -----------------------
   */

  /**
   * Proceed to next step.
   */
  const nextStep = () => {
    if (state.activeStep + 1 < steps.length) {
      scrollTop();
      dispatch({ activeStep: state.activeStep + 1 });
    }
  };

  /**
   * Go back to previous step.
   */
  const previousStep = () => {
    if (state.activeStep > 0) {
      scrollTop();
      dispatch({ activeStep: state.activeStep - 1 });
    }
  };

  /**
   * Reset state and steps.
   */
  const reset = (resetData = false) => {
    if (resetData) {
      dispatch({
        activeStep: 0,
        formState: initValuesRef.current,
      });
    } else {
      dispatch({
        activeStep: 0,
      });
    }
  };

  /**
   * Changes the active step.
   * @param step Step index.
   */
  const setStep = (step: number) => {
    dispatch({
      activeStep: step,
    });
  };

  /**
   * Push new form state.
   * @param newState New form state to push.
   */
  const pushState = (newState: any) => {
    dispatch({
      formState: {
        ...state.formState,
        ...newState,
      },
    });
  };

  /**
   * Memoized step component.
   */
  const Component = useMemo(() => {
    return steps[state.activeStep].component;
  }, [steps, state.activeStep]);

  /**
   * On push state effect.
   */
  useEffect(() => {
    if (onPushState) {
      onPushState(state.formState);
    }
  }, [state.formState]);

  /**
   * On step change effect.
   */
  useEffect(() => {
    if (onStepChange) {
      onStepChange(state.activeStep);
    }
  }, [state.activeStep]);

  /**
   * Set step loading state from prop.
   */
  useEffect(() => {
    if (typeof propIsLoading !== "undefined") {
      setLoading(propIsLoading);
    }
  }, [propIsLoading]);

  return (
    <MultiFormProvider
      value={{
        ...state,
        isLoading,
        steps,
        setLoading,
        nextStep,
        previousStep,
        pushState,
        reset,
        setStep,
      }}
    >
      <div className="multiform-top-form" ref={topFormRef} />
      {Wrapper ? (
        <Wrapper>
          <Component />
        </Wrapper>
      ) : (
        <Component />
      )}
    </MultiFormProvider>
  );
};
