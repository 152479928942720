import { createResponsiveStyles, theme } from "@clearabee/ui-library";

const mainContainer = createResponsiveStyles({
  base: {
    width: "fit-content",
    margin: "0 auto",
    backgroundColor: theme.colors.light.base,
    padding: theme.spacing.xsmall,
    borderRadius: theme.spacing.xsmall,
  },
});

const wrapper = createResponsiveStyles({
  base: {
    ...theme.fontDefaults.base,
    display: "block",
    width: "100%",
    margin: "0 auto",
    boxSizing: "border-box",
    textAlign: "center",
  },
  large: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing.small,
    padding: `${theme.spacing.xsmall2} ${theme.spacing.small}`,
    width: "560px",
  },
});

const carousel = createResponsiveStyles({
  base: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    marginBottom: "1px",
  },
  large: {
    marginBottom: 0,
    width: "60%",
  },
});

const stepButton = createResponsiveStyles({
  base: {
    ...theme.fontDefaults.small,
    width: theme.spacing.small,
    height: theme.spacing.small,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "100%",
    background: "none",
    cursor: "pointer",
    "&:disabled": {
      pointerEvents: "none",
      color: theme.colors.greyscale.lightest,
    },
  },
  large: {
    ...theme.fontDefaults.base,
    width: theme.spacing.medium,
    height: theme.spacing.medium,
  },
});

const rowPerPageWrap = createResponsiveStyles({
  base: {
    ...theme.fontDefaults.xsmall2,
    marginLeft: 0,
    marginBottom: "3px",
  },
  large: {
    ...theme.fontDefaults.xsmall,
    marginBottom: 0,
    width: "30%",
  },
});

const resultsDropdownWrap = createResponsiveStyles({
  base: {
    margin: "0 auto",
    width: "25%",
  },
  large: {
    width: "20%",
  },
});

export const paginationStyles = {
  mainContainer,
  wrapper,
  carousel,
  stepButton,
  rowPerPageWrap,
  resultsDropdownWrap,
};
