import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Heading,
  Panel,
  InputText,
  Text,
  Button,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { Results } from "./components/results";
import { getQueriedResults } from "../../../helpers";
import { RouteLink } from "components/core";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";

export const ReadCatalogues = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [search, setSearch] = useState("");
  const [results, setResults] =
    useState<
      ApiResponseData<typeof instance.catalogues.getCatalogues>["items"]
    >();

  const {
    data: initialData,
    isLoading,
    error,
  } = useQuery(
    ["readCatalogues"],
    async () =>
      (await instance.catalogues.getCatalogues({ params: { limit: 2000 } }))
        .data,
    {
      retry: 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    setResults(
      getQueriedResults(initialData?.items, search, ["title", "description"]),
    );
  }, [search]);

  return (
    <div className="max-w-screen-lg mx-auto py-10">
      <Panel styles={{ marginBottom: theme.spacing.large }}>
        <div className="flex flex-col sm:flex-row items-center justify-between border-b border-grey-200 pb-4 mb-3">
          <Heading level={1} fontSize="large" color="brand">
            {translate("filters.cataloguesHeading")}
          </Heading>
          <RouteLink href="/catalogues/create">
            <Button
              size="small"
              as="a"
              color="brand"
              className="inline-block mt-3 sm:mt-0"
            >
              {translate("filters.createNewCatalogueButton")}
            </Button>
          </RouteLink>
        </div>
        <div className="flex flex-wrap sm:flex-no-wrap items-center pt-5 relative">
          <InputText
            placeholder={translate("filters.catalogueSearchPlaceholder")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Icon.Search size="small" className="absolute right-3" />
        </div>
      </Panel>
      <Results
        data={results || initialData?.items || []}
        isLoading={isLoading}
        error={error}
      />
      {!!error && (
        <div className="bg-red-500 text-white w-full p-3 text-center rounded-lg">
          <Text className="block" as="span">
            {translate("errors.loadingResults")}
          </Text>
        </div>
      )}
    </div>
  );
};
