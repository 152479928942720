import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import subcomponents.
 */
import { Modal } from "../../core";

/**
 * Import images.
 */
import QuestionMark from "../../../images/question-mark.svg";

/**
 * Types.
 */
interface IDeleteConfirmationModal {
  title: string;
  yesButtonLabel: string;
  visible: boolean;
  hideCallback: () => void;
  deleteCallback: () => void;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const DeleteConfirmationModal: React.FC<IDeleteConfirmationModal> = ({
  visible,
  title,
  yesButtonLabel,
  hideCallback,
  deleteCallback,
}) => {
  const [t] = useTranslation("common");

  return (
    <Modal
      visible={visible}
      title={title}
      image={QuestionMark}
      buttons={[
        {
          name: "yesCancel",
          label: yesButtonLabel,
          className: "btn-danger px-2 hover:btn-danger-hover w-40",
          onClick: () => {
            hideCallback();
            deleteCallback();
          },
        },
        {
          name: "noCancelJob",
          label: t("no"),
          className: "border-transparent px-2 hover:bg-secondary w-40",
          onClick: hideCallback,
        },
      ]}
    />
  );
};
