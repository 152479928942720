import { theme } from "../../theme";
import { StyleSheet } from "../../utils";
import { keyframes } from "@emotion/react";

export type AllowedColorKeys = keyof Pick<
  typeof theme.colors,
  "brand" | "accent" | "negative" | "warning" | "usp" | "greyscale"
>;

const slideIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } });

export const styles = StyleSheet({
  defaults: {
    ...theme.fontDefaults.base,
    color: theme.colors.dark.base,
  },
  item: {
    margin: `${theme.spacing.small} 0`,
    cursor: "pointer",
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderRadius: theme.spacing.xsmall,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.05)",
    background: theme.colors.light.base,
    minHeight: "80px",
  },
  heading: {
    ...theme.fontDefaults.small,
    fontWeight: "bold",
    padding: `${theme.spacing.xsmall} ${theme.spacing.xlarge4} ${theme.spacing.xsmall} ${theme.spacing.small}`,
    [`@media (min-width: ${theme.screens.medium})`]: {
      ...theme.fontDefaults.base,
    },
  },
  toggle: {
    position: "absolute",
    top: "50%",
    right: theme.spacing.xsmall,
    transform: "translateY(-50%)",
  },
  content: {
    display: "none",
  },
  contentVisible: {
    display: "block",
    marginLeft: theme.spacing.xsmall,
    marginRight: theme.spacing.xsmall,
    padding: theme.spacing.medium,
    borderBottomLeftRadius: theme.spacing.small,
    borderBottomRightRadius: theme.spacing.small,
    boxShadow: "inset 0 0 20px 10px rgba(0, 0, 0, 0.02)",
    animationName: slideIn,
    animationDuration: "300ms",
    background: theme.colors.light.base,
  },
  brand: {},
  accent: {
    color: theme.colors.dark.base,
  },
  negative: {},
  warning: {
    color: theme.colors.dark.base,
  },
  usp: {},
  greyscale: {},
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
});
