import * as Sentry from "@sentry/react";
import axios from "axios";
import { toasts } from "./toasts";

export const hasErrorMessage = (error: any): error is { message: string } => {
  return !!error.message;
};

export const handleException = (
  error: unknown,
  data?: Record<string, unknown>,
  tags?: Record<string, string>,
  defaultMessage = "An error occurred, please try again or contact support.",
  displayToasts = true,
) => {
  console.error(error);
  Sentry.withScope((scope) => {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        scope.setExtra(key, JSON.stringify(value));
      }
    }
    Sentry.captureException(error, {
      tags,
    });
  });
  if (displayToasts && axios.isAxiosError(error)) {
    return toasts.error({
      message: error.response?.data.message,
    });
  }
  if (displayToasts)
    toasts.error({
      message: hasErrorMessage(error) ? error.message : defaultMessage,
    });
};
