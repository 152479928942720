import axios from "axios";
import {
  TFilters,
  IUser,
  INewUser,
  ICurrentUser,
  ITabledResults,
  IPaginatedResults,
  ICompanyUser,
} from "./types";
import { generateQueryParams, hasMoreResults } from "./generic";
import { createRandomString } from "../helpers/api";

export const readUsersTableResults = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<ITabledResults<IUser>> => {
  try {
    const { items, pagination } = await readUsers(filters, currentPage, limit);

    return {
      items: items.map((item: IUser) => {
        const { email } = item;

        return {
          ...item,
          singleLink: `/users/update/${email}`,
          mutateUrl: `/users/${email}`,
        };
      }),
      pagination: {
        ...pagination,
        hasMoreResults: hasMoreResults(pagination.total, currentPage, limit),
      },
    };
  } catch (error) {
    throw error;
  }
};

export const readUsers = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<IPaginatedResults<IUser>> => {
  try {
    const queryParams = generateQueryParams(filters, currentPage, limit);
    const { data } = await axios.get(`/users${queryParams}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const readUser = async (emailAddress: string): Promise<ICurrentUser> => {
  try {
    const { data } = await axios.get(`/users/${emailAddress}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Get company users by company.
 * @param companyId int company id
 * @deprecated Use readCompanyUsersByCompanyCode instead
 */
export const readCompanyUsers = async (
  companyId: number,
): Promise<IPaginatedResults<ICompanyUser>> => {
  try {
    const { data } = await axios.get(`/users/?companies.id=${companyId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Get company users by company code (preferred).
 * @param companyCode string Company code
 */
export const readCompanyUsersByCompanyCode = async (
  companyCode: string,
): Promise<IPaginatedResults<ICompanyUser>> => {
  try {
    const { data } = await axios.get(
      `/users/?companies.companyCode=${companyCode}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  companies: number[];
  roles: number[];
  phoneNumber?: string;
  // temporaryPassword: string
  // active?: boolean
}

export const createUser = async (createUser: ICreateUser): Promise<IUser> => {
  try {
    const user = await axios.post("/users/portal", {
      ...createUser,
      active: true,
      temporaryPassword: createRandomString(9, true),
    });
    return user.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (userData: INewUser): Promise<IUser> => {
  const { firstName, lastName, phoneNumber, email, role, company, companies } =
    userData;

  const payload = {
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    email,
    company,
    companies,
    roles: [Number(role)],
  };

  try {
    const user = await axios.patch(`/users/${email}`, payload);
    return user.data;
  } catch (error) {
    throw error;
  }
};

export const getUser = async (id: string | undefined) => {
  try {
    const user = await axios.get(`/users/${id}`);
    return user.data;
  } catch (error) {
    throw error;
  }
};

export const getUserRoles = async (id: string | undefined) => {
  try {
    const roles = await axios.get(`/users/${id}`);
    return roles.data;
  } catch (error) {
    throw error;
  }
};

export const toggleDialpadRecording = async (
  userId: string,
  isRecording: boolean,
): Promise<{ isRecording: boolean }> => {
  const roles = await axios.post(`/users/dialpad/toggle-recording/${userId}`, {
    isRecording,
  });
  return roles.data;
};
