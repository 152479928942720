import React from "react";

/**
 * Import components
 */
import { Transition } from "..";

/**
 * Import images
 */
import { CloseIcon } from "../../../images";

/**
 * Type props for modal.
 */
interface IModalForm {
  visible: boolean;
  title: string;
  form: any;
  close?: () => void;
  smallForm?: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ModalForm: React.FC<IModalForm> = ({
  visible,
  title,
  form,
  close,
  smallForm = true,
}) => {
  /**
   * Output the modal only if visible is set to true otherwise null
   */
  return (
    <>
      <Transition
        className="modal-color top-0 left-0 bg-black z-50"
        customSettings={{
          from: { opacity: 0 },
          enter: { opacity: 0.7 },
          leave: { opacity: 0 },
        }}
        toggle={visible}
      />

      <Transition
        className="fixed top-0 pt-8 left-0 w-full h-full flex justify-center z-50 lg:pl-24 px-4 pb-10 md:px-0 md:pb-0 overflow-auto"
        transition="fadeIn"
        toggle={visible}
      >
        <div
          className={
            smallForm
              ? "modal modal-min-w modal-max-w flex flex-col items-center justify-center"
              : "modal modal-min-w modal-max-w flex flex-col items-center"
          }
        >
          <div className="w-full flex justify-between items-center">
            <h2 className="text-xl font-bold text-white leading-tight">
              {title}
            </h2>
            {typeof close !== "undefined" && (
              <button onClick={close}>
                <CloseIcon fill="#ffffff" />
              </button>
            )}
          </div>
          <div className="w-full mt-4 px-8 md:px-10 pt-5 pb-32 lg:py-8 bg-white rounded-xl mb-24 lg:mb-8">
            {form}
          </div>
        </div>
      </Transition>
    </>
  );
};
