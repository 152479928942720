/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const LeaderboardIcon: React.FC<TGenericImage> = ({
  className = "leaderboard-icon",
  fill = "currentColor",
  width = 26,
  height = 30,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 26 30"
    >
      <path
        fill={fill}
        d="M25.025 25.879l-4.523-7.835a10.689 10.689 0 002.83-7.278c0-2.982-1.213-5.661-3.159-7.607A10.713 10.713 0 0012.566 0C9.584 0 6.906 1.213 4.96 3.159A10.713 10.713 0 001.8 10.766c0 2.805 1.062 5.358 2.83 7.278L.108 25.88a.616.616 0 00-.05.48.523.523 0 00.682.303l3.614-1.44.556 3.866a.498.498 0 00.252.38c.253.15.582.05.733-.203l4.575-7.91c.657.126 1.364.202 2.047.202.707 0 1.39-.076 2.047-.202l4.574 7.91c.152.253.48.329.733.202a.498.498 0 00.253-.379l.555-3.867 3.614 1.441a.523.523 0 00.683-.303c.151-.177.126-.354.05-.48zM5.793 27.395l-.455-3.008a.56.56 0 00-.606-.454.36.36 0 00-.152.05l-2.805 1.112 3.64-6.293a10.67 10.67 0 004.017 2.25l-3.639 6.343zm6.773-6.925a9.664 9.664 0 01-9.679-9.679 9.664 9.664 0 012.83-6.849 9.501 9.501 0 016.85-2.88 9.664 9.664 0 019.678 9.679 9.664 9.664 0 01-2.83 6.848 9.501 9.501 0 01-6.849 2.881zm7.986 3.513c-.05-.025-.1-.05-.151-.05-.278-.051-.556.151-.607.454l-.455 3.008-3.64-6.343a10.67 10.67 0 004.02-2.25l3.638 6.293-2.805-1.112z"
      />
    </svg>
  );
};
