import { theme } from "../../../../theme";
import { StyleSheet, hexToRgba } from "../../../../utils";

export const styles = StyleSheet({
  container: {
    ...theme.fontDefaults.small,
  },
  control: {
    borderWidth: "1px",
    borderColor: theme.colors.greyscale.lighter,
    borderRadius: theme.spacing.xsmall2,
    minHeight: theme.spacing.xlarge,
    boxSizing: "border-box",
    boxShadow: "none",
    paddingRight: theme.spacing.xsmall,
    ":focus": {
      borderColor: theme.colors.focus.base,
    },
    ":hover": {
      borderColor: theme.colors.focus.base,
    },
    ".error &": {
      borderColor: theme.colors.negative.base,
    },
    ".success &": {
      borderColor: theme.colors.positive.base,
    },
    ".disabled &": {
      color: theme.colors.greyscale.lighter,
      borderColor: theme.colors.greyscale.lightest,
      backgroundColor: hexToRgba(theme.colors.greyscale.lightest, 0.9),
    },
  },
  dropdownIndicator: {
    color: theme.colors.brand.base,
    transform: " translateY(-10%) rotate(90deg)",
    transition: "0.2s ease-in-out",
    ".error &": {
      color: theme.colors.negative.base,
    },
    ".success &": {
      color: theme.colors.positive.base,
    },
    ".disabled &": {
      color: theme.colors.greyscale.lighter,
    },
    ".open &": {
      transform: "rotate(270deg)",
    },
  },

  menu: {
    borderColor: theme.colors.greyscale.lighter,
    borderRadius: theme.spacing.xsmall2,
    zIndex: theme.stack.inputSelect,
    borderWidth: 1,
  },
  indicatorSeparator: {
    display: "none",
  },
  nativeSelect: {
    display: "none",
  },
  loadingValueContainer: {
    position: "static",
  },
});
