import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { theme } from "../../theme";
import { AllowedColorKeys, styles } from "./Text.styles";

type ParagraphDetailedProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

type PreDetailedProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLPreElement>,
  HTMLPreElement
>;

type LabelDetailedProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

type TextDetailedProps = ParagraphDetailedProps &
  PreDetailedProps &
  LabelDetailedProps;

export interface TextProps extends UserStylesProps<TextDetailedProps> {
  as?: "p" | "span" | "pre" | "code" | "label";
  fontSize?: keyof typeof theme["fontDefaults"];
  color?: AllowedColorKeys;
}

export const Text = ({
  as = "p",
  fontSize = "base",
  styles: userStyles,
  color,
  ...rest
}: TextProps): React.ReactElement => {
  const Component = as;
  return (
    <Component
      css={css(
        styles.defaults,
        theme.fontDefaults[fontSize],
        userStyles,
        color && { color: theme.colors[color].base },
      )}
      {...rest}
    />
  );
};
