import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FieldArray } from "formik";
import {
  Form,
  Field,
  Link,
  theme,
  Heading,
  Input,
  Panel,
  Button,
} from "@clearabee/ui-library";
import {
  supplierTypeValidation,
  createSupplierTypeInitial,
} from "../../validation";

const typeOfValueOptions = [
  { label: "Text Input", value: "textInput" },
  { label: "Text Area", value: "textArea" },
  { label: "Number Input", value: "numberInput" },
  { label: "True / False", value: "toggle" },
];

// LORENZO - Some anys to resolve in this file
interface createUpdateSupplierTypeFormProps {
  mutateFunction: any;
  disableSubmitButton: boolean;
  supplierTypeData?: any;
  title: string;
}

type FormValues = Yup.InferType<typeof supplierTypeValidation>;

interface ColumnData {
  name: string;
  type: string;
  title: string;
  required: boolean;
}

export const CreateUpdateSupplierTypeForm = ({
  mutateFunction,
  disableSubmitButton,
  supplierTypeData,
  title,
}: createUpdateSupplierTypeFormProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");

  const handleSubmit = (values: FormValues) => {
    const columnsArrangedIntoObject: any = {};

    function toCamelCase(string: string) {
      return string
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
    }

    /**
     * For each column, generate an object with a key of "columnName", and
     * build another object as a value of that key, which contains the rest
     * of the column data. Add each of these column objects to the object called
     * columnsArrangedIntoObject
     */
    values.columns.forEach((column: ColumnData) => {
      columnsArrangedIntoObject[toCamelCase(column.name)] = {
        type: column.type,
        title: column.title,
        required: column.required,
      };
    });

    if (columnsArrangedIntoObject) {
      mutateFunction({
        type: values.type,
        columns: columnsArrangedIntoObject,
      });
    }
  };

  return (
    <Panel>
      <Heading level={3} fontSize="base" color="brand">
        {title}
      </Heading>
      <Form
        initialValues={supplierTypeData || createSupplierTypeInitial}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={supplierTypeValidation}
      >
        {({ values }) => (
          <>
            <Field name="type">
              {({ field }) => (
                <Input.Text
                  placeholder={translate(
                    "supplierTypes.form.placeholders.nameSupplierType",
                  )}
                  {...field}
                />
              )}
            </Field>

            <Heading
              level={3}
              fontSize="base"
              color="brand"
              styles={{
                marginTop: theme.spacing.large,
                marginBottom: theme.spacing.small,
              }}
            >
              {translate("supplierTypes.headings.columns")}
            </Heading>
            <FieldArray name="columns">
              {({ remove, push }) => (
                <>
                  {values.columns?.map((col: ColumnData, index: number) => {
                    return (
                      <div
                        className="flex flex-col md:flex-row gap-3 items-center w-full bg-gray-100 mb-3 px-3 rounded-md"
                        key={index}
                      >
                        <Field
                          name={`columns.${index}.name`}
                          styles={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          {({ field }) => (
                            <>
                              <Input.Text {...field} />
                            </>
                          )}
                        </Field>
                        <Field
                          name={`columns.${index}.type`}
                          styles={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          {({ field }) => (
                            <>
                              <Input.Select
                                {...field}
                                key={field.value}
                                options={typeOfValueOptions}
                                defaultValue={col.type}
                              />
                            </>
                          )}
                        </Field>
                        <Field
                          name={`columns.${index}.title`}
                          styles={{
                            textAlign: "left",
                            width: "100%",
                          }}
                        >
                          {({ field }) => (
                            <>
                              <Input.Text {...field} />
                            </>
                          )}
                        </Field>
                        <Field
                          name={`columns.${index}.required`}
                          styles={{
                            marginLeft: theme.spacing.small,
                            width: "100%",
                          }}
                          type="checkbox"
                        >
                          {({ field }) => (
                            <>
                              <Input.Toggle {...field} color="positive" />
                            </>
                          )}
                        </Field>
                        <Link
                          type="button"
                          color="negative"
                          underline
                          className="ml-3 font-black mb-4 md:mb-0"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          {translate("supplierTypes.buttons.remove")}
                        </Link>
                      </div>
                    );
                  })}

                  <div className="w-full flex justify-end mt-4">
                    <Button
                      size="xsmall"
                      type="button"
                      color="brand"
                      onClick={() =>
                        push({
                          columnName: "",
                          typeOfValue: "",
                          title: "",
                          defaultValue: "",
                          required: false,
                        })
                      }
                    >
                      {translate("supplierTypes.buttons.addAnotherColumn")}
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>

            <Button
              type="submit"
              size="medium"
              className="mx-auto flex justify-center mt-10"
              disabled={disableSubmitButton}
            >
              {translate("supplierTypes.buttons.submit")}
            </Button>
          </>
        )}
      </Form>
    </Panel>
  );
};
