import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  default: {
    borderStyle: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    border: `1px solid ${theme.colors.brand.light}`,
    padding: `${theme.spacing.xsmall} ${theme.spacing.xlarge2}`,
    borderRadius: theme.spacing.xsmall2,
    color: theme.colors.dark.base,
    "&:hover": {
      border: `1px solid ${theme.colors.brand.dark}`,
      boxShadow: `0 2px 5px 0 ${theme.colors.brand.lightest}`,
    },
  },
});
