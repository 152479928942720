import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useQuery, useMutation } from "react-query";
import { FieldArray } from "formik";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Message,
  Panel,
  displayErrorMessage,
  Text,
  theme,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { initialValues, validationSchema } from "./validation";

type PatchVehicleDriversRequestBody = Parameters<
  Awaited<typeof instance.vehicles.patchVehicleDrivers>
>[1];

export const DriverAllocation = (): React.ReactElement => {
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [translate] = useTranslation("drivers");
  const [error, setError] = useState("");

  /**
   * fetch all vehicles
   */
  const {
    data: allVehiclesData,
    isLoading: isLoadingAllVehicles,
    isFetching: isFetchingAllVehicles,
    refetch: refetchAllVehicles,
  } = useQuery(
    "getAllVehicles",
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            limit: 1000,
          },
        })
      ).data.items,
    {
      onError: () => setError("Error when fetching Vehicles Data"),
    },
  );

  /**
   * fetch all users with filter of role name :Clearabee Driver
   */
  const {
    data: allDriversData,
    isLoading: isLoadingAllDrivers,
    isFetching: isFetchingAllDrivers,
    refetch: refetchAllDrivers,
  } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver,Clearabee Administrator",
            limit: 1000,
          },
        })
      ).data.items,
    {
      onError: () => setError("Error when fetching Drivers Data"),
    },
  );

  /**
   * patch selected drivers to selected vehicle
   */
  const {
    mutate,
    isLoading: isLoadingAllocateDrivers,
    reset: resetAllocateDrivers,
    isSuccess,
  } = useMutation(
    "allocateDrivers",
    async (driverEmails: PatchVehicleDriversRequestBody) => {
      await instance.vehicles.patchVehicleDrivers(
        selectedVehicleId,
        driverEmails,
      );
    },
    {
      onError: () => setError("Error when updating vehicle's drivers"),
      onSuccess: () => {
        refetchAllVehicles();
      },
    },
  );

  /**
   * All drivers select options
   */
  const vehicleSelectOptions = !!allVehiclesData?.length
    ? allVehiclesData?.map(({ registration, id }) => ({
        label: registration,
        value: String(id),
      }))
    : [];

  /**
   * All drivers select options
   */
  const driverSelectOptions = !!allDriversData?.length
    ? allDriversData?.map(({ firstName, lastName, email }) => ({
        label: `${firstName} ${lastName} (${email})`,
        value: email,
      }))
    : [];

  /**
   * handle submit form
   */
  const handleSubmit = (values: typeof initialValues) => {
    mutate(values.driverEmails.filter(Boolean));
  };

  /**
   * handle reset form
   */
  const handleReset = (): void => {
    setError("");
    refetchAllVehicles();
    resetAllocateDrivers();
    refetchAllDrivers();
  };

  const selectFieldStyles = useMemo(
    () => ({
      ...theme.fontDefaults.small,
      minHeight: theme.spacing.xlarge,
      maxHeight: theme.spacing.xlarge2,
      alignItems: "center",
      overflow: "scroll",
    }),
    [],
  );

  /**
   * Display existing drivers number
   */
  const existingDrivers = useMemo(
    () =>
      allVehiclesData?.find(({ id }) => String(id) === selectedVehicleId)?.users
        ?.length,
    [selectedVehicleId, allVehiclesData],
  );

  const isLoading =
    isLoadingAllVehicles ||
    isFetchingAllVehicles ||
    isLoadingAllDrivers ||
    isFetchingAllDrivers ||
    isLoadingAllocateDrivers;

  return (
    <Box className="max-w-screen-lg mx-auto py-10 relative">
      <Form
        initialValues={{ ...initialValues, driverEmails: [""] }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, setFieldValue, values }) => {
          useEffect(() => {
            // set initial drivers emails to form value
            const initialDriverEmails = allVehiclesData
              ?.find(({ id }) => String(id) === selectedVehicleId)
              ?.users?.map(({ email }) => email);

            setFieldValue(
              "driverEmails",
              !!initialDriverEmails && initialDriverEmails?.length > 0
                ? initialDriverEmails
                : [""],
            );
          }, [selectedVehicleId]);

          return (
            <>
              <Panel
                className="flex flex-col gap-x-6 justify-between"
                shadow={false}
              >
                <Box className="flex justify-between">
                  <Heading fontSize="large" color="brand">
                    {translate("allocate.headings.driverAllocation")}
                  </Heading>
                  <Box className="flex flex-row gap-x-3 items-center">
                    {/* Loading */}
                    {isLoading && <Icon.Loading size="large" color="brand" />}
                    <Button
                      size="small"
                      color="negative"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        setSelectedVehicleId("");
                        handleReset();
                      }}
                    >
                      {translate("allocate.buttons.reset")}
                    </Button>
                    <Button size="small" type="submit">
                      {translate("allocate.buttons.update")}
                    </Button>
                  </Box>
                </Box>
                <Box className="w-full gap-x-3 border-t border-grey-200 mt-4">
                  <Box className="w-full">
                    <Field
                      name="vehicle"
                      className="flex-1 min-w-full"
                      label={translate("allocate.form.headings.vehicle")}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isMulti={false}
                          isClearable
                          isSearchable
                          placeholder={translate(
                            "allocate.form.placeholders.vehicle",
                          )}
                          options={vehicleSelectOptions}
                          onChange={(values) => {
                            if (!!values) {
                              setSelectedVehicleId(values.value);
                              setFieldValue("vehicle", values);
                            } else {
                              setSelectedVehicleId("");
                              setFieldValue("vehicle", "");
                            }
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              ...selectFieldStyles,
                            }),
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                {/* Display existing drivers number */}
                {!!existingDrivers && (
                  <Box className="w-full mt-1 flex flex-row justify-start items-center gap-x-1">
                    <Text fontSize="xsmall" className="font-bold">
                      {translate("allocate.existingDrivers")}
                    </Text>
                    <Text fontSize="xsmall">{existingDrivers}</Text>
                  </Box>
                )}
                <Box>
                  {/* Display Errors if not found Email and Reg or fail to send notification to drivers*/}
                  {error && (
                    <Box className="w-full mt-5">
                      {displayErrorMessage(
                        translate("allocate.errors.driversAllocation", {
                          error,
                        }),
                        ({ children }) => (
                          <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                            <Message type="error" background>
                              {children}
                            </Message>
                          </Box>
                        ),
                      )}
                    </Box>
                  )}
                  {/* Display success message*/}
                  {isSuccess && (
                    <Box className="w-full mt-2">
                      <Box className="flex items-center justify-start w-full mb-2 overflow-scroll">
                        <Message type="success" background color="light">
                          {translate(
                            "allocate.success.successAllocatedDrivers",
                          )}
                        </Message>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Panel>
              {!!selectedVehicleId && (
                <>
                  <Box className="w-full mt-5">
                    <Box className="flex flex-row justify-between items-center pl-1 pr-10">
                      <Text fontSize="base" className="font-semibold">
                        {translate("allocate.headings.allocatedDrivers")}
                      </Text>
                      <Button
                        size="small"
                        color="accent"
                        type="button"
                        disabled={
                          isLoading ||
                          values.driverEmails.length >=
                            driverSelectOptions.length
                        }
                        onClick={() =>
                          setFieldValue(`driverEmails`, [
                            ...values.driverEmails,
                            "",
                          ])
                        }
                      >
                        {translate("allocate.table.actions.add")}
                      </Button>
                    </Box>
                  </Box>
                  <Box className="mt-3">
                    <FieldArray
                      validateOnChange
                      name="driverEmails"
                      render={(arrayHelpers) => (
                        <>
                          {values.driverEmails.flatMap((_, index) => (
                            <Panel
                              key={index}
                              className="flex flex-row gap-x-6 justify-between items-center"
                              styles={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: theme.spacing.medium,
                              }}
                              shadow={false}
                            >
                              <Box className="w-5/6 relative -top-1">
                                <Field
                                  name={`driverEmails[${index}]`}
                                  className="flex-1"
                                  label={translate(
                                    "allocate.form.headings.driver",
                                  )}
                                >
                                  {({ field }) => (
                                    <Select
                                      {...field}
                                      isMulti={false}
                                      value={
                                        driverSelectOptions.find((value) =>
                                          values.driverEmails[index].includes(
                                            value.value,
                                          ),
                                        ) ?? ""
                                      }
                                      isClearable
                                      isSearchable
                                      placeholder={translate(
                                        "allocate.form.placeholders.driver",
                                      )}
                                      options={driverSelectOptions.filter(
                                        (option) =>
                                          !values.driverEmails.includes(
                                            option.value,
                                          ),
                                      )}
                                      onChange={(value) => {
                                        if (!!value) {
                                          setFieldValue(
                                            `driverEmails[${index}]`,
                                            value.value,
                                          );
                                        } else {
                                          setFieldValue(
                                            `driverEmails[${index}]`,
                                            "",
                                          );
                                        }
                                      }}
                                      styles={{
                                        control: (base) => ({
                                          ...base,
                                          ...selectFieldStyles,
                                        }),
                                      }}
                                    />
                                  )}
                                </Field>
                              </Box>
                              <Box className="w-1/6 relative top-2 flex flex-row justify-end">
                                <Button
                                  color="negative"
                                  type="button"
                                  size="small"
                                  disabled={isLoading}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  {translate("allocate.table.actions.delete")}
                                </Button>
                              </Box>
                            </Panel>
                          ))}
                        </>
                      )}
                    />
                  </Box>
                </>
              )}
            </>
          );
        }}
      </Form>
    </Box>
  );
};
