import React from "react";

/**
 * Import components.
 */
import { Quantity } from ".";

/**
 * Import types.
 */
import { Item } from "../../../../models/item";

/**
 * Import images.
 */
import FallbackImage from "../../../../images/call-centre.svg";

/**
 * Import hooks.
 */
import {
  useAuthContext,
  useBasketContext,
  useCatalogueContext,
} from "../../../../hooks";

export const ResultsItem = ({
  sku,
  parentSku,
  isPortalCatalogue,
  title,
  description,
  price,
  img,
}: Item & { isPortalCatalogue?: boolean }): React.ReactElement => {
  const { addItem, deleteItem, getItem, setCatalogueId } = useBasketContext();
  const { catalogueId: customerCatalogueId } = useCatalogueContext();
  const { getCurrentUserCurrentCompanySettings } = useAuthContext();
  const companySettings = getCurrentUserCurrentCompanySettings();

  return (
    <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex p-2">
      <div className="flex-1 justify-between bg-white shadow-filter rounded-lg px-4 pb-10 pt-12 flex flex-col relative">
        {!!price && !companySettings?.hidePrices && (
          <div
            className="absolute right-4 top-4 rounded-full px-2 py-1 text-right text-xs text-white"
            style={{ backgroundColor: "#FF860F" }}
          >{`£${price.toFixed(2)}`}</div>
        )}
        <img
          className="my-3 mx-auto"
          src={img || FallbackImage}
          alt={title || ""}
          loading="lazy"
          width={96}
          height={96}
        />
        {title ? (
          <h2 className="text-purple-800 font-semibold text-sm w-full text-center">
            {title}
          </h2>
        ) : null}
        {description ? (
          <div className="w-full text-xs text-center">{description}</div>
        ) : null}
        <div className="mt-4">
          <Quantity
            quantity={getItem(sku)?.quantity}
            setQuantity={(newQuantity) => {
              if (newQuantity) {
                setCatalogueId(
                  isPortalCatalogue ? "PORTAL" : customerCatalogueId,
                );
                addItem({
                  title,
                  quantity: newQuantity,
                  sku,
                  price: getItem(sku)?.price || price || 0,
                  parentSku,
                });
              } else {
                deleteItem(sku);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
