import React, { useEffect } from "react";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { Form, Field, Button, Input, Box } from "@clearabee/ui-library";
import roles from "constants/roles";
import { TFilters } from "../../../../api/types";
import { useAuthContext } from "../../../../hooks";
import { SearchIcon } from "images";
import { UserFiltersCompany } from "./userFiltersCompany";
import { UserFiltersRole } from "./userFiltersRole";
import { UserFiltersSupplier } from "./userFiltersSupplier";

interface UserFiltersType {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
}

export const UserFilters = ({
  isFetching,
  updateFilters,
}: UserFiltersType): React.ReactElement => {
  const [translate] = useTranslation("users");
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);

  const handleSubmit = ({ field, operator, searchValue }: FormikValues) => {
    if (
      field === "companies.companyCode" ||
      field === "roles.name" ||
      field === "suppliers.id"
    ) {
      updateFilters(`${field}=${encodeURIComponent(searchValue)}`);
    } else {
      updateFilters(
        `${field}:${operator}=${
          operator === "likeLower" ? encodeURIComponent("%") : ""
        }${searchValue}${
          operator === "likeLower" ? encodeURIComponent("%") : ""
        }`,
      );
    }
  };

  const fieldOptions = [
    {
      value: "firstName",
      label: translate("user.form.optionLabels.firstName"),
    },
    {
      value: "lastName",
      label: translate("user.form.optionLabels.lastName"),
    },
    {
      value: "email",
      label: translate("user.form.optionLabels.email"),
    },
  ];

  // Allow company, roles and suppliers filter only if Clearabee Admin
  // Allow company filter only if Company Admin
  if (isClearabeeAdmin || isCompanyAdmin || isClearabeeManager) {
    fieldOptions.push({
      value: "companies.companyCode",
      label: translate("user.form.optionLabels.company"),
    });

    if (isClearabeeAdmin || isClearabeeManager) {
      fieldOptions.push({
        value: "roles.name",
        label: "Role",
      });
    }
  }

  const operatorOptions = [
    { value: "likeLower", label: translate("user.form.optionLabels.contains") },
    { value: "eq", label: translate("user.form.optionLabels.equals") },
  ];

  const initialValues = {
    field: fieldOptions[0].value,
    operator: operatorOptions[0].value,
    searchValue: "",
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, resetForm }) => {
        useEffect(() => {
          if (
            values.field === "companies.companyCode" ||
            values.field === "roles.name" ||
            values.field === "suppliers.id"
          ) {
            setFieldValue("operator", "eq");
          }
        }, [values]);

        return (
          <Box className="border-t border-grey-200 pt-5 mt-4">
            <Box className="flex flex-col items-center md:flex-row -mx-2">
              <Box className="w-full md:w-1/4 px-2 mb-3 md:mb-0">
                <Field
                  name="field"
                  label={translate("user.form.placeholders.field")}
                  styles={{
                    margin: 0,
                  }}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={fieldOptions}
                      onChange={(event) => {
                        setFieldValue("searchValue", "");
                        setFieldValue("field", event.target.value);
                      }}
                    />
                  )}
                </Field>
              </Box>
              <Box className="w-full md:w-1/4 px-2 mb-3 md:mb-0">
                <Field
                  name="operator"
                  label={translate("user.form.placeholders.operator")}
                  styles={{
                    margin: 0,
                  }}
                >
                  {({ field }) => (
                    <Input.Select
                      disabled={
                        values.field === "companies.companyCode" ||
                        values.field === "roles.name" ||
                        values.field === "suppliers.id"
                      }
                      options={
                        values.field === "companies.companyCode" ||
                        values.field === "roles.name" ||
                        values.field === "suppliers.id"
                          ? operatorOptions.filter(
                              ({ value }) => value === "eq",
                            )
                          : operatorOptions
                      }
                      {...field}
                      defaultValue={values.operator}
                    />
                  )}
                </Field>
              </Box>
              <Box className="w-full md:w-1/4 px-2 mt-6 mb-3 md:mb-0">
                <Field
                  name="searchValue"
                  styles={{
                    margin: 0,
                  }}
                >
                  {({ field }) => (
                    <>
                      {values.field === "companies.companyCode" && (
                        <UserFiltersCompany {...field} />
                      )}
                      {values.field === "roles.name" && (
                        <UserFiltersRole {...field} />
                      )}
                      {values.field === "suppliers.id" && (
                        <UserFiltersSupplier {...field} />
                      )}
                      {values.field !== "roles.name" &&
                        values.field !== "companies.companyCode" &&
                        values.field !== "suppliers.id" && (
                          <Input.Text placeholder="..." {...field} />
                        )}
                    </>
                  )}
                </Field>
              </Box>
              <Box className="flex flex-1 px-2 pt-6 -mx-2 mt-2 md:mt-0">
                <Box className="w-1/2 px-2">
                  <Button
                    type="submit"
                    disabled={isFetching || !values.field || !values.operator}
                    size="small"
                    color="accent"
                    className="w-full flex justify-center items-center"
                  >
                    <SearchIcon className="mr-2" />
                    {translate("common:form.buttons.search")}
                  </Button>
                </Box>
                <Box className="w-1/2 px-2">
                  <Button
                    type="reset"
                    size="small"
                    variant="outline"
                    color="negative"
                    onClick={() => {
                      resetForm();
                      updateFilters("");
                    }}
                  >
                    {translate("common:form.buttons.reset")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </Form>
  );
};
