import { theme } from "../../theme";
import { StyleSheet, createResponsiveStyles } from "../../utils";

export const styles = StyleSheet({
  overlay: {
    position: "absolute",
    backgroundColor: `${theme.colors.dark.base}66`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.stack.overlay,
  },
  baseContainer: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: theme.stack.header,
    padding: `${theme.spacing.small} 0`,
  },
  headerItems: {
    margin: "0 auto",
    padding: `0 ${theme.spacing.small}`,
    justifyContent: "space-between",
    maxWidth: theme.screens.xlarge,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    boxSizing: "border-box",
  },
  headerBackground: {
    backgroundColor: theme.colors.brand.base,
    boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.15)",
  },
  navigationLinks: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    color: theme.colors.light.base,
  },
  textLinks: createResponsiveStyles({
    base: { display: "none" },
    medium: {
      display: "flex",
    },
  }),
  text: {
    marginRight: theme.spacing.large,
    fontFamily: "ProximaBold",

    ":hover": {
      color: theme.colors.accent.base,
    },
  },
  iconLink: {
    fontWeight: 600,
    color: theme.colors.light.base,
    cursor: "pointer",
    transition: "color 0.5s",

    ":hover": {
      color: theme.colors.accent.base,
    },
  },
  linkSpace: {
    marginLeft: theme.spacing.medium,
  },
  alignTextIcon: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  logo: {
    justifyContent: "center",
    alignItems: "center",
    justifySelf: "flex-end",
    display: "flex",
    cursor: "pointer",
  },
  logoScrolling: createResponsiveStyles({
    medium: {
      paddingLeft: theme.spacing.xlarge2,
    },
    xlarge: {
      paddingLeft: theme.spacing.xlarge,
    },
    xlarge2: {
      paddingLeft: 0,
    },
  }),
  basketCountContainer: {
    position: "relative",
  },
  basketCount: {
    position: "absolute",
    top: "-45px",
    left: "12px",

    borderRadius: "50%",
    width: "15px",
    height: "15px",
    padding: "5px",
    background: theme.colors.accent.base,
    color: theme.colors.dark.base,
    textAlign: "center",
    zIndex: -1,
  },
  basketButton: {
    paddingLeft: theme.spacing.xlarge,
    paddingRight: theme.spacing.xlarge,
  },
});
