import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { styles } from "./Breadcrumbs.styles";
import { Link, LinkProps } from "../../Core/Link/Link";

const BreadcrumbsLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ ...rest }: LinkProps, ref): React.ReactElement => {
    return <Link ref={ref} {...rest} />;
  },
);

export interface BreadcrumbsItemProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >
  > {
  active?: boolean;
}

const BreadcrumbsItem = ({
  active = false,
  styles: userStyles,
  ...rest
}: BreadcrumbsItemProps): React.ReactElement => {
  return (
    <span
      css={css(active && styles.active, userStyles)}
      data-testid="breadcrumbs-item"
      {...rest}
    />
  );
};

export interface BreadcrumbsProps
  extends UserStylesProps<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
  > {
  separator?: React.ReactNode;
  children?: React.ReactNode;
}

export const Breadcrumbs = ({
  children,
  separator = "/",
  styles: userStyles,
  ...rest
}: BreadcrumbsProps): React.ReactElement => {
  const childrenArray = React.Children.toArray(children);

  return (
    <nav aria-label="breadcrumbs" css={css(userStyles)} {...rest}>
      <ol css={css(styles.list)}>
        {childrenArray.map((child, index) => (
          <li key={`breadcrumb-item-${index}-${child.toString()}`}>
            {child}
            {index < childrenArray.length - 1 &&
              (typeof separator === "string" ? (
                <span
                  aria-hidden="true"
                  role="presentation"
                  css={css(styles.separator)}
                >
                  {separator}
                </span>
              ) : (
                separator
              ))}
          </li>
        ))}
      </ol>
    </nav>
  );
};

Breadcrumbs.Item = BreadcrumbsItem;
Breadcrumbs.Link = BreadcrumbsLink;
