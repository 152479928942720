"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tablePanel = void 0;
exports.tablePanel = {
    tables: "Tables",
    expand: "Expand",
    collapse: "Collapse",
    inputPlaceholder: "Search for driver, vehicle reg number or region",
    noRegion: "No region",
    noDriver: "No driver",
    unknown: "Unknown",
    noResults: "No results",
};
