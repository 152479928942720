import React from "react";
import cx from "classnames";

/**
 * Import images.
 */
import { Tick } from "../../../images";

/**
 * Interface.
 */
interface ICustomRadio {
  fieldId: string;
  fieldName: string;
  fieldValue: string | number;
  additionalClasses?: string;
  title: string;
  image: string | React.ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Custom checkbox component.
 */
export const CustomRadio: React.FC<ICustomRadio> = ({
  fieldId,
  fieldName,
  fieldValue,
  additionalClasses = "",
  title,
  image,
  isChecked = false,
  isDisabled = false,
  onChange,
}) => {
  /**
   * Classes for checkbox.
   */
  const classes = cx(
    "w-full text-center relative rounded-xl border-2 border-transparent bg-white p-4 md:px-8 md:pt-8 md:pb-6 xl:pt-16 xl:pb-12 shadow-filter transition-all duration-500",
    additionalClasses,
    {
      "opacity-50": !isChecked,
      "is-checked border-green-light opacity-100": isChecked,
      "is-disabled": isDisabled,
    },
  );

  return (
    <div className={classes}>
      <div className="flex flex-row justify-center items-center h-full">
        <label
          htmlFor={fieldId}
          className="custom-checkbox-label absolute left-0 top-0 right-0 bottom-0 cursor-pointer"
        />

        {isChecked && (
          <div className="absolute right-5 top-5 pointer-events-none">
            <div className="rounded-full bg-green-light flex items-center justify-center w-8 h-8 text-green-dark">
              <Tick
                stroke="currentColor"
                classes={{
                  svg: "relative w-4 h-4",
                }}
              />
            </div>
          </div>
        )}

        <input
          id={fieldId}
          name={fieldName}
          defaultChecked={isChecked}
          disabled={isDisabled}
          type="radio"
          className="custom-checkbox visually-hidden"
          onChange={onChange}
          value={fieldValue}
        />

        <div>
          {typeof image === "string" && (
            <div className="flex justify-center items-center h-20 md:h-40">
              <img src={image} alt={title} className="max-h-full" />
            </div>
          )}
          {typeof image !== "string" &&
            React.isValidElement(image) &&
            React.cloneElement(image)}
          <h3 className="mt-4 md:mt-8 text-primary text-xl font-bold">
            {title}
          </h3>
        </div>
      </div>
    </div>
  );
};
