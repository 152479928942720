import React, { useState } from "react";
import { Button, Icon, Modal, Table, theme } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { PaymentLinksFilters } from "./components";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { patchPaymentLink, readPaymentLinks } from "api/payment";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { PaymentLink } from "models";
import dayjs from "dayjs";
import { ErrorMessage } from "components/common/components";
import { useHistory } from "react-router-dom";
import { createBasketPaymentLink } from "api";
import { toasts } from "helpers";
import { handleException } from "helpers/handleException";
import { useMutation } from "react-query";

export type StatusColors = {
  [Key in PaymentLink["status"]]?: AllowedColorKeys;
};

interface ResultRowProps {
  item: PaymentLink;
  handleClick: (item: PaymentLink) => void;
  handleResendClick: (item: PaymentLink) => void;
  handleCancelClick: (item: PaymentLink) => void;
  isRowLoading?: boolean;
  isLoading?: boolean;
}

export const statusColors: StatusColors = {
  pending: "warning",
  paid: "positive",
  expired: "greyscale",
  cancelled: "negative",
};

export const ReadPaymentLinks = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();
  const [loadingIndex, setLoadingIndex] = useState<string | undefined>();
  const [cancelRow, setCancelRow] = useState<PaymentLink | undefined>();

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    query: { isFetching, isError, refetch },
  } = usePaginatedQuery(readPaymentLinks, "readPaymentLinks", "", {
    resultOptions: [10, 20, 50, 100],
  });

  const handleClick = (item: PaymentLink) => {
    history.push(`/jobs/payment-links/update/${item.basketToken}`);
  };

  const { mutate: resendPaymentLink, isLoading: resendLoading } = useMutation(
    (token: string) => {
      return createBasketPaymentLink(token);
    },
    {
      onError: (error, data) => {
        handleException(
          error,
          {
            data,
          },
          {
            type: "Update Payment Link",
            action: "Resend payment link",
          },
        );
      },
      onSuccess: () => {
        toasts.success({ message: translate("toasts.resendSuccess") });
      },
      onMutate: (item) => {
        setLoadingIndex(item);
      },
      onSettled: () => setLoadingIndex(undefined),
    },
  );

  const { mutate: cancelPaymentLink, isLoading: cancelLoading } = useMutation(
    (token: string) => {
      return patchPaymentLink(token, { status: "cancelled" });
    },
    {
      onError: (error, data) => {
        handleException(
          error,
          {
            data,
          },
          {
            type: "Update Payment Link",
            action: "Cancel payment link",
          },
        );
      },
      onSuccess: () => {
        refetch();
        toasts.success({ message: translate("toasts.cancelSuccess") });
      },
      onMutate: (item) => {
        setLoadingIndex(item);
      },
      onSettled: () => setLoadingIndex(undefined),
    },
  );
  const isLoading = resendLoading || cancelLoading;

  if (isError) return <ErrorMessage />;

  return (
    <>
      <PaymentLinksFilters
        isFetching={isFetching}
        updateFilters={updateFilters}
      />
      {isFetching ? (
        <Icon.Loading
          color="brand"
          styles={{ margin: `${theme.spacing.xlarge} auto` }}
        />
      ) : (
        <>
          <div className="relative flex flex-1 min-h-64">
            <Table
              className="my-10 self-start"
              styles={{ tableLayout: "fixed", borderSpacing: 0 }}
            >
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "28%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "22%" }} />
              </colgroup>
              <Table.Header
                headings={[
                  translate("payments.headings.status"),
                  translate("payments.headings.email"),
                  translate("payments.headings.jobDate"),
                  translate("payments.headings.createdDate"),
                  translate("payments.headings.postcode"),
                  translate("payments.headings.orderRef"),
                  translate("payments.headings.actions"),
                ]}
              />
              <Table.Body>
                {paginatedData.map((item) => (
                  <ResultRow
                    key={item.id}
                    item={item}
                    handleClick={handleClick}
                    handleResendClick={(item) =>
                      resendPaymentLink(item.basketToken)
                    }
                    handleCancelClick={(item) => setCancelRow(item)}
                    isRowLoading={loadingIndex === item.basketToken}
                    isLoading={isLoading}
                  />
                ))}
              </Table.Body>
            </Table>
          </div>
          <PaginationComponent />
        </>
      )}
      {cancelRow && (
        <Modal
          heading={translate("modal.headings.cancelPaymentLink")}
          subheading={translate("modal.headings.reference", {
            reference: cancelRow.orderRef,
          })}
          width={400}
        >
          <div className="flex justify-center mt-6">
            <Button
              size="medium"
              color="accent"
              className="mr-5"
              onClick={() => {
                cancelPaymentLink(cancelRow.basketToken);
                setCancelRow(undefined);
              }}
            >
              {translate("modal.buttons.labels.yesCancel")}
            </Button>
            <Button
              size="medium"
              color="negative"
              onClick={() => setCancelRow(undefined)}
            >
              {translate("modal.buttons.labels.goBack")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const ResultRow = ({
  item,
  handleClick,
  isLoading,
  isRowLoading,
  handleCancelClick,
  handleResendClick,
}: ResultRowProps) => {
  const [translate] = useTranslation("jobs");

  return (
    <Table.Row
      key={`table-row-${item.id}`}
      styles={{ cursor: "pointer", height: 73 }}
    >
      <Table.Cell.Pill
        color={statusColors[item.status]}
        onClick={() => handleClick(item)}
      >
        {item.status}
      </Table.Cell.Pill>
      <Table.Cell.Text onClick={() => handleClick(item)}>
        {item.email}
      </Table.Cell.Text>
      <Table.Cell.Text onClick={() => handleClick(item)}>
        {dayjs(item.date).format("DD/MM/YYYY")}
      </Table.Cell.Text>
      <Table.Cell.Text onClick={() => handleClick(item)}>
        {dayjs(item.createdOn).format("DD/MM/YYYY")}
      </Table.Cell.Text>
      <Table.Cell.Text onClick={() => handleClick(item)}>
        {item.postcode}
      </Table.Cell.Text>
      <Table.Cell.Text onClick={() => handleClick(item)}>
        {item.orderRef}
      </Table.Cell.Text>
      <Table.Cell>
        {item.status === "pending" && (
          <>
            {isRowLoading && (
              <Icon.Loading color="positive" className="mx-auto" />
            )}
            {!isRowLoading && (
              <>
                <Button
                  size="small"
                  color="brand"
                  className="mr-2"
                  onClick={() => handleResendClick(item)}
                  disabled={isLoading}
                >
                  {translate("payments.resend")}
                </Button>
                <Button
                  size="small"
                  color="negative"
                  onClick={() => handleCancelClick(item)}
                  disabled={isLoading}
                >
                  {translate("payments.cancel")}
                </Button>
              </>
            )}
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
};
