import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "@clearabee/ui-library";

/**
 * Import routes helper.
 */
import { Routes } from "../../../helpers/routes";

/**
 * Import form button component.
 */
import { FormButton } from "../../core";

/**
 * Button interface.
 */
interface IButton {
  linkRoute?: string;
  linkTranslationKey?: string;
  buttonTranslationKey?: string;
  loading?: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Button = ({
  linkRoute = "auth/forgot",
  linkTranslationKey = "forgotPassword",
  buttonTranslationKey = "login",
  loading = false,
}: IButton): React.ReactElement => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("auth");

  /**
   * Route config.
   */
  const route = new Routes().config(linkRoute);

  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      {typeof route !== "undefined" ? (
        <Link
          to={route.path}
          className="inline-block align-baseline font-semibold text-xs text-primary hover:text-blue-800"
        >
          {t(`form.links.${linkTranslationKey}`)}
        </Link>
      ) : null}
      <div className="flex flex-row items-center gap-3 mt-4 sm:mt-0">
        {loading && <Icon.Loading size="medium" color="brand" />}
        <FormButton
          className="block sm:inline-block"
          name="submit"
          type="submit"
          text={t(`form.buttons.${buttonTranslationKey}`)}
          variant="secondary"
          loader
        />
      </div>
    </div>
  );
};
