/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SearchIcon: React.FC<TGenericImage> = ({
  className = "search-icon",
  fill = "currentColor",
  width = 17,
  height = 17,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 17 17"
    >
      <g fill={fill}>
        <path d="M7.44 0C3.339 0 0 3.338 0 7.44c0 4.104 3.338 7.441 7.44 7.441 4.104 0 7.441-3.337 7.441-7.44S11.544 0 7.441 0zm0 13.508A6.074 6.074 0 011.375 7.44 6.074 6.074 0 017.44 1.374a6.074 6.074 0 016.067 6.067 6.074 6.074 0 01-6.067 6.067z" />
        <path d="M16.695 15.724l-3.938-3.938a.686.686 0 10-.971.97l3.938 3.939a.684.684 0 00.97 0 .686.686 0 000-.971z" />
      </g>
    </svg>
  );
};
