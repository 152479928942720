import * as Yup from "yup";
import { stringRequired } from "validation/common";
import { statusMap } from "./updateWorksheetHelpers";

export const validationSchema = Yup.object().shape({
  name: stringRequired,
  orderTypeIds: Yup.array().of(Yup.number()),
  statusId: Yup.number().oneOf(
    Object.keys(statusMap).map((key) => parseInt(key)), // Only these status updates are allowed
  ),
  includeForSnsEvents: Yup.boolean(),
});
