import React, { useEffect } from "react";
import { Button, Icon, useModal } from "@clearabee/ui-library";
import { styles } from "../bulkCreateJobs.styles";
import { useTranslation } from "react-i18next";

interface FailModalProps {
  isDisabled: boolean;
  duplicateRow: () => void;
  mutate: () => void;
  finishSubmit: (value: undefined) => void;
  isMutationError: boolean;
}

export const FailModal = ({
  isDisabled,
  duplicateRow,
  mutate,
  finishSubmit,
  isMutationError,
}: FailModalProps): React.ReactElement => {
  const [FailModal, setFailModal] = useModal();
  const [translate] = useTranslation("jobs");

  useEffect(() => {
    isMutationError && setFailModal(true);
  }, [isMutationError]);

  return (
    <>
      <button
        type="button"
        disabled={isDisabled}
        css={[
          styles.actionsCellDuplicate,
          isDisabled && { cursor: "not-allowed" },
        ]}
        onClick={duplicateRow}
      >
        <Icon.Copy size="small" />
      </button>
      <FailModal
        width={500}
        heading={translate("modal.headings.somethingWentWrong")}
        actions={
          <div style={styles.modal}>
            <Button
              onClick={() => {
                setFailModal(false);
                mutate();
              }}
              color="accent"
              size="medium"
              type="button"
            >
              {translate("modal.buttons.labels.retry")}
            </Button>
            <Button
              onClick={() => {
                setFailModal(false);
                finishSubmit(undefined);
              }}
              color="negative"
              type="button"
              size="medium"
            >
              {translate("modal.buttons.labels.skip")}
            </Button>
          </div>
        }
      />
    </>
  );
};
