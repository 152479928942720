import * as Yup from "yup";
import { IQuoteFormValues } from "../../api/types";
import {
  stringRequired,
  validEmail,
  fieldRequired,
  fieldRequiredShort,
  requiredOption,
  phone,
  lettersOnly,
} from "../../validation/common";
import { QuoteFormValues } from "./components/quoteForm";
import { ProductRow } from "./types";

/**
 * Create quote initial values.
 */
export const createQuoteInitialValues: IQuoteFormValues = {
  address: {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
  },
  contactFirstName: "",
  contactLastName: "",
  contactEmail: "",
  contactPhoneNumber: "",
  contactNoEmail: false,
  date: "",
  timeslot: {
    label: "",
    value: "",
  },
  description: "",
  accessInformation: "",
  images: [
    {
      id: 0,
      quoteId: 0,
      url: "",
    },
  ],
  addressLookupPostcode: "",
  addressChoices: [],
  company: {
    label: "",
    value: "",
  },
};

/**
 * Create quote validation schema.
 */
export const getCreateQuoteSchema = (canSelectCompany: boolean) =>
  Yup.object().shape({
    addressLine1: stringRequired,
    addressLine2: Yup.string(),
    addressCity: stringRequired,
    addressCounty: Yup.string(),
    addressPostcode: stringRequired,
    contactFirstName: lettersOnly,
    contactLastName: lettersOnly,
    contactEmail: Yup.string().when("contactNoEmail", {
      is: false,
      then: Yup.string().email(validEmail).required(fieldRequired),
    }),
    contactPhoneNumber: phone.when("contactNoEmail", {
      is: true,
      then: phone.required(fieldRequired),
    }),
    contactNoEmail: Yup.boolean(),
    date: stringRequired,
    noTimeConstraint: Yup.bool(),
    timeslot: Yup.object({
      to: Yup.string(),
      from: Yup.string(),
    }).when("noTimeConstraint", {
      is: false,
      then: Yup.object({
        to: stringRequired,
        from: stringRequired,
      }),
    }),
    description: Yup.string(),
    accessInformation: Yup.string(),
    images: Yup.string(),
    companyCode: canSelectCompany ? stringRequired : Yup.string(),
  });

/**
 * Respond to quote initial values
 */
export const newProductInitialValues: ProductRow = {
  service: {
    label: "",
    value: "",
  },
  product: {
    sku: "",
    qty: 0,
    price: 0,
    title: "",
  },
  qty: 0,
  price: 0,
};

/**
 * Respond to quote validation schema.
 */

const productSchema = Yup.object().shape({
  service: requiredOption,
  product: requiredOption,
});

export const staffResponseValidation = Yup.object().shape({
  products: Yup.array().of(productSchema),
  date: Yup.string().required(fieldRequiredShort),
  time: Yup.string().required(fieldRequiredShort),
  comments: Yup.string().when("commentsRequired", {
    is: true,
    then: Yup.string().required("Required"),
  }),
  commentsRequired: Yup.bool(),
});

/**
 * Accept on behalf of customer validation schema.
 */
export const acceptOnBehalfValidation = Yup.object().shape({
  companyUserEmail: Yup.string(),
});

export const blankValues: QuoteFormValues = {
  date: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressCounty: "",
  addressPostcode: "",
  contactFirstName: "",
  contactLastName: "",
  contactEmail: "",
  timeslot: {
    to: "",
    from: "",
  },
  noTimeConstraint: true,
  contactPhoneNumber: "",
  contactNoEmail: false,
  accessInformation: "",
  description: "",
  images: [],
};

export const fromTimeslots = [
  "08:00:00",
  "09:00:00",
  "10:00:00",
  "11:00:00",
  "12:00:00",
  "13:00:00",
  "14:00:00",
  "15:00:00",
  "16:00:00",
];

export const toTimeslots = [
  "10:00:00",
  "11:00:00",
  "12:00:00",
  "13:00:00",
  "14:00:00",
  "15:00:00",
  "16:00:00",
  "17:00:00",
  "18:00:00",
];
