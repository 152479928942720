/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const BeeLogo: React.FC<TGenericImage> = ({
  className = "bee-logo",
  fill = "currentColor",
  width,
  height,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 41"
      width={width}
      height={height}
    >
      <g fill={fill} fillRule="evenodd">
        <path d="M18.732 40.296c-4.07.256-7.666-1.254-9.183-4.278-1.508-3.001-.58-6.783 2.085-9.903.446 2.566 1.323 5.119 2.551 7.589a28.122 28.122 0 004.547 6.592M47.11 11.263c-.083 1.183-.661 2.145-1.375 2.418-.722 2.45-2.883 3.817-4.245 4.467.366.422.665.87.915 1.388a7.607 7.607 0 01-3.384 10.247 7.532 7.532 0 01-3.116.81c-.811-.046-1.657.15-2.424.547-.777.374-1.402.946-1.908 1.626-.078.132-.178.272-.254.404a2.352 2.352 0 01-.286.391 15.238 15.238 0 01-5.055-5.784 15.01 15.01 0 01-1.595-7.495c.142-.028.332 0 .483-.006.146.046.32.03.47.024a4.824 4.824 0 002.455-.532 4.712 4.712 0 001.877-1.64 7.298 7.298 0 012.49-1.977 7.6 7.6 0 015.846-.426c.796-.791 2.106-2.444 2.17-4.412-.633-.32-1.149-1.196-1.289-2.293-.139-1.43.456-2.68 1.366-2.774.91-.093 1.76.988 1.93 2.432.116 1.105-.23 2.134-.83 2.568.004 2.147-1.224 3.947-2.153 4.993.51.265.994.591 1.418.965 1.107-.45 3.26-1.633 3.944-3.787-.52-.492-.795-1.409-.728-2.432.119-1.427.968-2.545 1.863-2.478.903.088 1.555 1.321 1.415 2.756" />
        <path d="M21.223 19.659c-.662 1.43-.909 3.028-.636 4.58.32 1.822.902 3.627 1.772 5.357a19.447 19.447 0 005.834 6.943c-.92.737-1.95 1.387-3.066 1.943-.553.289-1.117.556-1.683.747a24.621 24.621 0 01-5.63-7.353 23.959 23.959 0 01-2.133-6.294c-.219-1.156-1.023-2.115-2.09-2.611l-9.35-4.354C.699 16.983-.824 12.81.81 9.269c1.65-3.497 5.868-5.037 9.378-3.416.625.297 1.193.642 1.696 1.089a7.33 7.33 0 01.609-1.947c1.65-3.497 5.824-5.02 9.356-3.408 3.52 1.642 5.042 5.815 3.408 9.356l-4.035 8.716" />
      </g>
    </svg>
  );
};
