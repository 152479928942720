import React from "react";
import { Link } from "react-router-dom";

/**
 * Import custom hooks.
 */
import { useAuthContext, useScreenWidth } from "../../hooks";

/**
 * Import app router component.
 */
import { Router } from "../app/components";

/**
 * Import sub components.
 */
import { Sidebar, CallCTA, AccountAvatar, AccountBubble } from "./components";
import { TitleBreadcrumbs } from "../core";
import { AccountSuspended } from "../app/components";

/**
 * Import images.
 */
import {
  BackgroundCircle,
  BeeLogo,
  DashboardIcon,
  TravellingBee,
} from "../../images";

import "./styles/portal.css";
import "./styles/submenu.css";
import "./styles/sidebar.css";
import "./styles/callCTA.css";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Portal: React.FC = () => {
  /**
   * Get user details.
   */
  const { isUserInactive } = useAuthContext();
  const { isLargeDesktop } = useScreenWidth();

  /**
   * Get year.
   */
  const dt = new Date();
  const copyrightYear = dt.getFullYear();

  /**
   * Get current path
   */
  const pathname = window.location.pathname;

  const hiddenLinks = ["reports"];

  if (isUserInactive()) {
    return <AccountSuspended />;
  }

  return (
    <div className="portal relative max-w-screen-2xl mx-auto pb-16 md:pb-20 lg:pb-0">
      <Sidebar />
      <TravellingBee className="portal-travelling-bee hidden xl:block absolute top-0 -mt-5 right-0" />

      <div className="portal-container min-h-screen z-10 px-4 py-3 pb-12 relative lg:pb-3 lg:pl-28 2xl:pl-32 x2xl:pl-4">
        {isLargeDesktop ? (
          <div className="relative flex-wrap hidden sm:flex-no-wrap xl:flex z-10">
            <div className="flex-auto flex-shrink-0">
              <TitleBreadcrumbs {...hiddenLinks} />
            </div>
            <div className="w-full text-right pt-1">
              <CallCTA />
            </div>
          </div>
        ) : (
          <>
            {/* Tablet and Mobile */}
            <div className="flex items-center justify-between md:pt-2 pb-2 md:pb-4 xl:hidden">
              <BeeLogo
                className="text-primary hidden md:block"
                width={64}
                height={43}
              />
              <div className="w-full md:text-center md:px-2">
                <CallCTA />
              </div>
              <div className="inline-flex mt-auto text-center">
                <AccountAvatar />
              </div>
            </div>
            {pathname !== "/" && (
              <Link to="/" className="display-block sm:hidden">
                <div className="flex">
                  <DashboardIcon className="mr-3 mt-2 text-primary-400" />
                  <div className="flex-row">
                    <p className="text-xs font-semibold">Back to</p>
                    <p className="text-xs font-semibold">Dashboard</p>
                  </div>
                </div>
              </Link>
            )}

            <TitleBreadcrumbs {...hiddenLinks} />
          </>
        )}
        <Router />
        <div className="w-full pt-4 text-xs text-center lg:text-left hidden">
          <span className="font-bold">Clearabee</span>
          {/* eslint-disable-next-line */}
          <span dangerouslySetInnerHTML={{ __html: " &copy; " }} />
          {`${copyrightYear}. All Rights Reserved.`}
        </div>
      </div>

      <BackgroundCircle className="portal-circle fixed bottom-0 left-0 pointer-events-none" />
      {!isLargeDesktop && <AccountBubble />}
    </div>
  );
};
