import { theme } from "../../../../theme";
import { StyleSheet } from "../../../../utils";

export type AllowedColorKeys = Extract<
  keyof typeof theme["colors"],
  "brand" | "accent" | "negative" | "warning" | "usp" | "positive" | "greyscale"
>;

export const getStyles = (color: AllowedColorKeys) =>
  StyleSheet({
    defaults: {
      ...theme.fontDefaults.small,
      width: "100%",
      boxSizing: "border-box",
    },
    container: {
      position: "relative",

      input: {
        paddingRight: theme.spacing.large,
      },
    },
    hideCalendar: {
      display: "none",
    },
    icon: {
      position: "absolute",
      right: theme.spacing.xsmall,
      top: theme.spacing.xsmall,
      cursor: "pointer",
      transition: "0.2s ease-in-out",
      transform: "translateY(10%) rotate(90deg)",
      pointerEvents: "none",
    },
    dropdownDisabled: {
      color: theme.colors.greyscale.lighter,
    },
    dropdownError: {
      color: theme.colors.negative.base,
    },
    dropdownSuccess: {
      color: theme.colors.positive.base,
    },
    up: {
      top: theme.spacing.xsmall,
      transform: "translateY(30%) rotate(270deg)",
    },
    notCollapsable: {
      position: "static",
      background: "none",

      ".UI-DayPicker": {
        padding: 0,
        margin: 0,
        boxShadow: "none",
      },
      ".UI-DayPicker-Day": {
        padding: "0.4rem",
      },
      ".UI-DayPicker-Month": {
        margin: 0,
        marginTop: theme.spacing.small,
      },
      ".UI-DayPicker-Month:first-of-type:not(:only-child)": {
        display: "block",
        paddingRight: theme.spacing.small,
      },
      ".UI-DayPicker-Month:last-of-type:not(:only-child)": {
        display: "block",
        paddingLeft: theme.spacing.small,
        border: `0px solid ${theme.colors[color].lightest}`,
        borderLeftWidth: "1px",
      },
    },
    dayPicker: {
      zIndex: theme.stack.inputDate,
      position: "absolute",
      background: theme.colors.light.base,
      ".UI-DayPicker-Months": {
        flexWrap: "nowrap",
      },
      ".UI-DayPicker": {
        ...theme.fontDefaults.small,
        padding: theme.spacing.medium,
        boxShadow: `0px 12px 30px -30px ${theme.colors.dark.base}`,
      },
      ".UI-DayPicker-NavButton": {
        top: theme.spacing.xsmall,
        width: theme.spacing.large,
        height: theme.spacing.large,
        backgroundImage:
          "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCA2IDkiPgogIDxwYXRoIGZpbGw9IiM3OTNCREEiIGQ9Ik0wLjU2MTkzNTkzMyw4Ljg5NjQ2Mjk0IEMwLjQzMDM2Nzk0OSw4Ljg5NjQ2Mjk0IDAuMjk4MjcxOTY0LDguODQ2MzUwOTUgMC4xOTc0NzE5NzYsOC43NDUzMTA5NiBDLTAuMDAzODM5OTk5NTQsOC41NDM5OTg5OCAtMC4wMDM4Mzk5OTk1NCw4LjIxODIyMzAyIDAuMTk3NDcxOTc2LDguMDE2OTExMDQgTDMuNzY2MzY3NTUsNC40NDgyNTU0NyBMMC4xOTc0NzE5NzYsMC44NzkzNTk4OTUgQy0wLjAwMzgzOTk5OTU0LDAuNjc4MDQ3OTE5IC0wLjAwMzgzOTk5OTU0LDAuMzUxOTgzOTU4IDAuMTk3NDcxOTc2LDAuMTUwOTU5OTgyIEMwLjM5ODc4Mzk1MiwtMC4wNTAzNTE5OTQgMC43MjQ4NDc5MTQsLTAuMDUwMzUxOTk0IDAuOTI2MTExODksMC4xNTA5NTk5ODIgTDQuODU4OTQzNDIsNC4wODM3OTE1MSBDNS4wNjAyNTU0LDQuMjg1MTAzNDkgNS4wNjAyNTU0LDQuNjExMTY3NDUgNC44NTg5NDM0Miw0LjgxMjE5MTQzIEwwLjkyNjExMTg5LDguNzQ1MjYyOTYgQzAuODI1NjQ3OTAyLDguODQ2MDYyOTUgMC42OTM3OTE5MTcsOC44OTY0NjI5NCAwLjU2MTkzNTkzMyw4Ljg5NjQ2Mjk0IFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC42KSIvPgo8L3N2Zz4K')",
      },
      ".UI-DayPicker-NavButton--next": {
        right: theme.spacing.xsmall,
      },
      ".UI-DayPicker-NavButton--prev": {
        left: theme.spacing.xsmall,
        transform: "rotate(180deg)",
      },
      ".UI-DayPicker-Day": {
        width: theme.spacing.medium,
        height: theme.spacing.medium,
      },
      ".UI-DayPicker-Day--today:not(.UI-DayPicker-Day--selected):not(.UI-DayPicker-Day--outside):not(.UI-DayPicker-Day--disabled)":
        {
          boxShadow: `0px 0px 0px 1px inset ${theme.colors[color].base}`,
          color: theme.colors.dark.base,
        },
      ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside)":
        {
          position: "relative",
          background: "none",
          borderRadius: 0,
          color: theme.colors.dark.base,
          "::before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "80%",
            backgroundColor: `${theme.colors.greyscale.base}11`,
            zIndex: -1,
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
          },
        },
      [`.UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside).start,
      .UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside).end`]:
        {
          borderRadius: theme.spacing.medium,
          color: theme.colors[color === "brand" ? "light" : "dark"].base,
          "::after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors[color].base,
            borderRadius: theme.spacing.medium,
            zIndex: -1,
            top: 0,
            left: 0,
          },
        },
      ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside):not(.start):first-of-type":
        {
          "::before": {
            borderTopLeftRadius: theme.spacing.medium,
            borderBottomLeftRadius: theme.spacing.medium,
          },
        },
      ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside):not(.end):last-of-type":
        {
          "::before": {
            borderTopRightRadius: theme.spacing.medium,
            borderBottomRightRadius: theme.spacing.medium,
          },
        },
      ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside).start":
        {
          "::before": {
            borderTopLeftRadius: theme.spacing.medium,
            borderBottomLeftRadius: theme.spacing.medium,
          },
        },
      ".UI-DayPicker-Day--selected:not(.UI-DayPicker-Day--disabled):not(.UI-DayPicker-Day--outside).end":
        {
          "::before": {
            borderTopRightRadius: theme.spacing.medium,
            borderBottomRightRadius: theme.spacing.medium,
          },
        },
      ".UI-DayPicker-Day--disabled": {
        pointerEvents: "none",
      },
      ".UI-DayPicker-Month:first-of-type:not(:only-child)": {
        display: "block",
      },
    },
  });
