import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Panel,
  Heading,
  Table,
  Icon,
  Text,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { useInstance } from "api/dsa";
import { Row } from "./components";

export const BulkAllocation = (): React.ReactElement => {
  const api = useInstance();
  const dsaExistingJobs = useRef<string[]>([]);
  const [rows, setRows] = useState(Array(1).fill(1));

  const deleteRow = (index: string): void => {
    setRows(rows.filter((i) => i !== index));
  };

  const { data: orderTypes, isLoading: isOrderTypesLoading } = useQuery(
    ["getOrderTypes"],
    async () => (await instance.catalogues.getOrderTypes()).data,
    {
      keepPreviousData: true,
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: subcontractors } = useQuery(
    ["subcontractors"],
    () => api.users.list("parentsOnly=true"),
    {
      keepPreviousData: true,
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const updateExistingJobStore = (reference: string) => {
    dsaExistingJobs.current.push(reference);
  };

  if (!subcontractors?.data || isOrderTypesLoading) {
    return (
      <div className="flex flex-col justify-center items-center text-center max-w-xs mx-auto">
        <Icon.Loading color="brand" className="mb-2" />
        <Text>
          Loading subcontractors. Try refreshing if it takes longer than 5
          seconds
        </Text>
      </div>
    );
  }

  return (
    <div className="py-8">
      <Panel className="mb-12">
        <Heading color="brand" level={4}>
          Bulk Allocation
        </Heading>
      </Panel>
      <Table>
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "50%" }} />
          <col style={{ width: "45%" }} />
        </colgroup>
        <Table.Header fontSize="small" headings={["", "", ""]} />
        <Table.Body>
          {rows.map((row) => (
            <Row
              key={row}
              orderTypes={orderTypes}
              deleteRow={() => deleteRow(row)}
              subcontractors={subcontractors.data}
              updateExistingJobStore={updateExistingJobStore}
            />
          ))}
        </Table.Body>
      </Table>
      <div className="flex justify-end mt-4">
        <Button
          type="button"
          size="small"
          onClick={() => setRows([...rows, Math.random()])}
        >
          Add Job
        </Button>
      </div>
    </div>
  );
};
