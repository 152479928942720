import React from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useInstance } from "api/dsa";
import {
  useModal,
  Form,
  Field,
  Input,
  Button,
  Gallery,
  Heading,
  Panel,
} from "@clearabee/ui-library";
import {
  validationSchema as dsaJobValueValidationSchema,
  getSubcontractorModalInitialValues,
} from "../validation";
import { toasts } from "../../../../helpers";
import "../../styles/create.css";
import { AdditionalInfoLine } from "./additionalInfoLine";
interface SubcontratorSubmittedFieldsProps {
  bcId: string;
}
export const SubcontractorSubmittedFields = ({
  bcId,
}: SubcontratorSubmittedFieldsProps): React.ReactElement => {
  const dsaApi = useInstance();
  const [t] = useTranslation("jobs");
  const [SubcontractorModal, showSubcontractorModal] = useModal();

  const { data: dsaData, refetch: refetchDsa } = useQuery<
    Awaited<ReturnType<typeof dsaApi.jobs.list>>["data"][number]
  >(
    ["listJobs", bcId],
    async () => (await dsaApi.jobs.list(`bigChangeId=${bcId}`)).data[0],
    {
      onError: () => {
        toasts.error({
          message: t("update.subcontractor.errors.loading"),
        });
      },
      enabled: !!bcId,
    },
  );

  const { mutate: mutateDsa, isLoading: isLoadingMutateDsa } = useMutation(
    async ({
      finalAmount,
      notes,
    }: ReturnType<typeof getSubcontractorModalInitialValues>) => {
      return await dsaApi.jobs.update(String(dsaData?._id), {
        invoiceDetails: {
          finalAmount,
          notes,
        },
      });
    },
    {
      onSuccess: () => {
        showSubcontractorModal(false);
        refetchDsa();

        toasts.success({
          message: t("update.subcontractor.success.saved"),
        });
      },
      onError: () => {
        toasts.error({
          message: t("update.subcontractor.errors.generic"),
        });
      },
    },
  );

  /**
   * Calculate total weight out of all waste types
   */
  const getTotalWeight = (
    data: Awaited<
      ReturnType<typeof dsaApi.jobs.list>
    >["data"][number]["worksheets"],
  ): string => {
    if (!data || !data.wasteTypes) return "";

    const response = data.wasteTypes.reduce(
      (prev, current) => prev + Number(current.weight),
      0,
    );
    return String(response);
  };

  return (
    <>
      {!!dsaData && (
        <Panel>
          <div className="mb-3">
            <Heading color="brand" level={5}>
              {t("headings.subcontractorData")}
            </Heading>
          </div>

          <div className="grid md:grid-cols-2">
            {/* Contractor Details Section */}
            <div>
              <Heading color="brand" level={5}>
                {t("update.subcontractor.heading.contractor")}
              </Heading>
              <AdditionalInfoLine
                label={t("update.subcontractor.label.contractorName")}
              >
                {dsaData?.user?.email}
              </AdditionalInfoLine>
              <AdditionalInfoLine
                label={t("update.subcontractor.label.amount")}
              >
                {dsaData?.invoiceDetails?.amount}
              </AdditionalInfoLine>
              <AdditionalInfoLine
                label={t("update.subcontractor.label.finalAmount")}
              >
                {dsaData?.invoiceDetails?.finalAmount}
              </AdditionalInfoLine>
              <AdditionalInfoLine label={t("update.subcontractor.label.notes")}>
                {dsaData?.invoiceDetails?.notes}
              </AdditionalInfoLine>
              <Button
                type="button"
                size="medium"
                className="my-6"
                onClick={() => showSubcontractorModal(true)}
              >
                {t("update.subcontractor.button.updateJobValue")}
              </Button>
            </div>
            {/* Waste Data Section */}
            <div>
              <Heading color="brand" level={5}>
                {t("update.subcontractor.heading.waste")}
              </Heading>
              <AdditionalInfoLine
                label={t("update.subcontractor.label.weight")}
              >
                {getTotalWeight(dsaData?.worksheets)}
              </AdditionalInfoLine>
              <AdditionalInfoLine
                label={t("update.subcontractor.label.volume")}
              >
                {dsaData?.worksheets?.volume}
              </AdditionalInfoLine>
              <AdditionalInfoLine label={t("update.subcontractor.label.tip")}>
                {dsaData?.tippingDetails?.tip?.name}
              </AdditionalInfoLine>
              <AdditionalInfoLine
                label={t("update.subcontractor.label.tipLicence")}
              >
                {dsaData?.tippingDetails?.tip?.license}
              </AdditionalInfoLine>
            </div>
            {/* Failed Job Section */}
            {(dsaData?.failedJob?.failureReason ||
              dsaData?.failedJob?.additionalInfo ||
              dsaData?.failedJob?.customerSignature ||
              dsaData?.failedJob?.customerCallImage) && (
              <>
                <Heading
                  color="brand"
                  className="col-start-1 col-end-3"
                  level={5}
                >
                  {t("update.subcontractor.heading.failedJobData")}
                </Heading>
                {dsaData?.failedJob?.failureReason && (
                  <AdditionalInfoLine
                    label={t("update.subcontractor.label.failureReason")}
                  >
                    {dsaData?.failedJob?.failureReason}
                  </AdditionalInfoLine>
                )}
                {dsaData?.failedJob?.additionalInfo && (
                  <AdditionalInfoLine
                    label={t(
                      "update.subcontractor.label.additionalInformation",
                    )}
                  >
                    {dsaData?.failedJob?.additionalInfo}
                  </AdditionalInfoLine>
                )}
                {dsaData?.failedJob?.customerSignature && (
                  <AdditionalInfoLine
                    label={t(
                      "update.subcontractor.label.images.customerSignature",
                    )}
                  >
                    <Gallery
                      items={[
                        { source: dsaData?.failedJob?.customerSignature },
                      ]}
                    />
                  </AdditionalInfoLine>
                )}
                {!!dsaData?.failedJob?.failureSitePhoto?.length && (
                  <AdditionalInfoLine
                    label={t(
                      "update.subcontractor.label.images.failedSiteImages",
                    )}
                  >
                    <Gallery
                      items={dsaData?.failedJob?.failureSitePhoto?.map(
                        (image) => ({ source: image }),
                      )}
                    />
                  </AdditionalInfoLine>
                )}
                {!!dsaData?.failedJob?.customerCallImage?.length && (
                  <AdditionalInfoLine
                    label={t(
                      "update.subcontractor.label.images.customerCallImages",
                    )}
                  >
                    <Gallery
                      items={dsaData?.failedJob?.customerCallImage?.map(
                        (image) => ({ source: image }),
                      )}
                    />
                  </AdditionalInfoLine>
                )}
              </>
            )}

            {/* Images Section */}
            <div className="col-start-1 col-end-3 mt-6">
              <Heading
                color="brand"
                className="col-start-1 col-end-3"
                level={5}
              >
                {t("update.subcontractor.heading.images")}
              </Heading>
              <div className="grid md:grid-cols-2">
                <AdditionalInfoLine
                  label={t("update.subcontractor.label.images.beforeImages")}
                >
                  {!!dsaData?.collectionDetails.beforeImages?.length && (
                    <Gallery
                      items={dsaData?.collectionDetails.beforeImages?.map(
                        (image) => ({ source: image }),
                      )}
                    />
                  )}
                </AdditionalInfoLine>
                <AdditionalInfoLine
                  label={t("update.subcontractor.label.images.afterImages")}
                >
                  {!!dsaData?.collectionDetails.afterImages?.length && (
                    <Gallery
                      items={dsaData?.collectionDetails.afterImages?.map(
                        (image) => ({ source: image }),
                      )}
                    />
                  )}
                </AdditionalInfoLine>
                {!!dsaData?.wasteDetails.wasteTransferNote?.length && (
                  <AdditionalInfoLine
                    label={t(
                      "update.subcontractor.label.images.wasteTransferNote",
                    )}
                  >
                    <Gallery
                      items={dsaData?.wasteDetails.wasteTransferNote?.map(
                        (image) => ({ source: image }),
                      )}
                    />
                  </AdditionalInfoLine>
                )}
                {!!dsaData?.tippingDetails?.weighbridgeTicket?.length && (
                  <AdditionalInfoLine
                    label={t(
                      "update.subcontractor.label.images.weighbridgeTicket",
                    )}
                  >
                    <Gallery
                      items={dsaData?.tippingDetails?.weighbridgeTicket?.map(
                        (image) => ({
                          source: image,
                        }),
                      )}
                    />
                  </AdditionalInfoLine>
                )}

                {!!dsaData?.invoiceDetails?.image?.length && (
                  <AdditionalInfoLine
                    label={t("update.subcontractor.label.images.invoice")}
                  >
                    <Gallery
                      items={dsaData?.invoiceDetails?.image?.map((image) => ({
                        source: image,
                      }))}
                    />
                  </AdditionalInfoLine>
                )}
                <AdditionalInfoLine
                  label={t(
                    "update.subcontractor.label.images.customerSignature",
                  )}
                >
                  {!!dsaData?.worksheets?.customerSignature && (
                    <Gallery
                      items={[
                        {
                          source: dsaData?.worksheets?.customerSignature,
                        },
                      ]}
                    />
                  )}
                </AdditionalInfoLine>
                <AdditionalInfoLine
                  label={t("update.subcontractor.label.images.driverSignature")}
                >
                  {!!dsaData?.worksheets?.driverSignature && (
                    <Gallery
                      items={[{ source: dsaData?.worksheets?.driverSignature }]}
                    />
                  )}
                </AdditionalInfoLine>
              </div>
            </div>
          </div>
          {/* Modal */}
          <SubcontractorModal
            heading={t("update.subcontractor.heading.updateJobValue")}
            width={400}
            onClose={() => showSubcontractorModal(false)}
            styles={{ textAlign: "center" }}
          >
            <Form
              validationSchema={dsaJobValueValidationSchema}
              onSubmit={(values) => {
                mutateDsa(values);
              }}
              initialValues={getSubcontractorModalInitialValues({
                finalAmount: dsaData?.invoiceDetails?.finalAmount,
                notes: dsaData?.invoiceDetails?.notes,
              })}
            >
              <Field name="finalAmount" styles={{ textAlign: "left" }}>
                {({ field }) => <Input.Text {...field} />}
              </Field>
              <Field name="notes" styles={{ textAlign: "left" }}>
                {({ field }) => (
                  <Input.Textarea
                    {...field}
                    slim
                    placeholder={t("update.subcontractor.label.notes")}
                  />
                )}
              </Field>

              <div style={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  disabled={isLoadingMutateDsa}
                  variant="outline"
                  size="medium"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </SubcontractorModal>
        </Panel>
      )}
    </>
  );
};
