import React from "react";

export const DragAndDrop = (): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="198"
      height="126"
      viewBox="0 0 198 126"
    >
      <defs>
        <path
          id="drag-n-drop-a"
          d="M0.034 0.033L31.185 0.033 31.185 31.185 0.034 31.185z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <rect
          width="183"
          height="109.5"
          stroke="#6B37BB"
          strokeWidth="1.5"
          rx="15"
        ></rect>
        <rect
          width="183"
          height="109.5"
          x="13.5"
          y="15"
          fill="#8645EA"
          rx="15"
        ></rect>
        <g transform="translate(81 45) translate(17.199 17.199)">
          <mask id="drag-n-drop-b" fill="#fff">
            <use xlinkHref="#drag-n-drop-a"></use>
          </mask>
          <path
            fill="#FFF"
            d="M22.752 30.77l-8.27-8.27-4.01 4.01a1.416 1.416 0 01-2.346-.554L.107 1.9A1.417 1.417 0 011.9.107l24.056 8.02a1.416 1.416 0 01.554 2.346l-4.01 4.009 8.27 8.27c.553.554.553 1.45 0 2.004l-6.014 6.014a1.417 1.417 0 01-2.004 0"
            mask="url(#drag-n-drop-b)"
          ></path>
        </g>
        <path
          fill="#FFF"
          d="M18.428 11.34a1.417 1.417 0 01-1.418-1.417V1.418a1.417 1.417 0 112.835 0v8.505c0 .783-.634 1.417-1.417 1.417m-7.016 2.076L5.397 7.402a1.417 1.417 0 112.005-2.005l6.014 6.015a1.417 1.417 0 11-2.004 2.004M5.397 31.458a1.417 1.417 0 010-2.005l6.015-6.014a1.417 1.417 0 112.004 2.004l-6.014 6.015a1.417 1.417 0 01-2.005 0M23.44 13.416a1.417 1.417 0 010-2.004l6.014-6.015a1.417 1.417 0 112.005 2.005l-6.015 6.014a1.417 1.417 0 01-2.004 0M9.923 19.845H1.418a1.417 1.417 0 110-2.835h8.505a1.417 1.417 0 110 2.835"
          transform="translate(81 45)"
        ></path>
      </g>
    </svg>
  );
};
