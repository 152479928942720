import * as Yup from "yup";
import {
  stringRequired,
  stringNumberOnly,
  fieldRequired,
} from "validation/common";

export const worksheetInitialValues = {
  name: "",
  orderTypeIds: [],
  statusId: "",
  includeForSnsEvents: false,
};

export const worksheetValidationSchema = Yup.object().shape({
  name: stringRequired,
  orderTypeIds: Yup.array(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.number(),
    }),
  )
    .min(1)
    .required()
    .typeError(fieldRequired),
  statusId: stringNumberOnly,
  includeForSnsEvents: Yup.boolean(),
});
