import React, { useState } from "react";
import css from "@emotion/css";
import {
  Icon,
  Panel,
  Text,
  UnstyledButton,
  Box,
  theme,
} from "@clearabee/ui-library";
import { styles } from "./WorksheetQuestion.styles";
import type { TQuestion } from "../../../worksheetTypes";

interface QuestionProps {
  question: TQuestion;
  level?: number;
  onClick: (question: TQuestion) => void;
}

export const WorksheetQuestion = ({
  question,
  level,
  onClick,
}: QuestionProps): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [panelColor, setPanelColor] = useState("#FFFFFF");

  const indent = (level ?? 0) * 50;

  return (
    <>
      <div className="flex flex-row items-center">
        <Panel
          styles={css(styles.questionPanel, {
            marginLeft: `${indent}px`,
            backgroundColor: panelColor,
          })}
          onMouseEnter={() => setPanelColor("#FBFBFB")}
          onMouseLeave={() => setPanelColor("#FFFFFF")}
        >
          {/* Expand/Collapse Button */}
          {!!question.children?.length ? (
            <UnstyledButton
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              className="w-4"
            >
              <Icon.Chevron
                size="small"
                color="brand.base"
                styles={{
                  ...(isExpanded && { transform: "rotate(90deg)" }),
                  marginTop: isExpanded ? "-5px" : "0px",
                }}
              />
            </UnstyledButton>
          ) : (
            <div className="w-4" />
          )}
          {/* Question */}
          <UnstyledButton
            styles={styles.panelButton}
            onClick={() => onClick(question)}
          >
            {/* POSITION */}
            <Box className="flex flex-row mr-3 items-center">
              <Icon.DirectionPointer color="greyscale.lighter" />
              <Text styles={{ color: theme.colors.greyscale.lighter }}>
                {question.position}
              </Text>
            </Box>

            {/* QUESTION & DISPLAY CONDITION */}
            <div className="flex flex-1 justify-between">
              <Text className="font-black">{question.question}</Text>
              {!!question.displayCondition && (
                <Text
                  className="font-normal pr-12"
                  styles={{ color: theme.colors.greyscale.lighter }}
                >
                  {question.displayCondition.toUpperCase()}
                </Text>
              )}
            </div>
          </UnstyledButton>
        </Panel>
      </div>
      {!!question.children?.length && isExpanded && (
        <div>
          {question.children.map((child, index) => {
            return (
              <WorksheetQuestion
                key={`${child.question}-${index}`}
                question={child}
                level={(level ?? 0) + 1}
                onClick={onClick}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
