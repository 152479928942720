import React, { useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { assignCatalogueToCompanies, createCatalogue } from "api";
import { PostCatalogue } from "api/types";
import { arrayChunk } from "helpers";
import {
  Button,
  displayErrorMessage,
  Icon,
  Modal,
  theme,
} from "@clearabee/ui-library";
import {
  CatalogueFormValues,
  CreateUpdateCatalogueForm,
  CatalogueEditor,
  CatalogueEditorContext,
} from "../components";
import { SideBar } from "../components/sideBar";
import { useContext } from "react";
import { createCatalogueItems } from "api/catalogues";
import { RouteLink } from "components/core";
import { CatalogueTabs } from "../components/catalogueTabs";
import {
  parseFormToCatalogue,
  StateData,
} from "../components/createUpdateCatalogueForm";
import { calculateNewItems, flattenCatalogue } from "../helpers";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

const CreateCatalogueWrap = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const valuesRef = useRef<CatalogueFormValues>();
  const { items, companies, catalogue } = useContext(CatalogueEditorContext);

  const { mutate, isSuccess, isLoading, error, reset } = useMutation(
    async (catalogue: PostCatalogue) => {
      const flatItems = calculateNewItems(
        [],
        flattenCatalogue(items, catalogue.id),
      );

      await createCatalogue(catalogue);

      if (flatItems.length > 0) {
        await Promise.all(
          arrayChunk(flatItems, 15).map((items) =>
            createCatalogueItems(catalogue.id, items),
          ),
        );
      }

      if (companies && companies.length > 0) {
        await assignCatalogueToCompanies(catalogue.id, companies);
      }
    },
  );

  const handleSubmit = (values: CatalogueFormValues) => {
    valuesRef.current = values;

    mutate({
      ...parseFormToCatalogue(values),
      id: values.id!,
    });
  };

  const initialValues: CatalogueFormValues = {
    id: "",
    title: catalogue?.title ?? "",
    description: catalogue?.description ?? "",
    public: catalogue?.public ?? false,
    hasVat: catalogue?.hasVat ?? false,
    vatRate: catalogue?.vatRate?.toString() ?? "",
    active: catalogue?.active ?? false,
    applySkipCharge: catalogue?.applySkipCharge ?? true,
  };

  return (
    <>
      <Link to="/catalogues" className="inline-flex items-center mb-5">
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues"),
        })}
      </Link>
      {displayErrorMessage(error, (props) => (
        <DefaultErrorComponent
          {...props}
          styles={{
            marginTop: theme.spacing.small,
            marginBottom: theme.spacing.small,
            maxWidth: theme.screens.small,
          }}
          onClose={reset}
        />
      ))}
      <CreateUpdateCatalogueForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        heading={translate("common:routes.catalogues/create")}
        disableSubmit={isLoading || isSuccess}
        includeId
        isLoading={isLoading}
      />
      <SideBar />
      <CatalogueTabs isLoading={isLoading} />

      {isSuccess && valuesRef.current && (
        <Modal
          heading={translate("modal.catalogueCreate.heading")}
          width={500}
          actions={
            <>
              <RouteLink
                href={`/catalogues/update/${valuesRef.current.id}`}
                state={{
                  catalogue: {
                    ...parseFormToCatalogue(valuesRef.current),
                    id: valuesRef.current.id!,
                    companies: companies?.map((company) => ({ code: company })),
                    items,
                  },
                }}
              >
                <Button
                  size="medium"
                  color="negative"
                  type="button"
                  className="mr-3"
                  as="a"
                >
                  {translate("modal.catalogueCreate.edit")}
                </Button>
              </RouteLink>
              <RouteLink href="/catalogues">
                <Button
                  size="medium"
                  color="accent"
                  type="button"
                  className="ml-3"
                  as="a"
                >
                  {translate("common:routes.catalogues")}
                </Button>
              </RouteLink>
            </>
          }
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("modal.catalogueCreate.message")}
          </div>
        </Modal>
      )}
    </>
  );
};

export const CreateCatalogue = (): React.ReactElement => {
  const location = useLocation<StateData | undefined>();
  const history = useHistory();
  const stateRef = useRef<StateData | undefined>();

  useEffect(() => {
    // when passing a state to another component, it will stay stored
    // we need to remove it as it will cause issues on refresh
    if (location.state) {
      history.replace({ ...location, state: undefined });
    }
  }, []);

  if (location.state) {
    stateRef.current = location.state;
  }

  return (
    <CatalogueEditor catalogue={stateRef.current?.catalogue} blankCatalogue>
      <CreateCatalogueWrap />
    </CatalogueEditor>
  );
};
