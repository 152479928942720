import React, { useEffect, useState } from "react";
import {
  Heading,
  Icon,
  Box,
  UserStylesProps,
  UnstyledButton,
  theme,
} from "@clearabee/ui-library";
import { styles } from "./PivotTab.styles";

interface PivotTabProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  options?: { open: boolean };
  label: string;
}

export const PivotTab = ({
  options = { open: true },
  label,
  styles: userStyles,
  ...rest
}: PivotTabProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(options.open);

  useEffect(() => {
    setIsOpen(options.open);
  }, [options]);

  return (
    <Box styles={userStyles}>
      <Box styles={styles.panel}>
        <UnstyledButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Heading
            level={4}
            color="brand"
            className="w-88 flex items-center"
            styles={{ marginTop: theme.spacing.xlarge }}
          >
            {label}
            <Icon.Chevron
              color="brand"
              styles={isOpen ? styles.chevronOpen : styles.chevronClosed}
              size="small"
            />
          </Heading>
        </UnstyledButton>
      </Box>
      <div style={{ display: isOpen ? "block" : "none" }} {...rest} />
    </Box>
  );
};
