import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Text, TextProps, UserStylesProps } from "@clearabee/ui-library";

interface TimerProps extends UserStylesProps<TextProps> {
  pendingTimestamp: Date;
}

interface TimeLeft {
  minutes: number;
  seconds: number;
}

const calculateTimeLeft = (pendingDate: Date) => {
  const toDate = dayjs(pendingDate).add(5, "minute");
  const difference = +toDate - +new Date();
  let timeLeft = {
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const Timer = ({
  pendingTimestamp,
  ...rest
}: TimerProps): React.ReactElement => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(
    calculateTimeLeft(pendingTimestamp),
  );

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(pendingTimestamp));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    const value = timeLeft[interval as keyof TimeLeft];
    let response = value.toString().padStart(2, "0") || "00";

    if (index !== Object.keys(timeLeft).length - 1) {
      response += ":";
    }

    return response;
  });

  return <Text {...rest}>{timerComponents}</Text>;
};
