import axios from "axios";
import { PaymentLink } from "models";

import { Basket, BasketItem, PresignedUrl } from "../models/basket";

export interface CreateBasketPayload
  extends Partial<
    Pick<
      Basket,
      | "contact"
      | "description"
      | "accessInformation"
      | "deliveryOption"
      | "deliveryCharge"
      | "timeslot"
      | "meta"
      | "sicCode"
      | "existingOrderRef"
    >
  > {
  catalogueId: string;
  items: Array<Pick<BasketItem, "sku"> & { qty: BasketItem["quantity"] }>;
  companyCode: Required<Basket["companyCode"]>;
  deliveryAddress: Required<Basket["deliveryAddress"]>;
  date: Required<Basket["date"]>;
}

export const createBasket = async (
  basket: CreateBasketPayload,
): Promise<Basket> => {
  const {
    catalogueId,
    companyCode,
    items,
    deliveryAddress,
    date,
    contact,
    description,
    accessInformation,
    deliveryOption,
    deliveryCharge,
    timeslot,
    meta,
    existingOrderRef,
  } = basket;
  try {
    const basketData = {
      catalogueId,
      companyCode,
      items,
      deliveryAddress,
      date,
      contact,
      description,
      accessInformation,
      deliveryOption,
      deliveryCharge,
      timeslot,
      meta,
      existingOrderRef,
    };
    // console.log("@API: POST /catalogues/baskets", basketData)

    const { data } = await axios.post("/catalogues/baskets", basketData);

    return data;
  } catch (error) {
    throw error;
  }
};

export type BasketData = Partial<
  Pick<
    Basket,
    | "description"
    | "accessInformation"
    | "contact"
    | "meta"
    | "deliveryOption"
    | "timeslot"
    | "deliveryCharge"
    | "sicCode"
  >
> & {
  items: Array<Pick<BasketItem, "sku"> & { qty: BasketItem["quantity"] }>;
};

export const updateBasket = async (
  basketId: Basket["basketToken"],
  basketData: BasketData,
): Promise<Basket> => {
  try {
    // console.log(`@API: PATCH /catalogues/baskets/${basketId}`, basketData)
    const { data } = await axios.patch(
      `/catalogues/baskets/${basketId}`,
      basketData,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const processBasketForInvoiceCustomer = async (
  basketId: Basket["basketToken"],
  purchaseOrderNumber: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const { data } = await axios.post(
      `/catalogues/baskets/${basketId}/invoice`,
      {
        purchaseOrderNumber,
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getBasketImagePresignedUrl = async (
  basketToken: Basket["basketToken"],
  filename: string,
  type = "receipt",
): Promise<PresignedUrl> => {
  try {
    const { data } = await axios.put(
      `/catalogues/baskets/${basketToken}/${filename}?type=${type}`,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUploadedFile = async (
  basketId: Basket["basketToken"],
  filename: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    const { data } = await axios.delete(
      `/catalogues/baskets/${basketId}/${filename}`,
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getBasket = async (basketId: string): Promise<Basket> => {
  try {
    const { data } = await axios.get(`/catalogues/baskets/${basketId}`);

    return data;
  } catch (error) {
    throw error;
  }
};

export const createBasketPaymentLink = async (
  token: string,
): Promise<PaymentLink> => {
  const res = await axios.put(`/catalogues/baskets/${token}/payment-link`);
  return res.data;
};
