/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const BuzzingBee: React.FC<TGenericImage> = ({
  className,
  width = 1500,
  height = 1000,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 200 1680 1181"
      width={width}
      height={height}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#979797"
          strokeDasharray="0 7.71724746"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.786"
          d="M164.65 180.478c11.831 193.487 77.097 291.43 195.796 293.828 64.031 1.294 108.085-29.743 159.39-18.438 96.536 21.272 90.229 56.557 189.016 80.494 64.375 15.6 97.511-1.484 119.964-21.94 37.858-34.491 34.171-91.916 17.263-115.675-26.936-37.849-87.457-53.088-134.033-16.953-46.577 36.136-75.252 139.11 72.952 207.075 98.803 45.309 184.523 43.746 257.16-4.689 95.763-50.846 167.943-2.717 216.54 144.389 48.597 147.105 142.448 224.558 281.552 232.357"
          opacity="0.7"
        />
        <g fill="#793BDA" className="3xl:hidden">
          <path d="M592.11 473.226c-2.985-.993-5.122-3.114-5.334-5.716-.211-2.584 1.546-5.022 4.355-6.483-.422 1.965-.533 4.045-.368 6.167a21.702 21.702 0 001.348 6.032m28.697-12.571c-.401.822-1.093 1.344-1.683 1.333-1.225 1.545-3.166 1.898-4.328 1.97.14.407.224.814.253 1.257a5.87 5.87 0 01-5.383 6.353 5.813 5.813 0 01-2.464-.32 3.652 3.652 0 00-1.893-.31c-.664.043-1.276.272-1.835.612-.094.072-.206.143-.3.215a1.815 1.815 0 01-.316.198 11.76 11.76 0 01-1.945-5.6 11.584 11.584 0 011.025-5.824c.11.02.238.096.348.135.09.075.22.114.329.153.585.269 1.228.39 1.91.329a3.636 3.636 0 001.818-.63 5.632 5.632 0 012.353-.696 5.865 5.865 0 014.306 1.385c.798-.335 2.213-1.14 2.828-2.529-.36-.412-.476-1.188-.26-2.013.315-1.064 1.102-1.787 1.78-1.59.678.196.973 1.215.678 2.297-.237.825-.782 1.46-1.337 1.598-.617 1.537-2.016 2.47-2.983 2.95.288.337.54.71.735 1.101.922-.002 2.805-.226 3.917-1.57-.23-.502-.162-1.237.183-1.95.497-.987 1.428-1.542 2.049-1.235.62.324.73 1.395.215 2.381" />
          <path d="M599.859 459.18c-.887.83-1.526 1.903-1.78 3.093a14.923 14.923 0 00-.28 4.345 15.007 15.007 0 002.167 6.655c-.871.26-1.796.429-2.755.504a9.25 9.25 0 01-1.42.047 19 19 0 01-1.904-6.889 18.49 18.49 0 01.294-5.12c.178-.89-.12-1.809-.74-2.472l-5.432-5.819a5.422 5.422 0 01.248-7.68c2.192-2.025 5.655-1.907 7.698.267.361.393.668.804.899 1.27.266-.439.605-.856.998-1.218 2.192-2.025 5.619-1.908 7.68.267a5.439 5.439 0 01-.266 7.68l-5.407 5.07" />
        </g>
      </g>
    </svg>
  );
};
