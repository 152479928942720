import React from "react";
import { Panel } from "@clearabee/ui-library";
import { theme } from "@clearabee/ui-library-base";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { EditableItem } from ".";

interface TopLevelLayerProps {
  isOver?: boolean;
  draggingItem?: EditableItem;
  items?: EditableItem[];
}

export const TopLevelLayer = ({
  isOver,
  draggingItem,
  items,
}: TopLevelLayerProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const itemAlreadyExists = items?.some(({ sku }) => sku === draggingItem?.sku);
  const isCategory = draggingItem?.type === "category";
  const canDrop = isCategory && !itemAlreadyExists;

  return (
    <div
      className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center pointer-events-none"
      css={css(
        isOver
          ? {
              margin: theme.spacing.xlarge,
              marginTop: theme.spacing.xlarge3,
              border: `4px dotted ${
                canDrop
                  ? theme.colors.positive.base
                  : theme.colors.negative.base
              }`,
              background: canDrop
                ? "rgba(255, 255, 255, 0.5)"
                : "rgba(0, 0, 0, 0.3)",
            }
          : {
              opacity: 0,
            },
      )}
    >
      {!canDrop && (
        <Panel
          styles={{
            backgroundColor: theme.colors.negative.base,
            fontWeight: "bold",
            color: theme.colors.light.base,
          }}
        >
          {itemAlreadyExists &&
            translate("catalogueEditing.errors.categoryAlreadyExists", {
              title: draggingItem?.title,
            })}
          {!isCategory && translate("catalogueEditing.errors.categoryOnly")}
        </Panel>
      )}
    </div>
  );
};
