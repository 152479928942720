import { useEffect, useState } from "react";

/**
 * Screen width configuration.
 * Identical to breakpoints found in tailwind.config.js
 */
const config = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1500,
  "3xl": 2000,
};
interface UseScreenWidthReturn {
  isLargeDesktop: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}
/**
 * useScreenWidth hook.
 * Use to determine the type of device the app is being used on.
 */
export const useScreenWidth = (): UseScreenWidthReturn => {
  /**
   * Screen condition state.
   */
  const [isLargeDesktop, setIsLargeDesktop] = useState(
    window.innerWidth >= config.xl,
  );
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= config.lg);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth < config.lg && window.innerWidth >= config.md,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < config.md);

  const changeWindowState = () => {
    setIsLargeDesktop(window.innerWidth >= config.xl);
    setIsDesktop(window.innerWidth >= config.lg);
    setIsTablet(
      window.innerWidth < config.lg && window.innerWidth >= config.md,
    );
    setIsMobile(window.innerWidth < config.md);
  };

  useEffect(() => {
    window.addEventListener("resize", changeWindowState);
    return () => window.removeEventListener("resize", changeWindowState);
  }, []);

  return {
    isLargeDesktop,
    isDesktop,
    isTablet,
    isMobile,
  };
};
