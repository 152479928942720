import React from "react";
import {
  Button,
  Field,
  Form,
  formatCurrency,
  Heading,
  Icon,
  Input,
  Message,
  Table,
  useModal,
} from "@clearabee/ui-library";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { styles } from "./lookupItemsModal.styles";

export const LookupItemsModal = ({
  companyCode,
}: {
  companyCode: string;
}): React.ReactElement => {
  const [LookupModal, setLookupModal] = useModal();
  const [translate] = useTranslation("jobs");

  const { data, isLoading } = useQuery(
    ["getAdminLookupCatalogue", companyCode],
    async () =>
      (await instance.catalogues.getCatalogueByCompanyCode(companyCode)).data,
    { enabled: !!companyCode, retry: 0 },
  );

  type CatalogueItemsType = ApiResponseData<
    typeof instance.catalogues.getCatalogueByCompanyCode
  >["items"];

  const itemList = [] as CatalogueItemsType;
  data?.items.forEach((item) =>
    item.children?.forEach((item) => itemList.push(item)),
  );

  return (
    <>
      <Button
        styles={styles.button}
        color="accent"
        size="small"
        type="button"
        onClick={() => setLookupModal(true)}
      >
        {translate("create.lookupModal.button")}
      </Button>

      <LookupModal width={1000} onClose={() => setLookupModal(false)}>
        {isLoading && (
          <Icon.Loading color="brand" styles={styles.loadingIcon} />
        )}
        {!isLoading && !data?.id && (
          <Message
            color="negative"
            styles={{
              justifyContent: "center",
              flex: 1,
            }}
          >
            {translate("create.lookupModal.notAssigned")}
          </Message>
        )}

        {!!itemList?.length && (
          <>
            <Heading color="brand" styles={styles.heading} fontSize="xlarge2">
              {data?.id}
            </Heading>
            <Form
              onSubmit={(values) => console.log(values)}
              renderFormElement={false}
              initialValues={itemList.flatMap((item) => ({
                title: item.title,
                parentSku: item.parentSku,
                price: item.price ?? 0,
                quantity: 0,
              }))}
            >
              {({ values }) => {
                const totalValue =
                  values
                    .flatMap((item) => item.quantity * item.price)
                    .reduce((a, b) => a + b, 0) ?? 0;

                return (
                  <>
                    <div style={styles.scrollView}>
                      {!isLoading && !!values.length && (
                        <Table>
                          <colgroup>
                            <col style={{ width: "50%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "20%" }} />
                            <col style={{ width: "10%" }} />
                          </colgroup>
                          <Table.Header
                            fontSize="small"
                            headings={[
                              translate("create.lookupModal.headings.title"),
                              translate(
                                "create.lookupModal.headings.parentSku",
                              ),
                              translate("create.lookupModal.headings.quantity"),
                              translate("create.lookupModal.headings.price"),
                            ]}
                          />
                          <Table.Body>
                            {values.map((item, index) => (
                              <Table.Row key={`${item.title}${index}`}>
                                <Table.Cell styles={styles.cell}>
                                  {item.title}
                                </Table.Cell>
                                <Table.Cell styles={styles.cell}>
                                  {item.parentSku}
                                </Table.Cell>
                                <Table.Cell styles={styles.cell}>
                                  <Field name={`[${index}].quantity`}>
                                    {({ field }) => (
                                      <Input.Quantity {...field} />
                                    )}
                                  </Field>
                                </Table.Cell>
                                <Table.Cell.Currency
                                  styles={styles.cell}
                                  price={item.price ?? 0}
                                />
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      )}
                    </div>
                    <Heading
                      level={4}
                      styles={styles.total}
                      fontSize="xlarge"
                      color="brand"
                    >
                      {translate("create.lookupModal.total")}:{" "}
                      {formatCurrency(totalValue)}
                    </Heading>
                  </>
                );
              }}
            </Form>
          </>
        )}
      </LookupModal>
    </>
  );
};
