import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  payloadModal: {
    maxHeight: 500,
    overflow: "hidden",
    overflowY: "scroll",
    background: theme.colors.light.base,
    textAlign: "left",
    "::before": {
      background: theme.colors.light.base,
    },
  },
  copyButton: {
    display: "flex",
    gap: theme.spacing.xsmall2,
  },
  json: {
    ...theme.fontDefaults.xsmall,
    overflow: "scroll",
    padding: theme.spacing.small,
    marginTop: theme.spacing.large,
    borderRadius: theme.spacing.xsmall,
  },
});
