import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import queryString from "query-string";
import { Twemoji } from "react-emoji-render";
import { IUserSso } from "@clearabee/api-schemas";
import { instance } from "@clearabee/ui-sdk";
import { useAuthContext, useLocalStorage } from "../../hooks";
import { toasts } from "../../helpers";
import {
  Box,
  UnstyledButton,
  theme,
  Text,
  Icon,
  Heading,
  Input,
} from "@clearabee/ui-library";
import { FormInput, FormButton } from "../core";
import { InputEmail, InputPassword, Button, BoxButton } from "./components";
import { login, sso } from "./validation";

export const Login: React.FC = () => {
  const [t] = useTranslation("auth");
  const Emoji = Twemoji;
  const [loginDetails, setSetLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [localStorageSsoMode, setLocalStorageSsoMode] = useLocalStorage(
    "ssoMode",
    undefined,
  );
  const [error, setError] = useState("");
  const [ssoMode, setSsoMode] = useState(false);
  const [ssoMessage, setSsoMessage] = useState("");
  const [ssoOptions, setSsoOptions] = useState<IUserSso[]>();

  useEffect(() => {
    setSsoMode(localStorageSsoMode === "true" ? true : false);
  }, []);

  /**
   * Get SSO error from URL query param
   */
  useEffect(() => {
    if (window?.location?.search) {
      const params: any = queryString.parse(window.location.search);
      params.error_description && setError(params.error_description);
    }
  }, []);

  /**
   * useAuth to login.
   */
  const { signIn, setLoading, passwordExpired, setPasswordExpired } =
    useAuthContext();

  /**
   * Set our initial values.
   */
  const initialValues = {
    email: "",
    password: "",
  };

  /**
   * If password expired state in auth set to true then show the correct message
   */
  useEffect(() => {
    if (passwordExpired.expired && !passwordExpired.apiCallFailed) {
      setError(t("login.errors.resendTemporaryPassword"));
    }
  }, [passwordExpired]);

  const { mutate } = useMutation(
    "resendTempPassword",
    async (email: string) => {
      setLoading(true);
      return await instance.users.postResendUserTempPassword({ email });
    },
    {
      onSuccess: () => {
        setPasswordExpired((prevValue) => ({
          ...prevValue,
          expired: true,
          apiCallFailed: false,
        }));
      },
      onError: () => {
        setPasswordExpired((prevValue) => ({
          ...prevValue,
          expired: true,
          apiCallFailed: true,
        }));
      },
      onSettled: () => setLoading(false),
    },
  );

  const { isLoading: userSsoIsLoading } = useQuery(
    ["getUserSsoLogin", loginDetails],
    async () => {
      return (await instance.users.getUserSso(loginDetails.email)).data as any;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!loginDetails.email,
      cacheTime: 0,
      onSuccess: (data) => {
        const ssoOnly = data.some((item: any) => item.ssoOnly === true);
        // user searches for sso options but has no sso options
        if (data.length === 0 && !loginDetails.password) {
          setSsoMessage(t("login.errors.noSsoOptions"));
          return;
        }
        // user trys to log in, has sso options and is sso only
        if (loginDetails.password && ssoOnly) {
          setSsoMode(true);
          setLocalStorageSsoMode("true");
          setSsoMessage(t("login.errors.ssoRequired"));
          setSsoOptions(data);
          return;
        }
        // user searches for sso options
        if (!loginDetails.password) {
          setSsoOptions(data);
          return;
        }
        signInAction(loginDetails.email, loginDetails.password);
      },
      onError: () => {
        if (loginDetails.email && !loginDetails.password) {
          setSsoMessage(t("login.errors.noSsoOptions"));
          return;
        }
        signInAction(loginDetails.email, loginDetails.password);
      },
    },
  );

  const signInAction = async (email: string, password: string) => {
    setError("");

    setPasswordExpired({
      expired: false,
      apiCallFailed: false,
      userEmail: email,
    });

    try {
      await signIn(email, password);
    } catch (e: any) {
      /**
       * if temporary password is expired when user try to log in, resend a new one
       */
      if (
        e?.code === "NotAuthorizedException" &&
        e?.message.includes("Temporary password has expired")
      ) {
        return mutate(email);
      }

      toasts.error({ message: (e as Error).message });
    }
  };

  return (
    <Box>
      <Box className="flex flex-col gap-3 mb-4">
        <Heading
          level={1}
          fontSize="xlarge3"
          color="brand"
          className="flex flex-row items-center"
        >
          {t("title.login")}
          <Emoji svg text=":wave:" className="ml-1" />
        </Heading>
        <Text>{t("description.login")}</Text>
        <Box className="flex flex-row justify-between items-center bg-gray-200 p-3 rounded-md">
          <Heading level={2} fontSize="base" color="dark">
            SSO sign in
          </Heading>
          <Input.Toggle
            checked={ssoMode}
            onClick={() => {
              setSsoMode(!ssoMode), setLocalStorageSsoMode(String(!ssoMode));
            }}
          />
        </Box>
      </Box>

      {/* Email login */}
      {!ssoMode && (
        <Formik
          initialValues={initialValues}
          validationSchema={login}
          onSubmit={(values) => {
            setSetLoginDetails(values);
            setSsoMessage("");
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box className="flex flex-col">
                <InputEmail />
                <InputPassword />
                <Button
                  loading={
                    userSsoIsLoading && loginDetails.password ? true : false
                  }
                />
              </Box>
            </form>
          )}
        </Formik>
      )}

      {/* OIDC login */}
      {ssoMode && (
        <Box className="text-center">
          <Formik
            initialValues={{ ssoEmail: "" }}
            validationSchema={sso}
            onSubmit={(values) => {
              setSetLoginDetails({ email: values.ssoEmail, password: "" });
              setSsoMessage("");
            }}
          >
            {({ handleSubmit }) => (
              <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                {ssoMessage && (
                  <Text className="text-left" color="negative" fontSize="small">
                    {ssoMessage}
                  </Text>
                )}
                {!ssoOptions && (
                  <Box className="flex flex-col gap-4">
                    <FormInput
                      type={t("form.label.emailAddress")}
                      name="ssoEmail"
                      label={{
                        text: t("form.label.emailAddress"),
                      }}
                    />
                    <Box className="flex flex-row justify-end items-center gap-3">
                      {userSsoIsLoading && !loginDetails.password && (
                        <Icon.Loading size="medium" color="brand" />
                      )}
                      <FormButton
                        className="block sm:inline-block"
                        name="submit"
                        type="submit"
                        text={t("form.buttons.next")}
                        variant="secondary"
                        loader
                      />
                    </Box>
                  </Box>
                )}
                {ssoOptions && ssoOptions.length > 0 && (
                  <>
                    <UnstyledButton
                      className="flex flex-row"
                      onClick={() => {
                        setSsoOptions(undefined),
                          setSsoMessage(""),
                          setSetLoginDetails({ email: "", password: "" });
                      }}
                    >
                      <Icon.Chevron
                        size="small"
                        color="brand"
                        styles={{
                          transform: "rotate(180deg)",
                          marginTop: "1px",
                        }}
                      />
                      <Text fontSize="small" color="brand">
                        {t("form.buttons.back")}
                      </Text>
                    </UnstyledButton>
                    <Box className="flex flex-col gap-3">
                      {ssoOptions.map((option) => {
                        return (
                          <BoxButton
                            onClick={async () => {
                              setLoading(true);
                              await Auth.federatedSignIn({
                                provider: option.provider as any,
                              });
                            }}
                            styles={{ width: "100%" }}
                          >
                            <Text fontSize="small">{`Sign in with ${option.provider}`}</Text>
                          </BoxButton>
                        );
                      })}
                    </Box>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Box>
      )}
      {!!error && (
        <Box
          className="flex flex-col items-center max-w-xl mx-auto mt-4 mb-0 py-1 px-2 text-white text-sm rounded-md"
          styles={{ background: theme.colors.negative.base }}
        >
          {error}
        </Box>
      )}
      {passwordExpired.apiCallFailed && (
        <Box
          className="max-w-xl mx-auto mt-4 mb-0 py-1 px-2 text-white text-sm rounded-md leading-4"
          styles={{ background: theme.colors.negative.base }}
        >
          <span>
            This password has expired. Please{" "}
            <UnstyledButton onClick={() => mutate(passwordExpired.userEmail)}>
              <Text
                className="font-bold underline underline-offset-8"
                fontSize="xsmall"
              >
                {t("login.buttons.clickHere")}
              </Text>
            </UnstyledButton>{" "}
            to receive a new password in your email.
          </span>
        </Box>
      )}
    </Box>
  );
};
