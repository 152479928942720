import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  panelButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    width: "100%",
  },
  questionPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 16,
    width: "100%",
    padding: theme.spacing.small,
    marginBottom: theme.spacing.small,
    boxShadow: "none",
  },
});
