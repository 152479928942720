import axios from "axios";

/**
 * Import types.
 */
import { IUserRole, IPaginatedResults } from "./types";

export const readRoles = async (): Promise<IPaginatedResults<IUserRole>> => {
  const { data } = await axios.get("/roles");
  return data;
};
