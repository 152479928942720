import React from "react";
import { components as SelectComponents } from "react-select";

/**
 * Custom component inner. If either an icon or subtext are added to the "label and value"
 * of the drop down options, then this will render these conditional elements, and
 * this style will override the standard of the "label and element"-only setup.
 */
const LabelWithIconAndSubtext = ({ data }: any) => {
  return (
    <>
      {typeof data.icon !== "undefined" && (
        <div className="mr-2">{data.icon}</div>
      )}
      <div className="flex flex-col">
        <div>{data.label}</div>
        {typeof data.subtext !== "undefined" && (
          <div className="text-gray-500 text-xs">{data.subtext}</div>
        )}
      </div>
    </>
  );
};

/**
 * Custom component for use in the select menu.
 * - Details here: https://react-select.com/components
 */
export const OptionWithIconAndSubtext = (props: any): JSX.Element => {
  const { data } = props;

  return (
    <SelectComponents.Option {...props}>
      <LabelWithIconAndSubtext data={data} />
    </SelectComponents.Option>
  );
};

/**
 * Custom component for use in the select menu.
 * - Details here: https://react-select.com/components
 */
export const Option = (props: any): JSX.Element => {
  const { data } = props;

  return (
    <SelectComponents.Option {...props}>{data.label}</SelectComponents.Option>
  );
};

/**
 * Custom component for use in the select menu.
 * - Details here: https://react-select.com/components
 */
export const SingleValue = (props: any): JSX.Element => {
  const { data } = props;
  return (
    <SelectComponents.SingleValue {...props}>
      <LabelWithIconAndSubtext data={data} />
    </SelectComponents.SingleValue>
  );
};
