import React, { useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { Link } from "react-router-dom";
import { instance } from "@clearabee/ui-sdk";
import { Icon, Panel, Text } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { styles } from "./dragItem.styles";

type WorksheetType = Awaited<
  ReturnType<typeof instance.worksheets.getWorksheetsByOrderTypeId>
>["data"][number] & { index: number };

export const Item = ({
  template,
  index,
  moveCard,
}: {
  template: Partial<
    Awaited<
      ReturnType<typeof instance.worksheets.getWorksheetsByOrderTypeId>
    >["data"][number]
  >;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}): React.ReactElement => {
  const [translate] = useTranslation("worksheets");
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    WorksheetType,
    void,
    { handlerId: string | symbol | null }
  >({
    accept: "Item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: WorksheetType, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "Item",
    item: () => {
      return { index, template };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <Panel
      styles={[styles.panel, isDragging && styles.isDragging]}
      ref={ref}
      data-handler-id={handlerId}
    >
      <Text color="brand" styles={styles.currentIndex}>
        {index}.
      </Text>
      <Text styles={styles.name}>{template.name}</Text>
      <Link target="_blank" to={`/worksheets/update/${template.id}`}>
        <Icon.NewWindow size="small" color="brand" />
      </Link>
    </Panel>
  );
};
