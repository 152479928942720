import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const createOrderTypeIDValidation = Yup.object().shape({
  name: stringRequired,
  parentRef: Yup.string(),
  bcJobType: Yup.string(),
  resourceId: Yup.string().matches(/^\d+$/),
});

export const createOrderTypeIDInitialValues = {
  name: "",
  parentRef: "",
  bcJobType: "",
  resourceId: "",
  allowPriceOverride: false,
};
