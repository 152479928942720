/**
 * Translations
 */
import i18n from "../translations/config";

/**
 * Import toasts
 */
import { toasts } from ".";

/**
 * Import api function.
 */
import { toggleDialpadRecording } from "../api";

/**
 * Stop user's Dialpad recording.
 */
export const stopDialpadRecording = async (userId: string): Promise<void> => {
  try {
    await toggleDialpadRecording(userId, false);
  } catch (error) {
    toasts.error({ message: i18n.t("common:errors.stopDialpadRecording") });
  }
};

/**
 * Start user's Dialpad recording.
 */
export const startDialpadRecording = async (userId: string): Promise<void> => {
  try {
    await toggleDialpadRecording(userId, true);
  } catch (error) {
    toasts.error({ message: i18n.t("common:errors.startDialpadRecording") });
  }
};
