import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import {
  Button,
  displayErrorMessage,
  Icon,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { getCatalogueItem, updateCatalogueItem } from "api";
import { ErrorMessage } from "components/common/components";
import { PatchCatalogueItem } from "api/types";
import {
  CreateUpdateItemForm,
  ItemForValuesWithUploadedImage,
} from "components/catalogues/components/createUpdateItemForm";
import { parseFormData } from "components/catalogues/parser";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

export const UpdateCatalogueItem = (): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);

  const { id, itemSku } = useParams<{ id: string; itemSku: string }>();
  const [translate] = useTranslation("catalogues");

  const {
    mutate,
    isLoading: isMutationLoading,
    error,
    reset,
  } = useMutation(
    (catalogueItem: PatchCatalogueItem) =>
      updateCatalogueItem(id, itemSku, catalogueItem),
    {
      onSuccess: () => setShowModal(true),
    },
  );

  const handleFormSubmit = async (values: ItemForValuesWithUploadedImage) => {
    mutate(parseFormData(values));
  };

  const {
    isFetching,
    isLoading: isQueryLoading,
    isError,
    data,
  } = useQuery(
    ["getCatalogueItem", id, itemSku],
    () => getCatalogueItem(id, itemSku),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

  if (isQueryLoading || isFetching || !data) {
    return (
      <Icon.Loading
        color="brand"
        size="xlarge2"
        className="m-auto mt-20"
        data-testid="loading"
      />
    );
  } else if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Link
        to={`/catalogues/update/${id}`}
        className="inline-flex items-center mb-5"
      >
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues/update"),
        })}
      </Link>
      <CreateUpdateItemForm
        allowDeliveryOptionsEdit={true}
        allowZoneOptionsEdit={true}
        initialValues={data}
        heading={data.title}
        onSubmit={handleFormSubmit}
        disableSubmit={isMutationLoading || showModal}
        cancelUrl={`/catalogues/update/${id}`}
        isLoading={isMutationLoading}
      />
      {displayErrorMessage(error, (props) => (
        <DefaultErrorComponent
          {...props}
          styles={{
            marginTop: theme.spacing.small,
            marginBottom: theme.spacing.small,
            maxWidth: theme.screens.small,
            marginRight: 0,
          }}
          onClose={reset}
        />
      ))}
      {showModal && (
        <Modal
          heading={translate("modal.catalogueUpdateItem.heading")}
          width={500}
          actions={
            <>
              <Link to={`/catalogues/update/${id}`}>
                <Button
                  size="medium"
                  color="negative"
                  type="button"
                  className="mr-3"
                >
                  {translate("modal.catalogueUpdateItem.viewCatalogue")}
                </Button>
              </Link>
              <Link to="/catalogues">
                <Button
                  size="medium"
                  color="accent"
                  type="button"
                  className="ml-3"
                >
                  {translate("common:routes.catalogues")}
                </Button>
              </Link>
            </>
          }
          onClose={() => setShowModal(false)}
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("modal.catalogueUpdateItem.message")}
          </div>
        </Modal>
      )}
    </>
  );
};
