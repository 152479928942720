import * as Yup from "yup";
import { stringRequired } from "../../../validation/common";

/**
 * Import types.
 */
import { ISelectServiceStep, IFormState } from "./types";

/**
 * Payment find job schema
 */
export const findJobSchema = Yup.object().shape({
  reference: stringRequired,
  postcode: stringRequired,
});

export const findJobInitialValues = {
  reference: "",
  postcode: "",
};

/**
 * Select service step.
 */
export const selectServiceSchema = Yup.object().shape({
  service: stringRequired,
});

export const selectServiceInitialValues: ISelectServiceStep = {
  service: "",
};

/**
 * Initial Form state.
 * Used by MultiForm component.
 */
export const formStateInitialValues: IFormState = {
  ...selectServiceInitialValues,
};
