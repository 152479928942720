import React from "react";
import { css } from "@emotion/react";
import { Text, Box, BoxProps, theme } from "@clearabee/ui-library";
import {
  ColorKeyVariants,
  getColorFromVariantKey,
} from "@clearabee/ui-library-base";
import { styles } from "./BadgeCount.styles";

type AllowedSizeKeys =
  | keyof Pick<typeof theme.spacing, "small" | "medium" | "large" | "xlarge">;

interface BadgeCountProps extends BoxProps {
  count: number;
  hidden?: boolean;
  countLimit?: number;
  size?: AllowedSizeKeys;
  backgroundColor?: ColorKeyVariants;
  borderColor?: ColorKeyVariants;
  textColor?: ColorKeyVariants;
}

export const BadgeCount = ({
  count,
  countLimit = 9,
  hidden = false,
  size = "medium",
  backgroundColor = "warning.base",
  borderColor = "brand.darkest",
  textColor = "dark.base",
  styles: userStyles,
  ...rest
}: BadgeCountProps): React.ReactElement => {
  return (
    <Box
      css={css(styles.default, userStyles, {
        width: theme.spacing[size],
        height: theme.spacing[size],
        backgroundColor: getColorFromVariantKey(backgroundColor),
        borderColor: getColorFromVariantKey(borderColor),
        color: getColorFromVariantKey(textColor),
        visibility: hidden ? "hidden" : "visible",
      })}
      {...rest}
    >
      <Text fontSize="xsmall2">
        {count <= countLimit ? count : `${countLimit}+`}
      </Text>
    </Box>
  );
};
