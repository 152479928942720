"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companies = void 0;
exports.companies = {
    common: {
        companyName: "Company Name",
        backToCompanies: "Back to companies",
        assignedCompanies: "Assigned companies",
        title: "Companies",
        addCompany: "Add company",
    },
    filters: {
        title: "Search companies",
        form: {
            label: {
                date: "Date",
                day: "Day",
                limit: "Limit",
                companyName: "$t(common.companyName)",
                reference: "Company Reference",
                sortBy: "Sort By",
                status: "Status",
            },
            placeholder: {
                selectDate: "Select date",
                selectDay: "Select day",
                companyName: "search by Company Name",
                reference: "search by Company Reference",
                sortBy: "Date created (Newset first)",
                status: "All",
                field: "Search field..",
                operator: "Search operator..",
            },
        },
        optionLabels: {
            contains: "Contains (Full-text search)",
            equals: "Equals (=)",
            name: "Name",
            contactEmail: "Contact Email",
            companyCode: "Company Code",
        },
    },
    table: {
        headings: {
            actions: "Actions",
            active: "Active",
            companyName: "Company Name",
            companyCode: "Company Code",
            companyReference: "Company Reference",
            delete: "Delete",
            edit: "Edit",
            status: "Status",
        },
        noResults: "No companies found",
    },
    modal: {
        headings: {
            updateSuccess: "Successfully updated company!",
            createCompanySuccess: "Company successfully created!",
            deleteCompany: "Are you sure you want to remove this company?",
        },
        buttons: {
            labels: {
                yesDeleteCompany: "Yes, i'm sure",
                backToCompanies: "Back to Companies",
                close: "Close",
            },
        },
    },
    steps: {
        titles: {
            companyDetails: "Company details",
            selectServices: "Select services",
            selectProducts: "Select products",
            createUserAccount: "Create user account",
        },
        productOptions: {
            table: {
                headings: {
                    product: "Product",
                    description: "Description",
                    price: "Price",
                    add: "Add",
                },
            },
        },
    },
    form: {
        headings: {
            addCompany: "Add Company",
            companyDetails: "Company Details",
            bookingLimitDays: "Booking Limit Days",
            bookingLimitDates: "Booking Limit Dates",
            customerDetails: "Customer Details",
            callOutCharge: "Call Out Charge",
            createUserAccount: "Create user account",
            company: "Company",
            contact: "Contact",
            settings: "Settings",
        },
        labels: {
            companyName: "$t(common.companyName)",
            companyCode: "Company Code",
            companyCatalogue: "Company Catalogue",
            companyPrefix: "Company Prefix",
            companyActive: "Company active?",
            canInvoice: "Can invoice?",
            bigChangeContactId: "Big Change ID",
            jobType: "Job Type",
            sicCode: "SIC Code",
            buildingName: "Building Name / Number",
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            streetName: "Street Name",
            townCity: "Town / City",
            county: "County",
            postcode: "Postcode",
            name: "Name",
            notes: "Useful Notes",
            phone: "Phone",
            emailAddress: "Email Address",
            callOutCharge: "Call Out Charge",
            showCollectionTimes: "Show Collection Times",
            showProductExtras: "Show Product Extras",
            enableCashOnly: "Enable Cash Only",
            userName: "User's Name",
            requireOrderNumber: "Require Order Number",
            orderNumberValidation: "Order Number Validation",
            orderNumberValidationMessage: "Order Number Validation Message",
            redefineOrderNumberLabel: "Redefine Order Number Label",
            requireContactDetails: "Require Contact Details",
            requirePhoneAndEmail: "Require BOTH Phone And Email",
            hideInvoices: "Hide Invoices?",
            hidePrices: "Hide Prices?",
            hidePhotos: "Hide Photos?",
            hideTransferNotes: "Hide Transfer Notes?",
            identityProvider: "Identity Provider",
            ssoOnly: "SSO Only",
        },
        placeholders: {
            orderNumberValidation: "Enter valid regex without wrapping slashes",
            selectCompanyCatalogue: "Select company catalogue...",
        },
        buttons: {
            labels: {
                save: "Save",
                next: "Next",
                uploadLogo: "Upload logo",
                viewCatalogue: "View catalogue",
                createCompany: "Create Company",
                saveExit: "Save & Exit",
                yes: "Yes",
                no: "No",
            },
        },
    },
    close: "Close",
    errors: {
        updateError: "Company could not be updated. Try again",
        fetchingCatalogues: "An error occurred fetching company catalogues, please try again later.",
        fetchingCompany: "An error occurred fetching company data, please try again later.",
    },
};
