"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColorFromVariantKey = void 0;
var theme_1 = require("../theme");
var getColorFromVariantKey = function (key) {
    if (!key.includes(".")) {
        return theme_1.theme.colors[key].base;
    }
    var _a = key.split("."), color = _a[0], variant = _a[1];
    return theme_1.theme.colors[color][variant];
};
exports.getColorFromVariantKey = getColorFromVariantKey;
