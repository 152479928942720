import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  toggle: {
    display: "inline-block",
    minWidth: theme.spacing.xlarge2,

    [`@media (min-width: ${theme.screens.xlarge})`]: {
      "span:last-child": {
        position: "absolute",
        top: theme.spacing.xlarge2,
      },
    },
  },
  cancelButton: {
    textAlign: "center",
    verticalAlign: "center",
  },
  saveButton: {
    marginLeft: theme.spacing.small,
  },
  duplicateLink: {
    marginLeft: theme.spacing.xsmall,
    textDecoration: "underline",
  },
  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  loadingOverlay: {
    "::after": {
      content: "''",
      background: theme.colors.light.base,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: "0.9",
      borderRadius: "10px",
    },
  },
});
