/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
const uniqueIdentifier = "Hexagon";

export const Hexagon: React.FC<
  TGenericImage & { imagePattern?: string; id?: string }
> = ({
  className = "hexagon-icon",
  stroke = "currentColor",
  width = 60,
  height = 68,
  imagePattern,
  id = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 56 62"
    >
      {imagePattern && (
        <defs>
          <pattern
            id={`${uniqueIdentifier + id}A`}
            patternUnits="userSpaceOnUse"
            height="100"
            width="100"
          >
            <image
              x="0"
              y="0"
              height="100%"
              width="100%"
              preserveAspectRatio="xMidYMid slice"
              xlinkHref={imagePattern}
            />
          </pattern>
        </defs>
      )}
      <path
        stroke={stroke}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        fill={imagePattern ? `url(#${uniqueIdentifier + id}A)` : undefined}
        strokeWidth="2"
        d="M48.9 41.5v-1.1 0-3.9 0V34v0-13.8c0-.3 0-.6-.1-.8 0-.1-.1-.3-.1-.4-.2-.6-.6-1-1.1-1.4-.1-.1-.2-.1-.2-.1l-2.2-1.3-2.1-1.2-1.7-.9-6.3-3.6-2.5-1.4L30.7 8l-1.1-.7c-1-.6-2.3-.6-3.3 0l-1.1.7-1.9 1.1-2.5 1.4v0l-6.3 3.6-2.8 1.6-2.1 1.2-1.1.6c-.1 0-.2.1-.2.1-.3.2-.6.5-.8.8-.1.2-.3.4-.4.7 0 .4-.1.8-.1 1.3v21.3s0 0 0 0c.1.3.2.6.4.8h0c.1.2.2.3.4.5 0 0 0 0 0 0 .2-.1.3 0 .3.1 0 0 0 0 0 0 .1.1.2.1.2.2.1.1.2.1.2.1l2.2 1.2 2.1 1.2 1.7 1 8.8 5 1.9 1.1 1.1.7c1 .6 2.3.6 3.3 0l1.1-.7 1.9-1.1 2.5-1.4 5.3-3.1 1-.6 2.5-1.4 2.1-1.2 1.3-.8c.1 0 .2-.1.2-.1.1-.1.2-.1.2-.2 0 0 0 0 0 0l.2-.2s0 0 0 0c.5-.3.8-.8 1-1.3 0 0 0 0 0 0z"
        clipPath="url(#SVGID_2_)"
      />
    </svg>
  );
};
