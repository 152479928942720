import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { QuoteForm, StaffResponse, CustomerResponse } from "./components";
import { readQuote } from "api";
import {
  FormLoader,
  StatusCompleted,
  StatusExpired,
  StatusFailed,
  StatusOpen,
  StatusStarted,
} from "components/common/components";
import { BackButton } from "./components/backButton";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { Quote } from "models";

export const statusComponents = {
  open: <StatusOpen text="Open" />,
  accepted: <StatusCompleted text="Accepted" />,
  quoted: <StatusStarted text="Quoted" />,
  rejected: <StatusFailed text="Rejected" />,
  expired: <StatusExpired text="Expired" />,
};

export const QuoteContext = createContext(
  {} as {
    data: Quote;
    refetch: () => void;
  },
);

export const UpdateQuote = (): React.ReactElement => {
  const { id } = useParams<{
    id: string;
  }>();

  /**
   * User details.
   */
  const { doesUserHaveRole } = useAuthContext();

  const staffResponse = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);
  const customerResponse = doesUserHaveRole([
    roles.COMPANY_ADMIN,
    roles.COMPANY_STAFF,
  ]);

  /**
   * Quote data.
   */
  const {
    data: quoteData,
    isFetching,
    refetch,
  } = useQuery(["readQuote", id], () => readQuote(id), {
    // prevent caching to show newly updated values
    cacheTime: 0,
    staleTime: 0,
  });

  if (isFetching || !quoteData) return <FormLoader />;

  return (
    <div className="max-w-screen-lg pt-10 mx-auto">
      <BackButton />

      <QuoteContext.Provider
        value={{
          data: quoteData,
          refetch,
        }}
      >
        {staffResponse && <StaffResponse />}
        {customerResponse && <CustomerResponse />}

        <QuoteForm
          disabled
          collapsable
          defaultCollapsed
          quoteData={quoteData}
        />
      </QuoteContext.Provider>
    </div>
  );
};
