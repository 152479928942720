import React, {
  useContext,
  useState,
  createContext,
  useMemo,
  useEffect,
} from "react";
import { css } from "@emotion/react";
import { Panel, PanelProps } from "../../Core/Panel/Panel";
import { styles } from "./Tab.styles";
import { UserStylesProps } from "../../utils/types";

export type TabsContextState<Tabs = string> = {
  setTab: (tab: Tabs) => void;
  activeTab: string;
  unmountContent: boolean;
};

export const TabsContext = createContext({} as TabsContextState);

export function useTabsContext<Tabs extends string>(): TabsContextState<Tabs> {
  return useContext(TabsContext);
}
export interface TabProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  > {
  active?: boolean;
}

export interface TabItemProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  label: string;
  children: JSX.Element;
}

export const TabButton = ({
  children,
  active,
  styles: userStyles,
  ...props
}: TabProps): React.ReactElement => {
  return (
    <button
      type="button"
      css={css(styles.tab, active ? styles.activeTab : undefined, userStyles)}
      {...props}
    >
      {children}
    </button>
  );
};

export const TabItem = ({
  children,
  label,
  styles: userStyles,
  ...props
}: TabItemProps): React.ReactElement | null => {
  const { activeTab, unmountContent } = useTabsContext();

  if (unmountContent && activeTab !== label) return null;
  else if (unmountContent) return children;

  return (
    <div
      css={css(activeTab !== label ? styles.hidden : undefined, userStyles)}
      key={`tabs-content-${label}`}
      {...props}
    >
      {children}
    </div>
  );
};

type TabItem = React.ReactElement<TabItemProps> | false;

export interface TabsProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  unmountContent?: boolean;
  children: TabItem | TabItem[];
  defaultTab?: string;
  onTabChange?: (tab: string) => void;
  panelProps?: Omit<PanelProps, "ref">;
}

export const Tabs = ({
  unmountContent = false,
  styles: userStyles,
  children,
  defaultTab,
  onTabChange,
  panelProps = {},
  ...props
}: TabsProps): React.ReactElement => {
  const tabs: string[] = useMemo(() => {
    return (
      (children.hasOwnProperty("length") ? children : [children]) as TabItem[]
    ).flatMap((element) => (element ? element.props.label : []));
  }, [children]);
  const [activeTab, setTab] = useState(defaultTab ?? tabs[0]);

  useEffect(() => onTabChange?.(activeTab), [activeTab]);

  useEffect(() => {
    const findTab = tabs.find((tab) => tab === activeTab);

    if (!findTab) {
      setTab(tabs[0]);
    }
  }, [children]);

  return (
    <TabsContext.Provider
      value={{
        setTab,
        activeTab,
        unmountContent,
      }}
    >
      <div css={css(userStyles)} {...props}>
        <div
          css={{
            display: "flex",
          }}
        >
          {tabs.map((heading) => (
            <TabButton
              active={activeTab === heading}
              onClick={() => setTab(heading)}
              key={`tabs-heading-${heading}`}
            >
              {heading}
            </TabButton>
          ))}
        </div>
        <Panel color="grey" shadow={false} {...panelProps}>
          {children}
        </Panel>
      </div>
    </TabsContext.Provider>
  );
};

Tabs.Tab = TabButton;
Tabs.Item = TabItem;
