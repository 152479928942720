import * as Yup from "yup";
import { stringRequired } from "validation/common";
import { translate } from "@clearabee/i18n";

export const initialValues = {
  drivers: [],
  title: "",
  body: "",
  data: [
    {
      key: "",
      value: "",
    },
  ],
};

export const validationSchema = Yup.object().shape({
  title: stringRequired,
  body: stringRequired,
  data: Yup.array().of(
    Yup.object().shape(
      {
        key: Yup.string().when("value", {
          is: (value) => !!value,
          then: stringRequired,
          otherwise: Yup.string(),
        }),
        value: Yup.string().when("key", {
          is: (key) => !!key,
          then: stringRequired,
          otherwise: Yup.string(),
        }),
      },
      [["key", "value"]],
    ),
  ),
  drivers: Yup.array().min(
    1,
    translate("portal.drivers.notifications.errors.noDriverSelected"),
  ),
});
