import React, {
  createContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Catalogue } from "api/types";
import { CatalogueItem, Template } from "models";
import { deepCloneObject, getItemById, removeItemFromList } from "helpers";
import { v4 } from "uuid";
import { useQuery } from "react-query";
import { getTemplates } from "api";
import { patchItemInList } from "helpers/patchItemInList";
import { getParent } from "helpers/getParent";
import { Button, useModal } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useScreenWidth } from "hooks";

export const CatalogueEditorContext = createContext(
  {} as EditabledCatalogueContextState,
);

export type QuickEditFormResult = Partial<
  Pick<CatalogueItem, "title" | "description" | "price">
>;

export interface EditabledCatalogueContextState {
  catalogue?: Catalogue;
  templates: Template[];
  undo: () => void;
  companies: string[] | undefined;
  setCompanies: (companies: string[]) => void;
  items: EditableItem[];
  addItem: (item: Template | EditableItem, parent: string | null) => void;
  moveItem: (item: EditableItem, parent: string | null) => void;
  deleteItem: (item: EditableItem) => void;
  deleteItemConfirm: (item: EditableItem) => void;
  editItem: (item: EditableItem, edits: QuickEditFormResult) => void;
  setItems: (items: EditableItem[]) => void;
  history: CatalogueHistoryEntry[];
  isLoading?: boolean;
  refetch?: () => void;
}

export type EditableItem = Omit<
  Template & CatalogueItem,
  "children" | "catalogueId"
> & {
  id: string;
  children?: EditableItem[];
};

type Action = "MOVE_ITEM" | "DELETE_ITEM" | "ADD_ITEM" | "EDIT_ITEM";

export interface CatalogueHistoryEntry {
  action: Action;
  item: EditableItem;
  parent?: EditableItem | null;
  edits?: QuickEditFormResult;
}

interface CatalogueEditorProps {
  catalogue?: Catalogue;
  isLoading?: boolean;
  blankCatalogue?: boolean;
  children: React.ReactNode;
  refetch?: () => void;
}

const generateEditableItemsFromCatalogue = (
  items: CatalogueItem[],
): EditableItem[] => {
  return items.map((item) => ({
    ...item,
    id: v4(),
    children: generateEditableItemsFromCatalogue(item.children ?? []),
  }));
};

export const CatalogueEditor = ({
  catalogue,
  children,
  isLoading = false,
  blankCatalogue,
  refetch,
}: CatalogueEditorProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const { isMobile } = useScreenWidth();
  const initialItems = useMemo(
    () =>
      catalogue?.items
        ? generateEditableItemsFromCatalogue(deepCloneObject(catalogue.items))
        : [],
    [catalogue?.items],
  );
  const [items, setItems] = useState<EditableItem[]>(initialItems);
  const [companies, setCompanies] = useState<string[] | undefined>(
    blankCatalogue ? [] : catalogue?.companies.map(({ code }) => code),
  );

  const [ConfirmDeleteModal, showConfirmDeleteModal] = useModal();
  const [deleteableItem, setDeleteableItem] = useState<EditableItem>();
  const history = useRef<CatalogueHistoryEntry[]>([]);

  useEffect(() => {
    setItems(initialItems);
  }, [catalogue?.items]);

  const { data: templates } = useQuery(
    ["readTemplates"],
    () => getTemplates({ limit: 2000 }),
    {
      retry: 0,
    },
  );

  const undoActions: Record<Action, (entry: CatalogueHistoryEntry) => void> = {
    ADD_ITEM: (entry) => deleteItem(entry.item, false),
    DELETE_ITEM: (entry) =>
      addItem(entry.item, entry.parent?.id ?? null, false),
    MOVE_ITEM: (entry) => moveItem(entry.item, entry.parent?.id ?? null, false),
    EDIT_ITEM: (entry) =>
      entry.edits && editItem(entry.item, entry.edits, false),
  };

  const undo = () => {
    const entry = history.current.pop();

    if (entry) {
      undoActions[entry.action](entry);
    }
  };

  const addItem = (
    template: Template | EditableItem,
    parentId: string | null,
    storeHistory = true,
  ) => {
    const newItems = deepCloneObject(items);
    const id = (template as EditableItem).id ?? v4();
    const item: EditableItem = {
      ...template,
      id,
    };
    let parent = null;
    if (parentId) {
      parent = getItemById(parentId, newItems);
      item.parentSku = parent?.sku;
      if (parent?.children) {
        parent?.children.unshift(item);
      } else if (parent) {
        parent.children = [item];
      }
    } else {
      newItems.push(item);
    }
    storeHistory &&
      history.current.push({
        action: "ADD_ITEM",
        item,
        parent,
      });
    setItems(newItems);
  };

  const deleteItemConfirm = (item: EditableItem) => {
    setDeleteableItem(item);
    showConfirmDeleteModal(true);
  };

  const deleteItem = (item: EditableItem, storeHistory = true) => {
    const newItems = removeItemFromList(item, deepCloneObject(items));
    storeHistory &&
      history.current.push({
        action: "DELETE_ITEM",
        item,
        parent: getParent(item, items),
      });

    setItems(newItems);
  };

  const moveItem = (
    item: EditableItem,
    parentId: string | null,
    storeHistory = true,
  ) => {
    // first remove item
    const newItems = removeItemFromList(item, deepCloneObject(items));
    let parent = null;
    // then insert into parent
    if (parentId) {
      parent = getItemById(parentId, newItems);
      if (parent?.children) {
        parent?.children.unshift({ ...item, parentSku: parent.sku });
      } else if (parent) {
        parent.children = [{ ...item, parentSku: parent.sku }];
      }
    } else {
      newItems.push(item);
    }
    storeHistory &&
      history.current.push({
        action: "MOVE_ITEM",
        item: { ...item, parentSku: parent?.sku },
        parent: getParent(item, items),
      });
    setItems(newItems);
  };

  const editItem = (
    item: EditableItem,
    edits: QuickEditFormResult,
    storeHistory = true,
  ) => {
    const newItems = patchItemInList(
      item.id,
      {
        ...edits,
      },
      deepCloneObject(items),
    );

    storeHistory &&
      history.current.push({
        action: "EDIT_ITEM",
        item,
        edits: {
          title: edits.title === undefined ? undefined : item.title,
          description:
            edits.description === undefined ? undefined : item.description,
          price: edits.price === undefined ? undefined : item.price,
        },
      });
    setItems(newItems);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <CatalogueEditorContext.Provider
        value={{
          refetch,
          catalogue,
          templates: templates?.items || [],
          companies,
          setCompanies,
          items,
          setItems,
          addItem,
          deleteItem,
          deleteItemConfirm,
          moveItem,
          editItem,
          undo,
          history: history.current || [],
          isLoading,
        }}
      >
        <>
          {children}
          <ConfirmDeleteModal
            subheading={translate("modal.deleteItem.message", {
              title: deleteableItem?.title,
            })}
            width={!isMobile ? 400 : undefined}
          >
            <div className="mt-8">
              <Button
                color="accent"
                className="mb-4"
                type="button"
                onClick={() => {
                  deleteableItem && deleteItem(deleteableItem);
                  setDeleteableItem(undefined);
                  showConfirmDeleteModal(false);
                }}
              >
                {translate("common:yes")}
              </Button>
              <Button
                color="negative"
                type="button"
                onClick={() => showConfirmDeleteModal(false)}
              >
                {translate("common:cancel")}
              </Button>
            </div>
          </ConfirmDeleteModal>
        </>
      </CatalogueEditorContext.Provider>
    </DndProvider>
  );
};
