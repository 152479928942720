import React, { useEffect } from "react";
import {
  useFormikContext,
  FormikProps,
  FormikFormProps,
  FormikValues,
} from "formik";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useQuery } from "react-query";

/**
 * Import images.
 */
import { SearchIcon } from "../../../../images";

/**
 * Import components
 */
import {
  FormButton,
  ImageButton,
  FormInput,
  AddressDropdown,
} from "../../../core";

/**
 * Import helpers.
 */
import { toasts } from "../../../../helpers";

/**
 * Import API functions.
 */
import { getBorough, getBoroughAvailability } from "../../../../api";

export const AddressPanel: React.FC = () => {
  const [t] = useTranslation("jobs");

  /**
   * Get Formik context.
   */
  const { setFieldValue, isValid, dirty }: FormikProps<FormikFormProps> =
    useFormikContext();
  const { values }: FormikProps<FormikValues> = useFormikContext();
  const { postcode, borough } = values;

  /**
   * Get borough from postcode
   */
  const { refetch: refetchBorough, isLoading } = useQuery(
    "getBorough",
    () => getBorough(postcode),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        setFieldValue("borough", data.borough);
      },
      onError: () => {
        toasts.error({
          message: "WEEE Kerbside service isn't available in your area",
        });
      },
    },
  );

  /**
   * Get borough availability
   */
  const { data: availabilityData, refetch: refetchAvailability } = useQuery(
    "getBoroughAvailability",
    () => getBoroughAvailability(borough),
    { enabled: false, retry: false },
  );

  useEffect(() => {
    if (!borough) {
      return;
    }

    refetchAvailability();
  }, [borough]);

  useEffect(() => {
    if (
      !availabilityData ||
      !availabilityData.dates ||
      !availabilityData.dates.length
    ) {
      return;
    }

    setFieldValue("boroughAvailability", availabilityData.dates);
  }, [availabilityData]);

  /**
   * Reset fields if postcode changes
   */
  useEffect(() => {
    setFieldValue("borough", "");
    setFieldValue("selectedAddress", undefined);
    setFieldValue("addressChoices", "");
  }, [postcode]);

  /**
   * Search button click handler - Fetch borough from api
   */
  const getBoroughByPostcode = () => {
    refetchBorough();
  };

  return (
    <>
      <h3 className="text-xl text-primary font-bold mb-6">
        {t("headings.getStarted")}
      </h3>
      <div className={cx("w-full", { flex: !borough || !postcode })}>
        <div className={postcode && borough ? "w-full" : "w-4/5 mr-6"}>
          <FormInput
            name="postcode"
            placeholder={t("weeeKerbside.form.placeholders.postcode")}
            classes=""
            floatingLabel={false}
            label={{
              text: t("weeeKerbside.form.labels.postcode"),
            }}
          />
        </div>
        <div>
          {(!borough || !postcode) && (
            <ImageButton
              name="search"
              type="button"
              image={<SearchIcon />}
              variant="secondary"
              loader={true}
              disabled={!postcode || isLoading}
              onClick={getBoroughByPostcode}
            />
          )}
        </div>
      </div>
      {postcode && borough && (
        <>
          <div className="w-full py-6">
            <AddressDropdown
              postcode={values.postcode}
              menuPlacement="bottom"
              updateAddress={(value) => {
                setFieldValue("selectedAddress", value);
              }}
            />
          </div>
          <div className="flex justify-end">
            <FormButton
              name="next"
              type="submit"
              text={t("weeeKerbside.buttons.next")}
              className="btn btn-icon w-24"
              variant="secondary"
              disabled={!dirty || !isValid}
            />
          </div>
        </>
      )}
    </>
  );
};
