import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { FieldArray } from "formik";
import { UpdateCompanyFormValues } from "../../api/types";
import {
  updateCompany,
  readCompany,
  getCatalogues,
  getCatalogueByCompanyCode,
  assignCatalogueToCompanies,
  removeCatalogueFromCompanies,
} from "../../api";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import {
  constantDays,
  defaultDates,
  defaultDays,
  updateCompany as validation,
} from "./validation";
import { toasts } from "../../helpers/toasts";
import {
  Button,
  Field,
  Heading,
  Input,
  Panel,
  theme,
  Icon,
  Form,
  useModal,
  hexToRgba,
  UnstyledButton,
  Box,
} from "@clearabee/ui-library";
import { LeftChevron } from "images";

export const UpdateCompany = (): React.ReactElement => {
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const { id } = useParams() as any;
  const history = useHistory();
  const [translate] = useTranslation("companies");
  const [disableContactDetailsToggle, setDisableContactDetailsToggle] =
    useState(false);
  const [SuccessModal, setSuccessModal] = useModal();

  const {
    data: catalogueData,
    isError: catalogueError,
    isFetching: catalogueFetching,
  } = useQuery(["getCatalogues"], () => getCatalogues({ limit: 1000 }));

  const {
    isFetching,
    isLoading,
    data: companyData,
    error,
  } = useQuery(["readCompany", id], () => readCompany(id), {
    // prevent caching to show newly updated values
    cacheTime: 0,
    staleTime: 0,
    onError: () => {
      toasts.error({
        message: translate("errors.fetchingCompany"),
      });
    },
  });

  const {
    data: companyCatalogue,
    isFetching: companyCatalogueFetching,
    isError: companyCatalogueError,
  } = useQuery(
    ["getCompanyCatalogue", companyData?.companyCode],
    () => getCatalogueByCompanyCode(companyData?.companyCode || ""),
    {
      enabled: !!companyData?.companyCode,
      retry: 0,
    },
  );

  const { mutate, isLoading: isUpdateCompanyLoading } = useMutation(
    async ({
      catalogue,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      companyCode,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      companyPrefix,
      // companyCode and companyPrefix are removed from the company object, but are
      // not themselves used in anything, so they throw the un-used var lint error
      ...updatedCompany
    }: UpdateCompanyFormValues) => {
      await updateCompany(id, updatedCompany);

      if (!companyData?.companyCode) {
        return;
      }
      if (catalogue === "" && !!companyCatalogue) {
        await removeCatalogueFromCompanies(companyCatalogue?.id, [
          companyData.companyCode,
        ]);
      } else if (catalogue && catalogue !== companyCatalogue?.id) {
        await assignCatalogueToCompanies(catalogue, [companyData.companyCode]);
      }
    },
    {
      onError: () => {
        toasts.error({
          message: translate("errors.updateError"),
        });
      },
      onSuccess: () => setSuccessModal(true),
    },
  );

  const initial: UpdateCompanyFormValues = {
    // required
    name: companyData?.name || "",
    companyCode: companyData?.companyCode || "",
    companyPrefix: companyData?.companyPrefix || "",
    addressLine1: companyData?.addressLine1 || "",
    addressPostcode: companyData?.addressPostcode || "",
    contactName: companyData?.contactName || "",
    contactEmail: companyData?.contactEmail || "",
    contactPhoneNumber: companyData?.contactPhoneNumber || "",
    settings: {
      canInvoice: companyData?.settings?.canInvoice || false,
      hidePrices: companyData?.settings?.hidePrices || false,
      hideInvoices: companyData?.settings?.hideInvoices || false,
      requireOrderNumber: companyData?.settings?.requireOrderNumber || false,
      orderNumberLabel: companyData?.settings?.orderNumberLabel || "",
      requireContactDetails:
        companyData?.settings?.requireContactDetails || false,
      requirePhoneAndEmail:
        companyData?.settings?.requirePhoneAndEmail || false,
      orderNumberValidation: companyData?.settings?.orderNumberValidation || "",
      orderNumberValidationMessage:
        companyData?.settings?.orderNumberValidationMessage || "",
      hidePhotos: companyData?.settings?.hidePhotos || false,
      hideTransferNotes: companyData?.settings?.hideTransferNotes || false,
      identityProvider: companyData?.settings?.identityProvider || "",
      ssoOnly: companyData?.settings?.ssoOnly || false,
    },
    active: companyData?.active || false,
    // optional
    addressLine2: companyData?.addressLine2 || "",
    bookingLimitDates: companyData?.bookingLimitDates || [],
    bookingLimitDays: companyData?.bookingLimitDays || [],
    addressCity: companyData?.addressCity || "",
    addressCounty: companyData?.addressCounty || "",
    sicCode: companyData?.sicCode || "",
    bigChangeContactId: companyData?.bigChangeContactId ?? 0,
    catalogue:
      !companyCatalogueError && companyCatalogue
        ? companyCatalogue.id
        : undefined,
    notes: companyData?.notes || "",
  };

  const handleSubmit = (values: UpdateCompanyFormValues) => {
    mutate({
      ...values,
      bookingLimitDays: values.bookingLimitDays?.map((item) => ({
        ...item,
        day: Number(item.day),
      })),
    });
  };

  if (error) return <Redirect to="/companies" />;

  return (
    <>
      <Box className="max-w-screen-lg py-5 sm:px-4 mx-auto">
        {isLoading || isFetching || companyCatalogueFetching ? (
          <Icon.Loading color="brand" styles={{ margin: "auto" }} />
        ) : (
          <>
            <Link
              to="/companies"
              className="text-sm inline-flex items-center mb-4 justify-start"
            >
              <LeftChevron />
              <span className="ml-2">
                {translate("common.backToCompanies")}
              </span>
            </Link>
            <Form
              initialValues={initial}
              validationSchema={validation}
              onSubmit={handleSubmit}
            >
              {({ errors, isValid, values, setFieldValue }) => {
                useEffect(() => {
                  setDisableContactDetailsToggle(false);
                  if (values.settings.requirePhoneAndEmail) {
                    setDisableContactDetailsToggle(true);
                    setFieldValue("settings.requireContactDetails", true);
                  }
                }, [values.settings.requirePhoneAndEmail]);

                return (
                  <Panel>
                    <Box className="flex flex-col md:flex-row justify-between md:space-x-8">
                      <Box className="w-full md:w-1/2">
                        <Heading level={2} color="brand" fontSize="large">
                          {translate("form.headings.company")}
                        </Heading>
                        <Field
                          name="name"
                          label={translate("form.labels.companyName")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          styles={{ marginBottom: "0px" }}
                          name="bigChangeContactId"
                          label={translate("form.labels.bigChangeContactId")}
                        >
                          {({ field }) => <Input.Text {...field} disabled />}
                        </Field>
                        <Box className="xl:grid xl:gap-4 xl:grid-cols-2">
                          <Field
                            name="companyCode"
                            label={translate("form.labels.companyCode")}
                          >
                            {({ field }) => <Input.Text {...field} disabled />}
                          </Field>
                          <Field
                            name="companyPrefix"
                            label={translate("form.labels.companyPrefix")}
                          >
                            {({ field }) => <Input.Text {...field} disabled />}
                          </Field>
                        </Box>
                        <Box className="border-t border-gray-400 mt-8 xl:mt-4 pb-2" />
                        <Field
                          name="addressLine1"
                          label={translate("form.labels.addressLine1")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="addressLine2"
                          label={translate("form.labels.addressLine2")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="addressCity"
                          label={translate("form.labels.townCity")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="addressCounty"
                          label={translate("form.labels.county")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Box className="lg:grid lg:gap-x-4 lg:-my-4 lg:grid-cols-2">
                          <Field
                            name="addressPostcode"
                            label={translate("form.labels.postcode")}
                          >
                            {({ field }) => (
                              <Input.Text
                                {...field}
                                disabled={isClearabeeManager}
                              />
                            )}
                          </Field>
                          <Field
                            name="sicCode"
                            label={translate("form.labels.sicCode")}
                          >
                            {({ field }) => (
                              <Input.Text
                                {...field}
                                disabled={isClearabeeManager}
                              />
                            )}
                          </Field>
                        </Box>
                        <Field
                          name="notes"
                          label={translate("form.labels.notes")}
                        >
                          {({ field }) => (
                            <Input.Textarea
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <FieldArray
                          name="bookingLimitDays"
                          render={({ remove, push }) => (
                            <>
                              <Box className="flex mt-3">
                                <Heading level={4} color="brand">
                                  {translate("form.headings.bookingLimitDays")}
                                </Heading>
                              </Box>
                              {values.bookingLimitDays?.map((item, index) => (
                                <Box
                                  key={`bookingLimitDays-${index}-${item.day}`}
                                  className="flex items-center"
                                >
                                  <Box className="w-full h-24 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.day",
                                      )}
                                      name={`bookingLimitDays[${index}].day`}
                                    >
                                      {({ field }) => {
                                        return (
                                          <Input.Select
                                            {...field}
                                            placeholder={translate(
                                              "filters.form.placeholder.selectDay",
                                            )}
                                            options={constantDays.filter(
                                              ({ value }) =>
                                                value === item.day.toString() ||
                                                !values.bookingLimitDays?.some(
                                                  (item) =>
                                                    item.day.toString() ===
                                                    value,
                                                ),
                                            )}
                                            defaultValue={String(item.day)}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Box>
                                  <Box className="w-2/5 h-4 -mt-20 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.limit",
                                      )}
                                      name={`bookingLimitDays[${index}].limit`}
                                    >
                                      {({ field }) => (
                                        <Input.Text
                                          {...field}
                                          type="number"
                                          placeholder={translate(
                                            "filters.form.label.limit",
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Box>
                                  <UnstyledButton
                                    className="mt-5"
                                    onClick={() => remove(index)}
                                  >
                                    <Icon.Trash
                                      size="medium"
                                      color="negative"
                                    />
                                  </UnstyledButton>
                                </Box>
                              ))}
                              <Box className="flex justify-start mt-5 pr-8">
                                <Button
                                  disabled={
                                    values.bookingLimitDays &&
                                    values.bookingLimitDays?.length > 6
                                  }
                                  onClick={() => {
                                    push(defaultDays);
                                  }}
                                  size="small"
                                  type="button"
                                >
                                  {translate(
                                    "steps.productOptions.table.headings.add",
                                  )}
                                </Button>
                              </Box>
                            </>
                          )}
                        />
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <FieldArray
                          name="bookingLimitDates"
                          render={({ push, remove }) => (
                            <>
                              <Box className="flex mt-3">
                                <Heading level={4} color="brand">
                                  {translate("form.headings.bookingLimitDates")}
                                </Heading>
                              </Box>
                              {values.bookingLimitDates?.map((item, index) => (
                                <Box
                                  key={`${index}-${item.date}`}
                                  className="flex items-center"
                                >
                                  <Box className="w-full h-24 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.date",
                                      )}
                                      name={`bookingLimitDates[${index}].date`}
                                    >
                                      {({ field }) => (
                                        <Input.Date
                                          {...field}
                                          dateFormat="YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                          collapsable
                                          disabledDays={(day) =>
                                            values.bookingLimitDates?.some(
                                              (item) =>
                                                dayjs(item.date).format(
                                                  "YYYY-MM-DD",
                                                ) ===
                                                dayjs(day).format("YYYY-MM-DD"),
                                            )
                                          }
                                          initialValue={dayjs(item.date).format(
                                            "YYYY-MM-DD",
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Box>
                                  <Box className="w-2/5 h-4 -mt-20 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.limit",
                                      )}
                                      name={`bookingLimitDates[${index}].limit`}
                                    >
                                      {({ field }) => (
                                        <Input.Text
                                          type="number"
                                          {...field}
                                          defaultValue={item.limit}
                                        />
                                      )}
                                    </Field>
                                  </Box>
                                  <UnstyledButton
                                    className="mt-5"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <Icon.Trash
                                      size="medium"
                                      color="negative"
                                    />
                                  </UnstyledButton>
                                </Box>
                              ))}
                              <Box className="flex justify-start mt-5 pr-8">
                                <Button
                                  onClick={() => {
                                    push(defaultDates);
                                  }}
                                  size="small"
                                  type="button"
                                >
                                  {translate(
                                    "steps.productOptions.table.headings.add",
                                  )}
                                </Button>
                              </Box>
                            </>
                          )}
                        />
                      </Box>
                      {/* Divider */}
                      <Box className="border-t border-gray-400 mt-4 pb-6" />
                      <Box className="w-full md:w-1/2">
                        <Heading level={2} color="brand" fontSize="large">
                          {translate("form.headings.contact")}
                        </Heading>
                        <Field
                          name="contactName"
                          label={translate("form.labels.name")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="contactEmail"
                          label={translate("form.labels.emailAddress")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="contactPhoneNumber"
                          label={translate("form.labels.phone")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <Heading level={4} color="brand" fontSize="large">
                          Catalogue
                        </Heading>
                        <Box className={catalogueError ? "mt-3" : "mt-4"}>
                          {!catalogueError ? (
                            <>
                              <Field
                                name="catalogue"
                                label={translate(
                                  "form.labels.companyCatalogue",
                                )}
                              >
                                {({ field }) => (
                                  <Input.Select
                                    {...field}
                                    isClearable
                                    isSearchable
                                    isLoading={catalogueFetching}
                                    placeholder={translate(
                                      "form.placeholders.selectCompanyCatalogue",
                                    )}
                                    options={
                                      catalogueData?.items.map((catalogue) => ({
                                        label: catalogue.title,
                                        value: catalogue.id,
                                      })) ?? []
                                    }
                                    defaultValue={field.value ?? ""}
                                  />
                                )}
                              </Field>
                              {values?.catalogue && (
                                <Link
                                  to={`/catalogues/update/${values.catalogue}`}
                                >
                                  <Button
                                    as="button"
                                    styles={{
                                      display: "inline-block",
                                    }}
                                    size="medium"
                                  >
                                    {translate(
                                      "form.buttons.labels.viewCatalogue",
                                    )}
                                  </Button>
                                </Link>
                              )}
                            </>
                          ) : (
                            <>
                              {companyCatalogue && (
                                <Box className="mb-3">
                                  <label className="font-semibold text-sm">
                                    {translate("form.labels.companyCatalogue")}:
                                  </label>
                                  <p>{companyCatalogue.title}</p>
                                </Box>
                              )}
                              <Box className="bg-red-500 text-white w-full p-3 text-center rounded-lg">
                                <span className="block">
                                  {translate("errors.fetchingCatalogues")}
                                </span>
                              </Box>
                            </>
                          )}
                        </Box>
                        {/* Divider */}
                        <Box className="border-t border-gray-400 mt-8 pb-8" />
                        <Panel
                          styles={{
                            backgroundColor: hexToRgba(
                              theme.colors.greyscale.lightest,
                              0.4,
                            ),
                          }}
                        >
                          <Heading level={3} color="brand" fontSize="large">
                            {translate("form.headings.settings")}
                          </Heading>
                          <Box className="flex flex-col">
                            <Field name="active" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.companyActive")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field name="settings.canInvoice" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  label={translate("form.labels.canInvoice")}
                                  color="positive"
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field name="settings.hideInvoices" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.hideInvoices")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field name="settings.hidePrices" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.hidePrices")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.requireOrderNumber"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate(
                                    "form.labels.requireOrderNumber",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.orderNumberLabel"
                              label={translate(
                                "form.labels.redefineOrderNumberLabel",
                              )}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.orderNumberValidation"
                              label={translate(
                                "form.labels.orderNumberValidation",
                              )}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  placeholder={translate(
                                    "form.placeholders.orderNumberValidation",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.orderNumberValidationMessage"
                              label={translate(
                                "form.labels.orderNumberValidationMessage",
                              )}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.requireContactDetails"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  disabled={
                                    disableContactDetailsToggle ||
                                    isClearabeeManager
                                  }
                                  color="positive"
                                  label={translate(
                                    "form.labels.requireContactDetails",
                                  )}
                                  styles={{
                                    color: disableContactDetailsToggle
                                      ? "lightGrey"
                                      : "black",
                                  }}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.requirePhoneAndEmail"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate(
                                    "form.labels.requirePhoneAndEmail",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.hideTransferNotes"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate(
                                    "form.labels.hideTransferNotes",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field name="settings.hidePhotos" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.hidePhotos")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field
                              name="settings.identityProvider"
                              label={translate("form.labels.identityProvider")}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field name="settings.ssoOnly" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.ssoOnly")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                          </Box>
                        </Panel>
                      </Box>
                    </Box>
                    <Box className="flex justify-end pt-5 mt-5">
                      <Button
                        disabled={
                          Object.keys(errors).length > 0 ||
                          !isValid ||
                          isUpdateCompanyLoading
                        }
                        type="submit"
                        onClick={() => {
                          handleSubmit(values);
                        }}
                        color="accent"
                        size="medium"
                      >
                        {translate("form.buttons.labels.save")}
                      </Button>
                    </Box>
                  </Panel>
                );
              }}
            </Form>
          </>
        )}
      </Box>
      <SuccessModal
        width={400}
        heading={translate("modal.headings.updateSuccess")}
        onClose={() => setSuccessModal(false)}
        actions={
          <>
            <Button
              size="medium"
              color="accent"
              onClick={() => history.push("/companies")}
            >
              {translate("modal.buttons.labels.backToCompanies")}
            </Button>
          </>
        }
      />
    </>
  );
};
