import React, { useContext } from "react";
import {
  Button,
  Form,
  Input,
  Panel,
  Field,
  Heading,
  Loader,
  Link,
  theme,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "hooks";
import { createUpdateCatalogueValidation } from "../validation";
import { Catalogue } from "../../../api/types";
import { styles } from "./createUpdateCatalogueForm.styles";
import { RouteLink } from "components/core/routeLink";
import { CatalogueEditorContext } from "./catalogueEditor";
import { ButtonLoader } from "./buttonLoader";
import roles from "constants/roles";

export interface CatalogueFormValues
  extends Omit<
    Catalogue,
    "id" | "description" | "vatRate" | "items" | "companies"
  > {
  id?: string;
  description: string;
  vatRate: string;
}

export interface StateData {
  catalogue: Catalogue | undefined;
}

interface CreateUpdateCatalogueFormProps {
  initialValues: CatalogueFormValues;
  heading?: string;
  onSubmit: (values: CatalogueFormValues) => void;
  includeId?: boolean;
  disableSubmit?: boolean;
  isLoading?: boolean;
}

export const parseFormToCatalogue = (
  values: CatalogueFormValues,
): Omit<Catalogue, "items" | "companies" | "id"> => {
  return {
    active: values.active,
    title: values.title,
    description: values.description || null,
    public: values.public,
    hasVat: values.hasVat,
    vatRate: parseInt(values.vatRate) || null,
    applySkipCharge: values.applySkipCharge,
  };
};

export const CreateUpdateCatalogueForm = ({
  initialValues,
  heading,
  onSubmit,
  includeId = false,
  disableSubmit = false,
  isLoading = false,
}: CreateUpdateCatalogueFormProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const { items, companies } = useContext(CatalogueEditorContext);
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);

  return (
    <Form
      initialValues={initialValues}
      validationSchema={createUpdateCatalogueValidation(includeId)}
      onSubmit={(values) => onSubmit(values)}
      enableReinitialize
    >
      {({ values: { id, ...valuesWithoutId } }) => (
        <Panel
          styles={{
            padding: theme.spacing.medium,
          }}
          shadow={false}
        >
          <div className="w-full flex items-center">
            <Heading level={5} color="brand">
              {heading}
            </Heading>
            {!includeId && (
              <RouteLink
                href="/catalogues/create"
                state={{
                  catalogue: {
                    ...parseFormToCatalogue(valuesWithoutId),
                    title: translate("catalogueEditing.copyOf", {
                      title: valuesWithoutId.title,
                    }),
                    companies: companies?.map((company) => ({ code: company })),
                    items,
                  },
                }}
              >
                <Link styles={styles.duplicateLink}>
                  ( {translate("form.buttons.duplicate")} )
                </Link>
              </RouteLink>
            )}
          </div>
          <div className="xl:flex xl:space-x-4 mt-4 border-t">
            {includeId && (
              <div className="w-full">
                <Field name="id" label={translate("form.labels.id")}>
                  {({ field }) => (
                    <Input.Text
                      disabled={isLoading}
                      type="text"
                      placeholder={translate("form.placeholders.id")}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            )}
            <div className="w-full">
              <Field name="title" label={translate("form.labels.title")}>
                {({ field }) => (
                  <Input.Text
                    disabled={isLoading}
                    type="text"
                    placeholder={translate("form.placeholders.title")}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full overflow-hidden">
              <Field
                name="description"
                label={translate("form.labels.description")}
              >
                {({ field }) => (
                  <Input.Textarea
                    disabled={isLoading}
                    autoGrow
                    placeholder={translate("form.placeholders.description")}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="min-w-24">
              <Field name="vatRate" label={translate("form.labels.vatRate")}>
                {({ field }) => (
                  <Input.Text
                    disabled={isLoading}
                    type="text"
                    data-testid="vatRate"
                    prefix="%"
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="flex flex-row space-x-4 relative flex-shrink-0">
              <Field name="hasVat" type="checkbox">
                {({ field }) => (
                  <Input.Toggle
                    disabled={isLoading}
                    color="positive"
                    label={translate("form.labels.hasVat")}
                    styles={styles.toggle}
                    {...field}
                  />
                )}
              </Field>
              <Field name="public" type="checkbox">
                {({ field }) => (
                  <Input.Toggle
                    disabled={isLoading}
                    color="positive"
                    label={translate("form.labels.public")}
                    styles={styles.toggle}
                    {...field}
                  />
                )}
              </Field>
              <Field name="active" type="checkbox">
                {({ field }) => (
                  <Input.Toggle
                    disabled={isLoading}
                    color="positive"
                    label={translate("form.labels.active")}
                    styles={styles.toggle}
                    {...field}
                  />
                )}
              </Field>
              <Field name="applySkipCharge" type="checkbox">
                {({ field }) => (
                  <Input.Toggle
                    disabled={isLoading}
                    color="positive"
                    label={translate("form.labels.applySkipCharge")}
                    styles={styles.toggle}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="flex flex-row items-start mt-8">
              <RouteLink href="/catalogues">
                <Button
                  size="medium"
                  color="negative"
                  styles={styles.cancelButton}
                  as="a"
                  disabled={isLoading}
                >
                  {translate("form.buttons.cancel")}
                </Button>
              </RouteLink>
              <Button
                size="medium"
                color="accent"
                type="submit"
                disabled={disableSubmit || isLoading || isClearabeeManager}
                styles={styles.saveButton}
              >
                {isLoading && <ButtonLoader />}
                {!isLoading && translate("form.buttons.save")}
              </Button>
            </div>
          </div>
        </Panel>
      )}
    </Form>
  );
};
