import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import {
  Button,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  Field,
  Table,
  theme,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { instance } from "@clearabee/ui-sdk";
import { initialValues, validationSchema } from "./validation";
import { ErrorMessage } from "components/common/components";

export const WorksheetOrderTypeValue = (): React.ReactElement => {
  const [editing, setEditing] = useState(false);
  const [translate] = useTranslation("worksheets");

  // READ

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    ["orderTypeValue"],
    async () =>
      (
        await instance.worksheets.getQuestionTypeValues({
          params: { limit: 1000 },
        })
      ).data,
    {
      onError: () => {
        toasts.error({
          message: translate("orderTypeValue.getError"),
        });
      },
    },
  );

  // CREATE
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    async ({ value }: typeof initialValues) =>
      await instance.worksheets.postQuestionTypeValue({ value }),
    {
      onError: () => {
        toasts.error({
          message: translate("orderTypeValue.postError"),
        });
      },
      onSuccess: () => {
        toasts.success({
          message: translate("orderTypeValue.postSuccess"),
        });
      },
    },
  );

  // UPDATE
  const { mutateAsync: patchAsync, isLoading: isPatchLoading } = useMutation(
    async ({ id, value }: typeof initialValues) =>
      await instance.worksheets.patchQuestionTypeValue(id, {
        value,
      }),
    {
      onError: () => {
        toasts.error({
          message: translate("orderTypeValue.patchError"),
        });
      },
      onSuccess: () => {
        toasts.success({
          message: translate("orderTypeValue.patchSuccess"),
        });
      },
    },
  );

  // SUBMIT
  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>,
  ) => {
    if (editing) {
      await patchAsync({ ...values, value: values.value.trim() });
    } else {
      await mutateAsync({ ...values, value: values.value.trim() });
    }
    setEditing(false);
    refetch();
    resetForm();
  };

  if (error) return <ErrorMessage />;

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      className="max-w-screen-lg mx-auto py-10 relative"
      validateOnChange
    >
      {({
        errors,
        isValid,
        setTouched,
        resetForm,
        setValues,
        touched,
        values,
      }) => {
        return (
          <>
            <Panel
              styles={{
                paddingTop: theme.spacing.medium,
                paddingBottom: theme.spacing.medium,
              }}
            >
              <Heading fontSize="large" color="brand">
                {translate(
                  editing
                    ? "orderTypeValue.editOrderTypeValue"
                    : "orderTypeValue.addNewOrderTypeValue",
                )}
              </Heading>
              <div className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />
              <div className="flex flex-col lg:flex-row gap-x-6">
                <Field
                  styles={{ flex: 1 }}
                  name="value"
                  label={translate("orderTypeValue.placeholders.value")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={isMutationLoading || isPatchLoading}
                      placeholder={translate(
                        "orderTypeValue.placeholders.value",
                      )}
                    />
                  )}
                </Field>
                <div className="flex items-center gap-2 relative top-3">
                  {editing && (
                    <Button
                      color={"negative"}
                      type="reset"
                      size="small"
                      onClick={() => {
                        resetForm();
                        setEditing(false);
                      }}
                    >
                      {translate("orderTypeValue.cancel")}
                    </Button>
                  )}
                  <Button
                    color="accent"
                    type="submit"
                    size="small"
                    disabled={
                      !values.value ||
                      !touched ||
                      Object.keys(errors).length > 0 ||
                      isMutationLoading ||
                      isPatchLoading ||
                      !isValid
                    }
                  >
                    {translate(
                      editing ? "orderTypeValue.update" : "orderTypeValue.add",
                    )}
                  </Button>
                </div>
              </div>
            </Panel>
            <div className="relative flex flex-1 min-h-96">
              {isLoading || isFetching ? (
                <Icon.Loading
                  color="brand"
                  styles={{ margin: `${theme.spacing.xlarge} auto` }}
                />
              ) : (
                <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
                  <colgroup>
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "5%" }} />
                  </colgroup>
                  <Table.Header
                    fontSize="small"
                    headings={[
                      translate("orderTypeValue.headings.id"),
                      translate("orderTypeValue.headings.value"),
                      translate("table.headings.action"),
                    ]}
                  />
                  <Table.Body>
                    {data?.items.map((item: any) => (
                      <Table.Row key={`table-row-${item.id}`}>
                        <Table.Cell.Text className="truncate">
                          {item.id}
                        </Table.Cell.Text>
                        <Table.Cell.Text className="truncate">
                          {item.value}
                        </Table.Cell.Text>

                        <Table.Cell>
                          <Button
                            size="xsmall"
                            type="button"
                            className="inline-block text-center"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setEditing(true);
                              setValues({
                                id: item.id?.toString() || "",
                                value: item.value,
                              });
                              setTouched({ id: false, value: false });
                            }}
                          >
                            {translate("table.actions.edit")}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </div>
          </>
        );
      }}
    </Form>
  );
};
