"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchPanel = void 0;
exports.searchPanel = {
    searchJobVehicles: "Search for job, vehilcle, waste transfer site, maintence, garage and more...",
    jobs: "Jobs",
    vehicles: "Vehicles",
    wasteTransferSites: "Waste Transfer Sites",
    shellFuelStations: "Shell Fuel Stations",
    maintenanceGarages: "Maintenance Garages",
    activeDrivers: "Active Drivers",
};
