import React from "react";
import { Link } from "react-router-dom";

/**
 * Import images.
 */
import { SingleArrow } from "../../../images";

/**
 * Type props for button.
 */
type IBackButton = {
  to?: string;
  onClick?: () => void;
  text: string;
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const BackButton: React.FC<IBackButton> = ({ to, onClick, text }) => {
  /**
   * Output the button.
   */
  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className="inline-flex flex-row items-center"
      >
        <span className="text-primary">
          <SingleArrow
            className="transform -rotate-180"
            height={15}
            width={15}
          />
        </span>
        <span className="font-semibold ml-3">{text}</span>
      </button>
    );
  }

  if (to) {
    return (
      <Link to={to} className="inline-flex flex-row items-center">
        <span className="text-primary">
          <SingleArrow
            className="transform -rotate-180"
            height={15}
            width={15}
          />
        </span>
        <span className="font-semibold ml-3">{text}</span>
      </Link>
    );
  }

  return null;
};
