import React, { useRef } from "react";
import Select, { OptionTypeBase } from "react-select";
import { useMutation } from "react-query";
import { toasts } from "helpers/toasts";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import {
  Input,
  Form,
  Field,
  Heading,
  OptionProps,
  Button,
  theme,
  Box,
  Icon,
  Panel,
  useModal,
  Text,
} from "@clearabee/ui-library";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../components";
import { statusMap } from "components/worksheets/updateWorksheet/updateWorksheetHelpers";
import {
  convertCamelCaseToSentenceCase,
  getAvailableParentOptions,
  getQuestionTypeIdsByName,
  getPatchBodyFromValues,
  getPostBodyFromValues,
  getDisplayConditionOptions,
  getPatchBodiesForPosition,
  getQuestionById,
  getQuestionsWithToggleAllMeta,
  getToggleAllPatchBodies,
  removeQuestion,
} from "./questionModalHelpers";
import { getValidationSchema } from "./questionModalValidation";
import { InitialValues, getInitialValues } from "./questionModalInitialValues";
import { styles } from "./questionModal.styles";
import type { FormikProps } from "formik";
import type {
  IQuestionType,
  IQuestionTypeValue,
  IWorksheet,
  TWorksheetQuestionPostBody,
} from "@clearabee/api-schemas";
import type { RequireID, TQuestion } from "../../../worksheetTypes";
import type { QuestionPatchBody } from "./questionModalTypes";

interface QuestionModalProps {
  questionHistory?: RequireID<TQuestion>[]; // Optional as we're using this component for both creating and editing questions
  questions: TQuestion[];
  questionTypes: Required<IQuestionType>[];
  questionTypeValues: Required<IQuestionTypeValue>[];
  worksheetId: Required<IWorksheet>["id"];
  refetchWorksheet: () => void;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionHistory: React.Dispatch<
    React.SetStateAction<RequireID<TQuestion>[] | undefined>
  >;
}

export const QuestionModal = ({
  questionHistory,
  questions,
  questionTypes,
  questionTypeValues,
  worksheetId,
  refetchWorksheet,
  setModalVisible,
  setQuestionHistory,
}: QuestionModalProps): React.ReactElement => {
  const [translate] = useTranslation("worksheets");
  const [DeleteModal, setShowDeleteModal] = useModal();
  const formRef = useRef<FormikProps<InitialValues>>(null); // So the form's values can be accessed in onSuccess handlers in react-query

  const question = questionHistory?.[questionHistory.length - 1]; // May be undefined, as using for both creating and editing questions

  // LIST OPTIONS - (labels and values for Select components)
  const typeOptions: OptionProps[] = questionTypes
    .map(({ name, id }) => ({
      label: convertCamelCaseToSentenceCase(name),
      value: id,
    }))
    .sort(({ label: aLabel }, { label: bLabel }) =>
      aLabel.localeCompare(bLabel),
    );

  const statusOptions: OptionProps[] = Object.entries(statusMap).map(
    ([statusId, status]) => ({ label: status, value: Number(statusId) }),
  );

  // For single and multi-select list question types
  const typeValuesOptions: OptionProps[] = questionTypeValues.map(
    ({ value, id }) => {
      return { label: value, value: id };
    },
  );

  // QUESTION-TYPE IDs
  // These ids are used to conditionally render questions depending on the question-type selected.
  // Instead of hard-coding ids, they're found dynamically, as ids could change in the future.
  const listQuestionIds = getQuestionTypeIdsByName("list", questionTypes);
  const booleanQuestionIds = getQuestionTypeIdsByName("boolean", questionTypes);
  const counterQuestionIds = getQuestionTypeIdsByName("counter", questionTypes);
  const modalQuestionIds = getQuestionTypeIdsByName("modal", questionTypes);

  // INITIAL VALUES and VALIDATION SCHEMA
  const initialValues = getInitialValues(questions, question);
  const validationSchema = getValidationSchema(questionTypes);

  /**
   * Fix positioning for this question's branch and display-condition.
   * (This is here to avoid repetition within patch, post and delete onSuccess handlers.)
   */
  const fixPositioning = async (
    question: TQuestion,
    questions: TQuestion[],
  ): Promise<void> => {
    const postionPatchBodies = getPatchBodiesForPosition(question, questions);

    if (postionPatchBodies.length) {
      const promises = postionPatchBodies.map((positionPatchBody) =>
        patchQuestionAsync(positionPatchBody),
      );

      await Promise.all(promises);
    }
  };

  /**
   * Remove toggleAll:true from any questions that have it in their meta property.
   *
   * With boolean questions, toggle-all functionality is set by a property in a question's meta.
   * If any question in a worksheet contains toggleAll:true in its meta, a toggle-all button will be shown on the worksheet in the Drivers App.
   * When updating or creating a question of type boolean, and removing the toggle-all functionality, any question containing toggleAll:true in its meta must be updated,
   * or the toggle-all button will still be shown on the worksheet in the Drivers App.
   * (Due to data inconsistency in the DB, there may be multiple questions that contain a meta of toggleAll:true.)
   *
   * @param questions Questions from which toggleAll:true meta should be removed
   * @param question  Question being edited, to avoid making an unnecessary extra patch call
   */
  const removeToggleAllMeta = async (
    questions: TQuestion[],
    question?: TQuestion,
  ): Promise<void> => {
    const questionsWithToggleAllTrue = getQuestionsWithToggleAllMeta(
      question ? removeQuestion(question, questions) : questions, // Needn't get a patch body for the question we've just upserted, so removing it from the array
    );

    const toggleAllPatchBodies = getToggleAllPatchBodies(
      questionsWithToggleAllTrue as RequireID<TQuestion>[],
    );

    const promises = toggleAllPatchBodies.map((toggleAllPatchBody) => {
      return patchQuestionAsync(toggleAllPatchBody);
    });

    await Promise.all(promises);
  };

  const { mutate: postQuestion, isLoading: postQuestionIsLoading } =
    useMutation(
      (postBody: TWorksheetQuestionPostBody) => {
        return instance.worksheets.postQuestion(postBody);
      },
      {
        onSuccess: async ({ data: postedQuestion }) => {
          await fixPositioning(postedQuestion as TQuestion, questions);

          const typeId = formRef.current?.values.typeId;
          const booleanQuestionToggleAll =
            formRef.current?.values.booleanQuestionToggleAll;

          if (
            typeId &&
            booleanQuestionIds.includes(typeId as number) &&
            booleanQuestionToggleAll === false
          ) {
            await removeToggleAllMeta(questions, question);
          }

          toasts.success({
            message: translate("updateWorksheet.questionModal.toasts.created"),
          });

          refetchWorksheet();
          setModalVisible(false);
          setQuestionHistory(undefined);
        },
        onError: () => {
          toasts.error({
            message: translate("updateWorksheet.questionModal.toasts.error"),
          });
        },
      },
    );

  const { mutate: patchQuestion, isLoading: patchQuestionisLoading } =
    useMutation(
      ({ id, ...update }: QuestionPatchBody) => {
        return instance.worksheets.patchQuestion(String(id), update); // instance expects a string, as id is a path parameter
      },
      {
        onSuccess: async ({ data: patchedQuestion }) => {
          await fixPositioning(patchedQuestion as TQuestion, questions);

          const typeId = formRef.current?.values.typeId;
          const booleanQuestionToggleAll =
            formRef.current?.values.booleanQuestionToggleAll;

          if (
            typeId &&
            booleanQuestionIds.includes(typeId as number) &&
            booleanQuestionToggleAll === false
          ) {
            await removeToggleAllMeta(questions, question);
          }

          toasts.success({
            message: translate("updateWorksheet.questionModal.toasts.updated"),
          });

          refetchWorksheet();
          setModalVisible(false);
          setQuestionHistory(undefined);
        },
        onError: () => {
          toasts.error({
            message: translate("updateWorksheet.questionModal.toasts.error"),
          });
        },
      },
    );

  // This is so we can run multiple patch requests in parallel when fixing positioning or removing toggle-all meta.
  const {
    mutateAsync: patchQuestionAsync,
    isLoading: patchQuestionAsyncIsLoading,
  } = useMutation(({ id, ...update }: QuestionPatchBody) => {
    return instance.worksheets.patchQuestion(String(id), update); // instance expects a string, as id is a path parameter
  });

  const { mutate: deleteQuestion, isLoading: deleteQuestionIsLoading } =
    useMutation(
      (id: number) => {
        return instance.worksheets.deleteQuestion(String(id));
      },
      {
        onSuccess: async (data) => {
          // Need to find the question by its id in the questions' array, and then use that to get the position patch bodies
          const deletedQuestionId = data.config.url?.lastIndexOf("/")
            ? data.config.url?.slice(data.config.url?.lastIndexOf("/") + 1)
            : null;

          const question = getQuestionById(
            Number(deletedQuestionId),
            questions,
          );

          if (question) {
            await fixPositioning(question, questions);
          }

          toasts.success({
            message: translate("updateWorksheet.questionModal.toasts.deleted"),
          });

          refetchWorksheet();
          setModalVisible(false);
          setQuestionHistory(undefined);
        },
        onError: () => {
          toasts.error({
            message: translate("updateWorksheet.questionModal.toasts.error"),
          });
        },
      },
    );

  return (
    <Box styles={styles.container}>
      <Form
        innerRef={formRef} // So values can be accessed in onSuccess handlers for patch and post above
        styles={styles.form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={(values) => {
          // PATCH
          if (question) {
            const patchBody = getPatchBodyFromValues(
              question,
              questions,
              questionTypes,
              values,
            );

            patchQuestion(patchBody);
          } else {
            // POST
            const postBody = getPostBodyFromValues(
              questions,
              questionTypes,
              values,
              worksheetId,
            );

            postQuestion(postBody);
          }
        }}
      >
        {({ values, errors, setFieldValue }) => {
          const parentOptions = getAvailableParentOptions(
            values,
            questions,
            questionTypes,
            question,
          );

          const displayConditionOptions = getDisplayConditionOptions(
            values.parentId as number,
            questions,
          );

          return (
            <>
              <Box className="flex flex-row justify-between items-center w-full">
                <div>
                  <Heading
                    styles={{ textAlign: "left" }}
                    level={2}
                    color="brand"
                  >
                    {question
                      ? translate(
                          "updateWorksheet.questionModal.headings.editQuestion",
                        )
                      : translate(
                          "updateWorksheet.questionModal.headings.createQuestion",
                        )}
                  </Heading>

                  {/* BACK BUTTON */}
                  {!!questionHistory && questionHistory.length > 1 && (
                    <div
                      onClick={() => {
                        // Set the previous question as the current question, i.e. the last item in the array
                        setQuestionHistory(questionHistory?.slice(0, -1));
                      }}
                      style={styles.backButton}
                    >
                      <Icon.Chevron
                        size="small"
                        style={{ transform: "rotate(180deg)" }}
                      />
                      <Text style={{ cursor: "pointer" }}>
                        {translate(
                          "updateWorksheet.questionModal.buttons.back",
                        )}
                      </Text>
                    </div>
                  )}
                </div>

                <Box className="flex flex-row gap-3">
                  {/* TOOLTIP */}
                  {!!question?.children?.length && (
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question color="brand" size="small" />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "updateWorksheet.questionModal.tooltips.delete",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  )}

                  {/* DELETE BUTTON */}
                  {question && question.id && (
                    <>
                      <Button
                        size="medium"
                        type="button"
                        color="negative"
                        onClick={() => {
                          setShowDeleteModal(true);
                        }}
                        disabled={
                          !!question?.children?.length ||
                          patchQuestionisLoading ||
                          patchQuestionAsyncIsLoading
                        }
                      >
                        {translate(
                          "updateWorksheet.questionModal.buttons.delete",
                        )}
                      </Button>

                      {/* DELETE QUESTION CONFIRMATION MODAL */}
                      <DeleteModal
                        onClose={() => setShowDeleteModal(false)}
                        styles={styles.deleteModal}
                      >
                        <Text className="font-semibold">
                          {translate(
                            "updateWorksheet.questionModal.headings.areYouSure",
                          )}
                        </Text>
                        <Button
                          size="small"
                          color="negative"
                          onClick={() => deleteQuestion(question.id)}
                        >
                          {deleteQuestionIsLoading ? (
                            <Box className="flex justify-center">
                              <Icon.Loading color="dark" size="medium" />
                            </Box>
                          ) : (
                            translate(
                              "updateWorksheet.questionModal.buttons.delete",
                            )
                          )}
                        </Button>
                      </DeleteModal>
                    </>
                  )}

                  {/* SAVE BUTTON */}
                  <Button
                    disabled={deleteQuestionIsLoading}
                    size="medium"
                    color="accent"
                    type="submit"
                  >
                    {postQuestionIsLoading || patchQuestionisLoading ? (
                      <Box className="flex justify-center">
                        <Icon.Loading color="dark" size="medium" />
                      </Box>
                    ) : (
                      translate("updateWorksheet.questionModal.buttons.save")
                    )}
                  </Button>
                </Box>
              </Box>

              {/* DIVIDER */}
              <Box className="border-t border-grey-200 my-4 flex flex-col w-full" />

              <Box className="flex flex-row flex-wrap items-start w-full -mx-3 pb-3">
                {/* QUESTION TEXT */}
                <Box className="w-1/2 px-3">
                  <Field
                    name="question"
                    label={translate(
                      "updateWorksheet.questionModal.labels.question",
                    )}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "updateWorksheet.questionModal.placeholders.question",
                        )}
                      />
                    )}
                  </Field>
                </Box>

                {/* BC QUESTION-ID */}
                <Box className="w-2/12 px-3">
                  <Field
                    name="bcQuestionId"
                    label={translate(
                      "updateWorksheet.questionModal.labels.bcQuestionId",
                    )}
                  >
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </Box>

                {/* POSITION */}
                <Box className="w-1/12 px-3">
                  <Field
                    name="position"
                    label={translate(
                      "updateWorksheet.questionModal.labels.position",
                    )}
                  >
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </Box>

                {/* STATUS UPDATE */}
                <Box className="flex flex-row items-start px-3 w-3/12">
                  <Field
                    name="statusId"
                    label={translate(
                      "updateWorksheet.questionModal.labels.status",
                    )}
                    styles={{ width: "100%" }}
                  >
                    {({ field }) => (
                      <Select
                        {...field}
                        isClearable
                        options={statusOptions}
                        placeholder=""
                        value={
                          values.statusId
                            ? statusOptions.find(
                                ({ value }) => value === values.statusId,
                              )
                            : initialValues.statusId
                        }
                        onChange={(option) => {
                          field.onChange(option?.value);
                        }}
                      />
                    )}
                  </Field>

                  {/* Tooltip */}
                  <Tooltip placement="top">
                    <TooltipTrigger>
                      <Icon.Question
                        styles={styles.questionMark}
                        color="brand"
                        size="small"
                      />
                    </TooltipTrigger>
                    <TooltipContent style={{ zIndex: 999 }} className="Tooltip">
                      <Panel styles={styles.tooltipPanel}>
                        {translate(
                          "updateWorksheet.questionModal.tooltips.statusUpdate",
                        )}
                      </Panel>
                    </TooltipContent>
                  </Tooltip>
                </Box>

                {/* QUESTION TYPE */}
                <Box className="flex flex-row items-start w-1/3 px-3">
                  <Field
                    name="typeId"
                    label={translate(
                      "updateWorksheet.questionModal.labels.type",
                    )}
                    styles={{ width: "100%" }}
                  >
                    {({ field }) => (
                      <Select
                        {...field}
                        isSearchable
                        isDisabled={!!question?.children?.length}
                        isClearable
                        options={typeOptions}
                        value={
                          values.typeId
                            ? typeOptions.find(
                                ({ value }) => value === values.typeId,
                              )
                            : initialValues.typeId
                        }
                        placeholder={translate(
                          "updateWorksheet.questionModal.placeholders.type",
                        )}
                        onChange={(option: OptionProps & { value: number }) => {
                          // Modals may only be level 0 questions, as they are shown first before any other question on worksheets in the Drivers App. (Currently, they are not triggered by a parent-question's answer.)
                          // For this reason, if there is a previous parentId in state, we need to clear it.
                          if (
                            option?.value &&
                            modalQuestionIds.includes(option.value)
                          ) {
                            setFieldValue("parentId", "");
                            setFieldValue("displayCondition", "");
                          }

                          field.onChange(option?.value);
                        }}
                      />
                    )}
                  </Field>

                  {/* Tooltip */}
                  {!!question?.children?.length && (
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "updateWorksheet.questionModal.tooltips.questionType",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </Box>

                {/* BOOLEAN PARENT-QUESTION TOGGLE (DISPLAY AS YES/NO LIST) - ONLY IF TYPE IS BOOLEAN */}
                {booleanQuestionIds.includes(values.typeId as number) && (
                  <Box className="flex flex-row items-start px-3">
                    {/* Although we do not submit a booleanParentQuestion field, we
              use it to store the state of the toggle in React and to set
              meta.defaultSelected on submission */}
                    <Field
                      name="booleanParentQuestion"
                      label={translate(
                        "updateWorksheet.questionModal.labels.booleanParentQuestion",
                      )}
                    >
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          checked={values.booleanParentQuestion ?? false}
                        />
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "updateWorksheet.questionModal.tooltips.booleanParent",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                )}

                {/* BOOLEAN TOGGLE-ALL OPTION  - ONLY IF TYPE IS BOOLEAN */}
                {booleanQuestionIds.includes(values.typeId as number) &&
                  !values.booleanParentQuestion && (
                    <Box className="flex flex-row items-start px-3">
                      <Field
                        name="booleanQuestionToggleAll"
                        label={translate(
                          "updateWorksheet.questionModal.labels.booleanToggleAll",
                        )}
                      >
                        {({ field }) => (
                          <Input.Toggle
                            {...field}
                            checked={values.booleanQuestionToggleAll ?? false}
                          />
                        )}
                      </Field>

                      {/* Tooltip */}
                      <Tooltip placement="top">
                        <TooltipTrigger>
                          <Icon.Question
                            styles={styles.questionMark}
                            color="brand"
                            size="small"
                          />
                        </TooltipTrigger>
                        <TooltipContent
                          style={{ zIndex: 999 }}
                          className="Tooltip"
                        >
                          <Panel styles={styles.tooltipPanel}>
                            {translate(
                              "updateWorksheet.questionModal.tooltips.booleanToggleAll",
                            )}
                          </Panel>
                        </TooltipContent>
                      </Tooltip>
                    </Box>
                  )}
                {/* BOOLEAN REQUIRED-VALUE TRUE OPTION - ONLY IF TYPE IS BOOLEAN */}
                {booleanQuestionIds.includes(values.typeId as number) &&
                  !values.booleanParentQuestion && (
                    <Box className="flex flex-row items-start px-3">
                      <Field
                        name="booleanQuestionRequiredValueTrue"
                        label={translate(
                          "updateWorksheet.questionModal.labels.booleanRequiredValueTrue",
                        )}
                      >
                        {({ field }) => (
                          <Input.Toggle
                            {...field}
                            checked={
                              values.booleanQuestionRequiredValueTrue ?? false
                            }
                          />
                        )}
                      </Field>

                      {/* Tooltip */}
                      <Tooltip placement="top">
                        <TooltipTrigger>
                          <Icon.Question
                            styles={styles.questionMark}
                            color="brand"
                            size="small"
                          />
                        </TooltipTrigger>
                        <TooltipContent
                          style={{ zIndex: 999 }}
                          className="Tooltip"
                        >
                          <Panel styles={styles.tooltipPanel}>
                            {translate(
                              "updateWorksheet.questionModal.tooltips.booleanRequiredValueTrue",
                            )}
                          </Panel>
                        </TooltipContent>
                      </Tooltip>
                    </Box>
                  )}
                {/* LIST VALUES */}
                {listQuestionIds.includes(values.typeId as number) && (
                  <Box className="w-1/3 px-3">
                    <Field
                      name="typeValues"
                      label={translate(
                        "updateWorksheet.questionModal.labels.typeValues",
                      )}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          isSearchable
                          isClearable
                          closeMenuOnSelect={false}
                          placeholder={translate(
                            "updateWorksheet.questionModal.placeholders.typeValues",
                          )}
                          options={typeValuesOptions}
                          value={
                            values.typeValues?.length
                              ? typeValuesOptions.filter(
                                  ({ value: typeValueId }) => {
                                    return values.typeValues?.some((id) => {
                                      return id === typeValueId;
                                    });
                                  },
                                )
                              : []
                          }
                          onChange={(options: readonly OptionTypeBase[]) => {
                            if (!options) {
                              field.onChange([]); // Clear the field if no options are selected
                              return;
                            }

                            field.onChange(options.map(({ value }) => value));
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                )}
                {/* COUNTER AS SCROLLABLE LIST TOGGLE OPTION */}
                {counterQuestionIds.includes(values.typeId as number) && (
                  <Box className="flex flex-row items-start px-3">
                    <Field
                      name="counterAsList"
                      label={translate(
                        "updateWorksheet.questionModal.labels.counterAsList",
                      )}
                    >
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          checked={values.counterAsList ?? false}
                        />
                      )}
                    </Field>

                    {/* Tooltip */}
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question
                          styles={styles.questionMark}
                          color="brand"
                          size="small"
                        />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 999 }}
                        className="Tooltip"
                      >
                        <Panel styles={styles.tooltipPanel}>
                          {translate(
                            "updateWorksheet.questionModal.tooltips.counterAsList",
                          )}
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </Box>
                )}
                {/* COUNTER AS SCROLLABLE LIST SETTINGS */}
                {counterQuestionIds.includes(values.typeId as number) &&
                  values.counterAsList && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="w-1/3 px-3 gap-6"
                    >
                      {/* START */}
                      <Field
                        name="counterStart"
                        label={translate(
                          "updateWorksheet.questionModal.labels.counterStart",
                        )}
                      >
                        {({ field }) => <Input.Text {...field} />}
                      </Field>

                      {/* END */}
                      <Field
                        name="counterEnd"
                        label={translate(
                          "updateWorksheet.questionModal.labels.counterEnd",
                        )}
                      >
                        {({ field }) => <Input.Text {...field} />}
                      </Field>

                      {/* INCREMENT */}
                      <Box className="flex flex-row items-start px-3">
                        <Field
                          name="counterIncrement"
                          label={translate(
                            "updateWorksheet.questionModal.labels.counterIncrement",
                          )}
                        >
                          {({ field }) => <Input.Text {...field} />}
                        </Field>

                        {/* Tooltip */}
                        <Tooltip placement="top">
                          <TooltipTrigger>
                            <Icon.Question
                              styles={styles.questionMark}
                              color="brand"
                              size="small"
                            />
                          </TooltipTrigger>
                          <TooltipContent
                            style={{ zIndex: 999 }}
                            className="Tooltip"
                          >
                            <Panel styles={styles.tooltipPanel}>
                              {translate(
                                "updateWorksheet.questionModal.tooltips.counterAsAList",
                              )}
                            </Panel>
                          </TooltipContent>
                        </Tooltip>
                      </Box>
                    </div>
                  )}
                {/* MODAL QUESTION TEXT */}
                {modalQuestionIds.includes(values.typeId as number) && (
                  <Box className="w-1/3 px-3">
                    <Field
                      name="modalText"
                      label={translate(
                        "updateWorksheet.questionModal.labels.modalText",
                      )}
                    >
                      {({ field }) => (
                        <Input.Textarea
                          styles={{
                            height: theme.spacing.xlarge,
                          }}
                          {...field}
                        />
                      )}
                    </Field>
                  </Box>
                )}

                {/* PARENT QUESTION */}
                <Box className="flex w-full">
                  <Box className="w-1/2 px-3">
                    <Field
                      name="parentId"
                      label={translate(
                        "updateWorksheet.questionModal.labels.parentQuestion",
                      )}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          options={parentOptions}
                          value={
                            values.parentId
                              ? parentOptions.find(
                                  ({ value }) => value === values.parentId,
                                )
                              : initialValues.parentId
                          }
                          placeholder={translate(
                            "updateWorksheet.questionModal.placeholders.parentQuestion",
                          )}
                          onChange={(option) => {
                            setFieldValue("displayCondition", ""); // Or the initial-value or last selected value will remain and may not be one of the options for parent answer
                            field.onChange(option?.value);
                          }}
                        />
                      )}
                    </Field>
                  </Box>

                  {/* DISPLAY CONDITION */}
                  {!!values.parentId && (
                    <Box className="flex flex-row items-start px-3 w-1/4">
                      <Field
                        name="displayCondition"
                        label={translate(
                          "updateWorksheet.questionModal.labels.displayCondition",
                        )}
                        styles={{ width: "100%" }}
                      >
                        {({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            options={displayConditionOptions}
                            value={
                              values.displayCondition
                                ? displayConditionOptions.find(
                                    ({ value }) =>
                                      value.toLowerCase() ===
                                      values.displayCondition?.toLowerCase(),
                                  )
                                : ""
                            }
                            placeholder={translate(
                              "updateWorksheet.questionModal.placeholders.displayCondition",
                            )}
                            onChange={(option) => {
                              field.onChange(option?.value);
                            }}
                          />
                        )}
                      </Field>

                      {/* Tooltip */}
                      <Tooltip placement="top">
                        <TooltipTrigger>
                          <Icon.Question
                            styles={styles.questionMark}
                            color="brand"
                            size="small"
                          />
                        </TooltipTrigger>
                        <TooltipContent
                          style={{ zIndex: 999 }}
                          className="Tooltip"
                        >
                          <Panel styles={styles.tooltipPanel}>
                            {translate(
                              "updateWorksheet.questionModal.tooltips.displayCondition",
                            )}
                          </Panel>
                        </TooltipContent>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* CHILD QUESTIONS */}
              {!!question?.children?.length && (
                <div
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    padding: theme.spacing.xsmall,
                    marginBottom: theme.spacing.small,
                    width: "100%",
                  }}
                >
                  <Box className="flex flex-row items-start mb-4">
                    <Heading
                      level={3}
                      style={{
                        ...theme.fontDefaults.base,
                      }}
                    >
                      {translate(
                        "updateWorksheet.questionModal.headings.childQuestions",
                      )}
                    </Heading>
                  </Box>

                  {question?.children &&
                    // Group child-questions by display-conditions, e.g. group all 'TRUE' display-conditions together
                    question.children
                      .sort(
                        (
                          { displayCondition: displayConditionA },
                          { displayCondition: displayConditionB },
                        ) =>
                          (displayConditionA as string).localeCompare(
                            displayConditionB as string,
                          ),
                      )
                      .map((child, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              textAlign: "left",
                              marginBottom: theme.spacing.small,
                            }}
                          >
                            {translate(
                              "updateWorksheet.questionModal.text.childQuestionIfClause",
                            )}
                            <span style={styles.childQuestionDisplayCondition}>
                              {/* display-conditions are compared using .toLowerCase() in the Drivers app */}
                              {String(child.displayCondition).toLowerCase()}
                            </span>
                            {translate(
                              "updateWorksheet.questionModal.text.childQuestionThenClause",
                            )}
                            <span
                              style={styles.childQuestionText}
                              onClick={() => {
                                setQuestionHistory([
                                  ...(questionHistory ?? []),
                                  child as any,
                                ]);
                              }}
                            >
                              {child.question}
                            </span>
                          </div>
                        );
                      })}
                </div>
              )}
              {!!Object.keys(errors).length && console.log("errors", errors)}
            </>
          );
        }}
      </Form>
    </Box>
  );
};
