import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import clipboardCopy from "clipboard-copy";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Heading,
  Icon,
  Panel,
  Table,
  Button,
  theme,
  usePagination,
  useModal,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { styles } from "./failedJobs.styles";

type DataType = ApiResponseData<typeof instance.jobs.getFailedJobs>;

const paramsInitialValues = {
  offset: 0,
  limit: 10,
};

export const FailedJobs = (): React.ReactElement => {
  const [data, setData] = useState<DataType["items"]>();
  const [params, setParams] = useState(paramsInitialValues);
  const [totalPages, setTotalPages] = useState(1);
  const [translate] = useTranslation("jobs");
  const query = new URLSearchParams(location.search.replace("?", ""));
  const [offsetQuery, setOffsetQuery] = useState(
    query.get("offset") ?? paramsInitialValues.offset,
  );
  const [limitQuery, setLimitQuery] = useState(
    query.get("limit") ?? paramsInitialValues.limit,
  );
  const [payload, setPayload] =
    useState<DataType["items"][number]["payload"]>();
  const [PayloadModal, showPayloadModal] = useModal();

  // READ
  const { isLoading, isFetching } = useQuery(
    ["failedJobs", params, offsetQuery, limitQuery],
    async () => {
      const queryParams = {
        offset: params.offset,
        limit: params.limit,
      };

      return (
        await instance.jobs.getFailedJobs({
          params: queryParams,
        })
      ).data;
    },
    {
      onError: (error) => {
        toasts.error({
          message: (error as Error).message,
        });
      },
      onSuccess: (data) => {
        setData(data.items);

        setTotalPages(
          Math.ceil(
            params.limit === 0
              ? data.pagination.total / paramsInitialValues.limit
              : data.pagination.total / params.limit,
          ),
        );
      },
      refetchOnMount: true,
    },
  );

  const { PaginationComponent, paginatedData, dataRange, paginationState } =
    usePagination(data ?? [], {
      totalPages: totalPages,
    });

  useEffect(() => {
    setParams({
      offset: Number(offsetQuery) || dataRange[0],
      limit: Number(limitQuery) || paginationState?.resultsPerPage,
    });
    setOffsetQuery(dataRange[0]);
    setLimitQuery(paginationState?.resultsPerPage);
  }, [paginationState]);

  /**
   * Dispay selected payload in a modal
   */
  const viewPayload = (payload: DataType["items"][number]["payload"]) => {
    setPayload(payload);
    showPayloadModal(true);
  };

  return (
    <div className="mx-auto py-10 relative">
      <Panel>
        <Heading fontSize="xlarge2" color="brand">
          {translate("failedJobs.pageHeading")}
        </Heading>
      </Panel>
      <div className="relative flex flex-1">
        {isLoading || isFetching ? (
          <Icon.Loading
            color="brand"
            styles={{ margin: `${theme.spacing.xlarge} auto` }}
          />
        ) : (
          <Table className="mt-10">
            <Table.Header
              fontSize="small"
              headings={[
                translate("failedJobs.columns.orderRef"),
                translate("failedJobs.columns.topicArn"),
                translate("failedJobs.columns.attempts"),
                translate("failedJobs.columns.processed"),
                translate("failedJobs.columns.createdOn"),
                translate("failedJobs.columns.action"),
              ]}
            />
            <Table.Body>
              {paginatedData?.map((item) => (
                <Table.Row key={`table-row-${item.id}`}>
                  <Table.Cell.Text>{item.orderRef}</Table.Cell.Text>
                  <Table.Cell.Text>{item.topicArn}</Table.Cell.Text>
                  <Table.Cell.Text>{item.attempts}</Table.Cell.Text>
                  <Table.Cell.Text>{String(item.processed)}</Table.Cell.Text>
                  <Table.Cell.Text>
                    {dayjs(item.createdOn).format("DD/MM/YYYY HH:mm:ss")}
                  </Table.Cell.Text>
                  <Table.Cell>
                    <Button
                      size="xsmall"
                      type="button"
                      onClick={() => viewPayload(item.payload)}
                    >
                      {translate("failedJobs.buttons.viewPayload")}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      <div className="mt-10">
        <PaginationComponent />
      </div>
      <PayloadModal
        width={800}
        onClose={() => showPayloadModal(false)}
        styles={styles.payloadModal}
      >
        <>
          <div className="flex items-center gap-2">
            <Heading level={2} color="brand">
              {translate("failedJobs.payloadModalHeading")}
            </Heading>
            <Button
              size="xsmall"
              styles={styles.copyButton}
              onClick={async () => {
                await clipboardCopy(JSON.stringify(payload));
                toasts.success(
                  {
                    message: translate("failedJobs.copied"),
                  },
                  { autoClose: 1000 },
                );
              }}
            >
              <Icon.Clipboard size="small" />
              {translate("failedJobs.buttons.copy")}
            </Button>
          </div>
          <div css={styles.json} className="bg-gray-200">
            <pre>{JSON.stringify(payload, null, 2)}</pre>
          </div>
        </>
      </PayloadModal>
    </div>
  );
};
