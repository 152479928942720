import React from "react";
import { Stripe, StripeProps } from "@clearabee/ui-sdk";
import { Button, Icon, Message } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";

export interface StripeBillingFormProps
  extends Omit<StripeProps<true>, "publicKey"> {
  contact: {
    email?: string;
    name: string;
    phone?: string;
  };
  disableButton?: boolean;
  showCardholderNameField?: boolean;
  clientSecret?: string;
}
/**
 * New and improved Stripe with billing form component.
 */
export const StripeBillingForm = ({
  contact,
  disableButton = false,
  showCardholderNameField = false,
  ...props
}: StripeBillingFormProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  return (
    <Stripe
      publicKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ""}
      amount={props.amount ?? 0}
      onBeforeFormSubmit={props.onBeforeFormSubmit}
      onPaymentSuccess={props.onPaymentSuccess}
      clientSecret={props.clientSecret}
      showAddressFields
    >
      {({
        PaymentElement,
        handleFormSubmission,
        isCardValid,
        isProcessing,
        errorMessage,
        elementReady,
      }) => (
        <>
          <div className="relative mb-4">
            <div
              className={
                isProcessing ? "opacity-75 pointer-events-none relative" : ""
              }
            >
              <PaymentElement />
            </div>
            {(isProcessing || !elementReady) && (
              <Icon.Loading
                color="positive"
                styles={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </div>
          {errorMessage && (
            <Message type="error" background>
              {errorMessage}
            </Message>
          )}
          <div className="text-center">
            <Button
              id="stripeCompleteOrderButton"
              type="button"
              size="medium"
              color="accent"
              className="mt-4"
              onClick={() => handleFormSubmission()}
              disabled={disableButton || isProcessing || !isCardValid}
            >
              {translate("completeOrder")}
            </Button>
          </div>
        </>
      )}
    </Stripe>
  );
};
