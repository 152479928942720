import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  status: Yup.string().oneOf(["pending", "approved", "all"]),
  subcontractor: Yup.string(),
});

export const initialValues = {
  status: "",
  subcontractor: "",
};
