import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Text, theme, Icon } from "@clearabee/ui-library";
import { instance, ApiResponseData } from "@clearabee/ui-sdk";

interface PasswordRequirementsType {
  password: string;
  passwordPolicy: ApiResponseData<typeof instance.users.getPasswordPolicy>;
}

const PasswordPolicyItem = ({
  description,
  condition,
}: {
  description: string;
  condition: boolean;
}): React.ReactElement => {
  const getIcon = useCallback((condition: boolean): React.ReactElement => {
    if (condition) {
      return <Icon.Tick className="mr-2" size="small" />;
    } else {
      return <Icon.Attention className="mr-2" size="small" />;
    }
  }, []);

  return (
    <Box
      className="flex flex-row items-center mb-2"
      styles={{
        color: condition ? theme.colors.positive.dark : theme.colors.dark.base,
      }}
    >
      {getIcon(condition)}
      <Text fontSize="small">{description}</Text>
    </Box>
  );
};

export const PasswordRequirements = ({
  password,
  passwordPolicy: {
    MinimumLength,
    RequireNumbers,
    RequireSymbols,
    RequireUppercase,
  },
}: PasswordRequirementsType): React.ReactElement => {
  const [t] = useTranslation("auth");

  const policyArray = [
    {
      policy: MinimumLength,
      description: `${t(
        "description.minimumLength",
      )} ${MinimumLength} characters`,
      condition: password.length >= (MinimumLength || 0),
    },
    {
      policy: RequireUppercase,
      description: t("description.uppercaseRequired"),
      condition: /[A-Z]/.test(password),
    },
    {
      policy: RequireNumbers,
      description: t("description.numberRequired"),
      condition: /[0-9]/.test(password),
    },
    {
      policy: RequireSymbols,
      description: t("description.symbolRequired"),
      condition: /[^a-zA-Z0-9]+/.test(password),
    },
  ];

  return (
    <ul className="flex flex-col mb-2">
      {policyArray.map(
        ({ condition, policy, description }, index) =>
          policy && (
            <li css={{ marginBottom: theme.spacing.xsmall }} key={index}>
              <PasswordPolicyItem
                description={description}
                condition={condition}
              />
            </li>
          ),
      )}
    </ul>
  );
};
