import React, { useState } from "react";
import { FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Field,
  Icon,
  Input,
  Link,
  Modal,
  Panel,
  theme,
} from "@clearabee/ui-library";
import {
  fromTimeslots,
  toTimeslots as initialToTimeslots,
} from "../../quotes/validation";
import { ItemFormValues } from "./createUpdateItemForm";

interface AddDeliveryOptionProps {
  values: ItemFormValues;
}

/**
 * This allows you to create
 * deliveryOption objects,
 * within which you can specify a range of timeslots,
 * a name, and a charge.
 */
export const AddDeliveryOption = ({
  values,
}: AddDeliveryOptionProps): React.ReactElement => {
  const [showAddTimeSlotModal, setShowAddTimeSlotModal] = useState(false);
  const [translate] = useTranslation("catalogues");

  const toTimeslots = initialToTimeslots.map((timeslot) => ({
    label: timeslot.replace(":00", ""),
    value: timeslot,
  }));

  return (
    <>
      <Button
        type="button"
        size="small"
        onClick={() => {
          setShowAddTimeSlotModal(true);
        }}
      >
        {translate("form.buttons.viewTimeslotOptions")}
      </Button>
      {showAddTimeSlotModal && (
        <Modal
          styles={{
            width: "80%",
            height: "95%",
          }}
          heading={translate("form.headings.addEditTimeslotOptions")}
          onClose={() => setShowAddTimeSlotModal(false)}
        >
          <Container styles={{ height: "100%", overflow: "auto" }}>
            <FieldArray name="deliveryOptions">
              {({ remove, push }) => (
                <>
                  {values.deliveryOptions &&
                    values.deliveryOptions.length > 0 &&
                    values.deliveryOptions.map((range, optionIndex: number) => {
                      return (
                        <Panel
                          shadow={false}
                          styles={{
                            backgroundColor: theme.colors.greyscale.lightest,
                            maxWidth: theme.screens.large,
                            padding: theme.spacing.medium,
                            margin: `${theme.spacing.small} auto`,
                            position: "relative",
                          }}
                          key={optionIndex}
                        >
                          <div className="flex flex-row w-3/5 gap-3">
                            <Field
                              name={`deliveryOptions.${optionIndex}.title`}
                              styles={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              {({ field }) => (
                                <Input.Text
                                  placeholder={translate(
                                    "form.placeholders.nameThisTimeSlot",
                                  )}
                                  {...field}
                                />
                              )}
                            </Field>
                            <Field
                              name={`deliveryOptions.${optionIndex}.charge`}
                              styles={{
                                width: "25%",
                                textAlign: "left",
                              }}
                            >
                              {({ field }) => (
                                <Input.Text
                                  prefix="£"
                                  placeholder={translate(
                                    "form.placeholders.costOfTimeSlot",
                                  )}
                                  {...field}
                                />
                              )}
                            </Field>
                          </div>
                          <Button
                            type="button"
                            size="medium"
                            onClick={() => remove(optionIndex)}
                            color="negative"
                            className="absolute right-6 top-14"
                          >
                            {translate("form.buttons.delete")}
                          </Button>

                          <div className="w-full">
                            <FieldArray
                              name={`deliveryOptions.${optionIndex}.range`}
                            >
                              {({ remove: removeRange, push }) => (
                                <>
                                  <div className="flex">
                                    <div className="w-full">
                                      {range.range?.map(
                                        (_, rangeIndex: number) => {
                                          return (
                                            <div
                                              className="my-3 flex flex-row items-center gap-3 justify-between"
                                              key={rangeIndex}
                                            >
                                              <Panel
                                                shadow={false}
                                                styles={{
                                                  backgroundColor:
                                                    theme.colors.greyscale
                                                      .lighter,
                                                  padding: `${theme.spacing.xsmall2} ${theme.spacing.small}`,
                                                }}
                                                className="flex flex-row gap-3 items-center w-2/3"
                                              >
                                                <Field
                                                  name={`deliveryOptions.${optionIndex}.range.${rangeIndex}.from`}
                                                  styles={{
                                                    textAlign: "left",
                                                    width: "100%",
                                                  }}
                                                >
                                                  {({ field }) => (
                                                    <>
                                                      <select
                                                        {...field}
                                                        style={{
                                                          padding: `${theme.spacing.xsmall} ${theme.spacing.small}`,
                                                          borderRadius:
                                                            theme.spacing
                                                              .xsmall2,
                                                          border: `1px solid ${theme.colors.greyscale.lighter}`,
                                                          width: "100%",
                                                        }}
                                                      >
                                                        {/* This first option is just a placeholder */}
                                                        <option
                                                          value=""
                                                          disabled
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        >
                                                          {translate(
                                                            "form.placeholders.selectTimeFrom",
                                                          )}
                                                        </option>
                                                        {fromTimeslots.map(
                                                          (timeslot, index) => {
                                                            return (
                                                              <option
                                                                value={timeslot}
                                                                key={`from${index}`}
                                                              >
                                                                {timeslot.replace(
                                                                  ":00",
                                                                  "",
                                                                )}
                                                              </option>
                                                            );
                                                          },
                                                        )}
                                                      </select>
                                                    </>
                                                  )}
                                                </Field>
                                                <Field
                                                  name={`deliveryOptions.${optionIndex}.range.${rangeIndex}.to`}
                                                  styles={{
                                                    textAlign: "left",
                                                    width: "100%",
                                                  }}
                                                >
                                                  {({ field }) => (
                                                    <select
                                                      {...field}
                                                      style={{
                                                        padding: `${theme.spacing.xsmall} ${theme.spacing.small}`,
                                                        borderRadius:
                                                          theme.spacing.xsmall2,
                                                        border: `1px solid ${theme.colors.greyscale.lighter}`,
                                                        width: "100%",
                                                      }}
                                                    >
                                                      {/* This first option is just a placeholder */}
                                                      <option
                                                        value=""
                                                        disabled
                                                        style={{
                                                          display: "none",
                                                        }}
                                                      >
                                                        {translate(
                                                          "form.placeholders.selectTimeUntil",
                                                        )}
                                                      </option>
                                                      {toTimeslots.map(
                                                        (
                                                          { label, value },
                                                          index,
                                                        ) => {
                                                          return (
                                                            <option
                                                              value={value}
                                                              key={`to${index}`}
                                                            >
                                                              {label}
                                                            </option>
                                                          );
                                                        },
                                                      )}
                                                    </select>
                                                  )}
                                                </Field>
                                                <Link
                                                  type="button"
                                                  color="negative"
                                                  underline
                                                  className="ml-3 mt-6 font-black"
                                                  onClick={() => {
                                                    removeRange(rangeIndex);
                                                  }}
                                                >
                                                  {translate(
                                                    "form.buttons.remove",
                                                  )}
                                                </Link>
                                              </Panel>
                                              {range.range &&
                                                range.range?.length - 1 ===
                                                  rangeIndex && (
                                                  <Link
                                                    color="greyscale"
                                                    underline
                                                    className="font-black"
                                                    type="button"
                                                    onClick={() =>
                                                      push({ to: "", from: "" })
                                                    }
                                                  >
                                                    {translate(
                                                      "form.buttons.addTimeslotRange",
                                                    )}
                                                  </Link>
                                                )}
                                            </div>
                                          );
                                        },
                                      )}
                                    </div>
                                    <Button
                                      size="medium"
                                      type="button"
                                      color="brand"
                                      styles={{
                                        position: "absolute",
                                        top: 55,
                                        right: 160,
                                      }}
                                      onClick={() => push({ to: "", from: "" })}
                                      disabled={
                                        range.range && range.range?.length >= 1
                                      }
                                    >
                                      {translate(
                                        "form.buttons.setTimeslotRanges",
                                      )}
                                    </Button>
                                  </div>
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </Panel>
                      );
                    })}
                  <div className="mt-10 mb-2 flex flex-row justify-between">
                    <Button
                      size="medium"
                      type="button"
                      onClick={() => push({ range: [], charge: 0, title: "" })}
                    >
                      {translate("form.buttons.addTimeslotOption")}
                    </Button>
                    <Button
                      size="medium"
                      type="button"
                      color="accent"
                      onClick={() => setShowAddTimeSlotModal(false)}
                    >
                      {translate("form.buttons.hideTimeslotOptions")}
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
          </Container>
        </Modal>
      )}
    </>
  );
};
