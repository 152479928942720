import React, { useState } from "react";
import { IWorksheet } from "@clearabee/api-schemas";
import {
  Field,
  Gallery,
  Heading,
  Input,
  Panel,
  Text,
  Icon,
  Box,
} from "@clearabee/ui-library";
import { isAnswerImageUrl as isImageUrl } from "helpers";
import { styles } from "./WorksheetsQuestions.styles";

interface WorksheetsQuestionProps {
  worksheet: {
    worksheetId: number;
    worksheetName: string;
    questions: {
      questionId: number;
      question: string;
      answer: string;
    }[];
  };
  collapsed?: boolean;
  worksheetIndex: number;
  editing: boolean;
}

export const WorksheetsQuestions = ({
  worksheet,
  worksheetIndex,
  editing,
  collapsed = false,
}: WorksheetsQuestionProps): React.ReactElement => {
  const [collapse, setCollapse] = useState(collapsed);

  return (
    <Panel
      key={`${worksheet.worksheetId}-${worksheetIndex}-panel`}
      styles={styles.panel}
      draggable={true}
    >
      <Box className="flex justify-between">
        <Heading color="brand" level={1} fontSize="large">
          {worksheet.worksheetName.replace(/^\d\d?\w?. /, "")}
        </Heading>
        <Box className="inline-block cursor-pointer">
          <Icon.Chevron
            size="medium"
            color="brand"
            onClick={() => {
              setCollapse(!collapse);
            }}
            styles={{
              transform: collapse
                ? "translateY(10%) rotate(-90deg)"
                : "rotate(90deg)",
            }}
          />
        </Box>
      </Box>

      <Box style={styles.questionContainer}>
        {worksheet.questions.map(({ questionId, question, answer }, index) => {
          const isAnswerImageUrl = isImageUrl(answer);

          return (
            <Box
              key={index}
              styles={collapse ? styles.question : styles.collapsedQuestion}
            >
              {/* IMAGE-URLs -  image URLs may NOT be edited, so display only */}
              {isAnswerImageUrl && (
                <>
                  <Text styles={styles.imageLabel}>{question}</Text>
                  <Box style={styles.gallery}>
                    <Gallery items={[{ source: answer }]} />
                  </Box>
                </>
              )}

              {/* ANSWERS - strings and numbers may be edited */}
              {!isAnswerImageUrl && (
                <Field label={question} name={String(questionId)}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={answer}
                      disabled={!editing}
                      styles={styles.input}
                    />
                  )}
                </Field>
              )}
            </Box>
          );
        })}
      </Box>
    </Panel>
  );
};
