import React, { useState } from "react";
import {
  Field,
  Form,
  Heading,
  Input,
  Panel,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { Quote } from "models";
import { useTranslation } from "react-i18next";
import { FormikConfig, FormikProps, FormikHelpers } from "formik";
import { blankValues } from "../../validation";
import { ImageUpload } from "components/core";
import { IImage } from "components/jobs/types";
import {
  getQuoteImagePresignedUrl,
  uploadQuoteImage,
  readCompanies,
} from "api";
import { ICompany } from "api/types";
import { useAuthContext } from "hooks";
import { useQuery } from "react-query";
import roles from "constants/roles";
import { Address } from "api/libs/getAddressIo";
import { QuoteImages } from "../updateQuote";
import cx from "classnames";
import { css } from "@emotion/react";
import { DateTimeslotSelection } from "./dateTimeslotSelection";
import { AddressFields } from "./addressFields";

export type QuoteFormValues = Pick<
  Quote,
  | "date"
  | "addressLine1"
  | "addressLine2"
  | "addressCity"
  | "addressCounty"
  | "addressPostcode"
  | "accessInformation"
  | "contactEmail"
  | "contactFirstName"
  | "contactLastName"
  | "contactPhoneNumber"
  | "contactNoEmail"
  | "description"
  | "images"
  | "companyCode"
  | "timeslot"
> & {
  noTimeConstraint: boolean;
};

/**
 * Upload a single image to S3 bucket.
 */
const uploadImage = async (file: File, companyCode: string) => {
  const { name: filename } = file;
  const presignedUrlResponse = await getQuoteImagePresignedUrl(
    companyCode,
    filename,
  );
  const { url, fields } = presignedUrlResponse;

  return uploadQuoteImage(file, url, fields);
};

const uploadFormImages = async (images: IImage[], companyCode: string) => {
  if (!images.length) {
    return;
  }

  const promises = images.map(async (image: IImage) => {
    return uploadImage(image.file, companyCode);
  });

  return Promise.all(promises);
};

const getInitialValues = (
  quoteData: Quote | QuoteFormValues,
  canSelectCompany: boolean,
  userCompanyCode: string,
): QuoteFormValues => {
  const values = quoteData;

  if (!quoteData.companyCode && canSelectCompany) {
    values.companyCode = "";
  } else if (!quoteData.companyCode) {
    values.companyCode = userCompanyCode;
  }

  return { ...values, noTimeConstraint: true };
};

interface QuoteFormProps {
  quoteData?: Quote;
  validationSchema?: FormikConfig<QuoteFormValues>["validationSchema"];
  disabled?: boolean;
  collapsable?: boolean;
  defaultCollapsed?: boolean;
  onSubmit?: (values: QuoteFormValues) => void;
  isSuccess?: boolean;
  children?: (
    props: FormikProps<QuoteFormValues> & { companies: ICompany[] },
  ) => React.ReactNode;
}

export const QuoteForm = ({
  quoteData,
  validationSchema,
  disabled = false,
  collapsable = false,
  defaultCollapsed = true,
  onSubmit,
  isSuccess,
  children,
}: QuoteFormProps): React.ReactElement => {
  const [translate] = useTranslation("quotes");
  const initialValues = quoteData ? quoteData : blankValues;
  const [images, setImages] = useState<IImage[]>([]);
  const [collapse, setCollapse] = useState(defaultCollapsed && collapsable);

  /**
   * User details.
   */
  const { getCurrentUserCurrentCompany, doesUserHaveRole } = useAuthContext();
  const { companyCode: userCompanyCode } = getCurrentUserCurrentCompany() ?? {};

  const canSelectCompany = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const { isLoading, data } = useQuery(
    ["readAllCompanies"],
    () => readCompanies("", 0, 5000),
    {
      enabled: canSelectCompany && !!onSubmit,
    },
  );

  const availableCompanies: ICompany[] = data?.items || [];

  const handleAddressSelection = (
    address: Address,
    setFieldValue: FormikHelpers<unknown>["setFieldValue"],
  ) => {
    setFieldValue("addressLine1", address.line_1);
    setFieldValue("addressLine2", address.line_2);
    setFieldValue("addressCity", address.town_or_city);
    setFieldValue("addressCounty", address.county);
  };

  return (
    <Form
      initialValues={{
        ...getInitialValues(initialValues, canSelectCompany, userCompanyCode),
        noTimeConstraint: !quoteData?.timeslot,
      }}
      onSubmit={async (values) => {
        if (onSubmit) {
          const imageUrls = await uploadFormImages(
            images,
            values.companyCode || userCompanyCode,
          );
          onSubmit({
            ...values,
            images: imageUrls?.map((url) => ({ url })),
            companyCode: values.companyCode || userCompanyCode,
          });
        }
      }}
      validationSchema={validationSchema}
      renderFormElement={!!onSubmit}
    >
      {({ values, ...rest }) => (
        <>
          <Panel shadow={false}>
            {collapsable && (
              <button
                onClick={() => setCollapse(!collapse)}
                className="inline-flex items-center"
                css={css({
                  borderBottom: `2px solid ${theme.colors.brand.base}`,
                  position: "absolute",
                  color: theme.colors.brand.base,
                  right: theme.spacing.xlarge,
                  top: theme.spacing.xlarge,
                })}
              >
                {collapse ? "Open" : "Collapse"}
                <Icon.Chevron
                  className="ml-1"
                  width={16}
                  height={16}
                  color="brand"
                  styles={{
                    transform: !collapse
                      ? "translateY(10%) rotate(-90deg)"
                      : "rotate(90deg)",
                  }}
                />
              </button>
            )}
            {collapse && (
              <Heading
                styles={{
                  color: theme.colors.brand.base,
                }}
                level={5}
              >
                {translate("headings.quoteDetails")}
              </Heading>
            )}
            <div
              className={cx("flex -mx-2", {
                hidden: collapse,
              })}
            >
              <div className="px-2 w-1/4">
                {canSelectCompany && (
                  <>
                    <Heading
                      styles={{ color: theme.colors.brand.base }}
                      level={5}
                    >
                      {translate("headings.company")}
                    </Heading>
                    <Field
                      name="companyCode"
                      label={translate("form.label.companyCode")}
                    >
                      {({ field }) =>
                        !!onSubmit ? (
                          <Input.Select
                            {...field}
                            options={availableCompanies.map(
                              ({ name, companyCode }) => {
                                return {
                                  label: name,
                                  value: companyCode,
                                };
                              },
                            )}
                            isLoading={isLoading}
                            isSearchable
                            disabled={disabled}
                            placeholder={translate(
                              "form.placeholder.companyCode",
                            )}
                          />
                        ) : (
                          <Input.Text {...field} disabled />
                        )
                      }
                    </Field>
                  </>
                )}
                <Heading styles={{ color: theme.colors.brand.base }} level={5}>
                  {translate("headings.collectionDetails")}
                </Heading>
                <AddressFields
                  defaultManualAddress={!onSubmit}
                  enabledSwitch={!!onSubmit}
                  onAddressSelection={handleAddressSelection}
                  disabled={disabled}
                />
              </div>
              <div className="px-2 w-1/4">
                <Heading styles={{ color: theme.colors.brand.base }} level={5}>
                  {translate("headings.customerDetails")}
                </Heading>
                {[
                  "contactFirstName",
                  "contactLastName",
                  "contactPhoneNumber",
                  "contactEmail",
                ].map((name) =>
                  name === "contactEmail" && !!values.contactNoEmail ? null : (
                    <Field
                      label={translate(`form.label.${name}`)}
                      name={name}
                      key={name}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={disabled}
                          placeholder={translate(`form.placeholder.${name}`)}
                        />
                      )}
                    </Field>
                  ),
                )}
                <Field
                  name="contactNoEmail"
                  label={translate("form.label.contactNoEmail")}
                  styles={{
                    span: {
                      marginLeft: 0,
                    },
                  }}
                >
                  {({ field }) => (
                    <Input.Toggle
                      {...field}
                      disabled={disabled}
                      color={disabled ? "greyscale" : "negative"}
                      defaultChecked={!!initialValues.contactNoEmail}
                    />
                  )}
                </Field>
              </div>
              <div className="px-2 w-1/2">
                <Heading styles={{ color: theme.colors.brand.base }} level={5}>
                  {translate("headings.wasteDetails")}
                </Heading>
                <DateTimeslotSelection
                  disabled={disabled}
                  initialValues={initialValues}
                />
                <Field
                  label={translate(`form.label.description`)}
                  name="description"
                  styles={{ marginTop: 0 }}
                >
                  {({ field }) => (
                    <Input.Textarea
                      {...field}
                      disabled={disabled}
                      slim
                      maxLength={499}
                      placeholder={translate(`form.placeholder.description`)}
                    />
                  )}
                </Field>
                <Field
                  label={translate(`form.label.accessInformation`)}
                  name="accessInformation"
                >
                  {({ field }) => (
                    <Input.Textarea
                      {...field}
                      disabled={disabled}
                      slim
                      maxLength={499}
                      placeholder={translate(
                        `form.placeholder.accessInformation`,
                      )}
                    />
                  )}
                </Field>
                {!!onSubmit ? (
                  <ImageUpload
                    buttonClassName="ut-transition"
                    buttonText={translate("form.label.uploadImages")}
                    onChange={(files: IImage[]) => setImages(files)}
                    clearFileList={isSuccess}
                  />
                ) : (
                  <div className="mt-3">
                    <span className="text-xs text-black font-semibold">
                      <p>
                        {translate("customerImagesCount", {
                          count: initialValues.images?.length,
                        })}
                      </p>
                    </span>
                    <QuoteImages imageData={initialValues.images} />
                  </div>
                )}
              </div>
            </div>
          </Panel>

          {children &&
            children({ values, companies: availableCompanies, ...rest })}
        </>
      )}
    </Form>
  );
};
