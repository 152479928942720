import get from "lodash.get";

const searchObject = <T>(
  obj: T,
  searchTerm: string,
  keysToSearch: Array<string>,
): T | undefined => {
  const found = keysToSearch.find((key) => {
    return (
      typeof get(obj, key) === "string" &&
      (get(obj, key) as string).toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (found) return obj;
  return;
};

/**
 * Perform a search of an array of objects for a given string.
 * It will perform deep searching, looking inside nested objects.
 * You must provide an array of object keys on which to search.
 * @template T
 * @param {Array<T>} array - An array of objects in which to search for the search-term
 * @param {string} searchTerm - String used to search object values
 * @param {Array<string>} keysToSearch - Object keys on which to search. To refer to nested properties, use dot notation: accessor.key (e.g. person.address.city)
 * @returns {Array<T>}
 */
export const search = <T>(
  array: Array<T>,
  searchTerm: string,
  keysToSearch: Array<string>,
): Array<T> => {
  const filtered = array.filter((element) => {
    return <T>searchObject(element, searchTerm, keysToSearch);
  });

  return filtered;
};
