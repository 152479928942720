import { useState } from "react";

import { searchItems, filterItems } from "../helpers/catalogue";

import { Item } from "../models/item";
import { Category } from "../models/category";

type UseCatalogueSearchReturn = {
  query: string;
  setQuery: (newQuery: string) => void;
  selectedCategories: Array<Category["sku"]>;
  setSelectedCategories: (
    newSelectedCategories: Array<Category["sku"]>,
  ) => void;
  results: Array<Item>;
};
type UseCatalogueSearch = (
  items: Array<Item> | null,
) => UseCatalogueSearchReturn;

export const useCatalogueSearch: UseCatalogueSearch = (
  items,
): UseCatalogueSearchReturn => {
  const [query, setQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<
    Array<Category["sku"]>
  >([]);

  const changeQuery: UseCatalogueSearchReturn["setQuery"] = (
    newQuery: string,
  ): void => {
    setQuery(newQuery);
  };

  const changeSelectedCategories: UseCatalogueSearchReturn["setSelectedCategories"] =
    (newSelectedCategories): void => {
      setSelectedCategories(newSelectedCategories);
    };

  const getResults = (): Array<Item> => {
    if (items === null) {
      return [];
    }

    const results = items.filter((item) => searchItems(item, query));

    if (selectedCategories.length > 0) {
      return filterItems(results, selectedCategories);
    }

    return results;
  };

  return {
    query,
    setQuery: changeQuery,
    selectedCategories,
    setSelectedCategories: changeSelectedCategories,
    results: getResults(),
  };
};
