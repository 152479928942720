import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

/**
 * Import images.
 */
import { Plus, Minus } from "../../../../images";

interface IQuantity {
  setQuantity: (newQuantity: number) => void;
  min?: number;
  max?: number;
  initialValue?: number;
  quantity?: number;
}

export const Quantity = ({
  setQuantity: setParentQuantity,
  min = 0,
  max = 100,
  initialValue,
  quantity: propsQuantity,
}: IQuantity): JSX.Element => {
  const [quantity, setQuantity] = useState(initialValue || 0);
  const ref = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && ref.current) {
      ref.current.blur();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value) || 0;

    if (val <= max && val >= min) setQuantity(val);
    if (val > max) setQuantity(max);
  };

  useEffect(() => {
    setParentQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    propsQuantity && setQuantity(propsQuantity);
  }, [propsQuantity]);

  return (
    <div className="flex items-center justify-center space-x-2">
      <button
        onClick={() => setQuantity(quantity - 1)}
        disabled={quantity === min}
        type="button"
        className={cx(
          "text-white w-6 h-6 rounded-full flex items-center justify-center",
          quantity > 0 ? "bg-primary-400" : "bg-gray-400",
        )}
      >
        <Minus />
      </button>
      <input
        type="text"
        min={min}
        max={max}
        value={quantity}
        className="border-0 outline-none bg-white text-center w-8"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        ref={ref}
      />
      <button
        data-testid="plusButton"
        type="button"
        onClick={() => setQuantity(quantity + 1)}
        disabled={quantity === max}
        className={cx(
          "text-white w-6 h-6 rounded-full flex items-center justify-center",
          quantity < max ? "bg-primary-400" : "bg-gray-400",
        )}
      >
        <Plus />
      </button>
    </div>
  );
};
