import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useQuery, useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Form,
  Field,
  Input,
  Heading,
  Button,
  Icon,
  displayErrorMessage,
  Message,
  UnstyledButton,
} from "@clearabee/ui-library";
import { useInstance } from "api/dsa";
import { useAuthContext } from "../../../hooks";
import roles from "../../../constants/roles";
import "./../styles/create.css";

/**
 * Import components.
 */
import {
  BookJobAgain,
  JobDetails,
  JobImages,
  SubcontractorSubmittedFields,
  SubcontractorPanel,
} from "./components";
import { FormLoader } from "../../common/components";
import { Transition } from "../../core";

/**
 * Import API.
 */
import { editJob } from "api";

/**
 * Import helpers.
 */
import { toasts } from "../../../helpers";

/**
 * Import types.
 */
import { TEditJob } from "../../../api/types";
import { updateJobValidation } from "./validation";
import { instance } from "@clearabee/ui-sdk";
import { AdditionalInfo } from "./components/additionalInfo";

export const UpdateJob = (): React.ReactElement => {
  const {
    doesUserHaveRole,
    getCurrentUserCurrentCompanySettings,
    getCurrentUserEmailAddress,
  } = useAuthContext();
  const { hidePhotos } = getCurrentUserCurrentCompanySettings() || {};
  const isAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const currentUser = getCurrentUserEmailAddress();
  const isClearabee = doesUserHaveRole(roles.CLEARABEE_CUSTOMER_SERVICE);
  const [t] = useTranslation("jobs");
  const dsaApi = useInstance();
  const { id: encodedId } = useParams<{ id: string }>();
  const history = useHistory();
  const jobId = decodeURIComponent(encodedId);

  const [editing, setEditing] = useState<boolean>(false);
  const [bookJobAgainOpen, setBookJobAgainOpen] = useState(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);
  const [hasTransferNotes, setHasTransferNotes] = useState<boolean>(false);

  const {
    data: jobData,
    isLoading: isLoadingJob,
    refetch: refetchJob,
    isRefetching: isRefetchingJob,
  } = useQuery(
    ["readJob", encodedId],
    async () => (await instance.jobs.getJob(encodedId)).data,
    {
      enabled: !!encodedId,
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const { data: user, isLoading: isUserLoading } = useQuery(
    ["jobUser", jobData?.contactEmail],
    async () =>
      !!jobData?.contactEmail &&
      (await instance.users.getUser(jobData?.contactEmail)).data,
    { enabled: !!jobData?.contactEmail && isAdmin },
  );

  const {
    ref,
    date,
    accessInformation,
    addressCity,
    addressCounty,
    addressLine2,
    addressLine1,
    addressPostcode,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    contactNoEmail,
    items: products,
    description,
    images,
    bcId,
    collectionInformation,
    disposalInformation,
    hasCollectionData,
    hasDisposalData,
  } = jobData || {};

  const { cubicYardsCollected, estimatedWeight, wasteTypes } =
    collectionInformation || {};

  const { placeOfDisposal, landfillDiversionRate, permitNumber } =
    disposalInformation || {};

  const initialValues: TEditJob = {
    ref: ref || "",
    date: dayjs(date).format("DD-MM-YYYY") || "",
    accessInformation: accessInformation || "",
    addressCity: addressCity || "",
    addressCounty: addressCounty || "",
    addressLine1: addressLine1 || "",
    addressLine2: addressLine2 || "",
    addressPostcode: addressPostcode || "",
    contactEmail: contactEmail || "",
    contactFirstName: contactFirstName || "",
    contactLastName: contactLastName || "",
    contactNoEmail: contactNoEmail || false,
    contactPhoneNumber: contactPhoneNumber || "",
    products: products || [],
    description: description || "",
    images: images?.map((val) => val.url) || [],
    bcId: bcId || "",
    collectionInformation: {
      cubicYardsCollected: cubicYardsCollected || undefined,
      estimatedWeight: estimatedWeight || undefined,
      wasteTypes: wasteTypes || "",
    },
    disposalInformation: {
      placeOfDisposal: placeOfDisposal || "",
      landfillDiversionRate: landfillDiversionRate || undefined,
      permitNumber: permitNumber || "",
    },
  };

  const { status, companyCode } = jobData || {};

  const cancel = async (dsaJobId?: string) => {
    try {
      await instance.jobs.patchJobCancel(jobId?.toString() ?? "");
      if (dsaJobId) {
        await dsaApi.jobs.update(dsaJobId, {
          failedJob: {
            failureReason: "Cancelled in portal",
          },
          contractor: "",
          availableContractors: [],
          vehicleDetails: { registration: "" },
        });
      }
      history.push("/jobs");
      toasts.success({
        message: t("update.toasts.success"),
      });
    } catch (e) {
      toasts.error({
        message: t("update.toasts.error"),
      });
      throw e;
    }
  };

  const editForm = () => {
    try {
      setEditing(true);
    } catch (e) {
      toasts.error({
        message: "Failed to edit job. Please try again.",
      });
      throw e;
    }
  };

  const checkInvoice = async (id: string) => {
    const hasInvoice = (await instance.jobs.getHasInvoice(id.toString())).data;
    return setHasInvoice(hasInvoice.result);
  };

  const checkTransferNotes = async (id: string) => {
    const hasTransferNotes = (
      await instance.jobs.getHasWasteTransferNote(id.toString())
    ).data;
    return setHasTransferNotes(hasTransferNotes.result);
  };

  const handleSubmit = (values: TEditJob) => {
    if (values) {
      const payload = {
        ...values,
        contactPhoneNumber: values.contactPhoneNumber?.replace(/\s/g, ""),
        date: dayjs.utc(values.date, "D/M/YYYY").toISOString(),
      };
      mutate(payload);
    }
  };

  const { mutate, error } = useMutation(
    async (payload: TEditJob) => editJob({ id: jobId?.toString(), ...payload }),

    {
      onSuccess: () => {
        setEditing(false);
        refetchJob();
        toasts.success({
          message: "Changes saved.",
        });
      },
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
    },
  );

  useEffect(() => {
    if (!jobId) return;
    checkInvoice(jobId.toString());
    checkTransferNotes(jobId.toString());
  }, [jobId]);

  return (
    <div className="max-w-5xl mx-auto">
      {isLoadingJob || !jobData || !jobId ? (
        <FormLoader isLoading={isLoadingJob} />
      ) : (
        <Form
          initialValues={initialValues}
          validationSchema={updateJobValidation}
          onSubmit={handleSubmit}
          validateOnChange
        >
          {({ resetForm, values }) => (
            <>
              {useEffect(() => {
                setShowSave(true);
              }, [values])}
              <Transition
                className="mb-4 relative"
                duration={500}
                transition="fadeInDown"
                distance={100}
                toggle={!isLoadingJob}
              >
                <UnstyledButton
                  className="flex mb-3 items-center justify-center"
                  onClick={() => history.goBack()}
                >
                  <Icon.Chevron
                    styles={{ transform: "rotate(180deg)" }}
                    size="small"
                  />
                  {t("modal.buttons.labels.goBack")}
                </UnstyledButton>
                <JobDetails
                  id={Number(jobId)}
                  hasInvoice={hasInvoice}
                  hasTransferNotes={hasTransferNotes}
                  status={status || "Loading"}
                  jobRef={ref || ""}
                  companyCode={companyCode}
                  collection={date || ""}
                  isFetching={isLoadingJob}
                  jobData={jobData}
                  cancelJob={cancel}
                  editJob={editForm}
                  setBookJobAgainOpen={setBookJobAgainOpen}
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10">
                  <div>
                    <Heading color="brand" level={5}>
                      Collection Details
                    </Heading>
                    <Field
                      label={t("update.form.label.addressLine1")}
                      name="addressLine1"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled
                          placeholder={t(
                            "update.form.placeholder.addressLine1",
                          )}
                        />
                      )}
                    </Field>

                    <Field
                      label={t("update.form.label.city")}
                      name="addressCity"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled
                          placeholder={t("update.form.placeholder.city")}
                        />
                      )}
                    </Field>

                    <Field
                      label={t("update.form.label.county")}
                      name="addressCounty"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled
                          placeholder={t("update.form.placeholder.county")}
                        />
                      )}
                    </Field>

                    <Field
                      label={t("update.form.label.postcode")}
                      name="addressPostcode"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled
                          placeholder={t("update.form.placeholder.postcode")}
                        />
                      )}
                    </Field>
                    {editing && isAdmin && (
                      <Field
                        name="date"
                        label={t("update.form.label.collectionDate")}
                      >
                        {({ field }) => (
                          <Input.Date
                            disabledDays={[
                              {
                                before: new Date(),
                              },
                            ]}
                            collapsable
                            {...field}
                          />
                        )}
                      </Field>
                    )}
                  </div>

                  <div>
                    <div className="flex">
                      <Heading
                        color="brand"
                        level={5}
                        styles={{ marginRight: "auto" }}
                      >
                        Customer Details
                      </Heading>
                      {isUserLoading && (
                        <Icon.Loading size="small" color="brand" />
                      )}
                      {!isUserLoading && !!user && (
                        <Button
                          onClick={() => {
                            history.push(`/users/update/${user.email}`);
                          }}
                          color="accent"
                          size="xsmall"
                        >
                          {t("buttons.labels.viewUser")}
                        </Button>
                      )}
                    </div>
                    <Field
                      label={t("update.form.label.firstName")}
                      name="contactFirstName"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={!editing}
                          placeholder={t("update.form.placeholder.firstName")}
                        />
                      )}
                    </Field>

                    <Field
                      label={t("update.form.label.lastName")}
                      name="contactLastName"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={!editing}
                          placeholder={t("update.form.placeholder.lastName")}
                        />
                      )}
                    </Field>

                    <Field
                      label={t("update.form.label.email")}
                      name="contactEmail"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={!editing}
                          placeholder={t("update.form.placeholder.email")}
                        />
                      )}
                    </Field>

                    <Field
                      label={t("update.form.label.phone")}
                      name="contactPhoneNumber"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          disabled={!editing}
                          placeholder={t("update.form.placeholder.phone")}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="lg:col-span-2">
                    <Heading color="brand" level={5}>
                      Waste Details
                    </Heading>
                    <div className="grid lg:grid-cols-2 gap-x-10">
                      <Field
                        label={t("update.form.label.wasteDescription")}
                        name="description"
                      >
                        {({ field }) => (
                          <Input.Textarea
                            {...field}
                            disabled={!editing}
                            maxLength={499}
                            placeholder={t(
                              "update.form.placeholder.wasteDescription",
                            )}
                          />
                        )}
                      </Field>

                      <Field
                        label={t("update.form.label.accessInformation")}
                        name="accessInformation"
                      >
                        {({ field }) => (
                          <Input.Textarea
                            {...field}
                            disabled={!editing}
                            placeholder={t(
                              "update.form.placeholder.accessInformation",
                            )}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  {(hasDisposalData || hasCollectionData) && (
                    <div className="lg:col-span-2">
                      <Heading color="brand" level={5}>
                        {t("headings.completedJobDetails")}
                      </Heading>
                      <div className="grid lg:grid-cols-2 gap-x-10">
                        <Field
                          label={t("form.label.weight")}
                          name="collectionInformation.estimatedWeight"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("form.placeholder.notYetAdded")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("form.label.volumeCubicYards")}
                          name="collectionInformation.cubicYardsCollected"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("form.placeholder.notYetAdded")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("form.label.placeOfDisposal")}
                          name="disposalInformation.placeOfDisposal"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("form.placeholder.notYetAdded")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("form.label.permitNumber")}
                          name="disposalInformation.permitNumber"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("form.placeholder.notYetAdded")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("form.label.wasteTypes")}
                          name="collectionInformation.wasteTypes"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("form.placeholder.notYetAdded")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("form.label.landfillDiversionRate")}
                          name="disposalInformation.landfillDiversionRate"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("form.placeholder.notYetAdded")}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  )}
                </div>

                {!hidePhotos && <JobImages jobRef={jobData?.ref} />}
              </Transition>

              <Transition
                className="flex justify-center mb-6"
                duration={500}
                transition="fadeInUp"
                distance={100}
                toggle={editing}
              >
                <Button
                  name="save"
                  size="xsmall"
                  type="submit"
                  color="accent"
                  disabled={!showSave}
                  className="mr-3"
                >
                  {t("buttons.labels.save")}
                </Button>

                {editing && (
                  <Button
                    name="cancel"
                    type="button"
                    color="negative"
                    size="xsmall"
                    onClick={() => {
                      resetForm();
                      setShowSave(false);
                      setEditing(false);
                    }}
                  >
                    {t("buttons.labels.cancelEditing")}
                  </Button>
                )}
              </Transition>
            </>
          )}
        </Form>
      )}
      {displayErrorMessage(error, ({ children }) => (
        <div className="flex items-center justify-center w-full mb-4 overflow-scroll">
          <Message type="error" background>
            {children}
          </Message>
        </div>
      ))}

      {!isLoadingJob && !!jobData && (
        <AdditionalInfo
          data={jobData}
          setBookJobAgainOpen={setBookJobAgainOpen}
        />
      )}

      {/* FIELDS SHOWING DATA THAT THE SUBCONTRACTOR HAS SUBMITTED,
      ALONG WITH THE MODAL TO UPDATE JOB VALUE */}
      {(isAdmin || isClearabee) && !!bcId && (
        <SubcontractorSubmittedFields bcId={bcId} />
      )}

      {!!jobData && (
        <BookJobAgain
          jobData={jobData}
          isOpen={bookJobAgainOpen}
          setIsOpen={setBookJobAgainOpen}
        />
      )}

      {isAdmin &&
        jobData &&
        currentUser === "naveed.ziarab@clearabee.co.uk" && (
          <SubcontractorPanel
            jobPostcode={jobData.addressPostcode}
            jobId={jobData.id || ""}
            jobMeta={jobData.meta}
            jobPools={jobData.pool}
            jobIsRefetching={isRefetchingJob}
            refetchJob={refetchJob}
          />
        )}
    </div>
  );
};
