import React, { useState } from "react";
import { useQuery } from "react-query";
import { useFormikContext, getIn } from "formik";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { Icon, Box, theme } from "@clearabee/ui-library";
import { useDebounce } from "../../../../hooks";
import { FormInput, Modal } from "../../../core";

/**
 * Import images.
 */
import Warning from "../../../../images/warning.svg";

interface IPONumberField {
  postcode: string;
  companyCode: string | number;
  required?: boolean;
  label?: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const PONumberField = ({
  postcode,
  companyCode,
  required,
  label,
}: IPONumberField): JSX.Element => {
  const [t] = useTranslation("jobs");

  const [warning, showWarning] = useState(false);

  const { values } = useFormikContext();

  const value = getIn(values, "meta.poNumber");

  const debouncedValue = useDebounce(value, 2000);

  const { data: jobs, isLoading: jobsIsLoading } = useQuery(
    ["getJobs", debouncedValue],
    async () => {
      const jobs = (
        await instance.jobs.getJobs({
          params: {
            limit: 1,
            "companyCode:in": companyCode,
            "addressPostcode:eq": postcode.replaceAll(" ", ""),
            "purchaseOrder:eq": value || Number(value),
          },
        })
      ).data.items;

      if (jobs.length) {
        showWarning(true);
      }

      return jobs;
    },
    { refetchOnWindowFocus: false, retry: 1, enabled: !!debouncedValue },
  );

  return (
    <>
      <Box className="flex flex-row items-center gap-x-3 w-full">
        <Box
          className="w-4/5"
          styles={{
            "& > .error > .form-input-validate": {
              position: "initial",
              marginTop: theme.spacing.xsmall2,
            },
          }}
        >
          <FormInput
            name="meta.poNumber"
            placeholder={
              label
                ? label
                : t(
                    "create.steps.collectionDetails.form.placeholders.purchaseOrder",
                  )
            }
            label={{
              text: `${
                label
                  ? label
                  : t(
                      "create.steps.collectionDetails.form.labels.purchaseOrder",
                    )
              }${required ? "*" : ""}`,
            }}
            floatingLabel={false}
          />
        </Box>
        {jobsIsLoading && <Icon.Loading color="brand" size="medium" />}
      </Box>
      <Modal
        visible={warning}
        className="pt-28 bg-top"
        textClass="text-sm"
        image={Warning}
        text={
          <>
            A job with postcode: <span className="font-bold">{postcode}</span>{" "}
            <br />
            and P.O. Number: <span className="font-bold">{value}</span> <br />
            already exists.
          </>
        }
        buttons={[
          {
            label: "Close",
            name: "close-button",
            onClick: () => showWarning(false),
          },
        ]}
      />
    </>
  );
};
