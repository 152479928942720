import * as Yup from "yup";
import i18n from "../translations/config";

export const fieldRequired = i18n.t("common:form.errors.requiredField");
export const fieldRequiredShort = i18n.t(
  "common:form.errors.requiredFieldShort",
);
export const matchField = i18n.t("auth:form.errors.matchField");
export const validPostcode = i18n.t("common:form.errors.validPostcode");
export const validEmail = i18n.t("common:form.errors.validEmail");
export const validNumber = i18n.t("common:form.errors.validNumber");
export const validPhone = i18n.t("common:form.errors.validPhone");
export const validLettersOnly = i18n.t("common:form.errors.validLettersOnly");
export const validIncludeLetters = i18n.t(
  "common:form.errors.validIncludeLetters",
);
export const emailRegExp =
  /^[a-zA-Z0-9\._%+!$&*=^|~#%{}\/\-]+@([A-Za-z0-9\-]+\.){1,}([A-Za-z]{2,22})$/;
export const phoneRegExp = /^(?:0|\+?44)\s?(?:\(0\)){0,1}\s?(?:\d\s?){9,10}$/;
export const lettersOnlyRegExp = /^[a-zA-Z]+$/;
export const lettersAndColonOnlyRegExp = /^[a-zA-Z:]+$/;
export const postcodeRegExp =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
export const includesLettersRegExp = /[a-zA-Z]/;
export const stringRequired = Yup.string().required(fieldRequired);

export const stringIncludesLetters = stringRequired.matches(
  includesLettersRegExp,
  validIncludeLetters,
);

export const stringNumberOnly = Yup.string().matches(/^\d+$/, validNumber);

export const lettersOnly = stringRequired.matches(
  lettersOnlyRegExp,
  validLettersOnly,
);
export const optionalStringCharacterLimit = Yup.string().max(
  499,
  "Must be 499 characters or less.",
);
export const requiredStringCharacterLimit = Yup.string()
  .required()
  .max(499, "Must be 499 characters or less.");
export const email = stringRequired.email(validEmail);
export const phone = stringRequired.matches(phoneRegExp, validPhone);

export const requiredOption = Yup.object()
  .shape({
    label: Yup.string(),
    value: stringRequired,
  })
  .nullable()
  .required(fieldRequired);

export const optionField = Yup.object()
  .shape({
    label: Yup.string(),
    value: Yup.string(),
  })
  .nullable();

export const name = Yup.string().matches(
  includesLettersRegExp,
  i18n.t("common:form.errors.validIncludeLetters"),
);

export const nameIsRequired = stringRequired.matches(
  includesLettersRegExp,
  i18n.t("common:form.errors.validIncludeLetters"),
);
