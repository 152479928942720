import * as Yup from "yup";
import {
  stringRequired,
  phone,
  fieldRequired,
  validEmail,
  email,
  nameIsRequired,
} from "validation/common";

/**
 * Import types.
 */
import {
  IVTCollectionDetailsStep,
  IVTSelectServiceStep,
  IVTFormState,
} from "./types";

/**
 * VT collection details step.
 */
export const vtCollectionDetailsSchema = Yup.object().shape({
  timeslot: Yup.string().when("timeslotPreference", {
    is: "Choose a 2 hour timeslot",
    then: stringRequired,
  }),
  timeslotPreference: stringRequired,
  firstName: nameIsRequired,
  lastName: nameIsRequired,
  phoneNumber: phone.required(fieldRequired),
  email: Yup.string().email(validEmail).when("customerNoEmail", {
    is: false,
    then: email,
  }),
  customerNoEmail: Yup.boolean().required(fieldRequired),
  wasteDescription: Yup.string(),
  accessInformation: Yup.string(),
});

export const vtCollectionDetailsInitialValues: IVTCollectionDetailsStep = {
  timeslot: "",
  timeslotCharge: 0,
  timeslotPreference: "Any",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  customerNoEmail: false,
  wasteDescription: "",
  accessInformation: "",
};

/**
 * VT select service step.
 */
export const vtSelectServiceSchema = Yup.object().shape({
  service: stringRequired,
  collectionDate: stringRequired,
  addressLookupPostcode: stringRequired,
  address: Yup.object().shape({
    line1: stringRequired,
    line2: Yup.string(),
    city: stringRequired,
    county: Yup.string(),
    postcode: stringRequired,
  }),
});

export const vtSelectServiceInitialValues: IVTSelectServiceStep = {
  service: "",
  collectionDate: "",
  addressChoices: "",
  addressLookupPostcode: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
  },
};

/**
 * VT Initial Form state.
 * Used by MultiForm component.
 */
export const formStateInitialValues: IVTFormState = {
  ...vtCollectionDetailsInitialValues,
  ...vtSelectServiceInitialValues,
};
