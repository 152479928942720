import React from "react";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";

/**
 * Import Yup field validation.
 */
import { welcome } from "./validation";

/**
 * Import auth hook.
 */
import { useAuthContext } from "../../hooks";

/**
 * Import components.
 */
import { TitleWelcome, InputPassword, Button } from "./components";
import { Trail } from "../core";
import { toasts } from "../../helpers";

/**
 * Components array for trail transition.
 */
const formFields = [
  <InputPassword name="newPassword" translationKey="newPassword" />,
  <Button
    linkRoute="logins"
    linkTranslationKey="back"
    buttonTranslationKey="save"
  />,
];

/**
 * Set our initial form values.
 */
const initialValues = {
  newPassword: "",
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Welcome: React.FC = () => {
  /**
   * Set new password on submit.
   */
  const { completeNewPassword, forcePasswordChange, getTemporaryUser } =
    useAuthContext();
  const tempUser = getTemporaryUser();

  const setPassword = async (newPassword: string) => {
    try {
      await completeNewPassword(newPassword);

      toasts.success({ message: "Password changed successfully!" });
    } catch (e) {
      // toast error
      toasts.error({ message: (e as Error).message });
    }
  };

  if (tempUser === null || !forcePasswordChange) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <>
      <TitleWelcome />

      <Formik
        initialValues={initialValues}
        validationSchema={welcome}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          setPassword(values.newPassword);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Trail components={formFields} />
          </form>
        )}
      </Formik>
    </>
  );
};
