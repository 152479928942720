import React from "react";
import {
  BaseFormConfig,
  FormContext,
  Formik,
} from "@clearabee/ui-library-base";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { Field, FieldConfig } from "./Field/Field";

export type FormConfig<Values extends Formik.FormikValues> = UserStylesProps &
  BaseFormConfig<Values, FieldConfig<Values>> & { renderFormElement?: boolean };

export function Form<Values extends Formik.FormikValues = Formik.FormikValues>({
  children,
  className,
  styles: userStyles,
  renderFormElement = true,
  ...formikProps
}: FormConfig<Values>): React.ReactElement {
  return (
    <Formik.Formik {...formikProps}>
      {(formik): React.ReactElement => {
        const fields =
          typeof children === "function"
            ? children({ ...formik, Field })
            : children;

        return (
          <FormContext.Provider
            value={{
              ...formik,
              validationSchema: formikProps.validationSchema,
            }}
          >
            <>
              {renderFormElement && (
                <form
                  onReset={formik.handleReset}
                  onSubmit={formik.handleSubmit}
                  css={css(userStyles)}
                  className={className}
                >
                  {fields}
                </form>
              )}
              {!renderFormElement && fields}
            </>
          </FormContext.Provider>
        );
      }}
    </Formik.Formik>
  );
}
