import React from "react";

/**
 * Import hooks.
 */
import { useAppContext } from "../../../hooks";

/**
 * Import images.
 */
import { Hexagon } from "../../../images";
import DefaultAvatar from "../../../images/default-avatar.png";

export const AccountAvatar: React.FC = () => {
  const { dispatch } = useAppContext();

  return (
    <button
      className="sidebar-account inline-block transform hover:scale-105 ut-transition"
      onClick={() => dispatch({ type: "SHOW_ACCOUNT_BUBBLE" })}
    >
      <Hexagon
        className="text-primary"
        imagePattern={DefaultAvatar}
        width={58}
        height={66}
      />
    </button>
  );
};
