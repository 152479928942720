"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errors = void 0;
exports.errors = {
    auth: {
        awsEmail: "Email could not be fetched from your user session",
    },
    invalidDate: "{{date}} is unavailable, please try another.",
    catalogueError: "Error getting products, please try again later...",
    caughtError: "Caught error: {{message}}",
    noData: "No catalogue data returned from API",
    addressLine1: "Address line 1 is required",
    county: "County is required",
    postcode: "Postcode is required",
    homepage: "Back to home",
    pageNotFoundMessage: "This page cannot be found",
    pageNotFound: "404",
    errorOccurred: "An error has occurred",
    contactSupport: "Try again or please contact our support team",
    postcodeDropdown: "Please select an address",
    refAndPostcodeFetch: "Invalid order found. Please try a different order reference and postcode.",
    skipBagNotCompleted: "This order has not been delivered.",
};
