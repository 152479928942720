import React, { useEffect, useState } from "react";
import {
  FilterList,
  FilterListOption,
  usePagination,
} from "@clearabee/ui-library";
import { getQueriedResults } from "helpers";
import { useFormikContext } from "formik";
import { IPaginatedResults } from "api/types";
import { FilteredKeys, QueryType } from "helpers/getQueriedResults";

interface PaginatedFilterListProps<T> {
  data: IPaginatedResults<T>;
  formatOptions: (option: T) => FilterListOption;
  label: string;
  content: React.ReactNode;
  searchPlaceholder: string;
  searchBy: FilteredKeys<T, QueryType>[];
}

export const PaginatedFilterList = <T,>({
  data,
  formatOptions,
  label,
  searchPlaceholder,
  content,
  searchBy,
}: PaginatedFilterListProps<T>): React.ReactElement => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<T[]>();
  const { setFieldValue } = useFormikContext();

  const { PaginationComponent, paginatedData } = usePagination(
    results ?? data?.items ?? [],
  );

  useEffect(
    () => setResults(getQueriedResults(data.items, search, searchBy)),
    [search],
  );

  return (
    <>
      <FilterList
        options={paginatedData.map(formatOptions)}
        onSearch={(search) => setSearch(search)}
        onOptionsChange={(options) => setFieldValue(label, options)}
        searchPlaceholder={searchPlaceholder}
        content={content}
      />
      <PaginationComponent />
    </>
  );
};
