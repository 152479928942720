import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  button: { margin: `0 auto`, display: "flex", justifySelf: "center" },
  heading: { textAlign: "left", marginBottom: theme.spacing.small },
  scrollView: { maxHeight: theme.screens.xsmall, overflow: "scroll" },
  loadingIcon: { margin: `${theme.spacing.xlarge} auto` },
  cell: {
    textAlign: "left",
    padding: `0 ${theme.spacing.xsmall}`,
  },
  total: {
    textAlign: "right",
    marginTop: theme.spacing.small,
  },
});
