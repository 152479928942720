import React, { useState } from "react";
import { Field, Input } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import {
  PostcodeLookupField,
  PostcodeLookupFieldProps,
} from "../postcodeLookupField";

interface AddressFieldsProps {
  defaultManualAddress: boolean;
  enabledSwitch?: boolean;
  disabled?: boolean;
  onAddressSelection: PostcodeLookupFieldProps["onOptionSelect"];
}

export const AddressFields = ({
  defaultManualAddress,
  onAddressSelection: handleAddressSelection,
  disabled,
  enabledSwitch,
}: AddressFieldsProps): React.ReactElement => {
  const [translate] = useTranslation("quotes");
  const [manualAddress, setManualAddress] = useState(defaultManualAddress);

  return (
    <>
      {manualAddress &&
        [
          "addressLine1",
          "addressLine2",
          "addressCity",
          "addressCounty",
          "addressPostcode",
        ].map((name) => (
          <Field label={translate(`form.label.${name}`)} name={name} key={name}>
            {({ field }) => (
              <Input.Text
                {...field}
                disabled={disabled}
                placeholder={translate(`form.placeholder.${name}`)}
              />
            )}
          </Field>
        ))}
      {!manualAddress && (
        <PostcodeLookupField
          name="addressPostcode"
          label={translate("form.label.address")}
          placeholder={translate("form.placeholder.addressPostcode")}
          onOptionSelect={handleAddressSelection}
        />
      )}
      {enabledSwitch && (
        <div className="text-center text-primary text-sm font-semibold">
          {!manualAddress && <p>{translate("cantFindAddress")}</p>}
          <button
            type="button"
            onClick={() => setManualAddress(!manualAddress)}
            className="underline"
          >
            {!manualAddress
              ? translate("buttons.manualAddress")
              : translate("buttons.postcodeLookup")}
          </button>
        </div>
      )}
    </>
  );
};
