"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormContext = exports.FormContext = void 0;
var react_1 = require("react");
var Field_1 = require("./Field/Field");
exports.FormContext = (0, react_1.createContext)(undefined);
var useFormContext = function () {
    var formContext = (0, react_1.useContext)(exports.FormContext);
    var isField = (0, react_1.useContext)(Field_1.FieldContext).isField;
    return formContext ? __assign(__assign({}, formContext), { isField: isField }) : undefined;
};
exports.useFormContext = useFormContext;
