"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removePixelValues = void 0;
function removePixelValues(obj) {
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], k = _b[0], v = _b[1];
        if (v && typeof v === "object") {
            removePixelValues(v);
        }
        else {
            if (typeof v === "string" && v.includes("px")) {
                obj[k] = Number(v.replace("px", ""));
            }
        }
    }
}
exports.removePixelValues = removePixelValues;
