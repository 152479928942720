import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

/**
 * Import components.
 */
import { FormButton, LoadingSpinner } from "../../../core";
import { PortalServiceSelection } from "../../components";

/**
 * Import form validation schema and initial values.
 */
import { selectServiceSchema, selectServiceInitialValues } from "../validation";

/**
 * Import hooks.
 */
import { useCatalogueContext, useMultiFormContext } from "../../../../hooks";

export const SelectService: React.FC = () => {
  /**
   * Translations.
   */
  const [t] = useTranslation("jobs");

  /**
   * Multi form context.
   */
  const { nextStep, pushState, formState } = useMultiFormContext();
  const { job } = formState;

  /**
   * Get services items from catalogue.
   */
  const allowedServices = ["SKIPS", "SKIPBAGS", "SOFAREMOVAL", "MANVAN"];
  const {
    catalogueItems,
    isLoading,
    setPostcode: setCataloguePostcode,
  } = useCatalogueContext();
  const adhocCatalogue = catalogueItems?.filter((item) =>
    allowedServices.includes(item.sku),
  );

  /**
   * Set catalogue postcode
   */
  useEffect(() => {
    if (!job || !job.addressPostcode) {
      return;
    }

    setCataloguePostcode(job.addressPostcode);
  }, [job]);

  return (
    <>
      {!isLoading && (
        <Formik
          initialValues={selectServiceInitialValues}
          validationSchema={selectServiceSchema}
          onSubmit={(values) => {
            pushState(values);
            nextStep();
          }}
        >
          {({ handleSubmit, dirty, isValid }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row flex-wrap justify-center mt-4 -mx-2">
                <PortalServiceSelection services={adhocCatalogue || []} />
              </div>
              <div className="mt-10 flex justify-around">
                <FormButton
                  text={t("create.buttons.next")}
                  variant="secondary"
                  type="submit"
                  name="submit"
                  disabled={!dirty || !isValid}
                />
              </div>
            </form>
          )}
        </Formik>
      )}
      {/* Spinner */}
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <LoadingSpinner
            classes={{
              circle: "text-primary opacity-100",
              bee: "text-primary",
              text: "text-primary opacity-100",
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
