import { TFilters } from "./types";

export const defaultAPILimit = 10;

const calculatePaginationOffset = (
  currentPage: number,
  limit: number,
): number => {
  return currentPage * limit;
};

export const generateQueryParams = (
  filters: TFilters,
  currentPage: number,
  limit = defaultAPILimit,
): string => {
  const offset = calculatePaginationOffset(currentPage, limit);

  let query = `?limit=${limit}&offset=${offset}`;

  if (filters) {
    query += `&${filters}`;
  }

  return query;
};

export const hasMoreResults = (
  total: number,
  currentPage = 0,
  limit = defaultAPILimit,
): boolean => {
  const offset = calculatePaginationOffset(currentPage, limit);
  return offset + limit < total;
};

export const successResponse = (data?: Record<string, any>) => {
  return {
    success: true,
    ...data,
  };
};
