import { theme } from "../../../../theme";
import { StyleSheet, hexToRgba } from "../../../../utils";

export const styles = StyleSheet({
  input: {
    ...theme.fontDefaults.small,
    width: "100%",
    boxSizing: "border-box",
    padding: theme.spacing.xsmall,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
    borderRadius: theme.spacing.xsmall2,
    outline: "none",

    ":focus": {
      borderColor: theme.colors.focus.base,
    },
    ":disabled": {
      color: theme.colors.greyscale.lighter,
      borderColor: theme.colors.greyscale.lightest,
      backgroundColor: hexToRgba(theme.colors.greyscale.lightest, 0.4),
    },
  },
  error: {
    borderColor: theme.colors.negative.base,
  },
  success: {
    borderColor: theme.colors.positive.base,
  },
  autoGrowDiv: {
    ...theme.fontDefaults.small,
    width: "100%",
    minHeight: theme.spacing.xlarge,
    wordWrap: "break-word",
    padding: theme.spacing.xsmall,
    position: "relative",
    color: "transparent",
  },
  autoGrowTextarea: {
    color: theme.colors.dark.base,
    position: "absolute",
    width: "inherit",
    minHeight: theme.spacing.xlarge,
    height: "100%",
    resize: "none",
    top: 0,
    left: 0,
  },
});
