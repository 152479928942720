"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.suppliers = void 0;
exports.suppliers = {
    suppliers: {
        headings: {
            suppliers: "Suppliers",
            createSupplier: "Create Supplier",
            updateSupplier: "Update Supplier",
            id: "Id",
            reference: "Reference",
            name: "Name",
            status: "Status",
            phone: "Phone",
            address: "Address",
            active: "Active",
            inactive: "Inactive",
            success: "Success",
            supplierTypeFields: "Supplier Type Fields",
        },
        buttons: {
            addNewSupplier: "Add New Supplier",
            backToSuppliers: "Back to Suppliers",
            viewSupplierUsers: "View All Supplier Users",
            edit: "Edit",
            users: "Users",
            submit: "Submit",
            remove: "Remove",
            returnToSuppliers: "Return To Suppliers",
            addAnother: "Add Another",
            viewSupplierTypes: "View Supplier Types",
            enterAddressMaually: "Enter the address manually",
            postcodeLookup: "Search for the address using the postcode",
        },
        form: {
            labels: {
                id: "Id",
                reference: "Reference",
                name: "Name",
                status: "Status",
                phone: "Phone Number",
                address: "Address",
                active: "Active",
                types: "Supplier Type",
                addressLine1: "Address Line 1",
                addressLine2: "Address Line 2",
                city: "City",
                county: "County",
                postcode: "Postcode",
            },
            placeholders: {
                enterYourPostcode: "Enter your postcode",
                loading: "Loading...",
                selectSupplierType: "Select a supplier type",
            },
        },
        errors: {
            supplierNotCreated: "The creation of this supplier was not successful",
            na: "N/A",
            noneProvided: "None provided",
            cannotGetSupplierInformation: "Cannot retrieve supplier information",
            errorUpdatingSupplier: "Error updating supplier information",
        },
        messages: {
            cantFindAddress: "Unable to find the address?",
            supplierAdded: "Supplier has been added",
            supplierUpdated: "Supplier has been updated",
        },
    },
    supplierTypes: {
        headings: {
            createSupplierType: "Create a Supplier Type",
            editSupplierType: "Edit Supplier Type",
            supplierTypes: "Supplier Types",
            columns: "Columns",
            success: "Success",
        },
        messages: {
            supplierTypeAdded: "Supplier type has been added",
            supplierTypeUpdated: "Supplier type has been updated",
        },
        errors: {
            supplierTypeNotCreated: "Supplier Type could not be created",
            cannotGetSupplierType: "Error retrieving supplier type data",
            errorUpdatingType: "Error updating supplier type data",
        },
        form: {
            labels: {
                name: "Name",
                type: "Type",
                required: "Required",
                title: "Title",
            },
            placeholders: {
                nameSupplierType: "Name this supplier type",
            },
        },
        buttons: {
            addNewSupplierType: "Add New Supplier Type",
            backToSupplierTypes: "Back to Suppliers Types",
            addAnotherColumn: "Add Another Column",
            edit: "Edit",
            submit: "Submit",
            remove: "Remove",
            returnToSuppliers: "Return To Suppliers",
            addAnother: "Add Another",
            viewSuppliers: "View Suppliers",
            viewAllSuppliers: "View All Suppliers",
        },
    },
};
