/**
 * Import types.
 */
import axios from "axios";
import { Template } from "models/template";
import {
  IPaginatedResults,
  TemplatePresignedUrl,
  TUpdateCatalogueTemplate,
  CascadeCatalogueTemplate,
} from "./types";

export const endpoints = {
  GET_ORDER_TYPES: "/catalogues/order-types",
  GET_TEMPLATES: "/catalogues/templates",
  POST_TEMPLATE: "/catalogues/templates",
  GET_TEMPLATE_IMAGE_PRESIGNED_URL: (filename: string) =>
    `/catalogues/templates/images/${filename}`,
  GET_TEMPLATE: (sku: string): string => `/catalogues/templates/${sku}`,
  PATCH_TEMPLATE: (sku: string): string => `/catalogues/templates/${sku}`,
  PATCH_CASCADE_TEMPLATE: (sku: string, append = false): string =>
    `/catalogues/templates/${sku}/cascade${append ? "?append=true" : ""}`,
};

interface GetTemplatesOptions {
  limit: number;
}

/**
 * Get all templates.
 */
export const getTemplates = async ({
  limit,
}: GetTemplatesOptions): Promise<IPaginatedResults<Template>> => {
  const templates = await axios.get(endpoints.GET_TEMPLATES, {
    params: { limit },
  });

  return templates.data;
};

/**
 * Request pre-signed url for quote image upload.
 */
export const getTemplateImagePresignedUrl = async (
  filename: string,
): Promise<TemplatePresignedUrl> => {
  try {
    const s3Url = await axios.put(
      endpoints.GET_TEMPLATE_IMAGE_PRESIGNED_URL(filename),
    );
    return s3Url.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Upload template image to s3 using the presigned url.
 */
export const uploadTemplateImage = async (
  file: File,
  url: string,
  fields: { [key: string]: string },
): Promise<string> => {
  const form = new FormData();

  /**
   * Add returned fields from our presigned url as form-data fields.
   */
  Object.keys(fields).forEach((field: string) => {
    form.append(field, fields[field]);
  });

  /**
   * Add extra fields required.
   */
  form.append("acl", "public-read");
  form.append("success_action_status", "2xx");
  form.append("file", file);

  try {
    const s3 = axios.create();
    // eslint-disable-next-line
    delete s3.defaults.headers.common["Authorization"];

    const s3Upload = await s3({
      method: "POST",
      url,
      data: form,
      headers: {
        Authorization: "",

        "Content-Type": "multipart/form-data",
      },
    });

    return s3Upload.headers.location;
  } catch (error) {
    throw error;
  }
};

export const getCatalogueTemplate = async (
  sku: string,
): Promise<Template | null> => {
  const res = await axios.get(endpoints.GET_TEMPLATE(sku));
  return res.data;
};

export const updateCatalogueTemplate: TUpdateCatalogueTemplate = async (
  sku,
  data,
) => {
  return await axios.patch(endpoints.PATCH_TEMPLATE(sku), data);
};

export const cascadeCatalogueTemplate: CascadeCatalogueTemplate = async (
  sku,
  data,
  // if append is true, it will append the data to the existing data, otherwise it will override
  append = false,
) => {
  return await axios.patch(endpoints.PATCH_CASCADE_TEMPLATE(sku, append), data);
};

export const createCatalogueTemplate = async (
  template: Template,
): Promise<Template> => {
  const res = await axios.post(endpoints.POST_TEMPLATE, [template]);
  return res.data;
};
