import axios from "axios";

/**
 * Upload an image to s3 using the presigned url.
 */
export const uploadImageToS3 = async (
  file: File,
  url: string,
  fields: { [key: string]: string },
): Promise<string> => {
  const form = new FormData();

  /**
   * Add returned fields from our presigned url as form-data fields.
   */
  Object.keys(fields).forEach((field: string) => {
    form.append(field, fields[field]);
  });

  /**
   * Add extra fields required.
   */
  form.append("acl", "public-read");
  form.append("success_action_status", "2xx");
  form.append("file", file);

  try {
    const s3 = axios.create();
    // eslint-disable-next-line
    delete s3.defaults.headers.common["Authorization"];

    const s3Upload = await s3({
      method: "POST",
      url,
      data: form,
      headers: {
        Authorization: "",

        "Content-Type": "multipart/form-data",
      },
    });

    return s3Upload.headers.location;
  } catch (error) {
    throw error;
  }
};
