import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SubcontractorsIcon: React.FC<TGenericImage> = ({
  className = "view-jobs-icon",
  fill = "currentColor",
  stroke = "currentColor",
  width = 25,
  height = 25,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 480 480"
    >
      <g
        id="Page-1"
        stroke={stroke}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="car" fill={fill} fillRule="nonzero">
          <path
            d="M240,216 C213.531,216 192,237.531 192,264 C192,290.469 213.531,312 240,312 C266.469,312 288,290.469 288,264 C288,237.531 266.469,216 240,216 Z M240,296 C222.355,296 208,281.648 208,264 C208,246.352 222.355,232 240,232 C257.645,232 272,246.352 272,264 C272,281.648 257.645,296 240,296 Z"
            id="Shape"
          />
          <path
            d="M240,0 C107.664,0 0,107.664 0,240 C0,372.336 107.664,480 240,480 C372.336,480 480,372.336 480,240 C480,107.664 372.336,0 240,0 Z M240,464 C116.484,464 16,363.516 16,240 C16,116.484 116.484,16 240,16 C363.516,16 464,116.484 464,240 C464,363.516 363.516,464 240,464 Z"
            id="Shape"
          />
          <path
            d="M240,48 C134.133,48 48,134.133 48,240 C48,345.867 134.133,432 240,432 C345.867,432 432,345.867 432,240 C432,134.133 345.867,48 240,48 Z M240,64 C314.785,64 378.781,110.905 404.228,176.825 L344.29,196.804 C323.731,203.656 301.505,202.585 281.688,193.773 C255.196,181.992 224.801,181.992 198.317,193.773 L195.286,195.117 C177.27,203.133 156.845,204.75 137.774,199.664 L73.672,182.57 C97.54,113.646 163.061,64 240,64 Z M64,240 C64,238.37 64.079,236.759 64.124,235.14 L69.68,236.992 C148.556,263.283 204.337,332.195 214.259,414.099 C129.373,401.603 64,328.302 64,240 Z M240,416 C236.823,416 233.668,415.905 230.532,415.738 C221.078,326.136 160.658,250.454 74.742,221.82 L65.336,218.685 C66.193,211.625 67.454,204.69 69.124,197.911 L133.648,215.118 C142.296,217.423 151.175,218.571 160.046,218.571 C174.343,218.571 188.605,215.602 201.78,209.743 L204.811,208.399 C227.17,198.446 252.819,198.454 275.209,208.407 C298.666,218.821 324.994,220.102 349.35,211.977 L409.289,191.997 C411.745,200.644 413.554,209.556 414.662,218.685 L405.256,221.82 C319.339,250.454 258.92,326.136 249.466,415.738 C246.332,415.905 243.177,416 240,416 Z M265.742,414.099 C275.664,332.195 331.444,263.283 410.321,236.992 L415.877,235.14 C415.921,236.759 416.001,238.37 416.001,240 C416.001,328.302 350.627,401.603 265.742,414.099 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};
