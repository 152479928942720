import React from "react";
import cx from "classnames";

/**
 * Import components.
 */
import { FormButton, LinkButton, Transition } from "../..";

/**
 * Import types.
 */
import { IModal as IModalBody } from "../types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ModalBody: React.FC<IModalBody> = ({
  visible,
  image,
  title,
  subtitle,
  text,
  textClass,
  buttons,
  children,
  width,
  close,
  className,
}) => {
  return (
    <>
      <Transition
        className="modal-color top-0 left-0 bg-black z-50"
        customSettings={{
          from: { opacity: 0 },
          enter: { opacity: 0.7 },
          leave: { opacity: 0 },
        }}
        toggle={visible}
      />
      <Transition
        className="fixed top-0 left-0 w-full h-screen flex justify-center items-center z-50"
        transition="fadeIn"
        toggle={visible}
      >
        <div
          className={cx(
            "modal px-10 bg-white rounded-xl text-center bg-no-repeat",
            className,
            { "modal-image-padding": image, "py-8": !image },
          )}
          style={{
            backgroundImage: image ? `url(${image})` : undefined,
            width: width ? `${width}px` : "300px",
          }}
        >
          {close !== undefined && (
            <div className="flex justify-end mb-5">{close}</div>
          )}

          {title && (
            <h2 className="text-md font-bold text-primary leading-tight mt-5">
              {title}
            </h2>
          )}

          {subtitle && (
            <h3 className="modal-heading text-md mt-2 font-bold text-primary leading-tight">
              {subtitle}
            </h3>
          )}

          {(text || buttons) && (
            <div className="mt-4">
              {text && <p className={textClass || "mb-5 text-sm"}>{text}</p>}
              {buttons &&
                buttons.map((button, index) =>
                  button.to ? (
                    <LinkButton
                      key={index}
                      text={button.label}
                      className={cx(button.className, "mt-4 shadow-none")}
                      to={button.to}
                    />
                  ) : (
                    button.onClick && (
                      <FormButton
                        key={index}
                        name={button.name}
                        type="button"
                        text={button.label}
                        className={cx(button.className, "mt-4 shadow-none")}
                        variant="secondary"
                        onClick={button.onClick}
                        disabled={button.disabled}
                      />
                    )
                  ),
                )}
            </div>
          )}
          {children}
        </div>
      </Transition>
    </>
  );
};
