import React, { useState } from "react";
import { Field, Input, Link, Text } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import {
  PostcodeLookupField,
  PostcodeLookupFieldProps,
} from "./postcodeLookupField";

/**
 * NOTES - make this into a core component, so that it can be used everywhere. Prevent copying code
 * in multiple places. Lorenzo / Louis
 */

interface AddressFieldsProps {
  defaultManualAddress: boolean;
  enabledSwitch?: boolean;
  disabled?: boolean;
  onAddressSelection: PostcodeLookupFieldProps["onOptionSelect"];
}

export const AddressFields = ({
  defaultManualAddress,
  onAddressSelection: handleAddressSelection,
  disabled,
  enabledSwitch,
}: AddressFieldsProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [manualAddress, setManualAddress] = useState(defaultManualAddress);

  return (
    <>
      {manualAddress &&
        ["addressLine1", "addressLine2", "city", "county", "postcode"].map(
          (name) => {
            return (
              <Field
                label={translate(`suppliers.form.labels.${name}`)}
                name={name}
                key={name}
              >
                {({ field }) => <Input.Text {...field} disabled={disabled} />}
              </Field>
            );
          },
        )}
      {!manualAddress && (
        <PostcodeLookupField
          name="postcode"
          label={translate("suppliers.form.labels.address")}
          placeholder={translate(
            "suppliers.form.placeholders.enterYourPostcode",
          )}
          onOptionSelect={handleAddressSelection}
        />
      )}
      {enabledSwitch && (
        <Text className="text-center text-sm" color="brand" fontSize="xsmall">
          {!manualAddress && (
            <p>{translate("suppliers.messages.cantFindAddress")}</p>
          )}
          <Link
            type="button"
            underline
            onClick={() => setManualAddress(!manualAddress)}
            className="underline font-semibold"
          >
            {!manualAddress
              ? translate("suppliers.buttons.enterAddressMaually")
              : translate("suppliers.buttons.postcodeLookup")}
          </Link>
        </Text>
      )}
    </>
  );
};
