import { keyframes } from "@emotion/react";
import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export type AllowedColorKeys = keyof typeof theme.colors;

const animation = `
    0% {
        transform: rotate(0deg) translateX(57.5px) rotate(110deg);
    }
    25% {
        transform: rotate(90deg) translateX(57.5px) rotate(110deg);
    }
    50% {
        transform: rotate(180deg) translateX(57.5px) rotate(110deg);
    }
    75% {
        transform: rotate(270deg) translateX(57.5px) rotate(110deg);
    }
    100% {
        transform: rotate(360deg) translateX(57.5px) rotate(110deg);
    }`;

export const styles = StyleSheet(
  Object.assign(
    {
      text: {
        textAlign: "center",
        fontWeight: 600,
        marginTop: theme.spacing.small,

        "::after": {
          position: "absolute",
          overflow: "hidden",
          display: "inline-block",
          verticalAlign: "bottom",
          animation: "ellipsis 2000ms infinite",
          content: "''",
          width: "auto",
          marginLeft: "2px",
        },
      },

      loader: {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
        width: "8rem",
      },

      beeLogo: {
        position: "absolute",
        width: "2rem",
        height: "2rem",
        left: "48px",
        top: "44.5px",
        transform: "rotate(0deg) translateX(55px) rotate(0deg)",
        animation: `${keyframes(animation)} 4s linear infinite`,
      },
    },
    ...Object.entries(theme.colors).map(([accessor, { base }]) => ({
      [accessor]: { color: base },
    })),
  ),
);
