import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * Import useAuth hook.
 */
import { useAuthContext } from "../../../hooks";

/**
 * Import images.
 */
import FourOFourImage from "../../../images/fourofour.svg";

export const FourOFour: React.FC = () => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("app");

  const { isUserAuthenticated } = useAuthContext();

  /**
   * Add class to body so we know we are on 404 page
   * We are hiding sidebar based on this class
   */
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0] as HTMLElement;
    body.classList.add("four-o-four-body");

    return () => {
      body.classList.remove("four-o-four-body");
    };
  }, []);

  return (
    <div className="four-o-four text-white flex flex-col justify-center items-center text-center p-6 overflow-y-scroll">
      <h2 className="four-o-four-heading font-bold">
        {t("fourOFour.heading")}
      </h2>
      <p className="text-xl sm:text-3xl mb-8">{t("fourOFour.text")}</p>
      <div className="mb-8">
        <img src={FourOFourImage} alt="404" />
      </div>
      <Link
        className="text-white btn-secondary hover:btn-secondary-hover btn-medium transition ease-in duration-100"
        to="/"
      >
        {isUserAuthenticated()
          ? t("fourOFour.buttons.backLoggedIn")
          : t("fourOFour.buttons.backLoggedOut")}
      </Link>
    </div>
  );
};
