import React from "react";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { useQuery, useMutation } from "react-query";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { toasts } from "../../helpers/toasts";
import dayjs from "dayjs";
import {
  Button,
  Form,
  Heading,
  Input,
  Panel,
  Field,
  Table,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { permissionsValidation, permissionsInitialValues } from "./validation";

export const Permissions = (): React.ReactElement => {
  const [translate] = useTranslation("permissions");

  // Get Permissions
  const {
    refetch,
    isLoading: queryIsLoading,
    data,
  } = useQuery(
    "permissions",
    async () => {
      return (await instance.users.getPermissions({ params: { limit: 1000 } }))
        .data;
    },
    {
      onError: () =>
        toasts.error({
          message: translate("errors.errorMessage"),
        }),
    },
  );

  const handleFormSubmit = (
    values: typeof permissionsInitialValues,
    { resetForm }: FormikHelpers<typeof permissionsInitialValues>,
  ) => {
    mutate(values.name);
    resetForm();
  };

  const { mutate, isLoading } = useMutation(
    async (name: string) => await instance.users.postPermission({ name }),

    {
      onError: () => {
        toasts.error({
          message: translate("errors.errorMessage"),
        });
      },
      onSuccess: () => {
        refetch(),
          toasts.success({
            message: translate("success.title"),
          });
      },
    },
  );

  return (
    <>
      <div className="max-w-screen-lg py-5 sm:px-4 mx-auto">
        <Panel
          styles={{
            paddingTop: theme.spacing.medium,
            paddingBottom: theme.spacing.medium,
          }}
        >
          <Heading fontSize="large" color="brand">
            {translate("headings.heading")}
          </Heading>
          <div className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />

          <Form
            initialValues={permissionsInitialValues}
            validationSchema={permissionsValidation}
            onSubmit={handleFormSubmit}
          >
            {({ isValid, resetForm, values, dirty }) => {
              return (
                <>
                  <div className="flex flex-col lg:flex-row gap-x-6 items-center">
                    <div className="w-full md:flex-1 ">
                      <Field
                        name="name"
                        className="min-w-full"
                        label={translate("form.labels.name")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate("form.labels.name")}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <Button
                        color="accent"
                        type="submit"
                        className="flex justify-center gap-2 mt-6"
                        size="small"
                        disabled={!!isLoading || !isValid || !dirty}
                      >
                        {translate(
                          isLoading
                            ? "button.loading"
                            : "buttons.addPermission",
                        )}
                        {isLoading && (
                          <Icon.Loading size="small" color="brand.base" />
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Form>
        </Panel>
        {queryIsLoading && !data && (
          <Icon.Loading styles={{ margin: `${theme.spacing.xlarge} auto` }} />
        )}
        {data && (
          <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "50%" }} />
            </colgroup>
            <Table.Header
              fontSize="small"
              headings={[
                translate("labels.name"),
                translate("labels.createdOn"),
              ]}
            />
            <Table.Body>
              {data?.items.map((item) => (
                <Table.Row key={`table-row-${item.name}`}>
                  <Table.Cell.Text className="truncate">
                    {item.name}
                  </Table.Cell.Text>
                  <Table.Cell.Text className="truncate">
                    {dayjs(item.createdOn).format("MMMM D, YYYY h:mm A")}
                  </Table.Cell.Text>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  );
};
