import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  topPanel: {
    marginBottom: theme.spacing.large,
  },
  questionMark: {
    marginTop: theme.spacing.small,
    marginLeft: theme.spacing.xsmall2,
  },
  tooltipPanel: {
    padding: theme.spacing.small,
    width: "400px",
    textAlign: "center",
  },
  largeTooltipPanel: {
    padding: theme.spacing.small,
    width: "600px",
    textAlign: "left",
    whiteSpace: "pre-line",
  },
  errorMessage: {
    width: "fit-content",
    margin: "0 auto",
  },
});
