"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.camelToCapital = void 0;
var camelToCapital = function (text) {
    return (text
        .replace(/([A-Z])/g, " $1")
        // uppercase the first character
        .replace(/^./, function (str) { return str.toUpperCase(); }));
};
exports.camelToCapital = camelToCapital;
