import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Formik } from "formik";

/**
 * Import components.
 */
import { FormSelect, FormButton } from "../../core";

const initDate = `01-01-${dayjs().format("YYYY")}`;
const allMonthOptions = Array(12)
  .fill(0)
  .map((_, index) => {
    const month = dayjs(initDate).add(index, "month").format("MMMM");
    return {
      value: month,
      label: month,
    };
  });

// Last 10 years
const allYearOptions = Array(2)
  .fill(0)
  .map((_, index) => {
    const year = dayjs(initDate).subtract(index, "year").format("YYYY");
    return {
      value: year,
      label: year,
    };
  });

interface IMonthPicker {
  initialValue: Dayjs;
  onChange: (month: Dayjs) => void;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const MonthPicker: React.FC<IMonthPicker> = ({
  initialValue,
  onChange,
}) => {
  const [selectedMonth, setMonth] = useState(initialValue);
  const thisMonthYear = dayjs().format("YYYY-MM");
  const selectedMonthYear = dayjs(selectedMonth, "MMMM-YYYY").format("YYYY-MM");

  const initialValues = {
    month: {
      value: initialValue.format("MMMM"),
    },
    year: {
      value: initialValue.format("YYYY"),
    },
  };

  useEffect(() => {
    onChange(selectedMonth);
    // eslint-disable-next-line
  }, [selectedMonth]);

  return (
    <div className="pt-4 pb-0 sm:py-4 relative z-10">
      <Formik
        initialValues={initialValues}
        onSubmit={({ month, year }) => {
          setMonth(dayjs(`${month.value}-${year.value}`, "MMMM-YYYY"));
        }}
      >
        {({ dirty, handleSubmit }) => (
          <form
            className="flex flex-wrap sm:flex-no-wrap -mx-2"
            onSubmit={handleSubmit}
          >
            <div className="px-2 w-40">
              <FormSelect
                name="month"
                label={{
                  text: "Month",
                }}
                floatingLabel={false}
                defaultValue={initialValues.month.value}
                options={allMonthOptions}
                validate={false}
              />
            </div>
            <div className="px-2 w-40">
              <FormSelect
                name="year"
                label={{
                  text: "Year",
                }}
                floatingLabel={false}
                defaultValue={initialValues.year.value}
                options={allYearOptions}
                validate={false}
              />
            </div>
            <div className="w-full mt-5 sm:mt-0 sm:w-auto px-2">
              <FormButton
                text="Submit"
                name="submit"
                type="submit"
                variant="secondary"
                disabled={!dirty}
              />
            </div>
          </form>
        )}
      </Formik>
      <div className="mt-5">
        {selectedMonthYear === thisMonthYear && (
          <div
            className="bg-orange-alert text-white rounded-lg shadow-filter px-4 py-2 font-semibold"
            role="alert"
          >
            You are viewing data for the current month in progress.
          </div>
        )}
      </div>
    </div>
  );
};
