/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
const uniqueIdentifier = "BackgroundCircle";

export const BackgroundCircle: React.FC<TGenericImage> = ({
  className = "background-circle",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      viewBox="0 0 619 388"
    >
      <defs>
        <path id={`${uniqueIdentifier}A`} d="M.06 0H2000v1364H.06z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="rotate(31 611.995 -1720.89)">
        <mask id={`${uniqueIdentifier}B`} fill="#fff">
          <use xlinkHref={`#${uniqueIdentifier}A`} />
        </mask>
        <path
          fill="#000"
          d="M0 515V356.822c145.658.661 419.036-40.032 876.067-246.404C1596.3-215.019 1961.79 277.105 2000 332.596V515H0"
          mask={`url(#${uniqueIdentifier}B)`}
          opacity="0.038"
        />
        <path
          fill="#000"
          d="M0 1364V409.822c145.658.661 419.036-40.032 876.067-246.404C1596.3-162.019 1961.79 330.105 2000 385.596V1364H0"
          mask={`url(#${uniqueIdentifier}B)`}
          opacity="0.06"
        />
      </g>
    </svg>
  );
};
