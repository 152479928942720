import React, { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import {
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  theme,
  Text,
  displayErrorMessage,
  Message,
  Box,
} from "@clearabee/ui-library";

type driverDetailsProps = {
  email: string;
  reg: string;
  vehicleId: number | null;
};

const initialDriverDetails = {
  email: "",
  reg: "",
  vehicleId: null,
};

export const DriverJobs = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const [driverDetails, setDriverDetails] =
    useState<driverDetailsProps>(initialDriverDetails);

  /**
   * fetch all users with filter of role name :Clearabee Driver and Clearabee Administrator
   */
  const {
    data: allDriversData,
    isLoading: isLoadingAllDrivers,
    isFetching: isFetchingAllDrivers,
    refetch: refetchAllDrivers,
  } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver,Clearabee Administrator",
            limit: 1000,
          },
        })
      ).data.items,
    { cacheTime: 0 },
  );

  /**
   * get driver information with associated driver email
   */
  const {
    isLoading: isLoadingDriverByEmailInfo,
    isError: isErrorDriverByEmailInfo,
  } = useQuery(
    ["getDriverByEmail", driverDetails.email],
    async () =>
      (await instance.vehicles.getVehicleUser(String(driverDetails.email)))
        .data,
    {
      enabled: !!driverDetails.email,
      retry: 1,
      onSuccess: (data) =>
        setDriverDetails({
          email: driverDetails.email,
          reg: data.vehicle?.registration ?? "",
          vehicleId: data.vehicle?.id ?? null,
        }),
    },
  );

  /**
   * get driverjobs with associated vehicle registration by email
   */
  const {
    data: driverJobsData,
    isLoading: isLoadingDriverJobs,
    isError: isErrorDriverJobs,
  } = useQuery(
    ["getDriverJobs", driverDetails.reg],
    async () =>
      (
        await instance.jobs.getJobsByAsset(driverDetails.reg, {
          params: {
            date: dayjs().format("YYYY-MM-DD"),
            "include-related": true,
          },
        })
      ).data,
    {
      enabled: !!driverDetails.reg,
    },
  );

  return (
    <Box className=" mx-auto py-10 relative">
      <Panel className="flex flex-col gap-x-6 justify-between" shadow={false}>
        <Box className="flex justify-between">
          <Heading fontSize="large" color="brand">
            {translate("jobs.headings.read")}
          </Heading>
        </Box>
        <Form
          className="border-t border-grey-200 mt-4 pt-5"
          initialValues={{ label: "", value: "" }}
          onSubmit={() => {
            return;
          }}
        >
          {({ resetForm }) => {
            return (
              <>
                <Box className="flex flex-row items-center w-full gap-x-3">
                  <Box className="w-5/6">
                    <Field
                      name="driver"
                      className="flex-1 min-w-full"
                      label={translate("jobs.form.headings.driver")}
                    >
                      {({ field }) => (
                        <Input.Select
                          {...field}
                          isClearable
                          isSearchable
                          options={
                            !!allDriversData?.length
                              ? allDriversData.map(
                                  ({ firstName, lastName, email }) => ({
                                    label: `${firstName} ${lastName}`,
                                    value: email,
                                  }),
                                )
                              : []
                          }
                          onChange={(e) => {
                            if (!!allDriversData?.length) {
                              setDriverDetails({
                                reg: "",
                                email:
                                  allDriversData.find(
                                    (driver) => driver.email === e.target.value,
                                  )?.email ?? "",
                                vehicleId: null,
                              });
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className=" w-1/6">
                    <Button
                      className="ml-3 relative top-3"
                      size="small"
                      color="negative"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        refetchAllDrivers();
                        setDriverDetails(initialDriverDetails);
                      }}
                    >
                      {translate("jobs.buttons.reset")}
                    </Button>
                  </Box>
                </Box>
                <Box>
                  {/* Loading */}
                  {(isLoadingAllDrivers ||
                    isFetchingAllDrivers ||
                    isLoadingDriverByEmailInfo) && (
                    <Icon.Loading
                      color="brand"
                      styles={{ margin: `${theme.spacing.xlarge} auto` }}
                    />
                  )}
                  {/* Display Errors if not found Email and Reg */}
                  {isErrorDriverByEmailInfo && (
                    <Box className="w-full mt-5">
                      {displayErrorMessage(
                        translate("jobs.errors.notFoundDriver"),
                        ({ children }) => (
                          <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                            <Message type="error" background>
                              {children}
                            </Message>
                          </Box>
                        ),
                      )}
                    </Box>
                  )}
                  {/* Display Email and Reg of driver/user */}
                  {!isErrorDriverByEmailInfo &&
                    !isLoadingDriverByEmailInfo &&
                    !!driverDetails.email &&
                    !!driverDetails.reg &&
                    !!driverDetails.vehicleId && (
                      <Box className="flex flex-row items-center w-5/6 justify-start gap-x-3 mt-5">
                        <Box>
                          <Text fontSize="xsmall" className="font-bold">
                            {translate("jobs.form.headings.driverEmail")}
                          </Text>
                          <Text fontSize="xsmall">{driverDetails.email}</Text>
                        </Box>

                        <Box>
                          <Text fontSize="xsmall" className="font-bold">
                            {translate("jobs.form.headings.vehicleReg")}
                          </Text>
                          <Text fontSize="xsmall">{driverDetails.reg}</Text>
                        </Box>

                        <Box>
                          <Text fontSize="xsmall" className="font-bold">
                            {translate("jobs.form.headings.jobVehicleId")}
                          </Text>
                          <Text fontSize="xsmall">
                            {driverDetails.vehicleId}
                          </Text>
                        </Box>

                        {!isLoadingDriverJobs && !!driverJobsData?.length && (
                          <Box>
                            <Text fontSize="xsmall" className="font-bold">
                              {translate("jobs.form.headings.totalJobs")}
                            </Text>
                            <Text fontSize="xsmall">
                              {driverJobsData?.length}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    )}
                </Box>
              </>
            );
          }}
        </Form>
      </Panel>
      <Box className="relative flex flex-1">
        {/* Loading */}
        {isLoadingDriverJobs && (
          <Icon.Loading
            color="brand"
            styles={{ margin: `${theme.spacing.xlarge} auto` }}
          />
        )}
        {/* Display Errors if not jobs found */}
        {(isErrorDriverJobs ||
          (!isLoadingDriverJobs &&
            !!driverDetails.reg &&
            !driverJobsData?.length)) && (
          <Box className="w-full mt-10">
            {displayErrorMessage(
              translate("jobs.errors.noJobsFound"),
              ({ children }) => (
                <Box className="flex items-center justify-center w-full mb-4 overflow-scroll">
                  <Message type="error" background>
                    {children}
                  </Message>
                </Box>
              ),
            )}
          </Box>
        )}
        {/* Display jobs associated with selected driver if there are */}
        {!isLoadingDriverJobs && !!driverJobsData?.length && (
          <Box className="w-full">
            {driverJobsData
              // sort  job by start time in ascending order
              .sort(
                (a, b) =>
                  dayjs(a.plannedStartTime).unix() -
                  dayjs(b.plannedStartTime).unix(),
              )
              .map((job, index) => (
                <Panel
                  shadow={false}
                  key={index}
                  className="flex flex-row my-5 w-full"
                  style={{
                    paddingTop: theme.spacing.small,
                    paddingBottom: theme.spacing.small,
                  }}
                >
                  <Box className="flex flex-row items-center justify-between w-full gap-x-3">
                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobRef")}
                      </Text>
                      <Text fontSize="xsmall">{job.ref}</Text>
                    </Box>
                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobDate")}
                      </Text>
                      <Text fontSize="xsmall">
                        {dayjs(job.date).format("DD/MM/YYYY")}
                      </Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobPostcode")}
                      </Text>
                      <Text fontSize="xsmall">{job.addressPostcode}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobBcId")}
                      </Text>
                      <Text fontSize="xsmall">{job.bcId}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobStatus")}
                      </Text>
                      <Text fontSize="xsmall">{job.status}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobStatusId")}
                      </Text>
                      <Text fontSize="xsmall">{job.statusId}</Text>
                    </Box>
                    <Box className="gap-y-3 w-16">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobAsset")}
                      </Text>
                      <Text fontSize="xsmall">{job.asset}</Text>
                    </Box>
                    <Box className="gap-y-3 w-20">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobType")}
                      </Text>
                      <Text fontSize="xsmall">{job.type}</Text>
                    </Box>
                    <Box className="gap-y-3 w-24">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobOrderTypeId")}
                      </Text>
                      <Text fontSize="xsmall">{job.orderTypeId}</Text>
                    </Box>
                    <Box className="gap-y-3 w-56">
                      <Text fontSize="xsmall" className="font-bold">
                        {translate("jobs.form.headings.jobAssetCrew")}
                      </Text>
                      <Box className="flex overflow-scroll flex-col">
                        {job.assetCrew?.map((driver) => (
                          <Text fontSize="xsmall">{driver}</Text>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Panel>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
