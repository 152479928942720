"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dashboard = void 0;
exports.dashboard = {
    titles: {
        recentJobs: "Recent Jobs",
    },
    totals: {
        jobs: "total jobs",
        vehicles: "vehicles",
        employees: "employees",
    },
    buttonLabels: {
        manVan: "Man & Van",
        skipBags: "Skip Bags",
        skips: "Skips",
        sofaRemoval: "Sofa Removal",
        viewAllJobs: "View all jobs",
    },
    jobsTable: {
        headings: {
            status: "$t(jobs:table.headings.status)",
            jobReference: "$t(jobs:table.headings.jobReference)",
            contact: "$t(jobs:table.headings.contact)",
            postcode: "$t(jobs:table.headings.postcode)",
            type: "$t(jobs:table.headings.type)",
            vehicle: "Assigned Vehicle",
            date: "Collection Date",
        },
        noResults: "No jobs found, use another search term",
    },
    supplier: {
        jobs: "new jobs available",
        review: "Review",
        vehicle: {
            title: "Select your vehicle",
            subtitle: "You will only see jobs assigned to your vehicle.",
            form: {
                labels: {
                    myVehicle: "My Vehicle",
                },
                placeholders: {
                    myVehicle: "Select a vehicle",
                },
                success: {
                    set: "Successfully set your vehicle",
                    unset: "Successfully unset your vehicle",
                },
                buttons: {
                    save: "Save",
                    view: "View My Jobs",
                },
            },
        },
    },
    form: {
        label: {
            poNumber: "PO Number",
            jobReference: "Job Reference",
        },
        errors: {
            requiredField: "$t(common:requiredField)",
        },
    },
    dashboardV2: {
        titles: {
            bookAJob: "Book a Job",
            viewJobs: "View Jobs",
            viewAllCompanies: "View All Companies",
            viewReports: "View Reports",
            totalJobsToday: "Total Jobs Today",
            openJobsToday: "Open Jobs Today",
            completedJobsToday: "Completed Jobs Today",
            failedJobsToday: "Failed Jobs Today",
        },
    },
};
