import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  submitWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    gap: theme.spacing.small,
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  table: {
    margin: `${theme.spacing.xlarge} 0`,
    alignSelf: "flex-start",
    tableLayout: "fixed",
    borderSpacing: 0,
  },
  actionsCellDuplicate: {
    padding: theme.spacing.xsmall,
    backgroundColor: theme.colors.usp.base,
    borderRadius: "50%",
    color: theme.colors.light.base,
    marginRight: theme.spacing.xsmall,
  },
  actionsCellDelete: {
    padding: theme.spacing.xsmall,
    backgroundColor: theme.colors.negative.base,
    borderRadius: "50%",
  },
  modal: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    gap: theme.spacing.small,
    marginTop: theme.spacing.small,
  },
});
