import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";

/**
 * Import config hook and loading hook.
 */
// import { useLoadingState } from '../../../hooks'

/**
 * Import images.
 */
import { Tick } from "../../../images";

/**
 * Type props for button.
 */
import { TBasicButton } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const BasicButton: React.FC<TBasicButton> = ({
  name,
  type = "button",
  onClick,
  className,
  disabled,
  loading,
  loader = false,
  children,
}) => {
  /**
   * Button ref.
   */
  const buttonRef = useRef<HTMLButtonElement>(null);

  /**
   * Get loading state.
   */
  // const { isLoading } = useLoadingState( typeof loader === 'string' ? loader : undefined )
  const isLoading = false;
  const timeoutRef: any = useRef(false);

  /**
   * Loading classes.
   */
  const [loadingClass, setLoadingClass] = useState(
    loader ? "not-loading" : false,
  );

  /**
   * State.
   */
  const [clipWidth, setClipWidth] = useState(0);

  /**
   * Set clipping width based on buttons width.
   */
  useEffect(() => {
    const { current: currentButton } = buttonRef;

    if (currentButton) {
      const { width } = currentButton.getBoundingClientRect();
      setClipWidth(width);
    }
  }, []);

  /**
   * Add loading classes when loading initialises.
   */
  useEffect(() => {
    if (loader) {
      if (isLoading) {
        setLoadingClass("is-loading");
      } else if (loadingClass === "is-loading") {
        setLoadingClass("loaded");
        timeoutRef.current = setTimeout(() => {
          setLoadingClass("not-loading");
        }, 3000);
      }
    }
  }, [isLoading, loadingClass, loader]);
  // Remove timeout on dismount
  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  /**
   * An object is created with all of the set values.
   */
  const button = {
    name,
    type,
    onClick,
    classes: className,
    disabled,
    loading,
  };

  /**
   * Output the button.
   */
  return (
    <button
      name={button.name}
      className={cx(button.classes, loadingClass, "relative overflow-hidden")}
      type={button.type}
      disabled={
        (loader === true && loadingClass !== "not-loading") ||
        isLoading ||
        button.disabled ||
        button.loading
      }
      onClick={button.onClick}
      data-testid={`test-${button.name}`}
      ref={buttonRef}
    >
      {loader && (
        <>
          <span className="button-loading">
            <span
              className="button-clip"
              style={{ width: clipWidth !== 0 ? clipWidth : undefined }}
            >
              {children}
            </span>
          </span>
          <Tick classes={{ svg: "button-tick-svg" }} />
        </>
      )}
      <span className="button-label">{children}</span>
    </button>
  );
};
