import React, { useState } from "react";
import {
  Tabs,
  Form,
  Button,
  Field,
  Input,
  Text,
  Icon,
  Link as UiLink,
  theme,
  FilterListOption,
  Panel,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCatalogues, getTemplates, updateCatalogueItem } from "api";
import { toasts } from "helpers/toasts";
import { RouteLink } from "components/core";
import { useMutation } from "react-query";
import { Modal } from "@clearabee/ui-library";
import { Link } from "react-router-dom";
import { ErrorMessage, FormLoader } from "components/common/components";
import { Tooltip, TooltipContent, TooltipTrigger } from "../components";
import { PaginatedFilterList } from "./paginatedFilterList";
import { ListView } from "./listView";
import dayjs from "dayjs";

interface FormValues {
  blackoutDays: boolean[];
  blackoutDates: string[];
  catalogues: FilterListOption[];
  items: FilterListOption[];
  setDays: boolean;
  setDates: boolean;
}

interface HandleApiCalls {
  blackoutDays: number[] | undefined;
  blackoutDates: string[] | undefined;
  catalogues: string[];
  items: string[];
  append?: boolean;
}

const dayKeys = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const handleApiCalls = async ({
  blackoutDays,
  blackoutDates,
  catalogues,
  items,
  append = false,
}: HandleApiCalls): Promise<void> => {
  const handleUpdate = async (
    resolve: (value: unknown) => void,
    reject: (reason?: any) => void,
    id: string,
    sku: string,
  ): Promise<void> => {
    {
      try {
        resolve(
          await updateCatalogueItem(
            id,
            sku,
            {
              blackoutDates,
              blackoutDays,
            },
            append,
          ),
        );
      } catch (error) {
        toasts.error({
          message: `${sku} item does not exist on catalogue ${id}`,
        });
        reject(error);
      }
    }
  };

  await Promise.all(
    catalogues.flatMap((id) =>
      items.map(
        (sku) =>
          new Promise((resolve, reject) =>
            handleUpdate(resolve, reject, id, sku),
          ),
      ),
    ),
  );
};

export const Blackout = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [isOverriddenCascading, setIsOverriddenCascading] = useState(false);

  const defaultValues: FormValues = {
    blackoutDays: new Array(7).fill(false),
    blackoutDates: [],
    catalogues: [],
    items: [],
    setDays: false,
    setDates: false,
  };

  const {
    mutate,
    isLoading: isSubmitted,
    isSuccess,
    reset,
  } = useMutation(handleApiCalls);

  const templates = useQuery(
    ["readTemplates"],
    () => getTemplates({ limit: 2000 }),
    {
      retry: 0,
    },
  );
  const catalogues = useQuery(
    ["readCatalogues"],
    () => getCatalogues({ limit: 2000 }),
    {
      retry: 0,
    },
  );

  const handleSubmit = async (values: FormValues) => {
    mutate({
      catalogues: values.catalogues.map(({ value }) => value),
      items: values.items.map(({ value }) => value),
      blackoutDates: values.setDates
        ? values.blackoutDates.filter(Boolean)
        : undefined,
      blackoutDays: values.setDays
        ? values.blackoutDays.flatMap((day, index) => (day ? [index] : []))
        : undefined,
      append: !isOverriddenCascading,
    });
  };

  if (
    templates.isLoading ||
    templates.isFetching ||
    !templates.data ||
    catalogues.isLoading ||
    catalogues.isFetching ||
    !catalogues.data
  ) {
    return <FormLoader isLoading />;
  } else if (templates.isError || catalogues.isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Link to="/catalogues" className="inline-flex items-center mb-5">
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues"),
        })}
      </Link>
      <Form initialValues={defaultValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <>
            <div className="lg:flex mx-auto w-full">
              <div className="w-full lg:w-3/4 lg:mr-8">
                <Tabs
                  styles={{
                    padding: `${theme.spacing.xsmall} ${theme.spacing.medium}`,
                  }}
                >
                  <Tabs.Item label={translate("blackout.tabs.catalogues")}>
                    <PaginatedFilterList
                      formatOptions={(catalogue) => ({
                        label: `${catalogue.title} (${catalogue.id})`,
                        value: catalogue.id,
                      })}
                      label="catalogues"
                      data={catalogues.data}
                      searchPlaceholder={translate(
                        "filters.blackoutCataloguesPlaceholder",
                      )}
                      content={
                        <Text>
                          {translate("filters.blackoutCataloguesLabel")}:
                        </Text>
                      }
                      searchBy={["title", "description", "id"]}
                    />
                  </Tabs.Item>
                  <Tabs.Item label={translate("blackout.tabs.items")}>
                    <PaginatedFilterList
                      formatOptions={(template) => ({
                        label: `${template.title} (${template.sku})`,
                        value: template.sku,
                      })}
                      label="items"
                      data={templates.data}
                      searchPlaceholder={translate(
                        "filters.blackoutItemsPlaceholder",
                      )}
                      content={
                        <Text>{translate("filters.blackoutItemsLabel")}:</Text>
                      }
                      searchBy={["title", "description", "sku"]}
                    />
                  </Tabs.Item>
                </Tabs>
              </div>
              <div className="w-full lg:w-1/4 pt-8 lg:pt-20">
                <div className="flex flex-row items-center">
                  <div
                    className="flex flex-row items-center justify-start gap-x-2"
                    style={{ width: theme.spacing.xlarge5 }}
                  >
                    <Text
                      fontSize="small"
                      styles={{
                        fontWeight: "bold",
                      }}
                    >
                      {translate("overrideCascade")}
                    </Text>
                    <Tooltip placement="top">
                      <TooltipTrigger>
                        <Icon.Question color="brand" size="small" />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 10 }}
                        className="Tooltip"
                      >
                        <Panel
                          styles={{
                            padding: theme.spacing.small,
                            width: "600px",
                            textAlign: "left",
                            whiteSpace: "pre-line",
                          }}
                        >
                          <Text fontSize="small">
                            {translate("tooltips.overrideBlackouts")}
                          </Text>
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <Field name="override" type="checkbox">
                    {({ field }) => (
                      <Input.Toggle
                        {...field}
                        color="positive"
                        styles={{ marginLeft: theme.spacing.xsmall2 }}
                        onClick={() =>
                          setIsOverriddenCascading(!isOverriddenCascading)
                        }
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <div className="flex flex-row items-center">
                    <Text
                      fontSize="small"
                      styles={{
                        fontWeight: "bold",
                        width: theme.spacing.xlarge5,
                      }}
                    >
                      {translate("form.labels.blackOutDays")}
                    </Text>
                    <Field name="setDays" type="checkbox">
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          data-testid="blackout-days-toggle"
                          color="positive"
                          styles={{ marginLeft: theme.spacing.xsmall2 }}
                        />
                      )}
                    </Field>
                  </div>
                  {values.setDays && (
                    <div
                      className="grid grid-cols-7 gap-4"
                      css={{
                        marginRight: theme.spacing.xlarge3,
                        marginLeft: theme.spacing.small,
                      }}
                    >
                      {dayKeys.map((key, index) => (
                        <Field
                          key={key}
                          name={`blackoutDays.${index}`}
                          styles={{
                            label: {
                              marginTop: theme.spacing.xsmall,
                            },
                            marginBottom: 0,
                          }}
                        >
                          {({ field }) => (
                            <>
                              <Input.Checkbox
                                {...field}
                                defaultChecked={field.value}
                                label={translate(`form.labels.days.${key}`)}
                                labelPosition="top"
                                styles={{
                                  ".checkbox-control": {
                                    background: "white",
                                  },
                                }}
                              />
                            </>
                          )}
                        </Field>
                      ))}
                    </div>
                  )}
                </div>

                <div>
                  <div className="flex flex-row items-center">
                    <Text
                      fontSize="small"
                      styles={{
                        fontWeight: "bold",
                        width: theme.spacing.xlarge5,
                      }}
                    >
                      {translate("form.labels.blackOutDates")}
                    </Text>
                    <Field name="setDates" type="checkbox">
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          data-testid="blackout-dates-toggle"
                          color="positive"
                          styles={{ marginLeft: theme.spacing.xsmall2 }}
                        />
                      )}
                    </Field>
                  </div>
                  {values.setDates && (
                    <>
                      {values.blackoutDates.map(
                        (date: string, index: number) => (
                          <Field key={index} name={`blackoutDates.${index}`}>
                            {({ field }) => (
                              <div className="flex">
                                <div className="w-11/12">
                                  <Input.Date
                                    initialValue={date}
                                    dateFormat="YYYY-MM-DD"
                                    placeholder={translate(
                                      "form.placeholders.blackOutDates",
                                    )}
                                    disabledDays={{ before: dayjs().toDate() }}
                                    collapsable
                                    {...field}
                                    explicitValue={date}
                                  />
                                </div>
                                <UiLink
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFieldValue(
                                      "blackoutDates",
                                      values.blackoutDates.filter(
                                        (_: string, arrayIndex: number) =>
                                          arrayIndex !== index,
                                      ),
                                    );
                                  }}
                                  className="m-auto mr-0"
                                >
                                  <Icon.Close size="small" />
                                </UiLink>
                              </div>
                            )}
                          </Field>
                        ),
                      )}
                      <UiLink
                        styles={{
                          marginLeft: theme.spacing.small,
                        }}
                        onClick={() =>
                          setFieldValue("blackoutDates", [
                            ...(values.blackoutDates || []),
                            "",
                          ])
                        }
                      >
                        {translate("addDate")}
                      </UiLink>
                    </>
                  )}
                </div>

                <div className="pt-5">
                  <Button
                    size="medium"
                    color="accent"
                    type="submit"
                    disabled={
                      isSubmitted ||
                      isSuccess ||
                      !values.catalogues.length ||
                      !values.items.length ||
                      (!values.setDays && !values.setDates)
                    }
                    styles={{
                      marginLeft: theme.spacing.small,
                      marginTop: theme.spacing.medium,
                    }}
                  >
                    {translate("form.buttons.save")}
                  </Button>
                </div>
                <ListView
                  data={values.catalogues}
                  label={translate("blackout.cataloguesSelected")}
                />
                <ListView
                  data={values.items}
                  label={translate("blackout.itemsSelected")}
                />
              </div>
            </div>
          </>
        )}
      </Form>
      {isSuccess && (
        <Modal
          heading={translate("modal.blackout.heading")}
          width={500}
          onClose={reset}
          actions={
            <>
              <RouteLink href="/catalogues/templates">
                <Button
                  size="medium"
                  color="negative"
                  type="button"
                  className="mr-3"
                  as="a"
                >
                  {translate("common:routes.catalogues/templates")}
                </Button>
              </RouteLink>
              <RouteLink href="/catalogues">
                <Button
                  size="medium"
                  color="accent"
                  type="button"
                  className="ml-3"
                  as="a"
                >
                  {translate("common:routes.catalogues")}
                </Button>
              </RouteLink>
            </>
          }
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("modal.blackout.message")}
          </div>
        </Modal>
      )}
    </>
  );
};
