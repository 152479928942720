import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { styles, colorStyles, AllowedColorKeys } from "./Link.styles";
import { Icon } from "../Icon/Icon";
import { Icons } from "@clearabee/ui-library-base";

type LinkDetailedProps = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  "ref"
>;

export interface LinkProps extends UserStylesProps<LinkDetailedProps> {
  color?: AllowedColorKeys;
  underline?: boolean;
  iconPosition?: "before" | "after";
  icon?: Lowercase<keyof Icons>;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      color = "brand",
      underline = false,
      iconPosition,
      icon,
      children,
      styles: userStyles,
      ...rest
    },
    ref,
  ): React.ReactElement => {
    const IconComponent = icon
      ? Icon[(icon.charAt(0).toUpperCase() + icon.slice(1)) as keyof Icons]
      : null;

    return (
      <a
        css={css(
          styles.default,
          colorStyles[color],
          underline && styles.underline,
          userStyles,
        )}
        ref={ref}
        {...rest}
      >
        <>
          {iconPosition === "before" && IconComponent && (
            <IconComponent
              color={color}
              size="xsmall"
              styles={{ marginRight: "10px" }}
            />
          )}
          {children}
          {iconPosition === "after" && IconComponent && (
            <IconComponent
              color={color}
              size="xsmall"
              styles={{ marginLeft: "10px" }}
            />
          )}
        </>
      </a>
    );
  },
);
