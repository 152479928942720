"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = void 0;
exports.screens = {
    login: {
        screenTitle: "Log in to",
        screenSubtitle: "Your Account",
        info: "Enter your email address and password to access your jobs.",
        forgotPassword: "Forgot password?",
    },
    changePassword: {
        screenTitle: "Forgot Password?",
        screenSubtitle: "Enter New Password",
        info: "Enter your new password. This should contain at least one capital letter and one number, and must be a minimum of 6 characters in length.",
    },
    forgotPassword: {
        screenTitle: "Forgot Password?",
        screenSubtitle: "Enter Your Email",
        info: "Enter the email associated with your account, and we'll send you an email to reset your password.",
        button: "Send email",
    },
    resetPassword: {
        screenTitle: "Forgot Password?",
        screenSubtitle: "Reset Your Password",
        verificationCodeInfo: "We have sent a verification code to your email address. Use this code to reset your password.",
        get info() {
            return exports.screens.changePassword.info;
        },
        matchPassword: "Passwords must match",
        button: "Confirm",
    },
    dashboard: {
        screenTitle: "Dashboard",
        salutation: "Hey {{name}}!",
        jobsSummary: {
            noJobs: "You have no jobs today",
        },
        noVehicle: "No vehicle",
        noVehicleStatement: "You do not have a vehicle assigned. Please contact the office.\n\n(Once assigned, pull down to refresh.)",
        jobCompletionStatement: "Jobs can only be completed in the order they are presented.",
        JobSummaryPanel: {
            buttons: {
                viewJob: "View",
                startDay: "Start Day",
                endDay: "End Day",
                resumeJob: "Resume Job",
                pending: "Pending",
            },
            orderTypes: {
                startOfDay: "Start of Day",
                endOfDay: "End of Day",
                default: "Collection",
            },
            distance: "miles away",
        },
    },
    startAndEndOfDay: {
        screenTitle: {
            startOfDay: "Start of Day",
            endOfDay: "End of Day",
        },
        form: {
            submit: "Start day",
        },
    },
    jobDetails: {
        defaultType: "Collection",
        jobDetailsPanel: {
            address: "Address",
            collectionAddress: "Collection Address",
            customerInformation: "Customer Information",
            jobDetails: "Job Details",
            accessInformation: "Access Information",
            jobImages: "Job Images",
            driverDetails: {
                heading: "Driver Details",
                driverSelectQuestion: "Who will be driving to this job?",
                contactConfirmation: "I have attempted to contact the customer to confirm an ETA",
            },
            additionalPhotos: "Additional Photos",
            jobPhotos: "Job Photos",
            jobPhotosSubText: "Please add before, after, failed or additional photos.",
        },
        worksheetsErrorMessage: "Data is missing for this job. Please navigate back to the Dashboard, and pull down to refresh.",
        confirmationModal: {
            genericCopy: "Please confirm you are on your way",
            copy: "This will notify the customer that you are on your way. Do you want to confirm?",
            button: "Confirm",
        },
        buttons: {
            driveToJobButton: "Drive Now",
            completeWorksheets: "Complete Worksheets",
            viewInMaps: "View in Maps",
            getDirections: "Get Directions",
            suspend: "Suspend Job",
        },
        mapSelectModal: {
            directionsQuestion: "How would you like to navigate?",
            viewQuestion: "How would you like to view the location?",
            google: "Google Maps",
            apple: "Apple Maps",
        },
        suspendJobModal: {
            copy: "Are you sure you want to suspend this job?\n\n(Once suspended, you may only complete the next scheduled Tip Job, Quick Job or this job.)",
            button: "Suspend",
        },
    },
    camera: {
        permissionInfo: "We need your permission to show the camera",
        buttons: {
            grantPermission: "Grant permission",
            retake: "Retake",
            backToCamera: "Back to Camera",
            usePhoto: "Use photo",
            usePhoto_plural: "Use photos", // '_plural' required for i18n to select the appropriate property for the provided count
        },
    },
    signature: {
        open: "Tap to sign",
    },
    photo: {
        open: "Tap to open camera",
        add: "Tap to add photos",
        retake: "Tap to Retake",
    },
    riskAssessment: {
        screenTitle: "Risk Assessment",
        isJobGoingAheadQuestion: "Is the job going ahead?",
        buttons: {
            submit: "Submit risk assessment",
            fail: "Request to fail job",
        },
    },
    failJob: {
        buttons: {
            submit: "Request this job be failed",
        },
    },
    settings: {
        infomation: {
            title: "User details & app data",
            subTitle: "Settings",
            itemsToSync: "{{number}} items",
        },
        buttons: {
            logout: "Logout",
            clearStorage: "Clear storage",
            clearQueue: "Clear queue",
        },
        headings: {
            firstName: "First name",
            lastName: "Last name",
            email: "Email",
            version: "App Version",
            storage: "Storage",
            sync: "Sync status",
        },
        modal: {
            clearStorage: "Are you sure you want to clear storage? \n\nThis will remove all jobs from your Dashboard. \n\nTo refresh your jobs, pull down on the Dashboard. (You must have a signal or be connected to wifi for jobs to load.)",
            clearQueue: "Are you sure you want to clear the queue? \n\nIf you have pictures or worksheets from jobs that have not been synced, they will be lost and cannot be retrieved.",
            logoutQuestion: "Are you sure you want to log out?",
            cancel: "Cancel",
        },
    },
    completedJobs: {
        subtitle: "Previous Jobs",
    },
};
