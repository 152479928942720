import { stringRequired, fieldRequired } from "validation/common";
import * as Yup from "yup";

export const getValidationSchema = (
  commentsRequired: boolean,
  acceptOnBehalf: boolean,
) =>
  Yup.object().shape({
    validUntil: stringRequired,
    validUntilTime: stringRequired,
    products: Yup.array(
      Yup.object({
        price: Yup.number().min(1, fieldRequired),
        qty: Yup.number().min(1, fieldRequired),
        sku: stringRequired,
        serviceType: Yup.string(),
      }),
    ),
    companyUserEmail: acceptOnBehalf ? stringRequired : Yup.string(),
    comments: commentsRequired ? stringRequired : Yup.string(),
  });
