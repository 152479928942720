import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import dayjs from "dayjs";

/**
 * Import components.
 */
import { JobStatus } from "../../components";
import { FormButton } from "../../../core";
import { PaginatedResultsActions } from "../../../common/components/paginatedResults/paginatedResultsActions";
import {
  Button,
  FlexGrid,
  Input,
  Table,
  useModal,
  Form,
  Icon,
  Field,
  theme,
  Panel,
  createResponsiveStyles,
} from "@clearabee/ui-library";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { useMutation, useQuery } from "react-query";
import { useInstance } from "api/dsa";
import { User } from "@clearabee/api";
import { toasts } from "helpers";
import { search } from "helpers/search";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";

interface JobDetailsProps {
  id: number;
  status: string;
  jobRef: string;
  collection: string;
  isFetching: boolean;
  hasInvoice: boolean;
  hasTransferNotes: boolean;
  companyCode?: string;
  jobData: ApiResponseData<typeof instance.jobs.getJobByRef>;
  cancelJob: (dsaJobId?: string) => Promise<void>;
  editJob: () => void;
  setBookJobAgainOpen: (value: boolean) => void;
}
export const JobDetails = ({
  status,
  collection,
  jobRef,
  isFetching,
  cancelJob,
  editJob,
  hasInvoice,
  hasTransferNotes,
  companyCode,
  jobData,
  id,
  setBookJobAgainOpen,
}: JobDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { doesUserHaveRole, getCurrentUserCurrentCompanySettings } =
    useAuthContext();

  const [ContractorModal, showContractorModal] = useModal();
  const [CancelModal, showCancelModal] = useModal();
  const api = useInstance();
  const [selectedContractors, setSelectedContractors] = useState<string[]>([]);
  const [filteredContractors, setFilteredContractors] = useState<User[]>();
  const { hideInvoices, canInvoice, hideTransferNotes } =
    getCurrentUserCurrentCompanySettings() || {};

  const isAdmin = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    // roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);
  const isViewOnly = doesUserHaveRole(roles.VIEW_ONLY);
  // Subcontractors, with the distance of their location from the job.
  const subcontractorsQuery = useQuery(
    "jobDetailsUsersList",
    () =>
      api.users.list(`parentsOnly=true&jobPostcode=${jobData.addressPostcode}`),
    { cacheTime: 5000 },
  );
  // Find existing Job data in DSA
  const dsaJobQuery = useQuery(
    `jobSearch-${jobData.bcId}`,
    () => api.jobs.list(`bigChangeId=${jobData.bcId}`),
    {
      onSuccess: (data) => {
        setSelectedContractors(data.data[0]?.availableContractors || []);
      },
      retry: 0,
    },
  );

  // Create or Update DSA job
  const { mutate: updateJob } = useMutation<
    unknown,
    unknown,
    { contractors: string[]; invoiceAmount: string }
  >(
    ({ contractors, invoiceAmount }) => {
      if (!jobData.bcId) {
        throw Error("Job missing BC reference");
      }
      if (dsaJobQuery.data?.data?.length) {
        // PATCH
        return api.jobs.update(dsaJobQuery.data?.data[0]._id.toString(), {
          rejectionReason: "",
          availableContractors: contractors,
          invoiceDetails: {
            amount: parseFloat(invoiceAmount).toFixed(2) || undefined,
          },
        });
      }
      return api.jobs.add({
        jobId: String(jobData.id),
        jobReference: jobRef,
        bigChangeId: jobData.bcId.toString(),
        availableContractors: contractors,
        collectionDetails: {
          requestedDate: jobData.date,
          requestedTime: jobData.timeslot || "", // Assist
          collectionAddress: {
            addressLine1: `${jobData.addressLine1}` || "",
            addressLine2: `${jobData.addressLine2}`,
            city: `${jobData.addressCity}` || "",
            postcode: `${jobData.addressPostcode}` || "",
          },
        },
        customerDetails: {
          name: (
            jobData.contactFirstName +
            " " +
            jobData.contactLastName
          ).trim(),
          email: jobData.contactEmail || "",
          phoneNumber: jobData.contactPhoneNumber || "",
        },
        wasteDetails: {
          description: jobData.description || "",
        },
        invoiceDetails: {
          amount: parseFloat(invoiceAmount).toFixed(2) || undefined,
        },
      });
    },
    {
      onSuccess: () => {
        showContractorModal(false);
        dsaJobQuery.refetch();
      },
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
    },
  );
  const editDisabled = [
    "Completed",
    "Started",
    "Failed",
    "Cancelled",
    "On the way",
  ].some((index) => status === index);
  const searchContractors = (value: string) => {
    const contractors = subcontractorsQuery?.data?.data;
    //local variable is required, or it will search filtered results and not all results
    // If searching before subcontractors have loaded, it will throw
    if (!contractors) return;
    const keysToSearch: string[] = ["email", "company"];
    const filteredContractors = search(contractors, value, keysToSearch);
    setFilteredContractors(filteredContractors);
  };

  return (
    <>
      <CancelModal
        width={400}
        heading={translate("update.modal.heading")}
        onClose={() => showCancelModal(false)}
        actions={
          <div className="mt-6">
            <Button
              onClick={() =>
                cancelJob(dsaJobQuery.data?.data[0]?._id.toString())
              }
              color="accent"
              className="mr-5"
              size="medium"
            >
              {translate("update.modal.yes")}
            </Button>
            <Button
              onClick={() => showCancelModal(false)}
              size="medium"
              color="negative"
            >
              {translate("update.modal.no")}
            </Button>
          </div>
        }
      />
      <Panel
        styles={{
          marginBottom: theme.spacing.large,
          padding: theme.spacing.medium,
        }}
      >
        <div className="flex flex-wrap sm:flex-no-wrap mb-6 md:mb-10 relative">
          <h3 className="text-xl text-primary font-bold mx-auto mb-5 sm:mb-0 sm:mx-0 flex-shrink-0">
            {translate("headings.jobDetails")}
          </h3>
          <div className="sm:ml-5 flex w-full">
            {!isViewOnly && (
              <div>
                <FormButton
                  name="edit"
                  text="Edit Job"
                  type="button"
                  disabled={editDisabled}
                  variant="secondary"
                  className="h-8 mr-3 leading-3"
                  onClick={editJob}
                />
                <FormButton
                  name="cancel"
                  text="Cancel Job"
                  type="button"
                  variant="danger"
                  className="h-8 leading-3"
                  disabled={editDisabled}
                  onClick={() => showCancelModal(true)}
                />
              </div>
            )}
            <div className="ml-auto flex gap-x-3 items-center">
              {canInvoice && companyCode && (
                <Button
                  size="medium"
                  color="brand"
                  type="button"
                  styles={{ marginLeft: "auto" }}
                  onClick={() => setBookJobAgainOpen(true)}
                >
                  {translate("update.buttons.bookAgain")}
                </Button>
              )}
              {isAdmin && (
                <>
                  {!dsaJobQuery.isLoading && dsaJobQuery.data?.data && (
                    <>
                      {dsaJobQuery.data.data[0]?.contractor && (
                        <div className="ml-auto">
                          <Button size="small" color="warning" type="button">
                            {translate("contractorStatus", {
                              status: dsaJobQuery.data.data[0]?.status,
                            })}
                          </Button>
                        </div>
                      )}

                      {!dsaJobQuery.data.data[0]?.contractor && (
                        <Button
                          onClick={() => showContractorModal(true)}
                          size="medium"
                          color="warning"
                          type="button"
                          styles={{ marginLeft: "auto" }}
                        >
                          {translate("assignContractor", {
                            contractors:
                              dsaJobQuery.data.data[0]?.availableContractors
                                ?.length || 0,
                          })}
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {isAdmin && dsaJobQuery.isLoading && (
            <div className="absolute top-0 right-0 left-0 bottom flex items-center justify-center bg-white bg-opacity-75">
              <Icon.Loading color="brand" />
            </div>
          )}
        </div>
        {isFetching && (
          <div
            className={cx(
              "bg-opacity-75 transition-all duration-500 rounded-lg flex items-center justify-center text-primary z-10",
              {
                "opacity-0 invisible": !isFetching,
              },
            )}
          >
            {translate("common:loading")}
          </div>
        )}
        {!isFetching && (
          <>
            <div className="flex flex-wrap -mx-2 justify-between text-gray-600 text-sm">
              <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                <h3 className="py-2 sm:py-3 font-bold text-black">
                  {translate("headings.status")}
                </h3>
                <JobStatus
                  row={{
                    original: { status },
                  }}
                />
              </div>
              {!!jobData.meta?.jobResult && (
                <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.jobResult")}
                  </h3>
                  {jobData.meta?.jobResult}
                </div>
              )}
              <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                <h3 className="py-2 sm:py-3 font-bold text-black">
                  {translate("headings.collectionDate")}
                </h3>
                {dayjs(collection).format("dddd, MMMM Do YYYY")}
              </div>
              <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                <h3 className="py-2 sm:py-3 font-bold text-black">
                  {translate("headings.jobReference")}
                </h3>
                {jobRef}
              </div>
              {companyCode && (
                <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.companyCode")}
                  </h3>
                  {companyCode}
                </div>
              )}
              {!hideInvoices && (
                <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.invoices")}
                  </h3>
                  {hasInvoice && (
                    <PaginatedResultsActions
                      functionName=""
                      actions={["invoice"]}
                      row={{
                        original: {
                          id,
                          mutateUrl: "",
                          singleLink: "",
                          invoiceMutateUrl: hasInvoice
                            ? `/jobs/invoice/${encodeURIComponent(
                                jobRef,
                              )}/download`
                            : hasInvoice,
                          notesMutateUrl: "",
                        },
                      }}
                    />
                  )}
                  {!hasInvoice && <span>{translate("common:none")}</span>}
                </div>
              )}
              {!hideTransferNotes && (
                <div className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.wasteTransferNotes")}
                  </h3>
                  {hasTransferNotes && (
                    <PaginatedResultsActions
                      functionName=""
                      actions={["notes"]}
                      row={{
                        original: {
                          id,
                          mutateUrl: "",
                          singleLink: "",
                          invoiceMutateUrl: "",
                          notesMutateUrl: hasTransferNotes
                            ? `/jobs/waste-transfer-note/${encodeURIComponent(
                                jobRef,
                              )}/download`
                            : hasTransferNotes,
                        },
                      }}
                    />
                  )}
                  {!hasTransferNotes && <span>{translate("common:none")}</span>}
                </div>
              )}
            </div>
          </>
        )}
      </Panel>
      {isAdmin && (
        <Form
          renderFormElement={false}
          initialValues={{
            invoiceAmount: "",
            searchTerm: "",
          }}
          onSubmit={(values) => {
            updateJob({
              contractors: selectedContractors,
              invoiceAmount: values.invoiceAmount,
            });
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <ContractorModal
              width={1200}
              styles={{
                backgroundColor: theme.colors.light.base,
                ":before": { backgroundColor: theme.colors.light.base },
                ...createResponsiveStyles({
                  medium: {
                    padding: theme.spacing.xlarge2,
                  },
                }),
              }}
              heading={"Contractor Availability"}
              onClose={() => showContractorModal(false)}
              actions={
                <>
                  <FlexGrid
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <FlexGrid.Cell>
                      <Field
                        name="invoiceAmount"
                        label="Invoice Amount"
                        styles={{ margin: 0 }}
                      >
                        {({ field }) => (
                          <Input.Text type="number" prefix="£" {...field} />
                        )}
                      </Field>
                    </FlexGrid.Cell>
                    <FlexGrid.Cell>
                      <Button
                        color="accent"
                        type="submit"
                        size="medium"
                        onClick={() => handleSubmit()}
                        styles={{ marginLeft: "auto" }}
                        disabled={
                          !values.invoiceAmount || !selectedContractors.length
                        }
                      >
                        {translate("makeAvailable")}
                      </Button>
                    </FlexGrid.Cell>
                  </FlexGrid>
                </>
              }
            >
              <div className="my-10 flex flex-row items-center">
                <Field
                  name="searchTerm"
                  styles={{
                    marginRight: theme.spacing.medium,
                    width: "100%",
                  }}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "form.placeholder.searchByEmailAddress",
                      )}
                    />
                  )}
                </Field>
                <Button
                  size="xsmall"
                  style={{
                    backgroundColor: "transparent",
                    height: theme.spacing.xlarge2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => searchContractors(values.searchTerm)}
                >
                  <Icon.Search color="dark" size="medium" />
                </Button>
                <Button
                  className="ml-5"
                  size="small"
                  color="warning"
                  onClick={() => {
                    searchContractors("");
                    setFieldValue("searchTerm", "");
                  }}
                >
                  {translate("buttons.labels.showAll")}
                </Button>
              </div>
              <Table
                className="block my-6 overflow-y-scroll"
                styles={{ height: theme.screens.xsmall }}
              >
                <Table.Header
                  fontSize="xsmall"
                  headings={[
                    "Company",
                    "Email",
                    "Phone",
                    "License",
                    "Distance From Job",
                    "Actions",
                  ]}
                />
                <Table.Body>
                  {(
                    filteredContractors ||
                    subcontractorsQuery?.data?.data ||
                    []
                  )
                    .filter((contractor) => contractor.on_stop !== "true")
                    .map((contractor, index) => (
                      <Table.Row key={index} styles={{ textAlign: "left" }}>
                        <Table.Cell>{contractor.company}</Table.Cell>
                        <Table.Cell>{contractor.email}</Table.Cell>
                        <Table.Cell>{contractor.phone}</Table.Cell>
                        <Table.Cell>{contractor.wc_license}</Table.Cell>
                        <Table.Cell>
                          {contractor.distance
                            ? `${contractor.distance?.toFixed(1)} miles`
                            : ""}
                        </Table.Cell>
                        <Table.Cell>
                          <Input.Checkbox
                            label=""
                            checked={selectedContractors.includes(
                              contractor.username,
                            )}
                            onChange={() => {
                              if (
                                !selectedContractors.includes(
                                  contractor.username,
                                )
                              ) {
                                setSelectedContractors((c) => [
                                  ...c,
                                  contractor.username,
                                ]);
                              } else {
                                setSelectedContractors((c) =>
                                  c.filter((cs) => cs !== contractor.username),
                                );
                              }
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </ContractorModal>
          )}
        </Form>
      )}
    </>
  );
};
