import React from "react";
import { css, CSSObject } from "@emotion/react";
import { UserStylesProps } from "../../../../utils";
import { styles, AllowedColorKeys } from "./InputToggle.styles";
import { Text } from "../../../Text/Text";
export interface InputToggleProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  color?: AllowedColorKeys;
  label?: string;
  labelTextStyles?: CSSObject;
  toggleStyles?: CSSObject;
}

export const InputToggle = React.forwardRef<HTMLInputElement, InputToggleProps>(
  (
    {
      color = "brand",
      label,
      styles: userStyles,
      labelTextStyles,
      toggleStyles,
      ...rest
    }: InputToggleProps,
    ref,
  ) => {
    return (
      <div css={css(userStyles)}>
        <Text as="label" css={css(styles.toggle)}>
          {label && (
            <Text
              as="span"
              fontSize="small"
              css={css([styles.label, labelTextStyles])}
            >
              {label}
            </Text>
          )}
          <input
            type="checkbox"
            role="switch"
            css={css(styles.input, styles.input.colors[color])}
            {...rest}
            ref={ref}
          />
          <Text
            as="span"
            className="toggle-slider"
            css={css(styles.toggleSlide, toggleStyles)}
          />
        </Text>
      </div>
    );
  },
);
