import React from "react";

/**
 * Import components.
 */
import { ProductsAccordion } from "../../components";
import { FormButton } from "../../../core";

/**
 * Import hooks.
 */
import {
  useCatalogueContext,
  useBasketContext,
  useMultiFormContext,
} from "../../../../hooks";

/**
 * Interface.
 */
import { IProductsAccordion } from "../../types";

/**
 * Select service component.
 */
export const SelectProducts: React.FC = () => {
  /**
   * Multi form context.
   */
  const { formState, nextStep } = useMultiFormContext();
  const { service } = formState;

  /**
   * Basket context.
   */
  const { hasItems, calculateTotal } = useBasketContext();

  /**
   * Catalogue context.
   */
  const { catalogueItems } = useCatalogueContext();
  const products = catalogueItems?.find((item) => item.sku === service);

  /**
   * Accordions
   */
  const accordions: IProductsAccordion[] = [];
  if (products && products.children) {
    const accordion = {
      id: "vt",
      title: "VT Products",
      image: "",
      products: products?.children.map((product) => product),
    };
    accordions.push(accordion);
  }

  return (
    <>
      <div className="pt-6 pb-12 md:pb-6">
        {accordions.map((accordion: IProductsAccordion) => (
          <ProductsAccordion
            key={`accordion-container-${accordion.id}`}
            {...accordion}
          />
        ))}
        <div className="mt-10 flex justify-around">
          <FormButton
            variant="secondary"
            name="next"
            type="button"
            text="Next"
            disabled={!hasItems || calculateTotal(false) <= 0}
            onClick={() => nextStep()}
          />
        </div>
      </div>
    </>
  );
};
