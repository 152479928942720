import React, { useState, useEffect } from "react";
import { Icon, theme } from "@clearabee/ui-library";
import { Template } from "models/template";
import cx from "classnames";
import { styles } from "./styles";
import { SideBarItem } from "./sideBarItem";

interface SectionProps {
  items: Template[] | undefined;
  label: string;
  isOpen?: boolean;
}

export const Section = ({
  items,
  label,
  isOpen = false,
}: SectionProps): React.ReactElement => {
  const [showItems, setShowItems] = useState(isOpen);
  useEffect(() => setShowItems(isOpen), [items]);

  const sectionIdealSize = window.innerHeight / 2 - 123;
  const itemsHeight = (items?.length || 0) * 25;
  const minHeight =
    itemsHeight <= sectionIdealSize
      ? `${itemsHeight + 10}px`
      : "calc(50vh - 153px)";

  return (
    <>
      <div
        className="flex cursor-pointer select-none mb-2 transition-colors"
        onClick={() => setShowItems(!showItems)}
        css={{ ...styles.itemHoverEffect, ...styles.xMargin }}
      >
        <div className="w-full">{label}</div>
        <div className="w-full text-right">
          <Icon.Chevron
            className={cx(
              "transform inline -mr-1",
              showItems ? "-rotate-90" : "rotate-90",
            )}
            size="small"
            styles={styles.chevron}
          />
        </div>
      </div>
      {showItems && (
        <div
          css={{
            ...styles.scrollable,
            ...styles.xMargin,
            [`@media (min-width: ${theme.screens.xlarge3})`]: {
              minHeight,
              maxHeight: "none",
            },
          }}
        >
          {items?.map((item) => (
            <SideBarItem template={item} key={`category-${item.sku}`} />
          ))}
        </div>
      )}
    </>
  );
};
