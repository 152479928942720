import { AxiosInstance, AxiosResponse } from "axios";
import { Vehicle } from "@clearabee/api";

export interface AddVehicleApiProps {
  name?: string;
  registration?: string;
  images?: string[];
  status?: string;
  enabled?: boolean;
}

export type VehiclesInstance = {
  list: (query?: string) => Promise<AxiosResponse<Vehicle[]>>;
  get: (id: string) => Promise<AxiosResponse<Vehicle>>;
  add: (values: AddVehicleApiProps) => Promise<AxiosResponse<Vehicle>>;
  update: (
    id: string,
    values: Partial<AddVehicleApiProps>,
  ) => Promise<AxiosResponse<Vehicle>>;
  delete: (id: string) => Promise<AxiosResponse<void>>;
};

export const vehicles = (instance: AxiosInstance): VehiclesInstance => ({
  list: (query = "") => {
    return instance.get(`vehicles?${query}`);
  },
  get: (id: string) => {
    return instance.get(`vehicles/${id}`);
  },
  add: (values: AddVehicleApiProps) => {
    return instance.post(`vehicles`, values);
  },
  update: (id: string, values: Partial<AddVehicleApiProps>) => {
    return instance.patch(`vehicles/${id}`, values);
  },
  delete: (id: string) => {
    return instance.delete(`vehicles/${id}`);
  },
});
