"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stripe = exports.useStripeContext = exports.StripeContext = exports.StripeElement = void 0;
var react_1 = __importStar(require("react"));
var react_stripe_js_1 = require("@stripe/react-stripe-js");
var stripe_js_1 = require("@stripe/stripe-js");
var useStripePayments_1 = require("../../hooks/useStripePayments");
var theme_1 = require("./theme");
var StripeElement = function (_a) {
    var onCardValidity = _a.onCardValidity, onPaymentSuccess = _a.onPaymentSuccess, onPaymentFailure = _a.onPaymentFailure, children = _a.children, showAddressFields = _a.showAddressFields, rest = __rest(_a, ["onCardValidity", "onPaymentSuccess", "onPaymentFailure", "children", "showAddressFields"]);
    var _b = (0, useStripePayments_1.useStripePayment)(rest), handleFormSubmission = _b.handleFormSubmission, handleCardElementChange = _b.handleElementChange, card = _b.card, payment = _b.payment, errorMessage = _b.errorMessage;
    var _c = (0, react_1.useState)(false), elementReady = _c[0], setElementReady = _c[1];
    var isCardValid = card.status === "valid";
    var isProcessing = payment.status === "processing";
    (0, react_1.useEffect)(function () {
        if (typeof onCardValidity === "function") {
            onCardValidity(isCardValid);
        }
    }, [card.status]);
    (0, react_1.useEffect)(function () {
        if (rest.amount === 0) {
            if (typeof onCardValidity === "function") {
                onCardValidity(isCardValid);
            }
        }
    }, [rest.amount]);
    (0, react_1.useEffect)(function () {
        if (payment.status === "succeeded") {
            if (typeof onPaymentSuccess === "function") {
                onPaymentSuccess(payment);
            }
        }
        else if (payment.status === "error") {
            if (typeof onPaymentFailure === "function") {
                onPaymentFailure(payment);
            }
        }
    }, [payment.status]);
    var PaymentElementMemo = (0, react_1.useCallback)(function (props) { return (react_1.default.createElement(react_stripe_js_1.PaymentElement, __assign({ onReady: function () { return setElementReady(true); }, options: !showAddressFields
            ? {
                fields: {
                    billingDetails: "never",
                },
            }
            : undefined }, props, { onChange: handleCardElementChange }))); }, [rest.clientSecret]);
    return (react_1.default.createElement(react_1.default.Fragment, null, children === null || children === void 0 ? void 0 : children({
        elementReady: elementReady,
        errorMessage: rest.clientSecret
            ? errorMessage
            : "No client secret supplied",
        isProcessing: isProcessing,
        isCardValid: isCardValid,
        PaymentElement: PaymentElementMemo,
        handleFormSubmission: handleFormSubmission,
        presentPaymentSheet: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, ({})];
        }); }); },
    })));
};
exports.StripeElement = StripeElement;
exports.StripeContext = (0, react_1.createContext)({});
var useStripeContext = function () { return (0, react_1.useContext)(exports.StripeContext); };
exports.useStripeContext = useStripeContext;
var Stripe = function (_a) {
    var publicKey = _a.publicKey, options = _a.options, rest = __rest(_a, ["publicKey", "options"]);
    var _b = (0, react_1.useState)(rest.clientSecret), clientSecret = _b[0], setClientSecret = _b[1];
    return (react_1.default.createElement(exports.StripeContext.Provider, { value: {
            changeClientSecret: setClientSecret,
        } },
        react_1.default.createElement(react_stripe_js_1.Elements, { options: __assign(__assign({ appearance: theme_1.theme, fonts: [{ cssSrc: "https://use.typekit.net/dda8hgu.css" }] }, options), { clientSecret: clientSecret }), key: clientSecret, stripe: (0, stripe_js_1.loadStripe)(publicKey) },
            react_1.default.createElement(exports.StripeElement, __assign({}, rest)))));
};
exports.Stripe = Stripe;
