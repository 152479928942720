import React from "react";

import { useAuthContext } from "../../../../hooks";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserName: React.FC = ({ row }: any): JSX.Element => {
  const { original } = row;
  const { firstName, lastName, id } = original;

  const { getCurrentUserId } = useAuthContext();
  const userId = getCurrentUserId();

  return (
    <>
      <span>{firstName}</span> <span>{lastName}</span>
      {id === userId && <span className="font-semibold"> (You)</span>}
    </>
  );
};
