/**
 * Removes undefined (or null) values from an object by deleting them.
 * @param object Object to trim.
 * @param includeNulls Whether this should remove null property values also.
 * @returns
 */
export function trimObject<T extends Record<string, any>>(
  object: T,
  includeNulls = true,
): T | Partial<T> {
  const clone: Partial<T> = {};
  Object.entries(object).forEach(([key, value]) => {
    if (!(value === undefined || (includeNulls && value === null))) {
      clone[key as keyof typeof clone] = value;
    }
  });

  return clone;
}
