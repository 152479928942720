import { EditableItem } from "components/catalogues/components";
import { CatalogueItem } from "models/catalogueItem";

export const patchItemInList = (
  itemToUpdateId: EditableItem["id"],
  patch: Partial<CatalogueItem>,
  items: EditableItem[],
): EditableItem[] => {
  const found = items.find(({ id }) => id === itemToUpdateId);
  // check items have children
  const itemsHaveChildren = items.some(({ children }) => !!children?.length);

  if (found) {
    Object.assign(found, patch);
  } else if (itemsHaveChildren) {
    for (const { children } of items) {
      patchItemInList(itemToUpdateId, patch, children || []);
    }
  }

  return items;
};
