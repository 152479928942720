import React, { useState } from "react";
import { useFormikContext } from "formik";
import { QuoteFormValues } from ".";
import { Field, Input } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import {
  fromTimeslots,
  toTimeslots as initialToTimeslots,
} from "../../validation";
import dayjs from "dayjs";
import { SelectOption } from "../dropdownLookup";

export interface DateTimeslotSelectionProps {
  disabled?: boolean;
  initialValues: Pick<QuoteFormValues, "date" | "timeslot">;
}

const formatTimeslotToOption = (timeslots: string[]): SelectOption[] => {
  return timeslots.map((timeslot) => ({
    label: timeslot.replace(":00", ""),
    value: timeslot,
  }));
};

export const DateTimeslotSelection = ({
  disabled,
  initialValues,
}: DateTimeslotSelectionProps): React.ReactElement => {
  const [translate] = useTranslation("quotes");
  const { values } = useFormikContext<QuoteFormValues>();
  const [toTimeslots, setToTimeslots] = useState(
    formatTimeslotToOption(initialToTimeslots),
  );

  return (
    <div className="flex -mx-2 flex-wrap">
      <Field
        label={translate(`form.label.date`)}
        name="date"
        styles={{ width: "66.6666%", padding: `0 8px` }}
      >
        {({ field }) => (
          <Input.Date
            {...field}
            disabled={disabled}
            disabledDays={[{ daysOfWeek: [0] }, { before: dayjs().toDate() }]}
            initialValue={
              initialValues.date
                ? dayjs(initialValues.date).format("DD/MM/YYYY")
                : undefined
            }
            placeholder={translate(`form.placeholder.date`)}
            collapsable
          />
        )}
      </Field>
      <Field
        name="noTimeConstraint"
        type="checkbox"
        styles={{
          width: "33.3333%",
          padding: `0 8px`,
          marginTop: 40,
        }}
      >
        {({ field }) => (
          <Input.Checkbox
            {...field}
            disabled={disabled}
            label={translate("form.label.noTimeConstraint")}
          />
        )}
      </Field>
      {!values.noTimeConstraint && (
        <>
          <Field
            label={translate(`form.label.timeslot.from`)}
            name="timeslot.from"
            styles={{
              width: "50%",
              padding: `0 8px`,
              marginTop: 0,
            }}
          >
            {({ field }) => (
              <Input.Select
                {...field}
                disabled={disabled}
                options={fromTimeslots.map((timeslot) => ({
                  label: timeslot.replace(":00", ""),
                  value: timeslot,
                }))}
                defaultValue={initialValues.timeslot?.from || ""}
                placeholder={translate(`form.placeholder.timeslot`)}
                onChange={(event) => {
                  field.onChange(event);
                  setToTimeslots(
                    formatTimeslotToOption(
                      initialToTimeslots.filter((timeslot) => {
                        const time = parseInt(timeslot.substr(0, 2));
                        const from = parseInt(event.target.value.substr(0, 2));
                        return time > from;
                      }),
                    ),
                  );
                }}
              />
            )}
          </Field>
          <Field
            label={translate(`form.label.timeslot.to`)}
            name="timeslot.to"
            styles={{
              width: "50%",
              padding: `0 8px`,
              marginTop: 0,
            }}
          >
            {({ field }) => (
              <Input.Select
                {...field}
                disabled={disabled || !values.timeslot?.from}
                options={toTimeslots}
                defaultValue={initialValues.timeslot?.to || ""}
                placeholder={translate(`form.placeholder.timeslot`)}
              />
            )}
          </Field>
        </>
      )}
    </div>
  );
};
