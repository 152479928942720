import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DayPickerProps } from "react-day-picker";

/**
 * Import components.
 */
import {
  FormButton,
  DatePickerInput,
  AddressLookup,
  Transition,
  LoadingSpinner,
} from "../../../core";
import { PortalServiceSelection } from "../../components";

/**
 * Import form validation schema and initial values.
 */
import {
  vtSelectServiceSchema,
  vtSelectServiceInitialValues,
} from "../validation";

/**
 * Import hooks.
 */
import {
  useCatalogueContext,
  useMultiFormContext,
  useBasketContext,
} from "../../../../hooks";

/**
 * Import helpers.
 */
import { getDisabledDays, getCollectionDateConfig } from "../../../../helpers";

/**
 * Import types.
 */
import { IVTFormState } from "../types";

/**
 * Dates.
 */
const today = dayjs().toDate();
const thisMonth = dayjs(today).startOf("month").toDate();
const nextMonth = dayjs(today).startOf("month").add(3, "M").toDate();

export const SelectService: React.FC = () => {
  const [t] = useTranslation("jobs");

  /**
   * state.
   */
  const [disabledDays, setDisabledDays] =
    useState<DayPickerProps["disabledDays"]>();

  /**
   * Basket context.
   */
  const { clearItems } = useBasketContext();

  /**
   * Multi form context.
   */
  const { formState, nextStep, pushState } =
    useMultiFormContext<IVTFormState>();
  const { service, address, collectionDate, addressChoices } = formState;

  /**
   * Get services items from catalogue.
   */
  const allowedServices = ["SKIPS", "SKIPBAGS", "SOFAREMOVAL", "MANVAN"];
  const {
    catalogueItems,
    isLoading,
    setPostcode: setCataloguePostcode,
  } = useCatalogueContext();
  const adhocCatalogue = catalogueItems?.filter((item) =>
    allowedServices.includes(item.sku),
  );

  /**
   * Handle service change.
   * Set updated disabled days based on the selected service
   */
  const handleServiceChange = (newService: string) => {
    if (!adhocCatalogue) {
      return;
    }

    const selectedService = adhocCatalogue.find(
      (item) => item.sku === newService,
    );
    if (!selectedService) {
      return;
    }

    const collectionDateConfig = getCollectionDateConfig(selectedService);
    const updatedDisabledDays = getDisabledDays(collectionDateConfig);
    setDisabledDays(updatedDisabledDays);

    /**
     * When service changes
     * - Clear basket
     * - Update form state to remove timeslots
     */
    if (newService !== service) {
      clearItems();
      pushState({
        ...formState,
        timeslot: "",
        timeslotPreference: "Any",
        timeslotCharge: 0,
      });
    }
  };

  return (
    <>
      {!isLoading && (
        <Formik
          initialValues={{
            ...vtSelectServiceInitialValues,
            address,
            collectionDate,
            addressChoices,
            addressLookupPostcode: address.postcode,
          }}
          validationSchema={vtSelectServiceSchema}
          onSubmit={(values) => {
            setCataloguePostcode(values.address.postcode);
            pushState(values);
            nextStep();
          }}
        >
          {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row flex-wrap justify-center mt-4 -mx-2">
                <PortalServiceSelection
                  services={adhocCatalogue || []}
                  defaultValue={service}
                  onServiceChange={(service) => handleServiceChange(service)}
                />
              </div>
              <Transition transition="fadeInUp" toggle={!!values.service}>
                <div className="rounded-xl bg-white mt-4 px-8 pt-6 pb-8 shadow-filter">
                  <div className="md:flex md:flex-row md:flex-wrap xl:flex-no-wrap mt-4 -mx-2 justify-center">
                    <div className="w-full px-2 mb-4 lg:mb-0 lg:w-1/5 xl:w-1/4 md:w-1/2">
                      <DatePickerInput
                        name="collectionDate"
                        placeholder={t(
                          "create.steps.selectService.form.placeholders.date",
                        )}
                        label={{
                          text: t(
                            "create.steps.selectService.form.labels.date",
                          ),
                        }}
                        floatingLabel={false}
                        disabledDays={disabledDays}
                        numberOfMonths={1}
                        fromMonth={thisMonth}
                        toMonth={nextMonth}
                        isRanged={false}
                        calendarPosition="above"
                      />
                    </div>
                    <div className="w-full px-2 md:flex md:flex-row md:flex-wrap lg:w-3/5 xl:w-3/5 xl:flex-no-wrap">
                      <AddressLookup
                        updateAddress={(value: any) => {
                          setFieldValue("address", value);
                        }}
                        postcodeField={{
                          containerClasses:
                            "w-full mb-4 md:mb-0 md:w-1/3 md:pr-4",
                        }}
                        addressDropdown={{
                          containerClasses: "w-full md:w-2/3",
                          fieldClasses: "larger-dropdown drop-right",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Transition>
              <div className="mt-10 flex justify-around">
                <FormButton
                  text="Next"
                  variant="secondary"
                  type="submit"
                  name="submit"
                  disabled={!dirty || !isValid}
                />
              </div>
            </form>
          )}
        </Formik>
      )}
      {/* Spinner */}
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <LoadingSpinner
            classes={{
              circle: "text-primary opacity-100",
              bee: "text-primary",
              text: "text-primary opacity-100",
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
