import * as Yup from "yup";
import {
  stringRequired,
  fieldRequired,
  optionalStringCharacterLimit,
} from "../../validation/common";
import { translate } from "@clearabee/i18n";

export const cascadeValidation = Yup.object().shape({
  catalogueIds: Yup.array<string>().required(),
});

export const createUpdateCatalogueValidation = (includeId: boolean) =>
  Yup.object().shape({
    ...(includeId ? { id: stringRequired } : {}),
    title: stringRequired,
    description: Yup.string(),
    public: Yup.boolean().required(),
    hasVat: Yup.boolean().required(),
    vatRate: Yup.number().min(0).max(100),
    active: Yup.boolean().required(),
    applySkipCharge: Yup.boolean(),
  });

export const getValidation = (includeSku = false) =>
  Yup.object().shape({
    active: Yup.boolean(),
    title: stringRequired,
    description: optionalStringCharacterLimit,
    type: stringRequired,
    price: Yup.number().when(["type", "pricingModel"], {
      is: (type, pricingModel) =>
        type === "product" && pricingModel === "override",
      then: Yup.number().required(fieldRequired),
    }),
    pricingModel: Yup.string().when("type", {
      is: "product",
      then: stringRequired,
    }),
    blackoutDays: Yup.array(),
    blackoutDates: Yup.array(),
    sku: includeSku
      ? Yup.string()
          .matches(/^[A-Z]+$/, "Must be all capitals and letters only")
          .required(fieldRequired)
      : Yup.string(),
    cutoffTime: Yup.string(),
    leadTime: Yup.number(),
    baseTime: Yup.number().moreThan(-1, "Must be zero or greater"),
    unitTime: Yup.number().moreThan(-1, "Must be zero or greater"),
    multiplier: Yup.number().moreThan(-1, "Must be zero or greater"),
    supplierMargin: Yup.number(),
    orderTypeId: Yup.number().required(fieldRequired),
    requiresOrderRef: Yup.boolean(),
    noCalloutCharge: Yup.boolean(),
    img: Yup.string(),
    meta: Yup.object({
      friendlyTitle: Yup.string(),
      yards: Yup.string(),
      annotation: Yup.string(),
      serviceType: Yup.string(),
      volume: Yup.number(),
      fromPrice: Yup.number(),
      size: Yup.string(),
      itemCount: Yup.number(),
      weight: Yup.number(),
      priority: Yup.number(),
      popular: Yup.boolean(),
    }),
    deliveryOptions: Yup.array().of(
      Yup.object().shape({
        title: stringRequired.label(
          translate("portal.catalogues.form.labels.title"),
        ),
        charge: Yup.number()
          .typeError("Must be a number")
          .label(translate("portal.catalogues.form.labels.charge")),
        range: Yup.array().of(
          Yup.object().shape({
            from: stringRequired.label(
              translate("portal.catalogues.form.labels.from"),
            ),
            to: stringRequired.label(
              translate("portal.catalogues.form.labels.to"),
            ),
          }),
        ),
      }),
    ),
    zones: Yup.array().of(
      Yup.object().shape({
        zone: Yup.string(),
        blackoutDays: Yup.array().of(Yup.number()).nullable(),
        blackoutDates: Yup.array().of(Yup.string()).nullable(),
        postcodes: Yup.array().of(
          Yup.object()
            .shape({
              zoneId: Yup.number(),
              postcode: Yup.string(),
            })
            .nullable(),
        ),
      }),
    ),
  });
