import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const initialValues = {
  id: "",
  value: "",
};

export const validationSchema = Yup.object().shape({
  id: Yup.string(),
  value: stringRequired.trim(),
});
