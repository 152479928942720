import React from "react";
import { css } from "@emotion/react";
import {
  icons,
  IconComponentType,
  MapType,
  BaseIconProps,
  getColorFromVariantKey,
} from "@clearabee/ui-library-base";
import { UserStylesProps } from "../../utils";
import { theme } from "../../theme";

export type IconProps = BaseIconProps &
  UserStylesProps<React.SVGProps<SVGSVGElement>>;

const IconComponent = ({
  color,
  size = "xlarge",
  styles: userStyles,
  ...rest
}: IconProps): React.ReactElement => {
  const iconColor = !!color ? getColorFromVariantKey(color) : undefined;

  return (
    <svg
      role="img"
      aria-label="Supporting Icon"
      width={theme.spacing[size]}
      height={theme.spacing[size]}
      color={iconColor}
      css={css(userStyles)}
      {...rest}
    />
  );
};

(Object.entries(icons) as MapType[]).forEach(([iconName, icon]) => {
  (IconComponent as IconComponentType<IconProps>)[iconName] = ({
    ...rest
  }: IconProps): React.ReactElement => <Icon {...icon} {...rest} />;
});

export const Icon = IconComponent as IconComponentType<IconProps>;
