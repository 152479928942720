import * as Yup from "yup";
import { stringRequired } from "../../../validation/common";
import { translate } from "@clearabee/i18n";

export const supplierTypeValidation = Yup.object().shape({
  type: stringRequired.label(
    translate("portal.suppliers.supplierTypes.form.labels.name"),
  ),
  columns: Yup.array().of(
    Yup.object().shape({
      name: stringRequired.label(
        translate("portal.suppliers.supplierTypes.form.labels.name"),
      ),
      type: stringRequired.label(
        translate("portal.suppliers.supplierTypes.form.labels.type"),
      ),
      title: stringRequired.label(
        translate("portal.suppliers.supplierTypes.form.labels.title"),
      ),
      required: Yup.boolean()
        .required()
        .label(
          translate("portal.suppliers.supplierTypes.form.labels.required"),
        ),
    }),
  ),
});

export const createSupplierTypeInitial = {
  type: "",
  columns: [
    {
      name: "",
      type: "",
      title: "",
      required: false,
    },
  ],
};
