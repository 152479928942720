import { StyleSheet } from "../../utils";
import { theme } from "../../theme";

export const styles = StyleSheet({
  default: {
    width: "100%",
    padding: `0px ${theme.spacing.small}`,
    margin: "0 auto",
  },
});
