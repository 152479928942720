import React, { useEffect } from "react";
import {
  useFormikContext,
  FormikProps,
  FormikFormProps,
  FormikValues,
} from "formik";
import cx from "classnames";
import { Tick } from "../../../images";

/**
 * Import components
 */
import { FieldError } from "..";

/**
 * Import styles.
 */
import "./styles/textarea.css";

/**
 * Type props for textarea field.
 */
type TConditionalProps =
  | {
      label: ITextareaLabel;
    }
  | {
      label?: ITextareaLabel;
    };

type TDefaultProps = {
  name: string;
  rows?: number;
  cols?: number;
  classes?: string;
  wrapClass?: string;
  placeholder?: string;
  error?: IErrorMessage;
  required?: boolean;
  floatingLabel?: boolean;
  validate?: boolean;
  autocomplete?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  maxLength?: number;
};

type TTextarea = TConditionalProps & TDefaultProps;

/**
 * Type props for textarea label.
 */
interface ITextareaLabel {
  text: string;
  classes?: string;
}

/**
 * Type props for error messages.
 */
interface IErrorMessage {
  classes?: string;
}

/**
 * Ref type.
 */
type TWrapperRef = HTMLDivElement;

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const FormTextarea = React.forwardRef<TWrapperRef, TTextarea>(
  (
    {
      name,
      rows = 4,
      cols = 50,
      placeholder,
      classes,
      wrapClass,
      value,
      label = {
        text: "",
        classes: "",
      },
      error = {
        classes: "",
      },
      required = false,
      floatingLabel = true,
      validate = true,
      autocomplete = true,
      onChange,
      onFocus,
      onKeyUp,
      onClick,
      onBlur,
      disabled = false,
      readOnly = false,
      defaultValue,
      maxLength,
    },
    ref,
  ) => {
    /**
     * Get Formik context.
     */
    const {
      handleBlur,
      handleChange,
      setFieldValue,
    }: FormikProps<FormikFormProps> = useFormikContext();

    const { values, errors, touched }: FormikProps<FormikValues> =
      useFormikContext();

    useEffect(() => {
      if (defaultValue) {
        setFieldValue(name, defaultValue);
      }
      // eslint-disable-next-line
    }, []);

    /**
     * Custom prop handlers.
     */
    const customHandlers = {
      onChange,
      onFocus,
      onKeyUp,
      onClick,
      onBlur,
    };

    /**
     * An object is created with all of the set values.
     */
    const field = {
      name,
      rows,
      cols,
      id: `field-${name}`,
      placeholder,
      classes,
      error: false,
      label: {
        text: label.text,
        classes: cx("form-textarea-label", label.classes),
      },
    };

    /**
     * Textarea state variables.
     */
    const isValid = !errors[field.name] && touched[field.name];
    const hasError = errors[field.name] && touched[field.name];
    const hasValue = values[field.name] !== "";

    /**
     * Textarea field has a value.
     */
    field.classes = cx("form-textarea", field.classes, {
      "has-value": hasValue,
    });

    /**
     * Set label positioning
     */
    field.label.classes = cx(field.label.classes, {
      "form-textarea-normal-label": !floatingLabel,
    });

    /**
     * Output the input field. If label is set, we will also output that too.
     */
    return (
      <div
        className={cx(
          "form-textarea-wrap",
          { valid: isValid, required, error: hasError, disabled },
          wrapClass,
        )}
        ref={ref}
      >
        <textarea
          name={field.name}
          id={field.id}
          rows={field.rows}
          cols={field.cols}
          placeholder={!floatingLabel ? field.placeholder : ""}
          className={field.classes}
          value={value || values[field.name]}
          autoComplete={!autocomplete ? "off" : undefined}
          onChange={(e) => {
            handleChange(e);
            if (customHandlers.onChange) {
              customHandlers.onChange(e);
            }
          }}
          onFocus={(e) => {
            if (customHandlers.onFocus) {
              customHandlers.onFocus(e);
            }
          }}
          onKeyUp={(e) => {
            if (customHandlers.onKeyUp) {
              customHandlers.onKeyUp(e);
            }
          }}
          onClick={(e) => {
            if (customHandlers.onClick) {
              customHandlers.onClick(e);
            }
          }}
          onBlur={(e) => {
            handleBlur(e);
            if (customHandlers.onBlur) {
              customHandlers.onBlur(e);
            }
          }}
          data-testid={field.name}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
        />
        {field.label.text ? (
          <label className={field.label.classes} htmlFor={field.id}>
            {field.label.text}
          </label>
        ) : null}

        {validate && (
          <div className="form-textarea-validate">
            {hasError ? (
              <FieldError
                message={errors[field.name]}
                classes={error.classes}
              />
            ) : (
              isValid && (
                <Tick
                  classes={{
                    svg: "form-textarea-tick",
                    circle: "form-textarea-tick-circle",
                    path: "form-textarea-tick-path",
                  }}
                />
              )
            )}
          </div>
        )}
      </div>
    );
  },
);
