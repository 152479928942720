import React from "react";
import { Modal, Button, Icon } from "@clearabee/ui-library";
import { RouteLink } from "components/core/routeLink";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { createQuote } from "api";
import { toasts } from "helpers";
import { useMutation } from "react-query";
import { QuoteForm } from "./components";
import { FormLoader } from "components/common/components";
import { getCreateQuoteSchema } from "./validation";
import { QuoteFormValues } from "./components/quoteForm";
import { BackButton } from "./components/backButton";
import clipboard from "clipboard-copy";
import { ICompany } from "api/types";

/**
 * Create Quote Component.
 */
export const CreateQuote = (): React.ReactElement => {
  const [translate] = useTranslation("quotes");

  const handleClipboard = async (
    {
      timeslot,
      contactNoEmail,
      companyCode,
      noTimeConstraint,
      ...values
    }: QuoteFormValues,
    companies: ICompany[],
  ): Promise<void> => {
    let clipboardValue = Object.entries(values)
      .map(
        ([field, value]) =>
          `${translate(`form.label.${field}`)}: ${value || ""}`,
      )
      .join("\n");
    if (companyCode) {
      clipboardValue += `\n${translate("form.label.companyCode")}: ${
        companies.find(({ companyCode: code }) => code === companyCode)?.name ||
        companyCode
      }`;
    }
    if (!noTimeConstraint && timeslot) {
      clipboardValue += `\n${translate(`form.label.timeslot.from`)}: ${
        timeslot.from
      } - ${translate(`form.label.timeslot.to`)}: ${timeslot.to}\n`;
    }
    if (contactNoEmail) {
      clipboardValue += `\n${translate(`form.label.timeslot`)}: ${translate(
        "common:yes",
      )}`;
    }
    try {
      await clipboard(clipboardValue);
      toasts.success({ message: translate("success.clipboard") });
    } catch {
      toasts.error({ message: translate("errors.clipboard") });
    }
  };

  const { mutate, isSuccess, data, reset, isLoading } = useMutation(
    async ({ noTimeConstraint, ...values }: QuoteFormValues) => {
      return createQuote({
        ...values,
        ...(values.contactPhoneNumber && {
          contactPhoneNumber: values.contactPhoneNumber.replace(/\s/g, ""),
        }),
        ...(values.contactEmail && {
          contactEmail: values.contactEmail.replace(/\s/g, ""),
        }),
        timeslot:
          !noTimeConstraint && values.timeslot ? values.timeslot : undefined,
        date: dayjs(values.date, "DD/MM/YYYY").toISOString(),
        contactEmail: values.contactNoEmail ? undefined : values.contactEmail,
      });
    },
    {
      onError: () => {
        toasts.error({ message: translate("errors.createFailed") });
      },
    },
  );

  return (
    <>
      <div className="max-w-screen-lg pt-10 mx-auto">
        <BackButton />
        <QuoteForm
          validationSchema={getCreateQuoteSchema(true)}
          onSubmit={(values) => {
            mutate(values);
          }}
          isSuccess={isSuccess}
        >
          {({ resetForm, isSubmitting, values, companies }) => (
            <>
              {(isLoading || isSubmitting) && (
                <div className="fixed top-0 right-0 left-0 bottom-0">
                  <div className="bg-white opacity-50 absolute top-0 right-0 left-0 bottom-0"></div>
                  <FormLoader />
                </div>
              )}
              <div className="flex mt-10 justify-center">
                <Button
                  type="submit"
                  color="accent"
                  disabled={isLoading || isSubmitting}
                >
                  {translate("form.buttons.submitQuote")}
                </Button>
                <Button
                  type="button"
                  color="accent"
                  className="ml-3"
                  variant="outline"
                  onClick={() => handleClipboard(values, companies)}
                  styles={{
                    minWidth: 0,
                  }}
                >
                  <Icon.Clipboard className="h-5 w-5" />
                </Button>
              </div>
              {isSuccess && (
                <Modal
                  heading={
                    <>
                      {translate("modals.headings.createSuccess")}
                      <br />
                      {translate("modals.headings.reference", {
                        reference: data?.quoteRef,
                      })}
                    </>
                  }
                  width={500}
                  actions={
                    <div className="flex flex-col px-20 pt-4">
                      <RouteLink href="/">
                        <Button
                          size="medium"
                          color="negative"
                          type="button"
                          className="mb-3"
                        >
                          {translate("modals.buttons.backToDashboard")}
                        </Button>
                      </RouteLink>
                      <Button
                        size="medium"
                        color="accent"
                        className="mb-3"
                        onClick={() => {
                          reset();
                          resetForm();
                        }}
                      >
                        {translate("modals.buttons.requestAnother")}
                      </Button>
                      <RouteLink
                        href={`/quotes/update/${data?.id?.toString() || ""}`}
                      >
                        <Button size="medium" color="brand" type="button">
                          {translate("modals.buttons.viewQuote")}
                        </Button>
                      </RouteLink>
                    </div>
                  }
                />
              )}
            </>
          )}
        </QuoteForm>
      </div>
    </>
  );
};
