import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../../../utils";
import { styles } from "./InputText.styles";

export interface InputTextProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  error?: string;
  success?: boolean;
  prefix?: string;
}

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (
    { error, success, styles: userStyles, prefix, ...rest }: InputTextProps,
    ref,
  ): React.ReactElement => {
    const inputElement = (
      <input
        {...rest}
        css={css(
          styles.input,
          prefix && styles.inputPrefix,
          success && styles.success,
          error && styles.error,
          userStyles,
        )}
        ref={ref}
      />
    );

    if (!prefix) {
      return inputElement;
    }

    return (
      <div css={styles.container}>
        {prefix && <div css={styles.prefix}>{prefix}</div>}
        {inputElement}
      </div>
    );
  },
);
