import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Panel, Icon, theme, Text, PanelProps } from "@clearabee/ui-library";
import { css } from "@emotion/react";
import { useDrag } from "react-dnd";
import { CellForm } from "./cellForm";
import { DropCellWrap } from "./dropCellWrap";
import { CatalogueEditorContext, EditableItem } from "..";

export type CellDropTypes =
  | "product-template"
  | "category-template"
  | "product-item"
  | "category-item";

const getMarkedText = (search: string, text: string): React.ReactElement => {
  const index = text.toLowerCase().indexOf(search.toLowerCase());

  if (index === -1) return <>{text}</>;

  return (
    <>
      {text.slice(0, index)}
      <mark>{text.slice(index, index + search.length)}</mark>
      {text.slice(index + search.length)}
    </>
  );
};

const descendantHasSearchTerm = (
  searchTerm: string,
  items: EditableItem[],
): boolean => {
  return items.some(
    ({ title, children }) =>
      title.toLowerCase().includes(searchTerm) ||
      (children && descendantHasSearchTerm(searchTerm, children)),
  );
};

interface ItemPanelProps {
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  type: string;
  sku: string;
  parentSku?: string;
  catalogueId?: string;
  title: React.ReactNode;
  children: React.ReactNode;
  styles?: PanelProps["styles"];
}

const ItemPanel = React.forwardRef<HTMLDivElement, ItemPanelProps>(
  (
    {
      isOpen,
      type,
      children,
      sku,
      parentSku,
      title,
      catalogueId,
      onOpen,
      styles: userStyles,
    },
    drag,
  ) => {
    const [translate] = useTranslation("catalogues");

    return (
      <Panel
        className="flex flex-wrap items-center cursor-move select-none mb-3"
        id={`${sku}-${parentSku || "no-parent"}`}
        color={!isOpen ? (type === "category" ? "accent" : "light") : "brand"}
        styles={[
          {
            width: "100%",
            padding: !isOpen
              ? `0 ${theme.spacing.large} 0 ${theme.spacing.small}`
              : `${theme.spacing.medium} ${theme.spacing.small} 0 ${theme.spacing.small}`,
            height: !isOpen ? theme.spacing.xlarge3 : "auto",
            [`@media (min-width: ${theme.screens.medium})`]: {
              padding: `0 0 0 ${theme.spacing.medium}`,
              height: "auto",
              ":hover": {
                background: theme.colors.brand.base,
                "> span, svg, button": !isOpen
                  ? {
                      color: theme.colors.light.base,
                    }
                  : {},
              },
            },
          },
          userStyles,
        ]}
        shadow={false}
        ref={isOpen ? undefined : drag}
      >
        <Text
          as="span"
          color={!isOpen ? "brand" : "light"}
          fontSize="xsmall"
          className="font-bold"
        >
          {title}
        </Text>
        <a
          href={`/catalogues/update/${catalogueId}/${sku}`}
          target="_blank"
          rel="noreferrer"
          className="absolute right-16 top-0 h-16 flex items-center md:relative md:h-auto ml-auto mr-0 md:right-0"
        >
          <Icon.NewWindow color="brand" size="small" />
        </a>
        <button
          type="button"
          className="underline flex items-center absolute right-0 top-0 h-16 md:relative md:h-auto"
          css={css({
            color: !isOpen ? theme.colors.brand.base : theme.colors.light.base,
            padding: `${theme.spacing.small} ${theme.spacing.medium}`,
          })}
          onClick={() => {
            onOpen(!isOpen);
            setTimeout(() => {
              document
                .getElementById(`${sku}-${parentSku || "no-parent"}`)
                ?.scrollIntoView({ behavior: "smooth" });
            }, 0);
          }}
        >
          <span className="hidden md:inline-block">
            {!isOpen
              ? translate("catalogueEditing.edit")
              : translate("catalogueEditing.close")}
          </span>
          <Icon.Chevron
            className="ml-4"
            width={16}
            height={16}
            color={!isOpen ? "brand" : "light"}
            styles={{
              transform: isOpen
                ? "translateY(10%) rotate(-90deg)"
                : "rotate(90deg)",
            }}
          />
        </button>
        {children}
      </Panel>
    );
  },
);

export const CatalogueItemCell = (
  currentItem: EditableItem & {
    searchTerm?: string;
    excludeChildren?: boolean;
  },
): React.ReactElement => {
  const [isOpen, setOpen] = useState(false);
  const { catalogue } = useContext(CatalogueEditorContext);

  const [, drag] = useDrag(() => ({
    type: `${currentItem.type}-item`,
    item: currentItem,
    canDrag: !isOpen,
  }));

  const { children, title, searchTerm, excludeChildren } = currentItem;
  const hidden =
    !!searchTerm && !title.toLowerCase().includes(searchTerm.toLowerCase());
  const childrenHasSearchTerm =
    !!searchTerm &&
    descendantHasSearchTerm(searchTerm.toLowerCase(), children ?? []);

  return (
    <DropCellWrap item={currentItem}>
      <ItemPanel
        sku={currentItem.sku}
        parentSku={currentItem.parentSku}
        type={currentItem.type}
        isOpen={isOpen}
        onOpen={setOpen}
        ref={drag}
        title={searchTerm ? getMarkedText(searchTerm, title) : title}
        catalogueId={catalogue?.id}
        styles={{
          display: hidden && !childrenHasSearchTerm ? "none" : undefined,
          opacity: !isOpen && childrenHasSearchTerm && hidden ? 0.5 : undefined,
        }}
      >
        {isOpen && <CellForm item={currentItem} setOpen={setOpen} />}
      </ItemPanel>
      {!!children?.length && (
        <div className="flex flex-wrap pl-2 md:pl-6">
          {children.map((child) => (
            <CatalogueItemCell
              {...child}
              excludeChildren={excludeChildren}
              // Set to undefined (always show item) if current item is visible and excludeChildren is set to false
              searchTerm={!hidden && !excludeChildren ? undefined : searchTerm}
              key={child.id}
            />
          ))}
        </div>
      )}
    </DropCellWrap>
  );
};
