import React, { useEffect, useState, useRef } from "react";
import cx from "classnames";

/**
 * Interface.
 */
interface IQuantity {
  min?: number;
  max?: number;
  start?: number;
  showIcons?: boolean;
  fieldDisabled?: boolean;
  value?: number;
  classes?: {
    wrap?: string;
    input?: string;
    icons?: string;
    iconsSize?: string;
    buttons?: string;
    buttonPlus?: string;
    buttonMinus?: string;
    buttonsSize?: string;
  };
  updateQuantity: (quantity: number) => void;
}

/**
 * Custom quantity component.
 */
export const Quantity: React.FC<IQuantity> = ({
  min = 0,
  max = 99,
  start = 0,
  showIcons = true,
  fieldDisabled = true,
  value = undefined,
  classes = {},
  updateQuantity,
}) => {
  const isMounted = useRef(false);
  const quantityReference = useRef(value || start);
  const [quantity, setQuantity] = useState(value || start);
  const increment = quantity + 1;
  const decrement = quantity - 1;

  /**
   * Set is mounted to true after 200 ms.
   */
  useEffect(() => {
    setTimeout(() => {
      isMounted.current = true;
    }, 200);
  }, []);

  /**
   * Ensure values are correct.
   */
  useEffect(() => {
    if (isMounted.current) {
      if (quantity !== quantityReference.current) {
        if (typeof quantity === "string") {
          /**
           * The type will be a string if the user has typed into the field.
           * - The field can be typed in if it's not disabled
           */
          if (quantity !== "") {
            const newQuantity = parseInt(quantity, 10);
            setQuantity(newQuantity);
            quantityReference.current = newQuantity;
          }
        } else if (quantity < min) {
          /**
           * If the quantity is not set or is below the minimum, set to minimum.
           */
          setQuantity(min);
          quantityReference.current = min;
        } else if (quantity > max) {
          /**
           * If the quantity is above the maximum, set to the maximum.
           */
          setQuantity(max);
          quantityReference.current = max;
        } else {
          /**
           * Push to the upper component if none of the other conditions match.
           */
          updateQuantity(quantity);
          quantityReference.current = quantity;
        }
      }
    }
  }, [quantity, min, max, updateQuantity]);

  /**
   * If we pass a value, set that to the default quantity value.
   */
  useEffect(() => {
    /**
     * If the value prop changes, update the quantity.
     */
    if (typeof value !== "undefined" && value !== quantityReference.current) {
      setQuantity(value);
      quantityReference.current = value;
    } else if (typeof value === "undefined" && isMounted.current) {
      setQuantity(0);
      quantityReference.current = 0;
    }
  }, [value, setQuantity]);

  return (
    <div
      className={cx("flex flex-row items-center w-full relative", classes.wrap)}
    >
      {showIcons && (
        <button
          type="button"
          onClick={() => setQuantity(decrement)}
          className={cx(
            "quantity-decrement rounded-full cursor-pointer bg-gray-300 outline-none mr-1",
            { "w-6 h-6": !classes.buttonsSize },
            classes.buttons,
            classes.buttonMinus,
            classes.buttonsSize,
          )}
        >
          <span
            className={cx(
              "m-auto leading-tight",
              { "text-xl": !classes.iconsSize },
              classes.icons,
              classes.iconsSize,
            )}
          >
            −
          </span>
        </button>
      )}
      {!showIcons || !fieldDisabled ? (
        <input
          type="number"
          className={cx(
            "quantity-value outline-none w-12 h-6 text-center p-1 rounded-xl",
            classes.input,
          )}
          value={quantity}
          onChange={(e: any) => setQuantity(e.target.value)}
        />
      ) : (
        <div
          className={cx("quantity-value min-w-5 text-center", classes.input)}
        >
          {quantity}
        </div>
      )}
      {showIcons && (
        <button
          type="button"
          onClick={() => setQuantity(increment)}
          className={cx(
            "quantity-increment rounded-full cursor-pointer bg-primary outline-none ml-1",
            { "w-6 h-6": !classes.buttonsSize },
            classes.buttons,
            classes.buttonPlus,
            classes.buttonsSize,
          )}
        >
          <span
            className={cx(
              "m-auto text-white leading-tight",
              { "text-xl": !classes.iconsSize },
              classes.icons,
              classes.iconsSize,
            )}
          >
            +
          </span>
        </button>
      )}
    </div>
  );
};
