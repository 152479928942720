import type {
  IQuestionType,
  IQuestionMeta,
  TWorksheetQuestionPostBody,
  IWorksheet,
} from "@clearabee/api-schemas";
import type { OptionProps } from "@clearabee/ui-library";
import type {
  PickRequired,
  QuestionType,
  RequireID,
  TQuestion,
} from "components/worksheets/worksheetTypes";
import type { InitialValues } from "./questionModalInitialValues";
import type { AllowedMeta, QuestionPatchBody } from "./questionModalTypes";

export function convertCamelCaseToSentenceCase(str: string): string {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}

/**
 * Given a question, return possible parent questions,
 * in the form of an array of options for use with the Select component.
 *
 * - A modal-question may not be a child of any other question. (It must be a root-level, level 0, question.)
 * - A parent-question may only be of the type 'boolean' or 'list'.
 * - A parent-question may not be a descendant of the question.
 *
 * @param questions Array of questions, flat or tree, from which to find possible parent questions
 * @param questionTypes Array of question-types received from the API
 * @param question Question being edited or created
 * @returns Array of list options, ordered alphabetically by question
 */
export const getAvailableParentOptions = (
  values: InitialValues,
  questions: TQuestion[],
  questionTypes: IQuestionType[],
  question?: RequireID<TQuestion>,
): OptionProps[] => {
  const modalQuestionIds = getQuestionTypeIdsByName("modal", questionTypes);

  // Modal questions may not be children of any other question: they are root-level questions only
  if (values.typeId && modalQuestionIds.includes(values.typeId as number)) {
    return [];
  }

  const stack = [...questions];
  const availbleParentQuestions: TQuestion[] = [];

  while (stack.length) {
    const node = stack.shift() as TQuestion;

    if (node.id === question?.id) {
      // We do not want to go down the tree any further in this branch, or we will get descendants.
      // (A descendant cannot be a parent of its ancestor.)
      continue;
    }

    availbleParentQuestions.push(node);

    if (node.children?.length) {
      stack.push(...node.children);
    }
  }

  return availbleParentQuestions
    .filter(({ type: { name: typeName } }) => {
      // A parent-question may only be of type 'boolean' or 'list' (NOT listMultiSelect)
      return /^boolean|list$/i.test(typeName);
    })
    .map(({ id, question }) => ({
      value: id as number,
      label: question,
    }))
    .sort(({ label: labelA }, { label: labelB }) =>
      labelA.localeCompare(labelB),
    );
};

/**
 * Test whether meta contains defaultSelected: "none".
 *
 * (With boolean questions, if meta contains defaultSelected: "none",
 * then in the Drivers App, it is displayed as a list with 'Yes/No' options. This is set by a toggle in the UI inside the Portal;
 * this function is used to set the toggle's initial state.)
 */
export const isDefaultSelectedInMeta = (meta: IQuestionMeta): boolean => {
  return meta.defaultSelected === "none";
};

/**
 * Adds properties to meta
 */
export const addMeta = (
  metaToAdd: IQuestionMeta,
  meta: IQuestionMeta,
): IQuestionMeta => {
  return { ...meta, ...metaToAdd };
};

/**
 * Remove properties from meta
 */
export const removeMeta = (
  metaToDelete: IQuestionMeta,
  meta: IQuestionMeta,
): IQuestionMeta => {
  for (const prop in meta) {
    if (prop in metaToDelete) {
      delete meta[prop];
    }
  }

  return meta;
};

/**
 * Find whether there is a question with a meta property of toggleAll: true.
 * @param questions array of questions, as a flat or tree structure.
 */
export const hasBooleanQuestionWithToggleAll = (questions: TQuestion[]) => {
  const stack = [...questions];
  let result = false;

  while (stack.length) {
    const node = stack.shift() as TQuestion;

    if (
      node.type.name === "boolean" &&
      node.meta &&
      node.meta["toggleAll"] === true
    ) {
      result = true;
      break;
    }

    if (node.children?.length) {
      stack.push(...node.children);
    }
  }

  return result;
};

/**
 * Given an array of questions, return any questions that have a meta property of toggleAll: true.
 *
 * (If a question within a worksheet has toggleAll: true in its meta, then the Drivers App
 * will display a toggle-all option on that worksheet. If we want to turn this feature off on a worksheet,
 * then we need to ensure all questions on that worksheet do not have this meta property.)
 *
 * @param questions Array of questions, as a flat or tree structure
 * @returns Array of questions that have toggleAll: true in their meta
 */
export const getQuestionsWithToggleAllMeta = (
  questions: TQuestion[],
): Array<
  Omit<TQuestion, "meta"> & {
    meta: IQuestionMeta;
  }
> => {
  const stack = [...questions];
  const questionsWithToggleAllMeta: any = [];

  while (stack.length) {
    const node = stack.shift() as TQuestion;

    if (node.meta && node.meta["toggleAll"] === true) {
      questionsWithToggleAllMeta.push(node);
    }

    if (node.children?.length) {
      stack.push(...node.children);
    }
  }

  return questionsWithToggleAllMeta;
};

/**
 * Test for key-value pairs in meta for displaying a Counter question as a scrollable list?
 * (This tests for 'start', 'end', and 'increment' properties.)
 */
export const isCounterScrollableListInMeta = (meta: IQuestionMeta): boolean => {
  const scrollableListMetaKeys: Array<keyof AllowedMeta> = [
    "start",
    "end",
    "increment",
  ];

  return Object.keys(meta).some((key) =>
    (scrollableListMetaKeys as string[]).includes(key),
  );
};

/**
 * Using the QuestionModal's form values, return the meta object to be sent to the API.
 */
export const getMetaFromValues = (
  values: InitialValues,
  type: IQuestionType,
  questions: TQuestion[],
): AllowedMeta | null => {
  const typeName = type.name as QuestionType;

  if (typeName === "boolean") {
    const isOtherQuestionWithToggleAllMeta =
      hasBooleanQuestionWithToggleAll(questions);

    // When displaying as a list, with yes/no options, do not set any other meta properties
    if (values.booleanParentQuestion) {
      return { defaultSelected: "none" };
    }

    const meta: IQuestionMeta = {
      // Only include toggleAll if there is not another question with toggleAll meta
      ...(values.booleanQuestionToggleAll &&
        !isOtherQuestionWithToggleAllMeta && { toggleAll: true }),
      ...(values.booleanQuestionRequiredValueTrue && { requiredValue: true }),
    };

    return Object.keys(meta).length ? meta : null;
  }

  if (typeName === "counter" && values.counterAsList) {
    return {
      // Values are returned as strings from the Input, so converting to numbers, as we want them sent as JSON numbers
      start: parseInt(values.counterStart as unknown as string) ?? 0,
      end: parseInt(values.counterEnd as unknown as string) ?? 100,
      increment: parseInt(values.counterIncrement as unknown as string) ?? 1,
    };
  }

  if (typeName === "modal" && values.modalText) {
    return { text: values.modalText };
  }

  return null;
};

/**
 * Given a partial type-name, such as 'list', and an array of question-types, return an array of ids for that question-type.
 * (These ids can then be used to conditionally render the correct question-type components.)
 *
 * @param typeName This is a type-name, or part of one, such as 'list', 'boolean', 'counter', etc.
 * @param questionTypes Array of question-types received from API
 * @param partial Whether to match the whole word exactly, or to match a partial string. The default is a partial match, so, for example, 'list' will match 'list' and 'listMultiSelect'.
 */
export const getQuestionTypeIdsByName = (
  typeName: string,
  questionTypes: IQuestionType[],
  partial = true,
): Array<Required<IQuestionType>["id"]> => {
  const regexObj = new RegExp(partial ? typeName : `^${typeName}$`, "i");

  return questionTypes
    .filter(({ name }) => regexObj.test(name))
    .map(({ id }) => id as number);
};

/**
 * Given a question-id and an array of questions, find whether the question has children.
 * (Are there other questions for which the question's parentId is the same as its id.)
 *
 * @param id id of the question to find whether it has children
 * @param questions array of questions, as a tree or flat structure
 */
export const hasChildren = (
  id: Required<TQuestion>["id"],
  questions: TQuestion[],
): boolean => {
  const stack = [...questions];
  let result = false;

  while (stack.length) {
    const node = stack.shift() as TQuestion;

    if (node.parentId === id) {
      result = true;
      break;
    }

    if (node.children?.length) {
      stack.push(...node.children);
    }
  }

  return result;
};

/**
 * Create a patch body for a question from the edit-question modal's form values.
 *
 * @param question The question being edited
 * @param questions Flat array of all questions for the worksheet (not a tree)
 * @param questionTypes Array of question-types received from API
 * @param values Form values submitted from the edit-question modal
 */
export const getPatchBodyFromValues = (
  question: PickRequired<TQuestion, "id">,
  questions: TQuestion[],
  questionTypes: PickRequired<IQuestionType, "id">[],
  values: InitialValues,
): QuestionPatchBody => {
  const type = questionTypes.find(
    ({ id }) => id === values.typeId,
  ) as IQuestionType; // As we only allow list-options from the questionTypes array, we know it will be found and will not be undefined, so can cast

  const listQuestionTypeIds = getQuestionTypeIdsByName("list", questionTypes);

  const meta = getMetaFromValues(values, type, questions);

  return {
    id: question.id,
    question: values.question,
    questionTypeId: values.typeId as number, // Select returns a number
    type,

    // This is a required prop. If no bcId, send an empty array.
    questionIds: [
      ...(!!values.bcQuestionId
        ? [
            {
              bcQuestionId: Number(values.bcQuestionId), // Input returns a string, so needs parsing
            },
          ]
        : []),
    ],

    // n.b. While the props below are not required, to remove any existing value in the DB and set the field to null, we need to send the properties' values as null. (Or the old value will remain in the DB.)

    position: values.position ? Number(values.position) : null, // Input returns string, so needs parsing as number

    // In the Drivers App, display-conditions are compared using toLowerCase(), so it makes sense to store them in a case-insensitive way.
    // Unfortunately, there is an issue in the API that, when the string "true" or "false", all lowercase, is sent, it converts it to a boolean primitive;
    // due to conversion, if there is then schema validation and it is expecting a string, it throws a validation error.
    // For this reason, we are sending the displayCondition as uppercase.
    // @see https://clearabee.atlassian.net/browse/BEE-1297
    parentId: values.parentId ? Number(values.parentId) : null,

    displayCondition:
      values.parentId && values.displayCondition
        ? values.displayCondition?.toUpperCase()
        : (null as any), // cast can be removed when schema is updated in API (@see https://github.com/Clearabee/microservices-monorepo/pull/1502)

    statusId: values.statusId ? Number(values.statusId) : null,

    // If not a list, do not send questionTypeItems. (This may change once the API work is completed. @see https://clearabee.atlassian.net/browse/BEE-1301)
    ...(listQuestionTypeIds.includes(values.typeId as number) && {
      questionTypeItems: values.typeValues?.map((id) => ({
        itemTypeId: id as number,
      })),
    }),

    meta,
  };
};

export const getPostBodyFromValues = (
  questions: TQuestion[],
  questionTypes: PickRequired<IQuestionType, "id">[],
  values: InitialValues,
  worksheetId: Required<IWorksheet>["id"],
): TWorksheetQuestionPostBody => {
  const type = questionTypes.find(
    ({ id }) => id === values.typeId,
  ) as IQuestionType; // As we only allow list-options from the questionTypes array, we know it will be found and will not be undefined, so can cast

  const listQuestionTypeIds = getQuestionTypeIdsByName("list", questionTypes);

  const meta = getMetaFromValues(values, type, questions);

  return {
    worksheetId,
    question: values.question,
    questionTypeId: values.typeId as number,
    type,

    // Required prop
    // bcId is the first entry in the questionIds array
    questionIds: [
      ...(!!values.bcQuestionId
        ? [{ bcQuestionId: Number(values.bcQuestionId) }]
        : []),
    ],

    ...(!!values.position && { position: Number(values.position) }), // Input returns string

    ...(!!values.parentId &&
      !!values.displayCondition && {
        parentId: values.parentId as number, // Select returns a number
        // In the Drivers App, display-conditions are compared using toLowerCase(), so it makes sense to store them in the DB the same way.
        // Unfortunately, there is an issue in the API that when a string "true" or "false", all lowercase, is sent, it converts to a boolean primitive;
        // if there is then schema validation and it is expecting a string, it throws a validation error. For this reason, we are sending the displayCondition as uppercase.
        // @see https://clearabee.atlassian.net/browse/BEE-1297
        // (This also makes reading the Child Questions section easier inside the edit-question modal.)
        displayCondition: values.displayCondition.toUpperCase(),
      }),

    ...(!!values.statusId && { statusId: values.statusId }),

    ...(listQuestionTypeIds.includes(values.typeId as number) && {
      questionTypeItems: values.typeValues?.map((id) => ({
        itemTypeId: id as number,
      })),
    }),

    ...(!!meta && { meta }),
  };
};

/**
 * Given a question-id and an array of questions, return the question with that id or null if not found.
 */
export const getQuestionById = (
  id: Required<TQuestion>["id"],
  questions: TQuestion[],
): TQuestion | null => {
  let parentQuestion: TQuestion | undefined;
  const stack = [...questions];

  while (stack.length) {
    const node = stack.shift() as TQuestion;

    if (node.id === id) {
      parentQuestion = node;
      break;
    }

    if (node.children?.length) {
      stack.push(...node.children);
    }
  }

  return parentQuestion ? parentQuestion : null;
};

/**
 * Given a parentId and an array of questions, return options for use with the Select component.
 *
 * @param parentId The id of question for which we want to get display-condition options
 * @param questions Questions as a tree or flat structure
 */
export const getDisplayConditionOptions = (
  parentId: Required<TQuestion>["id"],
  questions: TQuestion[],
): Array<OptionProps & { value: string }> => {
  const parentQuestion = getQuestionById(parentId, questions);

  if (!parentQuestion) return [];

  const typeName = parentQuestion.type.name;

  // All boolean questions, list or toggle, return a "true" or "false" string value, so there is no need to check meta here
  if (typeName.toLocaleLowerCase() === "boolean") {
    return [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ];
  }

  if (
    typeName.toLowerCase().includes("list") &&
    parentQuestion.typeValues?.length
  ) {
    return parentQuestion.typeValues.map(({ id, value: itemName }) => {
      // Display condition is stored as string in the db, so we use the item's name, and not the item-type id, for both label and value
      return { value: itemName, label: itemName };
    });
  }

  return [];
};

/**
 * Given a question and an array of questions, return all siblings of the question.
 * A sibling has the same parentId and displayCondition as the question.
 *
 * @param question Question for which to find its siblings
 * @param questions Array of questions, as a flat or tree structure
 */
export const getSiblings = (question: TQuestion, questions: TQuestion[]) => {
  const siblings: TQuestion[] = [];

  const stack = [...questions];

  // If question doesn't have a parentId, find all other questions without a parentId

  while (stack.length) {
    const node = stack.shift() as TQuestion;

    // Top-level questions, those without a parentId, are all siblings
    if (!question.parentId && !node.parentId && node.id !== question.id) {
      siblings.push(node);
    }

    if (
      node.id !== question.id && // A question cannot be its own sibling
      node.parentId && // Must check existence, as comparing undefined and undefined will give a false positive
      question.parentId &&
      node.parentId === question.parentId && // A sibling has the same parentId as the question
      node.displayCondition && // As above, re. false positives
      question.displayCondition &&
      node.displayCondition.toLowerCase() ===
        question.displayCondition.toLowerCase() // A sibling has the same displayCondition as the question
    ) {
      siblings.push(node);
    }

    if (node.children?.length) {
      stack.push(...node.children);
    }
  }

  return siblings;
};

/**
 * Given an array of questions for a single level with the same display-condition, fix the positioning of the questions, so they are sequential.
 * Positioning should be sequential within a single level of a branch of the question tree.\
 * For example, all 'TRUE' branch questions with the same parent-id should have sequential numbering for their position properties, starting from 1.
 *
 * @param questions An array of **sibilings**, which have both the same parent-id and display-condition
 */
export const fixPositioning = (questions: TQuestion[]): QuestionPatchBody[] => {
  const patchedQuestions: QuestionPatchBody[] = [];

  questions.forEach((question, index) => {
    const position = question.position;

    if (position !== index + 1) {
      // Position is wrong, so needs updating
      patchedQuestions.push({
        id: question.id as number,
        position: index + 1,
      });
    }
  });

  return patchedQuestions;
};

/**
 * This will fix positioning for **one level with one display-condition** only.
 *
 * @param question Question being created or edited
 * @param questions Array of questions for a single display-condition, e.g. "TRUE", and a single level
 * @param isDeleted Whether the question has been deleted
 */
export const getPatchBodiesForPosition = (
  question: TQuestion,
  questions: TQuestion[],
  isDeleted = false,
): QuestionPatchBody[] => {
  // Deep clone to avoid any pass-by-ref issues
  const deepClonedQuestions = JSON.parse(
    JSON.stringify(questions),
  ) as TQuestion[];

  const siblings = getSiblings(question, deepClonedQuestions);

  // If deleted, do NOT add to array

  // Insert the new or updated question into the array at the correct index according to its position.
  // (We know then that this question is definitely in the correct position, and, if there are others with the same position, or are moved in the array, then their positions need updating.)
  if (!isDeleted && question.position) {
    siblings.splice(question.position - 1, 0, question);
  }

  // If no position, add to the end of the array
  if (!isDeleted && !question.position) {
    siblings.push(question);
  }

  // Sort by position, putting those without a position to the end of the array
  // (The new or updated question is in the correct position, and will not be swapped with another question, even if it has the same position.)
  siblings.sort((a, b) => {
    // Move questions with type 'modal' to the top, as they are displayed first in the Drivers App
    // (Modal questions should only be top-level, level 0, questions.)
    if (a.type?.name === "modal" && b.type?.name !== "modal") {
      return -1;
    }

    const aPosition = a.position ?? Number.MAX_VALUE;
    const bPosition = b.position ?? Number.MAX_VALUE;

    return aPosition - bPosition;
  });

  // Fix ordering and return patch bodies
  const patchBodies = fixPositioning(siblings);

  return patchBodies;
};

/**
 * Given an array of questions, return an array of patch bodies for removing toggleAll:true from their meta properties.
 * @param questions Array of questions
 */
export const getToggleAllPatchBodies = (
  questions: RequireID<TQuestion>[],
): QuestionPatchBody[] => {
  const patchBodies = questions
    .filter(
      (
        question,
      ): question is Omit<RequireID<TQuestion>, "meta"> & {
        meta: IQuestionMeta;
      } => !!question.meta,
    )
    .map((question) => {
      const updatedMeta = removeMeta({ toggleAll: true }, question.meta);

      return {
        id: question.id,
        meta: Object.keys(updatedMeta).length ? updatedMeta : null, // If empty, set field to null in db
      };
    });

  return patchBodies;
};

/**
 * Given a question and an array of questions, remove the question from the array.
 * This returns a new array, and does **NOT** mutate the original array.
 *
 * @returns New array of questions, with the question removed
 */
export const removeQuestion = (
  question: TQuestion,
  questions: TQuestion[],
): TQuestion[] => {
  const deepClonedQuestions = JSON.parse(
    JSON.stringify(questions),
  ) as TQuestion[];

  deepClonedQuestions.forEach((node, index) => {
    if (node.id === question.id) {
      deepClonedQuestions.splice(index, 1);
    }

    if (node.children?.length) {
      removeQuestion(question, node.children);
    }
  });

  return deepClonedQuestions;
};
