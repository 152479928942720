import { PatchCatalogueItems } from "api/types";
import { compareObjects } from "helpers";
import { CatalogueItem } from "models";
import { EditableItem } from "./components";

export type FlatCatalogueItem = Omit<CatalogueItem, "children">;

export const flattenCatalogue = (
  items: CatalogueItem[] | EditableItem[],
  catalogueId: string,
  final: FlatCatalogueItem[] = [],
): FlatCatalogueItem[] => {
  final.push(
    ...items.map(({ children, ...item }) => {
      const { id, ...rest } = item as EditableItem;

      return {
        ...rest,
        catalogueId,
      };
    }),
  );
  for (const item of items) {
    if (item.children?.length)
      flattenCatalogue(item.children, catalogueId, final);
  }
  return final;
};

export const calculateNewItems = (
  original: FlatCatalogueItem[],
  newItems: FlatCatalogueItem[],
): CatalogueItem[] => {
  const newItemsPayload: CatalogueItem[] = [];

  for (const newItem of newItems) {
    if (!original.some(({ sku }) => newItem.sku === sku)) {
      newItemsPayload.push(newItem);
    }
  }
  return newItemsPayload;
};

export const calculatePatched = (
  original: FlatCatalogueItem[],
  newItems: FlatCatalogueItem[],
): PatchCatalogueItems => {
  const patchedItemsPayload: PatchCatalogueItems = [];

  for (const newItem of newItems) {
    const originalItem = original.find(({ sku }) => newItem.sku === sku);
    if (originalItem) {
      const patch = compareObjects(newItem, originalItem);
      if (Object.keys(patch).length > 0) {
        patchedItemsPayload.push({
          ...patch,
          sku: newItem.sku,
        });
      }
    }
  }
  return patchedItemsPayload;
};

export const calculateDeleted = (
  original: FlatCatalogueItem[],
  newItems: FlatCatalogueItem[],
): string[] => {
  return original.flatMap(({ sku }) =>
    !newItems.some((item) => item.sku === sku) ? [sku] : [],
  );
};
