import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { Panel, theme } from "@clearabee/ui-library";
import { SideBarContent } from "./sideBarContent";
import { keyframes } from "@emotion/react";
import { BeeLogo } from "../../../images";
import { CatalogueEditorContext } from "./catalogueEditor";

export const sideBarWidth = "250px";

export const SideBar = (): React.ReactElement => {
  const { isLoading } = useContext(CatalogueEditorContext);

  useEffect(() => {
    const portalContainer =
      document.body.querySelector<HTMLElement>(".portal-container");
    portalContainer?.classList.add("2xl:ml-32");

    return () => portalContainer?.classList.remove("2xl:ml-32");
  }, []);

  return (
    <>
      <Panel
        shadow={false}
        className="my-4 2xl:hidden"
        color="brand"
        styles={{
          [`@media (max-width: ${theme.screens.medium})`]: {
            padding: theme.spacing.medium,
          },
        }}
      >
        <SideBarContent />
      </Panel>
      {ReactDOM.createPortal(
        <div
          className="hidden 2xl:block"
          css={{
            position: "fixed",
            top: 0,
            left: 0,
            minHeight: "100%",
            width: sideBarWidth,
            overflowY: "visible",
            zIndex: 1000,
            backgroundColor: theme.colors.brand.base,
            color: theme.colors.light.base,
            animation: !isLoading
              ? ""
              : `${keyframes`
                  0% { width: 0 }
                  100% { width: ${sideBarWidth} }
                `} 150ms 1`,
          }}
        >
          <div className="p-4">
            <BeeLogo width={35} />
          </div>
          <SideBarContent />
        </div>,
        document.body,
      )}
    </>
  );
};
