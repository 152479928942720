import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  panel: {
    marginRight: theme.spacing.medium,
    marginBottom: theme.spacing.xlarge,
  },
  questionContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
  },
  question: {
    width: "50%",
    paddingRight: theme.spacing.medium,
  },
  imageLabel: {
    display: "block",
    marginBottom: theme.spacing.xsmall2,
    textAlign: "left",
    ...theme.fontDefaults.small,
    fontWeight: 600,
  },
  gallery: {
    display: "inline-block",
    cursor: "pointer",
  },
  input: {
    ":disabled": {
      color: theme.colors.greyscale.light,
      borderColor: theme.colors.greyscale.lightest,
      backgroundColor: theme.colors.greyscale.lightest,
    },
  },
  collapsedQuestion: {
    display: "none",
  },
});
