import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Table,
  theme,
  useModal,
  Loader,
  Panel,
  Heading,
  Input,
  Button,
  Form,
  Field,
  Text,
} from "@clearabee/ui-library";
import { ReactQueryResponse, useInstance } from "api/dsa";
import type { Job } from "@clearabee/api";
import { instance } from "@clearabee/ui-sdk";
import { TableRow } from "./components";

dayjs.extend(utc);

export const ReadSubcontractorJobs = (): JSX.Element => {
  const api = useInstance();
  const [translate] = useTranslation("users");
  const [jobs, setJobs] = useState<Job[]>([]);
  const [LoadingModal] = useModal({ defaultVisible: true });

  const dateToday = new Date();
  const startOfDayISO = dayjs.utc(dateToday).startOf("day").toISOString();
  const endOfDayISO = dayjs.utc(dateToday).endOf("day").toISOString();

  const [getJobsQuery, setGetJobsQuery] = useState(
    `from=${startOfDayISO}&to=${endOfDayISO}`,
  );

  /**
   * API query to get all the jobs
   */
  const {
    data: result,
    isLoading,
    refetch: refetchJobs,
  } = useQuery<ReactQueryResponse<typeof api.jobs.list>>(
    `listJobs-${getJobsQuery}`,
    () => api.jobs.list(`${getJobsQuery}`),
    {
      cacheTime: 5000,
      enabled: Boolean(startOfDayISO) && Boolean(endOfDayISO),
    },
  );

  useEffect(() => {
    if (!result?.data) return;
    setJobs(result?.data);
  }, [result?.data]);

  /**
   * API query to reset a job to an unassigned state
   */
  interface UnassignPayload {
    jobId: string;
    msJobId: string;
    body: Parameters<typeof api.jobs.update>[1];
  }

  const {
    mutate: unassignJob,
    isLoading: isUnassigningJob,
    isSuccess: hasUnassignedJob,
    isError: couldNotUnassignJob,
  } = useMutation(
    ({ jobId, msJobId, body }: UnassignPayload) => {
      instance.jobs.patchJob(msJobId, { contractAssigned: false });
      return api.jobs.update(jobId, body);
    },
    {
      onSuccess: () => refetchJobs(),
    },
  );

  const handleUnassignJob = (jobId: string, msJobId: string) => {
    unassignJob({
      jobId: jobId,
      msJobId,
      body: {
        vehicleDetails: {
          registration: "",
        },
        contractor: "",
        availableContractors: [],
      },
    });
  };

  const statusSelectOptions = [
    { value: "accepted", label: "Accepted" },
    { value: "onway", label: "Onway" },
    { value: "arrived", label: "Arrived" },
    { value: "collected", label: "Collected" },
    { value: "tipped", label: "Tipped" },
    { value: "completed", label: "Completed" },
    { value: "failed", label: "Failed" },
    { value: "open", label: "Open" },
  ];

  const initialValues = {
    dates: "",
    status: "",
    jobReference: "",
    postcode: "",
  };

  const filterJobs = (values: typeof initialValues) => {
    let fromDay: string;
    let toDay: string;
    let query = "";
    const { status, jobReference, postcode } = values;

    if (values.dates.includes("-")) {
      const dates = values.dates.split("-");
      const trimmedDates = dates.map((date) => {
        return date.replace(/ /g, "");
      });

      fromDay = trimmedDates[0];
      toDay = trimmedDates[1];
    } else {
      fromDay = values.dates;
      toDay = values.dates;
    }

    if (fromDay && toDay) {
      fromDay = dayjs.utc(fromDay, "D/M/YYYY").startOf("day").toISOString();
      toDay = dayjs.utc(toDay, "D/M/YYYY").endOf("day").toISOString();
      query = `${query}&from=${fromDay}&to=${toDay}`;
    }

    if (status) {
      query = `${query}&status=${status}`;
    }

    if (jobReference) {
      query = `${query}&jobReference=${jobReference}`;
    }

    if (postcode) {
      query = `${query}&postcode=${postcode.replace(/ /g, "")}`;
    }

    setGetJobsQuery(query);
    refetchJobs();
  };

  return (
    <>
      <Panel shadow={false} className="mb-10 mt-10">
        <Form
          initialValues={initialValues}
          onSubmit={(values) => filterJobs(values)}
        >
          {({ resetForm, dirty }) => (
            <div className="flex flex-col">
              <div className="border-b pb-4 mb-2 flex flex-row justify-between items-center">
                <Heading
                  level={5}
                  color="brand"
                  className="pr-4 w-full sm:w-auto"
                >
                  {translate("filters.subcontractorJobsHeading")}
                </Heading>
                <Button
                  size="small"
                  color="negative"
                  disabled={isLoading || !dirty}
                  type="reset"
                  onClick={() => {
                    resetForm();
                    setGetJobsQuery(`from=${startOfDayISO}&to=${endOfDayISO}`);
                    refetchJobs();
                  }}
                >
                  {translate("subcontractors.buttons.reset")}
                </Button>
              </div>
              <div className="grid grid-rows-2 grid-cols-3 gap-2">
                <Field
                  name="dates"
                  label={translate("subcontractors.labels.date")}
                  styles={{ margin: theme.spacing.xsmall }}
                >
                  {({ field }) => (
                    <Input.RangedDate
                      {...field}
                      acceptSingleDate
                      placeholder={translate(
                        "subcontractors.placeholders.date",
                      )}
                      disabled={isLoading}
                      collapsable
                    />
                  )}
                </Field>
                <Field
                  name="status"
                  label={translate("subcontractors.labels.status")}
                  styles={{ margin: theme.spacing.xsmall }}
                >
                  {({ field }) => (
                    <Input.Select
                      options={statusSelectOptions}
                      {...field}
                      placeholder={translate(
                        "subcontractors.placeholders.status",
                      )}
                      disabled={isLoading}
                    />
                  )}
                </Field>
                <Field
                  name="jobReference"
                  label={translate("subcontractors.labels.jobReference")}
                  styles={{ margin: theme.spacing.xsmall }}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "subcontractors.placeholders.jobReference",
                      )}
                      disabled={isLoading}
                    />
                  )}
                </Field>
                <Field
                  name="postcode"
                  label={translate("subcontractors.labels.postcode")}
                  styles={{ margin: theme.spacing.xsmall }}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate(
                        "subcontractors.placeholders.postcode",
                      )}
                      disabled={isLoading}
                    />
                  )}
                </Field>

                <div className="self-end flex justify-end col-start-3">
                  <Button
                    size="small"
                    color="accent"
                    type="submit"
                    disabled={isLoading || !dirty}
                  >
                    {translate("subcontractors.buttons.search")}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>
      </Panel>
      {jobs && jobs.length ? (
        <Table
          styles={{
            marginTop: theme.spacing.large,
            marginBottom: theme.spacing.large,
            "th:first-of-type > p, td:first-of-type > span": {
              marginLeft: theme.spacing.small,
            },
          }}
        >
          <Table.Header
            fontSize="xsmall"
            headings={[
              "Status",
              "Job Reference",
              "Job Descripton",
              "Assigned Subcontractor",
              "Collection Date",
              "Postcode",
              "Job Value",
              "Actions",
            ]}
          />

          <Table.Body>
            {jobs.map((job, index) => {
              return (
                <TableRow
                  key={index}
                  index={index}
                  job={job}
                  unassignJob={handleUnassignJob}
                  isLoading={isUnassigningJob}
                  couldNotUnassignJob={couldNotUnassignJob}
                  hasUnassignedJob={hasUnassignedJob}
                />
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Panel className="flex justify-center flex-row gap-1">
          <Text>{translate("subcontractors.messages.noJobsFound")}</Text>
          {getJobsQuery === `from=${startOfDayISO}&to=${endOfDayISO}` && (
            <Text>for today</Text>
          )}
        </Panel>
      )}
      {isLoading && (
        <LoadingModal styles={{ "&:after": { display: "none" } }}>
          <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
            <Loader color="light" text={translate("common:loading2")} />
          </div>
        </LoadingModal>
      )}
    </>
  );
};
