import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BasketProvider,
  PostBasketResponse,
  instance,
} from "@clearabee/ui-sdk";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Text,
  Form,
  Heading,
  Panel,
  Table,
  Input,
  theme,
  Message,
  Icon,
  Field,
} from "@clearabee/ui-library";
import { readCompanies } from "api";
import { LeftChevron } from "images";
import {
  rowInitialValues,
  InitialValues,
  initialValues,
  rowsValidation,
  Row,
} from "./validation";
import { BulkSuccessModal, NewJobRow } from "./components";
import { ProvideCatalogue } from "../../../contexts";
import { styles } from "./bulkCreateJobs.styles";
import { useAuthContext } from "../../../hooks";
import roles from "constants/roles";
import { ICompany } from "api/types";

export const BulkCreateJobs = (): React.ReactElement => {
  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const userCompanies = getCurrentUserCompanies();
  const worksForClearabee = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);
  const history = useHistory();
  const [translate] = useTranslation("jobs");
  const [paymentIndex, setPaymentIndex] = useState<number>(0);
  const numberOfRows = useRef<number>();
  const [companyCode, setCompanyCode] = useState("");
  const [companySettings, setCompanySettings] =
    useState<ICompany["settings"]>();
  const [catalogueId, setCatalogueId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [finishedSubmitting, setFinishedSubmitting] = useState(false);
  const [successfulJobs, setSuccessfulJobs] = useState<PostBasketResponse[]>(
    [],
  );
  const [resetConfirmation, setResetConfirmation] = useState(false);

  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    "readCompanies",
    () => readCompanies("", 0, 5000),
    { enabled: worksForClearabee },
  );

  const companiesWhoCanInvoice = (
    !worksForClearabee ? userCompanies : companies?.items ?? []
  ).filter(
    ({ settings, active }) => settings?.canInvoice === true && active === true,
  );

  // REDIRECT TO DASHBOARD IF USER DOESN'T HAVE PERMISSIONS TO VIEW THIS PAGE
  // Redirect if user does not have "canInvoice=true"
  useEffect(() => {
    if (!worksForClearabee && companiesWhoCanInvoice.length < 1) {
      history.push("/");
    }
  }, [companiesWhoCanInvoice, worksForClearabee]);

  /**
   * ADMIN users can see all companies. If you are a company users however, then
   * the readCompanies call will only return the company for which they work for.
   * If the list of companies is just one company, then set the companyCode state
   * to the company code of that user's company, so that their catalogue is returned.
   * Also set the companySettings state, in case this company has any specific requirements,
   * such as requiring an email address when creating a job.
   */
  useEffect(() => {
    if (companiesWhoCanInvoice.length === 1 && !worksForClearabee) {
      setCompanyCode(companiesWhoCanInvoice[0].companyCode);
      setCompanySettings(companiesWhoCanInvoice[0].settings);
    }
  }, [companiesWhoCanInvoice, worksForClearabee]);

  const {
    data: companyCatalogue,
    isFetching: isFetchingCatalogue,
    isError: errorFetchingCatalogue,
  } = useQuery(
    [`getCatalogueByCompany-${companyCode}`, companyCode],
    () => instance.catalogues.getCatalogueByCompanyCode(companyCode),
    {
      enabled: !!companyCode,
      retry: 0,
      onSuccess: (data) => {
        setCatalogueId(data.data.id);
      },
      onError: () => {
        setErrorMessage(translate("errors.noCompanyCatalogue"));
      },
    },
  );

  const handleSubmit = (values: InitialValues): void => {
    setPaymentIndex(0);
    numberOfRows.current = values.rows.length;
  };

  const handleFinishSubmit = (basket: PostBasketResponse | undefined): void => {
    if (basket) {
      setSuccessfulJobs([...successfulJobs, basket]);
    }

    if (!numberOfRows.current || paymentIndex === numberOfRows.current - 1) {
      setFinishedSubmitting(true);
      return;
    }

    setPaymentIndex(paymentIndex + 1);
  };

  const areRowsChanged = (rows: InitialValues["rows"]): boolean => {
    const initialRows = rows.map(() => rowInitialValues);

    return !(
      rows.length === initialRows.length &&
      rows.every((row, index) =>
        (Object.keys(row) as Array<keyof Row>).every(
          (property) => row[property] === initialRows[index][property],
        ),
      )
    );
  };

  const isDisabled = !companyCode || errorFetchingCatalogue;

  const loadingTable = isFetchingCatalogue || !catalogueId;

  const resetModal = (): void => {
    setSuccessfulJobs([]);
    setFinishedSubmitting(false);
  };

  return (
    <>
      <Link
        to="/jobs"
        className="text-sm inline-flex items-center mb-4 justify-start"
      >
        <span>
          <LeftChevron />
        </span>
        <span className="ml-2">{translate("buttons.labels.backToJobs")}</span>
      </Link>

      <Form
        initialValues={initialValues}
        validationSchema={rowsValidation(
          companySettings?.requirePhoneAndEmail,
          companySettings?.orderNumberValidation,
          companySettings?.orderNumberValidationMessage,
          companySettings?.requireOrderNumber,
        )}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, setValues, submitCount, resetForm }) => {
          const addNewRow = () => {
            setValues({
              ...values,
              rows: [
                ...values.rows,
                {
                  ...rowInitialValues,
                  bigchangeProps: {
                    ...rowInitialValues.bigchangeProps,
                    cust_OriginalRequestedDate: new Date().toISOString(),
                  },
                },
              ],
            });
          };
          const rowsChanged = areRowsChanged(values.rows);

          return (
            <>
              <BulkSuccessModal
                successfulJobs={successfulJobs}
                hidePrices={companySettings?.hidePrices}
                resetModal={resetModal}
                finishedSubmitting={finishedSubmitting}
              />
              <Panel styles={styles.panel}>
                <div className="flex flex-col w-full">
                  <div className="flex flex-col md:flex-row md:items-center justify-between mb-4 border-b">
                    <Heading color="brand" level={4}>
                      {translate("create.bulkJobs.title")}
                    </Heading>
                    {worksForClearabee && (
                      <Field name="useAdhocCatalogue">
                        {({ field }) => {
                          useEffect(() => {
                            setCatalogueId(
                              field.value
                                ? "PORTAL"
                                : companyCatalogue?.data.id ?? "",
                            );
                            return;
                          }, [field.value]);
                          return (
                            <Input.Checkbox
                              {...field}
                              label={translate(
                                "create.bulkJobs.placeholders.adhoc",
                              )}
                              checked={values.useAdhocCatalogue}
                              disabled={
                                rowsChanged ||
                                !companyCode ||
                                isFetchingCatalogue
                              }
                            />
                          );
                        }}
                      </Field>
                    )}
                  </div>
                  <div>
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      {companiesWhoCanInvoice && !isLoadingCompanies && (
                        <div className="w-2/3 mb-6 md:mb-0 mr-3">
                          <Input.Select
                            options={
                              companiesWhoCanInvoice.flatMap(
                                ({ companyCode, name, settings }) => ({
                                  value: JSON.stringify({
                                    companyCode,
                                    settings,
                                  }),
                                  label: name,
                                }),
                              ) ?? []
                            }
                            onChange={(event) => {
                              const valueFromDropdown: Pick<
                                typeof companiesWhoCanInvoice[number],
                                "companyCode" | "settings"
                              > = JSON.parse(event.target.value || "{}");

                              setErrorMessage(undefined);
                              if (
                                valueFromDropdown &&
                                valueFromDropdown.companyCode &&
                                valueFromDropdown.companyCode !== companyCode
                              ) {
                                setCatalogueId("");
                                setCompanyCode(valueFromDropdown.companyCode);
                                setCompanySettings(valueFromDropdown.settings);
                              }
                            }}
                            disabled={!companiesWhoCanInvoice || rowsChanged}
                            isLoading={!companiesWhoCanInvoice}
                            placeholder={translate(
                              "create.bulkJobs.placeholders.selectCompany",
                            )}
                            isSearchable
                          />
                        </div>
                      )}
                      {isLoadingCompanies && companiesWhoCanInvoice && (
                        <Text
                          style={{
                            width: "250px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          color="brand"
                        >
                          <Icon.Loading
                            size="medium"
                            color="brand"
                            styles={{
                              marginRight: theme.spacing.xsmall,
                            }}
                          />
                          {translate("loadingMessage")}
                        </Text>
                      )}
                      <div className="flex gap-2 ml-auto">
                        {!resetConfirmation && (
                          <Button
                            type="button"
                            size="medium"
                            color="negative"
                            onClick={() => {
                              setResetConfirmation(true);
                              setTimeout(
                                () => setResetConfirmation(false),
                                3500,
                              );
                            }}
                            disabled={!companyCode}
                          >
                            {translate("create.bulkJobs.buttons.reset")}
                          </Button>
                        )}
                        {resetConfirmation && (
                          <>
                            <Button
                              size="medium"
                              type="reset"
                              color="warning"
                              onClick={() => {
                                resetForm(), setCompanyCode("");
                              }}
                            >
                              {translate("modal.buttons.labels.yesReset")}
                            </Button>
                            <Button
                              onClick={() => setResetConfirmation(false)}
                              size="medium"
                              type="button"
                              color="negative"
                            >
                              {translate("modal.buttons.labels.goBack")}
                            </Button>
                          </>
                        )}
                        <Button
                          type="submit"
                          color="accent"
                          size="medium"
                          disabled={
                            !isValid ||
                            !values.rows.length ||
                            !!submitCount ||
                            !!errorMessage ||
                            !rowsChanged
                          }
                        >
                          {translate("create.bulkJobs.buttons.submit")}
                        </Button>
                      </div>
                    </div>
                  </div>

                  {!!errorMessage && (
                    <div style={{ display: "flex", alignSelf: "flex-start" }}>
                      <Message
                        type="error"
                        color="light"
                        styles={{
                          marginTop: theme.spacing.small,
                        }}
                        background
                      >
                        {errorMessage}
                      </Message>
                    </div>
                  )}
                </div>
              </Panel>
              {!isDisabled && (
                <>
                  {loadingTable ? (
                    <Icon.Loading
                      color="brand"
                      styles={{ margin: `${theme.spacing.xlarge} auto` }}
                    />
                  ) : (
                    <Table styles={styles.table}>
                      <colgroup>
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "85%" }} />
                        <col style={{ width: "10%" }} />
                      </colgroup>
                      <Table.Body>
                        <ProvideCatalogue
                          catalogueId={catalogueId}
                          postcode={null}
                          companyCode={
                            !values.useAdhocCatalogue ? companyCode : null
                          }
                        >
                          {values.rows.map((_, index) => (
                            <BasketProvider
                              catalogueId={catalogueId}
                              noLocalStorage
                              key={index}
                            >
                              <NewJobRow
                                index={index}
                                paymentIndex={paymentIndex}
                                finishSubmit={handleFinishSubmit}
                                companyCode={companyCode}
                                isLoading={isDisabled}
                                catalogueId={catalogueId}
                                poNumberLabel={
                                  companySettings?.orderNumberLabel
                                }
                                emailRequired={
                                  companySettings?.requirePhoneAndEmail
                                }
                              />
                            </BasketProvider>
                          ))}
                        </ProvideCatalogue>
                      </Table.Body>
                    </Table>
                  )}

                  <div style={styles.submitWrapper}>
                    <Button
                      onClick={addNewRow}
                      size="small"
                      type="button"
                      disabled={isDisabled}
                    >
                      {translate("create.bulkJobs.buttons.addNewJob")}
                    </Button>
                  </div>
                </>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};
