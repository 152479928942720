import React from "react";
import { Switch, Route as RouterRoute } from "react-router-dom";
import { Location } from "history";

/**
 * Import routes.
 */
import { routes } from "../../../helpers/routes";

/**
 * Import route components.
 */
import { PublicRoute, PrivateRoute } from "../../core";
import { FourOFour } from "./fourOFour";

interface RouterProps {
  item?: Location;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Router = ({ item }: RouterProps): React.ReactElement => {
  return (
    <Switch location={item || undefined}>
      {routes.map((route, index: number) => {
        const Route = route.type === "public" ? PublicRoute : PrivateRoute;

        if (route.type === "public" || route.type === "private") {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              component={route.component}
              permissions={route.permissions}
            />
          );
        }

        return (
          <RouterRoute
            key={index}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        );
      })}
      <RouterRoute component={FourOFour} />
    </Switch>
  );
};
