import React from "react";
import { Field } from "@clearabee/ui-library";
import { FormikHelpers } from "formik";
import { DropdownLookup, SelectOption } from "./dropdownLookup";
import { Address, readAddresses } from "api/libs/getAddressIo";

export interface PostcodeLookupFieldProps {
  name: string;
  label: string;
  placeholder: string;
  onOptionSelect: (
    address: Address,
    setFieldValue: FormikHelpers<unknown>["setFieldValue"],
  ) => void;
}

export const PostcodeLookupField = ({
  name,
  label,
  placeholder,
  onOptionSelect,
}: PostcodeLookupFieldProps): React.ReactElement => {
  const handlePostcodeLookup = async (
    postcode: string,
  ): Promise<SelectOption[]> => {
    if (!postcode) return [];
    const lookup = await readAddresses(postcode);
    return lookup.addresses.map((address) => ({
      label: `${address.line_1}, ${address.town_or_city}, ${address.county}`,
      value: JSON.stringify(address),
    }));
  };

  return (
    <Field name={name} label={label} renderLabel={false}>
      {/* Need a more elegant way to achieve this */}
      {({ field, form }) => (
        <DropdownLookup
          onChange={(e) => handlePostcodeLookup(e.target.value)}
          selectProps={{
            onChange: (e) => {
              // Only set a value if there is a value selected. Otherwise keep
              // the input blank. This is to show a blank input after a form reset
              if (e.target.value) {
                onOptionSelect(
                  JSON.parse(e.target.value) as Address,
                  form.setFieldValue,
                );
              }
            },
            isSearchable: true,
          }}
          textProps={{
            placeholder,
            ...field,
          }}
        />
      )}
    </Field>
  );
};
