"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortByPriceAndSize = void 0;
var sortByPriceAndSize = function (items) {
    return items.sort(function (a, b) {
        var _a, _b, _c, _d, _e, _f;
        return Number((_c = (_b = (_a = a.meta) === null || _a === void 0 ? void 0 : _a.yards) !== null && _b !== void 0 ? _b : a.price) !== null && _c !== void 0 ? _c : 0) -
            Number((_f = (_e = (_d = b.meta) === null || _d === void 0 ? void 0 : _d.yards) !== null && _e !== void 0 ? _e : b.price) !== null && _f !== void 0 ? _f : 0);
    });
};
exports.sortByPriceAndSize = sortByPriceAndSize;
