import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  unstyledButton: {
    minWidth: theme.spacing.xlarge4,
    marginLeft: theme.spacing.medium,
    fontWeight: 600,
    color: theme.colors.brand.base,
    borderBottom: `2px solid ${theme.colors.brand.base}`,
    ":hover": {
      color: theme.colors.brand.darker,
      borderBottom: `2px solid ${theme.colors.brand.darker}`,
    },
  },
  formPanel: {
    marginBottom: theme.spacing.medium,
  },
  selectInput: {
    width: "100%",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.colors.greyscale.lightest}`,
    marginBottom: theme.spacing.small,
    paddingBottom: theme.spacing.xsmall,
  },
  heading: {
    marginBottom: theme.spacing.medium,
  },
  topRowPanel: {
    padding: theme.spacing.medium,
    backgroundColor: theme.colors.brand.darker,
  },
  secondRowPanel: {
    padding: theme.spacing.medium,
    backgroundColor: theme.colors.brand.darker,
  },
  otherItemsPanel: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    backgroundColor: theme.colors.brand.darkest,
  },
  imageContainer: {
    width: theme.spacing.xlarge4,
    height: theme.spacing.xlarge4,
    marginBottom: theme.spacing.small,
  },
  graphPanel: {
    marginBottom: theme.spacing.small,
    width: "50%",
  },
  mapPanel: {
    padding: 0,
    width: "100%",
    overflow: "hidden",
    marginBottom: theme.spacing.xlarge,
  },
  pinTopText: {
    marginBottom: theme.spacing.xsmall,
  },
  companyHeader: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
  },
  jobsHeader: {
    fontWeight: 600,
  },
  company: {
    width: "80%",
  },
  jobs: {
    display: "flex",
    justifyContent: "flex-end",
    width: "20%",
  },
  stat: {
    width: "141px",
  },
  largeStatText: {
    fontSize: "40px",
    fontWeight: 800,
    lineHeight: theme.fontDefaults.xlarge3.lineHeight,
  },
  largeStatLabel: {
    ...theme.fontDefaults.small,
    fontWeight: 600,
  },
  smallStatText: {
    ...theme.fontDefaults.xlarge3,
    fontWeight: 800,
  },
  smallStatLabel: {
    ...theme.fontDefaults.small,
    fontWeight: 600,
  },
  tableBody: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.xsmall,
    maxHeight: "250px",
    overflowY: "scroll",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.colors.greyscale.lightest}`,
    marginBottom: theme.spacing.xsmall,
    paddingBottom: theme.spacing.xsmall,
  },
  divider: {
    borderTop: `1px solid ${theme.colors.greyscale.lightest}`,
    margin: `${theme.spacing.xlarge} 0`,
  },
  invoiceGraphContainer: {
    height: "500px",
    width: "66.66%",
  },
  map: {
    width: "100%",
    height: "900px",
  },
});
