"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalStorage = void 0;
var react_1 = require("react");
var isWebClient = typeof global.localStorage !== "undefined";
/**
 * useLocalStorage hook.
 * - Get and set local storage values
 * - Code from: https://usehooks.com/useLocalStorage
 */
var useLocalStorage = function (key, initialValue) {
    var _a = (0, react_1.useState)(isWebClient
        ? function () {
            try {
                var item = window.localStorage.getItem(key);
                return item ? JSON.parse(item) : initialValue;
            }
            catch (error) {
                return initialValue;
            }
        }
        : initialValue), storedValue = _a[0], setStoredValue = _a[1];
    var setValue = function (value) {
        setStoredValue(value);
        if (isWebClient)
            window.localStorage.setItem(key, JSON.stringify(value));
    };
    var clearStorage = function () {
        setStoredValue(undefined);
        if (isWebClient)
            window.localStorage.removeItem(key);
    };
    return [storedValue, setValue, clearStorage];
};
exports.useLocalStorage = useLocalStorage;
