import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

/**
 * Import Components.
 */
import { FormSelect, DatePickerInput, Checkbox } from "../../../core";
import { PostcodeManualAddress } from "../../../common/components/postcodeManualAddress";
import { Text, Panel, useModal, Button, theme } from "@clearabee/ui-library";

/**
 * Import API.
 */
import { readCompanies } from "../../../../api";
import { instance } from "@clearabee/ui-sdk";

/**
 * Import Hooks.
 */
import {
  useAuthContext,
  useBasketContext,
  useScreenWidth,
  useCatalogueContext,
  useMultiFormContext,
} from "../../../../hooks";

/**
 * Import validation schema.
 */
import {
  getDateAddressInitialValues,
  getDateAddressSchema,
  IDateAddressFormValues,
} from "../validation";

/**
 * Import types.
 */
import { ICompany } from "../../../../api/types";
import { ICreateJobFormState } from "../createJob";

import roles from "../../../../constants/roles";
import { LookupItemsModal } from "../components";
import Warning from "../../../../images/warning.svg";
import dayjs from "dayjs";

export const DateAddress = (): React.ReactElement => {
  const { setCompanyCode } = useCatalogueContext();
  const { clearItems, setBasketToken } = useBasketContext();
  const [companyNotes, setCompanyNotes] = useState<string>();
  const [Modal, setIsModalVisible] = useModal();
  const [date, setDate] = useState<string>();
  const [translate] = useTranslation("jobs");

  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const isClearabeeUser = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);
  const userCompanies = getCurrentUserCompanies();

  const { data: bookingLimit, isLoading: bookingLimitIsLoading } = useQuery(
    `bookingLimitDate-${date}`,
    async () =>
      (
        await instance.catalogues.getBookingLimit(
          dayjs(date).format("YYYY-MM-DD"),
        )
      ).data,
    {
      enabled: !isClearabeeUser,
      retry: 1,
    },
  );

  const history = useHistory();
  const [isAdhocChecked, setIsAdhocChecked] = useState(
    history.location.search === "?adhoc=true",
  );

  const { isLoading, data } = useQuery(
    ["readAllCompanies"],
    () => readCompanies("", 0, 5000),
    {
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      enabled: isClearabeeUser,
    },
  );

  const activeCompanies: ICompany[] = (
    data?.items ||
    userCompanies ||
    []
  ).filter(({ active }) => active === true);

  const { formState, nextStep, pushState } =
    useMultiFormContext<ICreateJobFormState>();

  const { isMobile } = useScreenWidth();

  const handleSubmit = (values: IDateAddressFormValues) => {
    const data = bookingLimit as any;

    if (
      !!data &&
      data.some(
        (item: any) =>
          item.companyCode === values.company?.value && !item.canBook,
      )
    ) {
      setIsModalVisible(true);
    } else {
      let companyCode = "";
      let companyName = "";
      if (values.company) {
        companyCode = values.company.value;
        companyName = values.company.label;
      }

      pushState({
        ...values,
        company: !isAdhocChecked
          ? { value: companyCode, label: companyName } || undefined
          : undefined,
        companyCanInvoice: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.canInvoice
          : undefined,
        companyOrderNumberRequired: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.requireOrderNumber
          : undefined,
        orderNumberValidation: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.orderNumberValidation
          : undefined,
        orderNumberValidationMessage: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.orderNumberValidationMessage
          : undefined,
        contactDetailsRequired: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.requireContactDetails
          : undefined,
        phoneAndEmailRequired: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.requirePhoneAndEmail
          : undefined,
        orderNumberLabel: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.settings?.orderNumberLabel
          : undefined,
        sicCode: companyCode
          ? activeCompanies.find(
              (company) => company.companyCode === companyCode,
            )?.sicCode
          : undefined,
      });
      if ((isClearabeeUser || userCompanies.length > 1) && companyCode) {
        setCompanyCode(companyCode);
      }

      nextStep();
    }
  };

  useEffect(() => {
    if (isAdhocChecked) {
      return history.push("/jobs/create?adhoc=true");
    } else {
      return history.push("/jobs/create");
    }
  }, [isAdhocChecked]);

  // Clear basket items on mount
  useEffect(() => {
    clearItems();
    setBasketToken("");
  }, []);

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={getDateAddressInitialValues(
          formState,
          isClearabeeUser,
          isAdhocChecked,
        )}
        validationSchema={getDateAddressSchema(isClearabeeUser)}
        validateOnMount
        validateOnChange
      >
        {({ handleSubmit, isValid, setFieldValue, values }) => {
          useEffect(() => {
            !!values.useAdhoc && setFieldValue("company", null);
          }, [values.useAdhoc]);

          useEffect(() => {
            if (values.collectionDate) {
              setDate(values.collectionDate);
            }
          }, [values.collectionDate]);

          useEffect(() => {
            if (history.location.search === "?adhoc=true") {
              setFieldValue("useAdhoc", true);
            } else {
              setFieldValue("useAdhoc", false);
            }
          }, [history.location]);

          useEffect(() => {
            const selectedCompanyCode = values.company?.value;

            if (!selectedCompanyCode) {
              setCompanyNotes("");
              return;
            }
            const selectedCompany = activeCompanies.find(
              (item) => item.companyCode === selectedCompanyCode,
            );
            setCompanyNotes(selectedCompany?.notes);
          }, [values.company]);

          return (
            <form onSubmit={handleSubmit}>
              <div
                data-testid="createJobDateAddressIdentifier"
                className="flex items-center justify-center bg-white w-full shadow-filter min-h-64 px-8 py-12 rounded-xl"
              >
                <div className="w-full max-w-screen-sm">
                  {isClearabeeUser && (
                    <>
                      <div className="flex mb-6 justify-end w-full">
                        <label
                          className="mr-3 font-semibold"
                          htmlFor="useAdhoc"
                        >
                          Book ADHOC Job
                        </label>
                        <Checkbox
                          name="useAdhoc"
                          onClick={() => setIsAdhocChecked(!isAdhocChecked)}
                        />
                      </div>
                    </>
                  )}
                  <div className="flex -mx-2">
                    {(isClearabeeUser || activeCompanies.length > 1) && (
                      <div
                        data-testid="createJobsChooseCompany"
                        className="w-full lg:w-4/6 px-2"
                      >
                        <FormSelect
                          name="company"
                          placeholder="Select company"
                          alwaysSetValue={false}
                          floatingLabel={false}
                          options={activeCompanies.map(
                            ({ name, companyCode }) => {
                              return {
                                value: companyCode?.toString() || "",
                                label: name,
                              };
                            },
                          )}
                          disabled={
                            activeCompanies.length === 0 || values.useAdhoc
                          }
                          loading={isLoading}
                          searchable={true}
                          validate={!isAdhocChecked}
                          menuPlacement={isMobile ? "top" : "bottom"}
                          label={{
                            text: `${translate("form.label.company")}${
                              isAdhocChecked ? "" : "*"
                            }`,
                          }}
                        />
                      </div>
                    )}
                    <div className="w-full lg:w-2/6 px-2 mb-5">
                      <DatePickerInput
                        name="collectionDate"
                        floatingLabel={false}
                        numberOfMonths={1}
                        validate
                        isRanged={false}
                        placeholder={translate("form.placeholder.selectADate")}
                        label={{ text: `${translate("form.label.date")}*` }}
                        disabledDays={[
                          {
                            before: new Date(),
                          },
                          {
                            daysOfWeek: [0],
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div>
                    <PostcodeManualAddress
                      fieldNamePrefix="collectionAddress."
                      fieldName="collectionAddress"
                    />
                  </div>
                  <div className="flex mt-6 justify-items-center">
                    {isClearabeeUser && values.company?.value && (
                      <LookupItemsModal companyCode={values.company.value} />
                    )}
                  </div>
                  {isClearabeeUser && !!companyNotes?.length && (
                    <Panel className="mt-6" shadow={false} color="warning">
                      <Text>{companyNotes}</Text>
                    </Panel>
                  )}
                </div>
              </div>
              <div className="my-10 flex items-center justify-center">
                <button
                  name="next"
                  type="submit"
                  data-testid="nextButton"
                  className="btn-secondary hover:btn-secondary-hover transition ease-in duration-100"
                  disabled={!isValid || bookingLimitIsLoading}
                >
                  {translate("buttons.labels.next")}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
      <Modal
        styles={{
          "@media (min-width: 768px)": {
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.large,
          },
        }}
      >
        <div className="flex justify-center mb-6">
          <img src={Warning} alt="Warning" width={90} />
        </div>
        <div className="mb-6">
          <Text fontSize="small">
            {translate("headings.bookingLimitReached")}
          </Text>
        </div>
        <Button
          color="warning"
          size="medium"
          onClick={() => setIsModalVisible(false)}
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      </Modal>
    </>
  );
};
