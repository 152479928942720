import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { FieldArray } from "formik";
import Select from "react-select";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Message,
  Panel,
  Table,
  displayErrorMessage,
  theme,
  Text,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { initialValues, validationSchema } from "./validation";

type PostDriverNotificationRequestBody = Parameters<
  Awaited<typeof instance.notifications.postDriverNotification>
>[0];

export const DriverNotifications = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");
  const [error, setError] = useState("");

  /**
   * fetch all users with filter of role name :Clearabee Driver
   */
  const {
    data: allDriversData,
    isLoading: isLoadingAllDrivers,
    isFetching: isFetchingAllDrivers,
    refetch: refetchAllDrivers,
  } = useQuery(
    "getAllDrivers",
    async () =>
      (
        await instance.users.getUsers({
          params: {
            "roles.name:in": "Clearabee Driver,Clearabee Administrator",
            limit: 1000,
          },
        })
      ).data.items,
    {
      cacheTime: 0,
      onError: () => setError("Error when fetching Drivers Data"),
    },
  );

  /**
   * sending notifications to selected drivers
   */
  const {
    mutate,
    isLoading: isLoadingSendNotification,
    reset: resetSendNotification,
    isSuccess,
  } = useMutation(
    "sendNotification",
    async (values: PostDriverNotificationRequestBody) =>
      await instance.notifications.postDriverNotification(values),
    {
      onError: () => setError("Error when sending notifications to drivers"),
    },
  );

  /**
   * All drivers select options
   */
  const selectOptions = useMemo(() => {
    if (!allDriversData?.length) return [];

    const options = allDriversData.map(
      ({ firstName, lastName, attributes, email }) => {
        const deviceTokenValue: string =
          attributes?.find(({ attrKey }) => attrKey === "deviceToken")
            ?.attrValue ?? "";

        return {
          label: `${firstName} ${lastName} - ${email}`,
          value: deviceTokenValue,
        };
      },
    );

    const filteredOptions = options.filter(({ value }) => !!value);

    return filteredOptions;
  }, [allDriversData]);

  /**
   * handle submit form
   */
  const handleSubmit = (values: typeof initialValues) => {
    const { drivers, data, ...rest } = values;

    const isAllSelected = !!drivers.find(
      ({ value }: { value: string }) => value === "all",
    );

    /**
     * parse data to string array of tokens
     * if all is selected, parse everything
     * if select some drivers, parse selected ones only
     */
    const tokens: string[] = isAllSelected
      ? selectOptions
          .map(({ value }: { value: string }) => value)
          .filter((value) => value !== "all")
      : drivers.map(({ value }: { value: string }) => value);

    const updatedValues = {
      ...rest,
      tokens,
      /**
       * convert [{ key: "value1", value: "value2"}] to  {"value1": "value2"}
       */
      data: Object.fromEntries(
        !!data[0].key ? data.map(({ key, value }) => [key, value]) : [],
      ),
    };

    mutate(updatedValues);
  };

  return (
    <Box className="max-w-screen-lg mx-auto py-10 relative">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {({ resetForm, setFieldValue, values }) => {
          return (
            <Panel
              className="flex flex-col gap-x-6 justify-between"
              shadow={false}
            >
              <Box className="flex justify-between">
                <Heading fontSize="large" color="brand">
                  {translate("notifications.headings.readNotification")}
                </Heading>
                <Box className="flex flex-row gap-x-3 items-center">
                  {/* Loading */}
                  {(isLoadingAllDrivers ||
                    isFetchingAllDrivers ||
                    isLoadingSendNotification) && (
                    <Icon.Loading size="large" color="brand" />
                  )}
                  <Button
                    size="small"
                    color="negative"
                    type="reset"
                    onClick={() => {
                      setError("");
                      resetForm();
                      refetchAllDrivers();
                      resetSendNotification();
                    }}
                  >
                    {translate("notifications.buttons.reset")}
                  </Button>
                  <Button size="small" type="submit">
                    {translate("notifications.buttons.notification")}
                  </Button>
                </Box>
              </Box>
              <Box className="w-full gap-x-3 border-t border-grey-200 mt-4">
                <Box className="w-full">
                  <Field
                    name="drivers"
                    className="flex-1 min-w-full"
                    label={translate("notifications.form.headings.driver")}
                  >
                    {({ field }) => (
                      <>
                        <Select
                          {...field}
                          isMulti
                          isClearable
                          isSearchable
                          placeholder={translate(
                            "notifications.form.placeholders.driver",
                          )}
                          options={
                            !!allDriversData?.length
                              ? [
                                  { label: "All", value: "all" },
                                  ...selectOptions,
                                ]
                              : []
                          }
                          onChange={(
                            value: [{ label: string; value: string }],
                          ) => {
                            const isAllSelected = !!value?.some(
                              (item) => item.value === "all",
                            );
                            if (isAllSelected) {
                              setFieldValue("drivers", [
                                { label: "All", value: "all" },
                              ]);
                            } else {
                              setFieldValue("drivers", value ?? []);
                            }
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              ...theme.fontDefaults.small,
                              minHeight: theme.spacing.xlarge3,
                              maxHeight: theme.spacing.xlarge4,
                              alignItems: "flex-start",
                              overflow: "scroll",
                            }),
                          }}
                        />
                        <Text
                          fontSize="xsmall"
                          className="font-semibold pt-2"
                          color="negative"
                        >
                          {translate("notifications.textSelectDropDown")}
                        </Text>
                      </>
                    )}
                  </Field>
                </Box>
                <Box className="w-full flex flex-row justify-between gap-x-3">
                  <Box className=" w-1/2">
                    <Field
                      name="title"
                      className="flex-1"
                      label={translate(
                        "notifications.form.headings.notificationTitle",
                      )}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "notifications.form.placeholders.notificationTitle",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className=" w-1/2">
                    <Field
                      name="body"
                      className="flex-1 w-1/3"
                      label={translate(
                        "notifications.form.headings.notificationBody",
                      )}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "notifications.form.placeholders.notificationBody",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Box className="w-full mt-2">
                  <Box className="flex flex-row justify-between items-center">
                    <Text fontSize="small" className="font-semibold">
                      {translate(
                        "notifications.form.headings.notificationJSON",
                      )}
                    </Text>
                    <Button
                      size="small"
                      color="accent"
                      type="button"
                      disabled={
                        isLoadingAllDrivers ||
                        isFetchingAllDrivers ||
                        isLoadingSendNotification
                      }
                      onClick={() => {
                        setFieldValue(
                          `data`,
                          [
                            ...values.data,
                            {
                              key: "",
                              value: "",
                            },
                          ],
                          true,
                        );
                      }}
                    >
                      {translate("notifications.table.actions.add")}
                    </Button>
                  </Box>
                  <Box
                    style={{
                      maxHeight: "500px",
                      overflow: "scroll",
                      marginTop: theme.spacing.small,
                    }}
                  >
                    <Table>
                      <colgroup>
                        <col style={{ width: "35%" }} />
                        <col style={{ width: "35%" }} />
                        <col style={{ width: "1%" }} />
                      </colgroup>
                      <Table.Header
                        fontSize="small"
                        headings={[
                          translate(
                            "notifications.table.notificationJSON.headings.key",
                          ),
                          translate(
                            "notifications.table.notificationJSON.headings.value",
                          ),
                          translate("notifications.table.actions.action"),
                        ]}
                      />
                      <Table.Body>
                        <FieldArray
                          validateOnChange
                          name="data"
                          render={(arrayHelpers) => (
                            <>
                              {values.data.flatMap((_, index) => (
                                <Table.Row key={`table-row-${index}`}>
                                  <Table.Cell>
                                    <Field
                                      styles={{ flex: 1 }}
                                      name={`data[${index}].key`}
                                    >
                                      {({ field }) => (
                                        <Input.Text
                                          {...field}
                                          placeholder={translate(
                                            "notifications.form.placeholders.notificationJSON.key",
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Field
                                      styles={{ flex: 1 }}
                                      name={`data[${index}].value`}
                                    >
                                      {({ field }) => (
                                        <Input.Text
                                          {...field}
                                          placeholder={translate(
                                            "notifications.form.placeholders.notificationJSON.value",
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      color="negative"
                                      type="button"
                                      size="small"
                                      disabled={
                                        isLoadingAllDrivers ||
                                        isFetchingAllDrivers ||
                                        isLoadingSendNotification ||
                                        values.data.length === 1
                                      }
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      {translate(
                                        "notifications.table.actions.delete",
                                      )}
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          )}
                        />
                      </Table.Body>
                    </Table>
                  </Box>
                </Box>
              </Box>
              <Box>
                {/* Display Errors if not found Email and Reg or fail to send notification to drivers*/}
                {!!error && (
                  <Box className="w-full mt-5">
                    {displayErrorMessage(
                      translate("notifications.errors.driversNotification", {
                        error,
                      }),
                      ({ children }) => (
                        <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                          <Message type="error" background>
                            {children}
                          </Message>
                        </Box>
                      ),
                    )}
                  </Box>
                )}
                {/* Display success message*/}
                {isSuccess && (
                  <Box className="w-full mt-2">
                    <Box className="flex items-center justify-start w-full mb-2 overflow-scroll">
                      <Message type="success" background color="light">
                        {translate(
                          "notifications.success.sentNotificationDrivers",
                        )}
                      </Message>
                    </Box>
                  </Box>
                )}
              </Box>
            </Panel>
          );
        }}
      </Form>
    </Box>
  );
};
