import React from "react";
import { Link, theme } from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { useTranslation } from "react-i18next";

export const BackButton = (): React.ReactElement => {
  const [translate] = useTranslation("quotes");

  return (
    <RouteLink href="/quotes">
      <Link
        icon="chevron"
        iconPosition="before"
        styles={{
          display: "inline-flex",
          alignItems: "center",
          marginBottom: theme.spacing.medium,
          " svg": {
            transform: "rotate(180deg)",
          },
        }}
      >
        {translate("buttons.backToQuotes")}
      </Link>
    </RouteLink>
  );
};
