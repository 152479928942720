import React, { useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ICompany, ICompanyPatchBody } from "@clearabee/api-schemas";
import { toasts } from "../../../helpers";
import {
  Heading,
  Button,
  Box,
  Text,
  Modal,
  theme,
  Icon,
  Panel,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../common/components";

export const ApproveSubcontractors = (): React.ReactElement => {
  const { t } = useTranslation("subcontractors");

  const [selectedSubcontractor, setSelectedSubcontractor] = useState<
    ICompany | undefined
  >();

  const {
    data: subcontractors,
    refetch: subcontractorRefetch,
    isLoading: subcontractorsIsLoading,
    isRefetching: subcontractorsIsRefetching,
  } = useQuery(
    "getSubcontractors",
    () => {
      return axios.get(`${process.env.REACT_APP_MS_API_BASE_URL}/companies`, {
        params: {
          "type:eq": "subcontractor",
          "active:eq": "0",
          limit: 1000,
        },
      });
    },
    {
      cacheTime: 0,
      onError: () => {
        toasts.error({
          message: t("approve.toasts.error"),
        });
      },
    },
  );

  const { mutate: patchSubcontractor, isLoading: patchSubcontractorIsLoading } =
    useMutation(
      "patchCompany",
      (body: { active: ICompanyPatchBody["active"] }) => {
        return axios.patch(
          `${process.env.REACT_APP_MS_API_BASE_URL}/companies/${
            selectedSubcontractor?.id || ""
          }`,
          {
            active: body.active,
          },
        );
      },
      {
        onSuccess: () => {
          setSelectedSubcontractor(undefined);
          subcontractorRefetch();
          toasts.success({
            message: t("approve.toasts.success"),
          });
        },
        onError: () => {
          toasts.error({
            message: t("approve.toasts.error"),
          });
        },
      },
    );

  return (
    <Panel>
      <Box className="mb-6">
        <Heading color="brand" level={1} fontSize="large">
          {t("approve.heading")}
        </Heading>
        <Text>{t("approve.description")}</Text>
      </Box>

      {/* LOADING */}
      {(subcontractorsIsLoading ||
        subcontractorsIsRefetching ||
        patchSubcontractorIsLoading) && <LoadingOverlay />}

      {/* TABLE */}
      <Box className="flex flex-col w-full">
        {/* TABLE HEADINGS */}
        <Box className="flex flex-row w-full justify-start items-center">
          <Text fontSize="small" className="font-bold w-1/3">
            {t("approve.table.name")}
          </Text>
          <Text fontSize="small" className="font-bold w-3/6">
            {t("approve.table.email")}
          </Text>
          <Text fontSize="small" className="font-bold w-1/6">
            {t("approve.table.code")}
          </Text>
          <Box className="w-1/6" />
        </Box>

        {/* DIVIDER */}
        <Box className="border-t border-gray-300 my-1 flex flex-col h-1" />

        {/* TABLE ROWS */}
        {!!subcontractors &&
          subcontractors?.data?.items.length > 0 &&
          subcontractors?.data?.items.map((subcontractor: ICompany) => (
            <>
              <Box
                key={subcontractor.id}
                className="flex flex-row w-full justify-start items-center py-4"
              >
                <Text fontSize="small" className="w-1/3">
                  {subcontractor.name}
                </Text>
                <Text fontSize="small" className="w-3/6">
                  {subcontractor.contactEmail}
                </Text>
                <Text fontSize="small" className="w-1/6">
                  {subcontractor.companyCode}
                </Text>
                <Box className="w-1/6">
                  <Button
                    size="xsmall"
                    onClick={() => setSelectedSubcontractor(subcontractor)}
                  >
                    {t("approve.buttons.view")}
                  </Button>
                </Box>
              </Box>

              {/* DIVIDER */}
              <Box className="border-t border-grey-200 my-1 flex flex-col" />
            </>
          ))}

        {/* APPROVAL MODAL */}
        {selectedSubcontractor && (
          <Modal
            width={750}
            onClose={() => setSelectedSubcontractor(undefined)}
            styles={{
              padding: theme.spacing.xlarge2,
              [`@media (min-width: ${theme.screens.medium})`]: {
                padding: theme.spacing.xlarge2,
              },
            }}
          >
            <Heading color="brand" level={2} className="mb-2">
              {selectedSubcontractor.name}
            </Heading>

            <Box
              styles={{ maxHeight: "500px", overflowY: "scroll" }}
              className="flex flex-col justify-start text-left mb-6"
            >
              <Box className="flex flex-col gap-3 mt-3">
                <Box>
                  <Heading color="brand" level={5}>
                    {t("approve.modal.date")}
                  </Heading>
                  <Text>
                    {dayjs(selectedSubcontractor.createdOn).format(
                      "DD/MM/YYYY",
                    )}
                  </Text>
                </Box>

                <Box>
                  <Heading color="brand" level={5}>
                    {t("approve.modal.code")}
                  </Heading>
                  <Text>{selectedSubcontractor.companyCode}</Text>
                </Box>

                <Box>
                  <Heading color="brand" level={5}>
                    {t("approve.modal.email")}
                  </Heading>
                  <Text>{selectedSubcontractor.contactEmail}</Text>
                </Box>

                {selectedSubcontractor.contactPhoneNumber && (
                  <Box>
                    <Heading color="brand" level={5}>
                      {t("approve.modal.phone")}
                    </Heading>
                    <Text>{selectedSubcontractor.contactPhoneNumber}</Text>
                  </Box>
                )}

                <Box className="mb-3">
                  <Heading color="brand" level={5}>
                    {t("approve.modal.address")}
                  </Heading>
                  <Text>{selectedSubcontractor.addressLine1}</Text>
                  {selectedSubcontractor.addressLine2 && (
                    <Text>{selectedSubcontractor.addressLine2}</Text>
                  )}
                  <Text>{selectedSubcontractor.addressCity}</Text>
                  {selectedSubcontractor.addressCounty && (
                    <Text>{selectedSubcontractor.addressCounty}</Text>
                  )}
                  <Text>{selectedSubcontractor.addressPostcode}</Text>
                </Box>
              </Box>

              {!!selectedSubcontractor.settings && (
                <Box>
                  <Heading color="brand" level={5}>
                    {t("approve.modal.settings")}
                  </Heading>
                  <Box className="flex flex-col gap-2">
                    {Object.entries(selectedSubcontractor.settings).map(
                      ([key, value]) => {
                        return (
                          <Text className="font-semibold">
                            {key}:{" "}
                            <Text
                              className="font-normal"
                              color="dark"
                              as="span"
                            >
                              {value ? value.toString() : "false"}
                            </Text>
                          </Text>
                        );
                      },
                    )}
                  </Box>
                </Box>
              )}
            </Box>

            <Box>
              <Button
                onClick={() => patchSubcontractor({ active: true })}
                className="mr-2"
                size="small"
              >
                {patchSubcontractorIsLoading ? (
                  <Icon.Loading className="mx-auto" size="medium" />
                ) : (
                  t("approve.buttons.approve")
                )}
              </Button>
            </Box>
          </Modal>
        )}
      </Box>
    </Panel>
  );
};
