import { theme } from "../../../../theme";
import { StyleSheet } from "../../../../utils";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes({
  from: {
    opacity: 0,
    transform: "translateY(20px)",
  },
});

export const styles = StyleSheet({
  fileWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    position: "relative",
    width: "340px",
    height: "200px",
    backgroundColor: theme.colors.greyscale.lightest,
    borderRadius: theme.spacing.xsmall,
    alignItems: "center",
  },
  imageContainer: {
    display: "flex",
    width: "126px",
    height: "126px",
    borderRadius: "10px",
    marginLeft: "10px",
    backgroundColor: theme.colors.light.base,
    animationName: fadeIn,
    animationDuration: "300ms",
  },
  image: {
    width: "69px",
    height: "75px",
    margin: "auto",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  placeholder: {
    container: {
      display: "flex",
      width: "126px",
      height: "126px",
      cursor: "pointer",
    },
    image: {
      margin: "auto",
    },
  },
  button: {
    zIndex: theme.stack.inputFile,
  },
  clickable: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "absolute",
  },
  error: {
    marginTop: "12px",
    padding: "8px 0 8px 0",
    fontWeight: "bold",
  },
});
