import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export const styles = StyleSheet({
  tab: {
    marginTop: "auto",
    ...theme.fontDefaults.small,
    padding: `${theme.spacing.small} ${theme.spacing.large}`,
    fontWeight: "normal",
    background: theme.colors.brand.base,
    borderRadius: `0 ${theme.spacing.xsmall} 0 0`,
    marginLeft: 2,
    border: "none",
    cursor: "pointer",
    color: theme.colors.light.base,
    ":first-of-type": {
      borderTopLeftRadius: theme.spacing.xsmall,
      marginLeft: theme.spacing.small,
    },
  },
  activeTab: {
    borderRadius: `${theme.spacing.xsmall} ${theme.spacing.xsmall} 0 0`,
    // override lineheight to get text to align properly
    ...theme.fontDefaults.large,
    lineHeight: "19px",
    fontWeight: "bold",
    paddingTop: theme.spacing.medium,
    color: theme.colors.brand.base,
    background: theme.colors.greyscale.lightest,
  },
  hidden: {
    display: "none",
  },
});
