import * as Yup from "yup";
import { translate } from "@clearabee/i18n";
import { statusMap } from "components/worksheets/updateWorksheet/updateWorksheetHelpers";
import { getQuestionTypeIdsByName } from "./questionModalHelpers";
import type { IQuestionType } from "@clearabee/api-schemas";

// Instead of repeating .test() with the same logic, creating a custom method. (Also see modules.d.ts)
Yup.addMethod(Yup.number, "isUnsigned", function (message?: string) {
  return this.test(
    "is-unsigned",
    message ??
      translate(
        "portal.worksheets.updateWorksheet.questionModal.validation.unsigned",
      ),
    function (value) {
      if (!value) return true; // Do not validate if field is blank or undefined

      return value >= 0;
    },
  );
});

// Following vars are used to avoid duplication in the validation schema
const stringRequired = Yup.string().required(
  translate(
    "portal.worksheets.updateWorksheet.questionModal.validation.required",
  ),
);
const numberInteger = Yup.number()
  .integer(
    translate(
      "portal.worksheets.updateWorksheet.questionModal.validation.wholeNumber",
    ),
  )
  .typeError(
    translate(
      "portal.worksheets.updateWorksheet.questionModal.validation.number",
    ),
  );
const numberIntegerRequired = Yup.number()
  .integer(
    translate(
      "portal.worksheets.updateWorksheet.questionModal.validation.wholeNumber",
    ),
  )
  .typeError(
    translate(
      "portal.worksheets.updateWorksheet.questionModal.validation.number",
    ),
  )
  .required(
    translate(
      "portal.worksheets.updateWorksheet.questionModal.validation.required",
    ),
  );
const boolean = Yup.boolean();

export const getValidationSchema = (questionTypes: IQuestionType[]) => {
  const counterQuestionTypeIds = getQuestionTypeIdsByName(
    "counter",
    questionTypes,
  );
  const listQuestionTypeIds = getQuestionTypeIdsByName("list", questionTypes);

  return Yup.object({
    question: stringRequired,
    position: numberInteger.min(
      1,
      translate(
        "portal.worksheets.updateWorksheet.questionModal.validation.position",
      ),
    ),
    typeId: numberIntegerRequired,
    bcQuestionId: numberInteger
      .typeError(
        translate(
          "portal.worksheets.updateWorksheet.questionModal.validation.bcInteger",
        ),
      )
      .isUnsigned(
        translate(
          "portal.worksheets.updateWorksheet.questionModal.validation.bcUnsigned",
        ),
      ),

    parentId: numberInteger,

    // Display condition needs to be one of the possible values of the parent question:
    // if a list, it will be one of the list's options (strings);
    // if a boolean-parent, it will be "true" or "false" string.
    displayCondition: Yup.string().when(["parentId"], {
      is: (parentId) => {
        return !!parentId; // If parentId, then check displayCondition has a value
      },
      then: Yup.string().required(
        translate(
          "portal.worksheets.updateWorksheet.questionModal.validation.displayCondition",
        ),
      ),
    }),

    statusId: numberInteger.oneOf(
      Object.keys(statusMap).map((key) => parseInt(key)),
      `${translate(
        "portal.worksheets.updateWorksheet.questionModal.validation.status",
      )}: ${Object.values(statusMap).join(", ")}`, // Only these status updates are allowed
    ),

    // For boolean quesiton type
    booleanParentQuestion: boolean,
    booleanQuestionToggleAll: boolean,
    booleanQuestionRequiredValueTrue: boolean,

    // For counter question type displayed as a scrollable list
    counterAsList: boolean,
    counterStart: Yup.mixed().when(["typeId", "counterAsList"], {
      is: (typeId, counterAsList) => {
        return counterQuestionTypeIds.includes(typeId) && counterAsList;
      },
      then: numberInteger.isUnsigned(),
    }),
    counterEnd: Yup.mixed().when(["typeId", "counterAsList"], {
      is: (typeId, counterAsList) => {
        return counterQuestionTypeIds.includes(typeId) && counterAsList;
      },
      then: numberInteger
        .isUnsigned()
        .test(
          "is-greater-than-start",
          translate(
            "portal.worksheets.updateWorksheet.questionModal.validation.counterEnd",
          ),
          function (value) {
            if (!value) return false;

            return value > this.parent.counterStart;
          },
        ),
    }),
    counterIncrement: Yup.mixed().when(["typeId", "counterAsList"], {
      is: (typeId, counterAsList) => {
        return counterQuestionTypeIds.includes(typeId) && counterAsList;
      },
      then: numberInteger
        .isUnsigned()
        .test(
          "is-range-divisible-by-increment",
          translate(
            "portal.worksheets.updateWorksheet.questionModal.validation.counterIncrement",
          ),
          function (value) {
            if (!value) return false;

            const start = this.parent.counterStart;
            const end = this.parent.counterEnd;
            const range = end - start;
            const modulo = range % value;

            return modulo === 0;
          },
        ),
    }),

    // For list quesiton types (list items)
    typeValues: Yup.array().when("typeId", {
      is: (typeId) => {
        return listQuestionTypeIds.includes(typeId);
      },
      then: Yup.array()
        .min(
          1,
          translate(
            "portal.worksheets.updateWorksheet.questionModal.validation.listItems",
          ),
        )
        .of(numberInteger),
    }),

    // For modal question types
    modalText: Yup.string(),
  });
};
