import React, { useState } from "react";
import {
  Button,
  displayErrorMessage,
  Icon,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { getCatalogueTemplate, updateCatalogueTemplate } from "../../../api";
import { FormLoader, ErrorMessage } from "../../common/components";
import { toasts } from "../../../helpers/toasts";
import { Template } from "models/template";
import { PatchTemplate } from "../../../api/types";
import { CascadeState } from "../cascadeTemplate/cascadeTemplate";
import {
  CreateUpdateItemForm,
  ItemFormValues,
  ItemForValuesWithUploadedImage,
} from "components/catalogues/components/createUpdateItemForm";
import { parseFormData } from "components/catalogues/parser";
import { compareObjects, isEmpty, trimObject } from "../../../helpers";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

export const UpdateTemplate = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [valuesRef, setValuesRef] = useState<ItemForValuesWithUploadedImage>();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const [translate] = useTranslation("catalogues");

  const {
    mutate,
    error,
    reset,
    isLoading: isMutationLoading,
  } = useMutation(
    (template: PatchTemplate) => updateCatalogueTemplate(id, template),
    {
      onMutate: () => setLoading(true),
      onSettled: () => setLoading(false),
      onSuccess: () => setShowModal(true),
    },
  );

  const handleFormSubmit = async (values: ItemForValuesWithUploadedImage) => {
    setValuesRef(values);
    mutate(parseFormData(values));
  };

  const { isFetching, isLoading, isError, data } = useQuery(
    ["getCatalogueTemplate", id],
    () => getCatalogueTemplate(id),
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );

  if (isLoading || isFetching || !data) {
    return <FormLoader isLoading />;
  } else if (isError) {
    return <ErrorMessage />;
  }

  const onCascadeMode = (values?: ItemFormValues) => {
    // take out img of the form values as we only upload on form submission
    // and not on file select, therefore we do not have the URL with a new image
    const { img, ...formValues } = values ?? valuesRef!;
    const parsedData = compareObjects(
      // only way to get parser to work nicely with compareObjects
      parseFormData({ ...formValues, img: null }),
      data,
    );
    const trimmedData: Partial<Template> = {
      ...trimObject(parsedData),
      // make sure to trim our meta object also
      meta: {
        ...trimObject(parsedData?.meta || {}),
      },
    };
    // remove if nothing has changed in meta
    if (isEmpty(trimmedData.meta)) {
      delete trimmedData.meta;
    }

    // if image exists then add it back to the object
    if (img && typeof img === "string") {
      trimmedData.img = img;
    } else if (img && typeof img === "object") {
      trimmedData.img = img.dataUrl;
    }

    const state: CascadeState = {
      heading: data.title,
      sku: id,
      data: trimmedData,
    };

    history.push({
      pathname: `/catalogues/templates/cascade/${id}`,
      state,
    });
  };

  return (
    <>
      <Link
        to="/catalogues/templates"
        className="inline-flex items-center mb-5"
      >
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues/templates"),
        })}
      </Link>

      <CreateUpdateItemForm
        initialValues={data}
        heading={data.title}
        onSubmit={handleFormSubmit}
        disableSubmit={loading || showModal}
        onCascadeMode={onCascadeMode}
        cancelUrl="/catalogues/templates"
        isLoading={isMutationLoading}
      />

      {displayErrorMessage(error, (props) => (
        <DefaultErrorComponent
          {...props}
          styles={{
            marginTop: theme.spacing.small,
            marginBottom: theme.spacing.small,
            maxWidth: theme.screens.small,
            marginRight: 0,
          }}
          onClose={reset}
        />
      ))}

      {showModal && (
        <Modal
          heading={translate("modal.update.heading")}
          width={500}
          actions={
            <>
              <Button
                size="medium"
                color="negative"
                type="button"
                onClick={() => setShowModal(false)}
                className="mr-3"
              >
                {translate("common:no")}
              </Button>
              <Button
                size="medium"
                color="accent"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  onCascadeMode();
                }}
                className="ml-3"
              >
                {translate("common:yes")}
              </Button>
            </>
          }
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("modal.update.message")}
          </div>
        </Modal>
      )}
    </>
  );
};
