import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Icon, Table, theme, Heading, Box } from "@clearabee/ui-library";
import { JobsFilters } from "./components";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { readJobs } from "api";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { convertJsonToCSV, toasts } from "helpers";
import { statusColors } from "helpers/statusColors";
import { useAuthContext } from "hooks";
import roles from "constants/roles";

dayjs.extend(utc);

export const ReadJobs = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeUser = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);
  const [enabled, setEnabled] = useState(!isClearabeeUser);

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    currentPage,
    setCurrentPage,
    query: { isFetching, isLoading, isSuccess },
  } = usePaginatedQuery(
    readJobs,
    "readJobs",
    "orderByDesc=date",
    {
      resultOptions: [10, 20, 50, 100],
      enabled,
      cacheTime: 0,
      initialPage: params.get("page") ? Number(params.get("page")) : 1,
    },
    // paginationStyles
    paginationStyles,
  );

  const handleDownloadClick = () => {
    if (!paginatedData || !paginatedData.length)
      return toasts.error({ message: "No data available to download" });

    const csvData = convertJsonToCSV(
      paginatedData.map((job) => ({
        ID: job.id,
        "Big Change Id": job.bcId,
        Reference: job.ref,
        Status: job.status,
        "PO Number": job.meta?.poNumber || "",
        "Line 1": job.addressLine1,
        City: job.addressCity,
        County: job.addressCounty,
        Postcode: job.addressPostcode,
        "Purchase Order": job.purchaseOrder,
        Name: `${job.contactFirstName} ${job.contactLastName}`,
        Email: job.contactEmail,
        "Company Code": job.companyCode,
        "Collection Date": dayjs(job.date).format("DD/MM/YYYY"),
        Description: job.description,
        "Access Information": job.accessInformation,
      })),
    );

    const blob = new Blob(["\ufeff", csvData]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `jobs_export_${dayjs().unix()}.csv`);
    document.body.appendChild(link);

    link.click();
  };

  return (
    <Box className="max-w-screen-lg py-5 ml-auto mr-auto">
      <JobsFilters
        isFetching={isFetching}
        updateFilters={updateFilters}
        onDownloadClick={handleDownloadClick}
        setEnabled={setEnabled}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {!!paginatedData.length && !isFetching && (
        <>
          <Box className="relative overflow-x-scroll">
            <Table
              styles={{
                marginBottom: theme.spacing.large,
                "th:first-of-type > p, td:first-of-type > span": {
                  marginLeft: theme.spacing.small,
                },
              }}
            >
              <Table.Header
                headings={[
                  translate("table.headings.status"),
                  translate("table.headings.jobReference"),
                  translate("table.headings.jobDate"),
                  translate("table.headings.contact"),
                  translate("table.headings.postcode"),
                  translate("table.headings.purchaseOrder"),
                ]}
              />
              <Table.Body>
                {paginatedData.map((job) => (
                  <Table.Row
                    key={`table-row-${job.id}`}
                    styles={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(
                        `/jobs/update/${encodeURIComponent(
                          String(job.id ?? ""),
                        )}`,
                      )
                    }
                  >
                    <Table.Cell.Pill color={statusColors[job.status]}>
                      {job.status}
                    </Table.Cell.Pill>
                    <Table.Cell.Text>{job.ref}</Table.Cell.Text>
                    <Table.Cell.Text>
                      {dayjs.utc(job.date).format("DD/MM/YYYY")}
                    </Table.Cell.Text>
                    <Table.Cell.Text>{`${job.contactFirstName} ${job.contactLastName}`}</Table.Cell.Text>
                    <Table.Cell.Text>{job.addressPostcode}</Table.Cell.Text>
                    <Table.Cell.Text>{job.purchaseOrder}</Table.Cell.Text>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Box>

          <PaginationComponent />
        </>
      )}
      {(isFetching || isLoading) && (
        <Icon.Loading
          styles={{ margin: `${theme.spacing.xlarge} auto` }}
          color="brand"
        />
      )}
      {!paginatedData.length && isSuccess && (
        <Heading styles={{ textAlign: "center" }} level={2} fontSize="large">
          {translate("noData")}
        </Heading>
      )}
    </Box>
  );
};
