import React from "react";

export const LoadingResults: React.FC = () => {
  const noResults = [
    "result1",
    "result2",
    "result3",
    "result4",
    "result5",
    "result6",
    "result7",
    "result8",
    "result9",
    "result10",
    "result11",
    "result12",
    "result13",
    "result14",
    "result15",
    "result16",
  ];

  return (
    <div className="flex flex-wrap -mx-2 mt-8">
      {noResults.map((noR) => {
        return (
          <div key={noR} className="w-1/4 p-2">
            <div className="flex-1 bg-white shadow-xl rounded-lg px-4 pt-16 pb-12 flex flex-col relative">
              <div className="animate-pulse flex flex-col items-center">
                <div className="rounded-full bg-purple-400 h-12 w-12"></div>
                <div className="w-full py-5 space-y-2">
                  <div className="h-3 bg-purple-400 rounded w-1/2 mx-auto"></div>
                  <div className="space-y-1 px-6">
                    <div className="h-3 bg-purple-300 rounded"></div>
                    <div className="h-3 bg-purple-300 rounded w-4/6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
