import React, { useEffect } from "react";
import { useFormikContext, FormikProps, FormikValues } from "formik";

/**
 * Import components.
 */
import { Checkbox } from "../../../core";

/**
 * Interface.
 */
interface ICustomerNoEmail {
  name: string;
  label: string;
}

export const CustomerNoEmail: React.FC<ICustomerNoEmail> = ({
  name,
  label,
}) => {
  /**
   * Formik context.
   */
  const { values, setFieldValue }: FormikProps<FormikValues> =
    useFormikContext();

  /**
   * Reset email field if no emial checkbox is set to true.
   */
  useEffect(() => {
    if (values[name]) {
      setFieldValue("email", "");
    }
  }, [values[name]]);

  return <Checkbox name={name} label={label} />;
};
