import React, { useEffect, useState, useRef } from "react";
import { css } from "@emotion/react";
import { InputText } from "../../Core/Form/Inputs/InputText/InputText";
import { InputRadio } from "../../Core/Form/Inputs/InputRadio/InputRadio";
import { InputCheckbox } from "../../Core/Form/Inputs/InputCheckbox/InputCheckbox";
import { Icon } from "../../Core/Icon/Icon";
import { UserStylesProps } from "../../utils/types";
import { styles } from "./FilterList.styles";

export interface FilterListOption {
  label: string;
  value: string;
}

export interface FilterListProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  options: FilterListOption[];
  content?: React.ReactNode;
  searchPlaceholder?: string;
  initialSelectedOptions?: FilterListOption[];
  onOptionsChange?: (options: FilterListOption[]) => void;
  onSearch?: (search: string) => void;
}

export const FilterList = ({
  styles: userStyles,
  content,
  searchPlaceholder,
  options,
  initialSelectedOptions = [],
  onOptionsChange,
  onSearch,
  ...rest
}: FilterListProps): React.ReactElement => {
  const isFirstRender = useRef(true);
  const [selectedOptions, setSelectedOptions] = useState<FilterListOption[]>(
    initialSelectedOptions,
  );
  const [visibleOptions, setVisibleOptions] = useState(options);
  const selectedCount = visibleOptions.filter((option) =>
    selectedOptions.some(({ value }) => option.value === value),
  ).length;
  const isSelectedAll = selectedCount === visibleOptions.length;
  const isDeselectAll = selectedCount === 0;

  const handleOptionSelect = (option: FilterListOption, checked: boolean) => {
    if (checked) {
      const newSelectOptions = selectedOptions.filter(
        ({ value }) => value !== option.value,
      );
      setSelectedOptions(newSelectOptions);
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSelectAll = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;

    if (target.value === "no") {
      const newOptions = selectedOptions.filter(
        (option) => !visibleOptions.some(({ value }) => option.value === value),
      );
      setSelectedOptions(newOptions);
    } else if (target.value === "yes") {
      const newOptions = [
        // filter out already selected options
        ...selectedOptions.filter(
          (option) =>
            !visibleOptions.some(({ value }) => option.value === value),
        ),
        ...visibleOptions,
      ];
      setSelectedOptions(newOptions);
    }
  };

  const handleSearch = (search: string) => {
    onSearch?.(search);
    setVisibleOptions(
      options.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  useEffect(() => setVisibleOptions(options), [options]);
  useEffect(() => {
    // we should only call onOptionsChange when user changes their selection
    // setting initial value of selected options should be ignored
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      onOptionsChange?.(selectedOptions);
    }
  }, [selectedOptions]);

  return (
    <div css={css([userStyles, styles.defaultStyles])} {...rest}>
      <div css={css(styles.contentContainer)}>
        {content && <div>{content}</div>}
        <div css={css(styles.radios)}>
          <InputRadio
            label="Select all"
            value="yes"
            onChange={handleSelectAll}
            checked={isSelectedAll}
            styles={css(styles.selectAllRadio)}
          />
          <InputRadio
            label="Deselect all"
            value="no"
            checked={isDeselectAll}
            onChange={handleSelectAll}
          />
        </div>
        <div css={css(styles.searchContainer)}>
          <InputText
            css={css(styles.searchInput)}
            placeholder={searchPlaceholder}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Icon.Search size="small" styles={styles.searchIcon} />
        </div>
      </div>

      <div css={css(styles.optionsContainer)}>
        {visibleOptions.map((option) => {
          const checked = selectedOptions.some(
            ({ value }) => option.value === value,
          );
          return (
            <div
              css={css(styles.optionWrap)}
              key={`option-${option.label}-${option.value}`}
            >
              <InputCheckbox
                styles={styles.option}
                label={option.label}
                value={option.value}
                onChange={() => handleOptionSelect(option, checked)}
                checked={checked}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
