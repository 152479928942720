import React, { useState } from "react";
import {
  Button,
  Heading,
  theme,
  Icon,
  Pill,
  Table,
  displayErrorMessage,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

interface BeeLoyalTransactionsProps {
  email: string;
}

const ResultsTable = ({
  email,
}: BeeLoyalTransactionsProps): React.ReactElement => {
  const history = useHistory();
  const { paginatedData, PaginationComponent, query } = usePaginatedQuery(
    async (filters, page, limit) => {
      const { items, pagination } = (
        await instance.payments.getBeeLoyalTransactions({
          params: {
            limit,
            offset: page * limit,
            email,
            orderByDesc: "addedOn",
          },
        })
      ).data;
      return {
        items,
        pagination: {
          ...pagination,
          hasMoreResults: false,
        },
      };
    },
    `${email}-getBeeLoyalTransactions`,
    "",
  );
  const [translate] = useTranslation("users");

  if (query.isLoading) {
    return <Icon.Loading color="brand" />;
  }

  if (query.error) {
    return displayErrorMessage(query.error, (props) => (
      <DefaultErrorComponent {...props} onRetry={() => query.refetch()} />
    ));
  }

  if (!paginatedData || !paginatedData.length) {
    return <Pill color="warning">{translate("user.noData")}</Pill>;
  }

  return (
    <>
      <div className="relative">
        <Table>
          <Table.Header
            headings={["Amount", "Credit Changed", "Order Ref", "Created On"]}
          />
          <Table.Body>
            {paginatedData.map((row) => (
              <Table.Row
                key={`beeLoyalTransactions-${row.id}`}
                onClick={() => {
                  history.push(
                    `/jobs/update/${encodeURIComponent(row.id ?? "")}`,
                  );
                }}
                styles={{
                  cursor: "pointer",
                }}
              >
                <Table.Cell.Currency price={row.amount} />
                <Table.Cell.Currency
                  price={row.creditChange}
                  styles={{
                    color:
                      row.creditChange > 0
                        ? theme.colors.positive.base
                        : theme.colors.negative.base,
                  }}
                />
                <Table.Cell.Text>{row.reference}</Table.Cell.Text>
                <Table.Cell.Text>
                  {dayjs(row.addedOn).format("DD/MM/YYYY")}
                </Table.Cell.Text>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {query.isFetching && (
          <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
            <Icon.Loading color="accent" />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center mt-10">
        <PaginationComponent />
      </div>
    </>
  );
};

export const BeeLoyalTransactions = ({
  email,
}: BeeLoyalTransactionsProps): React.ReactElement => {
  const [tableVisible, showTable] = useState(false);
  const [translate] = useTranslation("users");

  return (
    <>
      <Heading
        color="brand"
        level={2}
        fontSize="xlarge"
        styles={{ marginBottom: theme.spacing.medium }}
      >
        {translate("user.titles.beeLoyalTransactions")}
      </Heading>
      {!tableVisible && (
        <Button onClick={() => showTable(true)} size="small">
          {translate("user.buttons.loadTransactions")}
        </Button>
      )}
      {tableVisible && <ResultsTable email={email} />}
    </>
  );
};
