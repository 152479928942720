import React, { createContext, useEffect } from "react";
import Amplify, { Hub } from "aws-amplify";

import { useAuthHandler } from "../hooks";
import { IAuthContext } from "../hooks/useAuthHandler";

export const authContext = createContext({} as IAuthContext);

export const ProvideAuth = (props: { children?: React.ReactElement }) => {
  const { children } = props;
  const auth = useAuthHandler();
  const { setCurrentUser } = auth;

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId:
          process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_OIDC_OAUTH_DOMAIN,
          scope: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: process.env.REACT_APP_OIDC_OAUTH_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_OIDC_OAUTH_REDIRECT_SIGN_OUT,
          responseType: "code",
        },
      },
    });

    auth.setAmplifyConfigured(true);

    // listen to any authentication state changes
    Hub.listen("auth", () => {
      setCurrentUser();
    });

    // fire this for the first time
    // => we can't rely on the Hub listener until the auth state actually changes
    setCurrentUser();

    return () => Hub.remove("auth", setCurrentUser);
  }, []);

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
