import { CSSObject } from "@emotion/react";
import { theme } from "../../theme";

export type AllowedColorKeys = keyof Omit<typeof theme.colors, "focus">;

export const styles: Record<string, CSSObject> = {
  defaults: {
    margin: 0,
  },
  heading1: {
    ...theme.fontDefaults.xlarge4,
    fontWeight: "bold",
  },
  heading2: {
    ...theme.fontDefaults.xlarge3,
    fontWeight: "bold",
  },
  heading3: {
    ...theme.fontDefaults.xlarge2,
    fontWeight: "bold",
  },
  heading4: {
    ...theme.fontDefaults.xlarge,
    fontWeight: "bold",
  },
  heading5: {
    ...theme.fontDefaults.large,
    fontWeight: 600,
  },
  heading6: {
    ...theme.fontDefaults.base,
    fontWeight: "normal",
  },
};
