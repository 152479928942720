import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export type Color = Extract<
  keyof typeof theme.colors,
  "light" | "brand" | "accent" | "warning" | "greyscale"
>;

export const styles = StyleSheet({
  background: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: "0px",
    top: "0px",
    zIndex: theme.stack.modal,
    backgroundColor: "#000000AA",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: theme.stack.modal,
    textAlign: "center",
    borderRadius: theme.spacing.small,
    padding: theme.spacing.large,

    [`@media (min-width: ${theme.screens.medium})`]: {
      padding: `${theme.spacing.xlarge4} ${theme.spacing.xlarge2}`,
    },

    // overlay
    ":before": {
      content: "''",
      position: "fixed",
      top: "50%",
      left: "50%",
      width: "100vw",
      height: "100vh",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#000000",
      opacity: 0.5,
      zIndex: -1,
    },

    // modal background
    ":after": {
      content: "''",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: theme.spacing.small,
      zIndex: -1,
    },
  },
  close: {
    position: "absolute",
    top: theme.spacing.xlarge,
    right: theme.spacing.xlarge,
    paddingBottom: theme.spacing.xsmall2,
  },
  children: {
    paddingTop: theme.spacing.xsmall,
    paddingBottom: theme.spacing.xsmall,
  },
  actions: {
    paddingTop: theme.spacing.xsmall2,
    paddingBottom: theme.spacing.xsmall2,
  },
  actionChild: {
    paddingTop: theme.spacing.xsmall2,
    paddingBottom: theme.spacing.xsmall2,
  },
  light: {
    backgroundColor: theme.colors.light.base,
  },
  brand: {
    backgroundColor: theme.colors.brand.base,
    color: theme.colors.light.base,
  },
  accent: {
    backgroundColor: theme.colors.accent.base,
  },
  warning: {
    backgroundColor: theme.colors.warning.base,
  },
  greyscale: {
    backgroundColor: theme.colors.greyscale.base,
    color: theme.colors.light.base,
  },
});
