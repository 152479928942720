export type FilteredKeys<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];
export type QueryType = string | null | undefined;

export function getQueriedResults<T>(
  data: T[] | undefined,
  searchFor: string,
  fields: FilteredKeys<T, QueryType>[],
): T[] | undefined {
  return data?.filter((item: T) =>
    fields.some((field) =>
      (item[field] as unknown as QueryType)
        ?.toLowerCase()
        .includes(searchFor.toLowerCase()),
    ),
  );
}
