import React, { useState, useMemo, useEffect } from "react";
import {
  Input,
  InputSelectProps,
  InputTextProps,
  theme,
} from "@clearabee/ui-library";

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface DropdownLookupProps {
  onChange: (
    text: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<SelectOption[]>;
  debounce?: number;
  textProps?: Omit<InputTextProps, "ref">;
  selectProps?: Omit<InputSelectProps, "options" | "ref">;
}

export const DropdownLookup = ({
  onChange,
  debounce = 500,
  selectProps = {},
  textProps = {},
}: DropdownLookupProps): React.ReactElement => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [event, setEvent] = useState<React.ChangeEvent<HTMLInputElement>>();

  useEffect(() => {
    const run = async () => {
      if (!event) return;
      setLoading(true);
      let onChangeOptions: SelectOption[] = [];
      try {
        onChangeOptions = await onChange(event);
      } catch {}
      setOptions(onChangeOptions);
      setLoading(false);
    };
    let timeout: number;

    if (debounce) {
      timeout = window.setTimeout(run, debounce);
    } else {
      run();
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [event]);

  const selectComponent = useMemo(() => {
    return (
      <Input.Select
        {...selectProps}
        options={options}
        isLoading={isLoading || selectProps?.isLoading}
        reset={textProps.value === ""}
      />
    );
  }, [options, isLoading, selectProps]);

  return (
    <>
      <Input.Text
        {...textProps}
        styles={{ marginBottom: theme.spacing.small }}
        onChange={async (e) => {
          textProps?.onChange && textProps.onChange(e);
          setEvent(e);
        }}
      />
      {selectComponent}
    </>
  );
};
