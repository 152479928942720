import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Input } from "@clearabee/ui-library";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { readCompaniesForTables } from "../../../../api";

export const UserFiltersCompany = ({
  ...field
}: React.InputHTMLAttributes<HTMLSelectElement>): React.ReactElement => {
  const [translate] = useTranslation("users");
  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);

  // query
  const { isLoading, data } = useQuery(["readAllCompanies"], () =>
    readCompaniesForTables("", 0, 2000),
  );

  // select options
  const options = useMemo(() => {
    const allCompanies = data?.items;
    const currentUserCompanies = getCurrentUserCompanies();

    if (isClearabeeAdmin && !!allCompanies?.length)
      return allCompanies.map((company) => ({
        label: company.name,
        value: company.companyCode,
      }));

    if (isCompanyAdmin && !!currentUserCompanies?.length)
      return currentUserCompanies.map((company) => ({
        label: company.name,
        value: company.companyCode,
      }));

    return [];
  }, [isClearabeeAdmin, isCompanyAdmin, data?.items]);

  return (
    <Input.Select
      {...field}
      placeholder={translate("user.form.placeholders.searchValue")}
      options={options}
      disabled={isLoading}
      isLoading={isLoading}
      isSearchable
    />
  );
};
