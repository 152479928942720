/**
 * Import types.
 */
import axios from "axios";
import { PaymentLink } from "models";
import { IPaymentBody } from "../components/common/components/stripeElements/types";
import { generateQueryParams } from "./generic";
import { IPaginatedResults, TFilters } from "./types";

export const endpoints = {
  GET_PAYMENT_LINKS: (queryParams: string) =>
    `/catalogues/baskets/payment-links${queryParams}`,
  GET_PAYMENT_LINK: (token: string) =>
    `/catalogues/baskets/payment-links/${token}`,
  PATCH_PAYMENT_LINK: (token: string) =>
    `/catalogues/baskets/payment-links/${token}`,
};

/**
 * Submit basket to payment.
 * @param body Payments body.
 */
export const processPayment = async (body: IPaymentBody) => {
  try {
    const basket = await axios.post("/payments", body);
    return basket.data as any;
  } catch (error) {
    throw error;
  }
};

/**
 * Get payment order.
 * @param id Payment id.
 */
export const getPayment = async (id: string) => {
  try {
    const basket = await axios.get(`/payments/${id}`);
    return basket as any;
  } catch (error) {
    throw error;
  }
};

export const readPaymentLinks = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<IPaginatedResults<PaymentLink>> => {
  const queryParams = generateQueryParams(filters, currentPage, limit);
  const { data } = await axios.get(endpoints.GET_PAYMENT_LINKS(queryParams));
  return data;
};

export const getPaymentLink = async (token: string): Promise<PaymentLink> => {
  const { data } = await axios.get(endpoints.GET_PAYMENT_LINK(token));
  return data;
};

export const patchPaymentLink = async (
  token: string,
  patch: Partial<PaymentLink>,
): Promise<PaymentLink> => {
  const res = await axios.patch(endpoints.PATCH_PAYMENT_LINK(token), patch);
  return res.data;
};
