import React from "react";
import { FieldLabel, Pill, Box, theme } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";

interface AdditionalInfoLineProps {
  label: string;
  children?: React.ReactNode;
}

export const AdditionalInfoLine = ({
  label,
  children,
}: AdditionalInfoLineProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  return (
    <FieldLabel label={label} key={label}>
      <Box styles={{ ...theme.fontDefaults.small }}>{children}</Box>
      {!children && (
        <Pill color="negative" size="small" className="mt-1 inline-block">
          {translate("noData")}
        </Pill>
      )}
    </FieldLabel>
  );
};
