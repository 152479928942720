"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hwa = void 0;
const components_1 = require("./components");
const screens_1 = require("./screens");
const errors_1 = require("./errors");
exports.hwa = {
    components: components_1.components,
    screens: screens_1.screens,
    errors: errors_1.errors,
};
