import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

/**
 * Import subcomponents.
 */
import { LoadingSpinner, Transition } from "../../core";
import { Icon } from "@clearabee/ui-library";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const FormLoader: React.FC<{ isLoading?: boolean; overlay?: boolean }> =
  ({ isLoading = true, overlay = false }) => {
    const [t] = useTranslation("common");
    const [loader, setLoader] = useState(false);

    /**
     * Custom loader timeouts.
     */
    useEffect(() => {
      let timeout: any;
      if (loader && !isLoading) {
        timeout = setTimeout(() => {
          setLoader(false);
        }, 500);
      } else if (!loader && isLoading) {
        setLoader(true);
      }
      return () => clearTimeout(timeout);
    }, [isLoading, loader]);

    return (
      <Transition toggle={loader}>
        <div
          className={cx(
            "absolute top-0 right-0 bottom-0 left-0 opacity-50 z-50",
            {
              "bg-white opacity-50": overlay,
            },
          )}
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <div className="loading-wrap relative">
            <Icon.Loading color="brand" size="xlarge" className="mx-auto" />
          </div>
          <div className="render-loading-text text-lg text-primary font-semibold text-center mt-5">
            {t("loading2")}
          </div>
        </div>
      </Transition>
    );
  };
