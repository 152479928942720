import { theme } from "../../../../theme";
import { StyleSheet } from "../../../../utils";

export const styles = StyleSheet({
  defaults: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  label: {
    paddingLeft: theme.spacing.xsmall,
  },
  input: {
    opacity: 0,
    width: 0,
    height: 0,

    ":checked + .radio-control": {
      backgroundColor: theme.colors.brand.base,
    },
    ":focus + .radio-control": {
      borderColor: theme.colors.focus.base,
    },
    ":disabled ~ span": {
      cursor: "not-allowed",
      color: theme.colors.greyscale.lighter,
      borderColor: theme.colors.greyscale.lightest,
    },
  },
  radioControl: {
    backgroundColor: theme.colors.light.base,
    width: theme.spacing.medium,
    height: theme.spacing.medium,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
    borderRadius: "100%",
    boxShadow: `0px 0px 0px 2px ${theme.colors.light.base} inset`,
    outline: "none",
  },
  error: {
    "> .radio-control": {
      borderColor: theme.colors.negative.base,
    },
  },
  success: {
    "> .radio-control": {
      borderColor: theme.colors.positive.base,
    },
  },
  inverted: {
    input: {
      ":checked + .radio-control": {
        backgroundColor: theme.colors.light.base,
        borderColor: theme.colors.light.base,
      },
    },
    "> .radio-control": {
      borderColor: theme.colors.light.base,
      backgroundColor: theme.colors.brand.base,
      boxShadow: `0px 0px 0px 2px ${theme.colors.brand.base} inset`,
    },
  },
});
