import axios from "axios";
import React, {
  createContext,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import { useQuery } from "react-query";

/**
 * Import API.
 */
import { getCatalogueByCompanyCode } from "../api";

/**
 * Import Helpers.
 */
import {
  filterCategoriesFromResults,
  filterItemFromResults,
} from "../helpers/catalogue";
import { ICatalogueTree } from "../models/catalogue";

/**
 * Import Models.
 */
import { CatalogueItem } from "../models/catalogueItem";
import { Category } from "../models/category";
import { Item } from "../models/item";

export interface ICatalogueContext {
  catalogueId: string;
  items: Array<Item> | null;
  categories: Array<Category> | null;
  catalogueItems: Array<CatalogueItem> | null;
  setPostcode: (postcode: string) => void;
  setCompanyCode: (companyCode: string) => void;
  isLoading: boolean;
  isFallback: boolean;
}

const initContextState: ICatalogueContext = {
  catalogueId: "",
  isLoading: false,
  items: null,
  categories: null,
  catalogueItems: null,
  isFallback: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPostcode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompanyCode: () => {},
};

export const catalogueContext = createContext(initContextState);

/**
 * Get fallback catalogue
 */
const getFallbackCatalogue = async (
  postcode: string | null,
): Promise<ICatalogueTree> => {
  const { data } = await axios.get("/catalogues/PORTAL", {
    params: { postcode },
  });
  return data;
};

interface IFetchCatalogueResult {
  catalogue: ICatalogueTree | null;
  isFallback: boolean;
}

/**
 * Fetch catalogue either by company id or default.
 */
const fetchCatalogue = async (
  companyCode: string | null,
  postcode: string | null,
): Promise<IFetchCatalogueResult> => {
  try {
    let catalogue: ICatalogueTree | null;
    if (companyCode) {
      // Check if company has catalogue and default to portal catalogue otherwise
      catalogue = await getCatalogueByCompanyCode(companyCode);

      if (catalogue) return { catalogue, isFallback: false };

      catalogue = await getFallbackCatalogue(postcode);
      // Fallback catalogue
      return {
        catalogue,
        isFallback: true,
      };
    }
    catalogue = await getFallbackCatalogue(postcode);
    // Fallback catalogue
    return {
      catalogue,
      isFallback: true,
    };
  } catch (error) {
    throw error;
  }
};

interface IProvideCatalogue {
  catalogueId: string;
  postcode: string | null;
  companyCode: string | null;
  children?: React.ReactNode;
}

interface IProvideCatalogueState extends IProvideCatalogue {
  items: Item[];
  catalogueItems: CatalogueItem[];
  categories: Category[];
  isFallback: boolean;
}

/**
 * Simple object override reducer.
 */
const reducer: React.Reducer<
  IProvideCatalogueState,
  Partial<IProvideCatalogueState>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = (prevState: any, newState: any) => ({
  ...prevState,
  ...newState,
});

export const ProvideCatalogue: React.FC<IProvideCatalogue> = ({
  catalogueId: initCatalogueId,
  postcode: initPostcode,
  companyCode: initCompanyCode,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    catalogueId: initCatalogueId,
    isFallback: true,
    postcode: initPostcode,
    companyCode: initCompanyCode,
    items: [],
    catalogueItems: [],
    categories: [],
  });
  const {
    catalogueId,
    postcode,
    companyCode,
    items,
    catalogueItems,
    categories,
    isFallback,
  } = state;

  /**
   * Fetch catalogue data query.
   */
  const { data, isLoading } = useQuery(
    ["fetchCatalogue", companyCode, postcode],
    () => fetchCatalogue(companyCode, postcode),
    {
      enabled: catalogueId !== "",
      retry: 1,
    },
  );

  const catalogue = data?.catalogue;

  /**
   * Set new postcode callback.
   */
  const setPostcode = useCallback(
    (postcode: string) => dispatch({ postcode }),
    [],
  );

  const setCompanyCode = useCallback(
    (companyCode: string) => dispatch({ companyCode }),
    [],
  );

  useEffect(() => {
    if (typeof data !== "undefined") {
      const items = filterItemFromResults(catalogue?.items || []);
      const categories = filterCategoriesFromResults(catalogue?.items || []);

      dispatch({
        items,
        catalogueItems: catalogue?.items,
        categories,
        catalogueId: catalogue?.id,
        isFallback: data.isFallback,
      });
    } else {
      // if error returned by get catalogue api, then show fallback
      dispatch({
        isFallback: true,
      });
    }
  }, [data]);

  return (
    <catalogueContext.Provider
      value={{
        catalogueId,
        items,
        categories,
        catalogueItems,
        isFallback,
        setPostcode,
        setCompanyCode,
        isLoading,
      }}
    >
      {children}
    </catalogueContext.Provider>
  );
};
