import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Panel, Heading } from "@clearabee/ui-library";
import { BackButton } from "../common/components";
import { CreateUpdateSubContractorForm } from "./components/createUpdateSubcontactorForm";

export const CreateSubContractor = (): React.ReactElement => {
  const [translate] = useTranslation("users");
  const history = useHistory();

  return (
    <>
      <div className="center-screen-form md:absolute md:max-w-screen-sm xl:max-w-screen-lg min-w-full md:min-w-screen-md xl:min-w-screen-lg sm:px-4 py-5">
        <div className="mb-10 sm:mt-24 2xl:mt-0">
          <BackButton
            text={translate("subcontractors.buttons.returnToSubcontractors")}
            onClick={() => history.push("/subcontractors")}
          />
        </div>
        <CreateUpdateSubContractorForm />
      </div>
    </>
  );
};
