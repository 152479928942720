import React, { useState, useMemo, useRef } from "react";
import { useParams, useHistory } from "react-router";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import {
  Form,
  Input,
  Button,
  Text,
  Icon,
  Modal,
  Box,
  UnstyledButton,
} from "@clearabee/ui-library";
import {
  toasts,
  getInitialValues,
  sortQuestionAnswersByWorksheet,
  getQuestionIdToAnswerIdMap,
  getWorksheetDataPostBody,
} from "helpers";
import { JobDetails, WorksheetsQuestions } from "./components";
import { styles } from "./approvePending.styles";

type Job = Awaited<ReturnType<typeof instance.jobs.getJob>>["data"];

export const ApprovePendingJob = (): React.ReactElement => {
  const history = useHistory();
  const [translate] = useTranslation("jobs");
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const notes = useRef("");
  const { id } = useParams<{ id: `${Required<Job>["id"]}` }>();

  const { data: job, isLoading: isLoadingJob } = useQuery(
    ["getJob", id],
    async () => {
      const { data } = await instance.jobs.getJob(id);
      return data;
    },
  );

  const { ref, date, description, status } = job || {};

  const getWorksheetQuestionAnswers = async () => {
    const { data } = await instance.worksheets.getJobWorksheetData(
      encodeURIComponent(job?.ref || ""),
    );
    return data;
  };

  const { data, isLoading: isLoadingWorksheetAnswers } = useQuery(
    ["getWorksheetAnswers", id],
    () => getWorksheetQuestionAnswers(),
    {
      enabled: Boolean(job),
      cacheTime: 0,
      staleTime: 0,
      onError() {
        toasts.error({ message: translate("toasts.error") });
      },
    },
  );

  const questionIdToAnswerIdMap = useMemo(() => {
    if (!data) return {};

    return getQuestionIdToAnswerIdMap(data);
  }, [JSON.stringify(data)]);

  const { mutate: updateAnswers, isLoading: isUpdatingAnswers } = useMutation(
    async (values: Record<string, string | number>) => {
      if (!job) return;

      setEditing(false);

      const updatedWorksheetAnswers = getWorksheetDataPostBody(
        job,
        values,
        questionIdToAnswerIdMap,
      );

      await instance.worksheets.postWorksheetData(updatedWorksheetAnswers);
    },
    {
      onError: (error) => {
        toasts.error({ message: translate("toasts.error") });
        setEditing(true);
      },
      onSuccess: () => {
        toasts.success({ message: translate("toasts.answersUpdated") });
      },
    },
  );

  const { mutate: approveJob, isLoading: isApprovingJob } = useMutation(
    async () => {
      await instance.jobs.patchBulkStatus([
        {
          bcId: String((job as any).bcId), // bcId is returned as a number, but typed as a string
          statusId: 12, // Completed
          notes: notes.current,
        },
      ]);
    },
    {
      onSuccess() {
        toasts.success({ message: translate("toasts.jobApproved") });

        // Redirect back to pending-jobs page
        history.push({
          pathname: "/jobs/pending",
        });
      },
      onError() {
        toasts.error({ message: translate("toasts.error") });
      },
    },
  );

  return (
    <>
      {(!data ||
        isLoadingJob ||
        isLoadingWorksheetAnswers ||
        isUpdatingAnswers ||
        !job ||
        isApprovingJob) && <Icon.Loading color="brand" />}
      {!!job && (
        <Box className="mb-6 mr-48 pr-2">
          <JobDetails
            status={status || "Loading"}
            jobReference={ref || ""}
            collectionDate={date || ""}
            description={description || "No description"}
            data={job}
          />
        </Box>
      )}
      {data && !!data.length && (
        <Form
          initialValues={getInitialValues(data)}
          onSubmit={(values) => {
            updateAnswers(values);
          }}
        >
          {({ resetForm }) => (
            <Box style={styles.container}>
              {/* BUTTONS */}
              <Box style={styles.editButtonContainer}>
                {!editing && !isUpdatingAnswers && !isApprovingJob && (
                  <>
                    {/* APPROVE */}
                    <Button
                      type="button"
                      size="small"
                      variant="outline"
                      onClick={() => setShowModal(true)}
                      color={"positive"}
                      styles={
                        editing ? styles.buttonHidden : styles.approveButton
                      }
                    >
                      {translate("buttons.labels.approve")}
                    </Button>

                    {/* EDIT */}
                    <Button
                      type="button"
                      size="small"
                      variant="outline"
                      onClick={() => setEditing(true)}
                      color={"warning"}
                      style={editing ? styles.buttonHidden : undefined}
                    >
                      {translate("buttons.labels.edit")}
                    </Button>
                  </>
                )}

                {(editing || isUpdatingAnswers) && (
                  <Box>
                    {/* SAVE */}
                    <Button
                      type="submit"
                      name="save"
                      size="small"
                      variant="outline"
                      color="positive"
                      disabled={isUpdatingAnswers}
                      styles={styles.saveButton}
                    >
                      {translate("buttons.labels.save")}
                    </Button>

                    {/* CANCEL */}
                    <Button
                      type="button"
                      name="cancel"
                      size="small"
                      variant="outline"
                      color="negative"
                      disabled={isUpdatingAnswers}
                      onClick={() => {
                        resetForm();
                        setEditing(false);
                      }}
                    >
                      {translate("buttons.labels.cancel")}
                    </Button>
                  </Box>
                )}
              </Box>

              {/* WORKSHEETS WITH QUESTIONS AND ANSWERS */}
              <Box className="w-full">
                {sortQuestionAnswersByWorksheet(data).map(
                  (worksheet, index) => (
                    <WorksheetsQuestions
                      editing={editing}
                      worksheet={worksheet}
                      worksheetIndex={index}
                      collapsed={worksheet.worksheetName === "5. Waste"}
                    />
                  ),
                )}
              </Box>
            </Box>
          )}
        </Form>
      )}

      {/* APPROVAL MODAL */}
      {showModal && !isApprovingJob && (
        <Modal width={700}>
          <Text styles={[styles.imageLabel]}>{`Notes (optional):`}</Text>
          <Input.Textarea
            onChange={({ target: { value } }) => (notes.current = value)}
            styles={styles.modalInput}
          />
          <Box style={styles.modalButtonContainer}>
            <Button size="medium" color="positive" onClick={() => approveJob()}>
              {translate("buttons.labels.approve")}
            </Button>
            <UnstyledButton
              styles={styles.modalCancelButton}
              onClick={() => setShowModal(false)}
            >
              {translate("buttons.labels.cancel")}
            </UnstyledButton>
          </Box>
        </Modal>
      )}
    </>
  );
};
