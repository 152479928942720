import * as Yup from "yup";
import {
  stringRequired,
  email,
  postcodeRegExp,
  validPostcode,
  phoneRegExp,
  validPhone,
  fieldRequired,
} from "../../validation/common";

//LORENZO - some things commented out here need to be commented back in
export interface ICreateUserFormState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roles: { label: string; value: string }[];
  company?: { label: string; value: string }[];
  // isSupplier: boolean;
  // supplier?: string;
}

const optionObject = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string(),
});

export const getCreateUserValidation = (): // isSupplier: boolean,
Yup.ObjectSchema =>
  Yup.object().shape({
    firstName: stringRequired,
    lastName: stringRequired,
    email: email,
    phoneNumber: Yup.string().matches(phoneRegExp, validPhone),
    company: Yup.array().of(optionObject).min(1).required(fieldRequired),
    // supplier: isSupplier ? stringRequired : Yup.string(),
    roles: Yup.array().of(optionObject).min(1).required(fieldRequired),
    // isSupplier: Yup.boolean(),
  });

export const createUserInitialValues: ICreateUserFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  roles: [],
  company: [],
  // supplier: "",
  // isSupplier: false,
};

export const updateUser = Yup.object().shape(
  {
    firstName: stringRequired,
    lastName: stringRequired,
    email: email,
    company: Yup.array().when("roles", {
      is: (roles) => !!roles && !!roles.length,
      then: Yup.array().of(optionObject).min(1).required(fieldRequired),
      otherwise: Yup.array().of(optionObject),
    }),
    companies: Yup.array().of(Yup.number()),
    phoneNumber: Yup.string().matches(phoneRegExp, validPhone).nullable(),
    roles: Yup.array().when("company", {
      is: (company) => !!company.length,
      then: Yup.array().of(optionObject).min(1).required(fieldRequired),
      otherwise: Yup.array().of(optionObject),
    }),
    attributes: Yup.array().of(
      Yup.object().shape({
        userId: Yup.number(),
        attrKey: Yup.string(),
        attrValue: Yup.string(),
      }),
    ),
  },
  [["company", "roles"]],
);

export const createSubcontractor = Yup.object().shape({
  phone: Yup.string().matches(phoneRegExp, validPhone),
  wc_license: stringRequired,
  email: email,
  password: stringRequired,
  company: Yup.string(),
  postcode: Yup.string().matches(postcodeRegExp, validPostcode).required(),
});

export const updateSubcontractor = Yup.object().shape({
  username: Yup.string(),
  email: Yup.string(),
  wc_license: Yup.string(),
  phone: Yup.string(),
  company: Yup.string(),
  postcode: Yup.string(),
  status: Yup.string(),
  on_stop: Yup.boolean(),
});
