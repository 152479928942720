import React, { useState, useContext, useMemo } from "react";
import ReactDOM from "react-dom";
import { Icon, Tabs, Text, theme } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import {
  ItemDropPanel,
  CompanySelection,
  AssignedCompanies,
  CatalogueEditorContext,
} from "../components";
import { sideBarWidth } from "./sideBar";
import { styles } from "./styles";
import { Global } from "@emotion/react";

interface WrapperProps {
  children: React.ReactNode;
}

interface CatalogueTabsProps {
  isLoading?: boolean;
}

export const CatalogueTabs = ({
  isLoading,
}: CatalogueTabsProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [fullScreen, setFullScreen] = useState(false);
  const { isLoading: isCatalogueEditorLoading, catalogue } = useContext(
    CatalogueEditorContext,
  );
  const [tab, setTab] = useState<string>(
    translate("catalogueEditing.tabs.products"),
  );

  const Wrapper = useMemo(
    () =>
      fullScreen
        ? ({ children }: WrapperProps) =>
            ReactDOM.createPortal(children, document.body)
        : React.Fragment,
    [fullScreen],
  );

  return (
    <Wrapper>
      {/* force page content to disapear when in full screen */}
      {fullScreen && (
        <Global
          styles={{
            ".portal-container": styles.portalContainer,
          }}
        />
      )}
      <div
        className={cx(fullScreen ? "bg-gray-200" : "mt-8", "relative")}
        style={
          fullScreen
            ? {
                marginLeft: sideBarWidth,
                width: `calc(100vw - ${sideBarWidth})`,
              }
            : {}
        }
      >
        <div className={cx(fullScreen && "p-3")}>
          <div
            className="hidden 2xl:inline-flex items-center mb-5"
            onClick={() =>
              setFullScreen(!isCatalogueEditorLoading && !fullScreen)
            }
            css={{
              ...styles.fullScreenButton,
              cursor: isCatalogueEditorLoading ? "" : "pointer",

              ":hover": {
                color: isCatalogueEditorLoading ? "" : theme.colors.brand.base,
              },
            }}
          >
            <Text color="dark" fontSize="small">
              {translate(
                fullScreen
                  ? "catalogueEditing.exitFullScreen"
                  : "catalogueEditing.enterFullScreen",
              )}
            </Text>
            {fullScreen ? (
              <Icon.Close className="ml-2" size="small" />
            ) : (
              <Icon.Fullscreen className="ml-2" size="medium" />
            )}
          </div>

          <Tabs
            defaultTab={tab}
            onTabChange={setTab}
            panelProps={{
              styles: {
                [`@media (max-width: ${theme.screens.medium})`]: {
                  padding: theme.spacing.medium,
                },
              },
            }}
          >
            <Tabs.Item
              label={translate("catalogueEditing.tabs.products")}
              styles={{
                ...styles.scrollable2,
                maxHeight: `calc(100vh - 140px)`,
                height: isCatalogueEditorLoading ? `calc(100vh - 580px)` : "",
                marginTop: theme.spacing.xlarge,
                [`@media (min-width: ${theme.screens.medium})`]: {
                  marginTop: 0,
                  paddingTop: theme.spacing.medium,
                  height: !fullScreen
                    ? `calc(100vh - 530px)`
                    : `calc(100vh - 170px)`,
                },
              }}
            >
              <ItemDropPanel />
            </Tabs.Item>
            <Tabs.Item
              label={translate("catalogueEditing.tabs.companies")}
              styles={{
                [`@media (min-width: ${theme.screens.medium})`]: {
                  marginTop: 0,
                  paddingTop: theme.spacing.medium,
                  maxHeight: !fullScreen ? "" : `calc(100vh - 170px)`,
                },
              }}
            >
              <CompanySelection />
            </Tabs.Item>
            {!!catalogue?.companies.length && (
              <Tabs.Item
                label={translate("catalogueEditing.tabs.assignedCompanies")}
                styles={{
                  [`@media (min-width: ${theme.screens.medium})`]: {
                    marginTop: 0,
                    paddingTop: theme.spacing.medium,
                    maxHeight: !fullScreen ? "" : `calc(100vh - 170px)`,
                  },
                }}
              >
                <AssignedCompanies />
              </Tabs.Item>
            )}
          </Tabs>
        </div>
        {(isCatalogueEditorLoading || isLoading) && (
          <div className="bg-white opacity-50 absolute inset-0"></div>
        )}
      </div>
    </Wrapper>
  );
};
