import React from "react";
import { Form, Field, Button, Input } from "@clearabee/ui-library";
import { TFilters } from "../../../../api/types";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "images";

interface CompanyFilters {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
}

export const CompanyFilters = ({
  isFetching,
  updateFilters,
}: CompanyFilters): React.ReactElement => {
  const [translate] = useTranslation("companies");
  const handleSubmit = ({ field, operator, searchValue }: FormikValues) => {
    updateFilters(
      `${field}:${operator}=${
        operator === "likeLower" && encodeURIComponent("%")
      }${searchValue}${operator === "likeLower" && encodeURIComponent("%")}`,
    );
  };

  const fieldOptions = [
    { value: "name", label: translate("filters.optionLabels.name") },
    {
      value: "contactEmail",
      label: translate("filters.optionLabels.contactEmail"),
    },
    {
      value: "companyCode",
      label: translate("filters.optionLabels.companyCode"),
    },
  ];

  const operatorOptions = [
    { value: "likeLower", label: translate("filters.optionLabels.contains") },
    { value: "eq", label: translate("filters.optionLabels.equals") },
  ];

  const initialValues = {
    field: fieldOptions[0].value,
    operator: operatorOptions[0].value,
    searchValue: "",
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, dirty }) => (
        <div className="border-t border-grey-200 pt-5 mt-4">
          <div className="flex flex-col items-center md:flex-row -mx-2">
            <div className="w-full md:w-1/4 px-2 mb-3 md:mb-0">
              <Field
                name="field"
                label={translate("filters.form.placeholder.field")}
                styles={{
                  margin: 0,
                }}
              >
                {({ field }) => (
                  <Input.Select options={fieldOptions} {...field} />
                )}
              </Field>
            </div>
            <div className="w-full md:w-1/4 px-2 mb-3 md:mb-0">
              <Field
                name="operator"
                label={translate("filters.form.placeholder.operator")}
                styles={{
                  margin: 0,
                }}
              >
                {({ field }) => (
                  <Input.Select
                    options={operatorOptions}
                    placeholder={translate("filters.form.placeholder.operator")}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:w-1/4 px-2 mt-6 mb-3 md:mb-0">
              <Field
                name="searchValue"
                styles={{
                  margin: 0,
                }}
              >
                {({ field }) => <Input.Text placeholder="..." {...field} />}
              </Field>
            </div>
            <div className="flex flex-1 px-2 -mx-2 pt-6 md:mt-0">
              <div className="w-1/2 px-2">
                <Button
                  type="submit"
                  disabled={isFetching || !values.field || !values.operator}
                  size="small"
                  color="accent"
                  className="w-full flex justify-center items-center"
                >
                  <SearchIcon className="mr-2" />
                  {translate("common:form.buttons.search")}
                </Button>
              </div>
              <div className="w-1/2 px-2">
                <Button
                  type="reset"
                  disabled={!dirty}
                  size="small"
                  variant="outline"
                  color="negative"
                  onClick={() => updateFilters("")}
                >
                  {translate("common:form.buttons.reset")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
