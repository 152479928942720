"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterBar = void 0;
exports.filterBar = {
    filters: {
        vehicles: "Vehicles",
        jobs: "Jobs",
        wasteTransferSites: "Waste Transfer Sites",
        shellFuelStations: "Shell Fuel Stations",
        maintenance: "Maintenance",
        activeDrivers: "Active Drivers",
        clear: "Clear",
        clearFilters: "Clear All Filters",
        clearVehicleFilters: "Clear Vehicle Filters",
        clearReg: "Clear Reg",
        submit: "Submit",
        applyChanges: "Apply Changes",
    },
};
