"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quote = void 0;
exports.quote = {
    accessInformation: "Access Information",
    binSize: "Bin Size",
    collectionFrequency: "Collection Frequency",
    estimatedVolume: "Estimated Volume (Cubic Yards)",
    placeholders: {
        accessInformation: "Enter premies access information",
        binSize: "Select bin size",
        collectionDate: "Select collection date",
        collectionFrequency: "Select frequency",
        deliveryDate: "Select delivery date",
        email: "Your email here",
        estimatedVolume: "Select estimated volume",
        name: "Your name here",
        telephone: "Your telephone number here",
        tradeWasteStreams: "Select trade waste streams",
        select: "Select...",
        skipLocation: "Select location",
        skipSize: "Select skip size",
        wasteDetails: "Please describe your waste...",
        wasteStream: "Select waste stream",
        wasteType: "Select waste type",
    },
    skipLocation: "Skip Location",
    tradeWasteStreams: "Trade Waste Streams",
    wasteStream: "Waste Stream",
};
