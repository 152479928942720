import { CSSObject } from "@emotion/react";
import { theme } from "../../theme";

export type AllowedColorKeys = keyof Omit<typeof theme.colors, "focus">;

export const styles: Record<string, CSSObject> = {
  defaults: {
    margin: 0,
  },
};
