import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export type AllowedColorKeys = keyof Omit<
  typeof theme.colors,
  "light" | "dark" | "focus"
>;

export type AllowedSizeKeys = keyof Pick<
  typeof theme.spacing,
  "xsmall" | "small" | "medium" | "large"
>;

const buttonColorCombinations = {
  brand: {
    text: "light",
    main: "base",
    hover: "dark",
    disabled: "lighter",
  },
  accent: {
    text: "dark",
    main: "base",
    hover: "darker",
    disabled: "lighter",
  },
  warning: {
    text: "dark",
    main: "base",
    hover: "dark",
    disabled: "lighter",
  },
  negative: {
    text: "light",
    main: "base",
    hover: "dark",
    disabled: "lighter",
  },
  usp: {
    text: "light",
    main: "base",
    hover: "dark",
    disabled: "lighter",
  },
  greyscale: {
    text: "light",
    main: "dark",
    hover: "darkest",
    disabled: "lighter",
  },
  positive: {
    text: "light",
    main: "base",
    hover: "dark",
    disabled: "lighter",
  },
};

/**
 * Default button styles - we include a border on all buttons so that internal spacing,
 * dimensions etc are always consistent
 */
export const defaultStyle = {
  fontWeight: 600,
  borderWidth: "2px",
  borderStyle: "solid",
  transition: "300ms",
  cursor: "pointer",
  textDecoration: "none",

  "&:hover": {
    transition: "300ms",
  },

  "&:focus": {
    color: theme.colors.light.base,
    borderColor: theme.colors.light.base,
    background: theme.colors.focus.base,
    transition: "300ms",
  },
};

/**
 * Styles for default fill variant, generated from above combinations
 */
export const styles = StyleSheet({
  ...Object.assign(
    {},
    ...Object.entries(buttonColorCombinations).map((input) => {
      // Casting required due to limited inference on Object.entries()
      const [accessor, colors] = input as unknown as [
        AllowedColorKeys,
        Record<
          keyof typeof buttonColorCombinations[AllowedColorKeys],
          keyof typeof theme.colors[AllowedColorKeys]
        >,
      ];

      return {
        [accessor]: {
          color: theme.colors[colors.text as "light" | "dark"].base,
          borderColor: theme.colors[accessor][colors.main],
          background: theme.colors[accessor][colors.main],

          "&:hover:not(:disabled), &:hover:not(.disabled)": {
            background: theme.colors[accessor][colors.hover],
            borderColor: theme.colors[accessor][colors.hover],
          },
          "&:disabled, &.disabled": {
            cursor: "not-allowed",
            background: theme.colors[accessor][colors.disabled],
            borderColor: theme.colors[accessor][colors.disabled],
          },
        },
      };
    }),
  ),
  light: {
    color: theme.colors.dark.base,
    borderColor: theme.colors.light.base,
    background: theme.colors.light.base,

    "&:hover:not(:disabled), &:hover:not(.disabled)": {
      background: theme.colors.greyscale.light,
      borderColor: theme.colors.greyscale.light,
    },
  },
});

/**
 * Styles for outline variant, generated from above combinations
 */
export const outlineStyles = StyleSheet({
  ...Object.assign(
    {},
    ...Object.entries(buttonColorCombinations).map((input) => {
      // Casting required due to limited inference on Object.entries()
      const [accessor, colors] = input as unknown as [
        AllowedColorKeys,
        Record<
          keyof typeof buttonColorCombinations[AllowedColorKeys],
          keyof typeof theme.colors[AllowedColorKeys]
        >,
      ];

      return {
        [accessor]: {
          color: theme.colors.dark.base,
          borderColor: theme.colors[accessor][colors.main],
          background: "transparent",

          "&:hover:not(:disabled)": {
            color: theme.colors[colors.text as "light" | "dark"].base,
            background: theme.colors[accessor][colors.main],
            borderColor: theme.colors[accessor][colors.main],
          },
        },
      };
    }),
  ),
  light: {
    color: theme.colors.light.base,
    borderColor: theme.colors.light.base,
    background: "transparent",

    "&:hover:not(:disabled)": {
      color: theme.colors.dark.base,
      background: theme.colors.light.base,
    },
  },
});

export const sizeStyles = StyleSheet({
  xsmall: {
    ...theme.fontDefaults.xsmall,
    minWidth: theme.spacing.xlarge2,
    paddingRight: theme.spacing.xsmall,
    paddingLeft: theme.spacing.xsmall,
    paddingTop: theme.spacing.xsmall2,
    paddingBottom: theme.spacing.xsmall2,
    borderRadius: theme.spacing.medium,
  },
  small: {
    ...theme.fontDefaults.xsmall,
    minWidth: "100px",
    paddingRight: theme.spacing.small,
    paddingLeft: theme.spacing.small,
    paddingTop: 9,
    paddingBottom: 9,
    borderRadius: theme.spacing.medium,
  },
  medium: {
    ...theme.fontDefaults.xsmall,
    minWidth: "120px",
    padding: theme.spacing.small,
    borderRadius: theme.spacing.large,
  },
  large: {
    ...theme.fontDefaults.base,
    minWidth: "170px",
    padding: theme.spacing.medium,
    borderRadius: theme.spacing.xlarge,
  },
});
