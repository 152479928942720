import React, { useState } from "react";
import {
  Text,
  Heading,
  Button,
  Table,
  theme,
  useModal,
  Modal,
  Loader,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Link } from "react-router-dom";
import { toasts } from "helpers";
import { useInstance } from "api/dsa";
import { users as usersInstance } from "api/dsa/resources/users";

type TemporaryPasswordData = Parameters<
  ReturnType<typeof usersInstance>["setTemporaryPassword"]
>[0];

export const ReadSubContractorChildren = (): React.ReactElement => {
  const api = useInstance();
  const { username } = useParams<{ username: string }>();
  const [translate] = useTranslation("users");
  const [childContractor, setChildContractor] = useState("");
  const [showNoChildrenModal, setShowNoChildrenModal] = useState(true);
  const [DeleteModal, showDeleteModal] = useModal();
  const [SuccessModal, showSuccessModal] = useModal();
  const [LoadingModal] = useModal({ defaultVisible: true });

  const {
    data: result,
    refetch: refetchChildren,
    isLoading: isLoadingChildren,
  } = useQuery(
    `${username}children`,
    () => api.users.listChildren(username || ""),
    { enabled: !!username },
  );

  const { mutate: deleteUser, isLoading: isDeleting } = useMutation(
    (username: string) => api.users.delete(username),
    {
      onSuccess: () => {
        refetchChildren();
        setChildContractor("");
        showDeleteModal(false);
      },
      onError: (error) => {
        toasts.error({ message: (error as Error).message });
      },
    },
  );

  const { mutate: setTemporaryPassword, isLoading: isResettingPassword } =
    useMutation(
      (user: TemporaryPasswordData) => api.users.setTemporaryPassword(user),
      {
        onSuccess: () => showSuccessModal(true),
        onError: (error) => {
          toasts.error({ message: (error as Error).message });
        },
      },
    );

  const isLoading = isLoadingChildren || isDeleting || isResettingPassword;

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      {!isLoadingChildren && (!result || result.data.length < 1) ? (
        <>
          {showNoChildrenModal && (
            <Modal onClose={() => setShowNoChildrenModal(false)}>
              <Text fontSize="large">
                {translate("subcontractors.messages.noSubUsers")}
              </Text>
              <div className="mt-10">
                <Link to="/subcontractors">
                  <Button size="medium" color="accent">
                    {translate("subcontractors.buttons.returnToSubcontractors")}
                  </Button>
                </Link>
              </div>
            </Modal>
          )}
        </>
      ) : (
        <Table
          styles={{
            marginBottom: theme.spacing.large,
            "th:first-of-type > p, td:first-of-type > span": {
              marginLeft: theme.spacing.small,
            },
          }}
        >
          <Table.Header
            headings={[
              "Company Name",
              "Email",
              "Waste Carrier License",
              "Phone",
              "Status",
              "Actions",
            ]}
          />

          <Table.Body>
            {(result?.data || []).map((contractor) => (
              <Table.Row key={`table-row-${contractor.email}`}>
                <Table.Cell.Text>{contractor.company}</Table.Cell.Text>
                <Table.Cell.Text>{contractor.email}</Table.Cell.Text>
                <Table.Cell.Text>{contractor.wc_license}</Table.Cell.Text>
                <Table.Cell.Text>{contractor.phone}</Table.Cell.Text>
                <Table.Cell.Text>{contractor.status}</Table.Cell.Text>
                <Table.Cell styles={{ display: "flex" }}>
                  <Button
                    size="small"
                    color="brand"
                    as="button"
                    className="mr-2"
                    disabled={isResettingPassword}
                    onClick={() =>
                      setTemporaryPassword({ username: contractor.username })
                    }
                  >
                    {translate("subcontractors.resetPassword")}
                  </Button>
                  <Button
                    size="small"
                    color="negative"
                    as="button"
                    onClick={() => {
                      setChildContractor(contractor.username);
                      showDeleteModal(true);
                    }}
                  >
                    {translate("subcontractors.delete")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}

      <DeleteModal width={500} onClose={() => showDeleteModal(false)}>
        <div className="mb-4">
          <Text>Are you sure?</Text>
        </div>
        <Button
          size="medium"
          color="negative"
          as="button"
          onClick={() => deleteUser(childContractor)}
          disabled={isDeleting}
        >
          {translate("subcontractors.delete")}
        </Button>
      </DeleteModal>

      <SuccessModal width={500} onClose={() => showSuccessModal(false)}>
        <Heading level={3} className="pb-2">
          Success!
        </Heading>
        <Text>The requested action has been completed successfully.</Text>
      </SuccessModal>

      {isLoading && (
        <LoadingModal styles={{ "&:after": { display: "none" } }}>
          <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
            <Loader color="light" text={translate("common:loading2")} />
          </div>
        </LoadingModal>
      )}
    </div>
  );
};
