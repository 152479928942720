import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  noWorksheetAnswers: {
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  editButtonContainer: {
    position: "sticky",
    top: theme.spacing.xlarge2,
    marginTop: theme.spacing.xlarge2,
    alignSelf: "flex-start",
  },
  buttonHidden: {
    display: "none",
  },
  approveButton: {
    marginBottom: theme.spacing.xsmall,
  },
  imageLabel: {
    display: "block",
    marginBottom: theme.spacing.xsmall2,
    textAlign: "left",
    ...theme.fontDefaults.small,
    fontWeight: 600,
  },
  saveButton: {
    marginBottom: theme.spacing.xsmall,
  },
  modalButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalCancelButton: {
    marginTop: theme.spacing.xsmall2,
    color: theme.colors.brand.base,
    textDecoration: "underline",
    textUnderlineOffset: theme.spacing.xsmall2,
  },
  modalInput: {
    marginBottom: theme.spacing.small,
  },
});
