"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
var debounce = function (callback, wait) {
    if (wait === void 0) { wait = 10; }
    var timerId;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        window.clearTimeout(timerId);
        timerId = window.setTimeout(function () {
            callback.apply(void 0, args);
        }, wait);
    };
};
exports.debounce = debounce;
