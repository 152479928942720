import React from "react";

/**
 * Type props for tick image.
 */
import { TGenericImage } from "./types";

interface ITick extends TGenericImage {
  classes?: {
    svg?: string;
    circle?: string;
    path?: string;
  };
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Tick: React.FC<ITick> = ({
  stroke = "#5AF6B2",
  classes = {
    svg: "tick-svg",
    circle: "tick-circle",
    path: "tick-path",
  },
}) => (
  <svg
    className={classes.svg}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 6"
  >
    <path
      className={classes.path}
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.8"
      d="M.5 3.75l2 2m5-5l-5 5"
    />
  </svg>
);
