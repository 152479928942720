import React, { useState } from "react";
import { UseQueryResult, useMutation } from "react-query";
import { Link } from "react-router-dom";
import axios from "axios";

/**
 * Import components.
 */
import { DeleteConfirmationModal } from "../../../common/components";

import { useAuthContext } from "../../../../hooks";
import { toasts } from "../../../../helpers";
import { DownloadIcon } from "../../../../images";

const base64ToBlob = (encode: string, options?: BlobPropertyBag) => {
  const binary = atob(encode.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view as unknown as string], options as any);
};

const downloadBlob = (blob: Blob) => {
  const a = document.createElement("a");
  a.href = (window as any).URL.createObjectURL(blob);
  a.download = "file.pdf";
  document.body.appendChild(a);
  a.click();
};

type PaginatedResultsActions = {
  functionName: string;
  actions: string[];
  refetch?: UseQueryResult["refetch"];
  row: {
    original: {
      id: number;
      singleLink: string;
      mutateUrl: string;
      invoiceMutateUrl: string | false;
      notesMutateUrl: string | false;
    };
  };
};

export const PaginatedResultsActions: React.FC<PaginatedResultsActions> = ({
  functionName,
  actions,
  refetch,
  row,
}): JSX.Element => {
  const { original } = row;
  const { id, singleLink, mutateUrl, invoiceMutateUrl, notesMutateUrl } =
    original;

  const { getCurrentUserId } = useAuthContext();
  const userId = getCurrentUserId();

  /**
   * Set state.
   */
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const isCurrentUser = functionName.includes("User") && id === userId;

  const { mutate: mutateDelete } = useMutation(() => axios.delete(mutateUrl), {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: () => {
      refetch && refetch();
    },
    onError: () => {
      toasts.error({
        message: "Failed to delete",
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const { mutate: mutateDownload } = useMutation(
    async (url: string) => {
      const result = await axios.get(url);
      const blob = base64ToBlob(result.data, {
        type: "application/pdf; charset=utf-8",
      });
      downloadBlob(blob);
    },
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        refetch && refetch();
      },
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
      onSettled: () => {
        setLoading(false);
      },
    },
  );

  return (
    <>
      {actions.includes("edit") && (
        <Link
          to={singleLink}
          className="mr-2 btn-tiny btn btn-primary hover:bg-primary hover:text-white transition ease-in duration-100"
        >
          Edit
        </Link>
      )}
      {actions.includes("delete") && !isCurrentUser && (
        <button
          className="btn-danger hover:btn-danger-hover btn-tiny"
          onClick={() => setShowModal(true)}
          disabled={loading}
        >
          Delete
        </button>
      )}
      {actions.includes("invoice") &&
        invoiceMutateUrl &&
        (loading ? (
          <p>Downloading...</p>
        ) : (
          <button
            type="button"
            onClick={() => mutateDownload(invoiceMutateUrl)}
            className="text-tertiary hover:text-primary-700 duration-300"
          >
            <span className="flex flex-row leading-3">
              <span className="mr-2 font-semibold">Download</span>
              <DownloadIcon width={10} height={10} />
            </span>
          </button>
        ))}
      {actions.includes("notes") &&
        notesMutateUrl &&
        (loading ? (
          <p>Downloading...</p>
        ) : (
          <button
            type="button"
            onClick={() => mutateDownload(notesMutateUrl)}
            className="text-tertiary hover:text-primary-700 duration-300"
          >
            <span className="flex flex-row leading-3">
              <span className="mr-2 font-semibold">Download</span>
              <DownloadIcon width={10} height={10} />
            </span>
          </button>
        ))}
      <DeleteConfirmationModal
        title="Are you sure you want to delete this?"
        visible={showModal}
        deleteCallback={() => mutateDelete()}
        hideCallback={() => {
          setShowModal(false);
        }}
        yesButtonLabel="Delete"
      />
    </>
  );
};
