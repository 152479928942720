import React, { useEffect } from "react";
import { useFormikContext } from "formik";

/**
 * Import components.
 */
import { CustomRadio } from ".";
import { Trail } from "../../core";

/**
 * Import types.
 */
import { Item } from "../../../models/item";

/**
 * Interface.
 */
interface IPortalServiceSelection {
  services: Item[];
  defaultValue?: string;
  onServiceChange?: (service: string) => void;
}

export const PortalServiceSelection: React.FC<IPortalServiceSelection> = ({
  services,
  defaultValue,
  onServiceChange,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();

  /**
   * Set default value if provided.
   */
  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setFieldValue("service", defaultValue);
    if (onServiceChange) {
      onServiceChange(defaultValue);
    }
  }, []);

  /**
   * Generating an array of service cell checkbox-components
   */
  let serviceCells: any = [];
  serviceCells = services.map((service) => {
    const { sku, title, img } = service;

    return (
      <CustomRadio
        key={sku}
        fieldId={sku}
        fieldName="service"
        fieldValue={sku}
        image={img}
        title={title || ""}
        isChecked={sku === values.service}
        onChange={(e) => {
          setFieldValue("service", e.target.value);
          if (onServiceChange) {
            onServiceChange(e.target.value);
          }
        }}
      />
    );
  });

  return (
    <Trail
      components={serviceCells}
      trailConfig={{
        opacity: 1,
        xyz: [0, 0, 0],
        delay: 100,
        from: {
          opacity: 0,
          xyz: [0, 100, 0],
        },
      }}
      animatedElemClass="custom-checkbox-container flex w-full md:w-1/2 lg:w-1/4 px-2 py-2 lg:py-4"
    />
  );
};
