import React, { useState, useMemo, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import css from "@emotion/css";
import axios from "axios";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import utc from "dayjs/plugin/utc";
import { useAuthContext } from "hooks";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { toPng } from "html-to-image";
import {
  Button,
  Text,
  Form,
  Heading,
  Panel,
  Icon,
  theme,
  Field,
  Box,
  formatCurrency,
  displayErrorMessage,
  Message,
  BadgeCount,
  UnstyledButton,
} from "@clearabee/ui-library";
import roles from "constants/roles";
import { LoadingOverlay } from "components/common/components";
import { Map, JobPin, PivotTab } from "../components";
import {
  permissionsValidation,
  permissionsInitialValues,
  defaultOptionValue,
} from "./validation";
import twoSeaterImage from "../../../images/statistics/2-seater.png";
import threeSeaterImage from "../../../images/statistics/3-seater-sofa.png";
import fourSeaterImage from "../../../images/statistics/4-seater-corner-sofa.png";
import armchairImage from "../../../images/statistics/armchair.png";
import bedImage from "../../../images/statistics/bed.png";
import carbonOffsetImage from "../../../images/statistics/carbon-offset.png";
import footstoolImage from "../../../images/statistics/footstool.png";
import fridgeImage from "../../../images/statistics/fridges.png";
import greenTickImage from "../../../images/statistics/green-tick.png";
import invoiceAmountImage from "../../../images/statistics/invoice-amount.png";
import landfillDiversionImage from "../../../images/statistics/landfill-diversion.png";
import largePopsImage from "../../../images/statistics/large-pops.png";
import mattressesImage from "../../../images/statistics/mattresses.png";
import otherItemsImage from "../../../images/statistics/other-items.png";
import redCrossImage from "../../../images/statistics/red-cross.png";
import smallPopsImage from "../../../images/statistics/small-pops.png";
import sofaBedImage from "../../../images/statistics/sofa-bed.png";
import totalVolumeImage from "../../../images/statistics/total-volume.png";
import totalWeightImage from "../../../images/statistics/total-weight.png";
import tvMonitorImage from "../../../images/statistics/tv-monitor.png";
import { styles } from "./statistics.styles";
import "./react_dates_overrides.css";

dayjs.extend(utc);

type TotalStat = {
  total: number;
  label: string;
  type: "percent" | "currency" | "number";
  image?: string;
  category?: "items" | "jobs" | "other" | "finance";
  weightPerUnit?: number;
};

type Totals = {
  totalJobs: TotalStat;
  totalJobsCompleted: TotalStat;
  totalJobsFailed: TotalStat;
  totalWeight: TotalStat;
  totalVolume: TotalStat;
  totalMattress: TotalStat;
  totalFridges: TotalStat;
  totalTvMonitor: TotalStat;
  totalSmallPops: TotalStat;
  totalLargePops: TotalStat;
  totalOtherItems: TotalStat;
  totalFootstool: TotalStat;
  totalArmchair: TotalStat;
  totalSofaTwoSeat: TotalStat;
  totalSofaThreeSeat: TotalStat;
  totalBed: TotalStat;
  totalSofabed: TotalStat;
  totalCornerSofa: TotalStat;
  totalInvoiceAmount: TotalStat;
  totalCreditAmount: TotalStat;
  landfillDiversion: TotalStat;
  carbonOffset: TotalStat;
};

type FormDataStateProps = {
  companies: string[];
  dateRange: string;
};

// max timeout is 9s, will change to 30s after updating timeout in API side
const maxTimeout = 9000;

/**
 * handles convert base64 to csv file
 * @param encode
 * @param options
 */
const donwloadCSV = (
  encode: string,
  time: string,
  options?: BlobPropertyBag,
) => {
  const binary = atob(encode.replace(/\s/g, ""));
  const length = binary.length;
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);
  for (let index = 0; index < length; index++) {
    view[index] = binary.charCodeAt(index);
  }

  // Binary Large Object
  const blob = new Blob([view as unknown as string], options as any);

  const element = document.createElement("a");
  element.href = (window as any).URL.createObjectURL(blob);
  element.download = `reports(${time}).csv`;
  document.body.appendChild(element);
  element.click();
};

export const Statistics = (): React.ReactElement => {
  const refPDFReport = useRef<HTMLDivElement | null>(null);
  const { getCurrentUserCompanies, doesUserHaveRole } = useAuthContext();
  const currentUserCompanies = getCurrentUserCompanies();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isAnalyst = doesUserHaveRole(roles.Analyst);
  const isFinanceAnalyst = doesUserHaveRole(roles.FINANCE_ANALYST);
  const [translate] = useTranslation("statistics");
  const [focusInput, setFocusInput] = useState<"startDate" | "endDate" | null>(
    null,
  );
  const [dateRangePicker, setDateRangePicker] = useState<{
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [formData, setFormData] = useState<{
    companies: string[];
    dateRange: string;
  }>({
    companies: [],
    dateRange: "",
  });

  const defaultDatesRange = [
    {
      text: translate("defaultTimeRange.last7Days"),
      startDate: moment().subtract(7, "days"),
      endDate: moment(),
    },
    {
      text: translate("defaultTimeRange.last30Days"),
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
    },
    {
      text: translate("defaultTimeRange.currentMonth"),
      startDate: moment().startOf("month"),
      endDate: moment(),
    },
    {
      text: translate("defaultTimeRange.lastMonth"),
      startDate: moment().subtract(1, "month").startOf("month"),
      endDate: moment().subtract(1, "month").endOf("month"),
    },
    {
      text: translate("defaultTimeRange.last3Months"),
      startDate: moment().subtract(3, "month").startOf("month"),
      endDate: moment().subtract(1, "month").endOf("month"),
    },
    {
      text: translate("defaultTimeRange.yearToDate"),
      startDate: moment().startOf("year"),
      endDate: moment(),
    },
  ];
  const [isExpanded, setIsExpanded] = useState({ open: true });

  const getCompanyCodeParam = (companyCode: string) => {
    if (isClearabeeAdmin && companyCode === defaultOptionValue.value) return {};

    return {
      "companyCode:in": companyCode,
    };
  };

  /**
   * download report data
   */
  const {
    mutate: mutateReportDownload,
    isLoading: isLoadingMutateReportDownload,
    isError: isErrorReportDownload,
  } = useMutation(
    ["getReportDataDownload", formData],
    async (formData: FormDataStateProps) => {
      const data = dataFormatter(formData.companies, formData.dateRange);

      if (!data) return;

      const { results } = (
        await instance.reports.getReportData({
          params: {
            ...getCompanyCodeParam(data.companyCode),
            "date:gte": data.start,
            "date:lt": data.end,
            ...(isAnalyst
              ? {
                  excludedColumns: "invoiceAmount,creditAmount,invoiceNumber",
                }
              : {}),
          },
        })
      ).data;

      const reportTimeRange = `${moment(data.start).format(
        "DD-MM-YYYY",
      )}-${moment(data.end).format("DD-MM-YYYY")}`;

      donwloadCSV(results, reportTimeRange, {
        type: "text/csv; charset=utf-8",
      });
    },
    {
      retry: 0,
    },
  );

  const { data: allCompanies, isLoading: isLoadingAllCompanies } = useQuery(
    "getAllCompanies",
    async () =>
      (
        await axios.get("/companies", {
          params: {
            limit: 5000,
          },
        })
      ).data.items,
    {
      enabled: isClearabeeAdmin,
    },
  );

  const {
    data: totalsReport,
    isLoading: totalsReportIsLoading,
    isError: totalsReportIsError,
  } = useQuery(
    ["getTotalsReport", formData],
    async () => {
      const data = dataFormatter(formData.companies, formData.dateRange);

      if (!data) return;

      return (
        await instance.reports.getJobsTotals({
          params: {
            ...getCompanyCodeParam(data.companyCode),
            "date:gte": data.start,
            "date:lt": data.end,
          },
          timeout: maxTimeout,
          timeoutErrorMessage: translate("errors.apiTimeout"), // this will display in the browser console
        })
      ).data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!formData.companies.length && !!formData.dateRange,
    },
  );

  const { mutate: downloadPDF, isLoading: isDownloadingPDF } = useMutation(
    ["downloadingPDF", refPDFReport, formData],
    async (formData: FormDataStateProps) => {
      if (refPDFReport.current === null) {
        return;
      }
      const data = dataFormatter(formData.companies, formData.dateRange);

      if (!data) return;

      const reportTimeRange = `${moment(data.start).format(
        "DD-MM-YYYY",
      )}-${moment(data.end).format("DD-MM-YYYY")}`;

      const dataUrl = await toPng(refPDFReport.current, {
        backgroundColor: "#edf2f7",
        cacheBust: true,
        skipAutoScale: true,
        width: 1350,
      });

      const link = document.createElement("a");
      link.download = `reports(${reportTimeRange}).png`;
      link.href = dataUrl;
      link.click();
    },
  );

  const {
    data: locations,
    isLoading: locationsIsLoading,
    isError: locationstIsError,
  } = useQuery(
    ["getLocations", formData],
    async () => {
      const data = dataFormatter(formData.companies, formData.dateRange);

      if (!data) return;

      return (
        await instance.reports.getJobsLocations({
          params: {
            ...getCompanyCodeParam(data.companyCode),
            "date:gte": data.start,
            "date:lt": data.end,
          },
          timeout: maxTimeout,
          timeoutErrorMessage: translate("errors.apiTimeout"), // this will display in the browser console
        })
      ).data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!formData.companies.length && !!formData.dateRange,
    },
  );

  // Will be used once I have checked the schema used in this instance
  // const {
  //   data: monthlyReport,
  //   isLoading: monthlyReportIsLoading,
  //   isError: monthlyReportIsError,
  // } = useQuery(
  //   ["getMonthlyReport", formData],
  //   async () => {
  //     const data = dataFormatter(formData.companies, formData.dateRange);

  //     if (!data) return;

  //     return (
  //       await instance.reports.getJobsTotalsByMonth({
  //         params: {
  //           ...getCompanyCodeParam(data.companyCode),
  //           "date:gte": data.start,
  //           "date:lt": data.end,
  //         },
  //         timeout: maxTimeout,
  //         timeoutErrorMessage: translate("errors.apiTimeout"), // this will display in the browser console
  //       })
  //     ).data;
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //     retry: 0,
  //     enabled: !!formData.companies.length && !!formData.dateRange,
  //   },
  // );

  const {
    data: monthlyReport,
    isLoading: monthlyReportIsLoading,
    isError: monthlyReportIsError,
  } = useQuery(
    ["getMonthlyReport", formData],
    async () => {
      const data = dataFormatter(formData.companies, formData.dateRange);

      if (!data) return;

      return (
        await axios.get(
          `${process.env.REACT_APP_MS_API_BASE_URL}/reports/jobs/totals-month`,
          {
            params: {
              ...getCompanyCodeParam(data.companyCode),
              "date:gte": data.start,
              "date:lt": data.end,
            },
            timeout: maxTimeout,
            timeoutErrorMessage: translate("errors.apiTimeout"), // this will display in the browser console
          },
        )
      ).data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!formData.companies.length && !!formData.dateRange,
    },
  );

  const dataFormatter = (companies: string[], date: string) => {
    if (!companies.length || !date) return;
    let companyCode = "";

    companies.map((value, index) =>
      index === companies.length - 1
        ? (companyCode += value)
        : (companyCode += value + ","),
    );

    const dateArray = date.split(" - ").map((value) => {
      const ok = value.split("-");
      return ok.reverse().join("/");
    });

    return {
      companyCode,
      start: dayjs.utc(dateArray[0]).startOf("day").toISOString(),
      end:
        date.length === 10
          ? dayjs.utc(dateArray[0]).endOf("day").toISOString()
          : dayjs.utc(dateArray[1]).endOf("day").toISOString(),
    };
  };

  const allTotalsByCompany: Totals = {
    totalJobs: {
      total: 0,
      label: "Total jobs",
      type: "number",
    },
    totalJobsCompleted: {
      total: 0,
      label: "Jobs completed",
      type: "number",
      image: greenTickImage,
      category: "jobs",
    },
    totalJobsFailed: {
      total: 0,
      label: "Jobs failed",
      type: "number",
      image: redCrossImage,
      category: "jobs",
    },
    totalWeight: {
      total: 0,
      label: "Total weight (kg)",
      type: "number",
      image: totalWeightImage,
      category: "other",
    },
    totalVolume: {
      total: 0,
      label: "Total volume (yd3)",
      type: "number",
      image: totalVolumeImage,
      category: "other",
    },
    totalMattress: {
      total: 0,
      label: "Mattresses",
      type: "number",
      image: mattressesImage,
      category: "items",
      weightPerUnit: 40,
    },
    totalFridges: {
      total: 0,
      label: "Fridges",
      type: "number",
      image: fridgeImage,
      category: "items",
      weightPerUnit: 38,
    },
    totalTvMonitor: {
      total: 0,
      label: "TVs and monitors",
      type: "number",
      image: tvMonitorImage,
      category: "items",
      weightPerUnit: 17,
    },
    totalSmallPops: {
      total: 0,
      label: "Small POPs",
      type: "number",
      image: smallPopsImage,
      category: "items",
      weightPerUnit: 17,
    },
    totalLargePops: {
      total: 0,
      label: "Large POPs",
      type: "number",
      image: largePopsImage,
      category: "items",
      weightPerUnit: 44,
    },
    totalOtherItems: {
      total: 0,
      label: "Other items",
      type: "number",
      image: otherItemsImage,
      category: "items",
      weightPerUnit: 37,
    },
    totalFootstool: {
      total: 0,
      label: "Footstools",
      type: "number",
      image: footstoolImage,
      category: "items",
      weightPerUnit: 5,
    },
    totalArmchair: {
      total: 0,
      label: "Armchairs",
      type: "number",
      image: armchairImage,
      category: "items",
      weightPerUnit: 27,
    },
    totalSofaTwoSeat: {
      total: 0,
      label: "2 seater sofas",
      type: "number",
      image: twoSeaterImage,
      category: "items",
      weightPerUnit: 37,
    },
    totalSofaThreeSeat: {
      total: 0,
      label: "3 seater sofas",
      type: "number",
      image: threeSeaterImage,
      category: "items",
      weightPerUnit: 42,
    },
    totalBed: {
      total: 0,
      label: "Beds",
      type: "number",
      image: bedImage,
      category: "items",
      weightPerUnit: 27,
    },
    totalSofabed: {
      total: 0,
      label: "Sofabeds",
      type: "number",
      image: sofaBedImage,
      category: "items",
      weightPerUnit: 85,
    },
    totalCornerSofa: {
      total: 0,
      label: "Corner sofas",
      type: "number",
      image: fourSeaterImage,
      category: "items",
      weightPerUnit: 74,
    },
    totalInvoiceAmount: {
      total: 0,
      label: "Invoice amount",
      type: "currency",
      image: invoiceAmountImage,
      category: "finance",
    },
    totalCreditAmount: {
      total: 0,
      label: "Credit amount",
      type: "currency",
      image: invoiceAmountImage,
      category: "finance",
    },
    landfillDiversion: {
      total: 97,
      label: "Landfill diversion",
      type: "percent",
      image: landfillDiversionImage,
      category: "other",
    },
    carbonOffset: {
      total: 100,
      label: "Carbon offset",
      type: "percent",
      image: carbonOffsetImage,
      category: "other",
    },
  };

  totalsReport &&
    Object.entries(totalsReport).map(([key, value]) => {
      allTotalsByCompany.totalJobs.total += value.totalJobs;
      allTotalsByCompany.totalJobsCompleted.total += value.totalJobsCompleted;
      allTotalsByCompany.totalJobsFailed.total += value.totalJobsFailed;
      allTotalsByCompany.totalWeight.total += value.totalWeight;
      allTotalsByCompany.totalVolume.total += value.totalVolume;
      allTotalsByCompany.totalMattress.total += value.totalMattress;
      allTotalsByCompany.totalFridges.total += value.totalFridges;
      allTotalsByCompany.totalTvMonitor.total += value.totalTvMonitor;
      allTotalsByCompany.totalSmallPops.total += value.totalSmallPops;
      allTotalsByCompany.totalLargePops.total += value.totalLargePops;
      allTotalsByCompany.totalOtherItems.total += value.totalOtherItems;
      allTotalsByCompany.totalFootstool.total += value.totalFootstool;
      allTotalsByCompany.totalArmchair.total += value.totalArmchair;
      allTotalsByCompany.totalSofaTwoSeat.total += value.totalSofaTwoSeat;
      allTotalsByCompany.totalSofaThreeSeat.total += value.totalSofaThreeSeat;
      allTotalsByCompany.totalBed.total += value.totalBed;
      allTotalsByCompany.totalSofabed.total += value.totalSofabed;
      allTotalsByCompany.totalCornerSofa.total += value.totalCornerSofa;
      allTotalsByCompany.totalInvoiceAmount.total += value.totalInvoiceAmount;
      allTotalsByCompany.totalCreditAmount.total += value.totalCreditAmount;
    });

  const compactFormatter = Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
  });

  // Dropdown options
  const getCompaniesDropDownOptions = (
    companies: { name: string; companyCode: string }[],
  ) => {
    if (!companies?.length) return [];

    return companies.map(({ name, companyCode }) => ({
      label: name,
      value: companyCode,
    }));
  };

  const dropDownOptions = getCompaniesDropDownOptions(
    !isClearabeeAdmin ? currentUserCompanies : allCompanies,
  );

  const statFormatter = (stat: TotalStat) => {
    switch (stat.type) {
      case "number":
        return compactFormatter.format(stat.total);
      case "currency":
        return `£${compactFormatter.format(stat.total)}`;
      case "percent":
        return `${stat.total}%`;
      default:
        return String(stat.total);
    }
  };

  const items = Object.entries(allTotalsByCompany)
    .filter(([key, value]) => value.category === "items")
    .map(([key, value]) => value);

  const weightViewEnabledCompanyCodes = ["DFS002", "SOF003"];
  const weightViewEnabled = weightViewEnabledCompanyCodes.some((code) =>
    currentUserCompanies.map((company) => company.companyCode).includes(code),
  );
  const weightViewEnabledTotalWeight = items
    .map((item) => (item.weightPerUnit && item.weightPerUnit * item.total) || 0)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  const statsToDisplay = Object.entries(allTotalsByCompany)
    .filter(([key, value]) => {
      return value.category === "other" && key !== "totalWeight";
    })
    .map(([key, value]) => value);

  if (isFinanceAnalyst || isClearabeeAdmin) {
    statsToDisplay.push(
      allTotalsByCompany.totalInvoiceAmount,
      allTotalsByCompany.totalCreditAmount,
    );
  }

  if (!weightViewEnabled) {
    statsToDisplay.unshift(allTotalsByCompany.totalWeight);
  }

  if (weightViewEnabled) {
    statsToDisplay.splice(
      statsToDisplay.findIndex((item) => item.label === "Total volume (yd3)"),
      1,
    );

    statsToDisplay.unshift({
      label: "Total weight (kg)",
      type: "number",
      image: totalWeightImage,
      category: "other",
      total: weightViewEnabledTotalWeight,
    });
  }

  const statWidth = 100 / statsToDisplay.length;

  const jobPins = useMemo(
    () => (
      <>
        {locations &&
          locations.map((location, index) => (
            <JobPin
              infoWindowContent={
                <>
                  <Text styles={styles.pinTopText} fontSize="small">
                    <Text
                      color="brand"
                      fontSize="small"
                      styles={{
                        fontWeight: 600,
                      }}
                    >
                      {translate("date")}
                    </Text>
                    {dayjs(location.date).format("DD/MM/YYYY").toString()}
                  </Text>

                  <Text fontSize="small">
                    <Text
                      color="brand"
                      fontSize="small"
                      styles={{ fontWeight: 600 }}
                    >
                      {translate("jobRef")}
                    </Text>
                    {location.jobRef}
                  </Text>
                </>
              }
              key={index}
              position={location}
            />
          ))}
      </>
    ),
    [locations],
  );

  return (
    <Box ref={refPDFReport} className="mx-5">
      {/* Loading */}
      {(totalsReportIsLoading ||
        monthlyReportIsLoading ||
        isLoadingAllCompanies ||
        isLoadingMutateReportDownload) && <LoadingOverlay />}

      {/* Form Panel */}
      <Panel styles={styles.formPanel}>
        <Form
          initialValues={permissionsInitialValues}
          validationSchema={permissionsValidation}
          onSubmit={(data) => {
            const selectedCompanies =
              data.companies as typeof defaultOptionValue[];

            const isAllSelected = selectedCompanies.some(
              (item) => item.value === defaultOptionValue.value,
            );

            const allCompanies =
              isClearabeeAdmin && isAllSelected
                ? [defaultOptionValue.value]
                : dropDownOptions.map(({ value }) => value);

            setFormData({
              ...data,
              companies: !isAllSelected
                ? selectedCompanies.map(({ value }) => value)
                : allCompanies,
            });
          }}
        >
          {({ setFieldValue }) => {
            return (
              <>
                <Box className="flex flex-row items-center justify-between">
                  <Box className="w-2/3">
                    <Heading level={4} color="brand">
                      {translate("headings.formHeading")}
                    </Heading>
                  </Box>
                  <Box className="w-1/12 relative -left-4">
                    <Button size="medium" color="accent" type="submit">
                      {translate("buttons.submit")}
                    </Button>
                  </Box>
                </Box>

                <Box className="border-t border-grey-200 my-4 flex flex-col" />

                <Box className="flex flex-row gap-5 w-full">
                  {/* Companies */}
                  <Box className="w-3/4">
                    <Field
                      styles={styles.selectInput}
                      name="companies"
                      label="Companies*"
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isSearchable
                          isClearable
                          isMulti
                          closeMenuOnSelect={false}
                          placeholder="Select companies"
                          options={[defaultOptionValue, ...dropDownOptions]}
                          className="text-base"
                          onChange={(value: typeof defaultOptionValue[]) => {
                            const isAllSelected = !!value?.some(
                              (item) => item.value === defaultOptionValue.value,
                            );

                            if (isAllSelected) {
                              setFieldValue("companies", [defaultOptionValue]);
                            } else {
                              setFieldValue("companies", value ?? []);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Box>

                  {/* Date Range */}
                  <Box className="w-1/5">
                    <Box className="w-full">
                      <Field label="Date Range" name="dateRange">
                        <Box className="dateRangeStatistics">
                          <DateRangePicker
                            required
                            hideKeyboardShortcutsPanel
                            anchorDirection="right"
                            isOutsideRange={(day) => day.isAfter(moment())}
                            startDate={dateRangePicker.startDate}
                            endDate={dateRangePicker.endDate}
                            focusedInput={focusInput}
                            minimumNights={0}
                            onDatesChange={({ startDate, endDate }) => {
                              setFieldValue(
                                "dateRange",
                                `${startDate} - ${endDate}`,
                              );
                              setDateRangePicker({ startDate, endDate });
                            }}
                            onFocusChange={(value) => setFocusInput(value)}
                            startDateId="DateRangePickerStartDateId"
                            endDateId="DateRangePickerEndDateId"
                            displayFormat="DD-MM-YYYY"
                            renderCalendarInfo={() => (
                              <>
                                {defaultDatesRange.map(
                                  ({ text, startDate, endDate }) => (
                                    <UnstyledButton
                                      className="mx-2 my-1"
                                      key={text}
                                      type="button"
                                      onClick={() => {
                                        setFieldValue(
                                          "dateRange",
                                          `${startDate} - ${endDate}`,
                                        );
                                        setDateRangePicker({
                                          startDate,
                                          endDate,
                                        });
                                      }}
                                    >
                                      <Text
                                        color="brand"
                                        fontSize="small"
                                        className="underline font-bold"
                                      >
                                        {text}
                                      </Text>
                                    </UnstyledButton>
                                  ),
                                )}
                              </>
                            )}
                          />
                        </Box>
                      </Field>
                    </Box>
                  </Box>
                </Box>
              </>
            );
          }}
        </Form>

        {/* Download report */}
        {totalsReport &&
          monthlyReport &&
          Object.keys(totalsReport).length > 0 &&
          monthlyReport.length > 0 && (
            <Box className="flex justify-end">
              <Box className="flex flex-row justify-end mt-1 gap-x-3">
                <UnstyledButton
                  onClick={() => downloadPDF(formData)}
                  type="button"
                  disabled={isDownloadingPDF}
                  className="text-primary hover:text-primary-700 duration-300"
                >
                  <Text
                    fontSize="small"
                    className="flex items-center justify-end gap-x-1 w-40"
                  >
                    {translate("screenshot")}
                    <Icon.Download width={14} height={14} />
                    {isDownloadingPDF && (
                      <Icon.Loading size="small" className="ml-2" />
                    )}
                  </Text>
                </UnstyledButton>
                <UnstyledButton
                  onClick={() => mutateReportDownload(formData)}
                  type="button"
                  className="text-primary hover:text-primary-700 duration-300"
                >
                  <Text
                    fontSize="small"
                    className="flex items-center justify-end gap-x-1 w-32"
                  >
                    {translate("downloadCSV")}
                    <Icon.Download width={14} height={14} />
                  </Text>
                </UnstyledButton>
              </Box>
            </Box>
          )}
      </Panel>

      {/* Error Message */}
      {(totalsReportIsError ||
        monthlyReportIsError ||
        isErrorReportDownload) && (
        <Box className="flex w-full mt-5">
          {displayErrorMessage(
            translate(
              isErrorReportDownload
                ? "errors.downloadReport"
                : "errors.fetchingData",
            ),
            ({ children }) => (
              <Box className="flex items-center justify-center w-full mb-4 overflow-scroll">
                <Message type="error" background>
                  {children}
                </Message>
              </Box>
            ),
          )}
        </Box>
      )}

      {monthlyReport && totalsReport && (
        <>
          <Box className="flex justify-end">
            <UnstyledButton
              styles={styles.unstyledButton}
              onClick={() => {
                setIsExpanded({ open: false });
              }}
            >
              {translate("buttons.collapseAll")}
            </UnstyledButton>
            <UnstyledButton
              styles={styles.unstyledButton}
              onClick={() => {
                setIsExpanded({ open: true });
              }}
            >
              {translate("buttons.expandAll")}
            </UnstyledButton>
          </Box>

          {/* Companies Table */}
          <PivotTab
            options={isExpanded}
            label={translate("headings.companies")}
          >
            <Panel>
              <Box className="flex flex-col">
                <Box styles={styles.tableHeader}>
                  <Box styles={styles.companyHeader}>
                    <Text styles={{ fontWeight: 600 }} color="brand">
                      {translate("headings.companies")}
                    </Text>
                    <BadgeCount
                      className="ml-2"
                      count={Object.keys(totalsReport).length}
                      color="brand"
                      backgroundColor="brand"
                      borderColor="brand"
                      textColor="light"
                    />
                  </Box>
                  <Box styles={styles.jobs}>
                    <Text color="brand" styles={styles.jobsHeader}>
                      {translate("headings.jobs")}
                    </Text>
                  </Box>
                </Box>
                <Box styles={styles.tableBody}>
                  {Object.entries(totalsReport).map(([key, value]) => {
                    const companyName = dropDownOptions.find(
                      ({ value }) => value === key,
                    )?.label;

                    return (
                      <Box
                        key={key}
                        className="flex flex-row"
                        styles={styles.row}
                      >
                        {!!companyName ? (
                          <Text styles={styles.company}>
                            {companyName?.includes(key)
                              ? companyName
                              : `${companyName} (${key})`}
                          </Text>
                        ) : (
                          <Text styles={styles.company}>{key}</Text>
                        )}
                        <Box styles={styles.jobs}>
                          <Text>{value.totalJobs.toLocaleString("en-US")}</Text>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Panel>
          </PivotTab>

          {/* Jobs Completed / Failed */}
          <PivotTab options={isExpanded} label={translate("headings.overview")}>
            <Box className="flex flex-row gap-4 mb-4">
              {Object.entries(allTotalsByCompany)
                .filter(([key, value]) => value.category === "jobs")
                .map(([key, value]) => {
                  return (
                    <Box key={key} className="flex w-1/2">
                      <Panel
                        className="flex flex-row w-full justify-between items-center"
                        styles={styles.topRowPanel}
                      >
                        <Box className="flex flex-col">
                          <Text
                            fontSize="xlarge5"
                            styles={{ fontWeight: 800 }}
                            color={
                              value.label === "Jobs completed"
                                ? "positive"
                                : "negative"
                            }
                          >
                            {statFormatter(value)}
                          </Text>
                          <Text color="light" styles={{ fontWeight: 600 }}>
                            {value.label}
                          </Text>
                        </Box>
                        <Box>
                          <img
                            src={value.image}
                            alt={value.label}
                            width={100}
                            height={100}
                          />
                        </Box>
                      </Panel>
                    </Box>
                  );
                })}
            </Box>

            {/* Statistics */}
            <Box className="flex flex-row gap-4">
              {statsToDisplay.map((stat, index) => (
                <Box
                  key={index}
                  className={`flex ${
                    isFinanceAnalyst || isClearabeeAdmin ? "w-1/5" : "w-1/4"
                  }`}
                  styles={{ display: "flex", width: `${statWidth}%` }}
                >
                  <Panel styles={styles.otherItemsPanel}>
                    <Box className="flex flex-col justify-start items-center">
                      <Box styles={styles.imageContainer}>
                        <img
                          src={stat.image}
                          alt={stat.label}
                          width="100px"
                          height="100px"
                        />
                      </Box>
                      <Text
                        fontSize="xlarge4"
                        styles={{
                          fontWeight: 800,
                        }}
                        className="text-center"
                        color="warning"
                      >
                        {statFormatter(stat)}
                      </Text>
                      <Text
                        color="light"
                        styles={{ textAlign: "center", fontWeight: 600 }}
                      >
                        {stat.label}
                      </Text>
                    </Box>
                  </Panel>
                </Box>
              ))}
            </Box>
          </PivotTab>

          {/* Items Collected */}
          <PivotTab
            options={isExpanded}
            label={translate("headings.itemsCollected")}
          >
            <Panel>
              <Box className="flex flex-row flex-wrap justify-center items-start -mx-3 ">
                {items.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      className="flex justify-center items-start h-auto w-1/5 p-3"
                    >
                      <Box className="flex flex-col">
                        <Box styles={styles.imageContainer}>
                          <img
                            src={item.image}
                            alt={item.label}
                            width="100px"
                            height="100px"
                          />
                        </Box>
                        <Text
                          fontSize="xlarge4"
                          styles={{ fontWeight: 800 }}
                          className="text-center"
                          color="brand"
                        >
                          {statFormatter(item)}
                        </Text>
                        <Text
                          fontSize="base"
                          styles={{ fontWeight: 600 }}
                          className="text-center"
                        >
                          {item.label}
                        </Text>
                        {weightViewEnabled &&
                          item.weightPerUnit &&
                          item.total > 0 && (
                            <Text
                              fontSize="base"
                              styles={{ fontWeight: 800 }}
                              className="text-center"
                              color="brand"
                            >
                              {(item.weightPerUnit * item.total).toLocaleString(
                                "en-US",
                              )}
                              kg
                            </Text>
                          )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Panel>
          </PivotTab>

          {/* Graphs */}
          <PivotTab options={isExpanded} label={translate("headings.graphs")}>
            <Box className="flex flex-row gap-4">
              <Panel
                styles={css(styles.graphPanel, {
                  width: isClearabeeAdmin || isFinanceAnalyst ? "50%" : "100%",
                })}
              >
                <Chart
                  series={[
                    {
                      name: translate("headings.invoiceAmountByMonth"),
                      data: monthlyReport.map((month: any) => month.totalJobs),
                    },
                    {
                      name: translate("graph.totalJobsCompleted"),
                      data: monthlyReport.map(
                        (month: any) => month.totalJobsCompleted,
                      ),
                    },
                    {
                      name: translate("graph.totalJobsFailed"),
                      data: monthlyReport.map(
                        (month: any) => month.totalJobsFailed,
                      ),
                    },
                  ]}
                  type="bar"
                  width="100%"
                  height={350}
                  options={{
                    title: {
                      text: translate("headings.jobsByMonth"),
                      align: "right",
                      style: {
                        fontSize: theme.fontDefaults.small.fontSize,
                        fontWeight: 600,
                        color: theme.colors.brand.base,
                      },
                    },
                    colors: [
                      theme.colors.brand.base,
                      theme.colors.positive.base,
                      theme.colors.negative.base,
                    ],
                    xaxis: {
                      categories: monthlyReport.map((month: any) =>
                        dayjs(month.startOfMonth).format("MMM YYYY"),
                      ),
                    },
                    yaxis: {
                      labels: {
                        formatter: (value: any) => {
                          return value.toLocaleString("en-US");
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    chart: {
                      id: "basic-bar",
                      toolbar: {
                        show: false,
                      },
                    },
                  }}
                />
              </Panel>
              {(isClearabeeAdmin || isFinanceAnalyst) && (
                <Panel styles={styles.graphPanel}>
                  <Chart
                    series={[
                      {
                        name: translate("headings.invoiceAmountByMonth"),
                        data: monthlyReport.map(
                          (month: any) => month.totalInvoiceAmount,
                        ),
                      },
                    ]}
                    type="bar"
                    width="100%"
                    height={350}
                    options={{
                      colors: [theme.colors.brand.base],
                      xaxis: {
                        categories: monthlyReport.map((month: any) =>
                          dayjs(month.startOfMonth).format("MMM YYYY"),
                        ),
                      },
                      title: {
                        text: translate("headings.invoiceAmountByMonth"),
                        align: "right",
                        style: {
                          fontSize: theme.fontDefaults.small.fontSize,
                          fontWeight: 600,
                          color: theme.colors.brand.base,
                        },
                      },
                      yaxis: {
                        labels: {
                          formatter: (value: any) => {
                            return formatCurrency(value);
                          },
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      chart: {
                        id: "basic-bar",
                        toolbar: {
                          show: false,
                        },
                      },
                    }}
                  />
                </Panel>
              )}
            </Box>
          </PivotTab>
        </>
      )}

      {/* Map */}
      {monthlyReport && (
        <PivotTab options={isExpanded} label={translate("headings.allJobs")}>
          <Panel styles={styles.mapPanel}>
            {locations && !locationsIsLoading && (
              <Map styles={styles.map}>{jobPins}</Map>
            )}
          </Panel>
        </PivotTab>
      )}
    </Box>
  );
};
