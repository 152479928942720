import React from "react";
import cx from "classnames";
import { toast, ToastOptions } from "react-toastify";

/**
 * Toast component props
 */
type ToastParams = {
  heading?: string;
  message: string;
  button?: {
    label: string;
    onClick: () => void;
  };
};

/**
 * Toast component props.
 */
type ToastComponent = ToastParams & {
  type: "success" | "info" | "warn" | "error";
};

/**
 * Extending the original toast emitter.
 */
const newToasts = Object.create(toast);

/**
 * Shorthand to display toast of type 'success'.
 */
newToasts.success = (
  { heading, message, button }: ToastParams,
  options?: ToastOptions,
) => {
  return toast.success(
    <ToastComponent
      heading={heading || "Success"}
      type="success"
      message={message}
      button={button}
    />,
    options,
  );
};

/**
 * Shorthand to display toast of type 'info'.
 */
newToasts.info = (
  { heading, message, button }: ToastParams,
  options?: ToastOptions,
) => {
  return toast.info(
    <ToastComponent
      heading={heading || "Info"}
      type="info"
      message={message}
      button={button}
    />,
    options,
  );
};

/**
 * Shorthand to display toast of type 'warn'.
 */
newToasts.warn = (
  { heading, message, button }: ToastParams,
  options?: ToastOptions,
) => {
  return toast.warn(
    <ToastComponent
      heading={heading || "Warn"}
      type="warn"
      message={message}
      button={button}
    />,
    options,
  );
};

/**
 * Shorthand to display toast of type 'error'.
 */
newToasts.error = (
  { heading, message, button }: ToastParams,
  options?: ToastOptions,
) => {
  return toast.error(
    <ToastComponent
      heading={heading || "Error"}
      type="error"
      message={message}
      button={button}
    />,
    options,
  );
};

/**
 * Custom toast component.
 */
const ToastComponent: React.FC<ToastComponent> = ({
  heading,
  message,
  type,
  button,
}) => (
  <div
    className="toasts-inner text-center sm:text-left"
    data-testid="toasts-component"
  >
    <h6
      className={cx(
        "toasts-heading inline-block py-2 px-4 uppercase font-semibold rounded-md sm:rounded-full text-xs mb-2 sm:mb-0",
        {
          "bg-secondary": type === "success",
          "bg-red-500 text-white": type === "error",
          "bg-orange text-white": type === "warn",
          "bg-gray-100 text-gray-700": type === "info",
        },
      )}
    >
      {heading}
    </h6>
    <p className="toasts-message inline-block w-full sm:w-auto px-4 text-xs sm:text-md text-center sm:text-left">
      {message}
    </p>
    {button && (
      <div className="w-full sm:w-auto mt-2 sm:mt-0 text-center sm:text-left text-xs sm:text-md inline-block">
        <button
          className="toasts-button btn-secondary hover:btn-secondary-hover focus:outline-none py-1 px-4 font-semibold text-white transition ease-in duration-75"
          onClick={button.onClick}
        >
          {button.label}
        </button>
      </div>
    )}
  </div>
);

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const toasts = newToasts;
