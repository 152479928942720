import React from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createBasketPaymentLink, getBasket, getPaymentLink } from "api";
import {
  Button,
  FlexGrid,
  Form,
  Heading,
  Icon,
  Input,
  Panel,
  Pill,
  theme,
  useModal,
} from "@clearabee/ui-library";
import { statusColors } from "./readPaymentLinks";
import dayjs from "dayjs";
import { handleException } from "helpers/handleException";
import { patchPaymentLink } from "api/payment";
import { toasts } from "helpers";
import { LeftChevron } from "images";

export const UpdatePaymentLink = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { token } = useParams<{ token: string }>();
  const [CancelModal, cancelModalVisible] = useModal();

  /**
   * Basket data.
   */
  const { data: basket, isLoading: basketIsLoading } = useQuery(
    ["getBasket", token],
    () => getBasket(token),
    {
      enabled: !!token,
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
    },
  );
  const {
    data: paymentLink,
    isLoading,
    refetch,
  } = useQuery(["getPaymentLink", token], () => getPaymentLink(token), {
    enabled: !!token,
    // prevent caching to show newly updated values
    cacheTime: 0,
    staleTime: 0,
  });

  const { mutate: resendPaymentLink, isLoading: resendLoading } = useMutation(
    (token: string) => {
      return createBasketPaymentLink(token);
    },
    {
      onError: (error) => {
        handleException(
          error,
          {
            basket,
            paymentLink,
          },
          {
            type: "Update Payment Link",
            action: "Resend payment link",
          },
        );
      },
      onSuccess: () => {
        toasts.success({ message: translate("toasts.resendSuccess") });
      },
    },
  );

  const { mutate: cancelPaymentLink, isLoading: cancelLoading } = useMutation(
    (token: string) => {
      return patchPaymentLink(token, { status: "cancelled" });
    },
    {
      onError: (error) => {
        handleException(
          error,
          {
            basket,
            paymentLink,
          },
          {
            type: "Update Payment Link",
            action: "Cancel payment link",
          },
        );
      },
      onSuccess: () => {
        refetch();
        toasts.success({ message: translate("toasts.cancelSuccess") });
      },
    },
  );

  return (
    <>
      <div className="max-w-5xl pt-10 mx-auto">
        <Link
          to="/jobs/payment-links"
          className="text-sm inline-flex items-center mb-4 justify-start"
        >
          <span>
            <LeftChevron />
          </span>
          <span className="ml-2">Back to jobs</span>
        </Link>

        <Panel>
          <div className="flex items-center mb-6">
            <Heading color="brand" level={4} styles={{ marginRight: "auto" }}>
              {translate("headings.jobDetails")}
            </Heading>
            {paymentLink && paymentLink.status === "pending" && (
              <>
                {(resendLoading || cancelLoading) && (
                  <Icon.Loading color="positive" className="mr-8" />
                )}
                <Button
                  className="mr-5"
                  size="small"
                  onClick={() => resendPaymentLink(paymentLink.basketToken)}
                  disabled={resendLoading || cancelLoading}
                >
                  {translate("resend")}
                </Button>
                <Button
                  size="small"
                  color="negative"
                  className="mr-5"
                  onClick={() => cancelModalVisible(true)}
                  disabled={resendLoading || cancelLoading}
                >
                  {translate("cancel")}
                </Button>
              </>
            )}
          </div>
          {!isLoading && !basketIsLoading && basket && paymentLink && (
            <FlexGrid wrap="nowrap" rowSpacing={null}>
              <FlexGrid.Cell base="100%">
                <Heading
                  level={5}
                  styles={{ marginBottom: theme.spacing.xsmall }}
                >
                  {translate("headings.status")}
                </Heading>
                <Pill color={statusColors[paymentLink.status]}>
                  {paymentLink.status}
                </Pill>
              </FlexGrid.Cell>

              <FlexGrid.Cell base="100%">
                <Heading
                  level={5}
                  styles={{ marginBottom: theme.spacing.xsmall }}
                >
                  {translate("headings.collectionDate")}
                </Heading>
                {dayjs(basket.date).format("dddd, MMMM Do YYYY")}
              </FlexGrid.Cell>

              <FlexGrid.Cell base="100%">
                <Heading
                  level={5}
                  styles={{ marginBottom: theme.spacing.xsmall }}
                >
                  {translate("headings.jobReference")}
                </Heading>
                {basket.orderRef}
              </FlexGrid.Cell>
              <FlexGrid.Cell base="100%">
                <Heading
                  level={5}
                  styles={{ marginBottom: theme.spacing.xsmall }}
                >
                  {translate("headings.companyCode")}
                </Heading>
                {basket.companyCode}
              </FlexGrid.Cell>
            </FlexGrid>
          )}
          {isLoading && <Icon.Loading color="positive" className="mx-auto" />}
        </Panel>
        {!basketIsLoading && basket && (
          <Form
            initialValues={basket}
            renderFormElement={false}
            onSubmit={() => {
              // keep form here in case we might want to allow user to edit basket
            }}
          >
            {({ Field }) => (
              <FlexGrid className="mt-10" rowSpacing={null}>
                <FlexGrid.Cell base="25%">
                  <Heading color="brand" level={5}>
                    {translate("headings.collectionDetails")}
                  </Heading>
                  <Field
                    name="deliveryAddress.line1"
                    label={translate("deliveryAddress.line1")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field
                    name="deliveryAddress.city"
                    label={translate("deliveryAddress.city")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field
                    name="deliveryAddress.county"
                    label={translate("deliveryAddress.county")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field
                    name="deliveryAddress.postcode"
                    label={translate("deliveryAddress.postcode")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                </FlexGrid.Cell>
                <FlexGrid.Cell base="25%">
                  <Heading color="brand" level={5}>
                    {translate("headings.customerDetails")}
                  </Heading>
                  <Field
                    name="contact.firstName"
                    label={translate("contact.firstName")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field
                    name="contact.lastName"
                    label={translate("contact.lastName")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field
                    name="contact.email"
                    label={translate("contact.email")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field
                    name="contact.phoneNumber"
                    label={translate("contact.phoneNumber")}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                </FlexGrid.Cell>
                <FlexGrid.Cell base="50%">
                  <Heading color="brand" level={5}>
                    {translate("headings.wasteDetails")}
                  </Heading>
                  <Field name="description" label={translate("description")}>
                    {({ field }) => <Input.Textarea {...field} disabled slim />}
                  </Field>
                  <Field
                    name="accessInformation"
                    label={translate("accessInformation")}
                  >
                    {({ field }) => <Input.Textarea {...field} disabled slim />}
                  </Field>
                </FlexGrid.Cell>
              </FlexGrid>
            )}
          </Form>
        )}
      </div>
      <CancelModal
        heading={translate("modal.headings.cancelPaymentLink")}
        width={400}
      >
        <div className="flex justify-center mt-6">
          <Button
            size="medium"
            color="accent"
            className="mr-5"
            onClick={() => {
              cancelModalVisible(false);
              cancelPaymentLink(paymentLink?.basketToken ?? "");
            }}
          >
            {translate("modal.buttons.labels.yesCancel")}
          </Button>
          <Button
            size="medium"
            color="negative"
            onClick={() => cancelModalVisible(false)}
          >
            {translate("modal.buttons.labels.goBack")}
          </Button>
        </div>
      </CancelModal>
    </>
  );
};
