"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCalloutCharge = void 0;
var getItems_1 = require("../utils/getItems");
var getCalloutCharge = function (items, item) {
    var _a, _b, _c;
    var baseCallout = items.find(function (_a) {
        var sku = _a.sku;
        return ["CALLOUTCHARGE", "CALLOUT"].includes(sku);
    });
    var itemCallout = (_b = (_a = (0, getItems_1.getItems)(["".concat(item.parentSku, "CALLOUT")], items)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : baseCallout;
    if (item.noCalloutCharge) {
        return 0;
    }
    return (_c = itemCallout === null || itemCallout === void 0 ? void 0 : itemCallout.price) !== null && _c !== void 0 ? _c : 0;
};
exports.getCalloutCharge = getCalloutCharge;
