"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterSet = void 0;
exports.filterSet = {
    vehicles: {
        vehicles: "Vehicles:",
        vehicleType: "Vehicle Type",
        vehicleReg: "Vehicle Reg ({{length}})",
        enterVehicleReg: "Enter a vehicle reg",
        search: "Search",
        selectedItems: "Selected Items ({{length}})",
        searchForReg: "Search for reg",
        clearReg: "Clear Reg",
        region: "Region",
        driver: "Driver",
        driversName: "Driver's name ({{length}})",
        searchByDriver: "Search by Driver",
        active: "Active:",
        twoMan: "Two Man:",
        oneMan: "One Man:",
        notMovedToday: "Not Moved Today:",
        notMovedInSevenDays: "Not Moved in Last 7 Days:",
        offTheRoad: "Off the Road:",
    },
    jobs: {
        jobs: "Jobs:",
        startDate: "Start Date: ",
        endDate: "End Date: ",
        enterVehicleReg: "Enter a vehicle reg",
        vehicleReg: "Vehicle Reg ({{length}})",
        showUnscheduledJobs: "Show Unscheduled Jobs: ",
        showCompletedJobs: "Show Completed Jobs: ",
        clearJobFilters: "Clear Job Filters",
    },
    wasteTransferStations: {
        wasteTransferStations: "Waste Transfer Stations:",
    },
    fuelStations: {
        fuelStations: "Fuel Stations:",
    },
    maintenance: {
        maintenance: "Maintenance:",
    },
    activeDrivers: {
        activeDrivers: "Active Drivers:",
    },
};
