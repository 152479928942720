import React from "react";

/**
 * Import components.
 */
import { Quantity } from "../../../core";

/**
 * Import context.
 */
import { useBasketContext } from "../../../../hooks";

/**
 * Import types.
 */
import { Item } from "../../../../models/item";

export const Product: React.FC<Item> = ({ title, sku, price }) => {
  const { addItem } = useBasketContext();

  /**
   * Handle qty change
   */
  const handleUpdateQuantity = (quantity: number) => {
    addItem({ sku, quantity, price: price || 0 });
  };

  return (
    <div className="flex items-center justify-between py-1">
      <p className="text-2xs pr-2">{title}</p>
      <div>
        <Quantity
          classes={{
            icons: "h-5 flex justify-center items-center text-lg",
            iconsSize: "text-lg",
            buttons: "flex justify-center items-center",
            buttonsSize: "w-5 h-5",
          }}
          updateQuantity={(qty) => {
            handleUpdateQuantity(qty);
          }}
        />
      </div>
    </div>
  );
};
