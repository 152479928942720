import * as Yup from "yup";
import { translate } from "@clearabee/i18n";
import { stringRequired } from "validation/common";

export const initialValues: {
  driverEmails: string[];
  vehicle: string;
} = {
  driverEmails: [],
  vehicle: "",
};

export const validationSchema = Yup.object().shape({
  vehicle: stringRequired,
  driverEmails: Yup.array().min(
    1,
    translate("portal.drivers.errors.noDriverEmail"),
  ),
});
