"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseError = void 0;
var parseError = function (error, fallback) {
    if (error instanceof Error) {
        return error.message;
    }
    if (typeof error === "string") {
        return error;
    }
    return fallback;
};
exports.parseError = parseError;
