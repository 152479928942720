import React, { useEffect } from "react";
import { Button, formatCurrency, Table, useModal } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { PostBasketResponse } from "@clearabee/ui-sdk";
import dayjs from "dayjs";

interface BulkSuccessModalProps {
  successfulJobs: PostBasketResponse[];
  resetModal: () => void;
  finishedSubmitting: boolean;
  hidePrices?: boolean;
}

export const BulkSuccessModal = ({
  successfulJobs,
  resetModal,
  finishedSubmitting,
  hidePrices = false,
}: BulkSuccessModalProps): React.ReactElement => {
  const [FinishedModal, setFinishedModal] = useModal();
  const [translate] = useTranslation("jobs");

  useEffect(() => {
    if (finishedSubmitting) {
      setFinishedModal(!!successfulJobs.length);
    }
    return;
  }, [successfulJobs, finishedSubmitting]);

  return (
    <FinishedModal
      heading={translate("modal.headings.successfullyBookedJobs")}
      width={1000}
      onClose={() => {
        resetModal();
        setFinishedModal(false);
      }}
      actions={
        <Button
          size="medium"
          color="accent"
          onClick={() => {
            resetModal();
            setFinishedModal(false);
          }}
          type="reset"
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      }
    >
      <Table className="my-6">
        <Table.Header
          headings={[
            translate("table.headings.orderReference"),
            translate("table.headings.jobDate"),
            translate("table.headings.contactPhone"),
            !hidePrices && translate("table.headings.totalCost"),
          ]}
          fontSize="small"
        />
        <Table.Body>
          {successfulJobs.map((job, index) => (
            <Table.Row
              data-testid={`successfully-created-${index}`}
              key={job.id}
            >
              <Table.Cell.Text styles={{ textAlign: "left" }}>
                {job.orderRef}
              </Table.Cell.Text>

              <Table.Cell.Text styles={{ textAlign: "left" }}>
                {dayjs(job.date).format("D/M/YYYY")}
              </Table.Cell.Text>
              <Table.Cell.Text styles={{ textAlign: "left" }}>
                {job.contact?.phoneNumber ?? ""}
              </Table.Cell.Text>
              {!hidePrices && (
                <Table.Cell.Text styles={{ textAlign: "left" }}>
                  {formatCurrency(job.totalCost)}
                </Table.Cell.Text>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </FinishedModal>
  );
};
