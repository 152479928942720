import React from "react";
import { Field, Input, Heading } from "@clearabee/ui-library";
import { IColumn } from "components/suppliers/types";
import { useTranslation } from "react-i18next";

interface SchemaFieldsProps {
  dynamicFormFields: [string, IColumn][];
}

export const SchemaFields = ({
  dynamicFormFields,
}: SchemaFieldsProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");

  const generatedForm = dynamicFormFields.map(
    ([name, column]: [string, IColumn], index: number) => {
      const { type } = column;

      return (
        <div key={`schemaInput${index}`}>
          <Field name={name} type={type === "toggle" ? "checkbox" : undefined}>
            {({ field }) => (
              <div>
                {type === "textArea" && <Input.Textarea {...field} />}
                {type === "textInput" && <Input.Text {...field} />}
                {type === "numberInput" && <Input.Text {...field} />}
                {type === "toggle" && <Input.Toggle {...field} />}
              </div>
            )}
          </Field>
          <div></div>
        </div>
      );
    },
  );

  return (
    <div className="flex flex-col gap-2">
      <Heading color="brand" level={4} fontSize="small">
        {translate("suppliers.headings.supplierTypeFields")}
      </Heading>
      {generatedForm}
    </div>
  );
};
