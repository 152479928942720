import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { theme, Input } from "@clearabee/ui-library";
import { updateCatalogue } from "../../../../api/catalogues";
import { toasts } from "../../../../helpers";

export interface ActiveToggleProps {
  initActive: boolean;
  id: string;
}

export const ActiveToggle = ({
  initActive,
  id,
}: ActiveToggleProps): React.ReactElement => {
  const [active, setActive] = useState(initActive);
  const activeRef = useRef(initActive);

  const { refetch } = useQuery(
    ["updateCatalogue", id],
    () =>
      updateCatalogue(id, {
        active,
      }),
    {
      enabled: false,
      retry: 0,
      onSuccess: (data) => {
        toasts.success({
          message: `${data.title} is now ${active ? "active" : "disabled"}`,
        });
      },
      onError: () => {
        toasts.error({
          message: `Error updating status of catalogue with id of ${id}`,
        });
      },
    },
  );

  useEffect(() => {
    // debounce request
    const timeout = setTimeout(() => {
      if (activeRef.current !== active) {
        refetch();
        activeRef.current = active;
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [active]);

  return (
    <Input.Toggle
      label={active ? "Active" : "Disabled"}
      defaultChecked={active}
      onChange={() => {
        setActive(!active);
      }}
      color="positive"
      styles={{
        marginRight: theme.spacing.medium,
        label: {
          justifyContent: "flex-end",
        },
      }}
    />
  );
};
