import React from "react";
import cx from "classnames";
import {
  useFormikContext,
  FormikFormProps,
  FormikProps,
  FormikValues,
} from "formik";

import "./styles/checkbox.css";

/**
 * Component types.
 */
interface ICheckbox {
  name: string;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  classes?: {
    wrap?: string;
    labelWrap?: string;
    label?: string;
    element?: string;
    visibleElement?: string;
  };
  value?: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Checkbox: React.FC<ICheckbox> = ({
  name,
  label,
  onClick,
  classes = {},
  disabled = false,
}) => {
  /**
   * Get Formik context.
   */
  const { setFieldValue }: FormikProps<FormikFormProps> = useFormikContext();

  const { values }: FormikProps<FormikValues> = useFormikContext();

  const field = {
    name,
    id: `field-${name}`,
    value: name.includes(".")
      ? values[name.split(".")[0]][name.split(".")[1]]
      : values[name],
  };

  return (
    <div className={cx("field-checkbox-wrap", classes.wrap, { disabled })}>
      <input
        className={cx(
          "field-checkbox opacity-0 invisible h-0 w-0",
          classes.element,
          { checked: field.value },
        )}
        id={field.id}
        name={field.name}
        defaultChecked={field.value}
        type="checkbox"
        disabled={disabled}
        onClick={() => {
          setFieldValue(field.name, !field.value);
          if (onClick) {
            onClick();
          }
        }}
        data-testid={`test-${field.name}`}
      />
      <label
        className={cx(
          "field-checkbox-label-wrap cursor-pointer inline-block text-left",
          classes.labelWrap,
        )}
        htmlFor={field.id}
      >
        {label && (
          <span
            className={cx(
              "field-checkbox-label-text block mb-1",
              classes.label,
            )}
          >
            {label}
          </span>
        )}
        <span
          className={cx(
            "field-checkbox-slide relative inline-block h-6 w-10 border border-gray-inactive rounded-full transition ease duration-200",
            classes.visibleElement,
          )}
        />
      </label>
    </div>
  );
};
