import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import form input component.
 */
import { FormInput } from "../../core";

/**
 * Button interface.
 */
interface IInputPassword {
  type?: string;
  name?: string;
  translationKey?: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const InputPassword = ({
  type = "password",
  name = "password",
  translationKey = "password",
}: IInputPassword) => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("auth");

  return (
    <div className="mb-4">
      <FormInput
        name={name}
        type={type}
        placeholder={t(`form.placeholder.${translationKey}`)}
        label={{
          text: t(`form.label.${translationKey}`),
        }}
        required
        togglePasswordVisibility
      />
    </div>
  );
};
