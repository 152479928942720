import React from "react";
import { useQuery } from "react-query";
import { readJobImages } from "api";
import {
  Loader,
  Gallery,
  GalleryItem,
  Panel,
  theme,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { UploadImages } from "./uploadImages/uploadImages";

const ImageBlock = ({
  imageData,
  section,
  handleClick,
}: {
  imageData: GalleryItem[] | undefined;
  section: string;
  handleClick: (index: number) => void;
}): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  return (
    <div className="w-full">
      <span className="text-xs text-black font-semibold">
        <p className="my-2 text-primary-400 text-xl">
          {!!imageData?.length && (
            <>
              <span className="capitalize">{`${section} `}</span>
              {translate("update.imagesAvailable", {
                length: imageData?.length,
              })}
            </>
          )}

          {!imageData?.length &&
            translate("update.noImagesAvailable", {
              type: section,
            })}
        </p>
      </span>
      <div className="flex flex-wrap gap-2 w-full h-full mt-4">
        {imageData?.map((image, index) => (
          <div className="rounded-md w-26 h-26" key={index}>
            <img
              className="rounded-md object-cover w-full h-full cursor-pointer"
              src={image.source}
              onClick={() => handleClick(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const JobImages = ({
  jobRef,
}: {
  jobRef: string | undefined;
}): React.ReactElement => {
  const [translate] = useTranslation("common");
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeStaff = doesUserHaveRole(roles.CLEARABEE_CUSTOMER_SERVICE);

  const {
    data: images,
    isLoading: imagesLoading,
    refetch: imagesRefetching,
  } = useQuery(
    ["readJobImages", jobRef],
    () => readJobImages(encodeURIComponent(jobRef || "")),
    {
      enabled: !!jobRef,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const sortedImages = images?.reduce((final, next) => {
    const type = next.type.replace("bigchange", "job");
    if (final[type]) final[type].push(next.url);
    else final[type] = [next.url];
    return final;
  }, {} as { [key: string]: string[] });

  /**
   * This filter job images only uploading directly from bigchange
   */
  if (jobRef && sortedImages && sortedImages.job) {
    const jobImages = sortedImages.job.filter((image) => {
      // get the filename image from last index splitted by forward slash
      const fileName = image.split("/").at(-1);

      // get the unix timestamp from fileName
      const timeStampFromFileName = new Date(
        Number(fileName?.split("_").at(0) || 0),
      );

      // check if that timeStamp is valid
      const isValidTimeStamp =
        timeStampFromFileName instanceof Date &&
        !isNaN(timeStampFromFileName.valueOf());

      // jobRef does include /, but the fileName does not so we need to remove it
      // since fileName of images uploaded directly from bigchange does not contain jobRef or unix timestamp
      if (
        !!fileName &&
        !fileName.includes(jobRef.replace("/", "")) &&
        !isValidTimeStamp
      ) {
        return image;
      }
    });

    if (!!jobImages.length) {
      sortedImages.job = jobImages; // assign jobImages back
    } else {
      delete sortedImages.job; // delete property job in sortedImage if no jobImages found
    }
  }

  return (
    <div className="mt-3">
      {imagesLoading && <Loader text={translate("loading2")} color="brand" />}
      <div className="grid lg:grid-cols-2 gap-x-10">
        {sortedImages &&
          Object.keys(sortedImages).map((type, index) => (
            <Panel
              key={index}
              style={{
                padding: theme.spacing.small,
              }}
            >
              <Gallery
                key={type}
                items={sortedImages[type].map((image) => ({ source: image }))}
              >
                {({ items, handleClick }) => (
                  <ImageBlock
                    section={type}
                    imageData={items}
                    handleClick={handleClick}
                  />
                )}
              </Gallery>
            </Panel>
          ))}
      </div>
      <div>
        {/* upload image is restricted for Clearabee admin and staff only */}
        {(isClearabeeAdmin || isClearabeeStaff) && (
          <div className="my-10">
            <UploadImages
              jobRef={jobRef ?? ""}
              deleteButtonText="Delete All"
              uploadButtonText="Attach Photo"
              multiple
              onFileChange={(files) => {
                if (!files.length) imagesRefetching();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
