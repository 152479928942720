import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * Import types.
 */
import { IDropdownOption } from "../components/common/types";
import { CatalogueItem } from "../models/catalogueItem";
import { Category } from "../models/category";
import { Item, IDeliveryOption } from "../models/item";
import { ICollectionDateConfig } from "../models/basket";
import { canBookDate } from "@clearabee/ui-sdk";

interface IDeliveryOptionDropdowns {
  timeslotPreferenceOptions: IDropdownOption[];
  timeslotOptions: IDropdownOption[];
}

const SKUsToIngore = [
  "SURCHARGES",
  "WREN",
  "SWYFT",
  "DFS",
  "DUNELM",
  "SHARPS",
  "FISHPOOLS",
  "CUCKCOOLAND",
  "HEALS",
  "SOFASTUFF",
  "HOMEPRO",
  "HANDBALLCHARGE",
  "SKIPPERMIT",
  "CALLOUT",
];

let parents: Array<Category["sku"]> = [];

const recursiveTreeSearch = (
  nextItems: Array<CatalogueItem>,
  lookingFor: CatalogueItem["type"],
  callback: (nextItem: Item) => void,
  recursiveLevels = 0,
) => {
  if (Array.isArray(nextItems)) {
    nextItems.forEach((nextItem) => {
      const { sku, type, children, parentSku } = nextItem;

      // if we are a top level item with no parent, clear the parents array and start again
      if (typeof parentSku === "undefined") {
        parents = [];
      }

      // as we move down the children, we add parents to the array
      // => when we then loop over to the next item on our current level, it contains parents of the children on the previous loop
      // => this operation will clear up those differences
      if (parents.length === recursiveLevels) {
        parents.pop();
      }

      // => this operation is similar to the above, but clears deep parents when we come back up to a higher level
      if (parents.length > recursiveLevels) {
        const difference = parents.length - recursiveLevels;
        const spliceStartIndex = parents.length - difference;
        parents.splice(spliceStartIndex, difference);
      }

      // first check if the SKU is in the ignore list
      // => if an SKU is ignored, all of it's children are ignored too
      if (!SKUsToIngore.includes(sku)) {
        if (typeof parentSku !== "undefined" && !parents.includes(parentSku)) {
          parents.push(parentSku);
        }

        if (type === lookingFor) {
          callback({ ...nextItem, parents: [...parents] });
        }

        if (typeof children !== "undefined") {
          recursiveTreeSearch(
            children,
            lookingFor,
            callback,
            recursiveLevels + 1,
          );
        }
      }
    });
  }
};

export const filterItemFromResults = (
  apiResults: Array<CatalogueItem>,
): Array<Item> => {
  const items: Array<Item> = [];
  recursiveTreeSearch(apiResults, "product", (nextItem) => {
    items.push(nextItem);
  });

  return items;
};

export const filterCategoriesFromResults = (
  apiResults: Array<CatalogueItem>,
): Array<Category> => {
  const categories: Array<Item> = [];

  recursiveTreeSearch(apiResults, "category", (nextItem) => {
    categories.push(nextItem);
  });

  return categories;
};

export const getItemsFromCatalogueByParentSku = (
  catalogueItems: Array<CatalogueItem>,
  lookingFor: CatalogueItem["sku"],
  items: CatalogueItem[] = [],
): Array<CatalogueItem> => {
  if (Array.isArray(catalogueItems)) {
    catalogueItems.forEach((catalogueItem) => {
      const { parentSku, type, children } = catalogueItem;

      if (!SKUsToIngore.includes(lookingFor)) {
        if (parentSku === lookingFor && type === "product") {
          items.push(catalogueItem);
        }

        if (typeof children !== "undefined") {
          getItemsFromCatalogueByParentSku(children, lookingFor, items);
        }
      }
    });
  }

  return items;
};

export const getItemFromCatalogueBySku = (
  catalogueItems: CatalogueItem[],
  lookingFor: CatalogueItem["sku"],
  itemType = "product",
  items: CatalogueItem[] = [],
): CatalogueItem | null => {
  if (Array.isArray(catalogueItems)) {
    catalogueItems.forEach((catalogueItem) => {
      const { sku, type, children } = catalogueItem;

      if (!SKUsToIngore.includes(lookingFor)) {
        if (sku === lookingFor && type === itemType) {
          items.push(catalogueItem);
        }

        if (typeof children !== "undefined") {
          getItemFromCatalogueBySku(children, lookingFor, itemType, items);
        }
      }
    });
  }

  return items.length > 0 ? items[0] : null;
};

// we can search by the following fields
// => title, description, sku, meta.unit, meta.annotation, meta.size, meta.friendly_title
// => not all of them will exist on every product, we have to condtionally check to be sure
export const searchItems = (item: Item, searchQuery: string): boolean => {
  return (
    item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.sku?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.meta?.unit?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    false ||
    item.meta?.annotation?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    false ||
    item.meta?.size?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    false ||
    item.meta?.friendly_title
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase()) ||
    false
  );
};

export const filterItems = (
  items: Array<Item>,
  selectedCategories: Array<Category["sku"]>,
): Array<Item> => {
  return items.filter((item) => {
    const matches =
      item.parents?.filter((parent) => selectedCategories.includes(parent)) ||
      [];
    // we only return items which match ALL filters selected
    // return matches.length === selectedCategories.length
    // if you want to show items which match ANY selected filter, use the below
    return matches.length > 0;
  });
};

export const filterByBlackoutDate = (
  catalogueItems: Array<Item>,
  collectionDate: string,
  postcode?: string,
): Array<Item> => {
  const items = JSON.parse(JSON.stringify(catalogueItems)) as Array<Item>;

  if (!!postcode) {
    items.forEach((item) => {
      item.zones?.forEach((zone) => {
        if (
          zone.postcodes?.some((zonePostcode) =>
            postcode?.includes(zonePostcode.postcode),
          )
        ) {
          item.blackoutDates?.push(...(zone.blackoutDates ?? []));
          item.blackoutDays?.push(...(zone.blackoutDays ?? []));
        }
      });
    });
  }

  return items.filter((item) => {
    return canBookDate(dayjs.utc(collectionDate), {
      blackoutDates: [
        ...(item.blackoutDates || item.inherited?.blackoutDates || []),
      ],
      blackoutDays: [
        ...(item.blackoutDays || item.inherited?.blackoutDays || []),
      ],
      cutoffTime: item.cutoffTime || item.inherited?.cutoffTime,
      leadTime: item.leadTime || item.inherited?.leadTime,
    });
  });
};

/**
 * Get black out dates, days, lead time and cut off time.
 */
export const getCollectionDateConfig = (
  item: CatalogueItem,
): ICollectionDateConfig => {
  return {
    dates: item.blackoutDates || undefined,
    days: item.blackoutDays || undefined,
    cutOff: item.cutoffTime || undefined,
    leadTime: item.leadTime || undefined,
  };
};

/**
 * Get delivery options from catalogue item
 */
export const getDeliveryOptions = (
  item: CatalogueItem | null,
): IDeliveryOption[] | null => {
  if (!item) {
    return null;
  }

  let deliveryOptions = item.deliveryOptions;
  if (deliveryOptions) {
    return deliveryOptions;
  }

  const { inherited } = item;
  if (inherited) {
    deliveryOptions = inherited.deliveryOptions;
  }

  return deliveryOptions || null;
};

/**
 * Parse delivery options for dropdown
 */
export const getDeliveryOptionsForDropdown = (
  deliveryOptions: IDeliveryOption[] | null,
): IDeliveryOptionDropdowns => {
  const timeslotOptions: IDropdownOption[] = [];
  let timeslotPreferenceOptions: IDropdownOption[] = [
    {
      label: "Any",
      value: "Any",
    },
  ];

  if (deliveryOptions) {
    // Set timeslot preference options to empty array
    timeslotPreferenceOptions = [];

    // Loop over delivery options and set timeslot preferences and timeslots
    deliveryOptions?.map((item) => {
      timeslotPreferenceOptions.push({
        label: item.charge ? `${item.title} - £${item.charge}` : item.title,
        value: item.title,
      });

      // If range key exists then set the timeslots
      if (item.range) {
        item.range.map((range) => {
          const { from, to } = range;
          timeslotOptions.push({
            label: `${dayjs(`2020-01-01T${from}`).format("h:mm A")} - ${dayjs(
              `2020-01-01T${to}`,
            ).format("h:mm A")}`,
            value: `${from}-${to}`,
          });
        });
      }
    });
  }

  return { timeslotPreferenceOptions, timeslotOptions };
};

export const getDeliveryOptionChargeFromOption = (
  option: IDropdownOption,
  deliveryOptions: IDeliveryOption[] | null,
): number => {
  return (
    deliveryOptions?.find(
      (deliveryOption) => deliveryOption.title === option.value,
    )?.charge || 0
  );
};

/**
 * Get product's callout charge.
 * Only checks one level deep on parent.
 */
export const getProductCalloutCharge = (
  productSku: string,
  catalogue: CatalogueItem[],
): CatalogueItem | null => {
  if (!catalogue.length) return null;
  // find product from `catalogue` using `productSku`
  const item = getItemFromCatalogueBySku(catalogue, productSku);
  if (!item || !item.parentSku) return null;

  /**
   * NOTE: Leave here for now in case we need separate callout charges per service.
   */
  // find the first category item from item's parentSku
  // const parent = getItemFromCatalogueBySku(
  //   catalogue,
  //   item.parentSku,
  //   "category"
  // )

  // // search category for a *CALLOUTCHARGE product
  // if (parent) {
  //   const callout = parent.children?.find((item) =>
  //     item.sku.includes("CALLOUTCHARGE")
  //   )
  //   return callout?.price || 0
  // }

  if (item.noCalloutCharge) return null;

  const callout = catalogue.find(
    (item) =>
      item.sku.includes("CALLOUTCHARGE") ||
      item.sku.indexOf("CALLOUTCHARGE") !== -1,
  );

  return callout || null;
};
