import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Text,
  Table,
  Button,
  Gallery,
  Icon,
  Input,
  useModal,
  theme,
} from "@clearabee/ui-library";
import { Vehicle } from "@clearabee/api";
import { useInstance } from "api/dsa";
import { useMutation } from "react-query";

interface VehicleRowProps {
  id: string | undefined;
  refetchVehicles: () => void;
  vehicle: Vehicle;
  isLoading: boolean;
  updateStatus: (id: string, status: string) => void;
}

export const VehicleRow = ({
  id,
  vehicle,
  isLoading,
  updateStatus,
  refetchVehicles,
}: VehicleRowProps): React.ReactElement => {
  const [translate] = useTranslation("users");
  const api = useInstance();
  const [showImages, setShowImages] = useState(false);
  const [RemoveModal, setRemoveModal] = useModal();

  const { mutate } = useMutation("removeVehicle", (id: string) =>
    api.vehicles.delete(id),
  );

  return (
    <>
      {/* Modal */}
      <RemoveModal
        heading="Are you sure?"
        width={500}
        onClose={() => {
          setRemoveModal(false);
        }}
        actions={
          <Button
            size="medium"
            color="negative"
            onClick={() => {
              mutate(String(vehicle._id));
              refetchVehicles();
              setRemoveModal(false);
            }}
          >
            Remove
          </Button>
        }
      >
        <Text
          styles={{
            marginTop: theme.spacing.xsmall,
            marginBottom: theme.spacing.xsmall,
          }}
        >
          Do you really want to remove the vehicle? This process cannot be
          undone.
        </Text>
      </RemoveModal>
      {/* Vehicle Row */}
      <Table.Row
        key={`table-row-${vehicle._id}`}
        styles={{ cursor: "pointer" }}
      >
        <Table.Cell.Text>{vehicle.name}</Table.Cell.Text>
        <Table.Cell.Text>{vehicle.registration}</Table.Cell.Text>
        <Table.Cell.Text>{vehicle.vehicleType}</Table.Cell.Text>
        <Table.Cell.Text>{vehicle.user?.company}</Table.Cell.Text>
        <Table.Cell>
          <div className="flex">
            {showImages ? (
              <Gallery
                items={vehicle.images.map((image) => {
                  return { source: image || "" };
                })}
              >
                {({ items, handleClick }) => {
                  return (
                    <ul className="flex items-center">
                      {items.map((item, index) => {
                        if (index < 4) {
                          return (
                            <li key={index}>
                              <img
                                src={item.source}
                                className="w-8 h-8 rounded-full ml-2"
                                onClick={() => handleClick(index)}
                              />
                            </li>
                          );
                        }
                      })}
                      {items.length > 4 && (
                        <li
                          className="ml-3 align-bottom"
                          onClick={() => handleClick(4)}
                        >
                          <Icon.Plus size="medium" color="brand" />
                        </li>
                      )}
                    </ul>
                  );
                }}
              </Gallery>
            ) : (
              <Button
                onClick={() => setShowImages(true)}
                size="small"
                color="accent"
              >
                {translate("subcontractors.buttons.showImages")}
              </Button>
            )}
          </div>
        </Table.Cell>
        <Table.Cell.Text className="capitalize">
          {isLoading && vehicle._id === (id as unknown as Vehicle["_id"]) ? (
            <Icon.Loading color="brand" size="small" />
          ) : (
            vehicle.status
          )}
        </Table.Cell.Text>
        <Table.Cell>
          <div className="self-center flex items-center justify-between">
            <Input.Toggle
              color="positive"
              disabled={isLoading}
              checked={vehicle.status === "approved"}
              onChange={() =>
                updateStatus(
                  String(vehicle._id),
                  vehicle.status === "approved" ? "pending" : "approved",
                )
              }
            />
            <Button
              size="xsmall"
              color="negative"
              onClick={() => setRemoveModal(true)}
            >
              Remove
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  );
};
