import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export type AllowedColorKeys = keyof typeof theme.colors;
export type AllowedSizeKeys = keyof typeof theme.fontDefaults;

export const styles = StyleSheet({
  defaults: {
    color: theme.colors.greyscale.base,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    flexShrink: 0,
    marginRight: theme.spacing.xsmall,
  },
  small: {
    marginRight: theme.spacing.xsmall2,
  },
  medium: {
    marginRight: theme.spacing.xsmall,
  },

  wrap: {
    ...Object.assign(
      {},
      ...Object.keys(theme.colors).map((accessor) => ({
        [accessor]: {
          backgroundColor:
            theme.colors[accessor as keyof typeof theme.colors].base,
          padding: theme.spacing.xsmall,
          borderRadius: theme.spacing.xsmall,
          fontWeight: 600,
        },
      })),
    ),
  },
  background: {
    alignSelf: "flex-start",
    padding: theme.spacing.xsmall2,
    borderRadius: theme.spacing.xsmall2,
    ...Object.assign(
      {},
      ...Object.keys(theme.colors).map((accessor) => ({
        [accessor]: {
          background:
            theme.colors[accessor as unknown as keyof typeof theme.colors].base,
        },
      })),
    ),
  },
  ...Object.assign(
    {},
    ...Object.keys(theme.colors).map((accessor) => ({
      [accessor]: {
        color: theme.colors[accessor as keyof typeof theme.colors].base,
      },
    })),
  ),
});
