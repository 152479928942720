/**
 * Import helpers.
 */
import { IPaymentLinksCount, IPaymentTotal } from "../../api/leaderboard";
import { formatToPrice } from "../../helpers";
import { IDataRow } from "./types";

/**
 * Parse get all payments data to cell data.
 */
export const parsePaymentsToCellData = (data: IPaymentTotal[]): IDataRow[] => {
  const sortedArray = data.sort((first, second) => second.total - first.total);
  return sortedArray.map((entry) => ({
    title: entry.name,
    value: formatToPrice(entry.total),
  }));
};

/**
 * Parse payments into bookings count data.
 */
export const parseBookingsToCellData = (data: IPaymentTotal[]): IDataRow[] => {
  const sortedArray = data.sort(
    (first, second) => second.bookings - first.bookings,
  );
  return sortedArray.map((entry) => ({
    title: entry.name,
    value: entry.bookings,
  }));
};

/**
 * Parse clerabee email to get full name
 */
const fullNameWithoutEmail = (clearabeeEmail: string): string =>
  clearabeeEmail
    .substring(0, clearabeeEmail.lastIndexOf("@"))
    .split(".")
    .toString()
    .split(",")
    .join(" ");

/**
 * Parse paid payment links operator data.
 */
export const parsePaidPaymentLinksToCellData = (
  data: IPaymentLinksCount[],
): IDataRow[] => {
  const sortedArray = data.sort(
    (first, second) => Number(second.paid ?? 0) - Number(first.paid ?? 0),
  );

  return sortedArray.map((entry) => ({
    title: fullNameWithoutEmail(entry.operator),
    value: entry.paid ?? 0,
  }));
};
/**
 * Parse pending payment links operator data.
 */
export const parsePendingPaymentLinksToCellData = (
  data: IPaymentLinksCount[],
): IDataRow[] => {
  const sortedArray = data.sort(
    (first, second) => Number(second.pending ?? 0) - Number(first.pending ?? 0),
  );

  return sortedArray.map((entry) => ({
    title: fullNameWithoutEmail(entry.operator),
    value: entry.pending ?? 0,
  }));
};
