import axios from "axios";

export interface IWebsiteOrders {
  total: number;
  value: number;
}

/**
 * Get all website orders.
 */
export const getAllWebsiteOrders = async (
  from: string,
  to: string,
): Promise<IWebsiteOrders> => {
  const orders = await axios.get("/payments/total-orders", {
    params: {
      from,
      to,
    },
  });

  return orders.data as IWebsiteOrders;
};

export interface IPaymentTotal {
  name: string;
  total: number;
  bookings: 0;
}

/**
 * Get total payment count.
 */
export const getTotalPaymentCount = async (
  from: string,
  to: string,
): Promise<IPaymentTotal[]> => {
  const payments = await axios.get("/payments/payment-count/total", {
    params: {
      from,
      to,
    },
  });

  return payments.data as IPaymentTotal[];
};

export interface IPaymentLinksCount {
  operator: string;
  pending: string | undefined;
  paid: string | undefined;
  expired: string | undefined;
  total: string | undefined;
}

/**
 * Get payment links operator data.
 */
export const getPaymentLinksCount = async (
  from: string,
  to: string,
): Promise<IPaymentLinksCount[]> => {
  const paymentsLinks = await axios.get(
    "/catalogues/baskets/payment-links/count",
    {
      params: {
        from,
        to,
      },
    },
  );

  return paymentsLinks.data as IPaymentLinksCount[];
};
