import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Heading, Panel, theme } from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { readCompaniesForTables } from "../../api";
import { PaginatedResults } from "../common/components";
import { CompanyActive, CompanyFilters } from "./components";

const tableColumns = [
  {
    Header: "Status",
    accessor: "status",
    Cell: CompanyActive,
    width: 75,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 200,
  },
  {
    Header: "Contact Email",
    accessor: "contactEmail",
    width: 200,
  },
  {
    Header: "Company Code",
    accessor: "companyCode",
  },
];

export const ReadCompanies = (): React.ReactElement => {
  const [translate] = useTranslation("companies");
  return (
    <div data-testid="readCompaniesComponentIdentifier">
      <PaginatedResults
        apiFn={readCompaniesForTables}
        queryKey="readCompanies"
        tableColumns={tableColumns}
        actions={["edit"]}
      >
        {({ isFetching, updateFilters }) => (
          <>
            <Panel styles={{ marginBottom: theme.spacing.large }}>
              <div className="flex flex-row items-center justify-between">
                <Heading level={1} fontSize="large" color="brand">
                  {translate("common.title")}
                </Heading>
                <RouteLink href="/companies/create">
                  <Button size="small" as="a" className="inline-block">
                    {translate("common.addCompany")}
                  </Button>
                </RouteLink>
              </div>
              <CompanyFilters
                isFetching={isFetching}
                updateFilters={updateFilters}
              />
            </Panel>
          </>
        )}
      </PaginatedResults>
    </div>
  );
};
