import React, { useRef, useEffect, useMemo, useState } from "react";
import { CSSInterpolation } from "@emotion/serialize";
import { Pagination, PaginationState } from "../Pagination";

export interface PaginationOptions {
  initialPage?: number;
  initialResultSize?: number;
  resultOptions?: number[];
  totalPages?: number;
  enabled?: boolean;
  cacheTime?: number;
  showRowsPerPage?: boolean;
}

export function determineDataRange({
  currentPage,
  resultsPerPage,
}: PaginationState): [number, number] {
  const startRange = (currentPage - 1) * resultsPerPage;
  const endRange = startRange + resultsPerPage;

  return [startRange, endRange];
}

export function usePagination<DataType>(
  data: DataType[],
  options?: PaginationOptions,
  paginationStyles?: Record<
    | "mainContainer"
    | "wrapper"
    | "carousel"
    | "stepButton"
    | "rowPerPageWrap"
    | "resultsDropdownWrap",
    CSSInterpolation
  >,
): {
  paginatedData: DataType[];
  dataRange: number[];
  PaginationComponent: () => React.ReactElement;
  paginationState: PaginationState;
  initialPaginationState: PaginationState;
  setPaginationState: (value: PaginationState) => void;
  setDataRange: (value: number[]) => void;
} {
  const hasPreviousData = useRef(!!data.length);
  const initialPaginationState: PaginationState = {
    currentPage: options?.initialPage || 1,
    totalPages:
      options?.totalPages ||
      Math.ceil(data.length / (options?.initialResultSize || 10)),
    resultsPerPage: options?.initialResultSize || 10,
    resultOptions: options?.resultOptions || [5, 10, 20],
  };
  const [initialStartRange, initialEndRange] = determineDataRange(
    initialPaginationState,
  );
  const [dataRange, setDataRange] = useState([
    initialStartRange,
    initialEndRange,
  ]);
  const [paginatedData, setPaginatedData] = useState(
    data.slice(initialStartRange, initialEndRange),
  );
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  /**
   * Accepts a new page number and updates both the pagination state and paginated data
   */
  const updatePage = (newPage: number) => {
    const updatedState = {
      ...paginationState,
      currentPage: newPage,
    };
    setPaginationState(updatedState);
    const [startRange, endRange] = determineDataRange(updatedState);
    setDataRange([startRange, endRange]);
    setPaginatedData(data.slice(startRange, endRange));
  };
  /**
   * Accepts a 'results' number and uses the value to update the amount of results returned from the paginator
   */
  const updateResultsPerPage = (results: number) => {
    const updatedState = {
      ...paginationState,
      currentPage: options?.initialPage || 1,
      resultsPerPage: results,
      totalPages: Math.ceil(data.length / results),
    };
    setPaginationState(updatedState);
    const [startRange, endRange] = determineDataRange(updatedState);
    setDataRange([startRange, endRange]);
    setPaginatedData(data.slice(startRange, endRange));
  };

  /**
   * If data changes at any point (ie - filters are applied), reset the pagination component to default state
   */
  useEffect(() => {
    if (data.length || hasPreviousData.current) {
      const updatedState = {
        ...initialPaginationState,
        currentPage: paginationState.currentPage,
        resultsPerPage: paginationState.resultsPerPage,
        resultOptions: paginationState.resultOptions,
      };

      setPaginationState(updatedState);

      setPaginatedData(data.slice(0, updatedState.resultsPerPage));
    }

    hasPreviousData.current = !!data.length;
  }, [data]);

  const PaginationComponent = useMemo(() => {
    return function PaginationNamedFunction() {
      return (
        <Pagination
          data-testid="pagination"
          onPrev={() => {
            paginationState.currentPage !== 1 &&
              updatePage(paginationState.currentPage - 1);
          }}
          onNext={() => {
            paginationState.currentPage < paginationState.totalPages &&
              updatePage(paginationState.currentPage + 1);
          }}
          paginationState={paginationState}
          onPageChange={(pageNumber: number) => {
            if (
              Number(pageNumber) <= paginationState.totalPages &&
              Number(pageNumber) > 0
            ) {
              updatePage(Number(pageNumber));
            }
          }}
          onResultsChange={(results: number) => {
            updateResultsPerPage(results);
          }}
          showRowsPerPage={options?.showRowsPerPage}
          userStyles={paginationStyles}
        />
      );
    };
  }, [
    paginationState,
    paginatedData,
    dataRange,
    setPaginationState,
    initialPaginationState,
    setDataRange,
    paginationStyles,
  ]);
  return {
    paginatedData,
    dataRange,
    PaginationComponent,
    paginationState,
    setPaginationState,
    initialPaginationState,
    setDataRange,
  };
}
