import React, { useState } from "react";
import { FieldArray } from "formik";
import {
  Panel,
  Form,
  Button,
  Field,
  Heading,
  Input,
  Text,
  Icon,
  Link,
  theme,
  CustomFile,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { getValidation } from "../validation";
import { Template, TPricingModel } from "models/template";
import { useQuery } from "react-query";
import { getCatalogueOrderTypes } from "api/catalogues";
import { getTemplateImagePresignedUrl, uploadTemplateImage } from "api";
import { toasts } from "helpers/toasts";
import { urlToFile } from "helpers";
import { RouteLink } from "components/core";
import { CatalogueItem, Item } from "models";
import { SKUField } from "./skuField";
import { AddDeliveryOption } from "./addDeliveryOption";
import { EditZoneOption } from "./editZoneOption";
import { ButtonLoader } from "./buttonLoader";
import { useAuthContext } from "hooks";
import roles from "constants/roles";

export interface ItemFormValues
  extends Omit<
    Template,
    | "blackoutDays"
    | "price"
    | "sku"
    | "orderTypeId"
    | "leadTime"
    | "supplierMargin"
    | "blackoutDates"
    | "pricingModel"
    | "img"
  > {
  sku?: string;
  blackoutDays: boolean[];
  blackoutDates: string[];
  deliveryOptions?: {
    title: string;
    charge?: number;
    range?: {
      to: string;
      from: string;
    }[];
  }[];
  price: string;
  orderTypeId?: number;
  leadTime: string;
  supplierMargin: string;
  pricingModel: "" | TPricingModel;
  img?: CustomFile;
  zones?: Item["zones"];
}

export interface ItemForValuesWithUploadedImage
  extends Omit<ItemFormValues, "img"> {
  img: string | null;
}

export interface CreateUpdateTemplateFormProps {
  initialValues?: CatalogueItem | Template;
  allowDeliveryOptionsEdit?: boolean;
  allowZoneOptionsEdit?: boolean;
  heading?: string;
  disableSubmit?: boolean;
  includeSkuField?: boolean;
  onSubmit: (values: ItemForValuesWithUploadedImage) => void;
  onCascadeMode?: (values: ItemFormValues) => void;
  cancelUrl: string;
  blueprint?: Template;
  isLoading?: boolean;
}

const cutOffOptions = Array.from(Array(24).keys())
  .reduce((options: string[], hour) => {
    return [
      ...options,
      ...[0, 15, 30, 45].map(
        (minute) => `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}`,
      ),
    ];
  }, [])
  .map((time) => {
    return {
      value: `${time}:00`,
      label: time,
    };
  });
const dayKeys = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
const typeOptions = ["category", "product"];
const pricingModelOptions = ["override", "postcode", "suppliers"];

const createInitialValues = (
  data: CatalogueItem | Template,
): ItemFormValues => {
  let deliveryOptions;
  let zones;
  /**
   * Type guarding - if the data being passed to this contains delivery options and zones,
   * then make sure "data" is typed as a catalogueItem, which contains delivery options and zones.
   * (Template does not contain delivery options and zones)
   */
  if ((data as CatalogueItem).deliveryOptions !== undefined) {
    deliveryOptions = (data as CatalogueItem).deliveryOptions;
  }

  if ((data as CatalogueItem).zones !== undefined) {
    zones = (data as CatalogueItem).zones;
  }

  const blackoutDays = new Array(7).fill(false).map((_, index) => {
    return !data?.blackoutDays ? false : !!data?.blackoutDays.includes(index);
  });
  return {
    ...data,
    img: data.img ? urlToFile(data.img) : undefined,
    pricingModel: data.pricingModel || "",
    description: data.description ?? "",
    price: data.price?.toString() ?? "",
    orderTypeId: data.orderTypeId ? data.orderTypeId : undefined,
    leadTime: data.leadTime ? data.leadTime?.toString() : "",
    cutoffTime: data.cutoffTime ?? "",
    baseTime: data.baseTime ?? 0,
    unitTime: data.unitTime ?? 5,
    multiplier: data.multiplier ?? 0,
    requiresOrderRef: data.requiresOrderRef ?? undefined,
    noCalloutCharge: data.noCalloutCharge ?? undefined,
    supplierMargin: data.supplierMargin ? data.supplierMargin?.toString() : "",
    blackoutDays,
    ...(deliveryOptions !== undefined && {
      deliveryOptions: deliveryOptions || [],
    }),
    blackoutDates: data.blackoutDates || [""],
    zones,
  };
};

export const CreateUpdateItemForm = ({
  initialValues: propInitialValues,
  disableSubmit: propDisableSubmit,
  allowDeliveryOptionsEdit = false,
  allowZoneOptionsEdit = false,
  heading,
  includeSkuField = false,
  onSubmit,
  onCascadeMode,
  cancelUrl,
  blueprint,
  isLoading: propIsLoading,
}: CreateUpdateTemplateFormProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [disableSubmit, setDisableSubmit] = useState(propDisableSubmit);
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);

  const {
    isLoading,
    isError,
    isFetching,
    refetch,
    data: paginatedOrderTypes,
  } = useQuery(["getCatalogueOrderTypes"], () => getCatalogueOrderTypes(), {
    cacheTime: 0,
    staleTime: 0,
  });

  const blankValues: ItemFormValues = {
    sku: blueprint?.sku ?? "",
    img: blueprint?.img ? urlToFile(blueprint.img) : undefined,
    pricingModel: blueprint?.pricingModel ?? "",
    price: String(blueprint?.price ?? ""),
    orderTypeId: blueprint?.orderTypeId ?? undefined,
    description: blueprint?.description ?? "",
    leadTime: String(blueprint?.leadTime ?? ""),
    baseTime: blueprint?.baseTime ?? 0,
    unitTime: blueprint?.unitTime ?? 5,
    multiplier: blueprint?.multiplier ?? 0,
    supplierMargin: String(blueprint?.supplierMargin ?? ""),
    deliveryOptions: [],
    requiresOrderRef: blueprint?.requiresOrderRef ?? undefined,
    noCalloutCharge: blueprint?.noCalloutCharge ?? undefined,
    cutoffTime: blueprint?.cutoffTime ?? "",
    blackoutDays:
      new Array(7)
        .fill(true)
        .map((_, index) => !!blueprint?.blackoutDays?.includes(index)) ?? [],
    blackoutDates: blueprint?.blackoutDates ?? [""],
    title: blueprint?.title ?? "",
    type: blueprint?.type ?? "product",
    active: blueprint?.active ?? true,
    zones: [],
  };

  const initialValues: ItemFormValues = !propInitialValues
    ? blankValues
    : createInitialValues(propInitialValues);

  const orderTypesOptions =
    (!isLoading &&
      !isFetching &&
      !isError &&
      paginatedOrderTypes?.items.map(({ name, id }) => ({
        value: id || 0,
        label: name,
      }))) ||
    [];

  /**
   * Upload a single image to S3 bucket.
   */
  const uploadImage = async ({ file, dataUrl }: CustomFile) => {
    if (!file.name) {
      return dataUrl;
    }

    const { name: filename } = file;
    const presignedUrlResponse = await getTemplateImagePresignedUrl(filename);
    const { url, fields } = presignedUrlResponse;

    return uploadTemplateImage(file, url, fields);
  };

  return (
    <Form
      initialValues={
        !propInitialValues
          ? blankValues
          : createInitialValues(propInitialValues)
      }
      validationSchema={getValidation(includeSkuField)}
      onSubmit={async (values) => {
        // CONVERT charge to a number if delivery options are provided
        try {
          const formattedDeliveryOptions = values.deliveryOptions
            ? values.deliveryOptions.map(({ title, charge, range }) => {
                return {
                  title: title,
                  charge: Number(Number(charge).toFixed(2)),
                  range: range,
                };
              })
            : undefined;

          const img = values.img
            ? decodeURIComponent(await uploadImage(values.img))
            : null;

          values.orderTypeId = Number(values.orderTypeId);

          onSubmit({
            ...values,
            ...(formattedDeliveryOptions !== undefined && {
              deliveryOptions: formattedDeliveryOptions,
            }),
            img,
          });
        } catch (error) {
          toasts.error({
            message: `Image could not be uploaded. Try again. ${error}`,
          });
        }
      }}
    >
      {({ values, setFieldValue, isValid }) => (
        <>
          <Panel
            styles={{
              paddingRight: theme.spacing.medium,
              paddingLeft: theme.spacing.medium,
              paddingTop: theme.spacing.xsmall,
              paddingBottom: theme.spacing.xsmall,
            }}
            shadow={false}
          >
            <div className="md:flex mx-auto w-full items-center">
              <div className="w-full md:w-1/2">
                {heading && (
                  <Heading level={5} color="brand">
                    {heading}
                  </Heading>
                )}
              </div>
              <div className="w-full md:w-1/2 flex">
                <div className="ml-auto text-right">
                  <Field name="active" type="checkbox">
                    {({ field }) => (
                      <Input.Toggle
                        color="positive"
                        label="Active"
                        disabled={propIsLoading}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </Panel>
          <div
            className="xl:flex mx-auto w-full"
            css={{ marginTop: theme.spacing.medium }}
          >
            <div className="w-full xl:w-7/12 flex">
              <div className="w-full md:w-1/2 mr-8">
                {includeSkuField && (
                  <SKUField
                    disabled={propIsLoading}
                    label={translate("form.labels.sku")}
                    placeholder={translate("form.placeholders.sku")}
                    onLoading={(loading) => {
                      // Is sku validation is loading, disable button else default back to prop
                      setDisableSubmit(!loading ? false : propDisableSubmit);
                    }}
                  />
                )}
                <Field name="title" label={translate("form.labels.title")}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      type="text"
                      placeholder={translate("form.placeholders.title")}
                      disabled={propIsLoading}
                    />
                  )}
                </Field>
                <Field
                  name="description"
                  label={translate("form.labels.description")}
                >
                  {({ field }) => (
                    <Input.Textarea
                      {...field}
                      disabled={propIsLoading}
                      slim
                      placeholder={translate("form.placeholders.description")}
                    />
                  )}
                </Field>
                <Field name="type" label={translate("form.labels.type.type")}>
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={initialValues.type}
                      options={typeOptions.map((value) => ({
                        value,
                        label: translate(`form.labels.type.${value}`),
                      }))}
                      disabled={propIsLoading}
                      placeholder={translate("form.placeholders.type")}
                    />
                  )}
                </Field>
                <Field
                  name="pricingModel"
                  label={translate("form.labels.pricingModel.pricingModel")}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={initialValues.pricingModel || undefined}
                      options={pricingModelOptions.map((value) => ({
                        value,
                        label: translate(`form.labels.pricingModel.${value}`),
                      }))}
                      disabled={values.type === "category" || propIsLoading}
                      placeholder={translate("form.placeholders.pricingModel")}
                    />
                  )}
                </Field>
                <Field name="price" label={translate("form.labels.price")}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={
                        values.pricingModel !== "override" ||
                        values.type === "category" ||
                        propIsLoading
                      }
                      type="text"
                      prefix="£"
                      placeholder={translate("form.placeholders.price")}
                      value={
                        values.pricingModel !== "override" ? "" : field.value
                      }
                    />
                  )}
                </Field>
                <Text
                  as="span"
                  fontSize="small"
                  style={{
                    display: "block",
                    fontWeight: 600,
                    marginBottom: theme.spacing.xsmall2,
                  }}
                >
                  {translate("form.labels.blackOutDays")}
                </Text>
                <div
                  className="grid grid-cols-7 gap-4"
                  css={{
                    marginRight: theme.spacing.xlarge4,
                  }}
                >
                  {dayKeys.map((key, index) => (
                    <Field
                      key={key}
                      name={`blackoutDays.${index}`}
                      styles={{
                        label: {
                          marginTop: theme.spacing.xsmall,
                        },
                        marginBottom: 0,
                      }}
                    >
                      {({ field }) => (
                        <Input.Checkbox
                          {...field}
                          defaultChecked={field.value}
                          label={translate(`form.labels.days.${key}`)}
                          labelPosition="top"
                          disabled={propIsLoading}
                          styles={{
                            ".checkbox-control": {
                              background: "white",
                            },
                          }}
                        />
                      )}
                    </Field>
                  ))}
                </div>
                <Text
                  as="span"
                  fontSize="small"
                  style={{
                    display: "block",
                    fontWeight: 600,
                    marginTop: theme.spacing.small,
                  }}
                >
                  {translate("form.labels.blackOutDates")}
                </Text>
                <FieldArray
                  name="blackoutDates"
                  render={({ remove }) => (
                    <>
                      {values.blackoutDates.map(
                        (date: string, index: number) => (
                          <div className="flex mb-2" key={`${date}-${index}`}>
                            <div className={index !== 0 ? "w-11/12" : "w-full"}>
                              <Input.Date
                                name={`blackoutDates.${index}`}
                                dateFormat="YYYY-MM-DD"
                                initialValue={values.blackoutDates[index]}
                                placeholder={translate(
                                  "form.placeholders.blackOutDates",
                                )}
                                onChange={(e) => {
                                  setFieldValue(
                                    `blackoutDates.${index}`,
                                    e.target.value,
                                  );
                                }}
                                disabled={propIsLoading}
                                collapsable
                              />
                            </div>
                            {index !== 0 && (
                              <Link
                                onClick={() => {
                                  remove(index);
                                }}
                                className="m-auto mr-0"
                                data-testid="remove-blackout-date"
                              >
                                <Icon.Close size="small" />
                              </Link>
                            )}
                          </div>
                        ),
                      )}
                    </>
                  )}
                />

                <div className="flex flex-row items-center justify-between">
                  {/* View and edit delivery options for this catalogue item. You can define names,
                  time slots, and charges for these delivery items */}
                  {allowDeliveryOptionsEdit && (
                    <AddDeliveryOption values={values} />
                  )}
                  <Link
                    styles={{
                      ...theme.fontDefaults.xsmall,
                      marginLeft: theme.spacing.xsmall,
                      fontWeight: 800,
                    }}
                    underline
                    onClick={() =>
                      setFieldValue("blackoutDates", [
                        ...(values.blackoutDates || []),
                        "",
                      ])
                    }
                  >
                    {translate("addAnotherBlackoutDate")}
                  </Link>
                </div>
                <div className="w-full">
                  {/* View and edit zone options for this catalogue item. */}
                  {allowZoneOptionsEdit && <EditZoneOption values={values} />}
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:mr-8 relative">
                <Field
                  name="cutoffTime"
                  label={translate("form.labels.cutoffTime")}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={initialValues.cutoffTime || undefined}
                      options={cutOffOptions}
                      placeholder={translate("form.placeholders.cutoffTime")}
                      isClearable
                      disabled={propIsLoading}
                    />
                  )}
                </Field>
                <Field
                  name="leadTime"
                  label={translate("form.labels.leadTime")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={propIsLoading}
                      type="number"
                      placeholder={translate("form.placeholders.leadTime")}
                    />
                  )}
                </Field>
                <Field
                  name="baseTime"
                  label={translate("form.labels.baseTime")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={propIsLoading}
                      type="number"
                      placeholder={translate("form.placeholders.baseTime")}
                    />
                  )}
                </Field>
                <Field
                  name="unitTime"
                  label={translate("form.labels.unitTime")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={propIsLoading}
                      type="number"
                      placeholder={translate("form.placeholders.unitTime")}
                    />
                  )}
                </Field>
                <Field
                  name="multiplier"
                  label={translate("form.labels.multiplier")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={propIsLoading}
                      type="number"
                      placeholder={translate("form.placeholders.multiplier")}
                    />
                  )}
                </Field>
                <div className="border-b-2 w-full my-6" />
                <Field
                  name="supplierMargin"
                  label={translate("form.labels.supplierMargin")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      type="text"
                      prefix="%"
                      disabled={propIsLoading}
                    />
                  )}
                </Field>
                <div className="absolute right-0">
                  <Link className="font-semibold" onClick={() => refetch()}>
                    {isFetching ? "Loading..." : "Refresh"}
                  </Link>
                </div>
                <Field
                  name="orderTypeId"
                  label={translate("form.labels.orderTypeId")}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={initialValues.orderTypeId}
                      options={orderTypesOptions}
                      placeholder={translate("form.placeholders.orderTypeId")}
                      disabled={isLoading || isFetching || propIsLoading}
                      isLoading={isLoading}
                      isSearchable
                    />
                  )}
                </Field>
                <Link
                  target="_blank"
                  href="/catalogues/order-type-ids"
                  className="font-semibold"
                >
                  {translate("orderType.manageOrderTypes")}
                </Link>
                <Field name="requiresOrderRef">
                  {({ field }) => (
                    <Input.Checkbox
                      {...field}
                      label={translate("form.labels.requiresOrderReference")}
                      disabled={propIsLoading}
                      styles={{
                        marginTop: theme.spacing.small,
                        ".checkbox-control": {
                          background: "white",
                        },
                      }}
                      defaultChecked={
                        initialValues.requiresOrderRef || undefined
                      }
                    />
                  )}
                </Field>
                <Field name="noCalloutCharge">
                  {({ field }) => (
                    <Input.Checkbox
                      {...field}
                      label={translate("form.labels.noCalloutCharge")}
                      disabled={propIsLoading}
                      styles={{
                        marginTop: theme.spacing.small,
                        ".checkbox-control": {
                          background: "white",
                        },
                      }}
                      defaultChecked={initialValues.noCalloutCharge}
                    />
                  )}
                </Field>
                <div style={{ marginTop: theme.spacing.large }}>
                  <Field name="img" label={translate("form.placeholders.img")}>
                    <div style={{ marginTop: theme.spacing.small }}>
                      <Input.File
                        name="img"
                        defaultValues={
                          initialValues.img ? [initialValues.img] : []
                        }
                        disabled={propIsLoading}
                        deleteButtonText={translate("form.labels.removeImage")}
                        uploadButtonText={translate("form.labels.uploadImage")}
                        onFileChange={(files) => setFieldValue("img", files[0])}
                      />
                    </div>
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-full mt-5 xl:mt-0 xl:w-5/12">
              <Panel
                styles={{ padding: theme.spacing.medium }}
                shadow={false}
                color="grey"
              >
                <Text
                  as="p"
                  fontSize="small"
                  styles={{
                    fontWeight: 600,
                    marginLeft: theme.spacing.medium,
                    marginTop: theme.spacing.medium,
                  }}
                >
                  {translate("form.headings.meta")}
                </Text>
                <div
                  className="md:flex mx-auto w-full"
                  css={{
                    paddingLeft: theme.spacing.large,
                    paddingRight: theme.spacing.large,
                  }}
                >
                  <div className="w-full md:w-1/2 md:mr-4">
                    <Field
                      name="meta.friendlyTitle"
                      label={translate("form.labels.friendlyTitle")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate(
                            "form.placeholders.friendlyTitle",
                          )}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.annotation"
                      label={translate("form.labels.annotation")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate(
                            "form.placeholders.annotation",
                          )}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.serviceType"
                      label={translate("form.labels.serviceType")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate(
                            "form.placeholders.serviceType",
                          )}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.fromPrice"
                      label={translate("form.labels.fromPrice")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          prefix="£"
                          disabled={propIsLoading}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.itemCount"
                      label={translate("form.labels.itemCount")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate("form.placeholders.itemCount")}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="w-full md:w-1/2 md:ml-4">
                    <Field
                      name="meta.priority"
                      label={translate("form.labels.priority")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate("form.placeholders.priority")}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.yards"
                      label={translate("form.labels.yards")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate("form.placeholders.yards")}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.volume"
                      label={translate("form.labels.volume")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate("form.placeholders.volume")}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.size"
                      label={translate("form.labels.size")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate("form.placeholders.size")}
                        />
                      )}
                    </Field>
                    <Field
                      name="meta.weight"
                      label={translate("form.labels.weight")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="text"
                          disabled={propIsLoading}
                          placeholder={translate("form.placeholders.weight")}
                        />
                      )}
                    </Field>
                    <Field name="meta.popular">
                      {({ field }) => (
                        <Input.Checkbox
                          {...field}
                          label={translate("form.labels.popular")}
                          defaultChecked={!!initialValues.meta?.popular}
                          disabled={propIsLoading}
                          styles={{
                            marginTop: theme.spacing.medium,
                            ".checkbox-control": {
                              background: "white",
                            },
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </Panel>
              <div className="flex justify-end">
                {onCascadeMode && (
                  <Button
                    size="medium"
                    color="greyscale"
                    type="button"
                    variant="outline"
                    disabled={disableSubmit || !isValid || propIsLoading}
                    styles={{
                      marginLeft: theme.spacing.small,
                      marginTop: theme.spacing.medium,
                      textAlign: "center",
                    }}
                    onClick={() => onCascadeMode(values)}
                  >
                    {translate("form.buttons.cascade")}
                  </Button>
                )}
                <RouteLink href={cancelUrl}>
                  <Button
                    size="medium"
                    color="negative"
                    as="a"
                    disabled={propIsLoading}
                    styles={{
                      marginLeft: theme.spacing.small,
                      marginTop: theme.spacing.medium,
                      textAlign: "center",
                    }}
                  >
                    {translate("form.buttons.cancel")}
                  </Button>
                </RouteLink>
                <Button
                  size="medium"
                  color="accent"
                  type="submit"
                  disabled={
                    disableSubmit || propIsLoading || isClearabeeManager
                  }
                  styles={{
                    marginLeft: theme.spacing.small,
                    marginTop: theme.spacing.medium,
                  }}
                >
                  {propIsLoading && <ButtonLoader />}
                  {!propIsLoading && translate("form.buttons.save")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};
