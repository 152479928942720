"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissions = void 0;
exports.permissions = {
    headings: {
        permissions: "Permissions",
        heading: "Add New Permission",
    },
    labels: {
        name: "Name",
        createdOn: "Created on",
    },
    buttons: {
        addPermission: "Add Permission",
        modalButton: "Close",
        loading: "Loading",
    },
    form: {
        labels: {
            name: "Permission Name",
        },
    },
    success: {
        title: "Created permission",
    },
    errors: {
        errorMessage: "Unfortuanlty we can't handle your request right now, try again later.",
        referenceRequired: "reference is required",
    },
};
