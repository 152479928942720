import { instance } from "@clearabee/ui-sdk";
import type {
  Answer,
  AnswersByWorksheet,
  QuestionIdToAnswerIdMap,
} from "models";

type Job = Awaited<ReturnType<typeof instance.jobs.getJob>>["data"];

/**
 * Get the timestamp of the 'pending' audit for a job
 */
export const getPendingTimestamp = (
  job: Awaited<
    ReturnType<typeof instance.jobs.getJobs>
  >["data"]["items"][number],
): Date | null => {
  const pendingAuditTimestamp = job.audits?.find(
    ({ valueAfter }) => (valueAfter as string).toLowerCase() === "pending",
  )?.timestamp;

  // timestamp is a string, but the type is incorrect, so coversion to Date is required
  return pendingAuditTimestamp ? new Date(pendingAuditTimestamp) : null;
};

/**
 * Sort jobs by the 'pending' audit timestamp
 * @param jobs - Jobs to sort
 * @param oldestFirst - Sort by the oldest first (default)
 */
export const sortPendingJobsByAudit = (
  jobs: Awaited<ReturnType<typeof instance.jobs.getJobs>>["data"]["items"],
  oldestFirst = true,
) => {
  return jobs.sort((a, b) => {
    // Move pending jobs without audits to the top of the list:
    // there should always be an audit trail, but this accounts for inconsistencies and errors in the database.
    // Moving them to the top encourages operators to investigate.
    if (!a.audits?.length) return -1;

    const aPendingAudit = a.audits?.find(
      ({ valueAfter }) => (valueAfter as string).toLowerCase() === "pending",
    );

    const bPendingAudit = b.audits?.find(
      ({ valueAfter }) => (valueAfter as string).toLowerCase() == "pending",
    );

    if (aPendingAudit && bPendingAudit) {
      const aDate = new Date(aPendingAudit.timestamp as any);
      const bDate = new Date(bPendingAudit.timestamp as any);

      return oldestFirst
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    }

    return 0; // Don't sort if either has a missing pending audit
  });
};

/**
 * Sort answers into an array of worksheets, with each worksheet containing an array of questions
 * and their answers.
 */
export const sortQuestionAnswersByWorksheet = (
  answers: Answer[],
): AnswersByWorksheet => {
  const sortedAnswers: AnswersByWorksheet = [];

  answers.forEach(({ worksheetId, worksheetName, ...rest }) => {
    const foundWorksheet = sortedAnswers.find(
      ({ worksheetId: sortedWorksheetId }) => worksheetId === sortedWorksheetId,
    );

    if (foundWorksheet) {
      foundWorksheet.questions.push(rest);
    } else {
      sortedAnswers.push({
        worksheetId,
        worksheetName,
        questions: [rest],
      });
    }
  });

  return sortedAnswers;
};

/**
 * Test whether an answer is an image URL
 */
export const isAnswerImageUrl = (answer: string | number): boolean => {
  if (typeof answer !== "string") return false;

  return answer.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

/**
 * Get the initial values for a Formik form from a list of answers to worksheet questions.
 */
export const getInitialValues = (answers: Answer[]) => {
  const initialValues: Record<string, string | number> = {};

  answers.forEach(({ questionId, answer }) => {
    initialValues[questionId] = answer;
  });

  return initialValues;
};

/**
 * Create a map of question IDs to answer-ids and bc-question-ids
 * @example
 * // return object
 * {
 *  "1": {
 *   "bcQuestionId": "2074997",
 *   "answerId": "20107"
 *    }
 * }
 */
export const getQuestionIdToAnswerIdMap = (
  answers: Answer[],
): QuestionIdToAnswerIdMap => {
  return answers.reduce((acc, { questionId, bcQuestionId, answerId }) => {
    acc[`${questionId}`] = { bcQuestionId, answerId };
    return acc;
  }, {} as QuestionIdToAnswerIdMap);
};

export const getWorksheetDataPostBody = (
  job: Job,
  formValues: Record<string, string | number>,
  questionIdToAnswerIdMap: QuestionIdToAnswerIdMap,
): Awaited<Parameters<typeof instance.worksheets.postWorksheetData>["0"]> => {
  return {
    jobId: String(job.bcId) || "",
    jobRef: job.ref,
    answers: Object.entries(formValues).map(([key, value]) => ({
      qid: Number(key),
      bcQuestionId: questionIdToAnswerIdMap[key]["bcQuestionId"] || null,
      answer: value,
      answerId: questionIdToAnswerIdMap[key]["answerId"],
    })),
  };
};
