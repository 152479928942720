/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const UsersIcon: React.FC<TGenericImage> = ({
  className = "users-icon",
  fill = "currentColor",
  width = 22,
  height = 25,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 25"
    >
      <path
        fill={fill}
        d="M10.9 12.88a5.947 5.947 0 01-5.94-5.94A5.947 5.947 0 0110.9 1a5.947 5.947 0 015.94 5.94 5.947 5.947 0 01-5.94 5.94zm0-10.395A4.46 4.46 0 006.445 6.94a4.46 4.46 0 004.455 4.455 4.46 4.46 0 004.455-4.455A4.46 4.46 0 0010.9 2.485zm9.193 21.285H1.707A.708.708 0 011 23.06v-4.892c0-.734.374-1.424.974-1.802 5.31-3.328 12.546-3.328 17.85 0 .602.378.976 1.068.976 1.802v4.891c0 .393-.316.711-.707.711zM2.414 22.349h16.972v-4.181a.702.702 0 00-.31-.597c-4.856-3.046-11.493-3.048-16.352 0a.7.7 0 00-.31.597v4.18z"
      />
    </svg>
  );
};
