import { css } from "@emotion/react";
import React from "react";
import { Text as TextComponent, TextProps } from "../../Core/Text/Text";
import { Pill as PillComponent, PillProps } from "../../Core/Pill/Pill";
import {
  InputToggle,
  InputToggleProps,
} from "../../Core/Form/Inputs/InputToggle/InputToggle";
import { UserStylesProps } from "../../utils/types";
import { styles } from "./Table.styles";
import { formatCurrency } from "../../utils";
import { theme } from "../../theme";

export type TableDataCellProps = UserStylesProps<
  React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  >
>;

export interface TableCellProps {
  cellProps?: TableDataCellProps;
}

export interface TableCellPriceProps extends TableCellProps {
  price: number;
}

export type TableCellImageProps = UserStylesProps<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> &
  TableCellProps;

export const TableData = ({
  children,
  styles: userStyles,
  ...props
}: TableDataCellProps): React.ReactElement => (
  <td css={css(styles.tableData, userStyles)} {...props}>
    {children}
  </td>
);

export const TableTextCell = ({
  children,
  cellProps,
  ...props
}: TextProps & TableCellProps): React.ReactElement => {
  return (
    <TableData {...cellProps}>
      <TextComponent fontSize="xsmall" {...props}>
        {children}
      </TextComponent>
    </TableData>
  );
};

export const TableImageCell = ({
  cellProps,
  ...props
}: TableCellImageProps & TableCellProps): React.ReactElement => {
  return (
    <TableData {...cellProps}>
      <img {...props} />
    </TableData>
  );
};

export const TableCurrencyCell = ({
  price,
  cellProps,
  ...rest
}: TableCellPriceProps & TableCellProps & TextProps): React.ReactElement => {
  return (
    <TableData {...cellProps}>
      <TextComponent
        as="span"
        fontSize="xsmall"
        {...rest}
        css={css(styles.tableCellPrice)}
      >
        {formatCurrency(price)}
      </TextComponent>
    </TableData>
  );
};

export const TablePillCell = ({
  cellProps,
  ...props
}: PillProps & TableCellProps): React.ReactElement => {
  return (
    <TableData {...cellProps}>
      <PillComponent {...props} />
    </TableData>
  );
};

export const TableToggleCell = ({
  cellProps,
  ...props
}: Omit<InputToggleProps, "ref"> & TableCellProps): React.ReactElement => {
  return (
    <TableData {...cellProps}>
      <InputToggle
        styles={{
          " > label > span": {
            fontSize: theme.fontDefaults.xsmall.fontSize,
          },
        }}
        {...props}
      />
    </TableData>
  );
};

const CellComponent = ({
  children,
  ...props
}: TableDataCellProps): React.ReactElement => {
  return <TableData {...props}>{children}</TableData>;
};

CellComponent.Text = TableTextCell;
CellComponent.Image = TableImageCell;
CellComponent.Currency = TableCurrencyCell;
CellComponent.Pill = TablePillCell;
CellComponent.Toggle = TableToggleCell;

export const TableCell = CellComponent;
