import React, { useContext } from "react";
import {
  Panel,
  Heading,
  theme,
  Button,
  FieldLabel,
  Input,
  Loader,
  Modal,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { editQuote } from "api";
import { QuotePatchBody } from "api/types";
import { toasts } from "helpers";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import { QuoteContext, statusComponents } from "components/quotes/updateQuote";
import { RouteLink } from "components/core";
import { TotalsPanel } from ".";

export const CustomerResponse = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [translate] = useTranslation("quotes");
  const { data: quoteData, refetch } = useContext(QuoteContext);

  const { status, products, comments, validUntil } = quoteData;

  /**
   * Accept or decline quote.
   */
  const { mutate, isLoading, isSuccess } = useMutation(
    (values: QuotePatchBody) => editQuote(id, values),
    {
      onError: () => {
        toasts.error({
          message: translate("errors.quoteUpdate"),
        });
      },
    },
  );

  return (
    <>
      <Panel className="mb-10 relative" shadow={false}>
        <div className="flex">
          <Heading
            styles={{ color: theme.colors.brand.base }}
            level={5}
            className="mr-auto"
          >
            {translate("headings.respondToQuote")}
          </Heading>
          {status === "quoted" ? (
            <>
              <Button
                color="accent"
                className="ml-auto mr-3"
                size="medium"
                disabled={isLoading}
                onClick={() => {
                  mutate({ status: "accepted" });
                }}
              >
                {translate("form.buttons.acceptQuote")}
              </Button>
              <Button
                color="negative"
                size="medium"
                disabled={isLoading}
                onClick={() => {
                  mutate({ status: "rejected" });
                }}
              >
                {translate("form.buttons.declineQuote")}
              </Button>
            </>
          ) : (
            <div className="ml-auto">{statusComponents[status]}</div>
          )}
        </div>

        <TotalsPanel products={products || []} quoteRef={quoteData.quoteRef} />

        <div className="border-t border-gray-300 mt-5 pt-3 flex -mx-2">
          <div className="px-2 w-1/2">
            <FieldLabel label={translate("form.label.validUntil")}>
              <Input.Text
                value={dayjs(validUntil).format("HH:mm, Do MMMM YYYY")}
                disabled
                placeholder={translate("form.placeholder.validUntil")}
                styles={{
                  backgroundColor: "white!important",
                  color: "black!important",
                }}
              />
            </FieldLabel>
          </div>
          <div className="px-2 w-1/2">
            <FieldLabel label={translate("form.label.comments")}>
              <Input.Textarea
                value={comments}
                disabled
                slim
                placeholder={translate("form.placeholder.comments")}
                styles={{
                  backgroundColor: "white!important",
                  color: "black!important",
                }}
              />
            </FieldLabel>
          </div>
        </div>

        {isLoading && (
          <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-white bg-opacity-75">
            <Loader color="brand" text={translate("common:loading2")} />
          </div>
        )}
      </Panel>
      {isSuccess && (
        <Modal heading={translate("modals.headings.updateSuccess")} width={400}>
          <div>
            <RouteLink href="/quotes">
              <Button className="mt-10 inline-block" as="a">
                {translate("modals.buttons.backToQuotes")}
              </Button>
            </RouteLink>
          </div>
          <div>
            <Button
              className="mt-3 inline-block"
              as="a"
              color="accent"
              onClick={refetch}
            >
              {translate("modals.buttons.viewQuote")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
