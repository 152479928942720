import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import {
  styles,
  sunSvgColors,
  AllowedColorKeys,
  SunSvgColor,
} from "./Sun.styles";

export interface SunSvgProps
  extends UserStylesProps<React.SVGProps<SVGSVGElement>> {
  colors: SunSvgColor;
  hideStroke?: boolean;
}

export const SunSvg = ({
  colors,
  hideStroke = false,
  ...rest
}: SunSvgProps): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="205"
      height="205"
      viewBox="0 0 205 205"
      css={css(styles.sunSvg)}
      data-testid="sun-svg"
      {...rest}
    >
      <g fill="none" transform="translate(0 1)">
        <ellipse
          data-testid="sun-stroke-line"
          css={css(hideStroke && styles.hideStroke)}
          cx={106}
          cy={94}
          stroke={colors[0]}
          rx={104}
          ry={104}
        />
        <ellipse
          cx={102.5}
          cy={102.5}
          fill={colors[1]}
          rx={102.427}
          ry={102.134}
        />
        <ellipse
          cx={102.5}
          cy={102.5}
          fill={colors[2]}
          rx={84.017}
          ry={83.716}
        />
        <ellipse
          cx={102.5}
          cy={102.5}
          fill={colors[3]}
          rx={65.272}
          ry={64.964}
        />
      </g>
    </svg>
  );
};

export interface SunProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  color?: AllowedColorKeys;
  containsIcon?: boolean;
  hideStroke?: boolean;
}

export const Sun = ({
  color = "brand",
  children,
  styles: userStyles,
  containsIcon = false,
  hideStroke = false,
  ...rest
}: SunProps): React.ReactElement => {
  return (
    <div
      css={css(styles.defaults, styles[color], userStyles)}
      data-testid="sun-component"
      {...rest}
    >
      <div css={css(styles.sun)}>
        <SunSvg colors={sunSvgColors[color]} hideStroke={hideStroke} />
      </div>
      <div
        data-testid="child-wrapping-div"
        css={css(styles.children, containsIcon && styles.childrenWithIcon)}
      >
        {/**
         * The children of the sun component can either be a string,
         * or another component, and will display according to the
         * below ternary operator
         */}
        {typeof children === "string" ? <span>{children}</span> : children}
      </div>
    </div>
  );
};
