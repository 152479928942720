import axios from "axios";

import { IPaginatedResults, TFilters, TEditJob, IJobImage } from "./types";
import { Job } from "models";
import { generateQueryParams, hasMoreResults } from "./generic";

export const readJobsForTables = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<IPaginatedResults<Job>> => {
  try {
    const data = await readJobs(filters, currentPage, limit);
    const { items, pagination } = data;

    return {
      items: items.map((item: Job) => {
        const { id } = item;

        return {
          ...item,
          singleLink: `/jobs/update/${encodeURIComponent(id ?? "")}`,
          mutateUrl: `/jobs/${encodeURIComponent(id ?? "")}`,
        };
      }),
      pagination: {
        ...pagination,
        hasMoreResults: hasMoreResults(pagination.total, currentPage, limit),
      },
    };
  } catch (error) {
    throw error;
  }
};

export const readJobs = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<IPaginatedResults<Job>> => {
  try {
    const queryParams = generateQueryParams(filters, currentPage, limit);
    const { data } = await axios.get(`/jobs${queryParams}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const readJob = async (id: string | undefined = "16"): Promise<Job> => {
  try {
    const { data } = await axios.get(`/jobs/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const readJobImages = async (ref: string): Promise<IJobImage[]> => {
  try {
    const { data } = await axios.get(`/jobs/images/${ref}`);
    return data;
  } catch (e) {
    throw e;
  }
};

export const cancelJob = async (id: string, job: any): Promise<void> => {
  try {
    await axios.patch(`/jobs/cancel/${id}`);
  } catch (e) {
    throw e;
  }
};

export const editJob = async (job: TEditJob): Promise<void> => {
  const {
    accessInformation,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    contactEmail,
    description,
    id,
    date,
  } = job;
  const patchBody = {
    accessInformation,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    contactEmail,
    description,
    id,
    date,
  };

  try {
    await axios.patch(`/jobs/${id}`, patchBody);
  } catch (e) {
    throw e;
  }
};

export const checkJobInvoice = async (id: string): Promise<boolean> => {
  try {
    const hasInvoice = await axios.get(`/jobs/has-invoice/${id}`);
    const {
      data: { result },
    } = hasInvoice;
    return result;
  } catch (e) {
    throw e;
  }
};

export const checkJobTransferNotes = async (id: string): Promise<boolean> => {
  try {
    const transferNotes = await axios.get(
      `/jobs/has-waste-transfer-note/${id}`,
    );
    const {
      data: { result },
    } = transferNotes;
    return result;
  } catch (e) {
    throw e;
  }
};

export const getBorough = async (postcode: string) => {
  const { data } = await axios.get(`/jobs/weee-kerbside/postcode/${postcode}`);
  return data;
};

export const getBoroughAvailability = async (borough: string) => {
  const { data } = await axios.get(
    `/jobs/weee-kerbside/availability/${borough}`,
  );
  return data;
};

export const getJobByOrderRef = async (
  ref: string,
  postcode: string,
): Promise<Job> => {
  const { data } = await axios.get(`/jobs/ref/${ref}/postcode/${postcode}`);
  return data;
};
