import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils/types";
import { Text } from "../../Core/Text/Text";
import { TableCell } from "./TableCell";
import { styles } from "./Table.styles";
import { theme } from "../../theme";

export type TableProps = UserStylesProps<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
>;

export type TableBodyProps = UserStylesProps<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
  >
>;

export type TableRowProps = UserStylesProps<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
>;

export interface TableHeaderProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLTableSectionElement>,
      HTMLTableSectionElement
    >
  > {
  headings: React.ReactNode[];
  fontSize?: keyof typeof theme.fontDefaults;
}

export const TableHeader = ({
  headings,
  fontSize = "xsmall2",
  ...props
}: TableHeaderProps): React.ReactElement => (
  <thead css={css(styles.tableHead)} {...props}>
    <tr>
      {headings.map((heading, index) => (
        <th key={`tbl-heading-${index}`} css={css(styles.tableHeading)}>
          <Text fontSize={fontSize} style={{ fontWeight: "bold" }}>
            {heading}
          </Text>
        </th>
      ))}
    </tr>
  </thead>
);

export const TableBody = ({
  children,
  ...props
}: TableBodyProps): React.ReactElement => <tbody {...props}>{children}</tbody>;

export const TableRow = ({
  children,
  styles: userStyles,
  ...props
}: TableRowProps): React.ReactElement => (
  <tr css={css(styles.tableRow, userStyles)} {...props}>
    {children}
  </tr>
);

const TableComponent = ({
  children,
  styles: userStyles,
  ...props
}: TableProps): React.ReactElement => {
  return (
    <table css={css(styles.table, userStyles)} {...props}>
      {children}
    </table>
  );
};

TableComponent.Header = TableHeader;
TableComponent.Body = TableBody;
TableComponent.Row = TableRow;
TableComponent.Cell = TableCell;

export const Table = TableComponent;
