import React, { useContext, useState } from "react";
import { css } from "@emotion/react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { Text, Icon, Input, theme } from "@clearabee/ui-library";
import { CatalogueItemCell } from "./catalogueItemCell";
import { styles } from "./styles";
import { Template } from "models/template";
import { CellDropTypes } from "./catalogueItemCell/catalogueItemCell";
import { CatalogueEditorContext, EditableItem } from "./catalogueEditor";
import { FormLoader } from "components/common/components";
import { DragAndDrop } from "./dragAndDrop";
import { TopLevelLayer } from "./topLevelLayer";

export const ItemDropPanel = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const {
    items: initialItems,
    addItem,
    moveItem,
    undo,
    isLoading,
    history,
  } = useContext(CatalogueEditorContext);
  const [excludeChildren, setExcludeChildren] = useState(false);
  const [search, setSearch] = useState("");
  const items = initialItems;

  const handleDrop = (
    template: Template | EditableItem,
    monitor: DropTargetMonitor,
  ) => {
    if (!monitor.isOver({ shallow: true })) return;
    // can only drop category items
    if (
      template.type !== "category" ||
      items.some(({ sku }) => sku === template.sku)
    ) {
      return;
    }

    const dropType = monitor.getItemType() as CellDropTypes;

    // Add templates to top level
    if (dropType.includes("template")) {
      addItem(template, null);
      // if a catalogue item, move the item
    } else {
      moveItem(template as EditableItem, null);
    }

    setTimeout(() => {
      document.getElementById(`${template.sku}-no-parent`)?.scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
  };

  const [{ isOverCurrent, draggingItem }, drop] = useDrop(
    () => ({
      accept: [
        "category-template",
        "category-item",
        "product-template",
        "product-item",
      ],
      drop: handleDrop,
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        draggingItem: monitor.getItem(),
      }),
    }),
    [items],
  );

  return (
    <>
      {!isLoading && (
        <div className="items-center flex mr-20" css={styles.itemsSearch}>
          <div className="relative w-100 mr-4">
            <Input.Text
              placeholder={translate("filters.searchItems")}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <Icon.Search className="absolute right-3 top-3" size="small" />
          </div>
          <Input.Checkbox
            name="excludeChildren"
            label="Exclude children?"
            checked={excludeChildren}
            onChange={(e) => {
              setExcludeChildren(e.target.checked);
            }}
          />
        </div>
      )}
      {!!history.length && (
        <div
          className="items-center flex mt-3"
          css={styles.undo}
          onClick={undo}
        >
          <Text color="dark" fontSize="small">
            {translate("catalogueEditing.undo")}
          </Text>
          <Icon.Undo className="ml-2" size="small" />
        </div>
      )}
      <div
        className="md:p-5 max-h-full h-full"
        ref={drop}
        data-testid="itemDropPanel"
      >
        <div
          className="h-full flex items-center justify-center flex-wrap pt-5"
          css={css(styles.scrollable2)}
        >
          {isLoading && <FormLoader />}
          {!isLoading && items.length > 0 && (
            <div
              css={css({
                maxWidth: "100%",
                [`@media (min-width: ${theme.screens.medium})`]: { width: 540 },
              })}
            >
              {items.map((item) => (
                <div
                  className="w-full flex items-center justify-center flex-wrap"
                  key={item.id}
                >
                  <CatalogueItemCell
                    {...item}
                    searchTerm={search}
                    excludeChildren={excludeChildren}
                  />
                </div>
              ))}
            </div>
          )}
          {!isLoading && !items.length && (
            <div className="my-auto">
              <DragAndDrop />
              <div className="text-center">
                <Text
                  fontSize="small"
                  css={{ width: 210 }}
                  className="pt-6 break-words font-semibold"
                >
                  {translate("catalogueEditing.emptyDragDrop")}
                </Text>
              </div>
            </div>
          )}
        </div>
        <TopLevelLayer
          isOver={isOverCurrent}
          draggingItem={draggingItem as EditableItem}
          items={items}
        />
      </div>
    </>
  );
};
