import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import images.
 */
import CallCentre from "../../../images/call-centre-zoomed.svg";

/**
 * Import subcomponents.
 */
import { CellTemplate } from ".";

/**
 * Import helpers.
 */
import { formatToPrice } from "../../../helpers";
import { IDataRow } from "../types";

/**
 * Types.
 */
interface IPaymentCell {
  total: number;
  rows: IDataRow[];
  isLoading: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const PaymentCell = ({
  total,
  rows,
  isLoading,
}: IPaymentCell): React.ReactElement => {
  const [t] = useTranslation("leaderboard");

  return (
    <CellTemplate
      heading={t("headings.totalPhonePayments")}
      subheading={formatToPrice(total)}
      image={CallCentre}
      backgroundSize="h-24 w-32"
      bgSpread="bg-cover"
      data={rows}
      loading={isLoading}
    />
  );
};
