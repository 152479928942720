import React from "react";
import { useHistory } from "react-router-dom";

export interface RouterLinkProps {
  href: string;
  state?: unknown;
  children: React.ReactElement;
}

export const RouteLink = ({
  href,
  state,
  children,
}: RouterLinkProps): React.ReactElement => {
  const history = useHistory();
  const childrenOnClick = children.props.onClick;

  const onClick = (e: Event) => {
    e.preventDefault();
    history.push(href, state);
    childrenOnClick && childrenOnClick(e);
  };

  return React.cloneElement(children, { onClick, href });
};
