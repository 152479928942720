import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  container: {
    overflow: "scroll",
    height: theme.screens.small,
    padding: "15px", // Stop overflow 'x' axis
  },
  form: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  deleteModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,

    [`@media (min-width: ${theme.screens.medium})`]: {
      paddingBottom: theme.spacing.xlarge3,
    },
  },
  modal: {
    width: theme.screens.medium,
  },
  childQuestionDisplayCondition: {
    fontWeight: "bold",
  },
  childQuestionText: {
    fontWeight: "bold",
    color: theme.colors.brand.base,
    fontStyle: "italic",
    cursor: "pointer",
  },
  questionMark: {
    marginTop: theme.spacing.small,
    marginLeft: theme.spacing.xsmall2,
  },
  childQuestionMark: {
    marginTop: theme.spacing.xsmall2,
    marginLeft: theme.spacing.xsmall2,
  },
  tooltipPanel: {
    padding: theme.spacing.small,
    width: "400px",
    textAlign: "center",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    marginRight: "15px",
  },
});
