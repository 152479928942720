import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import {
  Table,
  theme,
  Heading,
  Panel,
  usePagination,
  Button,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { FormLoader } from "components/common/components";
import { ISupplierType } from "components/suppliers/types";

interface ResultsTableProps {
  supplierTypes: ISupplierType[];
}

const ResultsTable = ({
  supplierTypes,
}: ResultsTableProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const { PaginationComponent, paginatedData } = usePagination(supplierTypes);
  return (
    <>
      <div className="relative overflow-x-scroll">
        <Table styles={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: "65%" }} />
            <col style={{ width: "35%" }} />
          </colgroup>
          <Table.Header headings={["Supplier Type", ""]} />
          <Table.Body>
            {/* LORENZO - any type to resolve here */}
            {paginatedData.length > 0 &&
              paginatedData.map((supplierType: any) => (
                <Table.Row
                  key={`table-row-${supplierType.type}`}
                  data-testid="catalogue-result"
                >
                  <Table.Cell.Text
                    className="truncate"
                    cellProps={{ styles: { width: "10%" } }}
                  >
                    {supplierType.type}
                  </Table.Cell.Text>

                  <Table.Cell>
                    <div className="flex flex-row gap-3 float-right">
                      <RouteLink
                        href={`/suppliers?supplierTypeName=${supplierType.type}`}
                      >
                        <Button
                          color="warning"
                          size="xsmall"
                          as="a"
                          className="inline-block text-center float-right"
                        >
                          {translate("supplierTypes.buttons.viewSuppliers")}
                        </Button>
                      </RouteLink>
                      <RouteLink
                        href={`/suppliers/types/update/${supplierType.id}`}
                      >
                        <Button
                          size="xsmall"
                          as="a"
                          className="inline-block text-center float-right"
                        >
                          {translate("supplierTypes.buttons.edit")}
                        </Button>
                      </RouteLink>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-10">
        <PaginationComponent />
      </div>
    </>
  );
};

export const ReadSupplierTypes = (): React.ReactElement => {
  const [translate] = useTranslation("suppliers");

  const {
    data: supplierTypes,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["readSupplierTypes"],
    () => ({} as any),
    //instance.users.getSupplierTypes()
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <Panel className="mb-10">
        <div className="flex flex-row justify-between items-center">
          <Heading level={3} fontSize="large" color="brand">
            {translate("supplierTypes.headings.supplierTypes")}
          </Heading>
          <div className="flex flex-row gap-3">
            <RouteLink href="/suppliers">
              <Button size="small" color="warning">
                {translate("supplierTypes.buttons.viewAllSuppliers")}
              </Button>
            </RouteLink>
            <RouteLink href="/suppliers/types/create">
              <Button size="small">
                {translate("supplierTypes.buttons.addNewSupplierType")}
              </Button>
            </RouteLink>
          </div>
        </div>
      </Panel>

      {!isLoading && !isError && supplierTypes ? (
        <ResultsTable supplierTypes={supplierTypes.data.items || []} />
      ) : (
        <div className="relative" style={{ height: theme.screens.xsmall }}>
          <FormLoader isLoading />
        </div>
      )}
    </div>
  );
};
