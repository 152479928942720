type JsonValue = string | number | undefined;

export const convertJsonToCSV = (
  json: Record<string, JsonValue>[],
  ignoredHeaders?: string[],
): string => {
  if (!json.length) return "";

  const headers = Object.keys(json[0]);
  const arr = [
    // add header
    headers.filter((header) => !ignoredHeaders?.includes(header)),
    // add data
    ...json.map((data) => {
      const dataArr: JsonValue[] = [];

      headers.forEach((key) => {
        if (ignoredHeaders?.includes(key)) return;

        const initialValue = data[key] ?? "";
        const trimmedValue =
          typeof initialValue === "string"
            ? initialValue.replace(/"/g, '""')
            : initialValue;

        dataArr.push(`"${trimmedValue}"`);
      });

      return dataArr;
    }),
  ];

  return arr.map((e) => e.join(",")).join("\n");
};
