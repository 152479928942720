"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactDriver = void 0;
exports.contactDriver = {
    driverDetails: {
        copyPhoneNumber: "Copy Phone Number",
        numberIsUndefined: "This driver's phone number is missing",
        call: "Call",
        sendSlack: "Send Slack",
        copiedToClipboard: "Copied to clipboard!",
        slackChannelIsUndefined: "This driver's slack channel is missing",
        noDriverDataAvailable: "This vehicle has no allocated drivers",
    },
};
