import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

/**
 * Import services.
 */
import { getMonthlyJobStats, getMonthlyWasteStreamStats } from "../../services";

/**
 * Import components.
 */
import { MonthPicker, BarCharts, TopStats } from "./components";
import { ErrorMessage } from "../common/components";

import { RefreshIcon } from "../../images";

/**
 * Impor types.
 */
import { IJobQueryData } from "./types";

// Until proper error handling of no data is sorted, use previous months data instead.
const current = dayjs().subtract(1, "month");
const monthFormat = "YYYY-MM";

export const ReportingDashboard = (): React.ReactElement => {
  /**
   * Selected date state.
   */
  const [searchMonth, setSearchMonth] = useState(current);

  const startDate = searchMonth.subtract(12, "month").format(monthFormat);
  const endDate = searchMonth.format(monthFormat);

  const { loading, error, data, refetch } = useQuery<IJobQueryData>(
    getMonthlyJobStats({
      start: startDate,
      end: endDate,
    }),
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const {
    loading: wasteLoading,
    error: wasteError,
    data: wasteData,
    refetch: wasteRefresh,
  } = useQuery(
    getMonthlyWasteStreamStats({
      start: endDate,
      end: endDate,
    }),
    {
      fetchPolicy: "cache-and-network",
    },
  );

  if (error || wasteError) return <ErrorMessage />;

  return (
    <>
      <div>
        <h2 className="text-primary font-semibold leading-none text-xl">
          Showing data for:
        </h2>
        <div className="flex justify-between items-center">
          <div className="w-full lg:w-2/3 pr-0 lg:pr-5">
            <MonthPicker
              initialValue={current}
              onChange={(newMonth) => setSearchMonth(newMonth)}
            />
          </div>
          <div className="w-full lg:w-1/3 text-right hidden lg:block">
            <button
              className="text-center text-black font-semibold rounded-full px-5 py-3 bg-secondary hover:bg-primary hover:text-white transition duration-500"
              onClick={() => {
                wasteRefresh();
                refetch();
              }}
            >
              <span>Refresh</span>
              <span className="ml-2">
                <RefreshIcon
                  className={
                    loading || wasteLoading
                      ? "dashboard-refresh-icon inline-block spin-slow w-4 h-4"
                      : " inline-block spin-slow w-4 h-4"
                  }
                />
              </span>
            </button>
          </div>
        </div>
        <TopStats
          data={data}
          wasteData={wasteData}
          endDate={endDate}
          isLoading={loading || wasteLoading}
        />
        <div className="py-10">
          <h2 className="font-bold text-xl sm:text-3xl">
            Month on month analysis
          </h2>
          <h3 className="text-primary font-bold text-xl">
            {searchMonth.format("MMMM YYYY")}
          </h3>
          <BarCharts
            data={data}
            fromMonth={searchMonth}
            isLoading={loading || wasteLoading}
          />
        </div>
      </div>
    </>
  );
};
