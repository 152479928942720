"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitByChunks = void 0;
var splitByChunks = function (array, size) {
    var chunks = [];
    for (var i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
};
exports.splitByChunks = splitByChunks;
