import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuthContext } from "../../../hooks";

/**
 * Interface for public route.
 */
interface IPublicRoute {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
  permissions?: string[];
}

/**
 * Public route component.
 * - If the token does exist in local storage redirect
 * - Public routes are those intended for logged out users only (i.e. login page)
 * - Use a standard react-router 'Route' for anything else
 */
export const PublicRoute: React.FC<IPublicRoute> = ({
  path,
  exact = false,
  component: Component,
}) => {
  const { isUserAuthenticated } = useAuthContext();

  if (!isUserAuthenticated()) {
    return (
      <Route
        path={path}
        exact={exact}
        render={(otherProps) => <Component {...otherProps} />}
      />
    );
  }

  return <Redirect to={{ pathname: "/" }} />;
};
