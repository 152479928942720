import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useQuery, useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import {
  Text,
  Icon,
  Modal,
  Button,
  theme,
  Loader,
  Message,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { RouteLink } from "components/core";
import { CreateUpdateSupplierTypeForm } from "../components";
// import { ISupplierType } from "@clearabee/api-schemas";

type ParsedTypeData = any;
// type ParsedTypeData = Omit<ISupplierType, "columns"> & {
//   columns: Array<
//     NonNullable<ISupplierType["columns"]>[string] & { name: string }
//   >;
// };

export const UpdateSupplierType = (): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [showModal, setShowModal] = useState(false);
  const [parsedTypeDataForForm, setParsedTypeDataForForm] =
    useState<ParsedTypeData>();

  /**
   * when a param is passed in the url as webAddress/param, this id will store the value of the
   * param. "id" here is defined in the routes file for this page, so it knows to expect a param of id.
   */
  const { id } = useParams<{ id: string }>();

  const {
    data: singleSupplierTypeData,
    isLoading,
    isError,
  } = useQuery(
    `readSupplierType${id}`,
    // () => instance.users.getSupplierType(id),
    () => ({} as any),
    {
      cacheTime: 0,
    },
  );

  const { mutate, isLoading: isUpdatingSupplierType } = useMutation(
    (values) => ({} as any),
    //instance.users.patchSupplierType(id, values),
    {
      onError: () => {
        toasts.error({
          message: translate("supplierTypes.errors.errorUpdatingType"),
        });
      },
      onSuccess: () => setShowModal(true),
    },
  );

  // LORENZO - any type to resolve
  useEffect(() => {
    if (
      singleSupplierTypeData &&
      singleSupplierTypeData.data.columns &&
      !Array.isArray(singleSupplierTypeData.data.columns)
    ) {
      const columns = Object.entries(singleSupplierTypeData.data.columns).map(
        // TODO: Remove any
        ([key, value]: any) => ({
          name: key,
          type: value.type,
          title: value.title,
          required: value.required,
        }),
      );

      setParsedTypeDataForForm({ ...singleSupplierTypeData.data, columns });
    }
  }, [singleSupplierTypeData]);

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <RouteLink href="/suppliers/types">
        <Text
          className="flex flex-row gap-1 items-center cursor-pointer"
          fontSize="small"
        >
          <Icon.Chevron size="small" styles={{ transform: "rotate(180deg)" }} />
          {translate("supplierTypes.buttons.backToSupplierTypes")}
        </Text>
      </RouteLink>

      {!isLoading && parsedTypeDataForForm ? (
        <div className="mt-4">
          <CreateUpdateSupplierTypeForm
            title={translate("supplierTypes.headings.editSupplierType")}
            disableSubmitButton={isUpdatingSupplierType}
            mutateFunction={mutate}
            supplierTypeData={parsedTypeDataForForm}
          />
        </div>
      ) : (
        <>
          {isError ? (
            <Message className="mt-6" color="negative">
              {translate("supplierTypes.errors.cannotGetSupplierType")}
            </Message>
          ) : (
            <Loader color="brand" />
          )}
        </>
      )}

      {showModal && (
        <Modal
          heading={translate("supplierTypes.headings.success")}
          width={500}
          actions={
            <>
              <RouteLink href="/suppliers/types">
                <Button
                  size="medium"
                  color="brand"
                  type="button"
                  className="mr-3"
                >
                  {translate("supplierTypes.buttons.backToSupplierTypes")}
                </Button>
              </RouteLink>
            </>
          }
          onClose={() => setShowModal(false)}
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("supplierTypes.messages.supplierTypeUpdated")}
          </div>
        </Modal>
      )}
    </div>
  );
};
