import React from "react";

import { StatusCompleted, StatusFailed } from "../../../common/components";

export const CompanyActive: React.FC = ({ row }: any): JSX.Element => {
  const { original } = row;
  const { active } = original;

  if (active) {
    return <StatusCompleted text="Active" />;
  }

  return <StatusFailed text="Inactive" />;
};
