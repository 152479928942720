import { theme } from "../../theme";
import { StyleSheet } from "../../utils";
import { btoa } from "abab";

export type AllowedColorKeys =
  | keyof Pick<
      typeof theme.colors,
      "brand" | "accent" | "warning" | "light" | "positive"
    >
  | "black"
  | "grey";

export const patterns = {
  brandSmall: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="354" height="515" viewBox="0 0 354 515">
  <defs>
    <rect id="brand-panel-a" width="354" height="515" x="0" y="0" rx="10"/>
    <filter id="brand-panel-b" width="133%" height="133.2%" x="-16.5%" y="-16.6%" filterUnits="objectBoundingBox">
      <feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
    <filter id="brand-panel-d" width="134%" height="134.1%" x="-17%" y="-17.1%" filterUnits="objectBoundingBox">
      <feOffset dy="46" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="42"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="brand-panel-c" fill="#fff">
      <use xlink:href="#brand-panel-a"/>
    </mask>
    <use fill="#8645EA" xlink:href="#brand-panel-a"/>
    <g filter="url(#brand-panel-b)" mask="url(#brand-panel-c)">
      <g fill-rule="nonzero" filter="url(#brand-panel-d)" transform="translate(-863 -936)">
        <ellipse cx="732.211" cy="729.715" fill="#8645EA" rx="732.211" ry="729.715"/>
        <ellipse cx="729.818" cy="727.94" fill="#8F4EF3" rx="600.604" ry="598.127"/>
        <ellipse cx="729.818" cy="728.565" fill="#9754FF" rx="466.605" ry="464.146"/>
      </g>
    </g>
  </g>
</svg>
`,
  brandLarge: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1257" height="409" viewBox="0 0 1257 409">
  <defs>
    <rect id="brand-sun-panel-full-a" width="1257" height="409" x="0" y="0" rx="10"/>
    <filter id="brand-sun-panel-full-b" width="107.9%" height="107.9%" x="-4%" y="-4%" filterUnits="objectBoundingBox">
      <feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="brand-sun-panel-full-c" fill="#fff">
      <use xlink:href="#brand-sun-panel-full-a"/>
    </mask>
    <use fill="#8645EA" xlink:href="#brand-sun-panel-full-a"/>
    <g fill-rule="nonzero" filter="url(#brand-sun-panel-full-b)" mask="url(#brand-sun-panel-full-c)">
      <g transform="translate(-619 -703)">
        <ellipse cx="732.211" cy="729.715" fill="#8645EA" rx="732.211" ry="729.715"/>
        <ellipse cx="729.818" cy="727.94" fill="#8F4EF3" rx="600.604" ry="598.127"/>
        <ellipse cx="729.818" cy="728.565" fill="#9754FF" rx="466.605" ry="464.146"/>
      </g>
    </g>
  </g>
</svg>`,
  accentSmall: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="354" height="515" viewBox="0 0 354 515">
  <defs>
    <rect id="accent-panel-a" width="354" height="515" x="0" y="0" rx="10"/>
    <filter id="accent-panel-b" width="133%" height="133.2%" x="-16.5%" y="-16.6%" filterUnits="objectBoundingBox">
      <feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
    <filter id="accent-panel-d" width="134%" height="134.1%" x="-17%" y="-17.1%" filterUnits="objectBoundingBox">
      <feOffset dy="46" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="42"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="accent-panel-c" fill="#fff">
      <use xlink:href="#accent-panel-a"/>
    </mask>
    <use fill="#56F7B2" xlink:href="#accent-panel-a"/>
    <g filter="url(#accent-panel-b)" mask="url(#accent-panel-c)">
      <g fill-rule="nonzero" filter="url(#accent-panel-d)" transform="translate(-863 -936)">
        <ellipse cx="732.211" cy="729.715" fill="#56F7B2" rx="732.211" ry="729.715"/>
        <ellipse cx="729.818" cy="727.94" fill="#82F9C4" rx="600.604" ry="598.127"/>
        <ellipse cx="729.818" cy="728.565" fill="#9BFED3" rx="466.605" ry="464.146"/>
      </g>
    </g>
  </g>
</svg>
`,
  accentLarge: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1257" height="409" viewBox="0 0 1257 409">
  <defs>
    <rect id="accent-landscape-sun-panel-a" width="1257" height="409" x="0" y="0" rx="10"/>
    <filter id="accent-landscape-sun-panel-b" width="133%" height="133.2%" x="-16.5%" y="-16.6%" filterUnits="objectBoundingBox">
      <feOffset dy="10" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
    <filter id="accent-landscape-sun-panel-d" width="134%" height="134.1%" x="-17%" y="-17.1%" filterUnits="objectBoundingBox">
      <feOffset dy="46" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="42"/>
      <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.05 0"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="accent-landscape-sun-panel-c" fill="#fff">
      <use xlink:href="#accent-landscape-sun-panel-a"/>
    </mask>
    <use fill="#53F0AC" xlink:href="#accent-landscape-sun-panel-a"/>
    <g filter="url(#accent-landscape-sun-panel-b)" mask="url(#accent-landscape-sun-panel-c)">
      <g fill-rule="nonzero" filter="url(#accent-landscape-sun-panel-d)" transform="translate(-616 -703)">
        <ellipse cx="732.211" cy="729.715" fill="#56F7B2" rx="732.211" ry="729.715"/>
        <ellipse cx="729.818" cy="727.94" fill="#82F9C4" rx="600.604" ry="598.127"/>
        <ellipse cx="729.818" cy="728.565" fill="#9BFED3" rx="466.605" ry="464.146"/>
      </g>
    </g>
  </g>
</svg>
`,
};

export const styles = StyleSheet({
  defaults: {
    position: "relative",
    padding: theme.spacing.xlarge,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  shadow: {
    boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.15)",
  },
  rounded: {
    borderRadius: theme.spacing.xsmall,
  },
  light: {
    backgroundColor: theme.colors.light.base,
  },
  brand: {
    backgroundColor: theme.colors.brand.base,
    color: theme.colors.light.base,
  },
  accent: {
    backgroundColor: theme.colors.accent.base,
  },
  positive: {
    backgroundColor: theme.colors.positive.base,
  },
  warning: {
    backgroundColor: theme.colors.warning.base,
  },
  black: {
    backgroundColor: theme.colors.greyscale.base,
    color: theme.colors.light.base,
  },
  grey: {
    backgroundColor: theme.colors.greyscale.lightest,
  },
  smallLightPattern: {},
  largeLightPattern: {},
  smallGreyPattern: {},
  largeGreyPattern: {},
  smallBlackPattern: {},
  largeBlackPattern: {},
  smallWarningPattern: {},
  largeWarningPattern: {},
  smallPositivePattern: {},
  largePositivePattern: {},
  smallBrandPattern: {
    backgroundImage: `url("data:image/svg+xml;base64,${btoa(
      patterns.brandSmall,
    )}")`,
  },
  largeBrandPattern: {
    backgroundImage: `url("data:image/svg+xml;base64,${btoa(
      patterns.brandLarge,
    )}")`,
  },
  smallAccentPattern: {
    backgroundImage: `url("data:image/svg+xml;base64,${btoa(
      patterns.accentSmall,
    )}")`,
  },
  largeAccentPattern: {
    backgroundImage: `url("data:image/svg+xml;base64,${btoa(
      patterns.accentLarge,
    )}")`,
  },
});
