import React, { forwardRef, useEffect, useState, useRef } from "react";
import ReactSelect, { NamedProps, ValueType } from "react-select";
import { css, CSSObject } from "@emotion/react";
import { styles } from "./InputSelect.styles";
import { CustomInputProps, mergeClasses } from "../../../../utils";
import { Icon } from "../../../..";

export interface OptionProps {
  label: string;
  value: string | number;
}

type SelectValue = ValueType<OptionProps, false>;

type ReactSelectProps = Pick<
  NamedProps<OptionProps>,
  | "isClearable"
  | "isSearchable"
  | "isLoading"
  | "isMulti"
  | "menuPlacement"
  | "menuPosition"
  | "onMenuOpen"
  | "onMenuClose"
  | "menuPortalTarget"
> & {
  options: OptionProps[];
};

export interface InputSelectProps
  extends CustomInputProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
    ReactSelectProps {
  styles?: CSSObject;
  reset?: boolean;
  openMenuOnFocus?: boolean;
  optionStyles?: CSSObject;
}

export type InputSelectRef = ReactSelect<OptionProps>;

const DropdownIndicator = (): React.ReactElement => {
  return <Icon.Chevron size="small" styles={styles.dropdownIndicator} />;
};

export const InputSelect = forwardRef<InputSelectRef, InputSelectProps>(
  (
    {
      options,
      error,
      success,
      disabled,
      placeholder,
      styles: userStyles,
      optionStyles,
      isClearable,
      isSearchable = false,
      isLoading,
      isMulti,
      menuPlacement,
      menuPosition,
      onMenuOpen,
      onMenuClose,
      defaultValue,
      reset,
      openMenuOnFocus,
      menuPortalTarget,
      ...rest
    },
    reactSelectRef,
  ): React.ReactElement => {
    const defaultOption =
      options?.find(({ value }) => value === defaultValue) ?? null;
    const nativeSelect = useRef<HTMLSelectElement>(null);
    const [selectedOption, setSelectedOption] = useState<SelectValue>(
      options?.find(({ value }) => value === rest.value),
    );

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      if (!nativeSelect.current || selectedOption === undefined) {
        return;
      }

      const changeEvent = new Event("change", { bubbles: true });
      if (nativeSelect.current.dispatchEvent) {
        nativeSelect.current.dispatchEvent(changeEvent);
      }
    }, [selectedOption]);

    const handleReset = () => setSelectedOption(defaultOption);

    const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
      rest.onBlur?.({
        ...event,
        target: nativeSelect.current,
      } as React.FocusEvent<HTMLSelectElement>);
    };

    /**
     * If we are resetting the native select value
     * then fire handle reset so react select can update itself.
     */
    useEffect(() => {
      if (nativeSelect.current && selectedOption && rest.value === "") {
        handleReset();
      }
    }, [rest.value]);

    /**
     * Reset input select if reset prop is set to true
     * and selected option has a value.
     */
    useEffect(() => {
      if (nativeSelect.current && selectedOption && reset) {
        handleReset();
      }
    }, [reset]);

    useEffect(() => {
      const formElement = nativeSelect.current?.form;

      if (!formElement) {
        return;
      }

      formElement.addEventListener("reset", handleReset);
      return () => formElement.removeEventListener("reset", handleReset);
    }, []);

    useEffect(() => {
      if (
        selectedOption &&
        !options.some(({ value }) => selectedOption.value === value)
      ) {
        setSelectedOption(null);
      }
    }, [options]);

    return (
      <div data-testid="select-component" role="combobox">
        <ReactSelect
          menuPosition={menuPosition}
          ref={reactSelectRef}
          openMenuOnFocus={openMenuOnFocus}
          value={selectedOption ?? defaultOption}
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          isDisabled={disabled || isLoading}
          onChange={(value) => setSelectedOption(value)}
          // Start ReactSelect Props
          options={options}
          onMenuOpen={() => {
            onMenuOpen?.();
            setIsOpen(true);
          }}
          onMenuClose={() => {
            onMenuClose?.();
            setIsOpen(false);
          }}
          onBlur={handleBlur}
          isClearable={isClearable}
          isSearchable={isSearchable}
          menuPortalTarget={menuPortalTarget}
          isLoading={isLoading}
          isMulti={isMulti}
          menuPlacement={menuPlacement}
          // End ReactSelect Props
          classNamePrefix="react-select"
          className={mergeClasses(
            error && "error",
            success && "success",
            disabled && "disabled",
            isOpen && "open",
          )}
          styles={{
            container: (base) => ({
              ...base,
              ...styles.container,
            }),
            control: (base) => ({
              ...base,
              ...styles.control,
              ...(userStyles as Record<string, unknown>),
            }),
            menu: (base) => ({
              ...base,
              ...styles.menu,
            }),
            valueContainer: (base) => ({
              ...base,
              ...(optionStyles as Record<string, unknown>),
              ...(isLoading ? styles.loadingValueContainer : {}),
            }),
            dropdownIndicator: () => styles.dropdownIndicator,
            indicatorSeparator: () => styles.indicatorSeparator,
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (base) => ({
              ...base,
              ...(optionStyles as Record<string, unknown>),
            }),
          }}
        />
        <select {...rest} ref={nativeSelect} css={css(styles.nativeSelect)}>
          {selectedOption && (
            <option key={selectedOption.label} value={selectedOption.value}>
              {selectedOption.label}
            </option>
          )}
        </select>
      </div>
    );
  },
);
