import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ViewJobsIcon: React.FC<TGenericImage> = ({
  className = "view-jobs-icon",
  fill = "currentColor",
  stroke = "currentColor",
  width = 23,
  height = 19,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 23 19"
    >
      <g fill="none" fillRule="evenodd" stroke={stroke}>
        <rect width="4" height="4" x="0.5" y="0.5" fill={fill} rx="1" />
        <rect width="4" height="4" x="0.5" y="7.5" rx="1" />
        <rect width="4" height="4" x="0.5" y="14.5" rx="1" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.625 2.5L22.375 2.5"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.625 9.5L22.375 9.5"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.625 16.5L22.375 16.5"
        />
      </g>
    </svg>
  );
};
