import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import transition component.
 */
import { SpringTransition } from "../../core";

/**
 * Import images.
 */
import { ReactComponent as DottedLine } from "../images/auth-background-line-top.svg";
import { ReactComponent as House } from "../../../images/auth-house.svg";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const PanelRight = (): React.ReactElement => {
  const [t] = useTranslation("auth");

  return (
    <SpringTransition
      className="fixed right-0 top-0 w-1/2 h-screen z-0 auth-shapes auth-shapes--top hidden lg:block"
      duration={1000}
    >
      <div
        data-testid="panelRightWithHouseComponent"
        className="transform scale-75 xl:scale-100"
      >
        <DottedLine className="fixed top-0 auth-shapes__line auth-shapes__line--top" />
      </div>
      <div className="fixed lg:right-0 lg:top-0 w-full h-screen bg-no-repeat login-background flex flex-col justify-top items-center">
        <div className="lg:w-full xl:w-3/4 hidden lg:block">
          <div className="px-16 pt-10 xl:pt-32 xl:px-20">
            <House className="w-full" />
          </div>

          <div className="px-12 2xl:px-20">
            <p className="mt-1 xl:mt-3 text-3xl xl:text-4xl text-right text-white leading-extra-tight auth-slogan">
              {t("strapline.start")}{" "}
              <span className="font-semibold text-secondary">
                {t("strapline.middle")}
              </span>{" "}
              {t("strapline.end")}
            </p>
          </div>
        </div>
      </div>
    </SpringTransition>
  );
};
