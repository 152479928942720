import React, { useEffect } from "react";
import { useFormikContext, FormikProps, FormikValues } from "formik";

/**
 * Import components.
 */
import { Checkbox } from "../../../core";

/**
 * Interface.
 */

interface INoContactDetailsSwitch {
  name: string;
  label: string;
}

export const NoContactDetailsSwitch: React.FC<INoContactDetailsSwitch> = ({
  name,
  label,
}) => {
  const { setFieldValue, values }: FormikProps<FormikValues> =
    useFormikContext();

  useEffect(() => {
    if (values[name]) {
      setFieldValue("customer.firstName", "");
      setFieldValue("customer.lastName", "");
      setFieldValue("customer.phoneNumber", "");
      setFieldValue("customer.email", "");
    }
  }, [values[name]]);

  return <Checkbox name={name} label={label} />;
};
