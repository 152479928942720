"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobPanel = void 0;
exports.jobPanel = {
    viewJobDetails: "View Job Details",
    whoWasThere: "Who was there?",
    createContactHere: "Create Contact Here",
    addToRoutePlanner: "Add to Route Planner",
    nearestVehicles: "Nearest Vehicles",
    streetView: "Street View",
    collectionAddress: "Collection Address",
    jobDetails: "Job Details",
    dateAndTime: "Date & Time",
    jobStatus: "Job Status",
    dataIsNotProvided: "Data is not provided",
};
