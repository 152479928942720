import * as Yup from "yup";
import { stringRequired, email, phone } from "validation/common";

export const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  temporaryPassword: "",
};

export const validationSchema = Yup.object().shape({
  email: email,
  firstName: stringRequired,
  lastName: stringRequired,
  phoneNumber: phone.notRequired(),
  temporaryPassword: stringRequired,
});
