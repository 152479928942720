import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Text, Button, theme } from "@clearabee/ui-library";
import { useConfig } from "../../../hooks";
import { WhiteLogo } from "../../../images/whiteLogo";
import { useAuthContext } from "../../../hooks";

export const AccountSuspended = (): React.ReactElement => {
  const [translation] = useTranslation("app");
  const appSettings = useConfig("app");
  const phone = appSettings("phoneNumber");
  const { signOut } = useAuthContext();

  return (
    <>
      <div
        className="absolute w-full h-full"
        style={{
          backgroundColor: theme.colors.brand.base,
        }}
      >
        <div
          className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-full"
          style={{ width: 250, height: 300 }}
        >
          <WhiteLogo />
        </div>
      </div>
      <Modal
        heading={translation("accountSuspended.heading")}
        width={600}
        actions={
          <>
            <Button
              href={`tel:${phone}`}
              className="mr-2"
              color="accent"
              as="a"
              styles={{
                display: "inline-block",
                width: "100px",
              }}
            >
              {translation("accountSuspended.contactUs")}
            </Button>
            <Button
              color="accent"
              className="ml-2"
              onClick={() => signOut()}
              styles={{
                width: "100px",
              }}
            >
              {translation("common:logout")}
            </Button>
          </>
        }
        styles={{ ":before": { display: "none" } }}
      >
        <div className="w-2/3 m-auto mt-1 mb-8 font-semibold">
          <Text>{translation("accountSuspended.message")}</Text>
        </div>
      </Modal>
    </>
  );
};
