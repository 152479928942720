import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { useTable, ColumnInstance, HeaderGroup, Row, Cell } from "react-table";

interface PaginatedResultsTable {
  headingSize?: string;
  headerStyles?: { borderBottom: number; hover: boolean };
  columns: {
    Header: string;
    accessor: string;
    Cell?: any;
    width?: number;
  }[];
  data?: any[];
  isFetching?: boolean;
}

export const PaginatedResultsTable: React.FC<PaginatedResultsTable> = ({
  headingSize,
  headerStyles,
  columns,
  data = [],
  isFetching,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  if (rows.length > 0) {
    return (
      <table {...getTableProps()} className="tbl">
        <thead className="bg-gray-300 sm:bg-transparent">
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="tbl-row">
              {headerGroup.headers.map((column: ColumnInstance) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ width: column.width, ...headerStyles }}
                  className={cx(
                    `tbl-header whitespace-no-wrap px-3 py-2 sm:py-3 text-left`,
                    headingSize ? headingSize : "text-xs",
                  )}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="tbl-body">
          {rows.map((row: Row) => {
            prepareRow(row);
            const { original } = row;
            const { singleLink } = original as any;

            return (
              <tr
                {...row.getRowProps()}
                className={cx("tbl-row sm:cursor-pointer", {
                  "sm:hover:bg-gray-100": headerStyles?.hover,
                })}
              >
                {row.cells.map((cell: Cell) => {
                  const isActionsColumn = cell.column.id === "id";
                  return (
                    <td
                      {...cell.getCellProps()}
                      data-testid="paginatedCell"
                      className={cx(
                        "relative text-sm px-3 py-2 sm:py-3 text-left text-gray-600",
                        // {
                        //   truncate: !isActionsColumn,
                        // }
                      )}
                    >
                      {typeof singleLink === "undefined" || isActionsColumn ? (
                        <div className="flex">{cell.render("Cell")}</div>
                      ) : (
                        <Link
                          to={`${singleLink}`}
                          className="tbl-link inline-block"
                        >
                          {cell.render("Cell")}
                        </Link>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  if (isFetching) {
    return null;
  }

  return (
    <div className="text-center font-semibold text-xl mt-5">
      No results found
    </div>
  );
};
