import React, { useContext } from "react";
import { CatalogueItem } from "models/catalogueItem";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { Template } from "models/template";
import { CatalogueEditorContext, EditableItem } from "../catalogueEditor";
import { CellDropTypes } from "./catalogueItemCell";
import { toasts } from "helpers";
import { css } from "@emotion/react";
import { theme } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";

interface DropCellWrapProps {
  item: EditableItem;
  children: React.ReactNode;
}

export const DropCellWrap = ({
  item,
  children,
}: DropCellWrapProps): React.ReactElement => {
  const { items, moveItem, addItem } = useContext(CatalogueEditorContext);
  const [translate] = useTranslation("catalogues");

  const addItemToCatalogue = (
    itemToAdd: Template | CatalogueItem,
    monitor: DropTargetMonitor<Template | CatalogueItem>,
  ) => {
    if (!monitor.isOver({ shallow: true })) return;
    if (itemToAdd.sku === item.sku)
      // do nothing if dropped on itself
      return;

    const exists = !!item.children?.some(({ sku }) => sku === itemToAdd.sku);

    if (exists) {
      toasts.error({
        message: translate("catalogueEditing.errors.itemExists", {
          item: itemToAdd.title,
          category: item.title,
        }),
      });
      return;
    }

    if ((monitor.getItemType() as CellDropTypes).includes("item")) {
      moveItem(itemToAdd as EditableItem, item.id);
    } else {
      addItem(itemToAdd, item.id);
    }
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [
        "category-item",
        "category-template",
        "product-item",
        "product-template",
      ],
      drop: (dropItem: Template | CatalogueItem, monitor) => {
        item.type === "category" && addItemToCatalogue(dropItem, monitor);
      },
      collect: (monitor) => ({
        isOver:
          monitor.isOver({ shallow: true }) &&
          monitor.getItem<CatalogueItem>().sku !== item.sku,
      }),
    }),
    [items, item],
  );

  return (
    <div
      className="w-full relative"
      ref={drop}
      css={css(
        item.type === "category" && isOver
          ? {
              "&:before": {
                content: '""',
                position: "absolute",
                top: -12,
                right: -12,
                bottom: 0,
                left: -12,
                borderWidth: 4,
                borderStyle: "dotted",
                borderColor: theme.colors.positive.base,
              },
            }
          : {},
      )}
      data-testid="drop-cell-wrap"
    >
      {children}
    </div>
  );
};
