import * as Yup from "yup";
import { fieldRequired, matchField, email } from "../../validation/common";

/**
 * Yup objects for each page.
 */
export const login = Yup.object().shape({
  email,
  password: Yup.string().required(fieldRequired),
});

export const sso = Yup.object().shape({
  ssoEmail: email,
});

export const forgot = Yup.object().shape({
  email,
});

export const reset = Yup.object().shape({
  password: Yup.string().required(fieldRequired),
  passwordConfirmation: Yup.string()
    .required(fieldRequired)
    .oneOf([Yup.ref("password"), null], matchField),
});

export const welcome = Yup.object().shape({
  newPassword: Yup.string().required(fieldRequired),
});
