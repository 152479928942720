/**
 * Import types.
 */
import { TTransitionSettings, TFadeIn, TSlideIn } from "./types";

/**
 * Transition Settings getter object.
 */
export const transitionSettings: TTransitionSettings = {
  fadeIn: () => ({
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  }),
  fadeInUp: (options: TFadeIn) => ({
    from: { opacity: 0, transform: `translateY(${options.distance}px)` },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: `translateY(${options.distance}px)` },
  }),
  fadeInDown: (options: TFadeIn) => ({
    from: { opacity: 0, transform: `translateY(-${options.distance}px)` },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: `translateY(-${options.distance}px)` },
  }),
  fadeInRight: (options: TFadeIn) => ({
    from: { opacity: 0, transform: `translateX(-${options.distance}px)` },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: `translateX(-${options.distance}px)` },
  }),
  fadeInLeft: (options: TFadeIn) => ({
    from: { opacity: 0, transform: `translateX(${options.distance}px)` },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: `translateX(${options.distance}px)` },
  }),

  fadeInLeftAbs: (options: TFadeIn) => ({
    from: {
      opacity: 0,
      transform: `translateX(${options.distance}px)`,
      position: "absolute",
    },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: {
      opacity: 0,
      transform: `translateX(${options.distance}px)`,
      position: "absolute",
    },
  }),

  fadeInLeftRightAbs: (options: TFadeIn) => ({
    from: {
      opacity: 0,
      transform: `translateX(${options.distance}px)`,
      position: "absolute",
      width: "100%",
    },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: {
      opacity: 0,
      transform: `translateX(-${options.distance}px)`,
      position: "absolute",
    },
  }),
  fadeInRightLeftAbs: (options: TFadeIn) => ({
    from: {
      opacity: 0,
      transform: `translateX(-${options.distance}px)`,
      position: "absolute",
    },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: {
      opacity: 0,
      transform: `translateX(${options.distance}px)`,
      position: "absolute",
    },
  }),

  slideInUp: (options: TSlideIn) => ({
    from: { transform: `translateY(${options.distance}px)` },
    enter: { transform: "translateY(0px)" },
    leave: { transform: `translateY(${options.distance}px)` },
  }),
  slideInDown: (options: TSlideIn) => ({
    from: { transform: `translateY(-${options.distance}px)` },
    enter: { transform: "translateY(0px)" },
    leave: { transform: `translateY(-${options.distance}px)` },
  }),
  slideInRight: (options: TSlideIn) => ({
    from: { transform: `translateX(-${options.distance}px)` },
    enter: { transform: "translateX(0px)" },
    leave: { transform: `translateX(-${options.distance}px)` },
  }),
  slideInLeft: (options: TSlideIn) => ({
    from: { transform: `translateX(${options.distance}px)` },
    enter: { transform: "translateX(0px)" },
    leave: { transform: `translateX(${options.distance}px)` },
  }),
};
