import React from "react";

import { ProgressBar } from "../../../core";
import { useMultiFormContext } from "../../../../hooks";

export const StepsWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { activeStep, steps, setStep } = useMultiFormContext();
  const step = steps[activeStep];

  const progressBarItems: string[] = steps.map((step) => step.name || "");

  return (
    <>
      <div className="flex justify-end">
        <ProgressBar
          items={progressBarItems}
          currentItem={activeStep}
          setStep={setStep}
        />
      </div>
      <div className="pt-5 lg:pt-10 pb-2 px-6 text-center">
        <h2 className="text-primary font-bold text-xl">{step.name}</h2>
        <div className="mt-1">
          <p>{step.description}</p>
        </div>
      </div>
      {children}
    </>
  );
};
