import React from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

/**
 * Extends dayjs to use advanced format plugin.
 */
dayjs.extend(advancedFormat);

/**
 * This component formats the date within the readQuotes date column.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const QuoteDate: React.FC = ({ row }: any): JSX.Element => {
  const { original } = row;
  const { createdAt } = original;

  return <span>{dayjs(createdAt).format("Do MMMM YYYY")}</span>;
};
