"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateBlackout = void 0;
/**
 * Check whether given date is blacked out.
 * @param date Date to compare.
 */
var isDateBlackout = function (date, blackoutDates, blackoutDays) {
    return ((blackoutDates === null || blackoutDates === void 0 ? void 0 : blackoutDates.includes(date.format("YYYY-MM-DD"))) ||
        (blackoutDays === null || blackoutDays === void 0 ? void 0 : blackoutDays.includes(date.day())));
};
exports.isDateBlackout = isDateBlackout;
