import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  panel: {
    display: "flex",
    justifyContent: "start",
    gap: theme.spacing.small,
    alignItems: "center",
    marginBottom: theme.spacing.small,
  },

  chevronOpen: {
    transform: "rotate(270deg)",
    marginLeft: theme.spacing.xsmall,
    marginTop: theme.spacing.xsmall2,
  },
  chevronClosed: {
    transform: "rotate(90deg)",
    marginLeft: theme.spacing.xsmall,
    marginBottom: theme.spacing.xsmall2,
  },
});
