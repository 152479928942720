import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Input } from "@clearabee/ui-library";
import { readRoles } from "../../../../api";

export const UserFiltersRole = ({
  ...field
}: React.InputHTMLAttributes<HTMLSelectElement>): React.ReactElement => {
  const [translate] = useTranslation("users");

  const [rolesOptions, setRolesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const { data: rolesForOptions, isLoading: isLoadingRoles } = useQuery(
    "readRolesForUserFilter",
    readRoles,
  );

  useEffect(() => {
    if (rolesForOptions) {
      const filteredSupplierRoles = rolesForOptions.items.map((role) => {
        return {
          label: role.name,
          value: role.name,
        };
      });

      if (filteredSupplierRoles.length > 0) {
        setRolesOptions([
          ...filteredSupplierRoles,
          // Website users neither have a company nor a role, so the query to readRoles will not return 'Website User'.
          // For this reason, we manually set the option here. (When this param is sent through to our api,
          // it strips out the filter and applies custom SQL to find website users.)
          { label: "Website User", value: "Website User" },
        ]);
      }
    }
  }, [rolesForOptions]);

  return (
    <Input.Select
      {...field}
      placeholder={translate("user.form.placeholders.selectRole")}
      options={rolesOptions}
      disabled={isLoadingRoles}
    />
  );
};
