import { EditableItem } from "components/catalogues/components";

export const getParent = (
  needle: EditableItem,
  haystack: EditableItem[],
): EditableItem | undefined => {
  for (const parent of haystack) {
    const children = parent.children ?? [];

    for (const child of children) {
      if (child.id === needle.id) {
        return parent;
      }

      const childParent = getParent(needle, children);

      if (childParent) {
        return childParent;
      }
    }
  }
};
