import { StyleSheet } from "../../utils";
import { theme } from "../../theme";

export const styles = StyleSheet({
  default: {
    borderRadius: "50%",
    border: `2px solid ${theme.colors.brand.darkest}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
  },
});
