import React from "react";
import { Icon } from "@clearabee/ui-library";

export const ButtonLoader = (): React.ReactElement => {
  return (
    <div className="flex items-center justify-center" style={{ height: 18 }}>
      <Icon.Loading size="medium" color="accent.darker" />
    </div>
  );
};
