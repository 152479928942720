"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.snakeToNormal = void 0;
var capitalise_1 = require("./capitalise");
var snakeToNormal = function (text) {
    return text
        .split("_")
        .map(function (value) { return (0, capitalise_1.capitalise)(value); })
        .join(" ");
};
exports.snakeToNormal = snakeToNormal;
