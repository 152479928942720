import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  scrollable: {
    overflowY: "auto",
    paddingRight: theme.spacing.xsmall2,
    minHeight: 0,
    maxHeight: theme.screens.xsmall,
    marginBottom: theme.spacing.small,

    "::-webkit-scrollbar": {
      width: theme.spacing.xsmall,
    },
    "::-webkit-scrollbar-track": {
      background: theme.colors.brand.darker,
      borderRadius: theme.spacing.xsmall2,
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.colors.light.base,
      borderRadius: theme.spacing.small,
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: theme.colors.brand.light,
    },
  },
  scrollable2: {
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: theme.spacing.xsmall,
    },
    "::-webkit-scrollbar-track": {
      background: theme.colors.greyscale.light,
      borderRadius: theme.spacing.xsmall2,
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.colors.brand.base,
      borderRadius: theme.spacing.small,
    },

    "::-webkit-scrollbar-thumb:hover": {
      background: theme.colors.brand.light,
    },
  },
  itemHoverEffect: {
    ":hover": {
      opacity: 0.9,
    },
  },
  chevron: {
    transition: "transform 0.3s, margin 0.3s",
  },
  xMargin: {
    marginLeft: theme.spacing.small,
    marginRight: theme.spacing.small,
  },
  itemContainer: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  fullScreenButton: {
    position: "absolute",
    right: 0,
    transition: "color 0.5s",
    transform: `translate(-30px, 20px)`,
  },
  undo: {
    position: "absolute",
    top: theme.spacing.small,
    right: theme.spacing.large,
    cursor: "pointer",
    transition: "color 0.3s",

    ":hover": {
      color: theme.colors.brand.base,
    },
  },
  dragOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 110,
  },
  portalContainer: {
    display: "none",
  },
  itemDropPanel: {
    zIndex: 120,
    position: "relative",
    background: theme.colors.greyscale.lightest,
    borderRadius: theme.spacing.xsmall,
  },
  itemsSearch: {
    position: "absolute",
    top: theme.spacing.small,
    left: theme.spacing.large,
    right: theme.spacing.large,
  },
});
