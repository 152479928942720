import { keyframes } from "@emotion/react";
import { theme, StyleSheet } from "@clearabee/ui-library";

const fadeIn = keyframes({
  from: {
    opacity: 0,
    transform: "translateY(20px)",
  },
});

export const styles = StyleSheet({
  fileWrapper: {
    position: "relative",
    width: "100%",
    backgroundColor: theme.colors.greyscale.lightest,
    borderRadius: theme.spacing.xsmall,
  },
  imagesContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    animationName: fadeIn,
    animationDuration: "300ms",
    padding: `${theme.spacing.xsmall} 0`,
    overflow: "scroll",
  },
  imageContainer: {
    position: "relative",
  },
  image: {
    width: theme.spacing.xlarge3,
    height: theme.spacing.xlarge3,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: "50%",
    backgroundColor: theme.colors.light.base,
    margin: theme.spacing.xsmall,
  },
  camera: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: "0.5",
    cursor: "pointer",
  },
  placeholder: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: `${theme.spacing.small} 0`,
  },
  buttonsContainer: {
    display: "flex",
  },
  button: {
    zIndex: theme.stack.inputFile,
  },
  trashIconContainer: {
    cursor: "pointer",
    position: "absolute",
    bottom: `-${theme.spacing.xsmall}`,
    left: "30%",
    backgroundColor: theme.colors.light.base,
    padding: theme.spacing.xsmall2,
    borderRadius: "50%",
  },
  loadingIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing.medium} 0`,
  },
});
