import React, { useImperativeHandle, useState } from "react";
import DayPicker, {
  DateUtils,
  DayPickerProps,
  DayModifiers,
} from "react-day-picker";

/**
 * Component prop types.
 */
type DatePickerPropTypes = {
  className?: string;
  numberOfMonths?: number;
  datePickerRef?: any;
  endMonth?: Date;
  isRanged?: boolean;
  fromMonth?: DayPickerProps["fromMonth"];
  toMonth?: DayPickerProps["toMonth"];
  disabledDays?: DayPickerProps["disabledDays"];
  hideCalendar?: () => void;
  onValueChange: (args: any) => void;
  resetCalendarInput?: () => void;
};

/**
 * Iniital state.
 */
const initialState = {
  from: undefined,
  to: undefined,
  enteredTo: null,
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const DatePicker = React.forwardRef<any, DatePickerPropTypes>(
  (
    {
      className = "DayPicker-Wrapper",
      numberOfMonths = 2,
      datePickerRef = { current: {} },
      isRanged,
      hideCalendar,
      onValueChange,
      fromMonth,
      toMonth,
      disabledDays,
      resetCalendarInput,
    },
    ref,
  ) => {
    /**
     * Component state.
     */
    const [dateRange, setDateRange] = useState<{
      from: any;
      to: any;
      enteredTo: any;
    }>(initialState);
    const [initialLoad, setInitialLoad] = useState(true);

    /**
     * Modifier functions.
     */
    const firstOfMonth = (day: Date) => {
      const firstDay = new Date(day.getFullYear(), day.getMonth(), 1);
      return day.getDate() === firstDay.getDate();
    };

    const lastOfMonth = (day: Date) => {
      const lastDay = new Date(day.getFullYear(), day.getMonth() + 1, 0);
      return day.getDate() === lastDay.getDate();
    };

    /**
     * Handle day picker single select.
     */
    const handleDayClick = (day: Date, modifiers: DayModifiers) => {
      if (modifiers.disabled) {
        return;
      }
      setDateRange({
        from: day,
        to: null,
        enteredTo: null,
      });
      onValueChange({ from: day });
      if (hideCalendar) {
        hideCalendar();
      }
    };

    /**
     * Is selecting first day calulation.
     */
    const isSelectingFirstDay = (
      from: Date | null,
      to: Date | null,
      day: Date,
    ) => {
      const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
      const isRangeSelected = from && to;
      return !from || isBeforeFirstDay || isRangeSelected;
    };

    /**
     * Handle day picker ranged calendar click.
     */
    const handleDayRangeClick = (day: Date, modifiers: DayModifiers) => {
      const { from, to } = dateRange;

      if (modifiers.disabled) {
        return;
      }

      if (from && to && day >= from && day <= to) {
        setDateRange(initialState);
        return;
      }

      if (isSelectingFirstDay(from, to, day)) {
        setDateRange({
          from: day,
          to: null,
          enteredTo: null,
        });
      } else {
        setDateRange({
          to: day,
          from,
          enteredTo: day,
        });

        if (hideCalendar) {
          hideCalendar();
        }
        if (onValueChange) {
          onValueChange({ from, to: day });
        }
      }

      if (initialLoad) {
        setInitialLoad(false);
      }
    };

    /**
     * Day mouse enter ranged handler.
     */
    const handleDayMouseEnter = (day: Date) => {
      const { from, to } = dateRange;

      if (!isSelectingFirstDay(from, to, day)) {
        setDateRange({
          ...dateRange,
          enteredTo: day,
        });
      }
    };

    /**
     * Hacky, please don't fire me...
     */
    useImperativeHandle(ref, () => ({
      reset: () => {
        setDateRange(initialState);
        if (resetCalendarInput) {
          resetCalendarInput();
        }
      },
    }));

    return (
      <div className={className}>
        <DayPicker
          numberOfMonths={numberOfMonths}
          onDayClick={isRanged ? handleDayRangeClick : handleDayClick}
          onDayMouseEnter={isRanged ? handleDayMouseEnter : undefined}
          modifiers={{
            start: dateRange.from,
            end: dateRange.enteredTo,
            firstOfMonth,
            lastOfMonth,
          }}
          fromMonth={fromMonth}
          toMonth={toMonth}
          disabledDays={disabledDays}
          selectedDays={
            isRanged
              ? [
                  dateRange.from,
                  { from: dateRange.from, to: dateRange.enteredTo },
                ]
              : []
          }
          ref={datePickerRef}
        />
      </div>
    );
  },
);
