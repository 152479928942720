/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const CompaniesIcon: React.FC<TGenericImage> = ({
  fill = "#FFF",
  width = 22,
  height = 25,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <g fill={fill}>
        <path d="M8.96,5.7276 L14.5984,6.8476 C15.0528,6.9436 15.36,7.3276 15.36,7.782 L15.36,14.31 C15.36,14.8412 14.9312,15.27 14.4,15.27 L8.64,15.27 C8.8192,15.27 8.96,15.1292 8.96,14.95 L8.96,14.63 L14.4,14.63 C14.5728,14.63 14.72,14.4892 14.72,14.31 L14.72,7.782 C14.72,7.6348 14.6176,7.5004 14.4704,7.4684 L8.96,6.3804 L8.96,5.7276 Z" />
        <path d="M12.48 8.87C12.6592 8.87 12.8 9.0108 12.8 9.19 12.8 9.3692 12.6592 9.51 12.48 9.51L11.2 9.51C11.0208 9.51 10.88 9.3692 10.88 9.19 10.88 9.0108 11.0208 8.87 11.2 8.87L12.48 8.87zM12.48 10.79C12.6592 10.79 12.8 10.9308 12.8 11.11 12.8 11.2892 12.6592 11.43 12.48 11.43L11.2 11.43C11.0208 11.43 10.88 11.2892 10.88 11.11 10.88 10.9308 11.0208 10.79 11.2 10.79L12.48 10.79zM12.48 12.71C12.6592 12.71 12.8 12.8508 12.8 13.03 12.8 13.2092 12.6592 13.35 12.48 13.35L11.2 13.35C11.0208 13.35 10.88 13.2092 10.88 13.03 10.88 12.8508 11.0208 12.71 11.2 12.71L12.48 12.71zM8.96 14.95C8.96 15.1292 8.8192 15.27 8.64 15.27 8.4608 15.27 8.32 15.1292 8.32 14.95L8.32 14.63 8.32 5.99C8.32 5.894 8.3648 5.8044 8.4352 5.7404 8.512 5.6828 8.608 5.6572 8.704 5.6764L8.96 5.7276 8.96 6.3804 8.96 14.63 8.96 14.95z" />
        <path d="M8.32 14.63L8.32 14.95C8.32 15.1292 8.4608 15.27 8.64 15.27L6.08 15.27C6.2592 15.27 6.4 15.1292 6.4 14.95L6.4 14.63 8.32 14.63zM6.72 3.11C6.8992 3.11 7.04 3.2508 7.04 3.43 7.04 3.6092 6.8992 3.75 6.72 3.75L5.44 3.75C5.2608 3.75 5.12 3.6092 5.12 3.43 5.12 3.2508 5.2608 3.11 5.44 3.11L6.72 3.11zM7.04 5.35C7.04 5.5292 6.8992 5.67 6.72 5.67L5.44 5.67C5.2608 5.67 5.12 5.5292 5.12 5.35 5.12 5.1708 5.2608 5.03 5.44 5.03L6.72 5.03C6.8992 5.03 7.04 5.1708 7.04 5.35zM6.72 6.95C6.8992 6.95 7.04 7.0908 7.04 7.27 7.04 7.4492 6.8992 7.59 6.72 7.59L5.44 7.59C5.2608 7.59 5.12 7.4492 5.12 7.27 5.12 7.0908 5.2608 6.95 5.44 6.95L6.72 6.95zM6.72 8.87C6.8992 8.87 7.04 9.0108 7.04 9.19 7.04 9.3692 6.8992 9.51 6.72 9.51L5.44 9.51C5.2608 9.51 5.12 9.3692 5.12 9.19 5.12 9.0108 5.2608 8.87 5.44 8.87L6.72 8.87zM3.84 9.19C3.84 9.3692 3.6992 9.51 3.52 9.51L2.24 9.51C2.0608 9.51 1.92 9.3692 1.92 9.19 1.92 9.0108 2.0608 8.87 2.24 8.87L3.52 8.87C3.6992 8.87 3.84 9.0108 3.84 9.19zM3.52 3.11C3.6992 3.11 3.84 3.2508 3.84 3.43 3.84 3.6092 3.6992 3.75 3.52 3.75L2.24 3.75C2.0608 3.75 1.92 3.6092 1.92 3.43 1.92 3.2508 2.0608 3.11 2.24 3.11L3.52 3.11zM3.52 5.03C3.6992 5.03 3.84 5.1708 3.84 5.35 3.84 5.5292 3.6992 5.67 3.52 5.67L2.24 5.67C2.0608 5.67 1.92 5.5292 1.92 5.35 1.92 5.1708 2.0608 5.03 2.24 5.03L3.52 5.03zM3.52 6.95C3.6992 6.95 3.84 7.0908 3.84 7.27 3.84 7.4492 3.6992 7.59 3.52 7.59L2.24 7.59C2.0608 7.59 1.92 7.4492 1.92 7.27 1.92 7.0908 2.0608 6.95 2.24 6.95L3.52 6.95zM5.76 11.75C5.76 11.5708 5.6128 11.43 5.44 11.43L3.52 11.43C3.3408 11.43 3.2 11.5708 3.2 11.75L3.2 14.63 2.56 14.63 2.56 11.75C2.56 11.2188 2.9888 10.79 3.52 10.79L5.44 10.79C5.9712 10.79 6.4 11.2188 6.4 11.75L6.4 14.63 5.76 14.63 5.76 11.75z" />
        <path d="M3.2,14.63 L5.76,14.63 L6.4,14.63 L6.4,14.95 C6.4,15.1292 6.2592,15.27 6.08,15.27 L2.88,15.27 C2.7008,15.27 2.56,15.1292 2.56,14.95 L2.56,14.63 L3.2,14.63 Z" />
        <path d="M1.12,0.038 L8.1536,1.1068 C8.6208,1.1836 8.96,1.5804 8.96,2.054 L8.96,5.7276 L8.704,5.6764 C8.608,5.6572 8.512,5.6828 8.4352,5.7404 C8.3648,5.8044 8.32,5.894 8.32,5.99 L8.32,2.054 C8.32,1.894 8.2048,1.7596 8.0512,1.734 L1.0176,0.6716 C0.9984,0.6652 0.9792,0.6652 0.96,0.6652 C0.8832,0.6652 0.8128,0.6908 0.7552,0.742 C0.6784,0.806 0.64,0.8892 0.64,0.9852 L0.64,14.31 C0.64,14.4892 0.7872,14.63 0.96,14.63 L2.56,14.63 L2.56,14.95 C2.56,15.1292 2.7008,15.27 2.88,15.27 L0.96,15.27 C0.4288,15.27 0,14.8412 0,14.31 L0,0.9852 C0,0.7036 0.1216,0.4348 0.3392,0.2556 C0.5568,0.07 0.8384,-0.0068 1.12,0.038 Z" />
      </g>
    </svg>
  );
};
