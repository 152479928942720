import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";

/**
 * Import helpers.
 */
import { formatToPrice } from "../../../helpers";

/**
 * Import images.
 */
import OnlineOrder from "../../../images/online-order.svg";

/**
 * Types.
 */
interface IWebsiteNumbers {
  orders: number;
  value: number;
  isLoading: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const WebsiteNumbers = ({
  orders,
  value,
  isLoading,
}: IWebsiteNumbers): React.ReactElement => {
  const [t] = useTranslation("leaderboard");
  /**
   * Previous values.
   */
  const previousRef = useRef([0, 0]);

  const [ordersProps, setOrders] = useSpring<{
    number: number;
  }>(() => ({
    number: 0,
  }));
  const [valueProps, setValue] = useSpring<{
    number: number;
  }>(() => ({
    number: 0,
  }));

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const { current: prevValues } = previousRef;
    if (prevValues[0] !== orders) {
      previousRef.current[0] = orders;
      setOrders({
        number: orders,
        config: {
          duration: 700,
        },
      });
    }
    if (prevValues[1] !== value) {
      previousRef.current[1] = value;
      setValue({
        number: value,
        config: {
          duration: 700,
        },
      });
    }
  }, [orders, value, isLoading, setValue, setOrders]);

  return (
    <div className="relative rounded-xl bg-white p-6 shadow-filter cell-inner cell-inner-data h-full overflow-hidden">
      <div
        className="cell-image bg-no-repeat absolute top-0 left-0 w-full h-full"
        style={{ backgroundImage: `url(${OnlineOrder})` }}
      />
      <p className="text-lg mt-4">{t("headings.websitePayments")}</p>
      <animated.h3 className="text-primary font-bold text-4xl leading-none">
        {valueProps.number.interpolate((val: number) => formatToPrice(val))}
      </animated.h3>

      <div className="dashboard-divider mt-5 mb-6 border-b border-gray-200 -ml-6 2xl:-ml-12" />

      <p className="text-lg">{t("headings.websiteOrders")}</p>
      <animated.h3 className="text-primary font-bold text-4xl leading-none">
        {ordersProps.number.interpolate((val: number) => Math.floor(val))}
      </animated.h3>
    </div>
  );
};
