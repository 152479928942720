import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Table,
  formatCurrency,
  Button,
  Text,
  theme,
  Modal,
  Message,
} from "@clearabee/ui-library";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import type { Job } from "@clearabee/api";

interface TableRowProps {
  index: number;
  job: Job;
  unassignJob: (jobId: string, msJobId: string) => void;
  isLoading: boolean;
  couldNotUnassignJob: boolean;
  hasUnassignedJob: boolean;
}

dayjs.extend(utc);

export const TableRow = ({
  job,
  index,
  unassignJob,
  isLoading,
  couldNotUnassignJob,
  hasUnassignedJob,
}: TableRowProps): React.ReactElement => {
  const history = useHistory();
  const [translate] = useTranslation("users");
  const [showDescription, setShowDescription] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    _id,
    jobId,
    jobReference,
    user,
    collectionDetails,
    invoiceDetails,
    wasteDetails,
    status,
    contractor,
    availableContractors,
  } = job;

  useEffect(() => {
    if (hasUnassignedJob) {
      setModalOpen(false);
    }
  }, [hasUnassignedJob]);

  let pillColor;
  switch (status) {
    case "accepted" || "onway" || "arrived" || "collected" || "tipped":
      pillColor = "accent";
      break;
    case "completed":
      pillColor = "positive";
      break;
    case "failed":
      pillColor = "negative";
      break;
    default:
      pillColor = "warning";
      break;
  }

  const disableUnassign =
    !contractor && availableContractors && availableContractors.length <= 0;

  return (
    <Table.Row
      key={`table-row-${index}`}
      onClick={() =>
        history.push(
          jobId ? `/jobs/update/${encodeURIComponent(jobId)}` : "/jobs",
        )
      }
      styles={{ cursor: "pointer" }}
      className="relative"
    >
      <Table.Cell.Pill color={pillColor as AllowedColorKeys}>
        {status || "open"}
      </Table.Cell.Pill>
      <Table.Cell.Text>{jobReference}</Table.Cell.Text>
      <Table.Cell onClick={(event) => event.stopPropagation()}>
        <Button size="xsmall" onClick={() => setShowDescription(true)}>
          {translate("subcontractors.buttons.showDescription")}
        </Button>
        {showDescription && (
          <Modal width={500} onClose={() => setShowDescription(false)}>
            <Text styles={{ marginRight: theme.spacing.large }}>
              {wasteDetails.description
                ? wasteDetails.description
                : translate("subcontractors.messages.noDescription")}
            </Text>
          </Modal>
        )}
      </Table.Cell>
      <Table.Cell.Text>
        {user
          ? user.company || translate("subcontractors.messages.noCompanyName")
          : translate("subcontractors.messages.currentlyUnassigned")}
      </Table.Cell.Text>
      <Table.Cell.Text>
        {dayjs.utc(collectionDetails.requestedDate).format("DD/MM/YYYY")}
      </Table.Cell.Text>
      <Table.Cell.Text>
        {collectionDetails.collectionAddress.postcode}
      </Table.Cell.Text>
      <Table.Cell.Text>
        {formatCurrency(
          Number(invoiceDetails?.finalAmount) ||
            Number(invoiceDetails?.amount) ||
            0,
        )}
      </Table.Cell.Text>
      <Table.Cell onClick={(event) => event.stopPropagation()}>
        <Button
          size="xsmall"
          color="warning"
          onClick={() => setModalOpen(true)}
          disabled={disableUnassign || isLoading}
        >
          {translate("subcontractors.buttons.unassignJob")}
        </Button>
      </Table.Cell>
      {modalOpen && (
        <div onClick={(event) => event.stopPropagation()}>
          <Modal onClose={() => setModalOpen(false)} width={600}>
            <Text>{translate("subcontractors.messages.confirmUnassign")}</Text>
            <Text
              color="brand"
              fontSize="xlarge2"
              styles={{ marginTop: theme.spacing.xsmall, fontWeight: 700 }}
            >
              {jobReference}
            </Text>
            {couldNotUnassignJob && (
              <div className="flex">
                <Message color="negative" className="mx-auto">
                  {translate("subcontractors.errors.couldNotUnassign")}
                </Message>
              </div>
            )}
            <div className="flex flex-row gap-6 mt-12 justify-around">
              <Button
                disabled={isLoading}
                color="negative"
                onClick={() => setModalOpen(false)}
              >
                {translate("subcontractors.buttons.cancel")}
              </Button>
              <Button
                disabled={isLoading}
                color="positive"
                onClick={() => unassignJob(String(_id), jobId)}
              >
                {translate("subcontractors.buttons.confirm")}
              </Button>
            </div>
          </Modal>
        </div>
      )}
    </Table.Row>
  );
};
