/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const TravellingBee: React.FC<TGenericImage> = ({
  className = "travelling-bee",
  fill = "currentColor",
  stroke = "currentColor",
  width = 763,
  height = 256,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 763 256"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke={stroke}
          strokeDasharray="0,10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity="0.2"
          strokeWidth="2"
          d="M382 255c228.094 0 413-184.906 413-413S610.094-571 382-571-31-386.094-31-158s184.906 413 413 413z"
        />
        <g fill={fill} fillOpacity="0.2">
          <path d="M674.616 126.574c-3.357 2.315-7.218 2.873-10.076 1.062-2.838-1.795-3.991-5.515-3.313-9.562 1.704 1.97 3.77 3.706 6.095 5.191a28.122 28.122 0 007.294 3.309m9.37-39.502c.538 1.057.538 2.18.067 2.781.643 2.473-.506 4.757-1.338 6.016.53.173 1.018.402 1.499.718a7.607 7.607 0 012.377 10.526 7.532 7.532 0 01-2.254 2.3c-.719.378-1.343.98-1.797 1.717-.473.72-.713 1.533-.797 2.376.001.153-.012.325-.01.478.002.153-.011.324-.043.481a15.238 15.238 0 01-7.313-2.354 15.01 15.01 0 01-5.227-5.603c.107-.097.285-.17.411-.254.15-.035.29-.138.416-.22a4.824 4.824 0 001.83-1.722c.473-.72.729-1.552.764-2.372a7.298 7.298 0 011.116-2.977 7.6 7.6 0 014.792-3.376c.275-1.088.546-3.18-.413-4.899-.707.052-1.6-.434-2.285-1.302-.856-1.154-.99-2.533-.258-3.081.732-.55 2.017-.06 2.906 1.09.67.888.901 1.948.61 2.63 1.11 1.837.985 4.012.728 5.388a7.08 7.08 0 011.713.097c.716-.956 1.953-3.08 1.43-5.278-.7-.153-1.408-.798-1.877-1.71-.633-1.284-.481-2.68.32-3.083.82-.39 2.014.331 2.633 1.633" />
          <path d="M666.121 107.601c.169 1.566.781 3.064 1.814 4.254a19.337 19.337 0 004.279 3.679 19.447 19.447 0 008.576 2.947 17.087 17.087 0 01-1.627 3.244c-.326.532-.671 1.052-1.058 1.507a24.621 24.621 0 01-8.614-3.402 23.959 23.959 0 01-5.07-4.298c-.782-.878-1.966-1.285-3.135-1.162l-10.258 1.085c-3.877.423-7.331-2.37-7.754-6.246-.386-3.848 2.435-7.34 6.279-7.759.689-.067 1.353-.064 2.015.06a7.33 7.33 0 01-.482-1.982c-.386-3.848 2.407-7.303 6.265-7.74 3.863-.405 7.317 2.387 7.74 6.264l1.03 9.55" />
        </g>
      </g>
    </svg>
  );
};
