import React, { useState } from "react";
import { IQuoteImage } from "../../../../api/types";
import { ChevronButtonDown } from "../../../common/components";
//import { ImagePlaceholder } from '../../../../images/imagePlaceholder'

const ImageBlock: React.FC<{
  imageData: IQuoteImage[] | undefined;
}> = ({ imageData }): JSX.Element => {
  return (
    <>
      <span className="text-xs text-black font-semibold">
        <p className="mt-5">
          {imageData?.length
            ? `Images (${imageData?.length} images)`
            : "No images available"}
        </p>
      </span>
      <div className="sm:grid sm:grid-template-cols sm:grid-cols-3 gap-5 w-full mt-4">
        {imageData &&
          imageData?.length > 0 &&
          imageData.map((image: IQuoteImage) =>
            image.url ? (
              <div className="rounded-md w-28 max-h-24" key={image.url}>
                <img
                  src={image.url}
                  alt="Image"
                  className="rounded-md w-full h-full"
                />
              </div>
            ) : (
              <p className="text-xs text-primary-400 font-bold">
                Cannot display image.
              </p>
            ),
          )}
      </div>
    </>
  );
};
export const QuoteImages: React.FC<{
  imageData: IQuoteImage[] | undefined;
}> = ({ imageData = [] }): JSX.Element => {
  /**
   * State.
   */
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className="mt-3">
      <div>
        <span className="flex justify-end text-sm text-black font-semibold">
          <p className="my-auto mr-5">View all images</p>
          <ChevronButtonDown
            onClick={() => imageData?.length > 0 && setExpanded(!expanded)}
            active={expanded}
          />
        </span>
      </div>
      <ImageBlock imageData={imageData} />
      {expanded && (
        <>
          {/* <ImageBlock imageData={imageData} />
					<ImageBlock imageData={imageData} />
					<ImageBlock imageData={imageData} /> */}
        </>
      )}
    </div>
  );
};
