"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.driversJobs = void 0;
exports.driversJobs = {
    heading: "Driver Jobs",
    dashboard: "Dashboard",
    drivers: "Drivers",
    jobs: "Jobs",
    areYouSure: "Are you sure you want to remove this job?",
    form: {
        headings: {
            driver: "Driver",
            driverEmail: "Driver Email",
            vehicleReg: "Vehicle Reg",
            totalJobs: "Total Jobs",
            jobRef: "Job Ref",
            jobDate: "Job Date",
            jobPostcode: "Job Postcode",
            jobBcId: "Job BC ID",
            jobStatus: "Job Status",
            jobStatusId: "Job Status ID",
            jobAsset: "Job Asset",
            vehicleId: "Vehicle ID",
            jobType: "Job Type",
            orderTypeId: "Order Type ID",
        },
    },
    buttons: {
        reset: "Reset",
        removeJob: "Remove Job",
        cancel: "Cancel",
    },
    errors: {
        noDriverFound: "No driver found",
        noJobsFound: "No jobs found",
        unableToDeleteJob: "Unable to delete job",
    },
};
