import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import {
  Table,
  theme,
  Heading,
  Panel,
  usePagination,
  Button,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { FormLoader } from "components/common/components";

interface ResultsTableProps {
  // suppliers: ApiResponseData<typeof instance.users.getSuppliers>["items"];
  suppliers: any;
}

const ResultsTable = ({ suppliers }: ResultsTableProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const { PaginationComponent, paginatedData } = usePagination(suppliers);
  return (
    <>
      <div className="relative overflow-x-scroll">
        <Table styles={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "25%" }} />
          </colgroup>
          <Table.Header
            headings={[
              ``,
              `${translate("suppliers.headings.id")}`,
              `${translate("suppliers.headings.reference")}`,
              `${translate("suppliers.headings.name")}`,
              ``,
            ]}
          />
          <Table.Body>
            {paginatedData.map((supplier: any) => (
              <Table.Row
                key={`table-row-${supplier.id}`}
                data-testid="catalogue-result"
              >
                <Table.Cell.Pill
                  size="small"
                  color={supplier.active ? "accent" : "warning"}
                >
                  {supplier.active
                    ? translate("suppliers.headings.active")
                    : translate("suppliers.headings.inactive")}
                </Table.Cell.Pill>

                <Table.Cell.Text>{supplier.id}</Table.Cell.Text>

                <Table.Cell.Text>{supplier.supplierRef}</Table.Cell.Text>

                <Table.Cell.Text>{supplier.name}</Table.Cell.Text>

                <Table.Cell>
                  <div className="flex flex-row gap-2 float-right">
                    {/* LORENZO - I am not entirely sure here, but I think this button should
                      take one to a users page, and then display all users belonging to that supplier. I have built a readUsers page, but
                      it is not in this codebase, I removed it as it wasn't required. Let me know if you need it, and I'll give you all
                      the work I've done on this so far.  */}
                    <RouteLink href={`/suppliers/users/${supplier.id}`}>
                      <Button
                        size="xsmall"
                        as="a"
                        className="text-center"
                        color="warning"
                      >
                        {translate("suppliers.buttons.users")}
                      </Button>
                    </RouteLink>
                    <RouteLink href={`/suppliers/update/${supplier.id}`}>
                      <Button size="xsmall" as="a" className="text-center">
                        {translate("suppliers.buttons.edit")}
                      </Button>
                    </RouteLink>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>

      <div className="mt-10">
        <PaginationComponent />
      </div>
    </>
  );
};

export const ReadSuppliers = (): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [supplierTypeToDisplay, setSupplierTypeToDisplay] = useState<
    null | string
  >("");

  /**
   * Check the query params in the url, and if it contains
   * a supplierType Name, then show just this type of supplier
   */
  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (queryParams) {
      setSupplierTypeToDisplay(queryParams.get("supplierTypeName"));
    }
  }, [queryParams]);

  const {
    data: suppliersData,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["readSuppliers"],
    () => ({} as any),
    // instance.users.getSuppliers({ params: { type: supplierTypeToDisplay } }),
  );

  useEffect(() => {
    refetch();
  }, [queryParams]);

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <Panel className="mb-10">
        <div className="flex flex-row justify-between items-center">
          <Heading level={3} fontSize="large" color="brand">
            {translate("suppliers.headings.suppliers")}
          </Heading>
          <div className="flex flex-row gap-4">
            <RouteLink href="/suppliers/types">
              <Button size="small" color="warning">
                {translate("suppliers.buttons.viewSupplierTypes")}
              </Button>
            </RouteLink>

            <RouteLink href="/suppliers/create">
              <Button size="small">
                {translate("suppliers.buttons.addNewSupplier")}
              </Button>
            </RouteLink>
          </div>
        </div>
      </Panel>

      {!isLoading && !isError ? (
        <ResultsTable suppliers={suppliersData?.data.items || []} />
      ) : (
        <div className="relative" style={{ height: theme.screens.xsmall }}>
          <FormLoader isLoading />
        </div>
      )}
    </div>
  );
};
