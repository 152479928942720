import React from "react";
import { Link, Route, useParams } from "react-router-dom";
import { theme } from "@clearabee/ui-library";

/**
 * Import routes.
 */
import { routes, Routes } from "../../../helpers/routes";

/**
 * Import components.
 */
import { Heading, Breadcrumbs } from "@clearabee/ui-library";

/**
 * Function to get all of the parents of a route.
 */
const getRouteParents = (routeKey: string) => {
  const rt = new Routes();

  const parents = [];
  let key = routeKey;

  for (let i = 0; i < routes.length; i += 1) {
    const route = rt.config(key);

    if (typeof route.parent !== "undefined") {
      parents.push(route.parent);

      /**
       * Set the parent
       */
      key = route.parent;
    } else {
      break;
    }
  }

  return parents.reverse();
};

interface BreadcrumbProps {
  breadcrumb: string | any;
  index: number;
  lastItem?: boolean;
}

const BreadCrumb = ({
  breadcrumb,
  index,
  lastItem,
}: BreadcrumbProps): React.ReactElement => {
  const params = useParams();
  const { path, nicename } = breadcrumb;
  const href = Object.entries(params).reduce(
    (path, [key, value]) => path.replace(`:${key}`, value),
    path,
  );

  return (
    <Breadcrumbs.Item key={`${name}-${index}`}>
      <Link
        to={href}
        style={{
          pointerEvents: lastItem ? "none" : "visible",
          fontWeight: lastItem ? 900 : 400,
        }}
        color="greyscale"
      >
        {nicename}
      </Link>
    </Breadcrumbs.Item>
  );
};
/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */

export const TitleBreadcrumbs = ({
  ...hiddenLinks
}: string[]): React.ReactElement => {
  let pushedHeading = false;
  return (
    <>
      {routes.map((route) => {
        if (route.type === "private") {
          return (
            <Route
              key={`${route.exact}-${route.nicename}`}
              exact={route.exact}
              path={route.path}
              render={() => {
                if (pushedHeading) {
                  return <></>;
                }

                const components = [
                  <Heading key={route.nicename} fontSize="large" color="brand">
                    {route.nicename}
                  </Heading>,
                ];
                /**
                 * Loop through each breadcrumb in the array and build out a menu.
                 */
                if (typeof route.parent !== "undefined") {
                  const rt = new Routes();

                  let breadcrumbs = getRouteParents(route.name).map((parent) =>
                    rt.config(parent),
                  );
                  const currentRoute = rt.config(route.name);
                  breadcrumbs.push(currentRoute);

                  breadcrumbs = hiddenLinks?.length
                    ? breadcrumbs.filter((link) =>
                        hiddenLinks.some(
                          (hidden: string) => hidden !== link.name,
                        ),
                      )
                    : breadcrumbs;

                  if (breadcrumbs.length > 0) {
                    components.push(
                      <Breadcrumbs
                        styles={{ marginBottom: theme.spacing.small }}
                        // we're pushing into an array, so we need a key on each item we push
                        key={`${route.nicename}-breadcrumbs`}
                      >
                        {breadcrumbs.map((breadcrumb, index) => {
                          return (
                            <BreadCrumb
                              breadcrumb={breadcrumb}
                              key={`${breadcrumb.name}-${index}`}
                              index={index}
                              lastItem={index === breadcrumbs.length - 1}
                            />
                          );
                        })}
                      </Breadcrumbs>,
                    );
                  }
                }

                pushedHeading = true;

                return components;
              }}
            />
          );
        }
      })}
    </>
  );
};
