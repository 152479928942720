import React, { useRef, useState, useEffect } from "react";

/**
 * Import interfaces.
 */
import { IFile, TFileList, IFileUpload } from "./types";

/**
 * Import helpers.
 */
import { onDataChange, generateFileList } from "./helpers";

/**
 * File upload component.
 */
export const FileUpload: React.FC<IFileUpload> = ({
  fieldName = "fileUpload",
  mode = "single",
  onChange,
  children,
  accept = ".jpg,.jpeg,.png",
  maxFiles = 10,
  defaultValues,
  isLoading = false,
  isDisabled = false,
  clearFileList = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState(defaultValues || []);
  const [error, setError] = useState("");
  const fileListRef = useRef(fileList);

  useEffect(() => {
    if (clearFileList) {
      setFileList(defaultValues || []);
    }
  }, [clearFileList]);

  /**
   * Add key and removeFile function to default values.
   */
  useEffect(() => {
    if (!defaultValues) return;
    const updatedList = fileList.map((item) => {
      const key = `${new Date().getTime().toString()}-${item.file.name}`;
      return {
        dataUrl: item.dataUrl,
        file: item.file,
        key,
        removeFile: (): void => removeFile(key),
      };
    });
    setFileList(updatedList);
  }, [defaultValues]);

  /**
   * Update ref of file list each time it changes.
   */
  useEffect(() => {
    fileListRef.current = fileList;
  }, [fileList]);

  /**
   * On upload file, click input to open file browser.
   */
  const uploadFile = (): void => {
    if (inputRef.current !== null && !isLoading && !isDisabled) {
      inputRef.current.click();
    }
  };

  /**
   * Remove a file on click.
   */
  const removeFile = (key: string): void => {
    const updatedList: TFileList = fileListRef.current.filter(
      (item: IFile) => item.key !== key,
    );

    setFileList(updatedList);
    onDataChange(updatedList, onChange);
  };

  /**
   * Function to run when a new file (or files) are added to the uploader.
   */
  const onDrop = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const { files } = e.target;
    setError("");

    if (files) {
      let newFileList = await generateFileList(files, removeFile);

      /**
       * Check for files now allowed.
       */
      newFileList.forEach((f: IFile) => {
        const extension = f.file.name.split(".").pop();

        if (typeof extension === "string") {
          if (!accept.includes(extension)) {
            newFileList = [];
            setError(
              `You have tried to upload a file type which is not allowed (allowed: ${accept})`,
            );
          }
        }
      });

      /**
       * If new files exist, attempt to process them.
       */
      if (newFileList.length > 0) {
        let updatedFileList: TFileList;

        if (mode === "multiple") {
          updatedFileList = [...fileList, ...newFileList];

          /**
           * Throw an error if maximum files limit is reached.
           * - This max file limit runs on a combination of current and new files
           */
          if (updatedFileList.length > maxFiles) {
            updatedFileList = fileList;
            setError(`You can only upload a maximum of ${maxFiles} files.`);
          }
        } else {
          updatedFileList = [newFileList[0]];
        }

        setFileList(updatedFileList);
        onDataChange(updatedFileList, onChange);
      }
    }
  };

  return (
    <>
      <input
        name={fieldName}
        type="file"
        accept={accept}
        ref={inputRef}
        onChange={onDrop}
        multiple={mode === "multiple"}
        className="hidden"
      />
      {children &&
        children({
          fileList,
          uploadFile,
          error,
          isLoading,
          isDisabled,
        })}
    </>
  );
};
