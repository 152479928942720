/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */

export const DownloadIcon: React.FC<TGenericImage> = ({
  className = "download-icon",
  fill = "currentColor",
  width = 482,
  height = 482,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 482 482"
      width={width}
      height={height}
      className={className}
    >
      <path
        fill={fill}
        d="M0 447.793h482.239v34.446H0zm396.091-223.93l-24.287-24.354-113.462 113.462V0h-34.446v312.971L110.401 199.475l-24.22 24.354 154.938 155.073z"
      />
    </svg>
  );
};
