import React from "react";
import useMeasure from "react-use-measure";
import Polyfill from "resize-observer-polyfill";
import { useLocation } from "react-router-dom";
import { useTransition, animated } from "react-spring";

/**
 * Import hooks.
 */
import { useScreenWidth } from "../../hooks";

/**
 * Import components.
 */
import { SpringTransition } from "../core";
import { PanelRight, PanelLeft } from "./components";
import { Router } from "../app/components";

/**
 * Import images.
 */
import { ReactComponent as LogoBee } from "./images/auth-logo-bee.svg";

/**
 * Import styles.
 */
import "./styles/auth.css";

/**
 * Import helpers.
 */
// import { Routes } from '../../helpers'

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Auth: React.FC = () => {
  const { isMobile, isTablet } = useScreenWidth();
  const [ref, bounds] = useMeasure({ polyfill: Polyfill || undefined });
  const location = useLocation();

  /**
   * Initiate routes helper
   */
  // const rt = new Routes()

  /**
   * Transitions between each route.
   * - Needs some finalisation later, but good for now.
   */
  const routerTransitions = useTransition(
    location,
    (currentLocation) => currentLocation.pathname,
    {
      config: {
        duration: 300,
      },
      initial: {
        opacity: 1,
        transform: "translate3d(0px,0,0)",
      },
      from: {
        opacity: 0,
        transform: "translate3d(0px,0,0)",
      },
      enter: {
        opacity: 1,
        transform: "translate3d(0,0,0)",
      },
      leave: {
        opacity: 0,
        transform: "translate3d(0px,0,0)",
        position: "absolute",
        minWidth: "254px",
        maxWidth: "254px",
      },
    },
  );

  /**
   * If 404 then simple return component without any transition
   * Transforms cause issue with position fixed
   * so thats the reason why need to do this way
   */
  /*
    Had to temporary disable the below because it breaks the password reset functionality/screen
    if ( !rt.hasPath( location.pathname ) ) {
        return (
            <Router
                item={location}
            />
        )
    }
    */

  return (
    <div className="px-3 min-h-screen relative">
      <PanelRight />

      <div className="min-h-screen py-10 m-auto flex justify-center items-center relative max-w-screen-2xl">
        <div className="w-full md:w-1/2 flex mr-0 lg:mr-auto justify-left z-10 auth-panel">
          <SpringTransition
            className="bg-white mr-auto ml-auto shadow-modal rounded-xl overflow-hidden auth-panel__content"
            transition="slideInUp"
            duration={1000}
          >
            <div
              className="relative auth-panel__transition"
              style={{
                height: bounds.height,
              }}
            >
              <div ref={ref} className="p-8 md:p-12 absolute w-full">
                {(isMobile || isTablet) && (
                  <div className="flex flex-col lg:hidden">
                    <div className="flex flex-row-reverse mb-2">
                      <LogoBee width={28} height={28} />
                    </div>
                  </div>
                )}
                {routerTransitions.map(({ item, props, key }) => (
                  <animated.div key={key} style={props}>
                    <Router item={item} />
                  </animated.div>
                ))}
              </div>
            </div>
          </SpringTransition>
        </div>
      </div>

      <PanelLeft />
    </div>
  );
};
