import * as Yup from "yup";
import { stringRequired } from "validation/common";
import { translate } from "@clearabee/i18n";

export const getInitialValues = {
  name: "",
  type: "",
  permissions: [],
};

export const addRoleValidation = Yup.object().shape({
  name: stringRequired,
  type: stringRequired,
  permissions: Yup.array()
    .required(translate("portal.roles.errors.permissionRequired"))
    .min(1),
});
