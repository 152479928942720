import React from "react";

/**
 * Import components.
 */
import { ResultsItem } from "../../../jobs/createJob/components/resultsItem";

/**
 * Import models.
 */
import { Item } from "../../../../models/item";
import { Category } from "../../../../models/category";

/**
 * Import hooks.
 */
import { useBasketContext } from "../../../../hooks";

interface IResults {
  results: Array<Item>;
  isPortalCatalogue?: boolean;
  categories: Array<Category> | null;
  selectedCategories: Array<Category["sku"]>;
  setSelectedCategories: (
    newSelectedCategories: Array<Category["sku"]>,
  ) => void;
  excludedItems?: string[];
}

type TErrorMessage = Omit<IResults, "categories"> & {
  items: Array<any>;
};

const ErrorMessage: React.FC<TErrorMessage> = ({
  results,
  items,
  selectedCategories,
  setSelectedCategories,
}) => {
  return results.length === 0 ? (
    <div className="bg-red-500 text-white w-full p-3 text-center rounded-lg mx-2">
      <span className="block">
        Please change your date on the previous step to find available services.
      </span>
      {selectedCategories.length > 0 && (
        <span className="block mt-2">
          <button onClick={() => setSelectedCategories([])}>
            Clear filters
          </button>
        </span>
      )}
    </div>
  ) : items?.length > 0 ? (
    <div className="bg-red-500 text-white w-full p-3 text-center rounded-lg mx-2">
      <span className="block">
        Only one service can be selected at this time.
      </span>
    </div>
  ) : null;
};

export const Results = ({
  isPortalCatalogue,
  results,
  selectedCategories,
  setSelectedCategories,
  excludedItems,
}: IResults): React.ReactElement => {
  const { items } = useBasketContext();

  /**
   * If the array of items includes items that contain the sku
   * of the excludedItems, these will be filtered from
   * the output
   */
  const basketItemsWithItemRemoved = items.filter((item) => {
    return !excludedItems?.includes(item.sku);
  });

  return (
    <>
      <div className="flex items-center justify-end mt-3">
        <div className="w-1/3 text-right text-xs">
          showing {results.length} products
        </div>
      </div>
      <div className="flex flex-wrap -mx-2 mt-2">
        {items.length > 0
          ? results.map(
              (result) =>
                (items[0]?.parentSku === result.parentSku ||
                  items[0]?.isCalloutCharge) && (
                  <ResultsItem
                    isPortalCatalogue={isPortalCatalogue}
                    key={result.sku}
                    {...result}
                  />
                ),
            )
          : results.map((result) => (
              <ResultsItem
                key={result.sku}
                {...result}
                isPortalCatalogue={isPortalCatalogue}
              />
            ))}
        <ErrorMessage
          results={results}
          items={basketItemsWithItemRemoved}
          setSelectedCategories={setSelectedCategories}
          selectedCategories={selectedCategories}
        />
      </div>
    </>
  );
};
