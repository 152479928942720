import React from "react";

/**
 * Import images.
 */
import { DownChevron } from "../../../images";

/**
 * Interface
 */
interface IChevronButton {
  onClick: () => void;
  active?: boolean;
}

export const ChevronButtonDown: React.FC<IChevronButton> = ({
  onClick,
  active,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`flex justify-center items-center w-10 h-10 border-2 border-secondary rounded-full text-black font-bold ${
        active ? "bg-secondary text-white" : ""
      }`}
    >
      {active ? (
        <DownChevron
          className="transform rotate-90 duration-200"
          width={14}
          height={20}
        />
      ) : (
        <DownChevron
          className="transform -rotate-90 duration-200"
          width={14}
          height={20}
        />
      )}
    </button>
  );
};
