/* eslint-disable prettier/prettier */
import React from "react";
import { FormLoader } from "../../components";
import cx from "classnames";
import dayjs from "dayjs";

import { TFilters } from "../../../../api/types";
import { PaginatedResultsTable } from "./paginatedResultsTable";
import { PaginatedResultsActions } from "./paginatedResultsActions";
import { ErrorMessage } from "../errorMessage";
import { usePaginatedQuery } from "../../../../hooks/usePaginatedQuery";

const getResults = (sort: boolean, paginatedData: unknown[]) => {
  return sort
    ? // eslint-disable-next-line
      paginatedData.sort((a: any, b: any) =>
        // Needs sorting... Bad assumption that all query data has `createdAt` property when this isn't always the case.
        // Should instead sort in api call using query param `orderBy`
        dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1,
      )
    : paginatedData;
};

interface PaginatedResultsChildren {
  isFetching: boolean;
  isError: boolean;
  updateFilters: (filters: TFilters) => void;
}

interface PaginatedResultsProps {
  apiFn: (filters: TFilters, page: number, limit: number) => Promise<any>;
  queryKey: string;
  tableColumns: {
    Header: string;
    accessor: string;
    Cell?: any;
    width?: number;
  }[];
  actions?: string[];
  showNavigationButtons?: boolean;
  children: (props: PaginatedResultsChildren) => React.ReactNode;
  initFilters?: string;
  sort?: boolean;
}

export const PaginatedResults = ({
  apiFn,
  queryKey,
  tableColumns,
  actions = [],
  showNavigationButtons = true,
  sort = true,
  initFilters = "",
  children,
}: PaginatedResultsProps): React.ReactElement => {
  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    query: { isFetching, isError, refetch },
  } = usePaginatedQuery(apiFn, queryKey, initFilters, {
    resultOptions: [10, 20, 50, 100],
  });

  // Sort results by newest first
  const results = getResults(sort, paginatedData);

  // attach 'actions' to columns
  const columnsWithActions = [
    ...tableColumns,
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }: any) => (
        <PaginatedResultsActions
          functionName={apiFn.name}
          actions={actions}
          refetch={refetch}
          row={row}
        />
      ),
      width: 75,
    },
  ];

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      {isError && <ErrorMessage />}
      <>
        {children &&
          children({
            isFetching,
            isError,
            updateFilters,
          })}
        <div
          className="relative overflow-auto p-0 lg:px-5"
          style={{ height: !paginatedData.length ? 400 : "" }}
        >
          <div
            className={cx(
              "absolute inset-0 bg-opacity-75 bg-white transition-all duration-500 rounded-lg flex items-center justify-center text-primary z-10",
              {
                "opacity-0 invisible": !isFetching,
              },
            )}
          >
            <FormLoader isLoading={isFetching} />
          </div>
          <PaginatedResultsTable
            columns={columnsWithActions}
            data={results}
            isFetching={isFetching}
          />
        </div>
      </>
      {typeof paginatedData !== "undefined" &&
        results.length > 0 &&
        !isError &&
        showNavigationButtons && (
          <div className="p-0 lg:px-5 pt-5">
            <PaginationComponent />
          </div>
        )}
    </div>
  );
};
