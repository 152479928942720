import React, { useState, useEffect } from "react";
import cx from "classnames";

/**
 * Import images.
 */
import { Tick } from "../../../images";

/**
 * Checkbox interface.
 */
interface IBasicCheckbox {
  name?: string;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  defaultValue?: boolean;
  classes?: {
    wrap?: string;
    labelWrap?: string;
    label?: string;
    element?: string;
    error?: string;
  };
  id?: string;
  value?: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const BasicCheckbox: React.FC<IBasicCheckbox> = ({
  name,
  label,
  onClick,
  classes = {},
  disabled = false,
  id,
  value,
  defaultValue,
}) => {
  const [checked, setChecked] = useState(defaultValue);

  useEffect(() => {
    if (typeof value !== "undefined") {
      setChecked(value);
    }
  }, [value]);

  return (
    <div className={cx(classes.wrap, { disabled })}>
      <label
        className={cx("flex cursor-pointer", classes.labelWrap, {
          "cursor-not-allowed": disabled,
        })}
      >
        <div
          className={cx(
            "bg-white shadow relative w-6 h-6 p-1 flex justify-center items-center mr-2",
            classes.element,
            { "bg-gray-inactive opacity-50": disabled },
          )}
        >
          <input
            className="hidden"
            type="checkbox"
            id={id}
            name={name}
            defaultChecked={checked}
            disabled={disabled}
            onClick={() => {
              setChecked(!checked);
              if (onClick) {
                onClick();
              }
            }}
            data-testid={name ? `test-${name}` : undefined}
          />
          <Tick
            stroke="currentColor"
            classes={{
              svg: cx(
                "w-4 h-4 pointer-events-none text-primary absolute left-1 top-1",
                {
                  hidden: !checked,
                },
              ),
            }}
          />
        </div>
        {label && (
          <span className={cx("select-none", classes.label)}>{label}</span>
        )}
      </label>
    </div>
  );
};
