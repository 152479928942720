import React from "react";

/**
 * Import hooks.
 */
import { useAuthContext, useAppContext } from "../../../hooks";

/**
 * import components.
 */
import { FormButton, Transition } from "../../core";

/**
 * Import images.
 */
import { Hexagon, CloseIcon } from "../../../images";
import DefaultAvatar from "../../../images/default-avatar.png";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const AccountBubble: React.FC = () => {
  const { getCurrentUserEmailAddress, getCurrentUserName, signOut } =
    useAuthContext();
  const { state, dispatch } = useAppContext();
  const { accountBubbleVisible } = state;

  return (
    <>
      <Transition
        className="modal-color bg-primary opacity-25 z-50"
        customSettings={{
          from: { opacity: 0 },
          enter: { opacity: 0.3 },
          leave: { opacity: 0 },
        }}
        toggle={accountBubbleVisible}
      />

      <Transition
        className="sidebar-acc-bubble z-50 fixed"
        transition="fadeIn"
        toggle={accountBubbleVisible}
      >
        <div className="sidebar-inner-bubble px-10 py-8 bg-white rounded-xl text-center shadow-filter">
          <button
            className="absolute right-4 top-4"
            onClick={() => dispatch({ type: "HIDE_ACCOUNT_BUBBLE" })}
          >
            <CloseIcon className="text-primary" width={12} height={12} />
          </button>
          <div className="inline-block relative mx-auto">
            <Hexagon
              className="text-primary"
              width={80}
              height={88}
              imagePattern={DefaultAvatar}
              id="inner-bubble"
            />
          </div>
          <h4 className="mt-3 text-md font-bold text-primary leading-none">
            {getCurrentUserName()}
          </h4>
          <h6 className="text-sm">{getCurrentUserEmailAddress()}</h6>
          <FormButton
            name="signout"
            type="button"
            text="Sign out"
            className="mt-8 shadow-none"
            variant="secondary"
            onClick={() => {
              dispatch({ type: "HIDE_ACCOUNT_BUBBLE" });
              signOut();
            }}
          />
        </div>
      </Transition>
    </>
  );
};
