"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
exports.theme = {
    theme: "none",
    variables: {
        // uses "proxima-nova" as this is what typekit calls the font family when loading font through css
        fontFamily: '"proxima-nova", sans-serif',
        borderRadius: "4px",
        focusOutline: "none",
    },
    rules: {
        ".Block": {
            backgroundColor: "var(--colorBackground)",
            boxShadow: "none",
        },
        ".Input": {
            padding: "8px",
            paddingTop: "10.5px",
            paddingBottom: "10.5px",
            height: "40px",
            border: "1px solid #AAAAA9",
        },
        ".Input:focus, .p-Input--focused": {
            outline: "none!important",
        },
        ".Input:disabled, .Input--invalid:disabled": {
            color: "#D5D5D4",
        },
        ".Tab": {
            border: "none",
        },
        ".Tab:hover": {
            border: "none",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
            border: "none",
            backgroundColor: "#fff",
            boxShadow: "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Label": {
            fontWeight: "600",
            color: "white",
        },
    },
};
