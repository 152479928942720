import { useContext } from "react";
import { IBasketContextState, basketContext } from "../contexts/basketContext";

/**
 * useBasketContext hook.
 */
export const useBasketContext = (): IBasketContextState =>
  useContext(basketContext);

/**
 * Unused and untested hooks... Work in progress...
 */
//  import { useQuery } from "react-query"
//  import { updateBasket, createBasket } from "../api"
//  import { BasketData, IBasket } from "../api/basket"
/**
 * Below is an experimental hook that needs to be tested before we can safely deploy.
 */
//  interface IUsePatchBasketOptions {
//   enabled?: boolean
//   retry?: boolean
// }

// const defaultUsePatchOptions = {
//   enabled: false,
//   retry: false,
// }
//  export const usePatchBasket = async (
//   basket: BasketData,
//   options: IUsePatchBasketOptions
// ) => {
//   const { basketToken } = useBasketContext()

//   const { isLoading, isError, refetch, data } = useQuery(
//     ["updateBasket", [basket, basketToken]],
//     () => updateBasket(basketToken, basket),
//     {
//       ...defaultUsePatchOptions,
//       ...options,
//     }
//   )

//   return {
//     isLoading,
//     isError,
//     response: data,
//     fetch: refetch,
//   }
// }

// export const useCreateBasket = async (basket: IBasket) => {
//   const { setBasketOrderRef, setBasketToken } = useBasketContext()

//   const createNewBasket = async () => {
//     const newBasket = await createBasket(basket)
//     setBasketToken(newBasket.basketToken)
//     setBasketOrderRef(newBasket.orderRef)
//   }

//   const { isLoading, isError, refetch } = useQuery(
//     ["createBasket", basket],
//     () => createNewBasket(),
//     {
//       enabled: false,
//       retry: false,
//     }
//   )

//   return {
//     isLoading,
//     isError,
//     fetch: refetch,
//   }
// }
