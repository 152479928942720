"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.app = void 0;
exports.app = {
    buttons: {
        openModal: "Open1",
    },
    fourOFour: {
        heading: "oops!",
        text: "We can't seem to find the page you're looking for.",
        buttons: {
            backLoggedIn: "Back to dashboard",
            backLoggedOut: "Back to login",
        },
    },
    accountSuspended: {
        heading: "Account suspended",
        message: "Your account is currently unavailable, please contact Clearabee to resolve any issues with your account.",
        contactUs: "Contact us",
    },
};
