import React from "react";
import cx from "classnames";
import { IFile, TFileList } from "../fileUpload/types";

/**
 * Import images.
 */
import { Trash } from "../../../images";

/**
 * Import components.
 */
import { FileUpload, Trail } from "..";

/**
 * Interface.
 */
interface IImageUpload {
  allowedExtensions?: string[];
  buttonClassName?: string;
  buttonText: string;
  onChange?: (files: any) => void;
  singleImage?: boolean;
  maxImages?: number;
  loading?: boolean;
  disabled?: boolean;
  displayInRows?: boolean;
  imagesFirst?: boolean;
  defaultValues?: TFileList;
  clearFileList?: boolean;
}

/**
 * Image upload and list component.
 */
export const ImageUpload: React.FC<IImageUpload> = ({
  buttonClassName,
  buttonText,
  onChange,
  singleImage = false,
  maxImages = 5,
  loading,
  disabled,
  displayInRows = false,
  imagesFirst = false,
  defaultValues,
  clearFileList,
}) => {
  const classes = cx(
    "btn btn-primary hover:bg-primary hover:text-white w-full",
    buttonClassName,
  );

  return (
    <FileUpload
      defaultValues={defaultValues}
      mode={singleImage ? "single" : "multiple"}
      onChange={onChange}
      maxFiles={maxImages}
      isDisabled={disabled}
      isLoading={loading}
      clearFileList={clearFileList}
    >
      {({ fileList, uploadFile, error, isLoading, isDisabled }) => {
        const images: React.ReactElement[] = [];

        fileList.forEach((file: IFile) => {
          const component = (
            <React.Fragment key={file.key}>
              <div
                className="relative bg-cover bg-center w-12 h-12 rounded-full border-4 border-white -mr-3"
                style={{ backgroundImage: `url(${file.dataUrl})` }}
              >
                <button
                  type="button"
                  className="absolute -right-1 -top-1 text-gray-900 rounded-full bg-white flex items-center justify-center w-5 h-5"
                  onClick={file.removeFile}
                >
                  <Trash stroke="currentColor" className="relative" />
                </button>
              </div>
            </React.Fragment>
          );

          images.push(component);
        });

        const flexClasses = cx("flex", {
          "flex-col md:flex-row md:items-center": !displayInRows,
          "flex-col": displayInRows,
        });

        const imageListClasses = cx("image-upload-list h-12 mt-3 w-full flex", {
          "md:w-1/2 flex-row-reverse justify-end md:mt-0": !displayInRows,
          "mt-0 mb-3": imagesFirst,
        });

        return (
          <div className="image-upload">
            <div className={flexClasses}>
              {imagesFirst && (
                <div className={imageListClasses}>
                  <Trail
                    components={images}
                    trailConfig={{
                      from: {
                        opacity: 0,
                        xyz: [0, -20, 0],
                      },
                    }}
                  />
                </div>
              )}
              <div className="w-full md:w-auto mr-3">
                <button
                  type="button"
                  className={classes}
                  onClick={uploadFile}
                  disabled={isLoading || isDisabled}
                >
                  {buttonText}
                </button>
              </div>
              {!imagesFirst && (
                <div className={imageListClasses}>
                  <Trail
                    components={images}
                    trailConfig={{
                      from: {
                        opacity: 0,
                        xyz: [0, -20, 0],
                      },
                    }}
                  />
                </div>
              )}
            </div>
            {error && (
              <div className="py-2 mt-3 font-semibold text-xs text-red">
                {error}
              </div>
            )}
          </div>
        );
      }}
    </FileUpload>
  );
};
