/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const JobStatus: React.FC<TGenericImage> = ({
  className = "job-status",
  fill = "currentColor",
  stroke = "currentColor",
  width = 14,
  height = 14,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="7" cy="7" r="4" fill={fill} />
        <circle cx="7" cy="7" r="6.5" stroke={stroke} />
      </g>
    </svg>
  );
};
