import React from "react";
import { useTrail, animated } from "react-spring";
import { easeQuad } from "d3-ease";
import cx from "classnames";

/**
 * Default transition config.
 */
const defaultTrail = {
  config: {
    mass: 5,
    tension: 2000,
    friction: 200,
    delay: 500,
    easing: easeQuad,
  },
  opacity: 1,
  xyz: [0, 0, 0],
  delay: 100,
  from: {
    opacity: 0,
    xyz: [-400, 0, 0],
  },
};

/**
 * Types.
 */
interface ITrail {
  components?: any[];
  trailConfig?: any;
  animatedElemClass?: string;
  wrapperElement?: keyof JSX.IntrinsicElements;
  toggle?: boolean;
  trailClasses?: string[];
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Trail: React.FC<ITrail> = ({
  components = [],
  trailConfig = {},
  animatedElemClass,
  toggle = false,
  wrapperElement = "div",
  trailClasses = [],
}) => {
  let settings = { ...defaultTrail, ...trailConfig };
  settings.config = { ...defaultTrail.config, ...trailConfig.config };
  settings = !toggle ? settings : { ...settings, ...settings.from };

  const trail = useTrail(components.length, settings);

  const WrapperComponent = animated[wrapperElement];

  if (components.length > 0) {
    return (
      <>
        {!toggle
          ? trail.map(({ xyz, ...rest }: any, index: number) => {
              return (
                <WrapperComponent
                  key={index}
                  style={{
                    ...rest,
                    transform: xyz.interpolate(
                      (x: number, y: number, z: any) =>
                        `translate3d(${x}px,${y}px,${z})`,
                    ),
                  }}
                  className={cx(animatedElemClass, trailClasses[index])}
                >
                  {components[index]}
                </WrapperComponent>
              );
            })
          : trail
              .slice(0)
              .reverse()
              .map(({ xyz, ...rest }: any, index: number) => {
                return (
                  <WrapperComponent
                    key={index}
                    style={{
                      ...rest,
                      transform: xyz.interpolate(
                        (x: number, y: number, z: any) =>
                          `translate3d(${x}px,${y}px,${z})`,
                      ),
                    }}
                    className={animatedElemClass}
                  >
                    {components[index]}
                  </WrapperComponent>
                );
              })}
      </>
    );
  }

  return null;
};
