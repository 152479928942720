import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps, CustomInputProps } from "../../../../utils";
import { styles } from "./InputTextarea.styles";

type TextareaDetailedProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export interface InputTextareaProps
  extends CustomInputProps,
    UserStylesProps<TextareaDetailedProps> {
  slim?: boolean;
  autoGrow?: boolean;
  autoGrowInitialHeight?: number;
}

export const InputTextarea = React.forwardRef<
  HTMLTextAreaElement,
  InputTextareaProps
>(
  (
    {
      slim = false,
      autoGrow = false,
      autoGrowInitialHeight,
      error,
      success,
      styles: userStyles,
      ...rest
    }: InputTextareaProps,
    ref,
  ) => {
    if (autoGrow) {
      return (
        <div
          css={css(
            styles.autoGrowDiv,
            userStyles,
            autoGrowInitialHeight && {
              minHeight: `${autoGrowInitialHeight}px`,
            },
          )}
        >
          {rest.value}
          <textarea
            css={css(
              styles.input,
              success && styles.success,
              error && styles.error,
              styles.autoGrowTextarea,
            )}
            rows={slim ? 3 : 9}
            {...rest}
            ref={ref}
          />
        </div>
      );
    }

    return (
      <textarea
        css={css(
          styles.input,
          success && styles.success,
          error && styles.error,
          userStyles,
        )}
        rows={slim ? 3 : 9}
        {...rest}
        ref={ref}
      />
    );
  },
);
