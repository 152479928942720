import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import * as Yup from "yup";
import { FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import {
  ICompany,
  IJobPatchBody,
  IJobMeta,
  IJobsPool,
} from "@clearabee/api-schemas";
import { toasts } from "../../../../helpers";
import {
  Text,
  Heading,
  Button,
  Panel,
  theme,
  Box,
  Field,
  Form,
  Input,
  Icon,
  Message,
} from "@clearabee/ui-library";
import { LoadingOverlay } from "../../../common/components";

interface SubcontractorPanelProps {
  jobId: string | number;
  jobPostcode: string;
  jobMeta: IJobMeta | undefined;
  jobPools: IJobsPool[] | undefined;
  refetchJob: () => void;
  jobIsRefetching: boolean;
}

const validationSchema = Yup.object().shape({
  companies: Yup.array().of(Yup.string()),
  invoiceAmount: Yup.string()
    .required("Required")
    .matches(/^\d*\.?\d*$/, "Must be a number"),
});

export const SubcontractorPanel = ({
  jobPostcode,
  jobId,
  jobMeta,
  jobPools,
  refetchJob,
  jobIsRefetching,
}: SubcontractorPanelProps): React.ReactElement => {
  const [t] = useTranslation("jobs");
  const [options, setOptions] = useState<
    { label: string; value: string }[] | undefined
  >();

  const companyCodes = jobPools?.map((item) => item.companyCode);

  const {
    isLoading: subcontractorsDataIsLoading,
    isError: subcontractorsDataIsError,
  } = useQuery(
    "getSubcontractors",
    () => {
      return axios.get(
        `${process.env.REACT_APP_MS_API_BASE_URL}/companies/postcode/${jobPostcode}`,
      );
    },
    {
      cacheTime: 0,
      onSuccess: (data) => {
        setOptions(
          data.data.map((item: ICompany) => ({
            label: item.name,
            value: item.companyCode,
          })),
        );
        setFilteredOptions(
          data.data.map((item: ICompany) => item.name.toLocaleLowerCase()),
        );
      },
    },
  );

  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);

  const initialValues = {
    companies: jobPools?.map((item) => item.companyCode),
    invoiceAmount: jobMeta?.subcontractorInvoiceAmount || "",
  };

  const { mutate: patchJob, isLoading: patchJobIsLoading } = useMutation(
    (body: { codes: IJobPatchBody["subcontractorCodes"]; meta: IJobMeta }) => {
      return instance.jobs.patchJob(String(jobId), {
        subcontractorCodes: body.codes || [],
        meta: {
          ...jobMeta,
          ...body.meta,
        },
      });
    },
    {
      onSuccess: () => {
        toasts.success({
          message: t("update.subcontractorPanel.toasts.patchSuccess"),
        });
        refetchJob();
      },
      onError: () => {
        toasts.error({
          message: t("update.subcontractorPanel.toasts.patchError"),
        });
      },
    },
  );

  return (
    <Panel className="mb-12">
      {(patchJobIsLoading || jobIsRefetching) && <LoadingOverlay />}

      <Form
        initialValues={initialValues}
        onSubmit={(values) => {
          patchJob({
            codes: values.companies?.map((item: string) => item) || [],
            meta: {
              ...jobMeta,
              subcontractorInvoiceAmount: values.invoiceAmount,
            },
          });
        }}
        validationSchema={validationSchema}
        styles={{ width: "100%" }}
      >
        {() => (
          <>
            <Box className="flex flex-row justify-between mb-2">
              <Heading
                color="brand"
                level={5}
                styles={{ marginBottom: theme.spacing.small }}
              >
                {t("update.subcontractorPanel.heading")}
              </Heading>

              {!!options && options.length > 0 && (
                <Button size="small" type="submit">
                  {t("update.subcontractorPanel.button")}
                </Button>
              )}
            </Box>

            {/* LOADING */}
            {subcontractorsDataIsLoading && (
              <Box className="flex w-full justify-center items-center py-16">
                <Icon.Loading color="brand" size="xlarge2" />
              </Box>
            )}

            {/* NO SUBCONTRACTORS */}
            {!!options && options.length === 0 && (
              <Text
                styles={{ padding: `${theme.spacing.xlarge3} 0` }}
                className="font-semibold"
              >
                {t("update.subcontractorPanel.errors.noSubcontractors")}
              </Text>
            )}

            {/* ERROR */}
            {subcontractorsDataIsError && (
              <Message
                styles={{ padding: `${theme.spacing.xlarge3} 0` }}
                type="error"
              >
                {t("update.subcontractorPanel.errors.failedSubcontractors")}
              </Message>
            )}

            {!!options && options.length > 0 && (
              <>
                <Box className="max-w-xl mb-3">
                  <Text fontSize="base">
                    {t("update.subcontractorPanel.description")}
                  </Text>
                </Box>

                {/* INVOICE AMOUNT */}
                <Field
                  label={t("update.subcontractorPanel.form.invoiceAmount")}
                  name="invoiceAmount"
                >
                  {({ field }) => {
                    return (
                      <Box className="w-1/4">
                        <Input.Text {...field} />
                      </Box>
                    );
                  }}
                </Field>

                <Box className="flex flex-row mb-3 gap-3 w-1/2 items-center">
                  {/* SEARCH SUBCONTRACTORS */}
                  <Box className="w-1/2">
                    <Box className="mb-1">
                      <Text className="font-semibold" fontSize="small">
                        {t("update.subcontractorPanel.form.subcontractors")}
                      </Text>
                    </Box>
                    <Input.Text
                      onChange={(e) =>
                        setFilteredOptions(
                          options
                            .filter((item) =>
                              item.label
                                .toLocaleLowerCase()
                                .includes(e.target.value.toLocaleLowerCase()),
                            )
                            .map((item) => item.label.toLocaleLowerCase()),
                        )
                      }
                      placeholder={t(
                        "update.subcontractorPanel.form.subcontractorPlaceholder",
                      )}
                    />
                  </Box>
                </Box>

                {/* SUBCONTRACTORS */}
                <FieldArray
                  name="companies"
                  render={() => (
                    <Box
                      role="group"
                      aria-labelledby="checkbox-group"
                      className="flex flex-row flex-wrap gap-2 md:gap-10"
                    >
                      {options.map((item, index) => (
                        <Box
                          css={{
                            display: filteredOptions.includes(
                              item.label.toLocaleLowerCase(),
                            )
                              ? "block"
                              : "none",
                            width: "fit-content",
                          }}
                          className="flex w-full md:w-1/3 xl:w-1/5"
                          key={index}
                        >
                          <Field name="companies">
                            {({ field }) => {
                              return (
                                <Input.Checkbox
                                  {...field}
                                  label={item.label}
                                  value={item.value}
                                  defaultChecked={companyCodes?.includes(
                                    item.value,
                                  )}
                                  id={item.value}
                                />
                              );
                            }}
                          </Field>
                        </Box>
                      ))}
                    </Box>
                  )}
                />
              </>
            )}
          </>
        )}
      </Form>
    </Panel>
  );
};
