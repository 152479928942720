import React from "react";
import { useTranslation } from "react-i18next";
import {
  usePagination,
  Table,
  Button,
  theme,
  Box,
} from "@clearabee/ui-library";
import { ActiveToggle } from "./activeToggle";
import { FormLoader } from "../../../common/components";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { RouteLink } from "components/core";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";

interface ResultsProps {
  data: ApiResponseData<typeof instance.catalogues.getCatalogues>["items"];
  isLoading: boolean;
  error: unknown;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Results = ({
  data,
  isLoading,
  error,
}: ResultsProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");

  const { PaginationComponent, paginatedData } = usePagination(
    data,
    // options
    undefined,
    // paginationStyles
    paginationStyles,
  );

  return (
    <>
      <Box className="relative overflow-x-scroll">
        <Table
          styles={{
            marginBottom: theme.spacing.medium,
            "th:first-of-type > p, td:first-of-type > span": {
              marginLeft: theme.spacing.small,
            },
          }}
        >
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <Table.Header
            headings={[
              translate("table.headings.title"),
              translate("table.headings.description"),
              translate("table.headings.status"),
              translate("table.headings.action"),
            ]}
          />
          <Table.Body>
            {paginatedData.map((item) => (
              <Table.Row
                key={`table-row-${item.title}`}
                data-testid="catalogue-result"
              >
                <Table.Cell.Text
                  className="truncate"
                  cellProps={{ styles: { width: "10%" } }}
                >
                  {item.title}
                </Table.Cell.Text>
                <Table.Cell.Text
                  className="truncate"
                  cellProps={{ styles: { width: "30%" } }}
                >
                  {item.description}
                </Table.Cell.Text>
                <Table.Cell>
                  <ActiveToggle id={item.id} initActive={item.active} />
                </Table.Cell>
                <Table.Cell>
                  <RouteLink href={`/catalogues/update/${item.id}`}>
                    <Button
                      size="small"
                      as="a"
                      className="inline-block text-center"
                    >
                      {translate("table.actions.edit")}
                    </Button>
                  </RouteLink>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Box>

      {!error && isLoading && (
        <Box styles={{ position: "relative", height: theme.screens.xsmall }}>
          <FormLoader isLoading />
        </Box>
      )}
      <PaginationComponent />
    </>
  );
};
