import { theme } from "../../../../theme";
import { StyleSheet } from "../../../../utils";

export type AllowedColorKeys = keyof typeof theme.colors;

export const toggleColors: AllowedColorKeys[] = [
  "brand",
  "accent",
  "positive",
  "greyscale",
  "usp",
  "warning",
  "light",
  "negative",
  "dark",
];

export const styles = StyleSheet({
  disabled: {
    backgroundColor: theme.colors.greyscale.darker,
    border: theme.colors.greyscale.lighter,
  },
  label: {
    paddingRight: theme.spacing.xsmall,
    text: theme.fontDefaults.small,
    fontWeight: "bold",
  },
  input: {
    opacity: 0,
    width: 0,
    height: 0,
    margin: 0,
    visibility: "hidden",
    colors: {
      ...Object.assign(
        {},
        ...toggleColors.map((accessor) => {
          return {
            [accessor]: {
              ":checked + .toggle-slider": {
                backgroundColor: theme.colors[accessor].base,
                border: `1px solid ${theme.colors[accessor].base}`,
              },
            },
          };
        }),
      ),
      light: {
        ":checked + .toggle-slider": {
          backgroundColor: theme.colors.light.base,
          border: `1px solid ${theme.colors.greyscale.lighter}`,
          "&:before": {
            backgroundColor: theme.colors.dark.base,
          },
        },
      },
    },
    ":checked + .toggle-slider": {
      "&:before": {
        transform: "translateX(14px)",
      },
    },
    ":not(checked) + .toggle-slider": {
      backgroundColor: theme.colors.greyscale.lighter,
    },
  },
  toggle: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  toggleSlide: {
    display: "inline-block",
    position: "relative",
    width: theme.spacing.xlarge,
    height: theme.spacing.medium,
    borderRadius: 45,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: -1,
      margin: 3,
      height: 18,
      width: 18,
      borderRadius: "50%",
      backgroundColor: theme.colors.light.base,
      transition: "0.2s ease",
    },
  },
});
