import React from "react";
import { FormikErrors } from "formik";
import cx from "classnames";
import { SpringTransition } from "..";

/**
 * Type props for field error.
 */
interface IError {
  message:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  classes?: string;
  containerClasses?: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const FieldError: React.FC<IError> = ({
  message,
  classes = "field-error",
}) => {
  /**
   * An object is created with all of the set values.
   */
  const error = {
    message: typeof message !== "undefined" ? message : "",
    classes,
  };

  error.classes = cx(error.classes, "field-error", {
    "field-error-overflow": error.message.length && error.message.length > 27,
  });

  const parsedError = () => {
    if (typeof error.message === "object") return Object.values(error.message);

    return error.message;
  };

  /**
   * Output the error message.
   */
  return (
    <SpringTransition className={error.classes} component="p" duration={100}>
      {parsedError()}
    </SpringTransition>
  );
};
