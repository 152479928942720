import React, { useEffect, useState } from "react";
import {
  Text,
  Heading,
  Button,
  Table,
  Panel,
  Form,
  Input,
  Field,
  theme,
  useModal,
  Loader,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { User } from "@clearabee/api";
import { toasts } from "helpers";
import { useInstance } from "api/dsa";
import { search } from "helpers/search";
import { users as usersInstance } from "api/dsa/resources/users";

type TemporaryPasswordData = Parameters<
  ReturnType<typeof usersInstance>["setTemporaryPassword"]
>[0];

// Replace me with schema...
export const userData = {
  username: "12345-12345-121345-12345",
  email: "warren.bickley@clearabee.co.uk",
  "custom:wc_license": "12345678",
  "custom:phone": "07827019317",
};

export type UserData = typeof userData;

export const data = [userData];

export const ReadSubContractors = (): JSX.Element => {
  const [filteredContractors, setFilteredContractors] = useState<User[]>();

  const api = useInstance();
  const { push } = useHistory();
  const [translate] = useTranslation("users");
  const [SuccessModal, showSuccessModal] = useModal();
  const [LoadingModal] = useModal({ defaultVisible: true });

  const {
    data: result,
    refetch: refetchSubcontractors,
    isFetching,
  } = useQuery("usersList", () => api.users.list("parentsOnly=true"));

  // Refetch list of subcontractors if the data has not been renewed. This is
  // so that the list is refreshed after creating a new subcontractor
  useEffect(() => {
    if (isFetching === false) {
      refetchSubcontractors();
    }
  }, []);

  const { mutate: setTemporaryPassword, isLoading: isResettingPassword } =
    useMutation(
      (user: TemporaryPasswordData) => api.users.setTemporaryPassword(user),
      {
        onSuccess: () => showSuccessModal(true),
        onError: (error) => {
          toasts.error({ message: (error as Error).message });
        },
      },
    );

  const isLoading = isResettingPassword;

  const searchContractors = (value: string) => {
    const contractors = result?.data;

    //local variable is required, or it will search filtered results and not all results
    // If searching before subcontractors have loaded, it will throw
    if (!contractors) return;

    const keysToSearch: string[] = ["email", "company"];

    const filteredContractors = search(contractors, value, keysToSearch);
    setFilteredContractors(filteredContractors);
  };

  return (
    <div className="py-5">
      <Panel className="mb-12">
        <div className="flex justify-between border-b border-grey-200 pb-8 mb-6">
          <Heading color="brand" level={4}>
            {translate("subcontractors.headings.subcontractors")}
          </Heading>
          <Link to="/subcontractors/create">
            <Button
              color="accent"
              size="medium"
              as="a"
              href="/subcontractors/create"
              styles={{ textAlign: "center" }}
            >
              {translate("subcontractors.createNew")}
            </Button>
          </Link>
        </div>
        <Form
          initialValues={{ searchValue: "" }}
          onSubmit={(values) => searchContractors(values.searchValue)}
        >
          {({ values }) => (
            <div className="flex flex-row justify-between gap-4">
              <div className="w-2/3">
                <Field
                  name="searchValue"
                  styles={{
                    margin: 0,
                  }}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder="Search by company name or email..."
                    />
                  )}
                </Field>
              </div>

              <div className="flex flex-row gap-4">
                <Button
                  type="submit"
                  disabled={!values.searchValue}
                  size="small"
                  color="accent"
                >
                  {translate("common:form.buttons.search")}
                </Button>

                <Button
                  type="reset"
                  disabled={!values.searchValue}
                  size="small"
                  color="negative"
                  onClick={() => searchContractors("")}
                >
                  {translate("common:form.buttons.reset")}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </Panel>
      <Table
        styles={{
          marginBottom: theme.spacing.large,
        }}
      >
        <Table.Header
          headings={[
            "Company Name",
            "Email",
            "Waste Carrier Licence",
            "Phone",
            "Status",
            "Actions",
          ]}
        />

        <Table.Body>
          {(filteredContractors || result?.data || []).map((contractor) => (
            <Table.Row key={`table-row-${contractor.email}`}>
              <Table.Cell.Text>{contractor.company}</Table.Cell.Text>
              <Table.Cell.Text>{contractor.email}</Table.Cell.Text>
              <Table.Cell.Text>{contractor.wc_license}</Table.Cell.Text>
              <Table.Cell.Text>{contractor.phone}</Table.Cell.Text>
              <Table.Cell.Text>{contractor.status}</Table.Cell.Text>
              <Table.Cell styles={{ display: "flex" }}>
                <Button
                  as="button"
                  size="small"
                  color="brand"
                  className="mr-2"
                  onClick={() =>
                    setTemporaryPassword({ username: contractor.username })
                  }
                >
                  {translate("subcontractors.resetPassword")}
                </Button>
                <Button
                  as="button"
                  size="small"
                  color="warning"
                  onClick={() => {
                    push(`/subcontractors/${contractor.username}/children`);
                  }}
                >
                  {translate("subcontractors.manage")}
                </Button>
                <Button
                  as="button"
                  className="ml-2"
                  size="small"
                  color="warning"
                  onClick={() => {
                    push(`/subcontractors/${contractor.username}/update`);
                  }}
                >
                  {translate("subcontractors.edit")}
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <SuccessModal width={500} onClose={() => showSuccessModal(false)}>
        <Heading level={3} className="pb-2">
          Success!
        </Heading>
        <Text>The requested action has been completed successfully.</Text>
      </SuccessModal>

      {isLoading && (
        <LoadingModal styles={{ "&:after": { display: "none" } }}>
          <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
            <Loader color="light" text={translate("common:loading2")} />
          </div>
        </LoadingModal>
      )}
    </div>
  );
};
