"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsive = void 0;
var useResponsive = function (width, screenSizes) {
    var screens = Object.entries(screenSizes).reduce(function (final, _a) {
        var _b;
        var screenSize = _a[0], breakpointWidth = _a[1];
        return __assign(__assign({}, final), (_b = {}, _b[screenSize] = width >= breakpointWidth, _b));
    }, {});
    var select = function (options) {
        var maxBreakpoint = Object.keys(options).reduce(function (_a, key) {
            var size = _a.size, breakpoint = _a.breakpoint;
            if (key !== "base" && screens[key] && screenSizes[key] >= size) {
                breakpoint = key;
                size = screenSizes[key];
            }
            return { size: size, breakpoint: breakpoint };
        }, { size: 0, breakpoint: "base" });
        return options[maxBreakpoint.breakpoint];
    };
    return {
        screens: screens,
        select: select,
    };
};
exports.useResponsive = useResponsive;
