import React, { useContext } from "react";
import { Heading, Table, theme, Icon } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { CatalogueEditorContext } from "./catalogueEditor";

export const AssignedCompanies = (): React.ReactElement => {
  const [translate] = useTranslation("companies");
  const { catalogue, isLoading } = useContext(CatalogueEditorContext);

  if (isLoading)
    return (
      <Icon.Loading
        color="brand"
        styles={{ margin: `${theme.spacing.xlarge} auto` }}
      />
    );

  return (
    <div style={{ padding: theme.spacing.medium }}>
      <Heading
        level={5}
        color="brand"
        styles={{ marginBottom: theme.spacing.xlarge }}
      >
        {translate("common.assignedCompanies")}
      </Heading>
      <Table
        styles={{
          marginBottom: theme.spacing.large,
          "th:first-of-type > p, td:first-of-type > span": {
            marginLeft: theme.spacing.small,
          },
        }}
      >
        <colgroup>
          <col style={{ width: "5%" }} />
          <col style={{ width: "35%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "30%" }} />
        </colgroup>
        <Table.Header
          headings={[
            "",
            translate("table.headings.companyName"),
            translate("table.headings.companyCode"),
            translate("table.headings.active"),
          ]}
          fontSize="small"
        />
        <Table.Body>
          {catalogue?.companies?.map((company, index: number) => (
            <Table.Row
              key={company.id}
              styles={{
                borderBottom: `1px solid ${theme.colors.greyscale.lighter}`,
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <Table.Cell.Text styles={{ marginLeft: theme.spacing.xsmall }}>
                {index + 1}
              </Table.Cell.Text>
              <Table.Cell.Text>{company.name}</Table.Cell.Text>
              <Table.Cell.Text>{company.code}</Table.Cell.Text>
              <Table.Cell.Text>
                {company.active
                  ? translate("form.buttons.labels.yes")
                  : translate("form.buttons.labels.no")}
              </Table.Cell.Text>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
