import React, { useRef, useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  useFormikContext,
  FormikProps,
  FormikFormProps,
  FormikValues,
} from "formik";
import { useTranslation } from "react-i18next";

/**
 * Import helpers.
 */
import { propertiesHaveValues } from "../../../helpers/api";

/**
 * Import core components.
 */
import { FormSelect } from "..";

/**
 * Import API functions.
 */
import { readAddresses } from "../../../api";

/**
 * Set interface for component.
 */
interface IAddressDropdown {
  postcode: string;
  menuPlacement?: "top" | "bottom";
  addressDropdown?: {
    containerClasses?: string;
    fieldClasses?: string;
    disabledOnInit?: boolean;
    fieldName?: string;
  };
  updateAddress: (value: unknown) => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

/**
 * Set default props for each field.
 */
const addressDefaults = {
  containerClasses: "",
  fieldClasses: "larger-dropdown drop-left",
  disabledOnInit: true,
  fieldName: "addressChoices",
};

/**
 * Address dropdown component
 * - We might look into merging this into address lookup component and use props to customise
 */
export const AddressDropdown: React.FC<IAddressDropdown> = ({
  postcode,
  menuPlacement = "top",
  addressDropdown = {
    ...addressDefaults,
  },
  updateAddress,
  disabled: overrideDisabled = false,
  hideLabel = false,
}) => {
  const [t] = useTranslation("common");

  /**
   * The final address, which will be saved via 'updateAddress'.
   */
  const savedAddress = useRef({});
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
  });

  /**
   * Get Formik context.
   */
  const { setFieldValue }: FormikProps<FormikFormProps> = useFormikContext();

  const { values, errors }: FormikProps<FormikValues> = useFormikContext();

  const choicesInput = addressDropdown.fieldName || addressDefaults.fieldName;
  const hasValue = values[choicesInput]
    ? values[choicesInput].hasOwnProperty("value")
    : false;
  const hasError = errors[postcode];

  /**
   * Check if address has changed, if so, fire updateAddress prop function.
   */
  useEffect(() => {
    if (propertiesHaveValues(address) && savedAddress.current !== address) {
      updateAddress(address);
      savedAddress.current = address;
    }
  }, [address, updateAddress]);

  /**
   * Get address options based on postcode.
   */
  const initialData: any = [];

  const { isLoading, data: addressData } = useQuery(
    ["getAddressByPostcode", postcode],
    () => readAddresses(postcode),
    {
      enabled: postcode !== "" && !hasError,
    },
  );

  const choices = addressData?.addresses || initialData;

  /**
   * Data for dropdown.
   */
  const hasChoices = choices.length > 0;

  return (
    <FormSelect
      name={choicesInput}
      placeholder={
        !hasChoices
          ? t("form.placeholders.addressChoicesDisabled")
          : t("form.placeholders.addressChoices")
      }
      alwaysSetValue={false}
      classes={addressDropdown.fieldClasses}
      floatingLabel={false}
      options={choices.map((choice: any, index: number) => {
        const {
          line_1: line1,
          line_2: line2,
          town_or_city: city,
          county,
          district,
        } = choice;

        return {
          label: line2
            ? `${line1}, ${line2}, ${city}, ${county}`
            : `${line1}, ${city}, ${county || district}`,
          value: index + 1,
        };
      })}
      loading={isLoading}
      disabled={
        overrideDisabled ||
        (addressDropdown.disabledOnInit && !hasChoices && !hasValue) ||
        (!hasChoices && !hasValue)
      }
      menuPlacement={menuPlacement}
      label={
        !hideLabel
          ? {
              text: t("form.labels.addressChoices"),
            }
          : undefined
      }
      searchable={true}
      onChange={(option: any) => {
        if (option !== null) {
          setFieldValue(choicesInput, option);
        } else {
          setFieldValue(choicesInput, "");
        }
        const addressId = parseInt(option.value, 10) - 1;
        const { district, county: state } = choices[addressId];
        const newAddress = {
          line1: choices[addressId].line_1,
          line2: choices[addressId].line_2,
          city: choices[addressId].town_or_city,
          county: state === "" ? district : state,
          postcode: postcode.toUpperCase(),
          lat: addressData?.latitude || null, // When searching for a postcode using getaddress.io, it returns the latitude and longitude for the postcode, and not specifically for addresses within that postcode.
          lng: addressData?.longitude || null,
        };

        setAddress(newAddress);
      }}
    />
  );
};
