import React from "react";
import { Text } from "../../Text/Text";
import { styles } from "./Field.styles";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../../utils";

export interface FieldLabelProps {
  children: React.ReactNode;
  label?: string;
  renderLabel?: boolean;
  required?: boolean;
}

export const FieldLabel = ({
  children,
  label,
  required,
  renderLabel = true,
  styles: userStyles,
}: UserStylesProps<FieldLabelProps>): React.ReactElement => {
  return (
    <Text
      as={renderLabel ? "label" : "span"}
      css={css(styles.field, userStyles)}
    >
      {label && (
        <span css={css(styles.label)}>
          {label}
          {required && "*"}
        </span>
      )}
      {children}
    </Text>
  );
};
