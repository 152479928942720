import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "@clearabee/ui-library";
import { styles } from "./BoxButton.styles";

type ButtonDetailedProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export interface BoxButtonProps extends UserStylesProps<ButtonDetailedProps> {
  children?: React.ReactNode;
}

export const BoxButton = React.forwardRef<HTMLButtonElement, BoxButtonProps>(
  ({ children, styles: userStyles, ...rest }, ref): React.ReactElement => {
    return (
      <button
        ref={ref}
        css={css(
          styles.default,
          userStyles,
          rest.disabled && {
            cursor: "not-allowed",
          },
        )}
        type="button"
        {...rest}
      >
        {children}
      </button>
    );
  },
);
