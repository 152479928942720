import { AxiosInstance, AxiosResponse } from "axios";
import { User } from "@clearabee/api";

export type UsersInstance = {
  list: (query?: string) => Promise<AxiosResponse<User[]>>;
  listChildren: (username: string) => Promise<AxiosResponse<User[]>>;
  getUser: (username: string) => Promise<AxiosResponse<User>>;
  update: (
    username: string,
    values: Partial<User>,
  ) => Promise<AxiosResponse<User>>;
  add: (
    user: Omit<User, "username" | "status"> & {
      password: string;
    },
  ) => Promise<AxiosResponse<User>>;
  delete: (username: string) => Promise<AxiosResponse>;
  setTemporaryPassword: (
    user: Pick<User, "username">,
  ) => Promise<AxiosResponse>;
};

export const users = (instance: AxiosInstance): UsersInstance => ({
  list: (query = "") => instance.get(`users?${query}`),
  listChildren: (username) => instance.get(`users/${username}/children`),
  getUser: (username) => instance.get(`users/${username}`),
  update: (username, values) => instance.patch(`users/${username}`, values),
  add: (values) => instance.post(`users`, values),
  delete: (username) => instance.delete(`users/${username}`),
  setTemporaryPassword: (values) =>
    instance.post("users/password/temporary", values),
});
