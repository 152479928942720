import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import images.
 */
import { ReactComponent as DottedLine } from "../../../auth/images/auth-background-line-top.svg";
import { ReactComponent as London } from "../../../../images/weee-kerbside.svg";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const PanelRight: React.FC = () => {
  const [t] = useTranslation("jobs");

  return (
    <div className="fixed right-0 top-0 w-1/2 h-screen z-0 auth-shapes auth-shapes--top">
      <div className="transform scale-75 xl:scale-100">
        <DottedLine className="fixed top-0 auth-shapes__line auth-shapes__line--top" />
      </div>
      <div className="fixed w-2/3 2xl:w-full h-screen bg-no-repeat login-background flex flex-col justify-top items-center max-w-6xl">
        <div className="lg:w-full xl:w-3/4 hidden lg:block">
          <div className="px-12 xl:px-2">
            <p className="mt-1 md:mt-48 3xl:mt-64 3xl:pt-64 text-3xl xl:text-5xl text-right text-white leading-extra-tight auth-slogan 3xl:text-6xl 3xl:text-left">
              <span className="font-bold text-secondary">
                {t("weeeKerbside.strapline.first")}
              </span>{" "}
              {t("weeeKerbside.strapline.second")}{" "}
              <span className="font-bold text-secondary">
                {t("weeeKerbside.strapline.third")}
              </span>{" "}
              {t("weeeKerbside.strapline.fourth")}
            </p>
          </div>

          <div className="weee-kerbside-london absolute right-0 bottom-0">
            <London className="w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};
