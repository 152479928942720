/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const LeftChevron: React.FC<TGenericImage> = ({
  className = "left-chevron-icon",
  fill = "currentColor",
  width = 9,
  height = 15,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 9 15"
    >
      <path
        fill={fill}
        d="M0.936559944,14.8274391 C0.717279957,14.8274391 0.49711997,14.7439191 0.32911998,14.5755191 C-0.00639999962,14.2399992 -0.00639999962,13.6970392 0.32911998,13.3615192 L6.27727963,7.41375956 L0.32911998,1.46559991 C-0.00639999962,1.13007993 -0.00639999962,0.586639965 0.32911998,0.251599985 C0.66463996,-0.083919995 1.20807993,-0.083919995 1.54351991,0.251599985 L8.09823952,6.80631959 C8.4337595,7.14183957 8.4337595,7.68527954 8.09823952,8.02031952 L1.54351991,14.5754391 C1.37607992,14.7434391 1.15631993,14.8274391 0.936559944,14.8274391 Z"
        transform="rotate(-180 4.5 7.5)"
      />
    </svg>
  );
};
