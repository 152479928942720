import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { formatCurrency, Icon, Text } from "@clearabee/ui-library";
import { useBasket } from "@clearabee/ui-sdk";

/**
 * Import Components
 */
import { SuccessModal } from "../../../common/components";

/**
 * Import helpers.
 */
import {
  startDialpadRecording,
  stopDialpadRecording,
} from "../../../../helpers";

/**
 * Import hooks
 */
import {
  useAuthContext,
  useCatalogueContext,
  useMultiFormContext,
  useBasketContext,
} from "../../../../hooks";

/**
 * Import api endpoints.
 */
import { createBasketPaymentLink } from "../../../../api";

/**
 * Import types.
 */
import { IVTFormState } from "../types";
import { parseFormDataToBasket } from "../lib/parsers";
import { StripeBillingForm } from "components/common/components/stripeBillingForm";
import { handleException } from "helpers/handleException";
import { CreateBasketPayload } from "api/basket";

/**
 * Payment step component.
 */
export const PaymentConfirmation = (): React.ReactElement => {
  /**
   * State.
   */
  const [basketToken, setBasketToken] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [success, setSuccess] = useState(false);

  /**
   * Basket hook.
   */
  const { items, addItem, clearItems } = useBasketContext();
  const { basket, create, clear } = useBasket();

  /**
   * Catalogue hook.
   */
  const { catalogueId } = useCatalogueContext();

  /**
   * Use auth hook to get user's company id.
   */
  const { getCurrentUserCurrentCompany, getCurrentUserId } = useAuthContext();
  const { companyCode } = getCurrentUserCurrentCompany() ?? {};
  const currentUserId = getCurrentUserId();

  /**
   * Translations.
   */
  const [translate] = useTranslation("jobs");

  /**
   * Multi form hook.
   */
  const { formState, reset } = useMultiFormContext<IVTFormState>();
  const {
    address,
    firstName,
    lastName,
    email,
    phoneNumber,
    timeslotCharge,
    customerNoEmail,
    timeslotPreference,
  } = formState;

  /**
   * If a timeslot charge is added to the basket,
   * then this creates a new product line on the payment page,
   * showing a delivery charge and the cost associated
   */
  useEffect(() => {
    if (!timeslotCharge) {
      return;
    }

    addItem({
      sku: "DELIVERYCHARGE",
      quantity: 1,
      price: timeslotCharge,
      title: `Delivery Charge - ${timeslotPreference}`,
    });
  }, [timeslotCharge]);

  /**
   * Process basket.
   */
  const processBasket = async () => {
    // Create a basket
    let basketData: CreateBasketPayload;
    try {
      basketData = parseFormDataToBasket(
        catalogueId,
        companyCode,
        formState,
        items,
      );
      const basket = await create.mutateAsync(basketData);

      // Store basket info into local state.
      const { basketToken, orderRef } = basket;
      setBasketToken(basketToken);
      setOrderRef(orderRef);
      setClientSecret(basket.clientSecret ?? "");
    } catch (error) {
      handleException(
        error,
        { basketToken },
        { type: "ADHOC Job" },
        "Failed to create the basket. Try again.",
      );
    }
  };

  /**
   * Restart the job creation flow.
   */
  const restartCreateVTJob = () => {
    clear();
    clearItems();
    reset(true);
  };

  /**
   * Create basket as we land on this page.
   * and stop dialpad recording
   */
  useEffect(() => {
    processBasket();
    stopDialpadRecording(currentUserId.toString());
  }, []);

  /**
   * This logs all the stages of the api call of creating a payment link
   * and provides actions at each step. After a successful API call
   * the user will be returned to the dashboard via a modal
   */
  const { mutate, isLoading, isSuccess } = useMutation(
    () => {
      return createBasketPaymentLink(basketToken);
    },
    {
      onError: (error) => {
        handleException(
          error,
          { basketToken },
          { type: "ADHOC Job" },
          "The Payment Link could not be sent. Please try again",
        );
      },
    },
  );
  /**
   * Handle payment success
   * - Set success state to true
   * - Start dialpad recording
   */
  const handlePaymentSuccess = () => {
    setSuccess(true);
    startDialpadRecording(currentUserId.toString());
  };

  return (
    <>
      <div className="mt-6">
        <div className="md:flex px-6 py-8 md:px-10 order-summary mx-auto shadow-filter bg-cover rounded-xl bg-pattern w-full pointer-events-auto md:max-w-screen-md is-visible">
          {!basket ? (
            <Icon.Loading color="accent" className="mx-auto" />
          ) : (
            <>
              <div className="w-full md:pr-5 md:w-1/2">
                <div className="relative flex -mx-4 text-white">
                  <div className="w-auto px-4 pr-16">
                    <h4 className="text-xl font-bold mb-3">
                      {translate("create.orderSummary.titles.details")}
                    </h4>
                    <p className="text-2xs">
                      {translate("create.orderSummary.titles.name")}
                    </p>
                    <p className="leading-none mb-3">{`${firstName} ${lastName}`}</p>
                    <p className="text-2xs">
                      {translate("create.orderSummary.titles.phone")}
                    </p>
                    <p className="leading-none mb-3">{phoneNumber}</p>
                    {!customerNoEmail && (
                      <>
                        <p className="text-2xs">
                          {translate("create.orderSummary.titles.email")}
                        </p>
                        <p className="leading-none mb-3">{email}</p>
                      </>
                    )}
                    <p className="text-2xs">
                      {translate("create.orderSummary.titles.collection")}
                    </p>
                    {address.hasOwnProperty("line1") && (
                      <p className="leading-5">{address.line1}</p>
                    )}
                    {address.hasOwnProperty("line2") && (
                      <p className="leading-5">{address.line2}</p>
                    )}
                    {address.hasOwnProperty("city") && (
                      <p className="leading-5">{address.city}</p>
                    )}
                    {address.hasOwnProperty("county") && (
                      <p className="leading-5">{address.county}</p>
                    )}
                    {address.hasOwnProperty("postcode") && (
                      <p className="leading-5">{address.postcode}</p>
                    )}
                  </div>
                </div>
                <div className="my-8 border-b border-white opacity-25 w-full" />
                <div className="relative text-white">
                  <>
                    <h4 className="text-xl font-bold">
                      {translate("create.orderSummary.titles.order")}
                    </h4>
                    <div className="flex flex-row items-center text-2xs py-3">
                      <div className="order-summary-qty w-10 pr-2">
                        {translate("create.orderSummary.titles.quantity")}
                      </div>
                      <div className="w-full">
                        {translate("create.orderSummary.titles.product")}
                      </div>
                      <div className="order-summary-price text-right">
                        {translate("create.orderSummary.titles.price")}
                      </div>
                    </div>
                  </>
                </div>
                <div className="text-white">
                  {basket?.items?.map((item) => {
                    const { title, qty, lineCost, sku } = item;
                    return (
                      <div
                        key={`basket-${sku}`}
                        className="flex mb-2 text-xs pt-2"
                      >
                        <div className="order-summary-qty w-10 pr-2">
                          {`x${qty}`}
                        </div>
                        <div className="w-full truncate">{title}</div>
                        <div className="order-summary-price text-right">
                          {formatCurrency(lineCost)}
                          <div className="mt-1 opacity-75 text-right">each</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="border-b my-8 md:my-0 border-white opacity-25 w-full visible md:invisible" />
              </div>
              <div className="w-full md:pl-5 md:w-1/2">
                <div className="mb-6">
                  <h4 className="text-xl font-bold text-white mb-4">
                    {translate("create.orderSummary.titles.billingDetails")}
                  </h4>
                  {clientSecret && (
                    <StripeBillingForm
                      contact={{
                        name: firstName,
                        phone: phoneNumber,
                        email: email || undefined,
                      }}
                      clientSecret={clientSecret}
                      amount={basket.totalCost}
                      disableButton={!basketToken || !orderRef}
                      onPaymentSuccess={handlePaymentSuccess}
                    />
                  )}
                </div>
                {!customerNoEmail && (
                  <div className="flex">
                    <button
                      id="sendPaymentLinkButton"
                      className="mx-auto btn-secondary btn-filled btn-medium transition ease-in duration-100"
                      disabled={isLoading || !basketToken}
                      onClick={() => mutate()}
                    >
                      Send Payment Link
                    </button>
                  </div>
                )}
                <div className="border-b border-white opacity-25 w-full mt-5" />
                <div className="flex flex-row justify-between items-center lg:pt-5 lg:mt-5 text-white">
                  <h4 className="text-lg md:text-xl font-bold">
                    {translate("create.orderSummary.titles.total")}
                  </h4>
                  <div className="text-right">
                    <h4 className="text-lg md:text-2xl font-bold leading-extra-tight">
                      <span id="paymentTotal">
                        {formatCurrency(basket?.totalCost || 0)}
                      </span>
                    </h4>
                    <h6 className="text-2xs">
                      {translate("create.orderSummary.vat")}
                    </h6>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="max-w-sm mx-auto px-2 pt-1 pb-4 mt-8 text-justify">
          <p>{translate("create.steps.confirmOrder.terms")}</p>
        </div>
      </div>
      {/* This modal appears after a successful payment completion */}
      <SuccessModal
        title={
          <>
            {translate("modal.headings.createJobSuccess")}
            <br />
            {translate("modal.headings.ref")}
            <Text as="span" fontSize="small" data-testid="createJobSuccessRef">
              {orderRef}
            </Text>
          </>
        }
        buttonOne={{
          label: translate("modal.buttons.labels.backToDashboard"),
          to: "/",
        }}
        buttonTwo={{
          label: translate("modal.buttons.labels.bookAnother"),
          close: true,
        }}
        hideCallback={restartCreateVTJob}
        visible={success}
      />
      {/* This modal appears after successfully sending a payment link */}
      <SuccessModal
        title={
          <>
            {translate("modal.headings.createPaymentLinkSuccess")}
            <br />
            {translate("modal.headings.ref")}
            <Text as="span" fontSize="small" data-testid="createJobSuccessRef">
              {orderRef}
            </Text>
          </>
        }
        buttonOne={{
          label: translate("modal.buttons.labels.backToDashboard"),
          to: "/",
        }}
        buttonTwo={{
          label: translate("modal.buttons.labels.bookAnother"),
          close: true,
        }}
        text={translate("modal.text.customerWillReceiveAnEmail")}
        hideCallback={restartCreateVTJob}
        visible={isSuccess}
      />
    </>
  );
};
