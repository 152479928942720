import React from "react";

/**
 * Import images
 */
import Barnet from "../images/barnet.png";
import Camden from "../images/camden.png";
import Enfield from "../images/enfield.png";
import Islington from "../images/islington.png";
import WalthamForest from "../images/walthamForest.png";

export const BoroughLogos: React.FC = () => {
  return (
    <>
      <p className="font-semibold mb-6">
        Only available to residents in the following boroughs:
      </p>
      <div className="flex items-center flex-wrap">
        <img
          className="my-4 mr-8"
          src={Barnet}
          alt="Barnet borough logo"
          style={{ width: "85px", height: "23px" }}
        />
        <img
          className="my-4 mr-8"
          src={Camden}
          alt="Camden borough logo"
          style={{ width: "85px", height: "17px" }}
        />
        <img
          className="my-4 mr-8"
          src={Enfield}
          alt="Enfield borough logo"
          style={{ width: "75px", height: "37px" }}
        />
        <img
          className="my-4 mr-8"
          src={Islington}
          alt="Islington borough logo"
          style={{ width: "75px", height: "13px" }}
        />
        <img
          className="my-4"
          src={WalthamForest}
          alt="Waltham forest borough logo"
          style={{ width: "60px", height: "34px" }}
        />
      </div>
    </>
  );
};
