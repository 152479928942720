import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export const styles = StyleSheet({
  defaultStyles: {
    padding: theme.spacing.medium,
    paddingRight: 0,
  },
  contentContainer: {
    display: "flex",
    flexWrap: "wrap",
    borderBottom: `2px solid ${theme.colors.greyscale.lighter}`,
    paddingBottom: theme.spacing.xlarge,
    [`@media (min-width: ${theme.screens.medium})`]: {
      marginRight: theme.spacing.xlarge2,
    },
  },
  radios: {
    display: "flex",
    marginTop: theme.spacing.xsmall,
    alignItems: "center",
    alignSelf: "flex-end",
    [`@media (min-width: ${theme.screens.medium})`]: {
      marginLeft: "auto",
    },
  },
  selectAllRadio: {
    marginRight: theme.spacing.medium,
  },
  searchContainer: {
    display: "flex",
    marginTop: theme.spacing.small,
    width: "100%",
    position: "relative",
  },
  searchIcon: {
    position: "absolute",
    right: theme.spacing.medium,
    top: "50%",
    transform: "translate(0,-50%)",
  },
  searchInput: {
    marginRight: theme.spacing.xsmall,
  },
  searchButton: {
    minWidth: 100,
    fontSize: theme.fontDefaults.xsmall.fontSize,
    padding: theme.spacing.xsmall,
  },
  optionsContainer: {
    overflowY: "scroll",
    paddingRight: theme.spacing.xlarge,
    maxHeight: theme.screens.small,
    "::-webkit-scrollbar": {
      width: theme.spacing.xsmall,
    },
    "::-webkit-scrollbar-track": {
      background: theme.colors.greyscale.lightest,
      borderRadius: theme.spacing.xsmall2,
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.colors.brand.base,
      borderRadius: theme.spacing.small,
    },

    "::-webkit-scrollbar-thumb:hover": {
      background: theme.colors.brand.light,
    },
  },
  optionWrap: {
    borderBottom: `1px solid ${theme.colors.greyscale.lighter}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  option: {
    position: "relative",
    paddingTop: theme.spacing.medium,
    paddingBottom: theme.spacing.medium,
  },
});
