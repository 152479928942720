"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subcontractors = void 0;
exports.subcontractors = {
    approve: {
        heading: "Approve Subcontractors",
        description: "Below are subcontractors that have created an account and are pending approval.",
        table: {
            name: "Company Name",
            email: "Contact Email",
            code: "Company Code",
        },
        toasts: {
            success: "Subcontractor approved",
            error: "Something went wrong. Please try again.",
        },
        modal: {
            date: "Date Created",
            code: "Company Code",
            email: "Contact Email",
            phone: "Contact Phone Number",
            address: "Address",
            settings: "Settings",
        },
        buttons: {
            view: "View",
            approve: "Approve",
        },
    },
};
