import React from "react";
import cx from "classnames";

/**
 * Import template.
 */
import { BasicButton } from "./basic";

/**
 * Import hooks.
 */
import { useConfig } from "../../../hooks";

/**
 * Import types.
 */
import { TBasicButton } from "./types";

type TImageButton = TBasicButton & {
  image: any;
  variant?: "primary" | "secondary";
  filled?: boolean;
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ImageButton: React.FC<TImageButton> = ({
  name,
  image,
  type = "button",
  onClick,
  className,
  disabled,
  loading,
  variant = "primary",
  filled = false,
  loader = false,
}) => {
  /**
   * Create button classname.
   */
  const settings = useConfig("button");
  const classes = cx(
    className,
    "btn-image w-12 h-12 border-2 rounded-full flex items-center justify-center",
    settings(
      !filled ? `classes.image.${variant}` : `classes.image.${variant}-filled`,
    ),
  );

  const button = {
    classes,
  };

  /**
   * Output the button.
   */
  return (
    <BasicButton
      name={name}
      className={button.classes}
      type={type}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      loader={loader}
    >
      {image}
    </BasicButton>
  );
};
