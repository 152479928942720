import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { DeleteConfirmationModal } from "../common/components/deleteConfirmationModal";
import { useAuthContext } from "../../hooks";
import { readRoles, userCompanies } from "../../api";
import { toasts, getErrorMessage } from "../../helpers";
import { updateUser as validation } from "./validation";
import {
  parseRolesDataForSelect,
  parseCompaniesDataForSelect,
  OptionForSelect,
} from "./parser";
import { AxiosError } from "axios";
import roles from "constants/roles";
import {
  Button,
  Field,
  Heading,
  Input,
  Panel,
  theme,
  Icon,
  Form,
  useResponsive,
  useModal,
  Text,
  FlexGrid,
} from "@clearabee/ui-library";
import { LeftChevron } from "images";
import {
  ApiRequestData,
  ApiResponseData,
  instance,
  snakeToNormal,
} from "@clearabee/ui-sdk";
import { BeeLoyalTransactions } from "./components/beeLoyalTransactions";
import { UserJobs } from "./components/userJobs";

interface UpdateUserInitialValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  company: string;
  companies: number[];
  roles: OptionForSelect[] | null;
}

export const UpdateUser = (): React.ReactElement => {
  const { doesUserHaveRole, forgotPassword, getCurrentUserCompanies } =
    useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);

  const history = useHistory();

  const { id: email } = useParams<{ id: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [translate] = useTranslation("users");
  const {
    screens: { medium },
  } = useResponsive();
  const [SuccessModal, setSuccessModal] = useModal();
  const { data: rolesData, isLoading: isLoadingRoles } = useQuery(
    "readRoles",
    readRoles,
  );
  const rolesOptions = parseRolesDataForSelect(
    rolesData?.items || [],
    isClearabeeAdmin ? true : false,
    isClearabeeManager ? true : false,
  );
  const rolesChoices = rolesOptions && rolesOptions?.length > 0;

  // get all companies
  const { data: companiesData, isLoading: isLoadingCompanies } = useQuery(
    "userCompanies",
    userCompanies,
  );
  /**
   * Companies data.
   */
  const companiesOptions = companiesData
    ? parseCompaniesDataForSelect(
        isClearabeeAdmin || isClearabeeManager
          ? companiesData
          : getCurrentUserCompanies(),
      )
    : [];
  const companiesChoices = companiesOptions && companiesOptions.length > 0;

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    ["getUser", email],
    async () => (await instance.users.getUser(email)).data,
    {
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: true,
    },
  );
  const { firstName, lastName, companies, phoneNumber } = userData || {};

  /**
   * Get user company
   */
  const userCompany: OptionForSelect[] =
    companies && companies.length > 0
      ? companies.map((item) => ({
          label: item.name,
          value: String(item.id),
        }))
      : [];

  /**
   * Get companies that belong when view user but not to company admin
   */
  const companiesNotBelongToCurrentUser: OptionForSelect[] = !isClearabeeAdmin
    ? userCompany.filter(
        ({ label }) => !JSON.stringify(companiesOptions).includes(label),
      )
    : [];

  const isCompaniesNotBelongExist =
    companiesNotBelongToCurrentUser &&
    !!companiesNotBelongToCurrentUser?.length;

  const checkCompaniesNotBelong = (company: string): boolean => {
    return companiesNotBelongToCurrentUser.some(
      ({ label }) => label === company,
    );
  };

  const { roles: userRoles } = userData || {};

  const existingRoles =
    userRoles && userRoles.length
      ? userRoles
          .map(({ id, name }) => ({ label: name, value: String(id) }))
          /**
           * this will sort existingRoles based on the role value id on database in terms of ascending order
           */
          .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      : [];

  const rolesNotBelongToCurrentUser: OptionForSelect[] = !isClearabeeAdmin
    ? existingRoles.filter(
        ({ label }) => !JSON.stringify(rolesOptions).includes(label),
      )
    : [];

  const isRolesNotBelongExist =
    rolesNotBelongToCurrentUser && !!rolesNotBelongToCurrentUser?.length;

  const { mutate: mutatePasswordReset, isLoading: isPasswordResetLoading } =
    useMutation(
      () => forgotPassword(email, { originUrl: window.location.origin }),
      {
        retry: false,
        onSuccess: () => {
          toasts.success({
            message: translate("user.toasts.sendPasswordResetLinkSuccess"),
          });
        },
        onError: (error: AxiosError) => {
          toasts.error({ message: getErrorMessage(error) || error.message });
        },
      },
    );

  const { mutate: mutateDeleteUser, isLoading: isDeleting } = useMutation(
    () => instance.users.deleteUser(email),
    {
      onSuccess: () => {
        history.push(`/users?deleted=${email}`);
      },
      onError: () => {
        toasts.error({
          message: "Failed to delete",
        });
      },
    },
  );

  const {
    mutate: mutateTemporaryPasswordReset,
    isLoading: isLoadingTemporaryPasswordReset,
  } = useMutation(
    () =>
      instance.users.postResendUserTempPassword({
        email,
      }),
    {
      onSuccess: () => {
        toasts.success({
          message: translate(
            "user.toasts.sendTemporaryPasswordResetLinkSuccess",
          ),
        });
      },
      onError: () => {
        toasts.error({
          message: translate("user.toasts.cannotSendTemporaryPassword"),
        });
      },
    },
  );

  const { mutate: mutateAttributes, isLoading: isLoadingAttributes } =
    useMutation(
      async (
        attributes: ApiResponseData<
          typeof instance.users.getUser
        >["attributes"],
      ) => {
        await instance.users.putUserAttributes(email, { attributes });
      },
      {
        onError: () => {
          toasts.error({
            message: translate("user.toasts.cannotUpdateAttributes"),
          });
        },
        onSuccess: () => {
          setSuccessModal(true);
        },
      },
    );

  const {
    mutate: mutateUser,
    isSuccess: isSuccessUpdate,
    isLoading,
    reset: resetMutationUser,
  } = useMutation(
    (user: ApiRequestData<typeof instance.users.patchUser>) =>
      instance.users.patchUser(email, user),
    {
      onError: () => {
        toasts.error({
          message: translate("user.toasts.cannotUpdateUser"),
        });
      },
      onSuccess: () => {
        setSuccessModal(true);
      },
    },
  );

  const initialValues: UpdateUserInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: "",
    companies: [],
    roles: [],
  };

  const handleSubmit = (values: any) => {
    const { roles, ...rest } = values;

    const updatedRoles: OptionForSelect[] = roles;

    if (!isClearabeeAdmin && isRolesNotBelongExist) {
      // check whether final roles value after submit does contain rolesNotBelongExist or not
      const check = rolesNotBelongToCurrentUser.every(({ label }) =>
        JSON.stringify(roles).includes(label),
      );

      if (!check) {
        updatedRoles.push(...rolesNotBelongToCurrentUser);
      }
    }

    mutateUser({
      ...rest,
      roles: updatedRoles.map(({ value }) => parseInt(value)) ?? [],
      company: values.company[0]?.label ?? "",
      companies:
        values?.company.map(({ value }: OptionForSelect) => parseInt(value)) ??
        [],
    });
  };

  return (
    <div className="py-5">
      {isLoadingUser || isLoadingCompanies ? (
        <Icon.Loading color="brand" styles={{ margin: "auto" }} />
      ) : (
        <div className="mb-10">
          <Link
            to="/users"
            className="text-sm inline-flex items-center justify-start"
          >
            <span>
              <LeftChevron />
            </span>
            <span className="ml-2">
              {translate("user.buttons.backToUsers")}
            </span>
          </Link>

          <>
            <Form
              initialValues={{
                ...initialValues,
                firstName: firstName ?? "",
                lastName: lastName ?? "",
                email,
                company: userCompany ?? [],
                phoneNumber: phoneNumber ?? "",
                roles: existingRoles,
                attributes: [],
              }}
              validationSchema={validation}
              isInitialValid
              onSubmit={handleSubmit}
            >
              {({ isValid, values, setFieldValue }) => (
                <div>
                  <Panel styles={{ marginTop: theme.spacing.small }}>
                    <Heading color="brand" level={2} fontSize="xlarge">
                      {translate("user.titles.manageUser")}
                    </Heading>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: medium ? "repeat(3, 1fr)" : "1fr",
                        gap: theme.spacing.xsmall,
                      }}
                    >
                      <Field
                        name="firstName"
                        label={translate("user.form.labels.firstName")}
                      >
                        {({ field }) => <Input.Text {...field} />}
                      </Field>
                      <Field
                        name="lastName"
                        label={translate("user.form.labels.lastName")}
                      >
                        {({ field }) => <Input.Text {...field} />}
                      </Field>
                      <Field
                        name="email"
                        label={translate("user.form.labels.emailAddress")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            disabled
                            data-lpignore="true"
                            autoComplete="off"
                            onChange={() =>
                              setFieldValue("email", values.email)
                            }
                          />
                        )}
                      </Field>
                      <Field
                        name="phoneNumber"
                        label={translate("user.form.labels.phoneNumber")}
                      >
                        {({ field }) => <Input.Text {...field} />}
                      </Field>

                      <Field
                        styles={{
                          flex: 1,
                        }}
                        name="company"
                        label={translate("user.form.placeholders.company")}
                      >
                        {({ field }) => (
                          <Select
                            {...field}
                            placeholder={
                              isLoadingCompanies
                                ? "Loading..."
                                : translate(
                                    "user.form.placeholders.searchValue",
                                  )
                            }
                            isClearable
                            isSearchable
                            isMulti
                            disabled={!companiesChoices}
                            options={companiesOptions}
                            onChange={(values) => {
                              const selectedValues =
                                values as unknown as OptionForSelect[];

                              if (!selectedValues?.length) {
                                if (isCompaniesNotBelongExist) {
                                  return setFieldValue(
                                    "company",
                                    companiesNotBelongToCurrentUser,
                                  );
                                }

                                return setFieldValue("company", []);
                              }

                              setFieldValue("company", selectedValues);
                            }}
                            className="text-base"
                            styles={{
                              multiValue: (base, state) => {
                                return isCompaniesNotBelongExist &&
                                  checkCompaniesNotBelong(state.data.label)
                                  ? { ...base, backgroundColor: "gray" }
                                  : base;
                              },
                              multiValueLabel: (base, state) => {
                                return isCompaniesNotBelongExist &&
                                  checkCompaniesNotBelong(state.data.label)
                                  ? {
                                      ...base,
                                      fontWeight: "bold",
                                      color: "white",
                                      paddingRight: 6,
                                    }
                                  : base;
                              },
                              multiValueRemove: (base, state) => {
                                return isCompaniesNotBelongExist &&
                                  checkCompaniesNotBelong(state.data.label)
                                  ? { ...base, display: "none" }
                                  : base;
                              },
                            }}
                          />
                        )}
                      </Field>

                      <Field
                        name="roles"
                        label={translate("user.form.labels.role", {
                          plural: isClearabeeAdmin ? "s" : "",
                        })}
                      >
                        {({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            isSearchable
                            isMulti={isClearabeeAdmin || isClearabeeManager}
                            disabled={
                              isLoadingRoles ||
                              !rolesChoices ||
                              isCompaniesNotBelongExist
                            }
                            placeholder={
                              isLoadingRoles
                                ? "Loading..."
                                : translate(
                                    "user.form.placeholders.selectRole",
                                    {
                                      plural: isClearabeeAdmin ? "s" : "",
                                    },
                                  )
                            }
                            options={rolesOptions ?? []}
                            onChange={(values: OptionForSelect[]) => {
                              if (values === null)
                                return setFieldValue("roles", []);

                              /**
                               * if multi-select allowed, "values" from onChange will be an array of label and value
                               * otherwise just an object of label and value
                               * roles property is an array, so if multi-select is not allowed (when user is not ClearabeeAdmin), wrap "values" in an array
                               */
                              setFieldValue(
                                "roles",
                                isClearabeeAdmin ? values : [values],
                              );
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </Panel>
                  <div
                    style={{
                      marginTop: theme.spacing.xlarge,
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      gap: theme.spacing.small,
                    }}
                  >
                    <Button
                      type="submit"
                      color="accent"
                      size="small"
                      onClick={() => handleSubmit(values)}
                      disabled={
                        !isValid ||
                        isLoading ||
                        isSuccessUpdate ||
                        isClearabeeManager
                      }
                    >
                      {translate("user.buttons.updateUser")}
                    </Button>
                    <Button
                      onClick={() => mutatePasswordReset()}
                      size="small"
                      variant="outline"
                      type="button"
                      disabled={isPasswordResetLoading || isSuccessUpdate}
                    >
                      {translate("user.buttons.passwordResetLink")}
                    </Button>
                    {isClearabeeAdmin && !!email && (
                      <Button
                        onClick={() => mutateTemporaryPasswordReset()}
                        size="small"
                        variant="outline"
                        type="button"
                        className="flex gap-x-3"
                        disabled={
                          isLoadingTemporaryPasswordReset || isSuccessUpdate
                        }
                      >
                        {isLoadingTemporaryPasswordReset && (
                          <Icon.Loading size="small" color="brand" />
                        )}
                        {translate("user.buttons.temporaryPasswordReset")}
                      </Button>
                    )}
                    <Button
                      onClick={() => setShowDeleteModal(true)}
                      size="small"
                      type="button"
                      color="negative"
                      disabled={
                        isDeleting || isSuccessUpdate || isClearabeeManager
                      }
                    >
                      {translate("user.buttons.deleteUser")}
                    </Button>
                  </div>
                </div>
              )}
            </Form>
            {!!userData?.attributes?.length && (
              <Form
                onSubmit={(values) => mutateAttributes(values.attributes)}
                initialValues={{
                  attributes: [
                    { id: 0, userId: 0, attrKey: "", attrValue: "" },
                  ],
                }}
              >
                {({ values, setFieldValue }) => {
                  useEffect(() => {
                    userData?.attributes?.map(
                      ({ attrKey, attrValue }, index) => {
                        setFieldValue(`attributes[${index}]`, {
                          attrKey,
                          attrValue,
                        });
                      },
                    );
                  }, [userData?.attributes]);

                  return (
                    <>
                      {isClearabeeAdmin && (
                        <Panel className="mt-10">
                          <>
                            <div className="flex justify-between items-center">
                              <Heading
                                color="brand"
                                level={2}
                                fontSize="xlarge"
                              >
                                {translate("user.titles.userAttributes")}
                              </Heading>
                              <Button type="submit" size="small">
                                {isLoadingAttributes ? (
                                  <Icon.Loading
                                    color="brand"
                                    size="small"
                                    styles={{ margin: "auto" }}
                                  />
                                ) : (
                                  translate("user.buttons.updateAttributes")
                                )}
                              </Button>
                            </div>
                            {!values.attributes?.length && (
                              <Text>
                                {translate("errors.noUserAttributes")}
                              </Text>
                            )}
                            {!!values.attributes?.length && (
                              <FlexGrid
                                cellSpacing="xsmall2"
                                rowSpacing="xsmall"
                              >
                                {values.attributes.map(
                                  ({ attrKey, attrValue }, index) => {
                                    return (
                                      <FlexGrid.Cell
                                        key={`attributes-${attrKey}-${index}`}
                                        base="50%"
                                        medium="25%"
                                      >
                                        <Field
                                          name={`attributes[${index}].attrValue`}
                                          label={snakeToNormal(attrKey)}
                                        >
                                          {({ field }) => (
                                            <>
                                              {attrKey ===
                                              "bee_loyal_member" ? (
                                                <Input.Toggle
                                                  {...field}
                                                  value={String(!!attrValue)}
                                                  defaultChecked={!!attrValue}
                                                />
                                              ) : (
                                                <Input.Text
                                                  {...field}
                                                  disabled={
                                                    attrKey !==
                                                      "bee_loyal_credit" &&
                                                    attrKey !==
                                                      "bee_loyal_member" &&
                                                    true
                                                  }
                                                  value={attrValue}
                                                />
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </FlexGrid.Cell>
                                    );
                                  },
                                )}
                              </FlexGrid>
                            )}
                          </>
                        </Panel>
                      )}
                    </>
                  );
                }}
              </Form>
            )}
          </>
        </div>
      )}
      {!isLoadingUser && isClearabeeAdmin && (
        <>
          <FlexGrid>
            <FlexGrid.Cell base="100%" xlarge="50%">
              <Panel>
                <UserJobs email={email} />
              </Panel>
            </FlexGrid.Cell>

            <FlexGrid.Cell base="100%" xlarge="50%">
              <Panel>
                <BeeLoyalTransactions email={email} />
              </Panel>
            </FlexGrid.Cell>
          </FlexGrid>
        </>
      )}

      <SuccessModal
        onClose={() => {
          setSuccessModal(false);
          resetMutationUser();
        }}
        heading={translate("user.modals.headings.updateUserSuccess")}
        width={500}
        actions={
          <Button
            className="mt-3"
            size="medium"
            onClick={() => history.push("/users")}
          >
            {translate("user.buttons.backToUsers")}
          </Button>
        }
      />

      <DeleteConfirmationModal
        title="Are you sure you want to delete this user?"
        visible={showDeleteModal}
        deleteCallback={() => mutateDeleteUser()}
        hideCallback={() => {
          setShowDeleteModal(false);
        }}
        yesButtonLabel="Delete"
      />
    </div>
  );
};
