import React, { useState } from "react";
import {
  Button,
  Heading,
  theme,
  Icon,
  Pill,
  Table,
  displayErrorMessage,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { statusColors } from "helpers/statusColors";
import { Job } from "models";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

interface UserJobsProps {
  email: string;
}

const ResultsTable = ({ email }: UserJobsProps): React.ReactElement => {
  const history = useHistory();
  const { paginatedData, PaginationComponent, query } = usePaginatedQuery(
    async (filters, page, limit) => {
      const { items, pagination } = (
        await instance.jobs.postQueryJobs({
          limit,
          offset: page * limit,
          filters: [["contactEmail", "=", email]],
          orderBy: {
            timestamp: "DESC",
          },
        })
      ).data;
      return {
        items,
        pagination: {
          ...pagination,
          hasMoreResults: false,
        },
      };
    },
    `${email}-getUserJobs`,
    "",
  );
  const [translate] = useTranslation("users");

  if (query.isLoading) {
    return <Icon.Loading color="brand" />;
  }

  if (!paginatedData || !paginatedData.length) {
    return <Pill color="warning">{translate("user.noData")}</Pill>;
  }

  if (query.error) {
    return (
      <>
        {displayErrorMessage(query.error, (props) => (
          <DefaultErrorComponent {...props} onRetry={() => query.refetch()} />
        ))}
      </>
    );
  }

  return (
    <>
      <div className="relative">
        <Table>
          <Table.Header
            headings={[
              translate("jobs:table.headings.status"),
              translate("jobs:table.headings.jobReference"),
              translate("jobs:table.headings.postcode"),
              translate("jobs:table.headings.amount"),
              translate("jobs:table.headings.creditUsed"),
            ]}
          />
          <Table.Body>
            {paginatedData.map((row) => {
              const total = row.items?.reduce(
                (total, { lineCost }) => total + lineCost,
                0,
              );
              return (
                <Table.Row
                  key={`userJobs-${row.id}`}
                  onClick={() => {
                    history.push(
                      `/jobs/update/${encodeURIComponent(row.id ?? "")}`,
                    );
                  }}
                  styles={{
                    cursor: "pointer",
                  }}
                >
                  <Table.Cell.Pill
                    color={statusColors[row.status as Job["status"]]}
                    size="small"
                  >
                    {row.status}
                  </Table.Cell.Pill>
                  <Table.Cell.Text>{row.ref}</Table.Cell.Text>
                  <Table.Cell.Text>{row.addressPostcode}</Table.Cell.Text>
                  {/* Price does not include any delivery charges! :( */}
                  <Table.Cell.Currency price={total || 0} />
                  <Table.Cell.Currency price={row.creditUsed || 0} />
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {query.isFetching && (
          <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
            <Icon.Loading color="accent" />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center mt-10">
        <PaginationComponent />
      </div>
    </>
  );
};

export const UserJobs = ({ email }: UserJobsProps): React.ReactElement => {
  const [tableVisible, showTable] = useState(false);
  const [translate] = useTranslation("users");

  return (
    <>
      <Heading
        color="brand"
        level={2}
        fontSize="xlarge"
        styles={{ marginBottom: theme.spacing.medium }}
      >
        {translate("user.titles.userJobs")}
      </Heading>
      {!tableVisible && (
        <Button onClick={() => showTable(true)} size="small">
          {translate("user.buttons.loadJobs")}
        </Button>
      )}
      {tableVisible && <ResultsTable email={email} />}
    </>
  );
};
