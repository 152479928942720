"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.quotes = void 0;
exports.quotes = {
    headings: {
        collectionDetails: "Collection Details",
        customerDetails: "Customer Details",
        wasteDetails: "Waste Details",
        company: "Company",
        respondToQuote: "Repond to quote",
        quoteDetails: "Quote Details...",
        contact: "Contact",
        postcode: "Postcode",
        status: "Status",
        creationDate: "Creation Date",
        quoteReference: "Quote Reference",
        filterQuotes: "Filter quotes",
    },
    form: {
        label: {
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            addressCity: "City",
            addressCounty: "County",
            addressPostcode: "Postcode",
            address: "Address",
            contactFirstName: "First name",
            contactLastName: "Last name",
            contactEmail: "Email address",
            contactPhoneNumber: "Phone number",
            product: "Product",
            companyCode: "Company",
            price: "Price",
            time: "Time",
            comments: "Supporting comments",
            date: "Date",
            noTimeConstraint: "No time constraint?",
            timeslot: {
                from: "From time",
                to: "To time",
            },
            allDay: "All day",
            description: "Waste Description",
            accessInformation: "Access information",
            contactNoEmail: "No email address available",
            uploadImages: "Upload images",
            qty: "Quantity",
            approved: "Approved on behalf of",
            validUntil: "Valid Until",
            validUntilTime: "Valid Until Time",
            companyUserEmail: "On behalf of:",
            addProduct: "Add product",
            showExpiredQuotes: "Show expired quotes",
            search: "Search",
        },
        placeholder: {
            addressLine1: "Number and Road Name",
            addressLine2: "Address Line 2",
            addressCity: "City",
            addressCounty: "County",
            addressPostcode: "Postcode",
            contactFirstName: "First name",
            contactLastName: "Last name",
            contactEmail: "Email address",
            contactPhoneNumber: "Phone number",
            serviceType: "Service",
            serviceProduct: "Product",
            price: "Price",
            time: "Time",
            comments: "Comments",
            date: "Collection Date",
            dateOnly: "Date",
            timeslot: "Timeslot",
            description: "Waste Description",
            accessInformation: "Access information",
            searchCustomer: "Type to search customer",
            companyCode: "Search company...",
            selectCompany: "Search company...",
            selectStatus: "Search status...",
            selectDate: "Select a date...",
            selectUser: "Select user...",
            validUntil: "Select valid until date...",
            companyUserEmail: "Selec company user...",
            viewCompany: "View company",
        },
        statusOptions: {
            all: "All",
            open: "Open",
            quoted: "Quoted",
            rejected: "Rejected",
            accepted: "Accepted",
            expired: "Expired",
        },
        errors: {
            invalidEmail: "This email is invalid",
            requiredField: "This field is required",
            matchField: "Passwords must match",
        },
        buttons: {
            submitQuote: "Submit quote",
            backToQuotes: "Back to Quotes",
            copyToClipboard: "Copy to clipboard",
            rejectQuote: "Reject quote",
            declineQuote: "Decline quote",
            acceptQuote: "Accept quote",
            acceptOnBehalf: "Accept on behalf of customer",
        },
    },
    title: {
        quote: "Quote",
        respondToQuote: "Respond to quote request",
        validUntil: "Valid until",
    },
    description: {
        login: "Sign into your account.",
        forgot: "Enter your email address and click the ‘reset password’ button and we’ll send you a link to create a new password..",
        reset: "Create a new password. Your password must be at least 8 characters in length.",
        welcome: "Set a strong password for your new account. Minimum 1 special character, number and letter.",
    },
    modals: {
        headings: {
            acceptOnBehalf: "Quote approval confirmation",
            createSuccess: "Quote Successfully Created",
            reference: "Ref: {{reference}}",
            updateSuccess: "Successfully updated quote!",
        },
        subtitles: {
            acceptOnBehalf: "Select the name from the list below of the customer you are making the booking on behalf of.",
        },
        buttons: {
            close: "Close",
            finish: "Finish",
            backToDashboard: "Back to Dashboard",
            requestAnother: "Request another",
            viewQuote: "View quote",
            backToQuotes: "Back to Quotes",
        },
    },
    errors: {
        createFailed: "Quote could not be created. Please try again",
        expiredCollectionDate: "OOPS! It looks like the collection date has already passed.",
        noCatalogue: "Company is missing a catalogue, please add a catalogue to the company and try again.",
        quoteUpdate: "Quote could not be updated. Please try again or contact support.",
        clipboard: "Error occurred copying, try again.",
    },
    cantFindAddress: "Can't find the address?",
    customerImagesCount: "Customer Images {{ count }}",
    buttons: {
        manualAddress: "Enter address manually",
        postcodeLookup: "Switch back to postcode lookup",
        backToQuotes: "Back to all quotes",
    },
    customerResponse: {
        each: "each",
    },
    success: {
        clipboard: "Successfully copied to clipboard",
    },
};
