/* eslint-disable prettier/prettier */
import React, { useRef, useState, useEffect } from "react";
import {
  useFormikContext,
  FormikFormProps,
  FormikProps,
  FormikValues,
  getIn,
} from "formik";
import { DayPickerProps } from "react-day-picker";
import cx from "classnames";
import dayjs from "dayjs";

/**
 * Import components.
 */
import { DatePicker } from "../datePicker";
import { FormInput } from "..";

/**
 * Type props for date picker input field.
 */
type TDatePickerInput = {
  name: string;
  placeholder?: string;
  classes?: string;
  label?: IInputLabel;
  error?: IErrorMessage;
  required?: boolean;
  floatingLabel?: boolean;
  validate?: boolean;
  numberOfMonths?: number;
  fromMonth?: DayPickerProps["fromMonth"];
  toMonth?: DayPickerProps["toMonth"];
  disabledDays?: DayPickerProps["disabledDays"];
  isRanged?: boolean;
  calendarPosition?: "above" | "below";
  disabled?: boolean;
};

/**
 * Type props for input label.
 */
interface IInputLabel {
  text: string;
  classes?: string;
}

/**
 * Type props for error messages.
 */
interface IErrorMessage {
  classes?: string;
}

/**
 * Configuration variables.
 */
const frontendFormat = "ddd DD MMM";
const backendFormat = "YYYY-MM-DD";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const DatePickerInput: React.FC<TDatePickerInput> = ({
  name,
  placeholder,
  classes,
  label = {
    text: "",
    classes: "",
  },
  error = {
    classes: "",
  },
  required = false,
  floatingLabel = true,
  validate = true,
  numberOfMonths = 2,
  isRanged = true,
  disabledDays,
  fromMonth,
  toMonth,
  calendarPosition,
  disabled = false,
}) => {
  /**
   * Get Formik context.
   */
  const { setFieldValue }: FormikProps<FormikFormProps> = useFormikContext();

  const { values }: FormikProps<FormikValues> = useFormikContext();

  /**
   * Constants.
   */
  const startField = `${name}Start`;
  const endField = `${name}End`;
  const blankValue =
    values[startField] && values[endField]
      ? {
          from: values[startField],
          to: values[endField],
        }
      : {
          from: undefined,
          to: undefined,
        };

  const startFieldValue = getIn(values, startField);
  const endFieldValue = getIn(values, endField);

  /**
   * Component state.
   */
  const [value, setValue] = useState(blankValue);
  const [calendarVisible, setCalendarVisible] = useState(false);

  /**
   * Refs.
   */
  const inputRef = useRef<any>();
  const datePickerRef = useRef<any>();
  const datePickerWrapperRef = useRef<any>();

  /**
   * Used to hide calendar when not focused on input or calendar.
   */
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const { target } = event as any;

      if (
        !(
          target &&
          datePickerRef &&
          datePickerRef.current &&
          inputRef &&
          inputRef.current
        )
      ) {
        return;
      }

      if (
        !(
          datePickerRef.current.dayPicker.contains(target) ||
          inputRef.current.contains(target)
        )
      ) {
        setCalendarVisible(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);

    return () => window.removeEventListener("click", handleOutsideClick);
  }, []);

  /**
   * Reset the input when the field value is set to empty string
   */
  useEffect(() => {
    if (!startFieldValue && !endFieldValue) {
      setValue(blankValue);
      if (datePickerWrapperRef.current) {
        datePickerWrapperRef.current.reset();
      }
    }
  }, [startFieldValue, endFieldValue]);

  /**
   * Text input string value.
   */
  const getDateString = (newDate: { from?: Date; to?: Date }) => {
    if ((newDate.from && newDate.to) || (!isRanged && newDate.from)) {
      return isRanged
        ? `${dayjs(newDate.from).format(frontendFormat)} - ${dayjs(
            newDate.to,
          ).format(frontendFormat)}`
        : dayjs(newDate.from).format(frontendFormat);
    }
    return "";
  };

  /**
   * Event handlers.
   */
  const handleValueChange = (newDate: any) => {
    setValue(newDate);

    if (isRanged) {
      if (newDate.from && newDate.to) {
        setFieldValue(startField, dayjs(newDate.from).format(backendFormat));
        setFieldValue(endField, dayjs(newDate.to).format(backendFormat));
      } else {
        setFieldValue(startField, "");
        setFieldValue(endField, "");
      }
    } else {
      setFieldValue(name, dayjs(newDate.from).format(backendFormat));
    }
  };

  const handleClick = () => {
    if (
      datePickerRef &&
      datePickerRef.current &&
      inputRef &&
      inputRef.current
    ) {
      setCalendarVisible(true);
      setTimeout(() => {
        datePickerRef.current.focus();
      }, 0);
    }
  };

  /**
   * Detect if values have been set back to empty string.
   */
  const hasReset =
    value.from !== blankValue.from &&
    value.to !== blankValue.to &&
    values[startField] === "" &&
    values[endField] === "";

  return (
    <div className="date-picker-input-wrap">
      <FormInput
        name={name}
        type="text"
        classes={classes}
        placeholder={placeholder}
        label={{
          text: label.text,
          classes: label.classes,
        }}
        value={getDateString(
          !!getIn(values, name) ? { from: values[name] } : value,
        )}
        error={error}
        floatingLabel={floatingLabel}
        validate={validate}
        required={required}
        autocomplete={false}
        onClick={handleClick}
        ref={inputRef}
        disabled={disabled}
        readOnly
      />
      <DatePicker
        className={cx(
          "DayPickerInput-Overlay inline-block absolute z-50 ut-transition",
          {
            "opacity-0 invisible pointer-events-none": !calendarVisible,
            "opacity-1": calendarVisible,
            above: calendarPosition === "above",
            below: calendarPosition === "below",
          },
        )}
        numberOfMonths={numberOfMonths}
        datePickerRef={datePickerRef}
        isRanged={isRanged}
        hideCalendar={() => setCalendarVisible(false)}
        onValueChange={handleValueChange}
        disabledDays={disabledDays}
        fromMonth={fromMonth}
        toMonth={toMonth}
        resetCalendarInput={() => setValue({ from: undefined, to: undefined })}
        ref={datePickerWrapperRef}
      />
    </div>
  );
};
