import React from "react";
import {
  Panel,
  Text,
  Heading,
  formatCurrency,
  theme,
} from "@clearabee/ui-library";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

export interface TotalsPanelProps {
  products: { price: number; qty: number; title: string }[];
  quoteRef?: string;
}

export const TotalsPanel = ({
  products,
  quoteRef,
}: TotalsPanelProps): React.ReactElement => {
  const [translate] = useTranslation("quotes");
  const total: number =
    products?.reduce((final, next) => final + next.price * next.qty, 0) ?? 0;

  return (
    <Panel color="brand" className="my-10 w-1/2 mx-auto">
      {quoteRef && (
        <Heading
          color="light"
          level={4}
          styles={{ marginBottom: theme.spacing.medium }}
        >
          {translate("common:reference")}: {quoteRef}
        </Heading>
      )}
      <div className="flex flex-wrap -mx-2">
        {products?.map(({ qty, price, title }) => (
          <React.Fragment key={`${qty}-${price}-${title}`}>
            <Text styles={{ width: "10%" }} className="px-2">
              x{qty}
            </Text>
            <Text styles={{ width: "60%" }} className="px-2">
              {title}
            </Text>
            <Text
              styles={{ width: "30%", textAlign: "right" }}
              className="px-2"
            >
              {formatCurrency(price)}{" "}
              <span css={css({ fontSize: theme.fontDefaults.xsmall.fontSize })}>
                {translate("customerResponse.each")}
              </span>
            </Text>
          </React.Fragment>
        ))}
        <div
          className="flex justify-between pt-6 mt-6 w-full"
          css={css({ borderTop: `1px solid ${theme.colors.light.base}` })}
        >
          <Text>{translate("common:total")}</Text>
          <Text>{formatCurrency(total)}</Text>
        </div>
      </div>
    </Panel>
  );
};
