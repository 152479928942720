import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { useConfig } from "../../../hooks";

/**
 * Type props for link.
 */
type LinkType = {
  text: string;
  to: string;
  onClick?: () => void;
  className?: string;
  variant?: "primary" | "secondary";
  filled?: boolean;
  button?: boolean;
  external?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top" | string;
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const LinkButton: React.FC<LinkType> = ({
  text,
  onClick,
  to,
  className,
  variant = "primary",
  filled = false,
  button = true,
  external = false,
  target,
}) => {
  /**
   * Create button classname.
   *
   * Borrow same styles from button config file.
   */
  const s = useConfig("button");
  const classes = cx(
    className,
    button ? s(`classes.form.${variant}`) : "",
    filled ? " btn-filled" : "",
  );

  /**
   * An object is created with all of the set values.
   */
  const link = {
    text,
    to,
    onClick,
    classes,
    target,
  };

  /**
   * Output the link.
   */
  return (
    <>
      {!external ? (
        <Link className={link.classes} onClick={link.onClick} to={link.to}>
          {link.text}
        </Link>
      ) : (
        <a className={link.classes} href={link.to} target={link.target}>
          {link.text}
        </a>
      )}
    </>
  );
};
