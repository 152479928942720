"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nav = void 0;
exports.nav = {
    links: {
        timeline: "Timeline",
        map: "Map",
        healthCheck: "Health Check",
    },
    tools: {
        search: "Search",
        notification: "Notification",
        settings: "Settings",
        fullscreen: "Fullscreen",
    },
};
