import React from "react";

import {
  StatusCompleted,
  StatusStarted,
  StatusOpen,
  StatusPending,
  StatusScheduled,
  StatusFailed,
  StatusPendingApproval,
  StatusCancelled,
} from "../../common/components";

interface IJobStatus {
  row: {
    original: {
      status: string;
    };
  };
}

export const JobStatus = ({ row }: IJobStatus): JSX.Element | null => {
  const { original } = row;
  const { status } = original;

  if (status === "Completed" || status === "processed") {
    return <StatusCompleted />;
  }

  if (status === "Started") {
    return <StatusStarted />;
  }

  if (status === "Open") {
    return <StatusOpen />;
  }

  if (status === "On the way") {
    return <StatusPending />;
  }

  if (status === "Pending") {
    return <StatusPendingApproval />;
  }

  if (status === "Scheduled") {
    return <StatusScheduled />;
  }

  if (status === "Failed") {
    return <StatusFailed />;
  }

  if (status === "Cancelled") {
    return <StatusCancelled />;
  }

  return null;
};
