import React, { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import { ApiRequestData, instance } from "@clearabee/ui-sdk";
import { uploadImageToS3 } from "api";
import {
  Box,
  Button,
  Icon,
  Message,
  Text,
  UnstyledButton,
  displayErrorMessage,
  theme,
} from "@clearabee/ui-library";
import { styles } from "./uploadImages.styles";

export interface CustomFile {
  dataUrl: string;
  file: File;
  key?: string;
  removeFile?: () => void;
}
type CustomFileList = CustomFile[];

type postJobImagesType = ApiRequestData<typeof instance.jobs.postJobImages>;

export interface InputFileProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  jobRef: string;
  defaultValues?: CustomFileList;
  accept?: string;
  isLoading?: boolean;
  uploadButtonText: string;
  deleteButtonText: string;
  onFileChange?: (files: CustomFile[]) => void;
}

/**
 * Generate Base64 string for files.
 */
const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.addEventListener("load", () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

/**
 * Generate the image data list based on the uploaded files.
 */
const generateFileList = (
  files: FileList,
  removeFile: (key: string) => void,
  target: EventTarget & HTMLInputElement,
): Promise<CustomFileList> => {
  const promiseFiles: Array<Promise<string>> = [];

  for (let i = 0; i < files.length; i += 1) {
    promiseFiles.push(getBase64(files[i]));
  }

  return Promise.all(promiseFiles).then((fileListBase64: Array<string>) => {
    const fileList: CustomFileList = fileListBase64.map((base64, index) => {
      const key = `${new Date().getTime().toString()}-${files[index].name}`;
      return {
        dataUrl: base64,
        file: files[index],
        key,
        removeFile: (): void => removeFile(key),
        target,
      };
    });
    return fileList;
  });
};

export const UploadImages = ({
  name = "fileUpload",
  accept = ".jpg,.jpeg,.png",
  jobRef,
  defaultValues,
  uploadButtonText,
  deleteButtonText,
  isLoading = false,
  onFileChange,
  onChange,
  ...rest
}: InputFileProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState(defaultValues || []);
  const [isUploading, setIsUploading] = useState(false);
  const [images, setImages] = useState(fileList);
  const [error, setError] = useState("");
  const fileListRef = useRef(fileList);

  /**
   * Update ref of file list each time it changes.
   */
  useEffect(() => {
    fileListRef.current = fileList;
  }, [fileList]);

  /**
   * Upload all images to S3 and database
   */
  const handleUploadImages = async (): Promise<void> => {
    setIsUploading(true);
    const uploadingImages: string[] = [];
    const encodedJobRef = encodeURIComponent(jobRef);

    try {
      for await (const { file } of fileList) {
        const encodedFileName = encodeURIComponent(file.name).replace(
          /'/g,
          "%27",
        );
        const { fields, url } = (
          await instance.jobs.putJobImage(encodedJobRef, encodedFileName)
        ).data;

        const imageURL = await uploadImageToS3(file, url, fields);

        uploadingImages.push(imageURL);
      }

      const payload = {
        type: "user",
        images: uploadingImages,
        // if wanted to duplicate images and send to bigchange, set to true
        sendBcAttachment: false,
      } as postJobImagesType;

      await instance.jobs.postJobImages(encodedJobRef, payload);

      removeFile();

      setIsUploading(false);
    } catch (error) {
      setError(String(error));
      setIsUploading(false);
    }
  };

  /**
   * Remove a selected image by index
   */
  const removeAnImage = (removeIndex: number): void => {
    const updatedImages = fileList.filter((_, index) => index !== removeIndex);

    setError("");
    setFileList(updatedImages);
    setImages(updatedImages);
    onDataChange(updatedImages);
  };

  /**
   * On upload file, click input to open file browser.
   */
  const uploadFile = (): void => {
    if (inputRef.current !== null && !isLoading) {
      inputRef.current.click();
    }
  };

  /**
   * Remove all files on click.
   */
  const removeFile = (): void => {
    const updatedList: CustomFileList = [];

    setError("");
    setFileList(updatedList);
    setImages(updatedList);
    onDataChange(updatedList);
    // reset value of input
    if (inputRef.current) inputRef.current.value = "";
  };

  /**
   * Actions to run when the data changes.
   */
  const onDataChange = (list: CustomFileList): void => {
    if (onFileChange) {
      const sData: CustomFileList = list.map((item) => ({
        file: item.file,
        dataUrl: item.dataUrl,
      }));

      onFileChange(sData);
    }
  };

  /**
   * Function to run when a new file (or files) are added to the uploader.
   */
  const onDrop = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const { files } = e.target;
    const { target } = e;
    setError("");

    if (files) {
      let newFileList = await generateFileList(files, removeFile, target);

      /**
       * Check for files now allowed.
       */
      newFileList.forEach(({ file }: CustomFile) => {
        const extension = file.name.split(".").pop()?.toLowerCase();

        if (typeof extension === "string") {
          if (!accept.toLowerCase().includes(extension)) {
            newFileList = [];
            setError(
              `You have tried to upload a file type which is not allowed (allowed: ${accept})`,
            );
          }
        }
      });

      /**
       * If new files exist, attempt to process them.
       */
      if (newFileList.length > 0) {
        const updatedFileList = newFileList;

        setFileList(updatedFileList);
        setImages(updatedFileList);
        onDataChange(updatedFileList);
      }
    }
  };

  return (
    <>
      <Box
        css={css(styles.fileWrapper, {
          cursor: !!images?.length ? "not-allowed" : "pointer",
        })}
      >
        {isUploading ? (
          <Box css={css(styles.loadingIconContainer)}>
            <Icon.Loading size="xlarge2" color="greyscale.lighter" />
          </Box>
        ) : (
          <>
            {!!images?.length && (
              <Box
                styles={[
                  css(styles.imagesContainer),
                  { justifyContent: images.length <= 4 ? "center" : "start" },
                ]}
              >
                {images.map(({ dataUrl }, index) => (
                  <Box css={css(styles.imageContainer)} key={index}>
                    <Box
                      role="img"
                      styles={[
                        css(styles.image),
                        { backgroundImage: `url(${dataUrl})` },
                      ]}
                    />
                    <UnstyledButton
                      styles={styles.trashIconContainer}
                      onClick={() => removeAnImage(index)}
                    >
                      <Icon.Trash size="medium" />
                    </UnstyledButton>
                  </Box>
                ))}
              </Box>
            )}

            <UnstyledButton
              styles={{
                ...styles.placeholder,
                cursor: !!images?.length ? "not-allowed" : "pointer",
              }}
              onClick={() => !images?.length && uploadFile()}
            >
              {!images?.length ? (
                <Box css={css(styles.camera)}>
                  <Icon.Camera size="xlarge2" color="greyscale.base" />
                  <Text color="greyscale" fontSize="large">
                    {uploadButtonText}
                  </Text>
                </Box>
              ) : (
                <Box css={css(styles.buttonsContainer)}>
                  <Box style={{ marginRight: theme.spacing.xsmall }}>
                    <Button
                      size="small"
                      color="negative"
                      type="button"
                      onClick={removeFile}
                      css={css(styles.button)}
                    >
                      {deleteButtonText}
                    </Button>
                  </Box>
                  <Button
                    size="small"
                    color="brand"
                    type="button"
                    onClick={handleUploadImages}
                    css={css(styles.button)}
                  >
                    {"Upload"}
                  </Button>
                </Box>
              )}
            </UnstyledButton>
          </>
        )}
      </Box>

      <input
        name={name}
        type="file"
        accept={accept}
        ref={inputRef}
        onChange={(event) => {
          onDrop(event);
          onChange?.(event);
        }}
        style={{ display: "none" }}
        {...rest}
      />

      {/* Display error if upload wrong image file type */}
      {error && (
        <Box className="w-full mt-5">
          {displayErrorMessage(error, ({ children }) => (
            <Box className="flex items-center justify-center w-full mb-4 overflow-scroll">
              <Message type="error" background>
                {children}
              </Message>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
