import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Heading,
  Panel,
  InputText,
  Text,
  Button,
  Table,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { getTemplates } from "../../../api/templates";
import { Template } from "models/template";
import { getQueriedResults } from "../../../helpers";
import { RouteLink } from "components/core";
import { PaginatedData } from "components/common/components";
import Placeholder from "../../../images/placeholder.svg";
import { useHistory } from "react-router-dom";

export const ReadTemplates = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<Template[]>();
  const history = useHistory();

  const {
    data: initialData,
    isLoading,
    error,
  } = useQuery(["readTemplates"], () => getTemplates({ limit: 2000 }), {
    retry: 0,
  });

  useEffect(() => {
    setResults(
      getQueriedResults(initialData?.items, search, [
        "title",
        "sku",
        "description",
      ]),
    );
  }, [search]);

  return (
    <div className="max-w-screen-lg mx-auto py-10">
      <Panel shadow={false} className="mb-10">
        <div className="flex flex-col sm:flex-row items-center justify-between border-b border-grey-200 pb-4 mb-3">
          <Heading level={1} fontSize="large" color="brand">
            {translate("filters.templatesHeading")}
          </Heading>
          <RouteLink href="/catalogues/templates/create">
            <Button size="small" as="a" className="inline-block mt-3 sm:mt-0">
              {translate("filters.createNewTemplateButton")}
            </Button>
          </RouteLink>
        </div>
        <div className="flex flex-wrap sm:flex-no-wrap items-center pt-5 relative">
          <InputText
            placeholder={translate("filters.templateSearchPlaceholder")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Icon.Search size="small" className="absolute right-3" />
        </div>
      </Panel>

      <PaginatedData
        data={results || initialData?.items || []}
        isLoading={isLoading}
        error={error}
      >
        {({ paginatedData }) => (
          <Table styles={{ tableLayout: "fixed" }}>
            <Table.Header
              headings={[
                translate("table.headings.image"),
                translate("table.headings.sku"),
                translate("table.headings.title"),
                translate("table.headings.description"),
                translate("table.headings.price"),
                translate("table.headings.action"),
              ]}
            />
            <Table.Body>
              {paginatedData.map((item) => (
                <Table.Row
                  key={`table-row-${item.sku}`}
                  data-testid="template-result"
                >
                  <Table.Cell.Image
                    src={item?.img || Placeholder}
                    css={{
                      height: 60,
                      width: "auto",
                    }}
                    height={60}
                    width={60}
                  />
                  <Table.Cell.Text
                    className="truncate"
                    cellProps={{ styles: { width: 150 } }}
                  >
                    {item.sku}
                  </Table.Cell.Text>
                  <Table.Cell.Text
                    className="truncate"
                    cellProps={{ styles: { width: 80 } }}
                  >
                    {item.title}
                  </Table.Cell.Text>
                  <Table.Cell.Text
                    className="truncate"
                    cellProps={{ styles: { width: 100 } }}
                  >
                    {item.description}
                  </Table.Cell.Text>
                  {item.price ? (
                    <Table.Cell.Currency price={item.price} />
                  ) : (
                    <Table.Cell.Text styles={{ width: 10 }}>
                      {translate("nonApplicable")}
                    </Table.Cell.Text>
                  )}
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: theme.spacing.xsmall,
                      }}
                    >
                      <button
                        style={{ backgroundColor: theme.colors.accent.dark }}
                        className="p-3 rounded-full cursor-pointer"
                        onClick={() =>
                          history.push(`/catalogues/templates/create`, { item })
                        }
                      >
                        <Icon.Clipboard size="small" color="light" />
                      </button>
                      <Button
                        size="small"
                        onClick={() =>
                          history.push(
                            `/catalogues/templates/update/${item.sku}`,
                          )
                        }
                      >
                        {translate("table.actions.edit")}
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </PaginatedData>

      {!!error && (
        <div className="bg-red-500 text-white w-full p-3 text-center rounded-lg">
          <Text className="block" as="span">
            {translate("errors.loadingResults")}
          </Text>
        </div>
      )}
    </div>
  );
};
