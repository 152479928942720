import React from "react";
import { css } from "@emotion/react";
import {
  ColorKeyVariants,
  getColorFromVariantKey,
} from "@clearabee/ui-library-base";
import { UserStylesProps } from "../../utils";

type DivDetailedProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export interface BoxProps
  extends UserStylesProps<Omit<DivDetailedProps, "ref">> {
  color?: ColorKeyVariants;
  backgroundColor?: ColorKeyVariants;
  children?: React.ReactNode;
}

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  (
    { color, backgroundColor, styles: userStyles, ...rest }: BoxProps,
    ref,
  ): React.ReactElement => {
    return (
      <div
        ref={ref}
        css={css(
          userStyles,
          color && { color: getColorFromVariantKey(color) },
          backgroundColor && {
            backgroundColor: getColorFromVariantKey(backgroundColor),
          },
        )}
        {...rest}
      />
    );
  },
);
