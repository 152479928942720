/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import {
  useFormikContext,
  FormikProps,
  FormikFormProps,
  FormikValues,
  getIn,
} from "formik";
import cx from "classnames";
import Select from "react-select";

/**
 * Import components.
 */
import { FieldError } from "..";
import { OptionWithIconAndSubtext, SingleValue } from "./components";

/**
 * Import images.
 */
import { Tick } from "../../../images";

/**
 * Import styles.
 */
import "./styles/select.css";

/**
 * Type props for select field.
 */
type TFormSelect =
  | {
      name: string;
      label: IFormSelectLabel;
      placeholder?: string;
      classes?: string;
      error?: IErrorMessage;
      required?: boolean;
      floatingLabel?: boolean;
      validate?: boolean;
      options: IFormSelectOption[];
      components?: Record<string, unknown>;
      noOptionsMessage?: string;
      clearable?: boolean;
      searchable?: boolean;
      open?: boolean;
      alwaysSetValue?: boolean;
      disabled?: boolean;
      loading?: boolean;
      loadingMessage?: string;
      onChange?: (option: any) => void;
      menuPlacement?: "top" | "bottom";
      isMulti?: boolean;
      defaultValue?: string | any;
    }
  | {
      name: string;
      placeholder?: string;
      classes?: string;
      label?: IFormSelectLabel;
      error?: IErrorMessage;
      required?: boolean;
      floatingLabel?: boolean;
      validate?: boolean;
      options: IFormSelectOption[];
      components?: Record<string, unknown>;
      noOptionsMessage?: string;
      clearable?: boolean;
      searchable?: boolean;
      open?: boolean;
      alwaysSetValue?: boolean;
      disabled?: boolean;
      loading?: boolean;
      loadingMessage?: string;
      onChange?: (option: any) => void;
      menuPlacement?: "top" | "bottom";
      isMulti?: boolean;
      defaultValue?: string;
    };

/**
 * Type props for select option. Icon and subtext are optional.
 */
interface IFormSelectOption {
  label: string;
  value: string | number;
  icon?: any;
  subtext?: any;
}

/**
 * Type props for input label.
 */
interface IFormSelectLabel {
  text: string;
  classes?: string;
}

/**
 * Type props for error messages.
 */
interface IErrorMessage {
  classes?: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const FormSelect: React.FC<TFormSelect> = ({
  name,
  placeholder,
  classes,
  label = {
    text: "",
    classes: "",
  },
  error = {
    classes: "",
  },
  required = false,
  floatingLabel = true,
  validate = true,
  options,
  components = {},
  noOptionsMessage = "No options",
  clearable = false,
  searchable = false,
  alwaysSetValue = true,
  open = undefined,
  disabled = false,
  loading = false,
  loadingMessage = "Loading...",
  onChange = undefined,
  menuPlacement = "bottom",
  isMulti = false,
  defaultValue,
}) => {
  /**
   * Get Formik context.
   */
  const { setFieldValue, setFieldTouched }: FormikProps<FormikFormProps> =
    useFormikContext();

  const { values, errors, touched }: FormikProps<FormikValues> =
    useFormikContext();

  useEffect(() => {
    if (defaultValue && options.length > 0) {
      const selected = options.filter(
        (option: any) => option.value === defaultValue,
      );
      if (selected.length > 0) {
        setFieldValue(name, selected[0]);
      }
    }
    // eslint-disable-next-line
  }, [options]);

  /**
   * Initial value.
   */
  const initialValue = {
    label: "",
    value: "",
  };

  /**
   * Override components if set. If either an icon or subtext are added to
   * the "label" and "value" properties of an option, then this will override the
   * standard option to display these conditional elements
   */
  const overrideComponents = {
    Option: OptionWithIconAndSubtext,
    SingleValue,
    ...components,
  };

  /**
   * An object is created with all of the set values.
   */
  const field = {
    name,
    id: `field-${name}`,
    placeholder: loading ? "Loading..." : placeholder,
    classes,
    error: false,
    label: {
      text: label.text,
      classes: cx("form-input-label", label.classes),
    },
    options,
    components: overrideComponents,
  };

  /**
   * Select state variables.
   */
  const isValid = !getIn(errors, field.name) && getIn(touched, field.name);
  const hasError = getIn(errors, field.name) && getIn(touched, field.name);
  const hasValue = !!getIn(values, field.name);

  /**
   * Classes for select container.
   */
  field.classes = cx(
    "form-input form-select",
    field.classes,
    `select-${field.name}`,
    { "has-value": hasValue },
  );

  /**
   * Set label positioning
   */
  field.label.classes = cx(field.label.classes, {
    "form-input-normal-label": !floatingLabel,
  });

  /**
   * On change default.
   */
  const onChangeDefault = (option: any) => {
    if (option !== null) {
      setFieldValue(field.name, option);
    } else {
      setFieldValue(field.name, initialValue);
    }
  };

  /**
   * Get select option object with label from value.
   */
  const getOptionFromValue = (value: any) => {
    const search = options?.filter((option: any) => option?.value === value);
    return search[0] || null;
  };

  let value: IFormSelectOption | null = null;

  if (!hasValue && !floatingLabel && alwaysSetValue) {
    value = options[0];
  } else if (isMulti) {
    value = getIn(values, field.name);
  } else {
    const formValue = getIn(values, field.name);
    value = formValue?.value ? formValue : getOptionFromValue(formValue);
  }

  /**
   * Output the select. If label is set, we will also output that too.
   */
  return (
    <div
      className={cx("form-input-wrap", {
        valid: isValid,
        required,
        error: hasError,
      })}
    >
      <Select
        name={field.name}
        id={field.id}
        placeholder={!floatingLabel ? field.placeholder : ""}
        className={field.classes}
        onChange={onChange || onChangeDefault}
        value={value || null}
        onBlur={() => {
          setFieldTouched(field.name);
        }}
        data-testid={field.name}
        components={field.components}
        options={field.options}
        classNamePrefix="form-select-dropdown"
        noOptionsMessage={() => noOptionsMessage}
        isClearable={clearable}
        isDisabled={disabled}
        isLoading={loading}
        loadingMessage={() => loadingMessage}
        isSearchable={searchable}
        menuIsOpen={open}
        menuPlacement={menuPlacement}
        isMulti={isMulti}
      />
      {field.label.text ? (
        <label className={field.label.classes} htmlFor={field.id}>
          {field.label.text}
        </label>
      ) : null}

      {validate && (
        <div className="form-input-validate">
          {hasError ? (
            <FieldError
              message={getIn(errors, field.name)}
              classes={error.classes}
            />
          ) : (
            isValid && (
              <Tick
                classes={{
                  svg: "form-input-tick",
                  circle: "form-input-tick-circle",
                  path: "form-input-tick-path",
                }}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};
