import React from "react";
import { Button, Icon, useModal } from "@clearabee/ui-library";
import { styles } from "../bulkCreateJobs.styles";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  isDisabled: boolean;
  deleteRow: () => void;
}
export const DeleteModal = ({
  isDisabled,
  deleteRow,
}: DeleteModalProps): React.ReactElement => {
  const [DeleteModal, setDeleteModal] = useModal();
  const [translate] = useTranslation("jobs");

  return (
    <>
      <button
        type="button"
        disabled={isDisabled}
        css={[
          styles.actionsCellDelete,
          isDisabled && { cursor: "not-allowed" },
        ]}
        onClick={() => setDeleteModal(true)}
      >
        <Icon.Trash size="small" color="light" />
      </button>
      <DeleteModal
        width={400}
        heading={translate("modal.headings.deleteRow")}
        onClose={() => setDeleteModal(false)}
        actions={
          <div style={styles.modal}>
            <Button
              onClick={() => {
                deleteRow();
                setDeleteModal(false);
              }}
              color="accent"
              size="medium"
              type="button"
            >
              {translate("modal.buttons.labels.yesDelete")}
            </Button>
            <Button
              onClick={() => setDeleteModal(false)}
              color="negative"
              size="medium"
              type="button"
            >
              {translate("modal.buttons.labels.goBack")}
            </Button>
          </div>
        }
      />
    </>
  );
};
