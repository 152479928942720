import React from "react";
import { css, useTheme } from "@emotion/react";
import { ThemeInterface } from "@clearabee/ui-library-base";
import { UserStylesProps } from "../../utils";
import { theme } from "../../theme";
import { styles } from "./Container.styles";

export type ContainerProps = UserStylesProps<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

export const Container = ({
  styles: userStyles,
  ...rest
}: ContainerProps): React.ReactElement => {
  const userTheme = useTheme() as ThemeInterface;

  return (
    <div
      css={css(
        styles.default,
        { maxWidth: userTheme.container?.width || theme.container.width },
        userStyles,
      )}
      {...rest}
    />
  );
};
