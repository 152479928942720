import { EditableItem } from "components/catalogues/components";

export const removeItemFromList = (
  item: EditableItem,
  items: EditableItem[],
): EditableItem[] => {
  if (!item.parentSku) return items.filter(({ sku }) => sku !== item.sku);

  // look for parent
  const parent = items.find(({ sku }) => sku === item.parentSku);
  // check items have children
  const itemsHaveChildren = items.some(({ children }) => !!children?.length);

  if (parent) {
    // can safely cast to number as we know this item exists
    const index = parent.children?.findIndex(
      ({ id }) => id === item.id,
    ) as number;
    parent.children?.splice(index, 1);
  } else if (itemsHaveChildren) {
    for (const { children } of items) {
      removeItemFromList(item, children || []);
    }
  }

  return items;
};
