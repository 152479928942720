import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { theme } from "../../theme";
import { styles, AllowedColorKeys } from "./Heading.styles";

export interface HeadingProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLHeadingElement>,
      HTMLHeadingElement
    >
  > {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  fontSize?: keyof typeof theme["fontDefaults"];
  color?: AllowedColorKeys;
}

export const Heading = ({
  level = 1,
  fontSize,
  styles: userStyles,
  color,
  ...rest
}: HeadingProps): React.ReactElement => {
  const Component = `h${level}` as keyof Pick<
    JSX.IntrinsicElements,
    `h${NonNullable<HeadingProps["level"]>}`
  >;
  return (
    <Component
      css={css(
        styles.defaults,
        styles[`heading${level}` as keyof typeof styles],
        fontSize && theme.fontDefaults[fontSize],
        userStyles,
        color && { color: theme.colors[color].base },
      )}
      {...rest}
    />
  );
};
