import { theme } from "../../../../theme";
import { StyleSheet } from "../../../../utils";

export const styles = StyleSheet({
  defaults: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  label: {
    paddingLeft: theme.spacing.xsmall,
  },
  input: {
    opacity: 0,
    width: 0,
    height: 0,
    ":checked + .checkbox-control": {
      backgroundColor: theme.colors.brand.base,
      borderColor: theme.colors.brand.base,
    },
    ":focus + .checkbox-control": {
      borderColor: theme.colors.focus.base,
    },
    ":disabled:checked + .checkbox-control": {
      backgroundColor: theme.colors.brand.lighter,
      borderColor: theme.colors.brand.lightest,
    },
    ":disabled ~ span": {
      cursor: "not-allowed",
      color: theme.colors.greyscale.lighter,
      borderColor: theme.colors.greyscale.lightest,
    },
  },
  checkboxControl: {
    backgroundColor: theme.colors.light.base,
    minWidth: theme.spacing.medium,
    height: theme.spacing.medium,
    border: `1px solid ${theme.colors.greyscale.lighter}`,
    borderRadius: "5px",
    boxShadow: `0px 0px 0px 1px ${theme.colors.light.base} inset`,
    outline: "none",

    ":before": {
      content: "''",
      position: "absolute",
      transform: "translate(50%, -150%)",
      width: "9px",
      height: "16px",
    },
    ":after": {
      content: "''",
      position: "relative",
      display: "block",
      left: "9px",
      top: "4px",
      width: "5px",
      height: "12px",
      border: `solid ${theme.colors.light.base}`,
      borderWidth: " 0 1px 1px 0",
      transform: "rotate(45deg)",
    },
  },
  error: {
    "> .checkbox-control": {
      borderColor: theme.colors.negative.base,
    },
  },
  success: {
    "> .checkbox-control": {
      borderColor: theme.colors.positive.base,
    },
  },
  inverted: {
    color: theme.colors.light.base,
    input: {
      ":checked + .checkbox-control": {
        backgroundColor: theme.colors.light.base,
        borderColor: theme.colors.light.base,
      },
    },
    "> .checkbox-control": {
      borderColor: theme.colors.light.base,
      backgoundColor: theme.colors.brand.base,
      boxShadow: `0px 0px 0px 1px ${theme.colors.brand.base} inset`,
      ":after": {
        borderColor: theme.colors.brand.base,
      },
    },
  },
});
