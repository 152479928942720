import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { instance } from "@clearabee/ui-sdk";
import { IOrderTypeWorksheetPriorityPatchBody } from "@clearabee/api-schemas";
import {
  Form,
  Field,
  Input,
  Panel,
  Button,
  Icon,
  Heading,
  Box,
} from "@clearabee/ui-library";
import { getCatalogueOrderTypes } from "api/catalogues";
import { useMutation, useQuery } from "react-query";
import { toasts } from "helpers/toasts";
import { ErrorMessage } from "components/common/components";
import { SearchIcon } from "images";
import { Item } from "./components/dragItem";
import { styles } from "./arrangeWorksheets.styles";
import { WorksheetDataType } from "./types";

export const ArrangeWorksheets = (): React.ReactElement => {
  const [translate] = useTranslation("worksheets");
  const [id, setId] = useState("");
  const [cards, setCards] = useState<WorksheetDataType>([]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const renderCard = useCallback(
    (card: WorksheetDataType[number], index: number) => {
      return (
        <Item key={card.id} index={index} template={card} moveCard={moveCard} />
      );
    },
    [],
  );

  const {
    data: orderTypes,
    isLoading: isLoadingOrderTypeIds,
    error,
    isFetching: isFetchingOrderTypeIds,
  } = useQuery(["readOrderTypeIDs"], () => getCatalogueOrderTypes(), {
    retry: 0,
    keepPreviousData: true,
    onError: () => {
      toasts.error({
        message: translate("arrange.errorReadTypeIds"),
      });
    },
  });

  const {
    data: worksheetsData,
    isSuccess,
    isLoading,
    refetch: refetchWorksheets,
    isFetching,
  } = useQuery(
    ["worksheetsById", id],
    async () => (await instance.worksheets.getWorksheetsByOrderTypeId(id)).data,
    {
      enabled: !!id,
      onSuccess: (data) => setCards(data),
      keepPreviousData: true,
    },
  );

  const orderTypeIdsOptions =
    orderTypes?.items?.flatMap(({ id, name }) => ({
      value: id ?? 0,
      label: name,
    })) ?? [];

  const { mutate, isLoading: mutationIsLoading } = useMutation(
    async (patchBody: IOrderTypeWorksheetPriorityPatchBody) => {
      await instance.worksheets.patchOrderTypeWorksheetPriority(id, patchBody);
    },
    {
      onSuccess: () => {
        toasts.success({
          message: translate("arrange.success"),
        });
        refetchWorksheets();
      },
      onError: () => {
        toasts.error({
          message: translate("arrange.error"),
        });
      },
    },
  );

  const updateCall = async () => {
    const patchBody = cards.map((item, index) => ({
      priority: index,
      worksheetId: item.id || 0,
    }));

    mutate(patchBody);
  };

  if (error) return <ErrorMessage />;

  if (isLoadingOrderTypeIds && isFetchingOrderTypeIds)
    return <Icon.Loading color="brand" styles={{ margin: "0 auto" }} />;

  return (
    <>
      <Panel styles={styles.panel}>
        <Form
          initialValues={{ id: "" }}
          styles={styles.form}
          onSubmit={({ id }) => setId(String(id))}
          isInitialValid={false}
        >
          {({ isValid }) => (
            <>
              <Field name="id" styles={{ flex: 1 }}>
                {({ field }) => (
                  <Input.Select
                    {...field}
                    isSearchable
                    options={orderTypeIdsOptions}
                  />
                )}
              </Field>
              <Button
                size="small"
                color="accent"
                className="inline-flex"
                type="submit"
                disabled={isLoading || isFetching || !isValid}
              >
                <SearchIcon className="mr-3" />
                {translate("arrange.searchButton")}
              </Button>

              {/* REMOVING THIS FOR NOW */}
              <Button
                size="small"
                color="accent"
                disabled={
                  JSON.stringify(worksheetsData) === JSON.stringify(cards) ||
                  !cards ||
                  !worksheetsData ||
                  mutationIsLoading
                }
                type="button"
                onClick={updateCall}
              >
                {translate("arrange.updateButton")}
              </Button>
            </>
          )}
        </Form>
      </Panel>
      <div>
        {(isLoading || isFetching || mutationIsLoading) && (
          <Box styles={styles.loadingContainer}>
            <Box styles={styles.loadingIconContainer}>
              <Icon.Loading size="xlarge4" color="brand" />
            </Box>
            <Box styles={styles.loadingBgContainer} />
          </Box>
        )}
        {!isLoading && isSuccess && !cards.length && (
          <Heading fontSize="base" styles={{ textAlign: "center" }}>
            {translate("arrange.noData")}
          </Heading>
        )}
        {!isLoading && !isFetching && !!cards.length && (
          <>
            <Heading fontSize="base" styles={{ textAlign: "center" }}>
              {translate("arrange.instruction")}
            </Heading>
            <DndProvider backend={HTML5Backend}>
              <div style={styles.cardsContainer}>
                {cards?.map((item, index) => renderCard(item, index))}
              </div>
            </DndProvider>
          </>
        )}
      </div>
    </>
  );
};
