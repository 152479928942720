import React, { useEffect } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayPicker, { DayPickerProps } from "react-day-picker";
import "./daypicker.css";

dayjs.extend(customParseFormat);

export interface BaseProps {
  firstDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

interface BaseInputDateProps
  extends Omit<DayPickerProps, "firstDayOfWeek">,
    BaseProps {
  onOutsideClick?: () => void;
  onReset?: () => void;
  dateRef: React.RefObject<DayPicker>;
  inputRef?: React.RefObject<HTMLInputElement>;
  multiMonth?: boolean;
}

export const defaultDateFormat = "DD/MM/YYYY";

export const setNativeValue = (
  element: HTMLInputElement,
  value: string,
): void => {
  Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(element),
    "value",
  )?.set?.call(element, value);
};

export const formatDate = (
  date: Date,
  dateFormat = defaultDateFormat,
): string => {
  return dayjs(date).format(dateFormat);
};

export const convertToDate = (
  date: string | undefined,
  dateFormat = defaultDateFormat,
): Date | undefined => {
  const response = dayjs(date, dateFormat);

  if (!response.isValid()) {
    return undefined;
  }

  return response.toDate();
};

export const BaseInputDate = ({
  onOutsideClick,
  onReset,
  dateRef,
  inputRef,
  multiMonth,
  firstDay,
  ...rest
}: BaseInputDateProps): React.ReactElement => {
  useEffect(() => {
    const formElement = inputRef?.current?.form;

    if (!formElement || !onReset) {
      return;
    }

    formElement.addEventListener("reset", onReset);
    return () => formElement.removeEventListener("reset", onReset);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as Node;

      if (!target || !dateRef.current || !inputRef?.current) {
        return;
      }

      if (dateRef.current.dayPicker.contains(target)) {
        event.preventDefault();
      }

      if (
        !dateRef.current.dayPicker.contains(target) &&
        !inputRef.current.contains(target)
      ) {
        onOutsideClick?.();
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <DayPicker
      {...rest}
      numberOfMonths={multiMonth ? 2 : 1}
      firstDayOfWeek={firstDay}
      ref={dateRef}
      classNames={{
        container: "UI-DayPicker",
        wrapper: "UI-DayPicker-wrapper",
        interactionDisabled: "UI-DayPicker--interactionDisabled",
        months: "UI-DayPicker-Months",
        month: "UI-DayPicker-Month",
        navBar: "UI-DayPicker-NavBar",
        navButtonPrev: "UI-DayPicker-NavButton UI-DayPicker-NavButton--prev",
        navButtonNext: "UI-DayPicker-NavButton UI-DayPicker-NavButton--next",
        navButtonInteractionDisabled:
          "UI-DayPicker-NavButton--interactionDisabled",
        caption: "UI-DayPicker-Caption",
        weekdays: "UI-DayPicker-Weekdays",
        weekdaysRow: "UI-DayPicker-WeekdaysRow",
        weekday: "UI-DayPicker-Weekday",
        body: "UI-DayPicker-Body",
        week: "UI-DayPicker-Week",
        weekNumber: "UI-DayPicker-WeekNumber",
        day: "UI-DayPicker-Day",
        footer: "UI-DayPicker-Footer",
        todayButton: "UI-DayPicker-TodayButton",
        today: "UI-DayPicker-Day--today",
        selected: "UI-DayPicker-Day--selected",
        disabled: "UI-DayPicker-Day--disabled",
        outside: "UI-DayPicker-Day--outside",
      }}
    />
  );
};
