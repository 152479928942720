import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import Yup from "yup";
import { User as UserSchema } from "@clearabee/api";
import {
  Button,
  theme,
  Form,
  Field,
  Input,
  Heading,
  Panel,
} from "@clearabee/ui-library";
import { SuccessModal } from "components/common/components";
import { toasts } from "helpers";
import { useInstance } from "api/dsa";
import { updateSubcontractor } from "./validation";

type FormValues = Yup.InferType<typeof updateSubcontractor>;

export const UpdateSubContractor = (): React.ReactElement => {
  const api = useInstance();
  const { push } = useHistory();
  const { username } = useParams<{ username: string }>();
  const [translate] = useTranslation("users");

  /**
   * Get subcontractor data to pre-fill the form and set initial values
   */
  const {
    data: result,
    refetch: refetchUserData,
    isFetched,
  } = useQuery(`get-${username}`, () => api.users.getUser(username));
  const userInfo: UserSchema | undefined = result?.data;

  const initialValues = {
    company: userInfo?.company || "",
    phone: userInfo?.phone || "",
    postcode: userInfo?.postcode || "",
    wc_license: userInfo?.wc_license || "",
    on_stop: userInfo?.on_stop === "true" ? true : false,
    email: "",
    username: "",
    status: "",
  };

  /**
   * UPDATE THE SUBCONTRACTOR with values provided
   * by the form
   */
  const {
    mutate: updateSubcontractorInfo,
    isLoading,
    isSuccess,
  } = useMutation(
    (values: Parameters<typeof api.users.update>[1]) =>
      api.users.update(username, values),
    {
      onError: () => {
        toasts.error({
          message: "Subcontractor info could not be updated",
        });
      },
      onSuccess: () => {
        refetchUserData();
      },
    },
  );

  const handleSubmit = async (values: FormValues) => {
    const valuesFromForm = { ...values, geolocation: "" };
    /**
     * A query to get coordinates from an address postcode
     */
    try {
      const postcodeData = await axios.get(
        `https://api.postcodes.io/postcodes?q=${encodeURI(values.postcode)}`,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
        },
      );
      const geolocation = {
        longitude: postcodeData.data.result[0].longitude,
        latitude: postcodeData.data.result[0].latitude,
      };
      /**
       * These coordinates are then added to the form values object,
       * and the user is created with a coordinate field attached
       */
      valuesFromForm.geolocation = JSON.stringify(geolocation);
    } catch (error) {
      toasts.error({
        message: translate("subcontractors.errors.couldNotGenerateCoordinates"),
      });
    }

    updateSubcontractorInfo({
      wc_license: valuesFromForm.wc_license,
      phone: valuesFromForm.phone,
      company: valuesFromForm.company,
      on_stop: String(valuesFromForm.on_stop),
      postcode: valuesFromForm.postcode,
      geolocation: valuesFromForm.geolocation,
    });
  };

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <Panel className="mb-8 flex flex-row justify-between">
        <Heading color="brand" level={4}>
          {translate("subcontractors.headings.updateSubcontractor")}
        </Heading>
      </Panel>

      {/* Form is pre-populated with user information, and only certain fields
      can be updated */}
      {isFetched && (
        <Form
          initialValues={initialValues}
          validationSchema={updateSubcontractor}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({}) => (
            <>
              <Panel className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <Field
                  name="username"
                  label={translate("subcontractors.labels.username")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={`${userInfo?.username} ${
                        userInfo?.on_stop === "true" ||
                        userInfo?.on_stop === undefined
                          ? `- ${translate(
                              "subcontractors.labels.disabled",
                            ).toUpperCase()}`
                          : `- ${translate(
                              "subcontractors.labels.enabled",
                            ).toUpperCase()}`
                      }`}
                      styles={{ marginBottom: theme.spacing.small }}
                      disabled
                    />
                  )}
                </Field>
                <Field
                  name="company"
                  label={translate("subcontractors.labels.company")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={userInfo?.company}
                      styles={{ marginBottom: theme.spacing.small }}
                    />
                  )}
                </Field>
                <Field
                  name="email"
                  label={translate("subcontractors.labels.email")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={userInfo?.email}
                      styles={{ marginBottom: theme.spacing.small }}
                      disabled
                    />
                  )}
                </Field>
                <Field
                  name="phone"
                  label={translate("subcontractors.labels.phone")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={userInfo?.phone}
                      styles={{ marginBottom: theme.spacing.small }}
                    />
                  )}
                </Field>
                <Field
                  name="postcode"
                  label={translate("subcontractors.labels.postcode")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={userInfo?.postcode}
                      styles={{ marginBottom: theme.spacing.small }}
                    />
                  )}
                </Field>
                <Field
                  name="wc_license"
                  label={translate("subcontractors.labels.wc_license")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={userInfo?.wc_license}
                      styles={{ marginBottom: theme.spacing.small }}
                    />
                  )}
                </Field>
                <Field
                  name="status"
                  label={translate("subcontractors.labels.status")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={userInfo?.status}
                      styles={{ marginBottom: theme.spacing.small }}
                      disabled
                    />
                  )}
                </Field>
                <Field
                  name="on_stop"
                  label={translate("subcontractors.labels.onStop")}
                  type="checkbox"
                >
                  {({ field }) => <Input.Toggle {...field} />}
                </Field>
              </Panel>
              <div className="w-full flex justify-center mt-8">
                <Button
                  className=""
                  type="submit"
                  color="accent"
                  size="large"
                  disabled={isLoading}
                >
                  {isLoading
                    ? translate("user.buttons.loading")
                    : translate("user.buttons.update")}
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
      <SuccessModal
        title={translate("user.modals.headings.subcontractorUpdated")}
        buttonOne={{
          label: translate("user.buttons.backToSubcontractors"),
          to: "/subcontractors",
        }}
        visible={isSuccess}
      />
    </div>
  );
};
