import React from "react";

type TSearch = {
  isLoading: boolean;
  query: string;
  setQuery: (newQuery: string) => void;
};

export const Search: React.FC<TSearch> = ({ isLoading, query, setQuery }) => {
  return (
    <div className="flex">
      <div className="w-full text-black">
        <label
          htmlFor="search"
          className="text-sm text-primary mb-2 block font-semibold"
        >
          Search by keyword
        </label>
        <input
          type="text"
          id="search"
          className="border border-primary px-4 py-2 rounded-md w-full bg-transparent"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search terms..."
          disabled={isLoading}
        />
      </div>
    </div>
  );
};
