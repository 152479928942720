import React, { useState } from "react";
import { Modal, ModalProps } from "../Modal";

export interface UseModalOptions {
  defaultVisible?: boolean;
}

export function useModal(
  options?: UseModalOptions,
): [
  (props: ModalProps) => React.ReactElement,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean,
] {
  const [modalVisible, setModalVisible] = useState(!!options?.defaultVisible);

  const ModalComponent = ({
    children,
    ...props
  }: ModalProps): React.ReactElement => {
    return (
      <Modal {...props} modalVisible={modalVisible}>
        {children}
      </Modal>
    );
  };

  return [ModalComponent, setModalVisible, modalVisible];
}
