/**
 * Import interfaces.
 */
import { TFileList, IFileUpload } from "./types";

/**
 * Generate Base64 string for files.
 */
export const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve: any) => {
    reader.addEventListener("load", () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

/**
 * Generate the image data list based on the uploaded files.
 */
export const generateFileList = (
  files: FileList,
  removeFile: (key: string) => void,
): Promise<TFileList> => {
  const promiseFiles: Array<Promise<string>> = [];

  for (let i = 0; i < files.length; i += 1) {
    promiseFiles.push(getBase64(files[i]));
  }

  return Promise.all(promiseFiles).then((fileListBase64: Array<string>) => {
    const fileList: TFileList = fileListBase64.map((base64, index) => {
      const key = `${new Date().getTime().toString()}-${files[index].name}`;
      return {
        dataUrl: base64,
        file: files[index],
        key,
        removeFile: (): void => removeFile(key),
      };
    });

    return fileList;
  });
};

/**
 * Actions to run when the data changes.
 */
export const onDataChange = (
  list: TFileList,
  onChange: IFileUpload["onChange"],
): void => {
  if (onChange) {
    const sData: TFileList = list.map((item) => ({
      file: item.file,
      dataUrl: item.dataUrl,
    }));

    onChange(sData);
  }
};
