import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import components.
 */
import { FormInput } from "../../../core";
import { useMultiFormContext } from "../../../../hooks";

/**
 * Import types.
 */
import { IWEEEFormState } from "../types";

export const ShowSelectedAddress: React.FC = () => {
  const [t] = useTranslation("jobs");

  /**
   * Multi form state.
   */
  const { formState } = useMultiFormContext<IWEEEFormState>();
  const { selectedAddress } = formState;

  return (
    <div className="bg-white p-6 lg:px-8 shadow-filter rounded-xl mb-8">
      <h3 className="text-2xl text-primary font-bold">
        {t("weeeKerbside.titles.weeeKerbside")}
      </h3>
      <div className="flex flex-wrap -mx-2 mt-6">
        <div className="w-full mb-4 px-2 lg:w-64">
          <FormInput
            name="postcode"
            label={{
              text: t("weeeKerbside.form.labels.postcode"),
            }}
            value={selectedAddress.postcode}
            floatingLabel={false}
            validate={false}
            disabled={true}
          />
        </div>
        <div className="w-full px-2 lg:w-1/2">
          <FormInput
            name="address"
            label={{
              text: t("weeeKerbside.form.labels.address"),
            }}
            value={`${selectedAddress.line1} ${selectedAddress.line2}`}
            floatingLabel={false}
            validate={false}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};
