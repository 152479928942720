import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { Modal, Field, Input, Button, theme } from "@clearabee/ui-library";
import { readCompanyUsersByCompanyCode } from "api/users";
import { QuoteResponseFormValues } from "./staffResponse";

export interface AcceptOnBehalfModal {
  companyCode: string | undefined;
  setVisible: (visible: boolean) => void;
}

export const AcceptOnBehalfModal = ({
  companyCode,
  setVisible,
}: AcceptOnBehalfModal): React.ReactElement => {
  const [translate] = useTranslation("quotes");
  const { setFieldValue, submitForm, isValid } =
    useFormikContext<QuoteResponseFormValues>();

  /**
   * Fetch company users.
   */
  const { data: companyUsers, isLoading: companyUsersIsLoading } = useQuery(
    ["readCompanyUsers", companyCode],
    () => readCompanyUsersByCompanyCode(companyCode || ""),
  );

  return (
    <Modal heading={translate("modals.headings.acceptOnBehalf")} width={400}>
      <Field
        name="companyUserEmail"
        label={translate("form.label.companyUserEmail")}
        styles={{
          textAlign: "left",
          margin: `${theme.spacing.medium} 0`,
        }}
      >
        {({ field }) => (
          <Input.Select
            {...field}
            isLoading={companyUsersIsLoading}
            placeholder={translate("form.placeholder.companyUserEmail")}
            options={
              companyUsers?.items.map(({ firstName, lastName, email }) => ({
                label: `${firstName} ${lastName}`,
                value: email,
              })) || []
            }
          />
        )}
      </Field>
      <Button
        size="medium"
        color="accent"
        className="mr-3"
        onClick={() => {
          setFieldValue("actionedOnBehalfOf", true);
          setFieldValue("status", "accepted");
          submitForm();
          isValid && setVisible(false);
        }}
      >
        {translate("form.buttons.acceptQuote")}
      </Button>
      <Button
        size="medium"
        color="negative"
        type="button"
        onClick={() => setVisible(false)}
      >
        {translate("common:close")}
      </Button>
    </Modal>
  );
};
