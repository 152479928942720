import React from "react";
import { Redirect } from "react-router-dom";
import i18n from "../translations/config";

/**
 * Import components.
 */
import {
  Login,
  ForgotPassword,
  ResetPassword,
  Welcome,
} from "../components/auth";
import {
  ReadJobs,
  CreateJob,
  UpdateJob,
  CreateWeeeKerbsideJob,
  CreateVTJob,
  AdditionalJobPayment,
  ReadPaymentLinks,
  UpdatePaymentLink,
  BulkCreateJobs,
  FailedJobs,
  PendingJobs,
  ApprovePendingJob,
} from "../components/jobs";
import {
  DriverAllocation,
  DriverCreate,
  DriverJobs,
  DriverNotifications,
} from "components/drivers";
import {
  ReadWorksheets,
  WorksheetOrderTypeValue,
  CreateWorksheet,
  UpdateWorksheet,
  ArrangeWorksheets,
} from "components/worksheets";
import {
  ReadCompanies,
  CreateCompany,
  UpdateCompany,
} from "../components/companies";
import {
  ReadSupplierTypes,
  CreateSupplierType,
  UpdateSupplierType,
  ReadSuppliers,
  CreateSupplier,
  UpdateSupplier,
} from "../components/suppliers";
import {
  ReadUsers,
  CreateUser,
  UpdateUser,
  ReadSubContractors,
  ReadSubcontractorJobs,
  CreateSubContractor,
  ReadSubContractorChildren,
  UpdateSubContractor,
} from "../components/users";
import {
  ApproveSubcontractors,
  Subcontractors,
} from "../components/subcontractors-new";
import { CreateQuote, ReadQuotes, UpdateQuote } from "../components/quotes";
import { ReportingDashboard, Statistics } from "../components/reporting";
import { Leaderboard } from "../components/leaderboard";
import { Dashboard } from "../components/dashboard";
import {
  Blackout,
  ReadCatalogues,
  UpdateCatalogue,
  CreateCatalogue,
  UpdateCatalogueItem,
  ReadTemplates,
  UpdateTemplate,
  CreateTemplate,
  CascadeTemplate,
  OrderTypeIDs,
  Zones,
} from "components/catalogues";
import { Permissions } from "components/permissions";
import { ApproveVehicles } from "components/vehicles";
import { BulkAllocation } from "../components/subcontractors";
import { Roles, CreateRole, UpdateRole } from "../components/roles";
import roles from "../constants/roles";

const {
  CLEARABEE_ADMIN,
  CLEARABEE_MANAGER,
  CLEARABEE_CUSTOMER_SERVICE: CUSTOMER_SERVICE,
  COMPANY_ADMIN,
  COMPANY_STAFF,
  BOOK_ONLY,
  VIEW_ONLY,
  Analyst,
  FINANCE_ANALYST,
} = roles;

/**
 * Types.
 */
interface IRoute {
  name: string;
  nicename: string;
  type: "public" | "private" | "standard";
  exact?: boolean;
  path: string;
  parent?: string;
  component: React.FC;
  permissions: string[] | [];
}

/**
 * i18n translation.
 */
const t = (string: string) => i18n.t(`common:routes.${string}`);

/**
 * Routes for the application.
 * - in src/components/app/app.tsx we loop through these
 * - use 'standard' as type for routes which are neither specifically public or private
 */
export const routes: IRoute[] = [
  {
    name: "auth",
    nicename: t("auth"),
    type: "public",
    exact: true,
    path: "/auth",
    component: () => <Redirect to={{ pathname: "/auth/login" }} />,
    permissions: [],
  },
  {
    name: "auth/login",
    nicename: t("auth/login"),
    type: "public",
    exact: true,
    path: "/auth/login",
    component: Login,
    permissions: [],
  },
  {
    name: "auth/forgot",
    nicename: t("auth/forgot"),
    type: "public",
    exact: true,
    path: "/auth/forgot",
    component: ForgotPassword,
    permissions: [],
  },
  {
    name: "auth/reset",
    nicename: t("auth/reset"),
    type: "public",
    path: "/auth/reset/:username/:code",
    component: ResetPassword,
    permissions: [],
  },
  {
    name: "auth/welcome",
    nicename: t("auth/welcome"),
    type: "public",
    path: "/auth/welcome",
    component: Welcome,
    permissions: [],
  },
  {
    name: "dashboard",
    nicename: t("dashboard"),
    type: "private",
    exact: true,
    path: "/",
    component: React.memo(Dashboard),
    permissions: [],
  },
  {
    name: "drivers/jobs",
    nicename: t("drivers/jobs"),
    type: "private",
    path: "/drivers/jobs",
    component: React.memo(DriverJobs),
    parent: "dashboard",
    exact: true,
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "drivers/notifications",
    nicename: t("drivers/notifications"),
    type: "private",
    path: "/drivers/notifications",
    component: React.memo(DriverNotifications),
    parent: "dashboard",
    exact: true,
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "drivers/create",
    nicename: t("drivers/create"),
    type: "private",
    path: "/drivers/create",
    component: React.memo(DriverCreate),
    parent: "dashboard",
    exact: true,
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "drivers/allocate",
    nicename: t("drivers/allocate"),
    type: "private",
    path: "/drivers/allocate",
    component: React.memo(DriverAllocation),
    parent: "dashboard",
    exact: true,
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "jobs/create",
    nicename: t("jobs/create"),
    type: "private",
    path: "/jobs/create",
    component: React.memo(CreateJob),
    parent: "jobs",
    exact: true,
    permissions: [
      COMPANY_ADMIN,
      COMPANY_STAFF,
      CLEARABEE_ADMIN,
      CUSTOMER_SERVICE,
      BOOK_ONLY,
    ],
  },
  {
    name: "jobs/create/weee-kerbside",
    nicename: t("jobs/create/weee-kerbside"),
    type: "private",
    path: "/jobs/create/weee-kerbside",
    component: React.memo(CreateWeeeKerbsideJob),
    parent: "jobs",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  // For now, we are only using the new booking flow, that begins
  // in book a job with the ADHOC toggle
  // {
  //   name: "jobs/create/adhoc",
  //   nicename: t("jobs/create/adhoc"),
  //   type: "private",
  //   path: "/jobs/create/adhoc",
  //   component: React.memo(CreateVTJob),
  //   parent: "jobs",
  //   permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  // },
  {
    name: "jobs/update",
    nicename: t("jobs/update"),
    type: "private",
    path: "/jobs/update/:id",
    component: React.memo(UpdateJob),
    parent: "jobs",
    permissions: [
      COMPANY_ADMIN,
      COMPANY_STAFF,
      CLEARABEE_ADMIN,
      CLEARABEE_MANAGER,
      CUSTOMER_SERVICE,
      VIEW_ONLY,
    ],
  },
  {
    name: "jobs/payment",
    nicename: t("jobs/payment"),
    type: "private",
    path: "/jobs/payment/:id?",
    component: React.memo(AdditionalJobPayment),
    parent: "jobs",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "jobs/payment-links/update",
    nicename: t("jobs/payment-links/update"),
    type: "private",
    path: "/jobs/payment-links/update/:token",
    component: React.memo(UpdatePaymentLink),
    parent: "jobs/payment-links",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "jobs/payment-links",
    nicename: t("jobs/payment-links"),
    type: "private",
    path: "/jobs/payment-links",
    component: React.memo(ReadPaymentLinks),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "jobs/book-multiple",
    nicename: t("jobs/book-multiple"),
    type: "private",
    path: "/jobs/book-multiple",
    component: React.memo(BulkCreateJobs),
    parent: "dashboard",
    permissions: [
      CLEARABEE_ADMIN,
      CUSTOMER_SERVICE,
      COMPANY_ADMIN,
      COMPANY_STAFF,
    ],
  },
  {
    name: "jobs/failed",
    nicename: t("jobs/failed"),
    type: "private",
    path: "/jobs/failed",
    component: React.memo(FailedJobs),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "jobs/pending/approve",
    nicename: t("jobs/pending/approve"),
    type: "private",
    path: "/jobs/pending/approve/:id",
    component: React.memo(ApprovePendingJob),
    parent: "jobs/pending",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "jobs/pending",
    nicename: t("jobs/pending"),
    type: "private",
    path: "/jobs/pending",
    component: React.memo(PendingJobs),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "jobs",
    nicename: t("jobs"),
    type: "private",
    path: "/jobs",
    component: React.memo(ReadJobs),
    parent: "dashboard",
    exact: true,
    permissions: [
      COMPANY_ADMIN,
      COMPANY_STAFF,
      CLEARABEE_ADMIN,
      CLEARABEE_MANAGER,
      CUSTOMER_SERVICE,
      VIEW_ONLY,
    ],
  },
  {
    name: "worksheets",
    nicename: t("worksheets"),
    type: "private",
    exact: true,
    path: "/worksheets",
    component: React.memo(ReadWorksheets),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "worksheets/create",
    nicename: t("worksheets/create"),
    type: "private",
    exact: true,
    path: "/worksheets/create",
    component: React.memo(CreateWorksheet),
    parent: "worksheets",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "worksheets/update",
    nicename: t("worksheets/update"),
    type: "private",
    exact: true,
    path: "/worksheets/update/:id",
    component: React.memo(UpdateWorksheet),
    parent: "worksheets",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "worksheets/arrange",
    nicename: t("worksheets/arrange"),
    type: "private",
    exact: true,
    path: "/worksheets/arrange",
    component: React.memo(ArrangeWorksheets),
    parent: "worksheets",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "worksheets/order-type-values",
    nicename: t("worksheets/order-type-values"),
    type: "private",
    exact: true,
    path: "/worksheets/order-type-values",
    component: React.memo(WorksheetOrderTypeValue),
    parent: "worksheets",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "users",
    nicename: t("users"),
    type: "private",
    exact: true,
    path: "/users",
    component: React.memo(ReadUsers),
    parent: "dashboard",
    permissions: [COMPANY_ADMIN, CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "users/create",
    nicename: t("users/create"),
    type: "private",
    exact: true,
    path: "/users/create",
    component: React.memo(CreateUser),
    parent: "users",
    permissions: [COMPANY_ADMIN, CLEARABEE_ADMIN],
  },
  {
    name: "users/update",
    nicename: t("users/update"),
    type: "private",
    path: "/users/update/:id",
    component: React.memo(UpdateUser),
    parent: "users",
    permissions: [COMPANY_ADMIN, CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "subcontractors-new",
    nicename: t("subcontractors-new"),
    type: "private",
    exact: true,
    path: "/subcontractors-new",
    component: React.memo(Subcontractors),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "subcontractors-new/approve",
    nicename: t("subcontractors-new/approve"),
    type: "private",
    path: "/subcontractors-new/approve",
    exact: true,
    component: React.memo(ApproveSubcontractors),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "subcontractors/create",
    nicename: t("subcontractors/create"),
    type: "private",
    path: "/subcontractors/create",
    component: React.memo(CreateSubContractor),
    exact: true,
    parent: "subcontractors",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "subcontractors/update",
    nicename: t("subcontractors/update"),
    type: "private",
    path: "/subcontractors/:username/update",
    component: React.memo(UpdateSubContractor),
    parent: "subcontractors",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "subcontractors/children",
    nicename: t("subcontractors/children"),
    type: "private",
    path: "/subcontractors/:username/children",
    component: React.memo(ReadSubContractorChildren),
    parent: "subcontractors",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "subcontractors/jobs",
    nicename: t("subcontractor/jobs"),
    type: "private",
    path: "/subcontractors/jobs",
    component: React.memo(ReadSubcontractorJobs),
    parent: "subcontractors",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "subcontractors/bulk-allocation",
    nicename: t("subcontractors/bulk-allocation"),
    type: "private",
    path: "/subcontractors/bulk-allocation",
    component: React.memo(BulkAllocation),
    parent: "subcontractors",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "subcontractors",
    nicename: t("subcontractors"),
    type: "private",
    path: "/subcontractors",
    component: React.memo(ReadSubContractors),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "reports",
    nicename: t("reports"),
    type: "private",
    exact: true,
    path: "/reports",
    component: () => <Redirect to="/" />,
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE, Analyst, FINANCE_ANALYST],
  },
  {
    name: "reports/statistics",
    nicename: t("reports/statistics"),
    type: "private",
    exact: true,
    path: "/reports/statistics",
    component: React.memo(Statistics),
    parent: "reports",
    permissions: [CLEARABEE_ADMIN, Analyst, FINANCE_ANALYST],
  },
  // uncomment when there are data from ms-reports, currently no data
  // {
  //   name: "reports/dashboard",
  //   nicename: t("reports/dashboard"),
  //   type: "private",
  //   exact: true,
  //   path: "/reports/dashboard",
  //   component: React.memo(ReportingDashboard),
  //   parent: "reports",
  //   permissions: [CLEARABEE_ADMIN],
  // },
  {
    name: "reports/leaderboard",
    nicename: t("reports/leaderboard"),
    type: "private",
    exact: true,
    path: "/reports/leaderboard",
    component: React.memo(Leaderboard),
    parent: "reports",
    permissions: [CLEARABEE_ADMIN, CUSTOMER_SERVICE],
  },
  {
    name: "quotes",
    nicename: t("quotes"),
    type: "private",
    path: "/quotes",
    component: React.memo(ReadQuotes),
    parent: "dashboard",
    exact: true,
    permissions: [
      // COMPANY_ADMIN,
      // COMPANY_STAFF,
      CLEARABEE_ADMIN,
      CUSTOMER_SERVICE,
    ],
  },
  {
    name: "quotes/create",
    nicename: t("quotes/create"),
    type: "private",
    path: "/quotes/create",
    component: React.memo(CreateQuote),
    parent: "quotes",
    exact: true,
    permissions: [
      // COMPANY_ADMIN,
      // COMPANY_STAFF,
      CLEARABEE_ADMIN,
      CUSTOMER_SERVICE,
    ],
  },
  {
    name: "quotes/update",
    nicename: t("quotes/update"),
    type: "private",
    path: "/quotes/update/:id",
    component: React.memo(UpdateQuote),
    parent: "quotes",
    exact: true,
    permissions: [
      // COMPANY_ADMIN,
      // COMPANY_STAFF,
      CLEARABEE_ADMIN,
      CUSTOMER_SERVICE,
    ],
  },
  {
    name: "companies/update",
    nicename: t("companies/update"),
    type: "private",
    exact: true,
    path: "/companies/update/:id",
    parent: "companies",
    component: React.memo(UpdateCompany),
    permissions: [CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "companies/create",
    nicename: t("companies/create"),
    type: "private",
    exact: true,
    path: "/companies/create",
    parent: "companies",
    component: React.memo(CreateCompany),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "suppliers",
    nicename: "View Suppliers",
    type: "private",
    exact: true,
    path: "/suppliers",
    parent: "dashboard",
    component: React.memo(ReadSuppliers),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "suppliers/create",
    nicename: "Create Supplier",
    type: "private",
    exact: true,
    path: "/suppliers/create",
    parent: "suppliers",
    component: React.memo(CreateSupplier),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "suppliers/update",
    nicename: "Edit Supplier",
    type: "private",
    exact: true,
    path: "/suppliers/update/:id",
    parent: "suppliers",
    component: React.memo(UpdateSupplier),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "suppliers/types",
    nicename: "View Supplier Types",
    type: "private",
    exact: true,
    path: "/suppliers/types",
    parent: "suppliers",
    component: React.memo(ReadSupplierTypes),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "suppliers/types/create",
    nicename: "Add A New Supplier Type",
    type: "private",
    exact: true,
    path: "/suppliers/types/create",
    parent: "suppliers/types",
    component: React.memo(CreateSupplierType),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "suppliers/types/update",
    nicename: "Update A Supplier Type",
    type: "private",
    exact: true,
    path: "/suppliers/types/update/:id",
    parent: "suppliers/types",
    component: React.memo(UpdateSupplierType),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "companies",
    nicename: t("companies"),
    type: "private",
    exact: true,
    path: "/companies",
    parent: "dashboard",
    component: React.memo(ReadCompanies),
    permissions: [CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "catalogues",
    nicename: t("catalogues"),
    type: "private",
    exact: true,
    path: "/catalogues",
    parent: "dashboard",
    component: React.memo(ReadCatalogues),
    permissions: [CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "catalogues/update",
    nicename: t("catalogues/update"),
    type: "private",
    exact: true,
    path: "/catalogues/update/:id",
    parent: "catalogues",
    component: React.memo(UpdateCatalogue),
    permissions: [CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "catalogues/update/item",
    nicename: t("catalogues/update/item"),
    type: "private",
    exact: true,
    path: "/catalogues/update/:id/:itemSku",
    parent: "catalogues/update",
    component: React.memo(UpdateCatalogueItem),
    permissions: [CLEARABEE_ADMIN, CLEARABEE_MANAGER],
  },
  {
    name: "catalogues/create",
    nicename: t("catalogues/create"),
    type: "private",
    exact: true,
    path: "/catalogues/create/",
    parent: "catalogues",
    component: React.memo(CreateCatalogue),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/templates",
    nicename: t("catalogues/templates"),
    type: "private",
    exact: true,
    path: "/catalogues/templates",
    parent: "catalogues",
    component: React.memo(ReadTemplates),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/templates/update",
    nicename: t("catalogues/templates/update"),
    type: "private",
    exact: true,
    path: "/catalogues/templates/update/:id",
    parent: "catalogues/templates",
    component: React.memo(UpdateTemplate),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/templates/cascade",
    nicename: t("catalogues/templates/cascade"),
    type: "private",
    exact: true,
    path: "/catalogues/templates/cascade/:id",
    parent: "catalogues/templates/update",
    component: React.memo(CascadeTemplate),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/templates/create",
    nicename: t("catalogues/templates/create"),
    type: "private",
    exact: true,
    path: "/catalogues/templates/create",
    parent: "catalogues/templates",
    component: React.memo(CreateTemplate),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/blackout",
    nicename: t("catalogues/blackout"),
    type: "private",
    exact: true,
    path: "/catalogues/blackout",
    parent: "catalogues",
    component: React.memo(Blackout),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/order-type-ids",
    nicename: t("catalogues/order-type-ids"),
    type: "private",
    exact: true,
    path: "/catalogues/order-type-ids",
    parent: "catalogues",
    component: React.memo(OrderTypeIDs),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "catalogues/zones",
    nicename: t("catalogues/zones"),
    type: "private",
    exact: true,
    path: "/catalogues/zones",
    parent: "catalogues",
    component: React.memo(Zones),
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "vehicles/approval",
    nicename: t("vehicles/approval"),
    type: "private",
    path: "/vehicles/approval",
    component: React.memo(ApproveVehicles),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "permissions",
    nicename: t("permissions"),
    type: "private",
    exact: true,
    path: "/permissions",
    component: React.memo(Permissions),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "roles",
    nicename: t("roles"),
    type: "private",
    path: "/roles",
    exact: true,
    component: React.memo(Roles),
    parent: "dashboard",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "roles/create",
    nicename: t("roles/create"),
    type: "private",
    path: "/roles/create",
    exact: true,
    component: React.memo(CreateRole),
    parent: "roles",
    permissions: [CLEARABEE_ADMIN],
  },
  {
    name: "roles/update",
    nicename: t("roles/update"),
    type: "private",
    path: "/roles/update/:id",
    component: React.memo(UpdateRole),
    parent: "roles",
    exact: true,
    permissions: [CLEARABEE_ADMIN],
  },
];

/**
 * Routes class.
 */
export class Routes {
  routes: any;

  /**
   * Set the routes into a class variable.
   */
  constructor() {
    this.routes = routes;
  }

  /**
   * Get the settings for a specific component.
   *
   * @param {string} route The name of the route as decided in the routes object.
   * @param {string} key? A config key within the routes object.
   *
   * @return {object/string/boolean} Config object or potentially string/boolean if key is supplied.
   */
  config(route: string, key?: string) {
    let config: any = "" || undefined;

    /**
     * Check route exists in object.
     */
    const routeKey = this.routes.filter((r: any) => {
      return r.name === route;
    })[0];

    if (typeof routeKey !== "undefined") {
      config = routeKey;

      /**
       * Check config key exists in object (if supplied).
       */
      if (key) {
        if (typeof routeKey[key] !== "undefined") {
          config = routeKey[key];
        } else {
          /**
           * If the 'key' was supplied but doesn't exist, return undefined.
           * - This is rather than returning the whole parent object
           */
          config = undefined;
        }
      }
    } else {
      config = undefined;
    }

    return config;
  }

  /**
   * Check if given path exists
   *
   * @param {string} path The path of the route as decided in the routes object.
   *
   * @return {boolean} If path exists.
   */
  hasPath(path: string) {
    /**
     * Check route exists in object.
     */
    const routeKey = this.routes.filter((r: any) => {
      return r.path === path;
    })[0];

    if (typeof routeKey !== "undefined") {
      return true;
    } else {
      return false;
    }
  }
}
