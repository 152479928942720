"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeClasses = void 0;
var mergeClasses = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes
        .filter(function (cla) {
        return typeof cla === "string";
    })
        .join(" ");
};
exports.mergeClasses = mergeClasses;
