import React, { useEffect, useState } from "react";
import { CSSInterpolation } from "@emotion/serialize";
import { QueryObserverResult, RefetchOptions, useQuery } from "react-query";
import { Pagination, PaginationOptions } from "@clearabee/ui-library";
import { IPaginatedResults } from "../api/types";

interface PaginatedQuery<DataType> {
  PaginationComponent: () => React.ReactElement;
  updateFilters: React.Dispatch<React.SetStateAction<string>>;
  paginatedData: DataType[];
  query: {
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    isSuccess: boolean;
    isPreviousData: boolean;
    refetch: (
      options?: RefetchOptions,
    ) => Promise<QueryObserverResult<IPaginatedResults<DataType>>>;
    error?: unknown;
  };
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export function usePaginatedQuery<DataType>(
  apiFn: (
    filters: string,
    page: number,
    limit: number,
  ) => Promise<IPaginatedResults<DataType>>,
  queryKey: string,
  initFilters = "",
  options = {} as PaginationOptions,
  paginationStyles?: Record<
    | "mainContainer"
    | "wrapper"
    | "carousel"
    | "stepButton"
    | "rowPerPageWrap"
    | "resultsDropdownWrap",
    CSSInterpolation
  >,
): PaginatedQuery<DataType> {
  const [filter, updateFilters] = useState(initFilters);
  const [currentPage, setCurrentPage] = useState(options.initialPage ?? 1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(
    options.initialResultSize ?? 10,
  );
  const resultOptions = options.resultOptions ?? [5, 10, 20];

  const recalculateTotalPages = (data: IPaginatedResults<DataType>): void => {
    setTotalPages(Math.ceil(data.pagination.total / resultsPerPage));
  };

  const { data, ...query } = useQuery(
    [queryKey, [filter, currentPage, resultsPerPage]],
    () => apiFn(filter, currentPage - 1, resultsPerPage),
    {
      keepPreviousData: true,
      onSuccess: (newData) => recalculateTotalPages(newData),
      enabled: options.enabled,
      cacheTime: options.cacheTime,
    },
  );
  useEffect(() => data && recalculateTotalPages(data), []);
  useEffect(
    () => setCurrentPage(options?.initialPage ?? 1),
    [filter, resultsPerPage],
  );

  const PaginationComponent = () => (
    <Pagination
      data-testid="pagination"
      onPrev={() => {
        currentPage !== 1 && setCurrentPage(currentPage - 1);
      }}
      onNext={() => {
        currentPage < (data?.pagination.total ?? 0) &&
          setCurrentPage(currentPage + 1);
      }}
      paginationState={{
        currentPage,
        totalPages,
        resultsPerPage,
        resultOptions,
      }}
      onPageChange={(pageNumber: number) => {
        if (
          Number(pageNumber) <= (data?.pagination.total ?? 0) &&
          Number(pageNumber) > 0
        ) {
          setCurrentPage(Number(pageNumber));
        }
      }}
      onResultsChange={(results: number) => setResultsPerPage(results)}
      userStyles={paginationStyles}
    />
  );

  return {
    PaginationComponent,
    updateFilters,
    paginatedData: data?.items ?? [],
    query,
    currentPage,
    setCurrentPage,
  };
}
