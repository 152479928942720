import { useState, useEffect } from "react";
import {
  removePixelValues,
  useResponsive as useResponsiveBase,
  Breakpoint,
} from "@clearabee/ui-library-base";
import { theme } from "../../theme";
import deepClone from "deep-clone";

export const useResponsive = () => {
  const [width, setWidth] = useState(global.innerWidth);

  const updatedScreens = deepClone(theme.screens);
  removePixelValues(updatedScreens);

  const updateWidth = (): void => {
    setWidth(global.innerWidth);
  };

  useEffect(() => {
    global.addEventListener("resize", updateWidth);
    return () => global.removeEventListener("resize", updateWidth);
  }, []);

  return useResponsiveBase(
    width,
    updatedScreens as unknown as Record<Breakpoint, number>,
  );
};
