import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Form, Button, Field, Input } from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { SearchIcon } from "images";
import { buildQuery } from "helpers/api";
import { TFilters } from "api/types";

const initialValues = {
  status: "",
  orderRef: "",
  postcode: "",
  jobDate: "",
  email: "",
  createdDate: "",
};

const getParamsFromValues = (values: typeof initialValues) => {
  const { status, postcode, jobDate, createdDate, orderRef, email } = values;
  const [jobDateStart, jobDateEnd] = jobDate.split(" - ");
  const [createdDateStart, createdDateEnd] = createdDate.split(" - ");

  const params: Record<string, string> = {
    "status:eq": status,
    "postcode:likeLower": `%${postcode.replace(" ", "")}%`,
    "email:likeLower": `%${email.replace(" ", "")}%`,
    "orderRef:likeLower": `%${orderRef}%`,
    "date:gte": jobDateStart
      ? dayjs(dayjs(jobDateStart, "DD/MM/YYYY"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "date:lte": jobDateEnd
      ? dayjs(dayjs(jobDateEnd, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : jobDateStart
      ? dayjs(dayjs(jobDateStart, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "createdOn:gte": createdDateStart
      ? dayjs(dayjs(createdDateStart, "DD/MM/YYYY"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "createdOn:lte": createdDateEnd
      ? dayjs(dayjs(createdDateEnd, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : createdDateStart
      ? dayjs(dayjs(createdDateStart, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    ...defaultParams,
  };

  return buildQuery(params);
};

interface PaymentFiltersProps {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
}

const defaultParams = {
  orderByDesc: "createdOn",
};

export const PaymentLinksFilters = ({
  isFetching,
  updateFilters,
}: PaymentFiltersProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const clearFilters = () => {
    updateFilters(buildQuery(defaultParams));
  };
  useEffect(() => clearFilters(), []);

  const statusOptions = ["all", "pending", "paid", "expired"].map((value) => ({
    label: translate(`payments.filters.status.${value}`),
    value: value === "all" ? "" : value,
  }));

  const handleSubmit = async (values: typeof initialValues) => {
    const queryParams = getParamsFromValues(values);
    updateFilters(queryParams);
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <div className="bg-white p-6 pb-4 lg:pt-8 lg:pb-6 lg:px-8 shadow-filter rounded-xl mb-8">
        <div className="flex flex-col sm:flex-row gap-3 sm:border-b-0">
          <h3 className="text-xl text-primary font-bold mr-auto mb-3 sm:mb-0">
            {translate("payments.title")}
          </h3>
          <Button
            variant="outline"
            color="negative"
            size="small"
            type="reset"
            onClick={clearFilters}
          >
            {translate("filters.buttons.reset")}
          </Button>
          <RouteLink href="/jobs/create">
            <Button
              as="a"
              className="text-center"
              variant="outline"
              color="accent"
              size="small"
            >
              {translate("filters.buttons.bookAJob")}
            </Button>
          </RouteLink>
        </div>
        <div className="border-t border-grey-200 mt-3 pt-5 flex flex-row -mx-2">
          <div className="flex flex-col sm:flex-row flex-1">
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="status" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Select
                    options={statusOptions}
                    placeholder={translate("form.placeholder.status")}
                    disabled={isFetching}
                    isSearchable
                    defaultValue={statusOptions[0].value}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="email" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Text
                    placeholder={translate(
                      "payments.filters.placeholder.email",
                    )}
                    disabled={isFetching}
                    {...field}
                  />
                )}
              </Field>
            </div>

            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="jobDate" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.RangedDate
                    acceptSingleDate
                    placeholder={translate(
                      "payments.filters.placeholder.jobDate",
                    )}
                    disabled={isFetching}
                    disabledDays={{
                      before: new Date(),
                    }}
                    collapsable
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="createdDate" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.RangedDate
                    acceptSingleDate
                    placeholder={translate(
                      "payments.filters.placeholder.createdDate",
                    )}
                    disabled={isFetching}
                    disabledDays={{
                      after: new Date(),
                    }}
                    collapsable
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="postcode" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Text
                    placeholder={translate(
                      "payments.filters.placeholder.postcode",
                    )}
                    disabled={isFetching}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="orderRef" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Text
                    placeholder={translate(
                      "payments.filters.placeholder.orderRef",
                    )}
                    disabled={isFetching}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Button
                id="submitPaymentLinkFilters"
                type="submit"
                disabled={isFetching}
                color="accent"
                size="small"
                className="w-full flex justify-center items-center"
              >
                <SearchIcon className="mr-2 -mt-1" />
                {translate("form.label.search")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
