import React from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { instance, ApiResponseData } from "@clearabee/ui-sdk";
import { ItemFormValues } from "./createUpdateItemForm";
import {
  Box,
  Field,
  Message,
  displayErrorMessage,
  theme,
} from "@clearabee/ui-library";

interface EditZoneOptionProps {
  values: ItemFormValues;
}

type GetZonesProps = ApiResponseData<typeof instance.catalogues.getZones>;

type OptionsProps = {
  label: string;
  value: string;
};

export const EditZoneOption = ({
  values,
}: EditZoneOptionProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");

  const { setFieldValue } = useFormikContext<{
    zones: GetZonesProps;
  }>();

  const {
    data: allZones,
    isLoading: isLoadingZones,
    isError: isErrorZones,
  } = useQuery(
    "getAllZones",
    async () => (await instance.catalogues.getZones()).data,
    {
      refetchOnWindowFocus: false,
    },
  );

  const parseZonesData = (zones: GetZonesProps): OptionsProps[] => {
    if (!zones?.length) return [];

    return zones?.map(({ zone, ...rest }) => ({
      label: zone,
      value: JSON.stringify(rest),
    }));
  };

  return (
    <Box>
      <Field styles={{ width: "100%" }} name="zones" label="Zones">
        {({ field }) => (
          <Select
            {...field}
            value={parseZonesData(values.zones ?? [])}
            isSearchable
            isClearable
            isMulti
            disabled={isLoadingZones}
            placeholder="Select zones"
            options={parseZonesData(allZones ?? [])}
            className="text-base"
            onChange={(selectedZones: OptionsProps[]) => {
              if (!selectedZones?.length) return setFieldValue("zones", []);

              setFieldValue(
                "zones",
                selectedZones.map(({ label, value }) => ({
                  ...JSON.parse(value),
                  zone: label,
                })),
              );
            }}
            styles={{
              control: (base) => ({
                ...base,
                ...theme.fontDefaults.small,
                minHeight: theme.spacing.xlarge2,
                alignItems: "center",
                justifyContent: "center",
              }),
            }}
          />
        )}
      </Field>
      {/* Display Errors*/}
      {isErrorZones && (
        <Box className="w-full mt-5">
          {displayErrorMessage(
            translate("errors.zonesData"),
            ({ children }) => (
              <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                <Message type="error" background>
                  {children}
                </Message>
              </Box>
            ),
          )}
        </Box>
      )}
    </Box>
  );
};
