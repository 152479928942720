import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import form input component.
 */
import { FormInput } from "../../core";

export const InputEmail = () => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("auth");

  return (
    <div className="mb-4">
      <FormInput
        name="email"
        type="email"
        placeholder={t("form.placeholder.emailAddress")}
        label={{
          text: t("form.label.emailAddress"),
        }}
        required
      />
    </div>
  );
};
