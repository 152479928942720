/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Plus: React.FC<TGenericImage> = ({
  className = "plus-icon",
  fill = "currentColor",
  width = 9,
  height = 9,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 9"
    >
      <path
        fill={fill}
        d="M7.81307619,3.48710924 L7.81354659,3.48710924 L4.77852541,3.48710924 L4.77852541,0.457800058 C4.77852541,0.208488026 4.57665658,3.36000043e-05 4.32734455,3.36000043e-05 L3.9389957,3.36000043e-05 C3.68975087,3.36000043e-05 3.48700844,0.208488026 3.48700844,0.457800058 L3.48700844,3.48710924 L0.452054457,3.48710924 C0.202876826,3.48710924 0,3.68870927 0,3.9380885 L0,4.32865495 C0,4.57783258 0.202809626,4.77855901 0.452054457,4.77855901 L3.48707564,4.77855901 L3.48707564,7.81882179 C3.48707564,8.06799942 3.68975087,8.26556745 3.9390629,8.26556745 L4.32741175,8.26556745 C4.57672378,8.26556745 4.77859261,8.06793222 4.77859261,7.81882179 L4.77859261,4.77855901 L7.81307619,4.77855901 C8.06238822,4.77855901 8.26560105,4.57776538 8.26560105,4.32865495 L8.26560105,3.9380885 C8.26560105,3.68870927 8.06238822,3.48710924 7.81307619,3.48710924 Z"
      />
    </svg>
  );
};
