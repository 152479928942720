import { Template } from "models/template";
import { ItemForValuesWithUploadedImage } from "components/catalogues/components/createUpdateItemForm";

export const parseFormData = ({
  price,
  pricingModel,
  ...values
}: ItemForValuesWithUploadedImage): Template => {
  const blackoutDays: number[] = [];
  for (let i = 0; i < values.blackoutDays.length; i++) {
    if (values.blackoutDays[i] === true) {
      blackoutDays.push(i);
    }
  }

  const { meta } = values;

  const data: Template = {
    ...values,
    orderTypeId: values.orderTypeId ? values.orderTypeId : null,
    sku: values.sku || "",
    cutoffTime: values.cutoffTime || null,
    leadTime: !values.leadTime ? null : parseInt(values.leadTime),
    unitTime: values.unitTime || null,
    baseTime: values.baseTime || null,
    multiplier: values.multiplier || null,
    supplierMargin: !values.supplierMargin
      ? null
      : parseInt(values.supplierMargin),
    blackoutDays: blackoutDays.length ? blackoutDays : null,
    blackoutDates:
      values.blackoutDates?.[0] !== "" ? values.blackoutDates : null,
    meta: {
      friendlyTitle: meta?.friendlyTitle || null,
      annotation: meta?.annotation || null,
      serviceType: meta?.serviceType || null,
      fromPrice: meta?.fromPrice || null,
      itemCount: meta?.itemCount || null,
      priority: meta?.priority || null,
      yards: meta?.yards || null,
      volume: meta?.volume || null,
      size: meta?.size || null,
      weight: meta?.weight || null,
      popular: meta?.popular ? "true" : null,
    },
  };

  // don't include price in request body if not product with pricing model override
  if (
    price !== "" &&
    pricingModel === "override" &&
    values.type === "product"
  ) {
    data.price = Number(price);
  } else if (
    price === "" &&
    pricingModel === "override" &&
    values.type === "product"
  ) {
    data.price = 0;
  }

  // if type is category don't add pricing model
  if (values.type !== "category" && pricingModel) {
    data.pricingModel = pricingModel;
  }
  return data;
};
