import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { Input } from "@clearabee/ui-library";
import { ISupplier } from "components/suppliers/types";

export const UserFiltersSupplier = ({
  ...field
}: React.InputHTMLAttributes<HTMLSelectElement>): React.ReactElement => {
  const [translate] = useTranslation("users");

  const [suppliersOptions, setSuppliersOptions] = useState<
    { label: string; value: number }[]
  >([]);

  // const { data: suppliersForOptions, isLoading: isLoadingSuppliers } = useQuery(
  //   "readSuppliersForUserFilter",
  //   () => instance.users.getSuppliers(),
  // );

  // useEffect(() => {
  //   if (suppliersForOptions) {
  //     const filteredSuppliersuppliers = suppliersForOptions.data.items.map(
  //       (supplier: ISupplier) => {
  //         return {
  //           label: supplier.name,
  //           value: supplier.id,
  //         };
  //       },
  //     );

  //     if (filteredSuppliersuppliers.length > 0) {
  //       setSuppliersOptions(filteredSuppliersuppliers);
  //     }
  //   }
  // }, [suppliersForOptions]);

  return (
    <Input.Select
      {...field}
      placeholder={translate("user.form.placeholders.searchSupplier")}
      options={suppliersOptions}
      // disabled={isLoadingSuppliers}
      // isLoading={isLoadingSuppliers}
      isSearchable
    />
  );
};
