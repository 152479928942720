"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.healthCheck = void 0;
exports.healthCheck = {
    headings: {
        driverHealthCheck: "Driver Health Check",
        email: "Email",
        sortBy: "Sort By",
        queueLength: "Queue Length",
        deviceBrand: "Device Brand",
        lastUpdatedAt: "Last Updated",
        healthCheck: "Health Check",
        queue: "Queue",
    },
    placeholders: {
        email: "Enter your email",
        sortBy: "Select an option",
    },
    buttons: {
        view: "View",
        reset: "Reset",
    },
    success: {
        copiedToClipboard: "Copied to clipboard!",
    },
    errors: {
        undefinedQueueValue: "Cannot copy to clipboard regarding this type queue",
    },
};
