import React, { useContext, useMemo, useState } from "react";
import {
  Panel,
  Form,
  Heading,
  theme,
  Button,
  Field,
  Input,
  Pill,
  Icon,
  Loader,
  Modal,
  formatCurrency,
  Message,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import clipboard from "clipboard-copy";
import { useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import { editQuote, getCatalogueByCompanyCode } from "api";
import { QuoteContext, statusComponents } from "components/quotes/updateQuote";
import { RouteLink } from "components/core";
import { css } from "@emotion/react";
import { v4 } from "uuid";
import dayjs from "dayjs";
import { QuotePatchBody } from "api/types";
import { Product } from "models/job";
import { toasts } from "helpers";
import { getValidationSchema } from "./validation";
import { AcceptOnBehalfModal } from "./acceptOnBehalfModal";
import { TotalsPanel } from "..";
import { getProductsFromCatalogue } from "helpers/getProductsFromCatalogue";

const calculateValidUntilDate = (collectionDate: string) => {
  const now = dayjs();
  if (now.add(7, "days").isBefore(dayjs(collectionDate)))
    return now.add(7, "days").format("DD/MM/YYYY");

  return dayjs(collectionDate).format("DD/MM/YYYY");
};

const timeOptions = Array(48)
  .fill(false)
  .map((_, index) =>
    dayjs()
      .startOf("day")
      .add(0.5 * index, "hour")
      .format("HH:mm"),
  );

const blankProduct = {
  price: 0,
  title: "",
  sku: "",
  qty: 0,
  key: v4(),
};

export interface QuoteFormProduct extends Product {
  key: string;
}

export interface QuoteResponseFormValues extends QuotePatchBody {
  products: QuoteFormProduct[];
  validUntilTime: string;
}

export const StaffResponse = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [translate] = useTranslation("quotes");
  const [acceptOnBehalf, setAcceptOnBehalf] = useState(false);
  const [commentsRequired, setCommentsRequired] = useState(false);
  const { data: quoteData, refetch } = useContext(QuoteContext);
  const {
    validUntil,
    date,
    comments,
    status,
    companyCode,
    products,
    quoteRef,
  } = quoteData;
  const disabled = status !== "open";

  /**
   * Accept or decline quote.
   */
  const { mutate, isLoading, isSuccess } = useMutation(
    (values: QuotePatchBody) => editQuote(id, values),
    {
      onError: () => {
        toasts.error({
          message: translate("errors.quoteUpdate"),
        });
      },
    },
  );

  /**
   * Fetch services.
   */
  const {
    data,
    isLoading: isCompanyCatalogueLoading,
    isError,
  } = useQuery(
    ["companyCatalogueServices", companyCode],
    () => getCatalogueByCompanyCode(companyCode ?? ""),
    {
      enabled: !!companyCode,
      retry: false,
    },
  );

  const availableProducts = getProductsFromCatalogue(data?.items ?? []);

  const initialValidUntil: string = useMemo(() => {
    if (validUntil) return dayjs(validUntil).format("DD/MM/YYYY");
    return calculateValidUntilDate(date);
  }, [validUntil, date]);

  const initialValues: QuoteResponseFormValues = {
    products: !products?.length
      ? [blankProduct]
      : products.map((product) => ({ ...product, key: v4(), serviceType: "" })),
    comments: comments || "",
    validUntil: initialValidUntil,
    validUntilTime: validUntil ? dayjs(validUntil).format("HH:mm") : "12:00",
    status: "open",
    companyUserEmail: "",
    actionedOnBehalfOf: false,
  };

  const handleSubmit = ({
    validUntilTime,
    ...values
  }: QuoteResponseFormValues) => {
    values.validUntil = dayjs(
      `${values.validUntil} ${validUntilTime}`,
      "DD/MM/YYYY HH:mm",
    ).toISOString();
    if (!values.actionedOnBehalfOf) delete values.companyUserEmail;
    mutate({
      ...values,
      products:
        values.status === "quoted"
          ? values.products.map(({ key, ...product }) => product)
          : undefined,
    });
  };

  const handleClipboard = async ({
    actionedOnBehalfOf,
    companyUserEmail,
    status,
    products,
    ...values
  }: QuoteResponseFormValues) => {
    let clipboardValue = "";

    if (products.length) {
      clipboardValue += "Products:";
      products.forEach(({ title, price, qty }) => {
        clipboardValue += `\n${title} x ${qty} - ${formatCurrency(
          price * qty,
        )}`;
      });
      clipboardValue += `\n${translate("common:total")}: ${formatCurrency(
        products.reduce((final, { qty, price }) => final + qty * price, 0),
      )}`;
    }
    clipboardValue +=
      "\n" +
      Object.entries(values)
        .map(
          ([field, value]) =>
            `${translate(`form.label.${field}`)}: ${value || ""}`,
        )
        .join("\n");

    try {
      await clipboard(clipboardValue);
      toasts.success({ message: translate("success.clipboard") });
    } catch {
      toasts.error({ message: translate("errors.clipboard") });
    }
  };

  if (isCompanyCatalogueLoading) {
    return (
      <Icon.Loading
        color="brand"
        styles={{ margin: `${theme.spacing.xlarge} auto` }}
      />
    );
  }

  if (!data || isError) {
    return (
      <Message
        styles={{
          marginBottom: theme.spacing.small,
        }}
        type="error"
        background
      >
        {translate("errors.noCatalogue")}
        {quoteData.companyId && (
          <RouteLink href={`/companies/update/${quoteData.companyId}`}>
            <Button
              as="a"
              styles={{
                marginLeft: theme.spacing.small,
                textAlign: "center",
                display: "inline-block",
              }}
              size="small"
            >
              {translate("form.placeholder.viewCompany")}
            </Button>
          </RouteLink>
        )}
      </Message>
    );
  }
  return (
    <Form
      initialValues={initialValues}
      validationSchema={getValidationSchema(commentsRequired, acceptOnBehalf)}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <>
          <Panel className="mb-10 relative" shadow={false}>
            <div className="flex">
              <Heading level={5} styles={{ color: theme.colors.brand.base }}>
                {translate("title.respondToQuote")}
              </Heading>
              <div className="ml-2">{statusComponents[quoteData.status]}</div>
              <Button
                type="button"
                color="accent"
                className="ml-auto"
                size="medium"
                variant="outline"
                onClick={() => handleClipboard(values)}
                styles={{
                  minWidth: 0,
                }}
              >
                <Icon.Clipboard className="h-5 w-5" />
              </Button>
              <Button
                size="medium"
                variant="outline"
                className="ml-3"
                disabled={disabled}
                onClick={() => {
                  setFieldValue("products", [...values.products, blankProduct]);
                }}
              >
                {translate("form.label.addProduct")}
              </Button>
            </div>
            {!disabled &&
              values.products.map(({ key }, index) => (
                <div className="flex -mx-2" key={key}>
                  <div className="px-2" css={css({ width: "10%" })}>
                    <Field
                      name={`products[${index}].price`}
                      label={translate("form.label.price")}
                    >
                      {({ field }) => (
                        <Input.Text
                          prefix="£"
                          {...field}
                          type="number"
                          inputMode="numeric"
                          disabled={disabled}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="px-2 w-1/5">
                    <Field
                      name={`products[${index}].sku`}
                      label={translate("form.label.product")}
                    >
                      {({ field }) => (
                        <Input.Select
                          options={availableProducts.map(({ sku, title }) => ({
                            label: title,
                            value: sku,
                          }))}
                          {...field}
                          isSearchable
                          disabled={disabled}
                          isLoading={isCompanyCatalogueLoading}
                          onChange={(e) => {
                            field.onChange(e);
                            const product = availableProducts.find(
                              ({ sku }) => sku === e.target.value,
                            );
                            setFieldValue(
                              `products[${index}].price`,
                              product?.price,
                            );
                            setFieldValue(
                              `products[${index}].title`,
                              product?.title,
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="px-2" css={css({ width: "10%" })}>
                    <Field
                      name={`products[${index}].qty`}
                      label={translate("form.label.qty")}
                      type="number"
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          type="number"
                          disabled={disabled}
                          inputMode="numeric"
                        />
                      )}
                    </Field>
                  </div>
                  {values.products[index].price &&
                  values.products[index].qty ? (
                    <div className="my-auto mb-6">
                      <Pill>
                        {`£ ${
                          values.products[index].price *
                          values.products[index].qty
                        }`}
                      </Pill>
                    </div>
                  ) : null}
                  {!disabled && values.products && values.products.length > 1 && (
                    <div className="ml-3 mb-5 my-auto">
                      <Button
                        type="button"
                        color="negative"
                        size="small"
                        styles={{ minWidth: 0, padding: 10 }}
                        disabled={disabled}
                        onClick={() => {
                          const products = [...values.products];
                          products.splice(index, 1);
                          setFieldValue("products", products);
                        }}
                      >
                        <Icon.Close className="w-4 h-4" />
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            {disabled && (
              <TotalsPanel
                products={values.products || []}
                quoteRef={quoteRef}
              />
            )}
            <div className="pt-2 mt-3 border-t border-gray-300 flex -mx-2">
              <div className="w-1/5 px-2">
                <Field
                  name="validUntil"
                  label={translate("form.label.validUntil")}
                >
                  {({ field }) => (
                    <Input.Date
                      {...field}
                      disabled={!!validUntil || disabled}
                      initialValue={initialValues.validUntil}
                      disabledDays={{
                        after: dayjs(quoteData.date).toDate(),
                        before: new Date(),
                      }}
                      placeholder={translate("form.placeholder.validUntil")}
                      collapsable
                    />
                  )}
                </Field>
              </div>
              <div className="w-1/5 px-2">
                <Field
                  name="validUntilTime"
                  label={translate("form.label.validUntilTime")}
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={initialValues.validUntilTime}
                      disabled={!!validUntil || disabled}
                      options={timeOptions.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                    />
                  )}
                </Field>
              </div>
              <div className="w-3/5 px-2">
                <Field name="comments" label={translate("form.label.comments")}>
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      placeholder={translate("form.placeholder.comments")}
                    />
                  )}
                </Field>
              </div>
            </div>
            {isLoading && (
              <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-white bg-opacity-75">
                <Loader color="brand" text={translate("common:loading2")} />
              </div>
            )}
          </Panel>
          <div className="flex justify-end mb-10">
            <Button
              color="brand"
              size="medium"
              className="mr-3"
              disabled={isLoading}
              type="button"
              onClick={() => {
                setCommentsRequired(true);
                setTimeout(() => {
                  setFieldTouched("comments", true);
                }, 0);
                values.comments && setAcceptOnBehalf(true);
              }}
            >
              {translate("form.buttons.acceptOnBehalf")}
            </Button>
            <Button
              color="negative"
              size="medium"
              className="mr-3"
              disabled={isLoading || disabled}
              type="button"
              onClick={() => {
                setFieldValue("status", "rejected");
                mutate({ status: "rejected" });
              }}
            >
              {translate("form.buttons.rejectQuote")}
            </Button>
            <Button
              color="accent"
              size="medium"
              disabled={isLoading || disabled}
              onClick={() => {
                setFieldValue("status", "quoted");
              }}
            >
              {translate("form.buttons.submitQuote")}
            </Button>
          </div>
          {isSuccess && (
            <Modal
              heading={translate("modals.headings.updateSuccess")}
              width={400}
            >
              <RouteLink href="/quotes">
                <Button as="a" className="inline-block mt-10">
                  {translate("modals.buttons.backToQuotes")}
                </Button>
              </RouteLink>
              <div>
                <Button
                  className="mt-3 inline-block"
                  as="a"
                  color="accent"
                  onClick={refetch}
                >
                  {translate("modals.buttons.viewQuote")}
                </Button>
              </div>
            </Modal>
          )}
          {acceptOnBehalf && (
            <AcceptOnBehalfModal
              setVisible={setAcceptOnBehalf}
              companyCode={companyCode}
            />
          )}
        </>
      )}
    </Form>
  );
};
