export function compareObjects<T>(newObject: T, initialObject: T): Partial<T> {
  return (
    Object.keys(newObject as Record<string, unknown>) as (keyof T)[]
  ).reduce<Partial<T>>((modifiedFields, key) => {
    const formField = newObject[key] ?? "";
    const initialField = initialObject[key] ?? "";

    // handle simple strict comparison
    if (formField === initialField) {
      return modifiedFields;
    }

    // compare each element of an array
    if (
      Array.isArray(formField) &&
      Array.isArray(initialField) &&
      formField.every((element, index) => element === initialField[index])
    ) {
      return modifiedFields;
    }

    // loop through each object with recursion
    if (typeof formField === "object" && !Array.isArray(formField)) {
      const modifiedObject = compareObjects(formField, initialField);

      return Object.keys(modifiedObject).length !== 0
        ? {
            ...modifiedFields,
            [key]: modifiedObject,
          }
        : modifiedFields;
    }

    return { ...modifiedFields, [key]: formField };
  }, {});
}
