import React, { Component, ErrorInfo, ReactNode } from "react";
import { displayErrorMessage } from "@clearabee/ui-library-base";
import {
  DefaultErrorComponent,
  DefaultErrorComponentProps,
} from "./DefaultErrorComponent";

interface Props {
  children: ReactNode;
  onError?: (error: unknown) => void;
  message?: ReactNode;
  FallbackComponent?: (props: {
    children: string;
    onRetry: () => void;
  }) => React.ReactElement;
  styles?: DefaultErrorComponentProps["styles"];
}

interface State {
  hasError: boolean;
  message: string;
  key: number;
}
/**
 * @param FallbackComponent - Accepts a functional component with children and onRetry as props
 */
export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    message: "",
    key: 0,
  };

  public static getDerivedStateFromError(): State {
    return {
      hasError: true,
      message: "",
      key: 0,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
    this.props?.onError?.(error);
    this.setState({ message: errorInfo.componentStack });
  }

  public render(): React.ReactNode {
    const { FallbackComponent } = this.props;
    if (this.state.hasError && FallbackComponent) {
      return (
        <React.Fragment key={this.state.key}>
          {displayErrorMessage(this.state.message, ({ children }) => (
            <FallbackComponent
              onRetry={() => {
                this.setState({
                  hasError: false,
                  key: this.state.key + 1,
                });
              }}
            >
              {children}
            </FallbackComponent>
          ))}
        </React.Fragment>
      );
    }

    if (this.state.hasError) {
      return displayErrorMessage(this.state.message, ({ children }) => (
        <DefaultErrorComponent
          styles={this.props.styles}
          onRetry={() => {
            this.setState({
              hasError: false,
              key: this.state.key + 1,
            });
          }}
          message={this.state.message}
        >
          {children}
        </DefaultErrorComponent>
      ));
    }

    return this.props.children;
  }
}
