import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

/**
 * import components.
 */
import { Transition, HavePermission } from "../../core";

import { Routes } from "../../../helpers/routes";

/**
 * Import images.
 */
import { SingleArrow, CloseIcon, BeeLogo } from "../../../images";

/**
 * Import hooks.
 */
import { useScreenWidth } from "../../../hooks";
import { styles } from "./submenu.styles";

/**
 * Submenu type
 */
type SubmenuType = {
  menu: Menu;
  toggle: boolean;
  hideCallback: () => void;
};

type Menu = {
  title: string;
  items: MenuItem[];
};

type MenuItem = {
  name: string;
  nicename: string;
  to: string;
  exact?: boolean;
  target?: string;
};

const rt = new Routes();

export const Submenu: React.FC<SubmenuType> = ({
  menu,
  toggle,
  hideCallback,
}) => {
  const location = useLocation();
  const { isDesktop } = useScreenWidth();

  /**
   * Component state
   */
  const [activeItem, setActiveItem] = useState("");

  /**
   * Set initial active submenu item.
   */
  useEffect(() => {
    const active = menu.items.find((item) => {
      if (item.exact) {
        return location.pathname === item.to;
      } else {
        // must be a path within the to url, can't just match against similar urls
        // e.g. /payment-links should not match with /payment
        // but /payment?query=1 or /payment/sub-page should
        if (location.pathname.indexOf(item.to) !== -1) {
          const rest = location.pathname.replace(item.to, "");
          return rest.charAt(0) === "?" || rest.charAt(0) === "/";
        }
      }
    });

    if (active) {
      setActiveItem(active.name);
    } else {
      setActiveItem("");
    }
  }, [location.pathname, menu]);

  return (
    <Transition
      className="c-submenu fixed overflow-scroll lg:overflow-visible top-0 left-0 w-full z-20 text-white text-center bg-primary-600 lg:h-full lg:text-left"
      transition={!isDesktop ? "slideInUp" : "slideInRight"}
      distance={!isDesktop ? window.outerHeight - 103 : 330}
      toggle={toggle}
    >
      {menu && (
        <nav>
          <button
            className="c-submenu-close absolute rounded-full text-center flex justify-center lg:pr-1 items-center"
            onClick={() => hideCallback()}
            css={styles.hideButton}
          >
            <CloseIcon className="md:hidden" />
            <SingleArrow
              className="transform rotate-90 lg:rotate-180 hidden md:block"
              width={9}
              height={15}
            />
          </button>
          <BeeLogo
            className="c-submenu-logo mt-12 mx-auto mb-4 md:mb-8 lg:hidden"
            fill="#FFF"
            width={70}
          />
          <h2 className="c-submenu-title text-2xl md:text-3xl font-bold xl:mb-8 lg:pt-12 lg:pr-0 lg:pb-6 lg:pl-12">
            {menu.title}
          </h2>
          <ul className="c-submenu-list pb-4 md:pb-0">
            {menu.items.map((item, index) => (
              <HavePermission
                key={index}
                requiredRoles={rt.config(item.name, "permissions")}
                isMenu={true}
              >
                <li
                  className={cx(
                    `c-submenu-item-${item.name}`,
                    "c-submenu-item relative text-white ut-transition",
                    { "c-submenu-active-item": activeItem === item.name },
                  )}
                >
                  {!item.target && (
                    <Link
                      to={item.to}
                      className="c-submenu-link relative block md:text-xl py-2 opacity-50 hover:font-bold hover:opacity-100 lg:text-base lg:p-4 lg:pl-12"
                    >
                      {item.nicename}
                    </Link>
                  )}
                  {item.target && (
                    <a
                      href={item.to}
                      target={item.target}
                      className="c-submenu-link relative block md:text-xl py-2 opacity-50 hover:font-bold hover:opacity-100 lg:text-base lg:p-4 lg:pl-12"
                    >
                      {item.nicename}
                    </a>
                  )}
                </li>
              </HavePermission>
            ))}
          </ul>
        </nav>
      )}
    </Transition>
  );
};
