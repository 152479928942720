import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { styles } from "./UnstyledButton.styles";

type ButtonDetailedProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export interface UnStyledButtonProps
  extends UserStylesProps<ButtonDetailedProps> {
  children?: React.ReactNode;
}

export const UnstyledButton = React.forwardRef<
  HTMLButtonElement,
  UnStyledButtonProps
>(({ children, styles: userStyles, ...rest }, ref): React.ReactElement => {
  return (
    <button
      ref={ref}
      css={css(
        styles.default,
        userStyles,
        rest.disabled && {
          cursor: "not-allowed",
        },
      )}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
});
