import { Template } from "models/template";
import React from "react";
import { useDrag } from "react-dnd";
import { styles } from "./styles";

export interface SideBarItemProps {
  template: Template;
}

export const SideBarItem = ({
  template,
}: SideBarItemProps): React.ReactElement => {
  const [, drag] = useDrag(() => ({
    type: `${template.type}-template`,
    item: template,
  }));

  return (
    <div
      className="cursor-pointer pt-1 text-sm select-none transition-colors"
      css={{
        ...styles.itemHoverEffect,
        ...styles.itemContainer,
      }}
      ref={drag}
      title={`${template.title} (${template.sku})`}
    >
      <span className="font-semibold">{template.title}</span>
      <span className="text-xs ml-1">({template.sku})</span>
    </div>
  );
};
