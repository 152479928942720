import React from "react";
import { animated, useTransition, UseSpringProps } from "react-spring";
import { easeQuad } from "d3-ease";

/**
 * Import prop types.
 */
import { ITransition } from "./types";

/**
 * Set animation configurations
 */
import { transitionSettings } from "./transitionSettings";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Transition = React.forwardRef(
  (
    {
      className,
      transition = "fadeIn",
      distance = 40,
      duration,
      delay = 0,
      component = "div",
      children,
      toggle = false,
      customSettings,
    }: ITransition,
    ref,
  ) => {
    const options = {
      distance,
    };

    /**
     * Retrieve set animation configuration
     */
    const getSettings = transitionSettings[transition];

    /**
     * Newly created animated component
     */
    const TransitionComponent: any = animated[component];

    let settings: UseSpringProps<Record<string, unknown>> = {
      config: { duration, easing: easeQuad },
      delay,
    };

    settings = customSettings
      ? { ...settings, ...customSettings }
      : { ...settings, ...getSettings(options) };

    /**
     * Transition styles
     *
     * If custom settings set use those instead, else use predefined settings.
     */
    // Not sure why the below works even though it's a typescript error...
    // eslint-disable-next-line
    const transitions = useTransition(toggle as any, null, settings);

    const transitionMap = transitions.map(({ item, key, props }) =>
      item ? (
        <TransitionComponent
          className={className}
          key={key}
          style={props}
          ref={ref}
        >
          {children}
        </TransitionComponent>
      ) : null,
    );

    return <>{transitionMap}</>;
  },
);
