import React, { useState } from "react";
import axios from "axios";
import {
  Panel,
  Heading,
  FlexGrid,
  Input,
  Button,
  Form,
  Field,
} from "@clearabee/ui-library";
import { SuccessModal } from "components/common/components";
import { useTranslation } from "react-i18next";
import { createSubcontractor as validationSchema } from "../validation";
import { toasts } from "helpers";
import { useMutation } from "react-query";
import { useInstance } from "api/dsa";
import { users as usersInstance } from "api/dsa/resources/users";

type AddUserData = Parameters<ReturnType<typeof usersInstance>["add"]>[0];

export interface CreateUpdateSubContractorFormProps {
  initialValues?: AddUserData;
}

const initialValues: Omit<AddUserData, "geolocation"> = {
  email: "",
  phone: "",
  wc_license: "",
  password: "",
  company: "",
  postcode: "",
};

export const CreateUpdateSubContractorForm = ({
  initialValues: propInitialValues,
}: CreateUpdateSubContractorFormProps): React.ReactElement => {
  const [translate] = useTranslation("users");
  const [isSuccess, setSuccess] = useState(false);
  const api = useInstance();

  const { mutate, isLoading } = useMutation(
    (data: AddUserData) => api.users.add(data),
    {
      onError: () => {
        toasts.error({
          message: "User could not be created. Try again",
        });
      },
      onSuccess: () => {
        setSuccess(true);
      },
    },
  );

  const handleSubmit = async (values: Omit<AddUserData, "geolocation">) => {
    const valuesFromForm = { ...values, geolocation: "" };
    /**
     * A query to get coordinates from an address postcode
     */
    try {
      const postcodeData = await axios.get(
        `https://api.postcodes.io/postcodes?q=${encodeURI(values.postcode)}`,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          },
        },
      );
      const geolocation = {
        longitude: postcodeData.data.result[0].longitude,
        latitude: postcodeData.data.result[0].latitude,
      };
      /**
       * These coordinates are then added to the form values object,
       * and the user is created with a coordinate field attached
       */
      valuesFromForm.geolocation = JSON.stringify(geolocation);
    } catch (error) {
      toasts.error({
        message: translate("subcontractors.errors.couldNotGenerateCoordinates"),
      });
    }
    mutate(valuesFromForm);
  };

  return (
    <Form
      initialValues={propInitialValues ?? initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validateOnMount
    >
      {({ isValid, handleReset }) => (
        <>
          <Panel>
            <div className="mb-4">
              <Heading color="brand" level={4}>
                {translate("subcontractors.headings.createSubcontractor")}
              </Heading>
            </div>
            <FlexGrid>
              {Object.keys(initialValues).map((fieldName) => (
                <FlexGrid.Cell base="33%">
                  <Field
                    name={fieldName}
                    // custom: trips up translation so replacing this bit for now
                    label={translate(
                      `subcontractors.labels.${fieldName.replace(
                        "custom:",
                        "",
                      )}`,
                    )}
                  >
                    {({ field }) => <Input.Text {...field} />}
                  </Field>
                </FlexGrid.Cell>
              ))}
            </FlexGrid>
          </Panel>
          <div className="flex justify-center mt-10">
            <Button
              as="button"
              type="submit"
              size="large"
              color="accent"
              disabled={!isValid || isLoading || isSuccess}
            >
              {propInitialValues
                ? translate("user.buttons.updateUser")
                : translate("subcontractors.buttons.createSubcontractor")}
            </Button>
          </div>

          <SuccessModal
            title={translate(
              "subcontractors.headings.createSubcontractorSuccess",
            )}
            buttonOne={{
              label: translate("subcontractors.buttons.returnToSubcontractors"),
              to: "/subcontractors",
            }}
            buttonTwo={{
              label: translate("user.form.labels.createAnother"),
              close: true,
            }}
            hideCallback={() => {
              handleReset();
              setSuccess(false);
            }}
            visible={isSuccess}
          />
        </>
      )}
    </Form>
  );
};
