import React, { useMemo, useCallback } from "react";
import * as yup from "yup";
import {
  BaseFieldConfig,
  Formik,
  useFormContext,
  isEvent,
  FieldContext,
} from "@clearabee/ui-library-base";
import { css } from "@emotion/react";
import { CSSInterpolation } from "@emotion/serialize";
import { UserStylesProps } from "../../../utils";
import { styles } from "./Field.styles";
import { FieldLabel } from "./FieldLabel";
import { Message } from "../../Message/Message";

export interface FieldConfig<Values extends Formik.FormikValues>
  extends BaseFieldConfig<Values, Formik.FieldProps>,
    Omit<UserStylesProps, "children"> {
  renderLabel?: boolean;
  messageStyles?: CSSInterpolation;
  errorBackground?: boolean;
}

interface CustomFieldInputProps extends Formik.FieldInputProps<unknown> {
  error?: string;
}

interface CustomFieldProps extends Omit<Formik.FieldProps, "field"> {
  field: CustomFieldInputProps;
}

export const Field = <Values extends Formik.FormikValues>({
  children,
  label: userLabel,
  required: userRequired,
  styles: userStyles,
  messageStyles,
  errorBackground = false,
  renderLabel = true,
  ...fieldProps
}: FieldConfig<Values>): React.ReactElement => {
  const formContext = useFormContext();

  if (!formContext) {
    throw new Error("Cannot use Field outside of a Form provider");
  }

  const { validationSchema } = formContext;
  const { label, required } = useMemo(() => {
    const values = { label: userLabel, required: userRequired };

    if (!validationSchema) return values;

    let schemaObject: yup.SchemaDescription | undefined;

    try {
      schemaObject = yup.reach(validationSchema, fieldProps.name).describe();
    } catch {
      console.error(`${fieldProps.name} does not exist on the form schema`);
    } finally {
      if (!schemaObject) return values;
    }

    const isFieldRequired = !!schemaObject.tests.find(
      (item: { name: string }) => item.name === "required",
    );

    if (values.label === undefined && schemaObject.label)
      values.label = schemaObject.label;

    if (values.required === undefined) values.required = isFieldRequired;

    return values;
  }, [validationSchema, userLabel, userRequired]);

  const renderChildren = useCallback(
    (field: CustomFieldProps): React.ReactNode => {
      if (typeof children !== "function") {
        return children;
      }

      if (field.meta.touched) {
        field.field.error = field.meta.error;
      }
      const formikOnChange = field.field.onChange;
      // If onChange value is not an event object, set value instead.
      field.field.onChange = (value: unknown) => {
        if (isEvent(value)) {
          formikOnChange(value);
        } else {
          field.form.setFieldValue(field.field.name, value);
        }
      };

      field.field.onBlur = () => {
        field.form.setFieldTouched(field.field.name, true);
      };

      return children(field);
    },
    [children],
  );

  return (
    <Formik.Field {...fieldProps}>
      {(field: Formik.FieldProps) => (
        <FieldContext.Provider value={{ isField: true }}>
          <FieldLabel
            label={label}
            required={required}
            renderLabel={renderLabel}
            styles={userStyles}
          >
            {renderChildren(field)}
            {field.meta.error && field.meta.touched && (
              <Message
                type="error"
                small={true}
                background={errorBackground}
                css={css(styles.message, messageStyles)}
              >
                {field.meta.error}
              </Message>
            )}
          </FieldLabel>
        </FieldContext.Provider>
      )}
    </Formik.Field>
  );
};
