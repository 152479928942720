import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { styles, AllowedColorKeys, AllowedSizeKeys } from "./Pill.styles";
export interface PillProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >
  > {
  color?: AllowedColorKeys;
  size?: AllowedSizeKeys;
}

export const Pill = ({
  color = "brand",
  styles: userStyles,
  size = "medium",
  ...rest
}: PillProps): React.ReactElement => {
  return (
    <span
      css={css(
        styles.defaults,
        styles[color],
        styles[size],
        rest.onClick && styles.clickable,
        userStyles,
      )}
      {...rest}
    />
  );
};
