import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { Modal, theme, Button, Text, Icon } from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { RouteLink } from "components/core";
import { CreateUpdateSupplierForm } from "../components";
import { ISupplier } from "../types";

export const CreateSupplier = (): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [showModal, setShowModal] = useState(false);

  const { mutate, isLoading: isCreatingSupplier } = useMutation(
    // (values: ISupplier) => instance.users.postSupplier(values),
    (values: ISupplier) => ({} as any),
    {
      onError: () => {
        toasts.error({
          message: `${translate("suppliers.errors.supplierNotCreated")}`,
        });
      },
      onSuccess: () => setShowModal(true),
    },
  );

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <RouteLink href="/suppliers">
        <Text
          className="flex flex-row gap-1 items-center cursor-pointer"
          fontSize="small"
        >
          <Icon.Chevron size="small" styles={{ transform: "rotate(180deg)" }} />
          {translate("suppliers.buttons.backToSuppliers")}
        </Text>
      </RouteLink>

      <div className="mt-4">
        <CreateUpdateSupplierForm
          title={translate("suppliers.headings.createSupplier")}
          disableSubmitButton={isCreatingSupplier}
          mutateFunction={mutate}
        />
      </div>

      {showModal && (
        <Modal
          heading={translate("supplierTypes.headings.success")}
          width={500}
          actions={
            <>
              <RouteLink href="/suppliers">
                <Button
                  size="medium"
                  color="brand"
                  type="button"
                  className="mr-3"
                >
                  {translate("suppliers.buttons.backToSuppliers")}
                </Button>
              </RouteLink>

              <Button
                size="medium"
                color="accent"
                type="button"
                className="mr-3"
                onClick={() => setShowModal(false)}
              >
                {translate("suppliers.buttons.addAnother")}
              </Button>
            </>
          }
          onClose={() => setShowModal(false)}
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("supplierTypes.messages.supplierTypeAdded")}
          </div>
        </Modal>
      )}
    </div>
  );
};
