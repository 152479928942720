/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Minus: React.FC<TGenericImage> = ({
  className = "minus-icon",
  fill = "currentColor",
  width = 9,
  height = 2,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 2"
    >
      <rect
        width={width}
        height={height}
        fill={fill}
        fillRule="evenodd"
        rx=".8"
      />
    </svg>
  );
};
