import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import components.
 */
import { SelectService, SelectProducts, PaymentConfirmation } from "../steps";
import { StepsWrapper } from ".";
import { MultiForm } from "../../../common/components";

/**
 * Import context.
 */
import { ProvideCatalogue, ProvideBasket } from "../../../../contexts";

/**
 * Import multi form initial values.
 */
import { formStateInitialValues } from "../validation";

/**
 * Types.
 */
import { Job } from "models";
interface ISteps {
  job: Job;
}

export const Steps: React.FC<ISteps> = ({ job }) => {
  /**
   * Translations.
   */
  const [t] = useTranslation("jobs");

  /**
   * Steps.
   */
  const steps = [
    {
      id: "select-service",
      name: t("create.steps.selectService.title"),
      description: t("create.steps.selectService.description"),
      component: SelectService,
    },
    {
      id: "select-products",
      name: t("create.steps.selectProductOptions.titleVT"),
      description: t("create.steps.selectProductOptions.descriptionVT"),
      component: SelectProducts,
    },
    {
      id: "payment-confirmation",
      name: t("create.steps.confirmOrder.titlePayment"),
      description: t("create.steps.confirmOrder.descriptionPayment"),
      component: PaymentConfirmation,
    },
  ];

  return (
    <div className="max-w-screen-xl px-4 py-8 lg:px-16 ml-auto mr-auto">
      <ProvideCatalogue catalogueId="PORTAL" postcode={null} companyCode={null}>
        <ProvideBasket>
          <MultiForm
            steps={steps}
            initialValues={{
              ...formStateInitialValues,
              job,
            }}
            wrapperComponent={StepsWrapper}
          />
        </ProvideBasket>
      </ProvideCatalogue>
    </div>
  );
};
