/**
 * Import polyfills.
 */
import "core-js/stable/symbol";
import "core-js/features/promise";
import "core-js/features/set";
import "core-js/features/array/from";
import "core-js/features/array/fill";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/object/entries";
import "core-js/features/string/starts-with";
import "core-js/features/string/ends-with";
import "core-js/features/string/code-point-at";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import Bowser from "bowser";
import { QueryClient, QueryClientProvider } from "react-query";
import "./styles/tailwind.css";

/**
 * Import the service worker.
 * - More info on service workers: https://bit.ly/CRA-PWA
 * - See the bottom of this file also for functions related to the service worker
 */
import * as serviceWorker from "./serviceWorker";

/**
 * Import the default app component to wrap in the provider component.
 */
import { App } from "./components";

/**
 * Import authContext wrapper component.
 */
import { ProvideAuth } from "./contexts";

/**
 * Import images.
 */
import { BeeLogo } from "./images";

/**
 * Import bundled i18n translations.
 */
import "./translations/config";

/**
 * Alter focus styles only to be applied when using keyboard
 */
// Let the document know when the mouse is being used
document.body.addEventListener("mousedown", () => {
  document.body.classList.add("using-mouse");
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener("keydown", (event) => {
  if (event.keyCode === 9) {
    document.body.classList.remove("using-mouse");
  }
});

/**
 * Check if user's browser is not supported
 */
const browser = Bowser.getParser(window.navigator.userAgent);
const isUnsupportedBrowser =
  browser.getBrowserName() === "Internet Explorer" &&
  parseInt(browser.getBrowserVersion(), 10) < 11;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Component shown on unsupported browsers
 */
const UnsupportedBrowser = () => {
  return (
    <section className="bg-white h-screen text-center flex flex-col justify-center items-center">
      <div className="mb-12">
        <BeeLogo fill="#793BDA" width={48} height={41} />
      </div>
      <h1
        className="text-primary font-bold leading-none mb-6"
        style={{ width: "422px", fontSize: "75px" }}
      >
        Something went wrong!
      </h1>
      <p className="text-3xl mb-12" style={{ width: "400px" }}>
        Your browser is out of date, please upgrade or call us
      </p>
      <a className="text-primary text-5xl font-bold" href="tel:01217942322">
        0121 794 2322
      </a>
    </section>
  );
};

/**
 * Render our app into a div with the ID of 'root'
 * - Wrapped with a Provider component to enable the entire app to access the store/state
 */

const root = ReactDOM.createRoot(
  document.getElementById("root") || document.createElement("div"),
);

if (isUnsupportedBrowser) {
  root.render(<UnsupportedBrowser />);
} else {
  root.render(
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <Router>
          <App />
        </Router>
      </ProvideAuth>
    </QueryClientProvider>,
  );
}

/**
 * Unregister the service worker, we aren't using it.
 */
serviceWorker.unregister();
