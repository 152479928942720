import { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";
import timezones from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
/**
 * Import custom hooks.
 */
import { useConfig } from ".";

/**
 * useTime Callback interface.
 */
interface IIsOpen {
  open: boolean;
  until: string;
  reopens: "today" | "tomorrow" | false;
  opensAt: false | string;
}

/**
 * Configuration
 */
const timeFormat = "HH:mm:ss";
const checkingInterval = 5000;
const defaultOutputFormat = "h:mma";

/**
 * Extend Dayjs
 */
dayjs.extend(timezones);
dayjs.extend(utc);
dayjs.extend(isBetween);

/**
 * useTime hook
 * - Returns various time related properties based on the UK timezone
 * - Configured to the timetable config set within app settings.json
 * - Config is based on US day numbers, e.g. 0 = Sunday through to 6 = Monday
 *
 * @param {string} outputFormat Moment.js formatting for all outputted time strings.
 *
 * @return {array} [open, openUntil, reopens, opensAt]
 */
export const useTime = (outputFormat?: string): (string | boolean)[] => {
  /**
   * Config timetable.
   */
  const appSettings = useConfig("app");
  const openingTimes = appSettings("openingTimes");

  /**
   * Today timestamp.
   */
  const today = parseInt(dayjs.utc().tz("Europe/London").format("d"), 10);

  /**
   * Get opening day string.
   */
  const getOpeningDay = useCallback(() => {
    // Get fresh timestamp
    const m = dayjs.utc().tz("Europe/London");
    const todayTimes = openingTimes[m.format("d")];

    return m.isBetween(
      dayjs("00:00:00", timeFormat),
      dayjs(todayTimes.open, timeFormat),
    )
      ? "today"
      : "tomorrow";
  }, [openingTimes]);

  /**
   * Check if call centre is open method.
   */
  const checkIsOpen = useCallback((): IIsOpen => {
    // Get fresh timestamp
    const m = dayjs().utc().tz("Europe/London");

    const todayTimes = openingTimes[m.format("d")];

    const todayOpenSplit = todayTimes.open.split(":");
    const todayClosedSplit = todayTimes.closed.split(":");

    const todayOpening = dayjs(m)
      .set("hour", todayOpenSplit[0])
      .set("minute", todayOpenSplit[1])
      .set("second", todayOpenSplit[2]);
    const todayClosing = dayjs(m)
      .set("hour", todayClosedSplit[0])
      .set("minute", todayClosedSplit[1])
      .set("second", todayClosedSplit[2]);

    const open = m.isBetween(todayOpening, todayClosing);

    const reopens = !open ? getOpeningDay() : false;
    let opensAt: IIsOpen["opensAt"] = false;

    if (reopens === "today") {
      opensAt = openingTimes[today].open;
    } else if (reopens === "tomorrow") {
      opensAt = today < 6 ? openingTimes[today + 1].open : openingTimes[0].open;
    }

    return {
      open,
      until: dayjs(openingTimes[today].closed, timeFormat).format(
        outputFormat || defaultOutputFormat,
      ),
      reopens,
      opensAt: opensAt
        ? dayjs(opensAt, timeFormat).format(outputFormat || defaultOutputFormat)
        : false,
    };
  }, [openingTimes, getOpeningDay, outputFormat, today]);

  /**
   * Initial state.
   */
  const initialState = checkIsOpen();
  const [open, setOpen] = useState(initialState.open);
  const [openUntil, setOpenUntil] = useState(initialState.until);
  const [reopens, setReopens] = useState(initialState.reopens);
  const [opensAt, setOpensAt] = useState(initialState.opensAt);

  /**
   * Check is open interval.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      const state = checkIsOpen();
      setOpen(state.open);
      setOpenUntil(state.until);
      setReopens(state.reopens);
      setOpensAt(state.opensAt);
    }, checkingInterval);

    // Clear interval on dismount
    return () => clearInterval(interval);
  }, [checkIsOpen]);

  return [open, openUntil, reopens, opensAt];
};
