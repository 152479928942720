import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  default: {
    zIndex: 1,
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",
  },
  loadingIconContainer: {
    margin: "0 auto",
    background: theme.colors.light.base,
    borderRadius: "50%",
    opacity: 1,
    zIndex: 2,
  },
  loadingBgContainer: {
    zIndex: 1,
    height: "100%",
    width: "100%",
    position: "fixed",
    background: theme.colors.greyscale.lightest,
    opacity: 0.6,
  },
});
