import React, { createContext, CSSProperties, useContext } from "react";
import { css } from "@emotion/react";
import { theme } from "../../theme";
import {
  Breakpoints,
  BreakpointStyles,
  UserStylesProps,
} from "../../utils/types";
import { createResponsiveStyles } from "../../utils";

export type FlexGridCellWidthBreakpoints = {
  [screen in Breakpoints]?: CSSProperties["width"];
};

type Padding = keyof typeof theme.spacing | number | null;

export interface FlexGridProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  children?: React.ReactNode;
  wrap?: CSSProperties["flexWrap"];
  direction?: CSSProperties["flexDirection"];
  cellSpacing?: Padding;
  rowSpacing?: Padding;
}

interface FlexGridContextState {
  paddingHorizontal: string;
  paddingBottom: string;
}

const FlexGridContext = createContext({} as FlexGridContextState);

const getPixels = (value: Padding): string => {
  if (typeof value === "string") return theme.spacing[value];
  return value ? `${value}px` : "0px";
};

export const FlexGrid = ({
  children,
  wrap: flexWrap = "wrap",
  styles: userStyles,
  direction: flexDirection = "row",
  cellSpacing: paddingHorizontal = "small",
  rowSpacing: paddingBottom = "small",
  ...props
}: FlexGridProps): React.ReactElement => {
  const margin = getPixels(paddingHorizontal);
  const bottomSpacing = getPixels(paddingBottom);

  return (
    <div
      css={css(
        {
          display: "flex",
          marginRight: margin ? `-${margin}` : undefined,
          marginLeft: margin ? `-${margin}` : undefined,
          flexWrap,
          flexDirection,
        },
        userStyles,
      )}
      {...props}
    >
      <FlexGridContext.Provider
        value={{
          paddingHorizontal: margin,
          paddingBottom: bottomSpacing,
        }}
      >
        {children}
      </FlexGridContext.Provider>
    </div>
  );
};

export interface FlexGridCellProps
  extends UserStylesProps<
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >
    >,
    FlexGridCellWidthBreakpoints {
  children?: React.ReactNode;
}

export const FlexGridCell = ({
  styles: userStyles,
  children,
  base,
  xsmall,
  small,
  medium,
  large,
  xlarge,
  xlarge2,
  xlarge3,
  xlarge4,
  ...props
}: FlexGridCellProps): React.ReactElement => {
  const { paddingHorizontal, paddingBottom } = useContext(FlexGridContext);

  const responsiveWidths: FlexGridCellWidthBreakpoints = {
    base,
    xsmall,
    small,
    medium,
    large,
    xlarge,
    xlarge2,
    xlarge3,
    xlarge4,
  };

  const breakpointStyles = createResponsiveStyles(
    Object.entries(responsiveWidths).reduce(
      (final, [key, width]) => (width ? { ...final, [key]: { width } } : final),
      {} as BreakpointStyles,
    ),
  );

  return (
    <div
      css={css({
        padding: `0 ${paddingHorizontal} calc(${paddingBottom} * 2)`,
        flexShrink: 1,
        ...breakpointStyles,
        userStyles,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

FlexGrid.Cell = FlexGridCell;
