import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * Import helper.
 */
import { Routes } from "../../../helpers/routes";

/**
 * Have permission core component.
 */
import { HavePermission } from "..";

/**
 * Import useAuth hook.
 */
import { useAuthContext } from "../../../hooks";

/**
 * Interface for private route.
 */
interface IPrivateRoute {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
  permissions: string[];
}

/**
 * Private route component.
 * - If the token doesn't exist in local storage redirect
 */
export const PrivateRoute: React.FC<IPrivateRoute> = ({
  path,
  exact = false,
  component: Component,
  permissions,
}) => {
  const { isUserAuthenticated } = useAuthContext();

  if (isUserAuthenticated()) {
    return (
      <HavePermission requiredRoles={permissions}>
        <Route path={path} exact={exact} render={() => <Component />} />
      </HavePermission>
    );
  }

  const rt = new Routes();
  const loginPath = rt.config("auth/login", "path");

  return <Redirect to={{ pathname: loginPath }} />;
};
