import React from "react";
import { Formik } from "formik";
import { toasts } from "../../helpers";

/**
 * Import Yup field validation.
 */
import { forgot } from "./validation";

/**
 * Get API function.
 */
import { useAuthContext } from "../../hooks";

/**
 * Import components.
 */
import { TitleForgot, InputEmail, Button } from "./components";
import { Trail } from "../core";

/**
 * Components array for trail transition.
 */
const formFields = [
  <InputEmail />,
  <Button
    linkRoute="login"
    linkTranslationKey="forgotPassword"
    buttonTranslationKey="forgot"
  />,
];

/**
 * Set our initial values.
 */
const initialValues = {
  email: "",
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ForgotPassword: React.FC = () => {
  /**
   * useAuth for forgot password.
   */
  const { forgotPassword } = useAuthContext();

  const sendPasswordChange = async (values: { email: string }) => {
    const { email } = values;

    if (!email) {
      return;
    } else {
      try {
        await forgotPassword(email, { originUrl: window.location.origin });
        toasts.success({
          message:
            "If this user exists, we have sent you a password reset email.",
        });
      } catch (e) {
        toasts.error({ message: "Something went wrong. Please try again." });
        throw e;
      }
    }
  };

  return (
    <div>
      <TitleForgot />

      <Formik
        initialValues={initialValues}
        validationSchema={forgot}
        onSubmit={(values) => sendPasswordChange(values)}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Trail components={formFields} />
          </form>
        )}
      </Formik>
    </div>
  );
};
