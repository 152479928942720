import { jobs } from "./jobs";
import { vehicles } from "./vehicles";
import { users } from "./users";

// Each time a new API resource is added to the app, it should be declared here
export default {
  jobs,
  vehicles,
  users,
};
