"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subcontractors = void 0;
exports.subcontractors = {
    headings: {
        subcontractorsSignup: "Subcontractors Sign Up",
    },
    form: {
        labels: {
            companyName: "Company Name",
            companyAddress: "Company Address",
            contactName: "Contact Name",
            contactEmail: "Contact Email",
            contactNumber: "Contact Number",
            companyRegisteredNumber: "Company Registered Number",
            publicLiabilityInsurance: "Public Liability Insurance",
            vehicleInsurance: "Vehicle Insurance",
            employersLiability: "Employers Liability",
            wasteCarriersLicencePDF: "Waste Carriers Licence (PDF)",
            wasteCarriersLicenceLink: "Waste Carriers Licence (Link to EA registration)",
        },
        placeholders: {
            companyName: "Enter company name",
            companyAddress: "Enter company address",
            contactName: "Enter contact name",
            contactEmail: "Enter contact email",
            contactNumber: "Enter contact number",
            companyRegisteredNumber: "Enter registered number",
            publicLiabilityInsurance: "Enter public liability insurance",
            vehicleInsurance: "Enter vehicle insurance",
            employersLiability: "Enter employers liability",
            wasteCarriersLicenceLink: "Enter link to EA registration",
        },
    },
    buttons: {
        submit: "Submit",
        upload: "Upload",
        remove: "Remove",
    },
    error: "Fail to submit, please try again",
    success: "Your submission is now pending approval",
};
