import axios from "axios";

import {
  TFilters,
  QuotePatchBody,
  INewQuote,
  IQuotePresignedUrl,
  ITabledResults,
  IPaginatedResults,
} from "./types";
import { Quote } from "models";
import { generateQueryParams, hasMoreResults } from "./generic";

export const readQuotesTableResults = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<ITabledResults<Quote>> => {
  try {
    const { items, pagination } = await readQuotes(filters, currentPage, limit);

    return {
      items: items.map((item: Quote) => {
        const { id: qid, contactEmail } = item;
        return {
          ...item,
          singleLink: `/quotes/update/${qid}`,
          mutateUrl: `/quotes/${contactEmail}`,
        };
      }),

      pagination: {
        ...pagination,
        hasMoreResults: hasMoreResults(pagination.total, currentPage, limit),
      },
    };
  } catch (error) {
    throw error;
  }
};

export const readQuotes = async (
  filters: TFilters = "",
  currentPage = 0,
  limit = 8,
): Promise<IPaginatedResults<Quote>> => {
  const queryParams = generateQueryParams(filters, currentPage, limit);
  const { data } = await axios.get(`/quotes${queryParams}`);
  return data;
};

export const readQuote = async (qid = "0"): Promise<Quote> => {
  try {
    const { data } = await axios.get(`/quotes/${qid}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const editQuote = async (
  id: string,
  payload: Partial<QuotePatchBody>,
): Promise<void> => {
  try {
    await axios.patch(`/quotes/${id}`, payload);
  } catch (e) {
    throw e;
  }
};

export const createQuote = async (quoteData: INewQuote): Promise<INewQuote> => {
  try {
    const quote = await axios.post("/quotes/", quoteData);
    return quote.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Request pre-signed url for quote image upload.
 */
export const getQuoteImagePresignedUrl = async (
  companyCode: string,
  filename: string,
): Promise<IQuotePresignedUrl> => {
  try {
    const s3Url = await axios.put(`/quotes/image/${companyCode}/${filename}`);
    return s3Url.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Upload quote image to s3 using the presigned url.
 */
export const uploadQuoteImage = async (
  file: File,
  url: string,
  fields: { [key: string]: string },
): Promise<string> => {
  const form = new FormData();

  /**
   * Add returned fields from our presigned url as form-data fields.
   */
  Object.keys(fields).forEach((field: string) => {
    form.append(field, fields[field]);
  });

  /**
   * Add extra fields required.
   */
  form.append("acl", "public-read");
  form.append("success_action_status", "2xx");
  form.append("file", file);

  try {
    const s3 = axios.create();
    // eslint-disable-next-line
    delete s3.defaults.headers.common["Authorization"];

    const s3Upload = await s3({
      method: "POST",
      url,
      data: form,
      headers: {
        Authorization: "",

        "Content-Type": "multipart/form-data",
      },
    });

    return s3Upload.headers.location;
  } catch (error) {
    throw error;
  }
};
