import React, { useState, useEffect } from "react";
import cx from "classnames";

/**
 * Import images.
 */
import { SingleArrow } from "../../../images";

/**
 * Interface.
 */
interface IAccordion {
  title: string;
  icon?: string | React.ReactNode;
  isOpen?: boolean;
  children: React.ReactNode;
  wrapperClasses?: string;
}

/**
 * Accordion component.
 */
export const Accordion = ({
  title,
  icon,
  isOpen,
  children,
  wrapperClasses = "my-3",
}: IAccordion) => {
  const [open, setOpen] = useState(false);

  /**
   * Open the accordion by default if isOpen set to true.
   */
  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  /**
   * Classes for the header.
   */
  const headerClasses = cx("border rounded-xl", {
    "border-primary shadow-filter": open,
    "border-black": !open,
  });

  /**
   * Classes for the chevron icon container.
   */
  const chevronContainerClasses = cx(
    "flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 border",
    {
      "border-primary bg-primary text-white": open,
      "border-black": !open,
    },
  );

  return (
    <article className={wrapperClasses}>
      <header className={headerClasses}>
        <button
          onClick={() => setOpen(!open)}
          className="block w-full flex justify-between items-center cursor-pointer select-none px-4 py-2"
        >
          <div className="flex flex-row justify-center items-center">
            {typeof icon === "string" && (
              <div className="flex justify-start items-center w-8 h-8 mr-2">
                <img src={icon} alt={title} className="max-w-full max-h-full" />
              </div>
            )}
            {typeof icon !== "string" &&
              React.isValidElement(icon) &&
              React.cloneElement(icon)}
            <h4 className="text-xs md:text-sm text-left text-black font-semibold md:text-center">
              {title}
            </h4>
          </div>
          <div className={chevronContainerClasses}>
            {open ? (
              <SingleArrow
                className="transform rotate-90"
                height={10}
                width={10}
              />
            ) : (
              <SingleArrow
                className="transform -rotate-90"
                height={10}
                width={10}
              />
            )}
          </div>
        </button>
      </header>
      {open && <article>{children}</article>}
    </article>
  );
};
