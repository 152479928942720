import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { Loader, Table, theme } from "@clearabee/ui-library";
import { QuoteFilters } from "./components";
import { readQuotes } from "api/quotes";

export const ReadQuotes = (): React.ReactElement => {
  const [translate] = useTranslation("quotes");
  const history = useHistory();

  const {
    PaginationComponent,
    updateFilters,
    paginatedData,
    query: { isFetching },
  } = usePaginatedQuery(readQuotes, "readQuotes", "", {
    resultOptions: [10, 20, 50, 100],
  });
  interface PillColours {
    [status: string]: AllowedColorKeys;
  }

  /**
   * defines the possible colours of the pills
   * based on quote status
   */
  const pillColors: PillColours = {
    open: "warning",
    failed: "negative",
    started: "brand",
    completed: "positive",
    scheduled: "brand",
    pending: "accent",
    expired: "usp",
  };

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <QuoteFilters isFetching={isFetching} updateFilters={updateFilters} />
      <div className="relative overflow-x-scroll">
        <Table
          styles={{
            marginBottom: theme.spacing.large,
            "th:first-of-type > p, td:first-of-type > span": {
              marginLeft: theme.spacing.small,
            },
          }}
        >
          <Table.Header
            headings={[
              translate("headings.status"),
              translate("headings.quoteReference"),
              translate("headings.contact"),
              translate("headings.postcode"),
              translate("headings.creationDate"),
            ]}
          />
          <Table.Body>
            {paginatedData.map((quote) => (
              <Table.Row
                key={`table-row-${quote.id}`}
                styles={{ cursor: "pointer" }}
                onClick={() => history.push(`/quotes/update/${quote.id}`)}
              >
                <Table.Cell.Pill
                  color={pillColors[`${quote.status}`] || "brand"}
                >
                  {quote.status}
                </Table.Cell.Pill>
                <Table.Cell.Text>{quote.quoteRef}</Table.Cell.Text>
                <Table.Cell.Text>{`${quote.contactFirstName} ${quote.contactLastName}`}</Table.Cell.Text>
                <Table.Cell.Text>{quote.addressPostcode}</Table.Cell.Text>
                <Table.Cell.Text>
                  {dayjs(quote.createdAt).format("Do MMMM YYYY")}
                </Table.Cell.Text>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {/* Fill space when no data is ready to show */}
        {!paginatedData.length && <div css={css({ height: 300 })}></div>}
        {isFetching && (
          <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-white bg-opacity-75">
            <Loader color="brand" text={translate("common:loading2")} />
          </div>
        )}
      </div>
      <PaginationComponent />
    </div>
  );
};
