import React, { useMemo } from "react";
import { css } from "@emotion/react";
import {
  useJsApiLoader,
  GoogleMapProps,
  GoogleMap,
  LoadScriptProps,
} from "@react-google-maps/api";
import { Box, UserStylesProps, Icon } from "@clearabee/ui-library";
import { mapStyles } from "./mapStyles";
import { styles } from "./Map.styles";

interface MapProps extends UserStylesProps {
  loading?: boolean;
  children?: React.ReactNode;
}

const libraries: LoadScriptProps["libraries"] = ["geometry"];

export const Map = ({
  styles: userStyles,
  loading = false,
  children,
  ...rest
}: MapProps): React.ReactElement => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD79Gc_JkcnMRPbv2X04keuLfV-mClEBiA",
    libraries,
  });

  const mapOptions = useMemo(() => {
    const options: GoogleMapProps["options"] = {
      zoom: 6,
      center: {
        lat: 55,
        lng: -5,
      },
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      styles: mapStyles,
    };
    return options;
  }, []);

  const mapContainerStyles = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    [],
  );

  return (
    <>
      {isLoaded ? (
        <>
          <Box styles={css(styles.default, userStyles)} {...rest}>
            <GoogleMap
              mapContainerStyle={mapContainerStyles}
              options={mapOptions}
            >
              {children}
            </GoogleMap>
            {loading && (
              <Box className="flex z-25 absolute top-0 right-0 items-center justify-center w-full h-full">
                <Icon.Loading size="xlarge4" color="brand" />
              </Box>
            )}
          </Box>
          {loading && <Icon.Loading size="xlarge4" />}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
