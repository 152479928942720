import { theme } from "../../theme";
import { StyleSheet, hexToRgba } from "../../utils";

export type SunSvgColor = [string, string, string, string];

export type AllowedColorKeys = keyof Pick<
  typeof theme.colors,
  "brand" | "accent" | "negative" | "warning" | "usp" | "greyscale"
>;

export const sunSvgColors: Record<AllowedColorKeys, SunSvgColor> = {
  brand: [
    hexToRgba(theme.colors.brand.dark, 1),
    hexToRgba(theme.colors.brand.base, 1),
    hexToRgba(theme.colors.brand.lighter, 0.1),
    hexToRgba(theme.colors.brand.lightest, 0.1),
  ],
  accent: [
    hexToRgba(theme.colors.accent.dark, 1),
    hexToRgba(theme.colors.accent.base, 1),
    hexToRgba(theme.colors.accent.lighter, 0.4),
    hexToRgba(theme.colors.accent.lightest, 0.3),
  ],
  negative: [
    hexToRgba(theme.colors.negative.dark, 1),
    hexToRgba(theme.colors.negative.base, 1),
    hexToRgba(theme.colors.negative.lighter, 0.1),
    hexToRgba(theme.colors.negative.lightest, 0.1),
  ],
  warning: [
    hexToRgba(theme.colors.warning.dark, 1),
    hexToRgba(theme.colors.warning.base, 1),
    hexToRgba(theme.colors.warning.lighter, 0.1),
    hexToRgba(theme.colors.warning.lightest, 0.2),
  ],
  usp: [
    hexToRgba(theme.colors.usp.dark, 1),
    hexToRgba(theme.colors.usp.base, 1),
    hexToRgba(theme.colors.usp.lighter, 0.2),
    hexToRgba(theme.colors.usp.lightest, 0.2),
  ],
  greyscale: [
    hexToRgba(theme.colors.greyscale.dark, 1),
    hexToRgba(theme.colors.greyscale.base, 1),
    hexToRgba(theme.colors.greyscale.dark, 0.1),
    hexToRgba(theme.colors.greyscale.darker, 0.1),
  ],
};

export const styles = StyleSheet({
  defaults: {
    ...theme.fontDefaults.base,
    position: "relative",
    width: "210px",
    height: "215px",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    color: theme.colors.light.base,
  },
  sun: {
    position: "absolute",
    top: "0",
    left: "0",
  },
  children: {
    position: "relative",
    padding: `0px ${theme.spacing.medium}`,
    textAlign: "center",
  },
  childrenWithIcon: {
    position: "absolute",
    inset: "0",
    "> svg": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
    },
  },
  sunSvg: {
    overflow: "visible",
  },
  hideStroke: {
    display: "none",
  },
  brand: {},
  accent: {
    color: theme.colors.dark.base,
  },
  negative: {},
  warning: {
    color: theme.colors.dark.base,
  },
  usp: {},
  greyscale: {},
});
