import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { useAuthContext } from "hooks";
import {
  Box,
  Icon,
  Text,
  theme,
  ColorKeyVariants,
  getColorFromVariantKey,
} from "@clearabee/ui-library";
import { LinkPanelWithImage } from "components/dashboard/components";
import roles from "constants/roles";

interface PanelProps {
  title: string;
  backgroundColor?: ColorKeyVariants;
  titleColor?: ColorKeyVariants;
  imageComponent?: React.ReactElement;
  path: string;
  isAuthorised: boolean;
}

const excludedOrderTypesNames: string[] = [
  "Quick job",
  "Maintenance",
  "NLWA Tip",
  "End of day",
  "Start of day",
  "Tip",
  "CB Skip",
  "Free WEEE Kerbside",
  "DLG Skip Request",
  "Skipbag Delivery",
  "Temp Worker Pickup",
  "LV Contractor Skip Request",
];

export const Dashboard = (): React.ReactElement => {
  const { doesUserHaveRole } = useAuthContext();
  const { t } = useTranslation("dashboard");
  /**
   * users roles
   */
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const isClearabeeCustomerServices = doesUserHaveRole(
    roles.CLEARABEE_CUSTOMER_SERVICE,
  );
  const isAnalyst = doesUserHaveRole(roles.Analyst);
  const isFinanceAnalyst = doesUserHaveRole(roles.FINANCE_ANALYST);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);
  const isCompanyStaff = doesUserHaveRole(roles.COMPANY_STAFF);
  const isBookOnly = doesUserHaveRole(roles.BOOK_ONLY);
  const isViewOnly = doesUserHaveRole(roles.VIEW_ONLY);

  /**
   * today date in YYYY-MM-DD format
   */
  const todayTime = dayjs().format("YYYY-MM-DD");

  /**
   * get all jobs for today
   */
  const { data: todayAllJobs } = useQuery(
    "getJobs",
    async () =>
      (
        await instance.jobs.getJobs({
          params: {
            limit: 3000,
            "date:gte": dayjs(todayTime)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            "date:lte": dayjs(todayTime)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
    },
  );

  const todayAllJobsWithExcludedOrderTypes = todayAllJobs?.filter(
    ({ type, statusId, status }) => {
      // exclude jobs with statusId 14 (cancelled) and order types in excludedOrderTypesNames
      if (
        statusId === 14 ||
        status.toLowerCase() === "cancelled" ||
        excludedOrderTypesNames.includes(type || "")
      ) {
        return false;
      }
      return true;
    },
  );

  const todayCompletedJobs = todayAllJobsWithExcludedOrderTypes?.filter(
    ({ statusId }) => statusId === 12,
  );

  const todayOpenJobs = todayAllJobsWithExcludedOrderTypes?.filter(
    ({ statusId, status }) => {
      // include jobs with statusId 1, 2, 3, 4, 5, 6, 7, 8, 10 and status "open"
      // include jobs with status "open" (1,2,3), "scheduled"(4,5,6), "on the way"(8), "started"(10)
      if (
        status.toLowerCase() === "open" ||
        [1, 2, 3, 4, 5, 6, 7, 8, 10].includes(statusId || 0)
      ) {
        return true;
      }
      return false;
    },
  );

  const todayFailedJobs = todayAllJobsWithExcludedOrderTypes?.filter(
    ({ statusId }) => statusId === 13,
  );

  /**
   * different imageComponent for each panel
   */
  const PlusInCircle = (
    <Box
      style={{
        height: theme.spacing.xlarge,
        width: theme.spacing.xlarge,
        border: "2px solid",
        borderColor: theme.colors.light.base,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon.Plus size="medium" color="light.base" />
    </Box>
  );

  const jobsLengthCircle = (
    jobsLength?: number,
    textColor?: ColorKeyVariants,
  ) => {
    return (
      <>
        {!jobsLength && jobsLength !== 0 ? (
          <Icon.Loading size="xlarge3" color="light" />
        ) : (
          <Text
            fontSize="xlarge5"
            styles={{
              fontWeight: 800,
              color: getColorFromVariantKey(textColor || "light"),
            }}
          >
            {jobsLength}
          </Text>
        )}
      </>
    );
  };

  /**
   * all panels
   */
  const linkPanels: Array<PanelProps> = [
    {
      title: t("dashboardV2.titles.bookAJob"),
      imageComponent: PlusInCircle,
      path: "jobs/create",
      isAuthorised:
        isClearabeeAdmin ||
        isClearabeeCustomerServices ||
        isCompanyAdmin ||
        isCompanyStaff ||
        isBookOnly,
    },
    {
      title: t("dashboardV2.titles.viewJobs"),
      imageComponent: PlusInCircle,
      path: "jobs",
      isAuthorised:
        isClearabeeAdmin ||
        isClearabeeManager ||
        isClearabeeCustomerServices ||
        isCompanyAdmin ||
        isCompanyStaff ||
        isViewOnly,
    },
    {
      title: t("dashboardV2.titles.viewAllCompanies"),
      imageComponent: PlusInCircle,
      path: "companies",
      isAuthorised: isClearabeeAdmin || isClearabeeManager,
    },
    {
      title: t("dashboardV2.titles.viewReports"),
      titleColor: "light",
      imageComponent: PlusInCircle,
      path: "reports/statistics",
      isAuthorised: isClearabeeAdmin || isAnalyst || isFinanceAnalyst,
    },
  ];

  const jobPanels: Array<PanelProps> = [
    {
      title: t("dashboardV2.titles.totalJobsToday"),
      imageComponent: jobsLengthCircle(
        todayAllJobsWithExcludedOrderTypes?.length,
        "light",
      ),
      path: `jobs?status=all&date=${dayjs(todayTime).format(
        "DD/MM/YYYY",
      )}&page=1`,
      isAuthorised:
        isClearabeeAdmin ||
        isClearabeeManager ||
        isClearabeeCustomerServices ||
        isCompanyAdmin ||
        isCompanyStaff ||
        isViewOnly,
    },
    {
      title: t("dashboardV2.titles.openJobsToday"),
      imageComponent: jobsLengthCircle(todayOpenJobs?.length, "warning"),
      path: `jobs?status=Open&date=${dayjs(todayTime).format(
        "DD/MM/YYYY",
      )}&page=1`,
      isAuthorised:
        isClearabeeAdmin ||
        isClearabeeManager ||
        isClearabeeCustomerServices ||
        isCompanyAdmin ||
        isCompanyStaff ||
        isViewOnly,
    },
    {
      title: t("dashboardV2.titles.completedJobsToday"),
      imageComponent: jobsLengthCircle(todayCompletedJobs?.length, "accent"),
      path: `jobs?status=Completed&date=${dayjs(todayTime).format(
        "DD/MM/YYYY",
      )}&page=1`,
      isAuthorised:
        isClearabeeAdmin ||
        isClearabeeManager ||
        isClearabeeCustomerServices ||
        isCompanyAdmin ||
        isCompanyStaff ||
        isViewOnly,
    },
    {
      title: t("dashboardV2.titles.failedJobsToday"),
      imageComponent: jobsLengthCircle(todayFailedJobs?.length, "negative"),
      path: `jobs?status=Failed&date=${dayjs(todayTime).format(
        "DD/MM/YYYY",
      )}&page=1`,
      isAuthorised:
        isClearabeeAdmin ||
        isClearabeeManager ||
        isClearabeeCustomerServices ||
        isCompanyAdmin ||
        isCompanyStaff ||
        isViewOnly,
    },
  ];

  return (
    <Box className="mt-20">
      {/* Links Panels */}
      <Box className="w-full flex flex-row flex-wrap gap-2 mb-10">
        {linkPanels.map(
          (
            {
              path,
              title,
              backgroundColor,
              imageComponent,
              titleColor,
              isAuthorised = true,
            },
            index,
          ) => (
            <Fragment key={`link-panel-${title}-${index}`}>
              {isAuthorised && (
                <Box
                  styles={{
                    width: "45%",
                    position: "relative",
                    height: "160px",
                  }}
                >
                  <LinkPanelWithImage
                    title={title}
                    path={path}
                    imageComponent={imageComponent}
                    backgroundColor={backgroundColor}
                    titleColor={titleColor}
                    className="w-full h-full my-1"
                  />
                </Box>
              )}
            </Fragment>
          ),
        )}
      </Box>
      {/* Jobs Panels */}
      <Box className="w-full flex flex-row flex-wrap gap-2">
        {jobPanels.map(
          (
            {
              path,
              title,
              backgroundColor,
              imageComponent,
              titleColor,
              isAuthorised = true,
            },
            index,
          ) => (
            <Fragment key={`link-panel-${title}-${index}`}>
              {isAuthorised && (
                <Box
                  styles={{
                    width: "45%",
                    position: "relative",
                    height: "160px",
                  }}
                >
                  <LinkPanelWithImage
                    title={title}
                    path={path}
                    imageComponent={imageComponent}
                    backgroundColor={backgroundColor}
                    titleColor={titleColor}
                    className="w-full h-full my-1"
                  />
                </Box>
              )}
            </Fragment>
          ),
        )}
      </Box>
    </Box>
  );
};
