import { theme } from "../../../../theme";
import { StyleSheet, createResponsiveStyles } from "../../../../utils";

export const styles = StyleSheet({
  container: createResponsiveStyles({
    base: {
      width: `calc(100% - ${theme.spacing.medium})`,
      position: "fixed",
      top: 77,
      left: "50%",
      bottom: 0,
      transform: "translateX(-50%)",
      maxHeight: 520,
      zIndex: theme.stack.header,
      display: "none",
      justifyContent: "flex-end",
    },
    medium: {
      display: "flex",
    },
    xlarge: {
      maxWidth: theme.screens.xlarge,
      width: "100%",
    },
  }),
  panel: {
    width: "430px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingLeft: 0,
    paddingRight: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    overflowY: "scroll",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing.medium,
    paddingLeft: theme.spacing.xlarge,
    paddingRight: theme.spacing.xlarge,
  },
  closeContainer: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    cursor: "pointer",
    color: theme.colors.greyscale.base,
    transition: "color 0.5s",

    ":hover": {
      color: theme.colors.greyscale.lighter,
    },
  },
  itemsContainer: {
    flex: "1",
    overflowY: "auto",
    overflowX: "hidden",
    padding: `0 ${theme.spacing.xlarge}`,
    minHeight: theme.spacing.xlarge3,

    "::-webkit-scrollbar": {
      width: theme.spacing.xsmall,
    },
    "::-webkit-scrollbar-track": {
      background: theme.colors.greyscale.light,
      borderRadius: theme.spacing.xsmall2,
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.colors.brand.base,
      borderRadius: theme.spacing.small,
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: theme.colors.brand.light,
    },
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.small} 0`,
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemInfo: {
    display: "flex",
    flexDirection: "column",
  },
  alignTextIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  removeConfirmationIcon: {
    borderRadius: theme.spacing.xlarge,
    width: theme.spacing.xlarge,
    border: "none",
    aspectRatio: "1/1",
    cursor: "pointer",
    transition: "background 0.5s",
  },
  tickIcon: {
    background: theme.colors.positive.base,
    marginRight: theme.spacing.xsmall,
    ":hover": {
      background: theme.colors.positive.dark,
    },
  },
  crossIcon: {
    background: theme.colors.negative.base,

    ":hover": {
      background: theme.colors.negative.dark,
    },
  },
  itemIcon: {
    width: theme.spacing.xlarge2,
    objectFit: "contain",
    height: theme.spacing.xlarge2,
    background: theme.colors.light.base,
    marginRight: theme.spacing.small,
  },
  basketError: {
    marginTop: theme.spacing.medium,
    marginBottom: theme.spacing.medium,
    marginLeft: theme.spacing.xlarge,
    marginRight: theme.spacing.xlarge,
  },
  totalPriceContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: theme.colors.greyscale.lightest,
    padding: theme.spacing.small,
    margin: `0 ${theme.spacing.xlarge}`,
  },

  totalPrice: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.colors.accent.base,
    fontFamily: "ProximaBold",
    padding: `${theme.spacing.small} ${theme.spacing.medium}`,
    borderTopRightRadius: theme.spacing.xsmall,
    borderBottomRightRadius: theme.spacing.xsmall,
  },
  lowerFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.large,
    paddingLeft: theme.spacing.xlarge,
    paddingRight: theme.spacing.xlarge,
  },
  emptyBasket: {
    textAlign: "center",
    margin: `${theme.spacing.large} 0`,
  },
  popupOverlay: {
    zIndex: theme.stack.overlay,
    backgroundColor: theme.colors.light.base,
    opacity: 0.6,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  loader: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: theme.stack.header,
  },
  clearBasketContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    transition: "color 0.5s",
    padding: `${theme.spacing.small} ${theme.spacing.xlarge}`,
  },
  clearBasketIcon: {
    marginLeft: theme.spacing.xsmall,
    padding: theme.spacing.xsmall,
    backgroundColor: theme.colors.negative.base,
    borderRadius: "50%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
      backgroundColor: theme.colors.negative.dark,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.xsmall,
  },
  clearBasket: {
    position: "absolute",
    backgroundColor: theme.colors.light.base,
    zIndex: theme.stack.modal,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: theme.colors.dark.base,
    borderRadius: theme.spacing.xsmall,
  },
  clearBasketContent: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    height: "100%",
    justifyContent: "center",
    paddingLeft: theme.spacing.medium,
    paddingRight: theme.spacing.medium,
  },
  clearBasketButtons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing.medium,
    marginTop: theme.spacing.medium,
  },
  removeItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.dark.base,
    backgroundColor: theme.colors.light.base,
    marginTop: theme.spacing.medium,
    paddingBottom: 18,
    borderBottom: `1px solid ${theme.colors.greyscale.lightest}`,
  },
  removeButtonsWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
});
