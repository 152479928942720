import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useSpring, animated } from "react-spring";

/**
 * Import images.
 */
import { DottedCircle, BeeLogo } from "../../../images";

interface ILoadingSpinner {
  isLoading: boolean;
  classes?: {
    wrapper?: string;
    circle?: string;
    bee?: string;
    text?: string;
  };
}

interface INumberObject {
  number: number;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const LoadingSpinner: React.FC<ILoadingSpinner> = ({
  isLoading,
  classes = {},
}) => {
  const [loader, setLoader] = useState(false);
  const [props, set] = useSpring<INumberObject>(() => ({
    number: 0,
  }));

  useEffect(() => {
    /**
     * If app loading is true, set loader state to true and start number animation.
     */
    if (isLoading) {
      setLoader(true);

      /**
       * Maximum of 90%, so the loader can't hit 100%.
       */
      set({
        config: {
          duration: 10000,
        },
        number: 90,
      });
    }

    /**
     * If loader is true and app loading is false, set to 100%.
     */
    if (loader && !isLoading) {
      /**
       * Set loading numbers to 100% as request is finished.
       */
      set({
        config: {
          duration: 400,
        },
        number: 100,
      });

      /**
       * Create a timeout to fade the loading screen, so we can see the animation to 100%.
       */
      setTimeout(() => {
        setLoader(false);

        /**
         * Set back to zero for the next loading.
         */
        setTimeout(() => {
          set({
            config: {
              duration: 100,
            },
            number: 0,
          });
        }, 400);
      }, 600);
    }
  }, [isLoading, set, loader]);

  const { number } = props;

  return (
    <div className={cx("loading", { "loading-show": loader }, classes.wrapper)}>
      <DottedCircle className={cx("loading-circle w-64", classes.circle)} />
      <div className="loading-bee-wrap">
        <BeeLogo
          className={cx("w-16 h-16 absolute loading-bee", classes.bee)}
        />
      </div>
      <div className="absolute loading-numbers">
        <animated.span className={cx("text-5xl opacity-50", classes.text)}>
          {number.interpolate((val: number) => `${Math.floor(val)}%`)}
        </animated.span>
      </div>
    </div>
  );
};
