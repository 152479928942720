"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.common = void 0;
exports.common = {
    form: {
        errors: {
            validPhone: "Invalid phone number",
            validEmail: "This email is invalid",
            validNumber: "Invalid number",
            validPostcode: "Invalid postcode",
            requiredField: "This field is required",
            requiredFieldShort: "Required",
            tooShortField: "Minimum {{minimum}} characters",
            tooLongField: "Maximum {{maximum}} characters",
            matchField: "Passwords must match",
            mustBeChecked: "This field must be checked",
            validIncludeLetters: "Must include letters",
            validLettersOnly: "Must contain letters only",
            validDate: "This is an invalid date format",
        },
    },
};
