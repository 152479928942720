import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useQuery, useMutation } from "react-query";
import Select from "react-select";
import {
  Table,
  Field,
  Icon,
  Input,
  theme,
  Button,
  Form,
} from "@clearabee/ui-library";
import { instance } from "@clearabee/ui-sdk";
import { toasts } from "helpers";
import { useInstance, UseInstanceType } from "api/dsa";
import { rowInitialValues, rowValidationSchema } from "../validation";

interface RowProps {
  deleteRow: () => void;
  orderTypes:
    | Awaited<ReturnType<typeof instance.catalogues.getOrderTypes>>["data"]
    | undefined;
  subcontractors: Awaited<ReturnType<UseInstanceType["users"]["list"]>>["data"];
  updateExistingJobStore: (reference: string) => void;
}

export const Row = ({
  deleteRow,
  subcontractors,
  updateExistingJobStore,
  orderTypes,
}: RowProps): React.ReactElement => {
  const dsaApi = useInstance();

  const [reference, setReference] = useState("");

  const {
    data: dsaJob,
    isLoading: dsaJobloading,
    refetch: dsaRefetch,
  } = useQuery(
    ["bulkAllocationDSA", reference],
    () => dsaApi.jobs.list(`jobReference=${reference}`),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        if (data.data.length) {
          updateExistingJobStore(reference);
        }
      },
      onError: () => {
        toasts.error({
          message: `Failed to find the DSA job ${reference}`,
        });
      },
    },
  );

  const {
    data: job,
    isLoading: jobLoading,
    refetch,
  } = useQuery(
    ["bulkAllocationJobByRef", reference],
    () => instance.jobs.getJobByRef(encodeURIComponent(reference)),
    {
      enabled: !!reference,
      retry: false,
      onError: () => {
        toasts.error({
          message: `Failed to find the job ${reference}`,
        });
      },
      onSuccess: () => {
        dsaRefetch();
      },
    },
  );

  const {
    mutate: updateJob,
    isSuccess,
    isLoading,
    isError,
  } = useMutation<
    unknown,
    unknown,
    {
      contractors: string[];
      invoiceAmount: string;
      description: string;
      date: string;
    }
  >(
    ({ contractors, invoiceAmount, description, date }) => {
      if (!job?.data.bcId) {
        throw Error("Job missing BC reference");
      }
      const collectionDetails = {
        requestedDate: date ?? job.data.date,
        requestedTime: job.data.timeslot || "",
        collectionAddress: {
          addressLine1: String(job.data.addressLine1 ?? ""),
          addressLine2: String(job.data.addressLine2 ?? ""),
          city: job.data.addressCity || "",
          postcode: job.data.addressPostcode || "",
        },
      };
      if (dsaJob?.data?.length) {
        // PATCH
        return dsaApi.jobs.update(dsaJob?.data[0]._id.toString(), {
          rejectionReason: "",
          availableContractors: contractors,
          invoiceDetails: {
            amount: parseFloat(invoiceAmount).toFixed(2) || undefined,
          },
          wasteDetails: { description },
          collectionDetails,
        });
      }

      return dsaApi.jobs.add({
        jobId: String(job.data.id),
        jobReference: job.data.ref,
        bigChangeId: job.data.bcId.toString(),
        availableContractors: contractors,
        collectionDetails,
        customerDetails: {
          name: (
            job.data.contactFirstName +
            " " +
            job.data.contactLastName
          ).trim(),
          email: job.data.contactEmail || "",
          phoneNumber: job.data.contactPhoneNumber || "",
        },
        wasteDetails: {
          description: description ?? job.data.description ?? "",
        },
        invoiceDetails: {
          amount: parseFloat(invoiceAmount).toFixed(2) || undefined,
        },
      });
    },
    {
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
    },
  );

  const {
    mutate: patchJob,
    isLoading: isPatchLoading,
    isSuccess: isPatchSuccess,
    isError: isPatchError,
  } = useMutation(
    ({ description, date }: { description: string; date: string }) =>
      instance.jobs.patchJob(String(job?.data.id) ?? "", {
        description,
        date,
        contractAssigned: true,
      }),
    {
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
      onSuccess: () => {
        refetch();
      },
    },
  );

  const handleSubmit = ({
    description,
    subcontractors,
    invoiceAmount,
    date,
  }: typeof rowInitialValues) => {
    const formattedDate = dayjs.utc(date, "DD/MM/YYYY").toISOString();
    patchJob({ description, date: formattedDate });
    updateJob({
      contractors: subcontractors.flatMap((contractor) => contractor.value),
      invoiceAmount,
      description,
      date: formattedDate,
    });
  };

  return (
    <Table.Row>
      <Form
        renderFormElement={false}
        initialValues={rowInitialValues}
        onSubmit={handleSubmit}
        validationSchema={rowValidationSchema}
      >
        {({ isSubmitting, values, isValid, setValues, dirty }) => {
          const disableField =
            isSubmitting || jobLoading || dsaJobloading || isSuccess;

          useEffect(() => {
            setValues({
              ...values,
              description: job?.data.description || "",
              date: job?.data.date
                ? dayjs(job?.data.date).format("DD/MM/YYYY")
                : "",
              postcode: job?.data.addressPostcode || "",
              orderType:
                orderTypes?.items.find(
                  ({ id }) => id === job?.data.items?.[0].orderTypeId,
                )?.name || "",
            });
          }, [job]);

          const {
            jobReference,
            subcontractors: contractors,
            invoiceAmount,
            description,
            date,
          } = values;

          return (
            <>
              <Table.Cell>
                <>
                  {(isLoading || isPatchLoading) && (
                    <Icon.Loading size="medium" color="brand" />
                  )}
                  {(!isLoading || !isPatchLoading) &&
                    isSuccess &&
                    isPatchSuccess && (
                      <Icon.Tick2 size="medium" color="positive" />
                    )}
                  {!jobLoading && isError && isPatchError && (
                    <button
                      onClick={() =>
                        updateJob({
                          contractors:
                            contractors &&
                            contractors.flatMap(
                              (contractor) => contractor.value,
                            ),
                          invoiceAmount,
                          description,
                          date,
                        })
                      }
                    >
                      <Icon.Refresh size="medium" color="usp" />
                    </button>
                  )}
                </>
              </Table.Cell>
              <Table.Cell styles={{ display: "flex", flexDirection: "column" }}>
                <div className="flex gap-x-4">
                  <Field
                    name={`jobReference`}
                    styles={{ position: "relative", flex: 2 }}
                    label="Job Reference"
                  >
                    {({ field }) => (
                      <>
                        <Input.Text
                          {...field}
                          placeholder="Job Reference"
                          disabled={!!job || disableField}
                        />

                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            right: theme.spacing.small,
                            top: 29,
                          }}
                        >
                          {(jobLoading || dsaJobloading) && (
                            <Icon.Loading
                              styles={{ marginTop: theme.spacing.xsmall }}
                              size="small"
                              color="brand"
                            />
                          )}
                          {!jobLoading && !dsaJobloading && (
                            <Button
                              color="warning"
                              type="button"
                              variant="outline"
                              size="xsmall"
                              onClick={() => {
                                setReference(jobReference);
                              }}
                              disabled={
                                !jobReference ||
                                disableField ||
                                !!job ||
                                jobLoading
                              }
                              styles={{
                                ":disabled": {
                                  opacity: "50%",
                                  cursor: "not-allowed",
                                },
                              }}
                            >
                              Job lookup
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  </Field>
                  <Field
                    name="invoiceAmount"
                    label="Invoice Amount(£)"
                    styles={{ flex: 1 }}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder="Invoice Amount"
                        disabled={!job || disableField}
                      />
                    )}
                  </Field>
                </div>
                <Field name="description" label="Description">
                  {({ field }) => (
                    <Input.Textarea
                      disabled={!job || disableField}
                      autoGrow
                      {...field}
                    />
                  )}
                </Field>
              </Table.Cell>
              <Table.Cell>
                <Field name="subcontractors" label="Subcontractors">
                  {({ field }) => (
                    <Select
                      {...field}
                      isSearchable
                      isClearable
                      isMulti
                      isDisabled={!job || disableField}
                      options={subcontractors.map((item) => ({
                        label: item.company || item.email,
                        value: item.username,
                      }))}
                    />
                  )}
                </Field>
                <div className="flex gap-x-4">
                  <Field
                    name="date"
                    label="Collection Date"
                    styles={{ flex: 2 }}
                  >
                    {({ field }) => (
                      <Input.Date
                        {...field}
                        collapsable
                        explicitValue={job?.data.date ? field.value : ""}
                        disabledDays={{ before: dayjs().toDate() }}
                        dateFormat="DD/MM/YYYY"
                        disabled={!job || disableField}
                      />
                    )}
                  </Field>
                  <Field
                    name="orderType"
                    label="Order Type"
                    styles={{ flex: 2 }}
                  >
                    {({ field }) => <Input.Text {...field} disabled />}
                  </Field>
                  <Field name="postcode" label="Postcode" styles={{ flex: 1 }}>
                    {({ field }) => <Input.Text disabled {...field} />}
                  </Field>
                </div>
                <div className="flex items-center justify-end gap-x-4">
                  <button
                    onClick={() => deleteRow()}
                    css={{
                      borderRadius: "50%",
                      backgroundColor: theme.colors.negative.base,
                      padding: theme.spacing.xsmall,
                      ":disabled": {
                        backgroundColor: theme.colors.greyscale.lighter,
                        cursor: "not-allowed",
                      },
                    }}
                    type="button"
                    disabled={disableField || isPatchLoading}
                  >
                    <Icon.Trash size="small" color="light" />
                  </button>
                  <Button
                    size="small"
                    color="positive"
                    onClick={() => handleSubmit(values)}
                    disabled={
                      disableField || !isValid || isPatchLoading || !dirty
                    }
                  >
                    Save
                  </Button>
                </div>
              </Table.Cell>
            </>
          );
        }}
      </Form>
    </Table.Row>
  );
};
