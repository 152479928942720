import * as Yup from "yup";
import { translate } from "@clearabee/i18n";
import { email, phone, fieldRequired, name } from "validation/common";

export const validationSchema = Yup.object().shape({
  finalAmount: Yup.number()
    .positive()
    .required()
    .label(translate("portal.jobs.update.subcontractor.label.finalAmount")),

  notes: Yup.string().label(
    translate("portal.jobs.update.subcontractor.label.notes"),
  ),
});

export const getSubcontractorModalInitialValues = ({
  finalAmount,
  notes,
}: {
  finalAmount: string | undefined;
  notes: string | undefined;
}): { finalAmount: string; notes: string } => {
  return {
    finalAmount: finalAmount ?? "",
    notes: notes ?? "",
  };
};

export const updateJobValidation = Yup.object().shape({
  addressLine1: Yup.string(),
  addressCity: Yup.string(),
  addressCounty: Yup.string(),
  addressPostcode: Yup.string(),
  accessInformation: Yup.string(),
  contactEmail: email.required(fieldRequired),
  contactFirstName: name,
  contactLastName: name,
  contactPhoneNumber: phone,
  description: Yup.string(),
  date: Yup.string(),
  collectionInformation: Yup.object({
    estimatedWeight: Yup.string(),
    cubicYardsCollected: Yup.string(),
    wasteTypes: Yup.string(),
  }),
  disposalInformation: Yup.object({
    placeOfDisposal: Yup.string(),
    permitNumber: Yup.string(),
    landfillDiversionRate: Yup.string(),
  }),
});
