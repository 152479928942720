import React from "react";
import { Link } from "react-router-dom";

/**
 * Import components.
 */
import { ProgressBar } from "../../../core";

/**
 * Import hooks.
 */
import { useAuthContext, useMultiFormContext } from "../../../../hooks";

/**
 * Import images.
 */
import { LeftChevron } from "../../../../images";

/**
 * Import types.
 */
import { ICreateJobFormState } from "../createJob";
import roles from "constants/roles";

export const Wrapper = ({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement => {
  const { steps, previousStep, activeStep, setStep } =
    useMultiFormContext<ICreateJobFormState>();
  const { doesUserHaveRole } = useAuthContext();

  const isBookOnly = doesUserHaveRole(roles.BOOK_ONLY);

  /**
   * Progress bar steps.
   */
  const progressBarItems: string[] = steps.map((step) => step.name || "");

  return (
    <>
      <div className="pt-10 text-center">
        <h3 className="text-xl font-bold text-primary">
          {steps[activeStep]?.name || steps[activeStep].id}
        </h3>
        {steps[activeStep]?.description && (
          <div>{steps[activeStep]?.description}</div>
        )}
      </div>
      <div className="max-w-screen-lg mx-auto lg:py-10">
        <div className="flex justify-between items-center">
          <div className="w-1/3 mt-4 lg:mt-0">
            {activeStep !== 0 && (
              <button
                className="text-sm flex items-center justify-start"
                type="button"
                onClick={previousStep}
              >
                <span>
                  <LeftChevron />
                </span>
                <span className="ml-2">Previous step</span>
              </button>
            )}
            {activeStep === 0 && !isBookOnly && (
              <Link
                to="/jobs"
                className="text-sm inline-flex items-center justify-start"
              >
                <span>
                  <LeftChevron />
                </span>
                <span className="ml-2">Back to jobs</span>
              </Link>
            )}
          </div>
          <div className="hidden lg:block w-2/3">
            <ProgressBar
              items={progressBarItems}
              currentItem={activeStep}
              setStep={(step) => setStep(step)}
            />
          </div>
        </div>
        <div className="mt-5">{children}</div>
      </div>
    </>
  );
};
