import React from "react";
import cx from "classnames";
import Select from "react-select";

/**
 * Import components.
 */
import { OptionWithIconAndSubtext, SingleValue } from "./components";

/**
 * Type props for select field.
 */
type TSimpleSelect = {
  name: string;
  classes?: string;
  options: ISelectOption[];
  components?: Record<string, unknown>;
  disabled?: boolean;
  onChange: (value: any, actionMeta: any) => void;
  selectedValue?: ISelectOption;
  menuPlacement?: "top" | "bottom";
  noText?: boolean;
};

/**
 * Type props for select option.
 */
export interface ISelectOption {
  label: string;
  value: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SimpleSelect: React.FC<TSimpleSelect> = ({
  name,
  classes,
  options,
  components = {},
  disabled = false,
  onChange,
  selectedValue = {
    value: options[0].value,
    label: options[0].label,
  },
  menuPlacement = "bottom",
  noText = true,
}) => {
  /**
   * Override components if set.
   */
  const overrideComponents = {
    Option: OptionWithIconAndSubtext,
    SingleValue,
    ...components,
  };

  /**
   * An object is created with all of the set values.
   */
  const field = {
    name,
    id: `field-${name}`,
    classes,
    options,
    components: overrideComponents,
  };

  /**
   * Classes for select container.
   */
  field.classes = cx(
    "form-select",
    noText ? "form-select-simple" : "",
    field.classes,
    `select-${field.name}`,
  );

  /**
   * Output the select. If label is set, we will also output that too.
   */
  return (
    <Select
      name={field.name}
      id={field.id}
      className={field.classes}
      onChange={onChange}
      defaultValue={selectedValue}
      components={field.components}
      options={field.options}
      classNamePrefix="form-select-dropdown"
      isDisabled={disabled}
      menuPlacement={menuPlacement}
      isSearchable={false}
      isClearable={false}
    />
  );
};
