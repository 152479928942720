"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.feedback = void 0;
exports.feedback = {
    pageHeading: "Leave us Feedback",
    successHeading: "Thank you for your feedback",
    labels: {
        collectionReference: "Collection Reference",
        rating: "Overall, how happy were you with our service?",
        name: "Your name or company name",
        email: "Email Address",
        message: "Message",
        emailOptIn: "Email Opt-In",
        emailOptInInfo: "Send me info about discounts, new services and major updates.",
        submitButton: "Send feedback",
        yourRatings: "Your ratings",
        yourMessage: "Your message",
        copyButton: "Copy your comments",
    },
    placeholders: {
        message: "If you have time, please let us know any specific comments or feedback you may have?",
    },
    errors: {
        failedRequest: "Sorry, something went wrong when processing your request.",
    },
};
