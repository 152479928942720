"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hyphenatedToNormal = void 0;
var capitalise_1 = require("./capitalise");
var hyphenatedToNormal = function (text) {
    return text
        .split("-")
        .map(function (value) { return (0, capitalise_1.capitalise)(value); })
        .join(" ");
};
exports.hyphenatedToNormal = hyphenatedToNormal;
