/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const RefreshIcon: React.FC<TGenericImage> = ({
  className = "travelling-bee",
  fill = "currentColor",
  width = 14,
  height = 14,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <path
        fill={fill}
        d="M13.1 6.059a.512.512 0 10-1.01.17 5.637 5.637 0 11-1.4-2.863l-2.268.756a.512.512 0 00.323.972l3.072-1.024c.21-.07.35-.266.35-.486V.512a.512.512 0 10-1.023 0v1.87A6.589 6.589 0 1013.1 6.06z"
      />
    </svg>
  );
};
