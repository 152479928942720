import React, { useRef } from "react";
import { useSpring, animated, UseSpringProps } from "react-spring";
import { easeQuad } from "d3-ease";

/**
 * Import prop types.
 */
import { ITransition } from "./types";

/**
 * Set animation configurations
 */
import { transitionSettings } from "./transitionSettings";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SpringTransition: React.FC<ITransition> = ({
  className,
  transition = "fadeIn",
  distance = 40,
  delay = 0,
  duration = 500,
  component = "div",
  children,
  style = {},
  toggle = true,
  onRest,
  customSettings,
}) => {
  const initialState = useRef(toggle);
  const options = {
    distance,
  };

  /**
   * Retrieve set animation configuration
   */
  const getSettings = transitionSettings[transition];

  /**
   * Newly created animated component
   */
  const TransitionComponent = animated[component];

  let settings: UseSpringProps<Record<string, unknown>> = {
    config: {
      duration,
      easing: easeQuad,
    },
    delay,
    onRest,
    reset: initialState.current !== toggle,
    reverse: initialState.current !== toggle,
  };

  const springSettings: any = getSettings(options);
  springSettings.leave = undefined;
  springSettings.to = springSettings.enter;

  settings = customSettings
    ? { ...settings, ...customSettings }
    : { ...settings, ...springSettings };

  /**
   * Animation styles
   *
   * If custom settings set use those instead, else use predefined settings.
   */
  const animationStyles = useSpring(settings);

  return (
    <TransitionComponent
      className={className}
      style={{ ...animationStyles, ...style }}
    >
      {children}
    </TransitionComponent>
  );
};
