import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  container: {
    [`@media (min-width: ${theme.screens.xlarge3})`]: {
      position: "absolute",
      top: "120px",
      bottom: "88px",
      left: "0",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  },
  section: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "0",
  },
  searchContainer: {
    padding: `${theme.spacing.small} ${theme.spacing.medium} 0`,
    [`@media (min-width: ${theme.screens.xlarge3})`]: {
      padding: theme.spacing.medium,
      background: theme.colors.brand.darker,
    },
  },
  searchBox: {
    color: theme.colors.dark.base,
  },
  divider: {
    borderColor: `${theme.colors.light.base}66`,
  },
});
