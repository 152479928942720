import React, { useState } from "react";
import { Modal } from "../Modal/Modal";
import { Icon } from "../../Core/Icon/Icon";
import { Text } from "../../Core/Text/Text";
import { styles } from "./Gallery.styles";

export interface GalleryItem {
  source: string;
  type?: string;
  alt?: string;
  caption?: string;
}

interface ChildrenProps {
  items: GalleryItem[];
  handleClick: (index: number) => void;
}
export interface GalleryProps {
  items: GalleryItem[];
  children?: (props: ChildrenProps) => React.ReactNode;
}

export const Gallery = ({
  items,
  children,
}: GalleryProps): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const next = () => {
    if (selectedItemIndex === items.length - 1) {
      return;
    }

    setSelectedItemIndex(selectedItemIndex + 1);
  };

  const prev = () => {
    if (selectedItemIndex === 0) {
      return;
    }

    setSelectedItemIndex(selectedItemIndex - 1);
  };

  const handleClick = (index: number) => {
    setSelectedItemIndex(index);
    setShowModal(true);
  };

  return (
    <>
      {children ? (
        children({ items, handleClick })
      ) : (
        <ul css={styles.list}>
          {items.map((item, index) => (
            <li key={index} css={styles.listItem}>
              <img
                src={item.source}
                css={styles.listImage}
                onClick={() => handleClick(index)}
              />
            </li>
          ))}
        </ul>
      )}

      {showModal && (
        <Modal styles={styles.modal}>
          <div css={styles.buttonGrid}>
            <div>
              <button
                css={styles.button}
                disabled={selectedItemIndex === 0}
                onClick={prev}
              >
                <Icon.Chevron
                  size="medium"
                  styles={{ transform: "rotate(180deg)", paddingTop: "2px" }}
                />
              </button>
              <button
                css={styles.button}
                disabled={selectedItemIndex === items.length - 1}
                onClick={next}
              >
                <Icon.Chevron size="medium" />
              </button>
            </div>
            <div>
              <button css={styles.button} onClick={() => setShowModal(false)}>
                <Icon.Close size="medium" />
              </button>
            </div>
          </div>

          <div css={styles.imageWrapper}>
            <img
              src={items[selectedItemIndex].source}
              alt={items[selectedItemIndex].alt}
              css={styles.modalImage}
            />
            {items[selectedItemIndex].caption && (
              <Text fontSize="small" styles={styles.caption}>
                {items[selectedItemIndex].caption}
              </Text>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
