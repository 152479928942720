import React from "react";
import { css } from "@emotion/react";
import {
  Box,
  Icon,
  IconProps,
  UserStylesProps,
  BoxProps,
} from "@clearabee/ui-library";
import { styles } from "./loadingOverlay.styles";

interface LoadingOverlayProps extends UserStylesProps<BoxProps> {
  iconSize?: IconProps["size"];
  IconColor?: IconProps["color"];
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  iconSize,
  IconColor,
  styles: userStyles,
  ...rest
}) => {
  return (
    <Box styles={css(styles.default, userStyles)} {...rest}>
      <Box styles={styles.loadingIconContainer}>
        <Icon.Loading
          size={iconSize ? iconSize : "xlarge4"}
          color={IconColor ? IconColor : "brand"}
        />
      </Box>
      <Box styles={styles.loadingBgContainer} />
    </Box>
  );
};
