import { StyleSheet } from "../../utils/stylesheet";
import { theme } from "../../theme";

export const styles = StyleSheet({
  wrapper: {
    ...theme.fontDefaults.base,
    width: "100%",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    boxSizing: "border-box",
    margin: "auto",
  },
  carousel: {
    display: "flex",
    alignItems: "center",
  },
  stepButton: {
    ...theme.fontDefaults.base,
    width: theme.spacing.large,
    height: theme.spacing.large,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "100%",
    background: "none",
    cursor: "pointer",
    "&:disabled": {
      pointerEvents: "none",
      color: theme.colors.greyscale.lightest,
    },
  },
  pageNumber: {
    ...theme.fontDefaults.xsmall,
    width: theme.spacing.large,
    height: theme.spacing.large,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none",
    border: "none",
    fontWeight: 600,
    cursor: "pointer",
    active: {
      pointerEvents: "none",
      padding: theme.spacing.xsmall,
      borderRadius: "100%",
      color: theme.colors.light.base,
      backgroundColor: theme.colors.brand.base,
      "&:hover": {
        color: theme.colors.light.base,
      },
    },

    "&:hover": {
      color: theme.colors.brand.light,
      transition: "200ms",
    },
  },
  ellipsis: {
    width: theme.spacing.large,
    textAlign: "center",
  },
  rowPerPageWrap: {
    marginLeft: theme.spacing.medium,
  },
  resultsDropdownWrap: {
    marginRight: theme.spacing.small,
    marginLeft: theme.spacing.small,
    width: 90,
  },
});
