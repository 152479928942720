import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

/**
 * Import components.
 */
import { Steps } from "./components";
import { FormInput, FormButton, ModalForm } from "../../core";

/**
 * Import api endpoints.
 */
import { getJobByOrderRef } from "../../../api";

/**
 * Import helpers.
 */
import { toasts } from "../../../helpers";

/**
 * Import validation schema and initial values.
 */
import { findJobSchema, findJobInitialValues } from "./validation";

/**
 * Import types.
 */
import { Job } from "models";

/**
 * Additional job payment component.
 */
export const AdditionalJobPayment = (): React.ReactElement => {
  /**
   * Translations.
   */
  const [t] = useTranslation("jobs");

  /**
   * State.
   */
  const [job, setJob] = useState<Job | null>(null);
  const { bcId } = job || {};

  /**
   * Get job by ref and postcode.
   */
  const getJob = async (ref: string, postcode: string) => {
    try {
      const data = await getJobByOrderRef(encodeURIComponent(ref), postcode);
      setJob(data);
    } catch (error) {
      const { response } = error as AxiosError;
      toasts.error({
        message: response?.data.message || (error as Error).message,
      });
    }
  };

  return (
    <>
      {job && bcId ? (
        <Steps job={job} />
      ) : (
        <ModalForm
          visible={true}
          title={t("payment.title")}
          form={
            <Formik
              initialValues={findJobInitialValues}
              validationSchema={findJobSchema}
              onSubmit={(values) => getJob(values.reference, values.postcode)}
            >
              {({ handleSubmit, isSubmitting, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    data-testid="takeAdditionalPaymentIdentifier"
                    className="mt-6"
                  >
                    <FormInput
                      name="reference"
                      type="reference"
                      placeholder={t("payment.form.placeholders.reference")}
                      label={{
                        text: t("payment.form.labels.reference"),
                      }}
                      floatingLabel={false}
                      required
                    />
                  </div>
                  <div className="mt-6">
                    <FormInput
                      name="postcode"
                      type="postcode"
                      placeholder={t("payment.form.placeholders.postcode")}
                      label={{
                        text: t("payment.form.labels.postcode"),
                      }}
                      floatingLabel={false}
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <FormButton
                      name="submit"
                      type="submit"
                      text={t("payment.form.buttons.submit")}
                      variant="secondary"
                      className="w-full"
                      disabled={!dirty || !isValid || isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          }
        />
      )}
    </>
  );
};
