import { theme } from "../../theme";
import { StyleSheet, createResponsiveStyles } from "../../utils";

export const styles = StyleSheet({
  footer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  container: createResponsiveStyles({
    base: {
      width: `calc(100% - ${theme.spacing.large})`,
      margin: "0 auto",
      paddingTop: theme.spacing.xlarge,
    },
    xlarge: {
      width: `calc(${theme.screens.xlarge} - ${theme.spacing.large})`,
    },
  }),
  alignRight: createResponsiveStyles({
    large: {
      marginLeft: "auto",
    },
  }),
  companyDetails: createResponsiveStyles({
    base: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      padding: 0,
      margin: 0,
    },

    medium: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
  }),
  headingContainer: createResponsiveStyles({
    base: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      textAlign: "center",
    },

    medium: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    large: {
      textAlign: "left",
    },
  }),
  topFooter: createResponsiveStyles({
    base: {
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
    },

    large: {
      flexFlow: "row",
      alignItems: "initial",
    },
  }),
  topLogo: createResponsiveStyles({
    base: {
      marginBottom: theme.spacing.small,
    },

    large: {
      display: "none",
    },
  }),
  topSocialLinks: createResponsiveStyles({
    base: {
      display: "none",
    },

    large: {
      display: "initial",
    },
  }),
  middleFooter: createResponsiveStyles({
    base: {
      display: "flex",
      flexFlow: "column-reverse",
      alignItems: "center",
      margin: "0 auto",
      marginTop: theme.spacing.large,
    },

    large: {
      flexFlow: "row",
      alignItems: "flex-end",
    },
  }),
  middleFooterContainer: createResponsiveStyles({
    base: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      padding: 0,
      margin: 0,
    },

    medium: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
  }),
  bottomFooter: createResponsiveStyles({
    base: {
      display: "flex",
      flexFlow: "column",
      marginBottom: theme.spacing.large,
      alignItems: "center",
    },

    xlarge: {
      flexFlow: "row",
      alignItems: "initial",
    },
  }),
  bottomLogo: createResponsiveStyles({
    base: {
      display: "none",
    },

    large: {
      display: "initial",
    },
  }),
  bottomFooterExtras: createResponsiveStyles({
    xlarge: {
      marginLeft: "auto",
    },
  }),
  bottomSocialLinks: createResponsiveStyles({
    large: {
      display: "none",
    },
  }),
  paymentMethods: createResponsiveStyles({
    base: {
      textAlign: "center",
    },

    xlarge: {
      textAlign: "right",
    },
  }),
});
