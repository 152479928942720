"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.website = void 0;
const feedback_1 = require("./feedback");
const partners_1 = require("./partners");
const common_1 = require("./common");
const quote_1 = require("./quote");
exports.website = {
    feedback: feedback_1.feedback,
    partners: partners_1.partners,
    common: common_1.common,
    quote: quote_1.quote,
};
