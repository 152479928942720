import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export const styles = StyleSheet({
  list: {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
    margin: 0,
  },
  separator: {
    padding: `0px ${theme.spacing.xsmall2}`,
  },
  active: {
    fontWeight: 600,
  },
});
