import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import {
  styles,
  AllowedColorKeys,
  AllowedSizeKeys,
  outlineStyles,
  defaultStyle,
  sizeStyles,
} from "./Button.styles";

type ButtonDetailedProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type AnchorDetailedProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type DetailedProps = ButtonDetailedProps & AnchorDetailedProps;

export interface ButtonProps extends UserStylesProps<DetailedProps> {
  color?: AllowedColorKeys;
  variant?: "fill" | "outline";
  as?: "button" | "a";
  size?: AllowedSizeKeys;
}

export const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
>(
  (
    {
      color = "brand",
      variant = "fill",
      as = "button",
      disabled = false,
      onClick,
      children,
      styles: userStyles,
      size = "large",
      ...rest
    },
    ref,
  ): React.ReactElement => {
    const Component = as;

    /*
      Ref has to be type-casted to as any because there is no straight-forward way
      of creating a generic forwardRef. In this case we need ref to be of type React.ForwardedRef<HTMLAnchorElement>
      or React.ForwardedRef<HTMLButtonElement> dependant on "as" prop.

      Ref
      https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref/58473012
    */

    return (
      <Component
        ref={ref as any}
        css={css(
          defaultStyle,
          variant === "fill" && styles[color],
          variant === "outline" && outlineStyles[color],
          sizeStyles[size],
          userStyles,
        )}
        {...(disabled ? {} : { onClick })}
        disabled={as === "button" && disabled ? disabled : undefined}
        {...rest}
        className={rest.className ?? "" + (disabled ? " disabled" : "")}
        href={as === "a" && !disabled ? rest.href : undefined}
      >
        {children}
      </Component>
    );
  },
);
