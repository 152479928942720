import React, { useEffect } from "react";
import { Field, Input } from "@clearabee/ui-library";
import { getCatalogueTemplate } from "api";
import { useFormikContext } from "formik";
import { ItemFormValues } from "components/catalogues/components/createUpdateItemForm";
import { useQuery } from "react-query";
import { useDebounce } from "hooks";
import { useTranslation } from "react-i18next";

interface SKUFieldProps {
  label: string;
  placeholder: string;
  disabled?: boolean;
  onLoading: (loading: boolean) => void;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SKUField = ({
  label,
  placeholder,
  disabled,
  onLoading,
}: SKUFieldProps): React.ReactElement => {
  const { values, errors, setErrors, touched } =
    useFormikContext<ItemFormValues>();
  const value = useDebounce(values.sku || "", 500);
  const [translate] = useTranslation("catalogues");
  const error = () =>
    setErrors({
      ...errors,
      sku: translate("form.errors.duplicateSku"),
    });

  const { data, isFetching } = useQuery(
    ["getCatalogueTemplate", value],
    () => getCatalogueTemplate(value),
    {
      retry: 0,
      staleTime: 0,
      enabled: !!value && !errors.sku,
      onSettled: (template) => {
        if (!!template && !errors.sku) {
          error();
        }
      },
    },
  );

  useEffect(() => {
    if (touched.sku && !!data) {
      setTimeout(() => {
        error();
      }, 0);
    }
  }, [touched.sku]);

  useEffect(() => {
    onLoading(isFetching);
  }, [isFetching]);

  return (
    <Field name="sku" label={label}>
      {({ field }) => (
        <Input.Text
          type="text"
          placeholder={placeholder}
          {...field}
          disabled={disabled}
        />
      )}
    </Field>
  );
};
