import React, { useRef, useContext, useEffect } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";

import {
  assignCatalogueToCompanies,
  createCatalogueItems,
  deleteCatalogueItems,
  getCatalogue,
  removeCatalogueFromCompanies,
  updateCatalogue,
  updateCatalogueItems,
} from "api";
import { Catalogue } from "api/types";
import { toasts } from "helpers";
import {
  Button,
  displayErrorMessage,
  extractMessageFromError,
  Icon,
  Message,
  Modal,
  theme,
} from "@clearabee/ui-library";
import { ErrorMessage } from "../../common/components";
import {
  CatalogueFormValues,
  SideBar,
  CreateUpdateCatalogueForm,
  CatalogueEditor,
  CatalogueEditorContext,
} from "../components";
import { CatalogueItem } from "models";
import { RouteLink } from "components/core";
import { CatalogueTabs } from "../components/catalogueTabs";
import {
  flattenCatalogue,
  calculateNewItems,
  calculatePatched,
  calculateDeleted,
} from "../helpers";
import {
  parseFormToCatalogue,
  StateData,
} from "../components/createUpdateCatalogueForm";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

interface CatalogueChanges {
  cataloguePatch: Partial<Catalogue>;
}

interface UpdateCatalogueWrapProps {
  initialData: CatalogueItem[];
}

const UpdateCatalogueWrap = ({
  initialData,
}: UpdateCatalogueWrapProps): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [translate] = useTranslation("catalogues");
  const {
    catalogue,
    companies,
    items,
    isLoading: isCatalogueLoading,
    refetch,
  } = useContext(CatalogueEditorContext);

  const handleApiCalls = async ({
    cataloguePatch,
  }: CatalogueChanges): Promise<void> => {
    await updateCatalogue(id, cataloguePatch);
    const flatInitial = flattenCatalogue(initialData, id);
    const flatNew = flattenCatalogue(items, id);

    const patchedItems = calculatePatched(flatInitial, flatNew);
    const newItems = calculateNewItems(flatInitial, flatNew);
    const deletedItems = calculateDeleted(flatInitial, flatNew);

    const companiesAssign =
      companies?.filter(
        (company) => !catalogue!.companies.some(({ code }) => company === code),
      ) || [];
    const companiesRemove = catalogue!.companies.flatMap(({ code }) =>
      !companies?.some((company) => company === code) ? [code] : [],
    );

    if (patchedItems.length > 0) await updateCatalogueItems(id, patchedItems);
    if (newItems.length > 0) await createCatalogueItems(id, newItems);
    if (deletedItems.length > 0) await deleteCatalogueItems(id, deletedItems);

    if (companiesAssign.length > 0) {
      await assignCatalogueToCompanies(id, companiesAssign);
    }
    if (companiesRemove.length > 0) {
      await removeCatalogueFromCompanies(id, companiesRemove);
    }
  };

  const {
    mutate,
    isLoading: isMutationLoading,
    isSuccess,
    reset,
    error,
  } = useMutation(handleApiCalls, {
    onSuccess: () => {
      refetch?.();
    },
  });

  const handleSubmit = async (values: CatalogueFormValues) => {
    mutate({
      cataloguePatch: parseFormToCatalogue(values),
    });
  };

  const emptyValues: CatalogueFormValues = {
    title: "",
    description: "",
    hasVat: false,
    vatRate: "",
    active: false,
    public: false,
  };
  const initialValues: CatalogueFormValues = catalogue
    ? {
        ...catalogue,
        description: catalogue.description ?? "",
        vatRate: catalogue.vatRate?.toString() ?? "",
      }
    : emptyValues;

  return (
    <>
      <Link to="/catalogues" className="inline-flex items-center mb-5">
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues"),
        })}
      </Link>
      {displayErrorMessage(error, (props) => (
        <DefaultErrorComponent
          {...props}
          styles={{
            marginTop: theme.spacing.small,
            marginBottom: theme.spacing.small,
            maxWidth: theme.screens.small,
          }}
          onClose={reset}
        />
      ))}
      <CreateUpdateCatalogueForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        heading={`${translate("headings.edit")} ${initialValues.title}`}
        disableSubmit={isMutationLoading || isSuccess}
        isLoading={isCatalogueLoading || isMutationLoading}
      />

      <SideBar />
      <CatalogueTabs isLoading={isMutationLoading} />

      {isSuccess && (
        <Modal
          heading={translate("modal.catalogueUpdate.heading")}
          width={500}
          actions={
            <>
              <Button
                size="medium"
                color="negative"
                type="button"
                onClick={reset}
                className="mr-3"
              >
                {translate("modal.catalogueUpdate.goBack")}
              </Button>
              <RouteLink href="/catalogues">
                <Button
                  size="medium"
                  color="accent"
                  type="button"
                  className="ml-3"
                  as="a"
                >
                  {translate("common:backTo", {
                    location: translate("common:routes.catalogues"),
                  })}
                </Button>
              </RouteLink>
            </>
          }
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("modal.catalogueUpdate.message")}
          </div>
        </Modal>
      )}
    </>
  );
};

export const UpdateCatalogue = (): React.ReactElement => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation<StateData | undefined>();
  const history = useHistory();
  const stateRef = useRef<StateData | undefined>();

  useEffect(() => {
    // when passing a state to another component, it will stay stored
    // we need to remove it as it will cause issues on refresh
    if (location.state) {
      history.replace({ ...location, state: undefined });
    }
  }, []);

  const {
    isLoading,
    isError,
    data: queryCatalogue,
    refetch,
  } = useQuery(["getCatalogue", id], () => getCatalogue(id), {
    cacheTime: 0,
    staleTime: 0,
  });

  if (location.state) {
    stateRef.current = location.state;
  }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Icon.Loading color="brand" size="xlarge2" data-testid="loading" />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <CatalogueEditor
      catalogue={queryCatalogue ?? stateRef.current?.catalogue ?? undefined}
      isLoading={!stateRef.current && isLoading}
      refetch={refetch}
    >
      <UpdateCatalogueWrap initialData={queryCatalogue?.items ?? []} />
    </CatalogueEditor>
  );
};
