import React from "react";

/**
 * Import types.
 */
import { Category } from "../../../../models/category";
import { FormSelect } from "../../../core";
import { Formik } from "formik";

type TFilters = {
  categories: Array<Category> | null;
  selectedCategories: Array<Category["sku"]>;
  setSelectedCategories: (
    newSelectedCategories: Array<Category["sku"]>,
  ) => void;
};

export const Filters: React.FC<TFilters> = ({
  categories,
  selectedCategories,
  setSelectedCategories,
}) => {
  if (categories === null) {
    return null;
  }

  const filteredCategories = categories.filter(
    (category) => !category.parentSku && category.title !== "WEEE Kerbside",
  );

  return (
    <>
      <div className="bg-white shadow-filter px-6 py-6 rounded-lg">
        <Formik
          initialValues={{ category: selectedCategories }}
          onSubmit={() => {
            return;
          }}
        >
          {({ setFieldValue }) => (
            <FormSelect
              name="category"
              placeholder={"Select a category"}
              alwaysSetValue={false}
              floatingLabel={false}
              searchable={true}
              clearable={true}
              isMulti={false}
              options={filteredCategories.map((category) => ({
                value: category.sku,
                label: category.title,
              }))}
              label={{
                text: "Filter by category",
              }}
              onChange={(option) => {
                setFieldValue("category", option);
                setSelectedCategories(option ? [option.value] : []);
              }}
            />
          )}
        </Formik>
      </div>
    </>
  );

  // Uncomment the below when API allows for multiple product categories
  /*const displayCategoryFilters = (parentSku?: string, level = 0) => {
    const filteredCategories = categories.filter(
      (category) => category.parentSku === parentSku
    )

    if (level > 2) {
      return null
    }

    return (
      <>
        {filteredCategories.map((category) => {
          const { sku, title } = category
          const categoryIsSelected = selectedCategories.includes(sku)
          const disableCategory = !noCategoriesSelected && !categoryIsSelected

          return (
            <React.Fragment key={`category-${sku}`}>
              <div
                className={cx(
                  "mb-3",
                  level === 1 ? "ml-3" : level === 2 ? "ml-6" : "ml-0",
                  typeof title === "undefined" ? "hidden" : ""
                )}
              >
                <input
                  id={`category-${sku}`}
                  type="checkbox"
                  name="category"
                  className={cx(
                    disableCategory ? "cursor-not-allowed" : "cursor-pointer"
                  )}
                  value={title || ""}
                  checked={categoryIsSelected}
                  onChange={() => {
                    const addCategoryToSelected = [...selectedCategories, sku]
                    const removeCategoryFromSelected =
                      selectedCategories.filter((sc) => sc !== sku)
                    setSelectedCategories(
                      categoryIsSelected
                        ? removeCategoryFromSelected
                        : addCategoryToSelected
                    )
                  }}
                  disabled={disableCategory}
                />
                <label
                  htmlFor={`category-${sku}`}
                  className={cx(
                    "ml-2 text-sm",
                    disableCategory ? "text-gray-600 cursor-not-allowed" : ""
                  )}
                >
                  {categoryIsSelected ? (
                    <span className="font-semibold">{title || ""}</span>
                  ) : (
                    <>{title || ""}</>
                  )}
                </label>
              </div>
              {displayCategoryFilters(sku, level + 1)}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  return (
    <div className="bg-white shadow-filter px-6 py-6 rounded-lg">
      <div className="mb-3">
        <input
          id="category-all"
          type="radio"
          name="category"
          className="cursor-pointer"
          value="all"
          checked={noCategoriesSelected}
          onChange={() => setSelectedCategories([])}
        />
        <label htmlFor="category-all" className="ml-3 cursor-pointer">
          {noCategoriesSelected ? (
            <span className="font-semibold">All</span>
          ) : (
            <>All</>
          )}
        </label>
      </div>
      {displayCategoryFilters()}
    </div>
  )*/
};
