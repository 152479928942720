"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jobs = void 0;
exports.jobs = {
    each: "each",
    copy: "Copy",
    noData: "No data available",
    resend: "Resend",
    cancel: "Cancel",
    loadingMessage: "Loading...",
    completeOrder: "Complete Order",
    noBigChangeId: "No Big Change ID",
    labels: {
        dateCreated: "Date Created",
        images: "Images",
        products: "Products",
        orderType: "Order Type",
        creditUsed: "Credit Used",
        bigChangeId: "Big Change ID",
        timeSlot: "Time slot",
        meta: "Meta",
    },
    headings: {
        bookingLimitReached: "You have reached your limit for bookings on this date. Please try selecting a different date.",
        jobDetails: "Job details",
        jobResult: "Job Result",
        status: "Status",
        collectionDate: "Collection Date",
        jobType: "Job Type",
        jobReference: "Job Reference",
        orderReference: "Order Reference",
        invoices: "Invoices",
        wasteTransferNotes: "Waste Transfer Notes",
        getStarted: "Get started",
        vehicle: "Assigned Vehicle",
        companyCode: "Company Code",
        basket: "Basket",
        total: "Totals",
        collectionDetails: "Collection Details",
        customerDetails: "Customer Details",
        wasteDetails: "Waste Details",
        recentJobs: "Recent Jobs",
        completedJobDetails: "Completed Job Details",
        subcontractorData: "Information Submitted By The Subcontractor",
        additonalInformation: "Additional Information",
    },
    deliveryAddress: {
        line1: "Line 1",
        city: "City",
        county: "County",
        postcode: "Postcode",
    },
    contact: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email Address",
        phoneNumber: "Phone Number",
    },
    description: "Waste Description",
    accessInformation: "Access Information",
    toasts: {
        resendSuccess: "Successfully resent!",
        cancelSuccess: "Payment link has been cancelled",
        error: "An error occurred. Please try again.",
        answersUpdated: "Answers updated",
        jobApproved: "Job approved",
    },
    form: {
        label: {
            accessInformation: "Access Information",
            addressLine1: "Address line 1",
            addressLine2: "Address line 2",
            city: "City",
            company: "Company",
            county: "County",
            collectionDate: "Collection Date",
            date: "Date",
            email: "Email Address",
            name: "Name",
            phone: "Phone",
            poNumber: "PO Number",
            postcode: "Postcode",
            purchaseOrder: "PO Number",
            reference: "Job Reference",
            search: "Search",
            wasteDescription: "Waste Description",
            zendeskTicketId: "Zendesk Ticket ID",
            weight: "Weight",
            volumeCubicYards: "Volume (cubic yards)",
            placeOfDisposal: "Place of Disposal",
            permitNumber: "Permit Number",
            wasteTypes: "Waste Types",
            landfillDiversionRate: "Landfill Diversion Rate",
        },
        placeholder: {
            searchByEmailAddress: "Search by email address...",
            status: "Status",
            selectCompany: "Select Company",
            selectADate: "Select a Date",
            bookingDate: "Booking Date",
            dateCreated: "Date Created",
            company: "$t(form.label.company)",
            purchaseOrder: "$t(form.label.purchaseOrder)",
            reference: "$t(form.label.reference)",
            postcode: "$t(form.label.postcode)",
            addressLine1: "$t(form.label.addressLine1)",
            addressLine2: "$t(form.label.addressLine2)",
            city: "$t(form.label.city)",
            county: "$t(form.label.county)",
            name: "$t(form.label.name)",
            phone: "$t(form.label.phone)",
            email: "$t(form.label.email)",
            wasteDescription: "$t(form.label.wasteDescription)",
            accessInformation: "$t(form.label.accessInformation)",
            zendeskTicketId: "$t(form.label.zendeskTicketId)",
            notYetAdded: "Not yet added",
        },
        statusOptions: {
            all: "All",
            Completed: "Completed",
            Started: "Started",
            Open: "Open",
            "On+the+way": "On the way",
            Scheduled: "Scheduled",
            Failed: "Failed",
            Cancelled: "Cancelled",
        },
        validation: {
            fiftyMax: "50 characters maximum",
            requiredField: "This is a required field",
            noSpaces: "Cannot contain spaces",
            invalidChar: "Contains invalid characters",
        },
    },
    buttons: {
        labels: {
            viewAllJobs: "View All Jobs",
            editJob: "Edit Job",
            cancelJob: "Cancel Job",
            viewAllImages: "View all images",
            paymentJob: "Take Additional Payment",
            assignVehicle: "Assign Vehicle",
            removeAssignedVehicle: "Remove Assigned Vehicle",
            startJob: "Start Job",
            completeJob: "Complete Job",
            completedJob: "Job Complete!",
            backToJobs: "Back to All Jobs",
            uploadImages: "Upload Images",
            remove: "Remove",
            next: "Next",
            edit: "Edit",
            save: "Save",
            cancel: "Cancel",
            cancelEditing: "Cancel editing",
            showAll: "Show All",
            viewUser: "View User",
            approve: "Approve",
        },
    },
    content: {
        noData: "None",
    },
    filters: {
        title: "Search Jobs",
        form: {
            label: {
                status: "Status",
                vehicle: "Vehicle",
                date: "Date",
                purchaseOrder: "$t(form.label.purchaseOrder)",
                reference: "$t(form.label.reference)",
                postcode: "$t(form.label.postcode)",
                addressLine1: "$t(form.label.addressLine1)",
                addressLine2: "$t(form.label.addressLine2)",
                city: "$t(form.label.city)",
                county: "$t(form.label.county)",
                name: "$t(form.label.name)",
                phone: "$t(form.label.phone)",
                email: "$t(form.label.email)",
                wasteDescription: "$t(form.label.wasteDescription)",
                accessInformation: "$t(form.label.accessInformation)",
                resetFilter: "Reset Filter",
            },
            placeholder: {
                status: "All",
                vehicle: "All",
                date: "select date/s",
                purchaseOrder: "search by $t(filters.form.label.purchaseOrder)",
                reference: "search by $t(filters.form.label.reference)",
                postcode: "search by $t(filters.form.label.postcode)",
            },
        },
        buttons: {
            search: "Search",
            bookAJob: "Book a Job",
            reset: "Reset filter",
            downloadFilteredJobs: "Download Filtered Jobs",
        },
    },
    warningModal: {
        withPostcode: "A job with postcode:",
        withPo: "and P.O number:",
        alreadyExists: "already exists",
    },
    update: {
        buttons: {
            bookAgain: "Book Again",
        },
        modal: {
            heading: "Are you sure you want to cancel this job?",
            yes: "Yes, cancel",
            no: "Go back",
        },
        toasts: {
            success: "Job successfully cancelled.",
            error: "Failed to cancel job. Please try again.",
        },
        form: {
            label: {
                firstName: "First name",
                lastName: "Last name",
                postcode: "$t(form.label.postcode)",
                addressLine1: "$t(form.label.addressLine1)",
                addressLine2: "$t(form.label.addressLine2)",
                collectionDate: "$t(form.label.collectionDate)",
                city: "$t(form.label.city)",
                county: "$t(form.label.county)",
                name: "$t(form.label.name)",
                phone: "$t(form.label.phone)",
                email: "$t(form.label.email)",
                wasteDescription: "$t(form.label.wasteDescription)",
                accessInformation: "$t(form.label.accessInformation)",
            },
            placeholder: {
                firstName: "First name",
                lastName: "Last name",
                postcode: "$t(form.label.postcode)",
                addressLine1: "$t(form.label.addressLine1)",
                addressLine2: "$t(form.label.addressLine2)",
                city: "$t(form.label.city)",
                county: "$t(form.label.county)",
                name: "$t(form.label.name)",
                phone: "$t(form.label.phone)",
                email: "$t(form.label.email)",
                wasteDescription: "$t(form.label.wasteDescription)",
                accessInformation: "$t(form.label.accessInformation)",
            },
        },
        imagesAvailable: "Images ({{length}} images)",
        noImagesAvailable: "No {{type}} images available",
        subcontractor: {
            button: {
                updateJobValue: "Update Job Value",
            },
            heading: {
                contractor: "Contractor Details",
                images: "Images",
                updateJobValue: "Update Job Value",
                waste: "Waste Data",
                failedJobData: "Failed Job Data",
            },
            label: {
                additionalInformation: "Additional Information",
                amount: "Original Job Value",
                contractorName: "Contractor",
                finalAmount: "Final Job Value",
                failureReason: "Failure Reason",
                images: {
                    afterImages: "After Images",
                    beforeImages: "Before Images",
                    customerCallImages: "Customer Call Images",
                    customerSignature: "Customer Signature",
                    driverSignature: "Driver Signature",
                    failedSiteImages: "Failed Site Images",
                    invoice: "Invoice",
                    wasteTransferNote: "Waste Transfer Note",
                    weighbridgeTicket: "Weighbridge Ticket",
                },
                notes: "Notes",
                tip: "Place of Disposal / Tip Name",
                tipLicence: "Tip Licence",
                volume: "Volume",
                weight: "Weight",
            },
            errors: {
                generic: "Something went wrong. Please try again.",
                loading: "Something went wrong loading subcontractor information",
            },
            success: {
                saved: "Changes saved.",
            },
        },
        subcontractorPanel: {
            heading: "Subcontractors",
            description: "Please select how much you would like this job to be invoiced for and what subcontractors you would like to make it available to.",
            button: "Assign",
            form: {
                invoiceAmount: "Invoice Amount",
                subcontractors: "Subcontractors*",
                subcontractorPlaceholder: "Search subcontractor",
                selectAll: "Select All",
            },
            toasts: {
                patchSuccess: "Job updated successfully.",
                patchError: "Failed to update job value. Please try again.",
            },
            errors: {
                noSubcontractors: "No subcontractors were found that cover this postcode",
                failedSubcontractors: "Failed to load subcontractors. Please try again.",
            },
        },
    },
    table: {
        headings: {
            actions: "Actions",
            address: "Address",
            contact: "Contact",
            contactPhone: "Contact Number",
            description: "Description",
            item: "Item",
            jobDate: "Job Date",
            jobReference: "Job Reference",
            name: "Name",
            noContactDetails: "No Contact Details",
            orderReference: "Order Reference",
            poNumber: "P.O. Number",
            postcode: "Postcode",
            purchaseOrder: "Purchase Order",
            status: "Status",
            totalCost: "Total Cost",
            type: "Type",
            email: "Email",
            creditUsed: "Credit Used",
            amount: "Amount",
            price: "Price",
            pendingDate: "Pending Date",
            pendingTime: "Pending Time",
            autoApprovesIn: "Auto-approves in",
            riskPostcode: "Risk Postcode",
            isRiskAddressDifferentToCollectionAddress: "Is the risk address different to the collection address?",
        },
        actions: {
            resend: "Resend link",
            cancel: "Cancel job",
            view: "View",
        },
    },
    status: {
        failed: "Failed",
        open: "Open",
        started: "Started",
        completed: "Completed",
    },
    create: {
        sendPaymentLink: "Send Payment Link",
        priceChange: "Price editable",
        noItems: "No items in this basket",
        lookupModal: {
            button: "Lookup items",
            total: "Total",
            notAssigned: "Catalogue not assigned",
            headings: {
                title: "Title",
                parentSku: "Parent Sku",
                quantity: "Quantity",
                price: "Price",
            },
        },
        bulkJobs: {
            title: "Book Multiple Jobs",
            buttons: {
                addNewJob: "Add new job",
                submit: "Submit",
                reset: "Reset",
            },
            placeholders: {
                selectCompany: "Select a company...",
                adhoc: "Use ADHOC catalogue",
            },
        },
        errorDisclaimer: "Something went wrong when creating your order:",
        steps: {
            selectCompany: {
                title: "Select company",
                description: "Select the company you are booking for.",
                form: {
                    labels: {
                        company: "Book on Behalf of",
                    },
                    placeholders: {
                        company: "Select company",
                    },
                },
            },
            selectItems: {
                showMore: "Show more",
                showLess: "Show less",
                notAvailable: "Sorry, no products are available...",
                nextStep: "Next",
                selectDate: "Select a date...",
            },
            selectService: {
                title: "Select service",
                description: "From the options below select the type of service you require.",
                form: {
                    labels: {
                        products: "Product",
                        date: "Date*",
                    },
                    placeholders: {
                        products: "Select product",
                        date: "Select date",
                    },
                },
            },
            selectCategory: {
                title: "Select category",
                description: "From the options below select the products you require.",
            },
            selectProductOptions: {
                title: "Select options",
                description: "From the options below select the quantities you require.",
                titleVT: "Select products",
                descriptionVT: "From the products below select the quantities you require.",
                tableTitles: {
                    quantity: "Quantity",
                    option: "Option",
                    description: "Description",
                    price: "Price",
                },
            },
            collectionDetails: {
                title: "Collection details",
                description: "Tell us more about the collection",
                titles: {
                    collection: "Collection Details",
                    customer: "Customer Details",
                    waste: "Waste Details",
                    fullAddress: "Collection Address",
                },
                form: {
                    labels: {
                        purchaseOrder: "P.O. Number",
                        firstName: "First Name*",
                        lastName: "Last Name*",
                        customerEmail: "Email Address*",
                        customerNoEmail: "Customer has no email",
                        noContactDetails: "There are no contact details available for this job.",
                        customerPhone: "Phone*",
                        wasteType: "Type of Waste",
                        wasteDescription: "Waste Description",
                        accessInformation: "Access Information",
                        upload: "Upload Images",
                        landType: "Type of Land*",
                        permitCharge: "Permit Charge",
                        timeslot: "Timeslot*",
                        timeslotPreference: "Timeslot Preference",
                        zendeskTicketId: "$t(form.label.zendeskTicketId)",
                        sicCode: "SIC Code",
                        riskPostcode: "Risk Postcode",
                        isRiskAddressDifferentToCollectionAddress: "Is the risk address different to the collection address?",
                    },
                    placeholders: {
                        firstName: "First Name",
                        lastName: "Last Name",
                        purchaseOrder: "P.O. Number",
                        customerName: "Full Name",
                        customerEmail: "Email Address",
                        customerPhone: "Phone Number",
                        wasteType: "Select type of waste",
                        wasteDescription: "Enter your waste description here...",
                        accessInformation: "Please let us know if there is any specific access information...",
                        landType: "Select type of land",
                        permitCharge: "Enter permit charge",
                        timeslot: "Timeslot",
                        timeslotPreference: "Timeslot Preference",
                        zendeskTicketId: "$t(form.label.zendeskTicketId)",
                        sicCode: "$t(create.steps.collectionDetails.form.labels.sicCode)",
                        riskPostcode: "Risk Postcode",
                    },
                    messages: {
                        emailOrPhone: "You must provide an email address or phone number for this job.",
                        emailAndPhone: "You must provide an email address and phone number for this job.",
                    },
                    buttons: {
                        uploadImages: "Upload Images",
                    },
                },
            },
            billing: {
                title: "Billing",
                description: "Enter the billing address and card information",
                titles: {
                    address: "Billing Address",
                    collectionAddress: "Original Collection Address",
                    card: "Card Information",
                    success: "Success",
                    note: "Note",
                },
                messages: {
                    success: "Card successfully authorised, continue to the next step.",
                    note: "You will have chance to review the details before a charge is made.",
                },
                form: {
                    placeholders: {
                        cardholderName: "Enter the cardholder name",
                    },
                    labels: {
                        billingSame: "The billing address is the same as the collection address",
                        billingSameOriginal: "The billing address is the same as the original collection address (option unavailable at this time)",
                        cardNumber: "Card Number",
                        expiry: "Expiry",
                        cvv: "CVV",
                        cardholderName: "Cardholder Name",
                    },
                    buttons: {
                        authorise: "Save Card Details",
                        card: "Enter Card Details",
                        cancel: "Cancel",
                        save: "Save Address",
                    },
                },
            },
            confirmOrder: {
                title: "Order details",
                description: "Review your order details and confirm your order",
                titlePayment: "Payment/confirmation",
                descriptionPayment: "Review the order details and confirm payment method",
                terms: "By clicking 'Complete Order' you agree to our terms and conditions and the job is added to our system.",
                termsPayment: "By clicking 'Take Payment' you agree to our terms and conditions, payment is taken immediately.",
                paymentModal: {
                    title: "Enter card details",
                },
            },
        },
        orderSummary: {
            titles: {
                name: "Name",
                phone: "Phone Number",
                email: "Email Address",
                order: "Your Order",
                additionalItems: "Additional Items",
                quantity: "Qty",
                product: "Product",
                price: "Price",
                subtotal: "Subtotal",
                total: "Total",
                collection: "Collection Address",
                selectedDate: "Selected Date",
                details: "Your Details",
                payment: "Payment Details",
                billing: "Billing Address",
                billingDetails: "Billing Details",
            },
            vat: "Excluding VAT",
            incVat: "Including VAT",
            permitCharge: "Permit Charge",
            timeslotCharge: "Timeslot Charge",
            calloutCharge: "Callout Charge",
            addonGroup: "Addons",
            noEmail: "No email",
        },
        buttons: {
            next: "Next",
            complete: "Complete Order",
            edit: "Edit",
            payment: "Take Payment",
            startAgain: "Start again",
            paymentLink: "Send Payment Link",
            retry: "Retry",
        },
    },
    payment: {
        title: "Find Job By Reference",
        error: "That job is not of type 'commmercial' or 'adhoc'",
        form: {
            placeholders: {
                reference: "Enter job reference",
                postcode: "Enter postcode",
            },
            labels: {
                reference: "Job Reference*",
                postcode: "Postcode*",
            },
            buttons: {
                submit: "Find Job",
            },
        },
    },
    modal: {
        headings: {
            cancelJob: "Are you sure you want to cancel this job?",
            cancelJobSuccess: "Job Successfully Cancelled!",
            createJobSuccess: "Job Successfully Created!",
            createPaymentLinkSuccess: "Payment Link Successfully Created!",
            weeeCreateSuccess: "WEEE Kerbside Job Successfully Created",
            paymentTakenSuccess: "Additional payment taken successfully!",
            reference: "Ref: {{reference}}",
            cancelPaymentLink: "Are you sure you want to cancel?",
            resetBulk: "Are you sure you want to reset this form?",
            successfullyBookedJobs: "Jobs booked successfully",
            deleteRow: "Are you sure you want to delete this row?",
            somethingWentWrong: "Something went wrong",
            ref: "Ref: ",
        },
        buttons: {
            labels: {
                yesCancelJob: "Yes, cancel job",
                noCancelJob: "No",
                backToJobs: "Back to All Jobs",
                backToDashboard: "Back to Dashboard",
                bookAnother: "Book another Job",
                yesCancel: "Yes, cancel",
                goBack: "Go back",
                yesReset: " Yes, reset",
                yesDelete: "Yes, delete",
                retry: "Retry",
                skip: "Skip",
                close: "Close",
            },
        },
        checkbox: {
            saveDetails: "Use same details for next job",
        },
        text: {
            customerWillReceiveAnEmail: "The customer will receive an email containing a unique link where they will be able to make payment, which will confirm their booking.",
        },
    },
    weeeKerbside: {
        strapline: {
            first: "Free",
            second: "removal of",
            third: "bulky Waste",
            fourth: "Electrical & Electronic (WEEE).",
        },
        create: {
            steps: {
                selectCompany: {
                    title: "Select company",
                    description: "Select the company you are booking for.",
                    form: {
                        labels: {
                            company: "Book on Behalf of",
                        },
                        placeholders: {
                            company: "Select company",
                        },
                    },
                },
                selectService: {
                    title: "Select service",
                    description: "From the options below select the type of service you require.",
                    form: {
                        labels: {
                            products: "Product",
                            date: "Date",
                        },
                        placeholders: {
                            products: "Select product",
                            date: "Select date",
                        },
                    },
                },
                selectCategory: {
                    title: "Select category",
                    description: "From the options below select the products you require.",
                },
                selectProductOptions: {
                    title: "Select options",
                    description: "From the options below select the quantities you require.",
                    titleVT: "Select products",
                    descriptionVT: "From the products below select the quantities you require.",
                    tableTitles: {
                        quantity: "Quantity",
                        option: "Option",
                        description: "Description",
                        price: "Price",
                    },
                },
                collectionDetails: {
                    title: "Collection details",
                    description: "Tell us more about the collection",
                    titles: {
                        collection: "Collection Details",
                        customer: "Customer Details",
                        waste: "Waste Details",
                        fullAddress: "Collection Address",
                    },
                    form: {
                        labels: {
                            purchaseOrder: "P.O. Number",
                            customerName: "Name",
                            customerEmail: "Email Address",
                            customerNoEmail: "Customer has no email",
                            noContactDetails: "There are no contact details available for this job.",
                            customerPhone: "Phone",
                            wasteType: "Type of Waste",
                            wasteDescription: "Waste Description",
                            accessInformation: "Access Information",
                            upload: "Upload Images",
                            landType: "Type of Land*",
                            permitCharge: "Permit Charge*",
                            timeslot: "Timeslot*",
                            timeslotPreference: "Timeslot Preference",
                            zendeskTicketId: "$t(form.label.zendeskTicketId)",
                        },
                        placeholders: {
                            purchaseOrder: "P.O. Number",
                            customerName: "Full Name",
                            customerEmail: "Email Address",
                            customerPhone: "Phone Number",
                            wasteType: "Select type of waste",
                            wasteDescription: "Enter your waste description here...",
                            accessInformation: "Please let us know if there is any specific access information...",
                            landType: "Select type of land",
                            permitCharge: "Enter permit charge",
                            timeslot: "Timeslot",
                            timeslotPreference: "Timeslot Preference",
                            zendeskTicketId: "$t(form.label.zendeskTicketId)",
                        },
                        messages: {
                            emailOrPhone: "You must provide an email address or phone number for this job.",
                        },
                        buttons: {
                            uploadImages: "Upload Images",
                        },
                    },
                },
                billing: {
                    title: "Billing",
                    description: "Enter the billing address and card information",
                    titles: {
                        address: "Billing Address",
                        collectionAddress: "Original Collection Address",
                        card: "Card Information",
                        success: "Success",
                        note: "Note",
                    },
                    messages: {
                        success: "Card successfully authorised, continue to the next step.",
                        note: "You will have chance to review the details before a charge is made.",
                    },
                    form: {
                        placeholders: {
                            cardholderName: "Enter the cardholder name",
                            cardNumber: "Card number",
                            expiry: "Expiry               MM/YY",
                            cvv: "CVV",
                        },
                        labels: {
                            billingSame: "The billing address is the same as the collection address",
                            billingSameOriginal: "The billing address is the same as the original collection address (option unavailable at this time)",
                            cardNumber: "Card number",
                            expiry: "Expiry",
                            cvv: "CVV",
                            cardholderName: "Cardholder Name",
                        },
                        buttons: {
                            authorise: "Save Card Details",
                            card: "Enter Card Details",
                            cancel: "Cancel",
                            save: "Save Address",
                        },
                    },
                },
                confirmOrder: {
                    title: "Order details",
                    description: "Review your order details and confirm your order",
                    titlePayment: "Payment/confirmation",
                    descriptionPayment: "Review the order details and confirm payment method",
                    terms: "By clicking 'Complete Order' you agree to our terms and conditions and the job is added to our system.",
                    termsPayment: "By clicking 'Take Payment' you agree to our terms and conditions, payment is taken immediately.",
                    paymentModal: {
                        title: "Enter card details",
                    },
                },
            },
            orderSummary: {
                titles: {
                    name: "Name",
                    phone: "Phone Number",
                    email: "Email Address",
                    order: "Your Order",
                    additionalItems: "Additional Items",
                    quantity: "Qty",
                    product: "Product",
                    price: "Price",
                    subtotal: "Subtotal",
                    total: "Total",
                    collection: "Collection Address",
                    details: "Your Details",
                    payment: "Payment Details",
                    billing: "Billing Address",
                },
                vat: "Excluding VAT",
                permitCharge: "Permit Charge",
                timeslotCharge: "Timeslot Charge",
                calloutCharge: "Callout Charge",
                addonGroup: "Addons",
                noEmail: "No email",
            },
            buttons: {
                next: "Next",
                complete: "Complete Order",
                edit: "Edit",
                payment: "Take Payment",
            },
        },
        titles: {
            collectionDate: "Collection Date",
            electricalItemDetails: "Electrical Item Details",
            contactDetails: "Contact Details",
            weeeKerbside: "WEEE Kerbside",
        },
        orderSummary: {
            titles: {
                name: "Name",
                phone: "Phone Number",
                email: "Email Address",
                order: "Your Order",
                additionalItems: "Additional Items",
                quantity: "Qty",
                product: "Product",
                price: "Price",
                subtotal: "Subtotal",
                total: "Total",
                collection: "Collection Address",
                details: "Your Details",
                payment: "Payment Details",
                billing: "Billing Address",
            },
            vat: "Excluding VAT",
            permitCharge: "Permit Charge",
            timeslotCharge: "Timeslot Charge",
            calloutCharge: "Callout Charge",
            addonGroup: "Addons",
            noEmail: "No email",
        },
        buttons: {
            next: "Next",
            complete: "Complete Order",
            edit: "Edit",
            payment: "Take Payment",
            submit: "Submit",
        },
        form: {
            labels: {
                postcode: "Postcode*",
                address: "Address",
                description: "Item Description",
                firstName: "First Name",
                lastName: "Last Name",
                phoneNumber: "Phone Number",
                email: "Email Address",
                customerNoEmail: "Customer has no email",
            },
            placeholders: {
                postcode: "Postcode",
                address: "Address",
                description: "Describe your waste...",
                firstName: "First Name",
                lastName: "Last Name",
                phoneNumber: "Phone Number",
                email: "Email Address",
                city: "City",
            },
        },
    },
    vehicles: {
        modal: {
            title: "Assign Vehicle",
            form: {
                labels: {
                    vehicle: "Vehicle",
                },
                placeholders: {
                    vehicle: "Select..",
                },
                buttons: {
                    cancel: "Cancel",
                    submit: "Assign",
                },
            },
        },
        imagesModal: {
            title: "Upload images",
            subtitle: "Upload images of the rubbish in order to start the job.",
            form: {
                labels: {
                    vehicle: "Vehicle",
                },
                placeholders: {
                    vehicle: "Select a vehicle",
                },
                buttons: {
                    cancel: "Cancel",
                    submit: "Upload",
                    images: "Select/Take Photo",
                },
            },
        },
        worksheetModal: {
            title: "Complete job",
            subtitle: "Answer each of the questions to complete this job.",
            form: {
                buttons: {
                    cancel: "Cancel",
                    submit: "Submit",
                    images: "Select/Take Photo",
                },
            },
        },
    },
    bookJobAgain: {
        headings: {
            book: "Book this job again?",
            bookSuccess: "Successfully booked this job again!",
        },
        buttons: {
            cancel: "Cancel",
            create: "Create",
            viewJob: "View Job",
        },
        form: {
            newReference: "New Job Reference:",
        },
        errors: {
            bookAgain: "Cannot Book this Job, please try again.",
        },
    },
    payments: {
        title: "Search Payment Links",
        headings: {
            createdDate: "Date Created",
            email: "Email Address",
            jobDate: "Job Date",
            orderRef: "Order Reference",
            postcode: "Postcode",
            status: "Status",
            actions: "Actions",
        },
        filters: {
            status: {
                all: "All",
                pending: "Pending",
                paid: "Paid",
                expired: "Expired",
            },
            placeholder: {
                createdDate: "Date Created",
                email: "Email Address",
                jobDate: "Job Date",
                orderRef: "Order Reference",
                postcode: "Postcode",
            },
        },
        resend: "Resend",
        cancel: "Cancel",
    },
    errors: {
        paymentLink: "The Payment Link could not be sent. Please try again",
        basket: "Error creating basket, please try again",
        noCompanyCatalogue: "This company does not have any associated catalogues",
    },
    assignContractor: "Contractors ({{contractors}})",
    contractorStatus: "Contractor Status: {{status}}",
    makeAvailable: "Make Available to Contractors",
    jobValue: "Job Value {{value}}",
    apiError: "API Error: {{message}}",
    failedJobs: {
        pageHeading: "Failed Jobs",
        payloadModalHeading: "Payload",
        copied: "Copied",
        columns: {
            orderRef: "Order Reference",
            attempts: "Attempts",
            processed: "Processed",
            company: "Company",
            topicArn: "Topic ARN",
            createdOn: "Created",
            action: "Action",
        },
        buttons: {
            viewPayload: "View Payload",
            copy: "Copy",
        },
    },
    pendingJobs: {
        noPendingJobs: "There are no jobs pending approval.",
        noWorksheetAnswers: "There are no worksheet answers for this job.",
    },
};
