import React from "react";
import { Formik } from "formik";

/**
 * Import components.
 */
import { useMultiFormContext } from "../../../../hooks";
import { PanelRight, AddressPanel, BoroughLogos } from "../components";

/**
 * Import form initial values.
 */
import {
  weeeAddressDetailsSchema,
  weeeAddressDetailsInitialValues,
} from "../validation";

export const AddressDetails: React.FC = () => {
  const { nextStep, pushState } = useMultiFormContext();

  return (
    <div
      data-testid="weeeKerbsideComponentIdentifier"
      className="address-selection px-6 lg:px-3 relative"
    >
      <div className="hidden xl:block">
        <PanelRight />
      </div>

      <div className="py-10 flex items-center justify-center relative max-w-screen-2xl lg:justify-start">
        <div className="w-full md:w-1/2 flex mr-0 justify-left z-10">
          <div className="w-full xl:w-2/3 bg-white shadow-modal rounded-xl">
            <div className="relative">
              <div className="p-6 pb-8 px-8 w-full">
                <Formik
                  initialValues={weeeAddressDetailsInitialValues}
                  validationSchema={weeeAddressDetailsSchema}
                  onSubmit={(values) => {
                    pushState(values);
                    nextStep();
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <AddressPanel />
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="borough-logos mb-8 lg:mb-0 lg:absolute lg:bottom-10">
        <BoroughLogos />
      </div>
    </div>
  );
};
