import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  panel: {
    padding: theme.spacing.small,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  isDragging: { cursor: "grab", opacity: "50%" },
  currentIndex: { fontWeight: 600, marginRight: theme.spacing.small },
  name: {
    marginRight: "auto",
    flex: 1,
  },
});
