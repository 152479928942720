import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import cx from "classnames";
import { useQuery } from "react-query";

/**
 * Import api.
 */
import {
  getTotalPaymentCount,
  getAllWebsiteOrders,
  IPaymentTotal,
  getPaymentLinksCount,
} from "../../api/leaderboard";

/**
 * Import images.
 */
import { RefreshIcon } from "../../images";

/**
 * Import parsers.
 */
import {
  parsePaymentsToCellData,
  parseBookingsToCellData,
  parsePaidPaymentLinksToCellData,
  parsePendingPaymentLinksToCellData,
} from "./parser";

/**
 * Import subcomponents.
 */
import {
  SimpleSelect,
  DatePickerInput,
  FormButton,
  Trail,
  SpringTransition,
} from "../core";
import {
  PaymentCell,
  BookingsCell,
  WebsiteNumbers,
  TotalNumbers,
  PaymentLinksPaid,
  PaymentLinksPending,
} from "./components";
import { IDataRow } from "./types";

import "./styles/leaderboard.css";

const dateFormat = "YYYY-MM-DD HH:mm:ss";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Leaderboard = (): React.ReactElement => {
  const [t] = useTranslation("leaderboard");

  /**
   * State.
   */
  const [customDate, setCustomDate] = useState(false);
  const [dateRange, setDateRange] = useState(0);

  const [from, to] = [
    dayjs().subtract(dateRange, "days").startOf("day").format(dateFormat),
    dayjs().endOf("day").format(dateFormat),
  ];

  const rangeOptions = [
    {
      label: "day",
      value: "0",
    },
    {
      label: "week",
      value: "6",
    },
    {
      label: "month",
      value: "29",
    },
    {
      label: "custom",
      value: "custom",
    },
  ];

  /**
   * Create date ranges.
   */
  const [custom, setCustom] = useState({
    from: "",
    to: "",
  });

  /**
   * Fetch data.
   */
  const {
    isLoading: isPhoneDataLoading,
    data: phoneData,
    refetch: fetchPhoneData,
  } = useQuery(
    ["getTotalPaymentCount", [customDate, custom.from, custom.to, from, to]],
    () =>
      getTotalPaymentCount(
        customDate ? custom.from : from,
        customDate ? custom.to : to,
      ),
  );

  const {
    isLoading: isWebsiteDataLoading,
    data: websiteData,
    refetch: fetchWebsiteData,
  } = useQuery(
    ["getAllWebsiteOrders", [customDate, custom.from, custom.to, from, to]],
    () =>
      getAllWebsiteOrders(
        customDate ? custom.from : from,
        customDate ? custom.to : to,
      ),
  );
  const {
    isLoading: isPaymentLinksDataLoading,
    data: paymentLinksData,
    refetch: fetchPaymentLinksData,
  } = useQuery(
    ["getPaymentLinksCount", [customDate, custom.from, custom.to, from, to]],
    () =>
      getPaymentLinksCount(
        customDate ? custom.from : from,
        customDate ? custom.to : to,
      ),
  );

  const paidLinks: IDataRow[] = paymentLinksData
    ? parsePaidPaymentLinksToCellData(paymentLinksData)
    : [];
  const pendingLinks: IDataRow[] = paymentLinksData
    ? parsePendingPaymentLinksToCellData(paymentLinksData)
    : [];

  const isLoading =
    isPhoneDataLoading || isWebsiteDataLoading || isPaymentLinksDataLoading;

  /**
   * Handlers.
   */
  const refreshData = () => {
    fetchPhoneData();
    fetchWebsiteData();
    fetchPaymentLinksData();
  };

  /**
   * Fetch data on mount.
   */
  useEffect(() => {
    fetchPhoneData();
    fetchWebsiteData();
    fetchPaymentLinksData();
  }, [fetchPhoneData, fetchWebsiteData, fetchPaymentLinksData]);

  /**
   * Parsed payments data.
   */
  const paymentRows = phoneData ? parsePaymentsToCellData(phoneData) : [];
  const bookingRows: IDataRow[] = phoneData
    ? parseBookingsToCellData(phoneData)
    : [];

  /**
   * Get total phone payments and orders.
   */
  let totalPhonePayments = 0;
  let totalPhoneBookings = 0;

  if (phoneData) {
    phoneData.forEach((entry: IPaymentTotal) => {
      totalPhonePayments += entry.total;
      totalPhoneBookings += parseInt(entry.bookings as any);
    });
  }

  /**
   * Get total number of payment links paid
   */
  const totalPaymentLinksPaid = paymentLinksData?.reduce(
    (a, b) => Number(a) + Number(b.paid ?? 0),
    0,
  );

  /**
   * Get total number of payment links pending
   */
  const totalPaymentLinksPending = paymentLinksData?.reduce(
    (a, b) => Number(a) + Number(b.pending ?? 0),
    0,
  );

  /**
   * Overall totals.
   */
  const totalOrders =
    totalPhoneBookings +
    (websiteData?.total ? parseInt(websiteData?.total as any) : 0);
  const totalValue =
    totalPhonePayments +
    (websiteData?.value ? parseInt(websiteData?.value as any) : 0);

  /**
   * Trail components.
   */
  const trailComponents = [
    <TotalNumbers
      orders={totalOrders}
      value={totalValue}
      isLoading={isLoading}
    />,
    <WebsiteNumbers
      orders={websiteData?.total || 0}
      value={websiteData?.value || 0}
      isLoading={isLoading}
    />,
    <PaymentCell
      total={totalPhonePayments}
      rows={paymentRows}
      isLoading={isLoading}
    />,
    <BookingsCell
      total={totalPhoneBookings}
      rows={bookingRows}
      isLoading={isLoading}
    />,
    <PaymentLinksPaid
      total={totalPaymentLinksPaid ?? 0}
      rows={paidLinks}
      isLoading={isLoading}
    />,
    <PaymentLinksPending
      total={totalPaymentLinksPending ?? 0}
      rows={pendingLinks}
      isLoading={isLoading}
    />,
  ];

  const launch = dayjs("2017-02-13").toDate();
  const today = dayjs().toDate();

  return (
    <div className="leaderboard-wrap relative flex flex-wrap max-w-4xl -mx-2 py-5">
      <SpringTransition className="w-full px-4 flex justify-between items-center">
        <div className="mb-4 flex-col sm:flex-row items-center">
          <span className="text-lg mr-4 block md:inline-block mb-3 md:mb-0">
            {t("headings.dateRange")}
          </span>
          <div className="inline-block w-24">
            <div className="form-input-wrap">
              <SimpleSelect
                classes="form-input w-full"
                name="dateRange"
                selectedValue={{
                  label: "day",
                  value: "0",
                }}
                noText={false}
                options={rangeOptions}
                onChange={(selected) => {
                  if (selected.value === "custom") {
                    setCustomDate(true);
                  } else {
                    setCustomDate(false);
                    setCustom({
                      from: "",
                      to: "",
                    });
                    setDateRange(selected.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <button
          className={cx(
            // eslint-disable-next-line
            "leaderboard-refresh-icon inline-flex bg-secondary mb-2 w-8 h-8 items-center justify-center border-secondary border p-1 rounded-full ut-transition transform md:hover:scale-110 mt-6 md:mt-0",
            { "is-loading": isLoading },
          )}
          type="button"
          onClick={refreshData}
        >
          <RefreshIcon className="text-white" />
        </button>
      </SpringTransition>
      {customDate && (
        <Formik
          initialValues={{
            fromDate: "",
            toDate: "",
          }}
          onSubmit={(values) => {
            setCustom({
              from: values.fromDate,
              to: values.toDate,
            });
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="w-full">
              <SpringTransition className="w-full px-4 pt-3 -mx-2 pb-8 flex flex-col md:flex-row justify-start items-center">
                <div className="w-full md:w-auto py-2 px-2">
                  <DatePickerInput
                    name="fromDate"
                    placeholder="Select 'from' date"
                    label={{
                      text: "From",
                    }}
                    floatingLabel={false}
                    disabledDays={{
                      before: launch,
                      after: today,
                    }}
                    numberOfMonths={1}
                    isRanged={false}
                    calendarPosition="below"
                  />
                </div>
                <div className="w-full md:w-auto py-2 px-2">
                  <DatePickerInput
                    name="toDate"
                    placeholder="Select 'to' date"
                    label={{
                      text: "To",
                    }}
                    floatingLabel={false}
                    disabledDays={{
                      before: launch,
                      after: today,
                    }}
                    numberOfMonths={1}
                    isRanged={false}
                    calendarPosition="below"
                  />
                </div>
                <div className="w-full md:w-auto py-2 px-2">
                  <FormButton
                    name="complete"
                    text="Get Results"
                    type="submit"
                    variant="secondary"
                    disabled={!values.toDate || !values.fromDate}
                    loader={false}
                  />
                </div>
              </SpringTransition>
            </form>
          )}
        </Formik>
      )}
      <Trail
        components={trailComponents}
        animatedElemClass="w-full sm:w-1/2 px-2 mb-4"
        trailConfig={{
          from: {
            opacity: 0,
            xyz: [0, 100, 0],
          },
        }}
      />
    </div>
  );
};
