import React from "react";
import { useTranslation } from "react-i18next";
import { Pill } from "@clearabee/ui-library";

/**
 * Import custom hooks.
 */
import { useTime, useScreenWidth } from "../../../hooks";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const CallCTA = (): React.ReactElement => {
  /**
   * i18n translations.
   */
  const [translate] = useTranslation("portal");

  /**
   * Use opening time hook.
   */
  const [open, openUntil, reopens, opensAt] = useTime();

  /**
   * Mobile screen check.
   */
  const { isMobile } = useScreenWidth();

  /**
   * Handle click of help button to show Zendesk messenger.
   */
  const handleClick = () => {
    (window as any).zE("webWidget", "open");
  };

  return (
    <div className="call-cta rounded-full inline-flex items-center">
      <Pill size="small" onClick={handleClick} color="usp">
        {translate("buttons.needHelp")}
      </Pill>
      {!isMobile &&
        (open ? (
          <p className="px-5 text-black text-sm z-10">
            <span className="hidden md:inline">
              {translate("cta.text.openUntil")}
              <span className="text-primary font-semibold">
                {`${openUntil} ${translate("cta.text.today")}`}
              </span>
            </span>
          </p>
        ) : (
          <p className="px-5 text-black text-sm z-10">
            {translate("cta.text.callCentreCurrently")}
            <span className="text-primary font-semibold">
              {translate("cta.text.closed")}
            </span>
            {translate("cta.text.reopen", { day: reopens })}
            <span className="text-primary font-semibold">{opensAt}</span>
          </p>
        ))}
    </div>
  );
};
