import React, { useContext } from "react";
import {
  Form,
  Field,
  Input,
  Link,
  Button,
  theme,
  formatCurrency,
} from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { CatalogueItem } from "models/catalogueItem";
import { RouteLink } from "components/core";
import { EditableItem, CatalogueEditorContext } from "..";
import { compareObjects } from "helpers";
import { QuickEditFormResult } from "../catalogueEditor";

export interface QuickEditFormValues
  extends Pick<CatalogueItem, "title" | "description"> {
  price?: string;
}

interface CellFormProps {
  item: EditableItem;
  setOpen: (open: boolean) => void;
}

export const CellForm = ({
  item,
  setOpen,
}: CellFormProps): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const { catalogue, editItem, deleteItemConfirm } = useContext(
    CatalogueEditorContext,
  );

  const handleDeleteItem = () => {
    deleteItemConfirm(item);
  };

  const initialValues: QuickEditFormValues = {
    title: item.title,
    description: item.description,
    price: formatCurrency(item.price || 0, ""),
  };

  const handleUpdateSubmit = ({ price, ...values }: QuickEditFormValues) => {
    const result: QuickEditFormResult = {
      ...values,
      price: price && item.type === "product" ? parseFloat(price) : undefined,
    };

    editItem(
      item,
      compareObjects(result, {
        title: item.title,
        description: item.description,
        price: item.price,
      }),
    );
    setOpen(false);
  };

  return (
    <div className="w-full md:px-4 md:pr-10 md:mb-4">
      <Form
        initialValues={initialValues}
        onSubmit={handleUpdateSubmit}
        styles={{
          color: theme.colors.dark.base,
          " span": { color: theme.colors.light.base },
        }}
      >
        <Field
          name="title"
          type="text"
          label={translate("catalogueEditing.form.title")}
        >
          {({ field }) => <Input.Text {...field} />}
        </Field>
        <Field
          name="description"
          type="text"
          label={translate("catalogueEditing.form.description")}
        >
          {({ field }) => (
            <Input.Textarea
              {...field}
              maxLength={499}
              styles={{ height: 80 }}
            />
          )}
        </Field>
        {item.type === "product" && (
          <Field
            name="price"
            type="number"
            label={translate("catalogueEditing.form.price")}
          >
            {({ field }) => <Input.Text {...field} prefix="£" />}
          </Field>
        )}
        <div className="flex flex-wrap md:flex-no-wrap items-center pt-2 pb-5">
          {catalogue && (
            <RouteLink href={`/catalogues/update/${catalogue.id}/${item.sku}`}>
              <Link
                color="light"
                underline
                className="ml-auto md:ml-0 mr-2"
                styles={{
                  "::before": {
                    backgroundColor: theme.colors.light.base,
                  },
                }}
              >
                {translate("catalogueEditing.advancedEdit")}
              </Link>
            </RouteLink>
          )}
          <div className="w-full md:w-auto md:ml-auto mt-6 flex justify-between md:block md:mt-0">
            <Button
              size="medium"
              color="negative"
              type="button"
              className="mr-2"
              onClick={handleDeleteItem}
            >
              {translate("form.buttons.remove")}
            </Button>
            <Button size="medium" color="accent" type="submit">
              {translate("form.buttons.save")}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
