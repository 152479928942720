"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roles = void 0;
exports.roles = {
    headings: {
        formHeading: "Add New Role",
        roleHeading: "View Roles",
        updateHeading: "Update Role",
    },
    buttons: {
        submitForm: "Add Role",
        loading: "Loading",
        edit: "Edit",
        createRole: "Create Role",
        updateRole: "Update Role",
    },
    form: {
        labels: {
            name: "Role Name",
            type: "Type",
            createdOn: "Created On",
            editRole: "Edit Role",
            permissions: "Permissions*",
            updatePermissions: "Permissions",
        },
    },
    success: {
        successMessage: "Created role",
        updateSuccess: "Updated role",
    },
    errors: {
        errorMessage: "Unfortuanlty we can't handle your request right now, try again later.",
        permissionRequired: "At least one permission must be selected",
    },
};
