import React from "react";

/**
 * Import transition component.
 */
import { SpringTransition } from "../../core";

/**
 * Import images.
 */
import { BackgroundCircle as Circle } from "../../../images";
import { ReactComponent as DottedLine } from "../images/auth-background-line-bottom.svg";
import { ReactComponent as LogoBee } from "../images/auth-logo-bee.svg";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const PanelLeft = () => {
  return (
    <SpringTransition
      className="transform scale-75 xl:scale-100 z-0 fixed left-0 auth-shapes auth-shapes--bottom"
      duration={1000}
    >
      <Circle className="fixed bottom-0 left-0 auth-shapes__circle" />
      <DottedLine className="fixed bottom-0 left-0 auth-shapes__line" />
      <LogoBee className="fixed auth-shapes__bee" />
    </SpringTransition>
  );
};
