import { useContext } from "react";

/**
 * Import authContext.
 */
import { authContext } from "../contexts";
import { IAuthContext } from "./useAuthHandler";

/**
 * The useAuth hook uses the useContext hook.
 * - The useAuth hook uses the authContext context
 * - The authContext uses a hook called useProvideAuth
 */
export const useAuthContext = (): IAuthContext => useContext(authContext);
