import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Heading,
  Modal,
  Panel,
  Text,
  Button,
  usePagination,
  FilterList,
  theme,
  Form,
  Icon,
  displayErrorMessage,
  Box,
  Input,
} from "@clearabee/ui-library";
import { getCatalogues, cascadeCatalogueTemplate } from "api";
import { Template } from "models/template";
import { CascadeCatalogueData, Catalogue } from "api/types";
import { FormLoader, ErrorMessage } from "../../common/components";
import { getQueriedResults } from "../../../helpers";
import { cascadeValidation } from "../validation";
import { RouteLink } from "components/core";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";
import { Tooltip, TooltipContent, TooltipTrigger } from "../components";

export interface CascadeState {
  heading: string;
  sku: Template["sku"];
  data: Partial<Template>;
}

type FormValues = Pick<CascadeCatalogueData, "catalogueIds">;

export const CascadeTemplate = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOverriddenCascading, setIsOverriddenCascading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { state: cascadeState } = useLocation<CascadeState | undefined>();
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<Catalogue[]>();

  const { mutate, error, reset } = useMutation(
    (data: CascadeCatalogueData) =>
      cascadeCatalogueTemplate(id, data, !isOverriddenCascading),
    {
      onMutate: () => setLoading(true),
      onSettled: () => setLoading(false),
      onSuccess: () => setShowModal(true),
    },
  );

  const { data, isLoading, isFetching, isError } = useQuery(
    ["readCatalogues"],
    () => getCatalogues({ limit: 2000 }),
    {
      retry: 0,
    },
  );
  const { PaginationComponent, paginatedData } = usePagination(
    results ?? data?.items ?? [],
  );

  useEffect(
    () =>
      setResults(
        getQueriedResults(data?.items, search, ["title", "description"]),
      ),
    [search],
  );

  if (isLoading || isFetching || !data) {
    return <FormLoader isLoading />;
  } else if (isError || cascadeState?.sku !== id) {
    return <ErrorMessage />;
  }

  const handleSubmit = (values: FormValues) => {
    mutate({
      attrs: cascadeState.data,
      catalogueIds: values.catalogueIds,
    });
  };

  return (
    <div className="max-w-screen-lg mx-auto pb-10">
      <Link
        to={`/catalogues/templates/update/${id}`}
        className="inline-flex items-center mb-5"
      >
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues/templates/update"),
        })}
      </Link>
      <Form
        initialValues={{}}
        onSubmit={(values) => handleSubmit(values as FormValues)}
        validationSchema={cascadeValidation}
      >
        {({ setFieldValue, isValid }) => (
          <>
            <Panel
              styles={{
                paddingRight: theme.spacing.medium,
                paddingLeft: theme.spacing.medium,
                paddingTop: theme.spacing.xsmall,
                paddingBottom: theme.spacing.xsmall,
              }}
              shadow={false}
            >
              <Box className="w-full flex flex-row justify-end items-center pr-12 gap-x-2 pt-1 relative top-5">
                <Box className="flex flex-row items-center gap-x-1">
                  <Text fontSize="small">{translate("overrideCascade")}</Text>
                  <Tooltip placement="top">
                    <TooltipTrigger>
                      <Icon.Question color="brand" size="small" />
                    </TooltipTrigger>
                    <TooltipContent style={{ zIndex: 10 }} className="Tooltip">
                      <Panel
                        styles={{
                          padding: theme.spacing.small,
                          width: "600px",
                          textAlign: "left",
                          whiteSpace: "pre-line",
                        }}
                      >
                        <Text fontSize="small">
                          {translate("tooltips.overrideCascade")}
                        </Text>
                      </Panel>
                    </TooltipContent>
                  </Tooltip>
                </Box>
                <Input.Toggle
                  onClick={() =>
                    setIsOverriddenCascading(!isOverriddenCascading)
                  }
                />
              </Box>
              <FilterList
                searchPlaceholder={translate(
                  "filters.catalogueSearchPlaceholder",
                )}
                options={paginatedData.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
                content={
                  <>
                    <Heading
                      fontSize="large"
                      color="brand"
                      styles={{
                        marginBottom: 5,
                      }}
                      level={2}
                    >
                      {cascadeState.heading}
                    </Heading>
                    <Text>{translate("filters.cascadeSearchLabel")}:</Text>
                  </>
                }
                onSearch={(search) => setSearch(search)}
                onOptionsChange={(options) =>
                  setFieldValue(
                    "catalogueIds",
                    options.map(({ value }) => value),
                  )
                }
              />
            </Panel>
            <div className="md:flex">
              <div
                className="flex-auto"
                style={{ marginTop: theme.spacing.large }}
              >
                <PaginationComponent />
              </div>
              <div className="flex justify-center sm:justify-end">
                <Link to={`/catalogues/templates/update/${id}`}>
                  <Button
                    size="medium"
                    color="negative"
                    type="button"
                    styles={{
                      marginLeft: theme.spacing.small,
                      marginTop: theme.spacing.medium,
                      textAlign: "center",
                    }}
                  >
                    {translate("form.buttons.cancel")}
                  </Button>
                </Link>
                <Button
                  size="medium"
                  color="accent"
                  type="submit"
                  styles={{
                    marginLeft: theme.spacing.small,
                    marginTop: theme.spacing.medium,
                  }}
                  disabled={!isValid || loading}
                >
                  {translate("form.buttons.save")}
                </Button>
              </div>
            </div>
          </>
        )}
      </Form>
      {displayErrorMessage(error, (props) => (
        <DefaultErrorComponent
          {...props}
          styles={{
            marginTop: theme.spacing.small,
            marginBottom: theme.spacing.small,
            maxWidth: theme.screens.small,
            marginRight: 0,
          }}
          onClose={reset}
        />
      ))}
      {showModal && (
        <Modal
          heading={translate("modal.cascade.heading")}
          width={500}
          actions={
            <>
              <RouteLink href="/catalogues/templates">
                <Button size="medium" color="brand" as="a" className="mr-3">
                  {translate("modal.cascade.goToTemplates")}
                </Button>
              </RouteLink>
              <RouteLink href={`/catalogues/templates/update/${id}`}>
                <Button size="medium" color="brand" as="a" className="ml-3">
                  {translate("modal.cascade.backToTemplate")}
                </Button>
              </RouteLink>
            </>
          }
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("modal.cascade.message")}
          </div>
        </Modal>
      )}
    </div>
  );
};
