import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { instance } from "@clearabee/ui-sdk";

/**
 * Import components.
 */
import {
  Form,
  Field,
  Input,
  Button,
  displayErrorMessage,
  Message,
} from "@clearabee/ui-library";
import { SpringTransition } from "../core";
import { BackButton, SuccessModal } from "../common/components";

/**
 * Import hooks.
 */
import { useAuthContext, useScreenWidth } from "../../hooks";

/**
 * Import api.
 */
import { userCompanies, readRoles, createUser } from "../../api";

/**
 * Import helpers.
 */
import { toasts } from "helpers";

/**
 * Import parser.
 */
import {
  OptionForSelect,
  parseCompaniesDataForSelect,
  parseRolesDataForSelect,
  parseSuppliersDataForSelect,
} from "./parser";

/**
 * Import validation.
 */
import {
  getCreateUserValidation,
  createUserInitialValues,
  ICreateUserFormState,
} from "./validation";
import roles from "constants/roles";

export const CreateUser = (): React.ReactElement => {
  /**
   * Permissions
   */
  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);

  /**
   * i18n translation.
   */
  const [t] = useTranslation("users");

  /**
   * Is desktop.
   */
  const { isMobile } = useScreenWidth();

  // LORENZO - comment these things back in
  /**
   * State.
   */
  const [redirect, setRedirect] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  // const [isSupplier, setIsSupplier] = useState(false);

  /**
   * Query hook.
   */
  const { data: companiesData, isLoading: isLoadingCompanies } = useQuery(
    "userCompanies",
    userCompanies,
  );
  const { data: rolesData, isLoading: isLoadingRoles } = useQuery(
    "readRoles",
    readRoles,
  );
  // const { data: suppliersData, isLoading: isLoadingSuppliers } = useQuery(
  //   "readSuppliersForCreateUser",
  //   () => instance.users.getSuppliers(),
  // );

  /**
   * SuppliersData
   */
  // const suppliersOptions = suppliersData
  //   ? parseSuppliersDataForSelect(suppliersData.data.items)
  //   : [];
  // const supplierChoices = suppliersOptions && suppliersOptions.length > 0;

  /**
   * Companies data.
   */
  const companiesOptions = companiesData
    ? parseCompaniesDataForSelect(
        isClearabeeAdmin ? companiesData : getCurrentUserCompanies(),
      )
    : [];
  const companiesChoices = companiesOptions && companiesOptions.length > 0;

  /**
   * Roles data.
   */
  const rolesOptions = parseRolesDataForSelect(
    rolesData?.items || [],
    isClearabeeAdmin ? true : false,
  );
  const rolesChoices = rolesOptions?.length > 0;

  /**
   * Create user on submit.
   */
  const {
    mutate: createUserMutation,
    isLoading: isLoadingCreateUser,
    error: errorsCreateUser,
  } = useMutation("createUser", createUser, {
    onSuccess: () => {
      setSuccess(true);
    },
  });

  /**
   * Create SUPPLIER user on submit.
   * LORENZO - instead of create user, here we want to use a createSupplierUser
   * function that we can extract from the ui-sdk
   */
  // const {
  //   mutate: createSupplierUserMutation,
  //   isLoading: isLoadingCreateSupplierUser,
  // } = useMutation(createUser, {
  //   onError: () => {
  //     toasts.error({
  //       message: "User could not be created. Try again",
  //     });
  //   },
  //   onSuccess: () => {
  //     setSuccess(true);
  //   },
  // });

  if (redirect) {
    return <Redirect to="/users" />;
  }

  return (
    <>
      <div className="center-screen-form md:absolute md:max-w-screen-sm xl:max-w-screen-lg min-w-full md:min-w-screen-md xl:min-w-screen-lg sm:px-4 py-5">
        <div className="mb-10">
          <BackButton
            text={t("user.buttons.backToUsers")}
            onClick={() => setRedirect(true)}
          />
        </div>
        <Form
          initialValues={createUserInitialValues}
          validationSchema={
            getCreateUserValidation() // isSupplier
          }
          onSubmit={(values: ICreateUserFormState) => {
            // if (values.isSupplier) {
            createUserMutation({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber || undefined,
              roles: values.roles.map(({ value }) => parseInt(value)) ?? [],
              companies:
                values?.company?.map(({ value }: OptionForSelect) =>
                  Number(value),
                ) ?? [],
            });
            // }
            /**
             * LORENZO - above, we are sending a payload to create user if the payload
             * contains a company. Below, we want to send a payload to createSupplierUserMutation
             * if they payload contains a SUPPLIER instead of a company
             */
            // else {
            //   createSupplierUserMutation({
            //     firstName: values.firstName,
            //     lastName: values.lastName,
            //     email: values.email,
            //     phoneNumber: values.phoneNumber || undefined,
            //     roles: [Number(values.role)],
            //     suppliers: [Number(values.supplier)],
            //   });
            // }
          }}
        >
          {({ isValid, dirty, handleReset, setFieldValue }) => (
            <>
              {/* {useEffect(() => {
                setIsSupplier(values.isSupplier);
              }, [values.isSupplier])} */}
              <SpringTransition
                className="bg-white p-6 pb-8 lg:pt-8 lg:pb-10 lg:px-8 shadow-filter rounded-xl mb-8 relative z-20"
                duration={500}
                transition="fadeInDown"
                distance={100}
              >
                <div className="flex flex-row justify-between pb-3">
                  <h3 className="text-xl text-primary font-bold">
                    {t("user.titles.newUser")}
                  </h3>
                </div>

                {/* This toggle is only visible to clearabee admin */}
                {/* {isClearabeeAdmin && (
                  <Field
                    name="isSupplier"
                    type="checkbox"
                    label={t("user.form.labels.isSupplier")}
                  >
                    {({ field }) => <Input.Toggle {...field} />}
                  </Field>
                )} */}

                <div className="grid md:grid-cols-3 md:grid-rows-2 gap-x-6">
                  <div>
                    <Field
                      name="firstName"
                      label={t("user.form.labels.firstName")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t("user.form.placeholders.firstName")}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="lastName"
                      label={t("user.form.placeholders.lastName")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t("user.form.placeholders.lastName")}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="email"
                      label={t("user.form.placeholders.emailAddress")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t("user.form.placeholders.emailAddress")}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field
                      name="phoneNumber"
                      label={t("user.form.placeholders.phoneNumber")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t("user.form.placeholders.phoneNumber")}
                        />
                      )}
                    </Field>
                  </div>
                  {/* !values.isSupplier && */}
                  <div>
                    <Field
                      styles={{
                        flex: 1,
                      }}
                      name="company"
                      label={t("user.form.placeholders.company")}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          placeholder={
                            isLoadingCompanies
                              ? "Loading..."
                              : t("user.form.placeholders.searchValue")
                          }
                          isSearchable
                          isClearable
                          isMulti
                          disabled={!companiesChoices}
                          options={companiesOptions}
                          className="text-base"
                          onChange={(values: OptionForSelect[]) => {
                            if (values === null)
                              return setFieldValue("company", []);

                            setFieldValue("company", values);
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  {/* {values.isSupplier && (
                    <div>
                      <Field
                        name="supplier"
                        label={t("user.form.labels.supplier")}
                      >
                        {({ field }) => (
                          <Input.Select
                            {...field}
                            options={suppliersOptions || []}
                            disabled={!supplierChoices}
                            isSearchable
                            menuPlacement={isMobile ? "top" : "bottom"}
                            placeholder={
                              isLoadingSuppliers
                                ? "Loading..."
                                : t("user.form.placeholders.searchSupplier")
                            }
                          />
                        )}
                      </Field>
                    </div>
                  )} */}

                  <div>
                    <Field
                      name="roles"
                      label={t("user.form.placeholders.role", {
                        plural: isClearabeeAdmin ? "s" : "",
                      })}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          isClearable
                          isSearchable
                          isMulti={isClearabeeAdmin}
                          disabled={!rolesChoices || isLoadingRoles}
                          menuPlacement={isMobile ? "top" : "bottom"}
                          placeholder={
                            isLoadingRoles
                              ? "Loading..."
                              : t("user.form.placeholders.selectRole", {
                                  plural: isClearabeeAdmin ? "s" : "",
                                })
                          }
                          options={rolesOptions ?? []}
                          onChange={(values: OptionForSelect[]) => {
                            if (values === null)
                              return setFieldValue("roles", []);

                            /**
                             * if multi-select allowed, "values" from onChange will be an array of label and value
                             * otherwise just an object of label and value
                             * roles property is an array, so if multi-select is not allowed (when user is not ClearabeeAdmin), wrap "values" in an array
                             */
                            setFieldValue(
                              "roles",
                              isClearabeeAdmin ? values : [values],
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  {displayErrorMessage(errorsCreateUser, ({ children }) => (
                    <div className="flex flex-col items-center">
                      <Message type="error" background>
                        {children}
                      </Message>
                    </div>
                  ))}
                </div>
              </SpringTransition>

              {/* LORENZO - we want this button to also be disabled when isLoadingCreateSupplierUser is true.
              Currently that variable is blocked out above */}
              <SpringTransition transition="fadeInUp">
                <div className="flex justify-center">
                  <Button
                    size="medium"
                    name="update"
                    type="submit"
                    disabled={
                      !dirty || !isValid || isLoadingCreateUser || isSuccess
                    }
                  >
                    {t("user.buttons.create")}
                  </Button>
                </div>
              </SpringTransition>

              <SuccessModal
                title={t("user.modals.headings.createUserSuccess")}
                buttonOne={{
                  label: t("user.form.labels.backToUsers"),
                  to: "/users",
                }}
                buttonTwo={{
                  label: t("user.form.labels.createAnother"),
                  close: true,
                }}
                hideCallback={() => {
                  handleReset();
                  setSuccess(false);
                }}
                visible={isSuccess}
              />
            </>
          )}
        </Form>
      </div>
    </>
  );
};
