import React, { Fragment } from "react";
import { ApiResponseData, camelToCapital, instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import {
  Text,
  Heading,
  Button,
  FlexGrid,
  formatCurrency,
  Panel,
  theme,
} from "@clearabee/ui-library";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { AdditionalInfoLine } from "./additionalInfoLine";
import clipboardCopy from "clipboard-copy";
import dayjs from "dayjs";
import { useQuery } from "react-query";

interface AddtionalInfoProps {
  data: ApiResponseData<typeof instance.jobs.getJobByRef> & {
    timestamp?: string;
  };
  setBookJobAgainOpen: (value: boolean) => void;
}

const MetaValue = ({
  value,
  enabled,
  // Please remove returnBoolean when API-3093 is merged https://clearabee.atlassian.net/browse/API-3093
  returnBoolean,
}: {
  enabled: boolean;
  value: string;
  returnBoolean: boolean;
}): React.ReactElement => {
  if (enabled && !value) return <>No data</>;

  if (dayjs(value, "YYYY-MM-DDTHH:mm:ss*").isValid())
    return <>{dayjs(value).format("dddd, MMMM Do YYYY")}</>;

  if (returnBoolean) return <>{!!value ? "Yes" : "No"}</>;

  return <>{value}</>;
};

export const AdditionalInfo = ({
  data,
  setBookJobAgainOpen,
}: AddtionalInfoProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { getCurrentUserCurrentCompanySettings, doesUserHaveRole } =
    useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeStaff = doesUserHaveRole(roles.CLEARABEE_CUSTOMER_SERVICE);
  const { canInvoice } = getCurrentUserCurrentCompanySettings() || {};

  const { data: orderTypes, isLoading } = useQuery(
    ["getOrderTypes"],
    async () => (await instance.catalogues.getOrderTypes()).data,
  );
  const timeSlotData = [
    new Set(
      [
        data.timeslot,
        data.timePreference,
        data.timeslotJson
          ? data.timeslotJson.from + " - " + data.timeslotJson.to
          : undefined,
      ].filter(Boolean),
    ),
  ];

  const updatedMeta =
    data.meta &&
    Object.entries(data.meta).filter(([key]) => {
      return key !== "jobResult";
    });

  return (
    <Panel className="mb-12">
      <div className="flex flex-row justify-between items-center mb-3">
        <Heading
          color="brand"
          level={5}
          styles={{ marginBottom: theme.spacing.small }}
        >
          {translate("headings.additonalInformation")}
        </Heading>
        {canInvoice && data?.companyCode && (
          <Button
            size="small"
            color="brand"
            type="button"
            styles={{ marginLeft: theme.spacing.small }}
            onClick={() => setBookJobAgainOpen(true)}
          >
            {translate("update.buttons.bookAgain")}
          </Button>
        )}
      </div>

      <FlexGrid rowSpacing={null}>
        <FlexGrid.Cell base="100%" medium="25%">
          <AdditionalInfoLine label={translate("labels.dateCreated")}>
            {data.timestamp
              ? dayjs(data.timestamp).format("dddd, MMMM Do YYYY")
              : undefined}
          </AdditionalInfoLine>
        </FlexGrid.Cell>

        <FlexGrid.Cell base="100%" medium="25%">
          <AdditionalInfoLine label={translate("labels.orderType")}>
            {isLoading && "Loading..."}
            {!isLoading &&
              !!data.items?.[0]?.orderTypeId &&
              orderTypes?.items.find(
                ({ id }) => id === data.items?.[0].orderTypeId,
              )?.name}
          </AdditionalInfoLine>
        </FlexGrid.Cell>
        <FlexGrid.Cell base="100%" medium="25%">
          <AdditionalInfoLine label={translate("labels.creditUsed")}>
            {data?.creditUsed ? formatCurrency(data?.creditUsed) : undefined}
          </AdditionalInfoLine>
        </FlexGrid.Cell>
        {(isClearabeeAdmin || isClearabeeStaff) && (
          <FlexGrid.Cell base="100%" medium="25%">
            <AdditionalInfoLine label={translate("labels.bigChangeId")}>
              {typeof data.bcId !== "undefined" ? (
                <div className="flex items-center">
                  <Text fontSize="small">
                    {!!data.bcId ? data.bcId : translate("noBigChangeId")}
                  </Text>
                  <Button
                    onClick={async () => {
                      await clipboardCopy(data.bcId!);
                    }}
                    className="ml-2"
                    color="accent"
                    size="xsmall"
                  >
                    {translate("copy")}
                  </Button>
                </div>
              ) : undefined}
            </AdditionalInfoLine>
          </FlexGrid.Cell>
        )}
        <FlexGrid.Cell base="100%" medium="33.33%">
          <AdditionalInfoLine label={translate("labels.timeSlot")}>
            {!!timeSlotData.length &&
              timeSlotData.map((timeslot, index) => (
                <p key={index}>{timeslot}</p>
              ))}
          </AdditionalInfoLine>
        </FlexGrid.Cell>
        <FlexGrid.Cell base="100%" medium="33.33%">
          <AdditionalInfoLine label={translate("labels.products")}>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  data?.items
                    ?.map(
                      ({ qty, lineCost, title }) =>
                        `${qty} x ${title} - ${formatCurrency(lineCost)}`,
                    )
                    .join("<br/>") || "",
              }}
            />
          </AdditionalInfoLine>
        </FlexGrid.Cell>
        <FlexGrid.Cell base="100%" medium="33.33%">
          <AdditionalInfoLine label={translate("labels.meta")}>
            {updatedMeta?.length ? (
              <>
                {updatedMeta.map(([key, value]) => {
                  return (
                    <Fragment key={`${key}-${value}`}>
                      <p className="flex">
                        <strong className="inline-block mr-2 text-sm">
                          {camelToCapital(key)}:
                        </strong>
                        <MetaValue
                          enabled={key.toLocaleLowerCase().includes("orderref")}
                          value={value}
                          returnBoolean={key
                            .toLocaleLowerCase()
                            .includes("readytocollect")}
                        />
                      </p>
                    </Fragment>
                  );
                })}
              </>
            ) : undefined}
          </AdditionalInfoLine>
        </FlexGrid.Cell>
      </FlexGrid>
    </Panel>
  );
};
