import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export const styles = StyleSheet({
  defaults: {
    "> span": {
      display: "block",
    },
  },
  left: {},
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "center",
    "> span": {
      display: "inline",
    },
    "> :first-child": {
      marginRight: "6px",
    },
    "> :last-child": {
      display: "block",
    },
  },
  inline: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing.xsmall,
  },
  price: {
    fontWeight: "bold",
  },
});
