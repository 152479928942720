"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCurrency = void 0;
/**
 * Formats a number into a currency string.
 * @param numberToFormat number Number to format into currency string
 * @param symbol string The currency symbol
 * @returns string
 */
var formatCurrency = function (numberToFormat, symbol, position) {
    if (symbol === void 0) { symbol = "£"; }
    if (position === void 0) { position = "prefix"; }
    return "".concat(position === "prefix" ? symbol : "").concat(numberToFormat
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")).concat(position === "suffix" ? symbol : "");
};
exports.formatCurrency = formatCurrency;
