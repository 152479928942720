import React, { useEffect, useState } from "react";
import { readUsersTableResults } from "../../api";
import { useAuthContext } from "../../hooks";
import { PaginatedResults } from "../common/components";
import { UserName, UserActive, UserCompany, UserFilters } from "./components";
import { RouteLink } from "components/core";
import { Button, Heading, Panel, theme } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import roles from "constants/roles";

const tableColumns = [
  {
    Header: "Status",
    accessor: "active",
    Cell: UserActive,
    width: 75,
  },
  {
    Header: "Name",
    accessor: "firstName",
    Cell: UserName,
    width: 100,
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Company",
    accessor: "company",
    Cell: UserCompany,
    width: 100,
  },
];

export const ReadUsers = (): React.ReactElement => {
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const isCompanyAdmin = doesUserHaveRole(roles.COMPANY_ADMIN);
  const [translate] = useTranslation("users");
  const [queryKey, setQueryKey] = useState("readUsers");
  const { search } = useLocation();

  useEffect(() => {
    if (!search) return;

    const query = new URLSearchParams(search);
    setQueryKey(`readUsers${query.get("deleted")}`);
  }, [search]);

  return (
    <div data-testid="readUsersComponentIdentifier">
      <PaginatedResults
        apiFn={readUsersTableResults}
        queryKey={queryKey}
        tableColumns={tableColumns}
        actions={!isClearabeeManager ? ["edit", "delete"] : []}
      >
        {({ isFetching, updateFilters }) => (
          <>
            <Panel styles={{ marginBottom: theme.spacing.large }}>
              <div className="flex flex-row items-center justify-between">
                <Heading level={1} fontSize="large" color="brand">
                  {translate("common.title")}
                </Heading>
                <RouteLink href="/users/create">
                  <Button
                    size="small"
                    as="a"
                    className="inline-block text-center"
                  >
                    {translate("common.addUser")}
                  </Button>
                </RouteLink>
              </div>
              {(isClearabeeAdmin || isCompanyAdmin || isClearabeeManager) && (
                <UserFilters
                  isFetching={isFetching}
                  updateFilters={updateFilters}
                />
              )}
            </Panel>
          </>
        )}
      </PaginatedResults>
    </div>
  );
};
