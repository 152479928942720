import React from "react";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import {
  Box,
  Gallery,
  Heading,
  Panel,
  formatCurrency,
} from "@clearabee/ui-library";
import dayjs from "dayjs";

interface JobDetailsProps {
  status: string;
  collectionDate: string;
  jobReference: string;
  description: string;
  data?: ApiResponseData<typeof instance.jobs.getJobByRef> & {
    timestamp?: string;
  };
}

export const JobDetails = ({
  status,
  data,
  collectionDate,
  jobReference,
  description,
}: JobDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  return (
    <>
      <Panel styles={{ paddingTop: "10px" }}>
        <Box className="text-left mt-2">
          <Heading color="brand" level={4}>
            {translate("headings.jobDetails")}
          </Heading>
        </Box>
        <Box className="border-t border-grey-200 mt-4 pt-5 flex flex-col -mx-2" />
        <Box className="flex justify-between">
          <Box className="text-left flex flex-col">
            <Box className="mb-4">
              <Box className="font-bold ml-2">
                {translate("headings.status")}
              </Box>
              <Box
                backgroundColor={
                  (status === "Pending" && "warning.base") ||
                  (status === "Completed" && "positive.base") ||
                  (status === "Loading" && "brand.base") ||
                  "negative.base"
                }
                className={`${
                  status === "Pending" ? "text-black" : "text-white"
                } font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block`}
              >
                {status}
              </Box>
            </Box>
            <Box className="mr-10 mb-2 ml-2">
              <Box className="font-bold">{translate("labels.products")}</Box>
              <Box
                dangerouslySetInnerHTML={{
                  __html:
                    data?.items
                      ?.map(
                        ({ qty, lineCost, title }) =>
                          `${qty} x ${title} - ${formatCurrency(lineCost)}`,
                      )
                      .join("<br/>") || "",
                }}
              />
            </Box>
          </Box>

          <Box className="text-left w-1/4">
            <Box className="mb-4">
              <Box className="font-bold ">
                {translate("headings.collectionDate")}
              </Box>
              <Box>{dayjs(collectionDate).format("dddd, MMMM Do YYYY")}</Box>
            </Box>
            {!!data && !!data.images?.length && (
              <Box>
                <Box className="font-bold mb-2">
                  {translate("labels.images")}
                </Box>
                <Gallery
                  items={data.images.map((image) => ({ source: image.url }))}
                />
              </Box>
            )}
          </Box>
          <Box className="text-left w-1/4">
            <Box className="font-bold">
              {translate("headings.jobReference")}
            </Box>
            <Box>{jobReference}</Box>
          </Box>
          <Box className="text-left w-1/4">
            <Box>
              <Box className="font-bold">{translate("description")}</Box>
            </Box>
            <Box>{description}</Box>
          </Box>
        </Box>
      </Panel>
    </>
  );
};
