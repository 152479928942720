import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Form,
  Button,
  Field,
  Input,
  Icon,
  Panel,
  theme,
  Heading,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";
import { TFilters } from "api/types";
import { parseCompaniesDataForFilter } from "components/users/parser";
import { SearchIcon } from "images";
import { readCompaniesForTables } from "api";
import { buildQuery } from "helpers/api";
import { useAuthContext } from "hooks";
import roles from "constants/roles";

interface JobsFilters {
  status: string;
  companyCode: string;
  ref: string;
  addressPostcode: string;
  date: string;
  createdAt: string;
  purchaseOrder: string;
}

const getParamsFromValues = (values: JobsFilters) => {
  const {
    status,
    addressPostcode,
    ref,
    companyCode,
    purchaseOrder,
    date,
    createdAt,
  } = values;
  const [dateStart, dateEnd] = date.split(" - ");
  const [createdAtStart, createdAtEnd] = createdAt.split(" - ");

  const params: Record<string, string> = {
    "status:eq": status === "all" ? "" : status,
    "addressPostcode:likeLower": `%${addressPostcode.replace(" ", "")}%`,
    "ref:likeLower": `%${ref}%`,
    "purchaseOrder:likeLower": `${purchaseOrder}`,
    "companyCode:eq": companyCode,
    "date:gte": dateStart
      ? dayjs(dayjs(dateStart, "DD/MM/YYYY"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "date:lte": dateEnd
      ? dayjs(dayjs(dateEnd, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : dateStart
      ? dayjs(dayjs(dateStart, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "timestamp:gte": createdAtStart
      ? dayjs(dayjs(createdAtStart, "DD/MM/YYYY"))
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    "timestamp:lte": createdAtEnd
      ? dayjs(dayjs(createdAtEnd, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : createdAtStart
      ? dayjs(dayjs(createdAtStart, "DD/MM/YYYY"))
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      : "",
    ...defaultParams,
  };

  return buildQuery(params);
};

interface JobsFiltersProps {
  isFetching: boolean;
  updateFilters: (filters: TFilters) => void;
  onDownloadClick: () => void;
  setEnabled: (value: boolean) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const defaultParams = {
  orderByDesc: "date",
};

export const JobsFilters = ({
  isFetching,
  updateFilters,
  onDownloadClick,
  setEnabled,
  currentPage,
  setCurrentPage,
}: JobsFiltersProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const history = useHistory();
  const { doesUserHaveRole, getCurrentUserCompanies } = useAuthContext();
  const currentUserCompanies = getCurrentUserCompanies();
  const isAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isViewOnly = doesUserHaveRole(roles.VIEW_ONLY);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [filterInitialValues, setFilterInitialValues] = useState({
    status: params.get("status") ?? "",
    companyCode: params.get("companyCode") ?? "",
    ref: params.get("ref") ?? "",
    addressPostcode: params.get("addressPostcode") ?? "",
    date: params.get("date") ?? "",
    createdAt: params.get("createdAt") ?? "",
    purchaseOrder: params.get("purchaseOrder") ?? "",
  });

  const { isLoading, data: companyData } = useQuery(
    ["readCompanies"],
    () => readCompaniesForTables("", 0, 1000),
    {
      enabled: !isViewOnly,
    },
  );
  const companiesOptions = parseCompaniesDataForFilter(
    !!companyData?.items.length ? companyData.items : currentUserCompanies,
  );

  /*
   * This useEffect is used to check if there are any filters in the URL
   * and if there are, it applies the filters
   */
  useEffect(() => {
    const filtersExist = Object.values(filterInitialValues).some(
      (value) => value !== "",
    );
    if (filtersExist) {
      const queryParams = getParamsFromValues(filterInitialValues);
      updateFilters(queryParams);
      setEnabled(true);
    }
  }, []);

  /**
   * This useEffect is used to update the URL when the pagination changes
   */
  useEffect(() => {
    const currentUrlWithoutBase = location.pathname + location.search;

    if (currentUrlWithoutBase.includes("page=")) {
      const updatedCurrentUrlWithoutBase = currentUrlWithoutBase.replace(
        /page=\d+/g,
        `page=${currentPage}`,
      );

      // replace the current url with the updated url
      history.push(updatedCurrentUrlWithoutBase);
    }
  }, [currentPage]);

  const clearFilters = () => {
    history.push("/jobs");
    setFilterInitialValues({
      status: "all",
      companyCode: "",
      ref: "",
      addressPostcode: "",
      date: "",
      createdAt: "",
      purchaseOrder: "",
    });
    updateFilters(buildQuery(defaultParams));
  };

  const statusOptions = [
    "all",
    "Completed",
    "Started",
    "Open",
    "On+the+way",
    "Scheduled",
    "Failed",
    "Cancelled",
  ].map((value) => ({
    label: translate(`form.statusOptions.${value}`),
    value,
  }));

  const handleSubmit = (values: typeof filterInitialValues) => {
    setEnabled(true);
    const queryParams = getParamsFromValues(values);
    updateFilters(queryParams);
    const filters = Object.entries({
      ...values,
      page: 1,
    }).filter(([_, value]) => value !== "");
    history.push(
      `/jobs?${filters
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join("&")}`,
    );
    setCurrentPage(1);
  };

  return (
    <Form
      initialValues={filterInitialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Panel styles={{ marginBottom: theme.spacing.large }}>
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <Heading level={1} fontSize="large" color="brand">
            {translate("filters.title")}
          </Heading>

          <div className="flex flex-col md:flex-row items-stretch md:items-center md:self-end md:space-x-3 md:space-y-0 space-y-3 mt-3 md:mt-0">
            {isAdmin && (
              <Button
                variant="outline"
                color="usp"
                size="small"
                type="button"
                disabled={isFetching}
                onClick={onDownloadClick}
              >
                {translate("filters.buttons.downloadFilteredJobs")}
                <Icon.Download className="inline-block w-3 h-3 ml-1 -mt-1" />
              </Button>
            )}
            <Button
              variant="outline"
              color="negative"
              size="small"
              type="reset"
              onClick={clearFilters}
            >
              {translate("filters.buttons.reset")}
            </Button>
            {!isViewOnly && (
              <RouteLink href="/jobs/create">
                <Button
                  as="a"
                  className="text-center"
                  variant="outline"
                  color="accent"
                  size="small"
                >
                  {translate("filters.buttons.bookAJob")}
                </Button>
              </RouteLink>
            )}
          </div>
        </div>
        <div className="border-t border-grey-200 mt-4 pt-5 flex flex-col -mx-2">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="status" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Select
                    {...field}
                    options={statusOptions}
                    placeholder={translate("form.placeholder.status")}
                    disabled={isFetching}
                    isSearchable
                    defaultValue={statusOptions[0].value}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              {!isLoading ? (
                <Field name="companyCode" styles={{ margin: 0 }}>
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={companiesOptions}
                      placeholder={translate("form.placeholder.company")}
                      isLoading={isLoading}
                      isClearable
                      isSearchable
                      disabled={isFetching || isLoading}
                    />
                  )}
                </Field>
              ) : (
                <div className="flex h-full items-center justify-center">
                  Loading companies
                  <Icon.Loading className="ml-4" size="medium" />
                </div>
              )}
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="date" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.RangedDate
                    {...field}
                    placeholder={translate("form.placeholder.bookingDate")}
                    disabled={isFetching}
                    acceptSingleDate
                    collapsable
                    initialValue={filterInitialValues.date.split(" - ")}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="createdAt" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.RangedDate
                    {...field}
                    placeholder={translate("form.placeholder.dateCreated")}
                    disabled={isFetching}
                    acceptSingleDate
                    disabledDays={{
                      after: new Date(),
                    }}
                    collapsable
                    initialValue={filterInitialValues.createdAt.split(" - ")}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="ref" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate("form.placeholder.reference")}
                    disabled={isFetching}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Field name="addressPostcode" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate("form.placeholder.postcode")}
                    disabled={isFetching}
                  />
                )}
              </Field>
            </div>

            <div className=" md:flex-1 px-2 mb-3">
              <Field name="purchaseOrder" styles={{ margin: 0 }}>
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={"Purchase Order"}
                    disabled={isFetching}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:flex-1 px-2 mb-3">
              <Button
                id="submitQuoteFilters"
                type="submit"
                disabled={isFetching}
                color="accent"
                size="small"
                className="w-full flex justify-center items-center"
              >
                <SearchIcon className="mr-2" />
                {translate("form.label.search")}
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </Form>
  );
};
