import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

export const graphQLClient = (
  token: string,
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    uri: `${process.env.REACT_APP_MS_API_BASE_URL}/reports`,
    cache: new InMemoryCache(),
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
};
