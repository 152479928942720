import * as Yup from "yup";
import { stringRequired, fieldRequired } from "validation/common";

export const rowValidationSchema = Yup.object().shape({
  jobReference: stringRequired,
  invoiceAmount: Yup.string()
    .matches(/^[1-9]\d*(\.\d+)?$/, "Numbers only")
    .required(fieldRequired),
  subcontractors: Yup.array()
    .of(Yup.object({ label: stringRequired, value: stringRequired }))
    .nullable()
    .required(fieldRequired),
  description: Yup.string(),
  date: stringRequired,
  postcode: Yup.string(),
  orderType: Yup.string(),
});

export const rowInitialValues = {
  jobReference: "",
  invoiceAmount: "1",
  subcontractors: [] as unknown as [{ label: ""; value: "" }],
  description: "",
  date: "",
  postcode: "",
  orderType: "",
};
