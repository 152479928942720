import * as Yup from "yup";
import {
  stringRequired,
  phone,
  fieldRequired,
  validEmail,
  email,
  nameIsRequired,
} from "validation/common";

/**
 * Import types.
 */
import {
  IWEEEFormState,
  IWEEEAddressDetailsStep,
  IWEEECollectionDetailsStep,
} from "./types";

/**
 * WEEE Kerbside address details step.
 */
export const weeeAddressDetailsSchema = Yup.object().shape({
  postcode: stringRequired,
  borough: stringRequired,
  boroughAvailability: stringRequired,
  selectedAddress: Yup.object().shape({
    line1: stringRequired,
    city: stringRequired,
    postcode: stringRequired,
  }),
});

export const weeeAddressDetailsInitialValues: IWEEEAddressDetailsStep = {
  postcode: "",
  borough: "",
  boroughAvailability: "",
  addressChoices: "",
  selectedAddress: {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
  },
};

/**
 * WEEE Kerbside collection details step validation.
 */
export const weeeCollectionDetailsSchema = Yup.object().shape({
  collectionDate: stringRequired,
  description: stringRequired,
  firstName: nameIsRequired,
  lastName: nameIsRequired,
  phoneNumber: phone.when("customerNoEmail", {
    is: true,
    then: phone.required(fieldRequired),
  }),
  email: Yup.string().email(validEmail).when("customerNoEmail", {
    is: false,
    then: email,
  }),
});

export const weeeCollectionDetailsInitialValues: IWEEECollectionDetailsStep = {
  collectionDate: "",
  description: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  customerNoEmail: false,
};

/**
 * WEEE Kerbside Initial Form state.
 * Used by MultiForm component.
 */
export const formStateInitialValues: IWEEEFormState = {
  ...weeeAddressDetailsInitialValues,
  ...weeeCollectionDetailsInitialValues,
};
