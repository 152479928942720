import React, { useState, useEffect } from "react";
import {
  useFormikContext,
  FormikProps,
  FormikFormProps,
  FormikValues,
  getIn,
} from "formik";
import cx from "classnames";

import { Eye } from "./images";
import { Tick } from "../../../images";

import "./styles/input.css";

/**
 * Import components
 */
import { FieldError } from "..";

/**
 * Type props for input field.
 */
type TFormInput = {
  id?: string;
  name: string;
  type?: string;
  classes?: string;
  label?: IFormInputLabel;
  placeholder?: string;
  error?: IErrorMessage;
  required?: boolean;
  floatingLabel?: boolean;
  togglePasswordVisibility?: boolean;
  validate?: boolean;
  autocomplete?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string | number;
  prefix?: any;
  suffix?: any;
};

/**
 * Type props for input label.
 */
interface IFormInputLabel {
  text: string;
  classes?: string;
}

/**
 * Type props for error messages.
 */
interface IErrorMessage {
  classes?: string;
}

/**
 * Ref type.
 */
type TWrapperRef = HTMLDivElement;

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const FormInput = React.forwardRef<TWrapperRef, TFormInput>(
  (
    {
      name,
      type = "text",
      placeholder,
      classes,
      value,
      label = {
        text: "",
        classes: "",
      },
      error = {
        classes: "",
      },
      required = false,
      floatingLabel = true,
      togglePasswordVisibility = false,
      validate = true,
      autocomplete = true,
      onChange,
      onFocus,
      onKeyUp,
      onKeyDown,
      onClick,
      onBlur,
      disabled = false,
      readOnly = false,
      defaultValue,
      prefix = null,
      suffix = null,
    },
    ref,
  ) => {
    /**
     * Get Formik context.
     */
    const {
      handleBlur,
      handleChange,
      setFieldValue,
    }: FormikProps<FormikFormProps> = useFormikContext();

    const { values, errors, touched }: FormikProps<FormikValues> =
      useFormikContext();

    useEffect(() => {
      if (defaultValue) {
        setFieldValue(name, defaultValue);
      }

      // eslint-disable-next-line
    }, [defaultValue]);

    /**
     * Custom prop handlers.
     */
    const customHandlers = {
      onChange,
      onFocus,
      onKeyUp,
      onKeyDown,
      onClick,
      onBlur,
    };

    /**
     * Password visibility state.
     */
    const [passwordVisible, setPasswordVisible] = useState(false);

    /**
     * An object is created with all of the set values.
     */
    const field = {
      name,
      id: `field-${name}`,
      type,
      placeholder,
      classes,
      error: false,
      label: {
        text: label.text,
        classes: cx("form-input-label", label.classes),
      },
    };

    /**
     * Input state variables.
     */
    const isValid = !getIn(errors, field.name) && getIn(touched, field.name);
    const hasError = getIn(errors, field.name) && getIn(touched, field.name);
    const hasValue = getIn(values, field.name) !== "";

    /**
     * Input field has a value.
     */
    field.classes = cx("form-input", field.classes, {
      "has-value": hasValue,
      prefix: !!prefix,
    });

    /**
     * Set label positioning
     */
    field.label.classes = cx(field.label.classes, {
      "form-input-normal-label": !floatingLabel,
    });

    /**
     * Apply validation elements and styling condition.
     */
    const useValidation = !(readOnly || disabled) && validate;

    /**
     * Output the input field. If label is set, we will also output that too.
     */
    return (
      <div
        className={cx("form-input-wrap", {
          valid: useValidation && isValid,
          required,
          error: hasError,
          disabled,
        })}
        ref={ref}
      >
        <input
          name={field.name}
          id={field.id}
          type={
            field.type === "password" && passwordVisible ? "text" : field.type
          }
          placeholder={!floatingLabel ? field.placeholder : ""}
          className={field.classes}
          value={value || getIn(values, field.name)}
          autoComplete={!autocomplete ? "off" : undefined}
          onChange={(e) => {
            handleChange(e);
            if (customHandlers.onChange) {
              customHandlers.onChange(e);
            }
          }}
          onFocus={(e) => {
            if (customHandlers.onFocus) {
              customHandlers.onFocus(e);
            }
          }}
          onKeyUp={(e) => {
            if (customHandlers.onKeyUp) {
              customHandlers.onKeyUp(e);
            }
          }}
          onKeyDown={(e) => {
            if (customHandlers.onKeyDown) {
              customHandlers.onKeyDown(e);
            }
          }}
          onClick={(e) => {
            if (customHandlers.onClick) {
              customHandlers.onClick(e);
            }
          }}
          onBlur={(e) => {
            handleBlur(e);
            if (customHandlers.onBlur) {
              customHandlers.onBlur(e);
            }
          }}
          data-testid={field.name}
          disabled={disabled}
          readOnly={readOnly}
        />

        {field.label.text ? (
          <label className={field.label.classes} htmlFor={field.id}>
            {field.label.text}
          </label>
        ) : null}

        {prefix && (
          <div className="absolute left-0 form-input-suffix font-semibold text-primary px-3">
            {prefix}
          </div>
        )}

        {suffix && (
          <div className="absolute right-0 form-input-suffix font-semibold text-primary px-3">
            {suffix}
          </div>
        )}

        {useValidation && (
          <div className="form-input-validate">
            {hasError ? (
              <FieldError
                message={getIn(errors, field.name)}
                classes={error.classes}
              />
            ) : (
              isValid && (
                <Tick
                  classes={{
                    svg: "form-input-tick",
                    circle: "form-input-tick-circle",
                    path: "form-input-tick-path",
                  }}
                />
              )
            )}
          </div>
        )}

        {field.type === "password" && togglePasswordVisibility && (
          <button
            className="form-input-password-visibility"
            type="button"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <Eye
              classes={{
                svg: "w-full h-full",
                strike: passwordVisible ? "hidden" : "text-purple-900",
                pupil: "text-purple-900",
                outline: "text-primary",
              }}
            />
          </button>
        )}
      </div>
    );
  },
);
