import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * Import hooks.
 */
import { useAuthContext } from "../../../hooks";

/**
 * Interface.
 */
interface IHavePermission {
  requiredRoles: string[];
  children: any;
  isMenu?: boolean;
}

export const HavePermission = ({
  requiredRoles,
  children,
  isMenu = false,
}: IHavePermission) => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("common");

  const { doesUserHaveRole } = useAuthContext();
  let canProceed = false;

  /**
   * Do we have permission to proceed?
   */
  if (requiredRoles.length === 0) {
    canProceed = true;
  } else if (requiredRoles.length > 0 && doesUserHaveRole(requiredRoles)) {
    canProceed = true;
  }

  /**
   * Show children.
   */
  if (canProceed) {
    return children;
  }

  /**
   * Show nothing for not allowed menu items.
   */
  if (isMenu) {
    return null;
  }

  /**
   * Return error.
   */
  return (
    <div className="four-o-four text-white flex flex-col justify-center items-center text-center p-6 overflow-y-scroll">
      <h2 className="four-o-four-heading font-bold">{t("fourOOne.heading")}</h2>
      <p className="text-xl sm:text-3xl mb-8">{t("fourOOne.text")}</p>
      <Link
        className="text-white btn-secondary hover:btn-secondary-hover btn-medium transition ease-in duration-100"
        to="/"
      >
        {t("fourOOne.buttons.back")}
      </Link>
    </div>
  );
};
