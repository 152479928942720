import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { styles, AllowedColorKeys } from "./Panel.styles";

export interface PanelProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  color?: AllowedColorKeys;
  pattern?: "small" | "large";
  shadow?: boolean;
  rounded?: boolean;
}

export const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  (
    {
      color = "light",
      pattern,
      shadow = true,
      rounded = true,
      styles: userStyles,
      ...rest
    },
    ref,
  ): React.ReactElement => {
    type PatternStyleKeys = `${NonNullable<PanelProps["pattern"]>}${Capitalize<
      NonNullable<PanelProps["color"]>
    >}Pattern`;

    const capitalizedColor = color.charAt(0).toUpperCase() + color.slice(1);
    const selectedPattern =
      `${pattern}${capitalizedColor}Pattern` as PatternStyleKeys;

    return (
      <div
        css={css(
          styles.defaults,
          styles[color],
          shadow && styles["shadow"],
          rounded && styles["rounded"],
          pattern && styles[selectedPattern],
          userStyles,
        )}
        data-testid="panel-component"
        {...rest}
        ref={ref}
      />
    );
  },
);
