import React from "react";
import { theme } from "@clearabee/ui-library";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */

interface IStatus {
  text?: string;
}

export const StatusCompleted: React.FC<IStatus> = ({ text = "Completed" }) => {
  return (
    <p className="bg-green text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusFailed: React.FC<IStatus> = ({ text = "Failed" }) => {
  return (
    <p className="bg-red text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusCancelled: React.FC<IStatus> = ({ text = "Cancelled" }) => {
  return (
    <p className="bg-red text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusOpen: React.FC<IStatus> = ({ text = "Open" }) => {
  return (
    <p className="bg-orange text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusStarted: React.FC<IStatus> = ({ text = "Started" }) => {
  return (
    <p className="bg-primary text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusScheduled: React.FC<IStatus> = ({ text = "Scheduled" }) => {
  return (
    <p className="bg-primary text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusPending: React.FC<IStatus> = ({ text = "On the way" }) => {
  return (
    <p className="bg-secondary text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};

export const StatusPendingApproval = ({
  text = "Pending",
}: IStatus): React.ReactElement => {
  return (
    <p
      style={{
        backgroundColor: theme.colors.warning.base,
      }}
      className="text-black font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block"
    >
      {text}
    </p>
  );
};

export const StatusExpired: React.FC<IStatus> = ({ text = "Expired" }) => {
  return (
    <p className="bg-danger text-white font-semibold py-2 px-3 rounded-full text-2xs leading-none inline-block">
      {text}
    </p>
  );
};
