import { AllowedColorKeys } from "@clearabee/ui-library/src/Core/Pill/Pill.styles";
import { Job } from "models";

export type StatusColors = {
  [Key in Job["status"]]?: AllowedColorKeys;
};

export const statusColors: StatusColors = {
  pending: "usp",
  processed: "accent",
  started: "brand",
  Open: "warning",
  "On the way": "accent",
  Failed: "negative",
  Started: "brand",
  Completed: "positive",
  Cancelled: "negative",
};
