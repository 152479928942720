/* eslint-disable max-len */
import React from "react";

export const WhiteLogo = (): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 371.96 96.12"
      fill="#FFFFFF"
    >
      <path
        d="M64,249c0-14,10.54-23.46,24.21-23.46a20.61,20.61,0,0,1,18.9,11.08l-8.3,4.08a12.26,12.26,0,0,0-10.6-6.59C80,234.15,74,240.47,74,249s6,14.89,14.28,14.89a12.25,12.25,0,0,0,10.6-6.6l8.3,4c-3.2,5.64-9,11.15-18.9,11.15C74.58,272.5,64,263,64,249Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M112.74,271.68V226.33h8.63v45.35Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M127.84,255.23c0-9.52,7.07-17.21,17-17.21s16.45,7.35,16.45,18v2H136.88c.61,4,3.88,7.35,9.45,7.35a13.72,13.72,0,0,0,8.7-3.2l3.88,5.71c-3.26,3-8.43,4.56-13.53,4.56C135.39,272.5,127.84,265.77,127.84,255.23Zm17-10.13a7.56,7.56,0,0,0-8,7H153c-.28-3.1-2.45-7-8.16-7Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M187.28,271.68v-3.47c-2.25,2.72-6.12,4.29-10.41,4.29-5.23,0-11.35-3.54-11.35-10.88,0-7.75,6.12-10.61,11.35-10.61,4.42,0,8.23,1.43,10.41,4v-4.14c0-3.34-2.86-5.51-7.21-5.51a13.83,13.83,0,0,0-9.45,3.87l-3.4-6A21.22,21.22,0,0,1,181.56,238c7.55,0,14.42,3,14.42,12.45v21.21Zm0-7.89v-4.08c-1.43-1.9-4.15-2.92-6.94-2.92-3.4,0-6.19,1.84-6.19,5s2.79,4.9,6.19,4.9c2.79,0,5.51-1,6.94-2.9Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M204.49,271.68V238.84h8.64v4.42A14.4,14.4,0,0,1,223.6,238v8.44a10.54,10.54,0,0,0-2.38-.21c-2.86,0-6.67,1.63-8.09,3.74v21.69Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M248.69,271.68v-3.47c-2.24,2.72-6.12,4.29-10.4,4.29-5.24,0-11.36-3.54-11.36-10.88,0-7.75,6.12-10.61,11.36-10.61,4.42,0,8.23,1.43,10.4,4v-4.14c0-3.34-2.85-5.51-7.21-5.51a13.83,13.83,0,0,0-9.45,3.87l-3.4-6A21.2,21.2,0,0,1,243,238c7.55,0,14.41,3,14.41,12.45v21.21Zm0-7.89v-4.08c-1.43-1.9-4.15-2.92-6.93-2.92-3.4,0-6.19,1.84-6.19,5s2.79,4.9,6.19,4.9c2.78,0,5.5-1,6.93-2.9Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M265.91,271.68V226.33h8.63v16.73a12.49,12.49,0,0,1,10.13-5c8.37,0,14.55,6.53,14.55,17.21,0,11-6.25,17.27-14.55,17.27a12.76,12.76,0,0,1-10.13-5v4.22ZM282,264.81c5,0,8.3-3.87,8.3-9.58s-3.34-9.52-8.3-9.52a9.7,9.7,0,0,0-7.48,3.8v11.63A9.74,9.74,0,0,0,282,264.81Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M303.65,255.23c0-9.52,7.08-17.21,17-17.21s16.46,7.35,16.46,18v2H312.7c.61,4,3.87,7.35,9.45,7.35a13.77,13.77,0,0,0,8.7-3.2l3.88,5.71c-3.27,3-8.43,4.56-13.53,4.56C311.2,272.5,303.65,265.77,303.65,255.23Zm17-10.13a7.56,7.56,0,0,0-8,7h16.18c-.29-3.1-2.47-7-8.18-7Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M341.27,255.23c0-9.52,7.07-17.21,17-17.21s16.46,7.35,16.46,18v2H350.31c.61,4,3.87,7.35,9.45,7.35a13.77,13.77,0,0,0,8.7-3.2l3.88,5.71c-3.27,3-8.43,4.56-13.53,4.56C348.81,272.5,341.27,265.77,341.27,255.23Zm17-10.13a7.56,7.56,0,0,0-8,7h16.18c-.3-3.1-2.45-7-8.19-7Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M392.27,237c-6.26.4-11.79-1.93-14.13-6.58s-.89-10.44,3.21-15.24A44.36,44.36,0,0,0,392.27,237Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M435.93,192.36c-.13,1.82-1,3.3-2.12,3.73-1.11,3.77-4.43,5.87-6.53,6.87a9.16,9.16,0,0,1,1.41,2.13,11.71,11.71,0,0,1-5.15,15.74l-.06,0a11.43,11.43,0,0,1-4.79,1.24,7.23,7.23,0,0,0-3.73.84,8.1,8.1,0,0,0-2.94,2.51c-.11.2-.27.42-.39.62a3.64,3.64,0,0,1-.43.6A23.48,23.48,0,0,1,401,206.24a4.69,4.69,0,0,1,.75,0,2.18,2.18,0,0,0,.72,0,7.52,7.52,0,0,0,3.78-.82,7.33,7.33,0,0,0,2.89-2.52,11.14,11.14,0,0,1,3.83-3.05,11.68,11.68,0,0,1,9-.65c1.22-1.22,3.24-3.76,3.34-6.79a4.5,4.5,0,0,1-2-3.53c-.22-2.2.7-4.12,2.1-4.26s2.7,1.52,3,3.74a4.45,4.45,0,0,1-1.28,4,12,12,0,0,1-3.31,7.68,11.47,11.47,0,0,1,2.18,1.48c1.7-.69,5-2.51,6.07-5.82A4.76,4.76,0,0,1,431,192c.18-2.2,1.49-3.92,2.86-3.82S436.14,190.16,435.93,192.36Z"
        transform="translate(-64 -176.42)"
      />
      <path
        d="M396.1,205.28a12,12,0,0,0-1,7.05,30,30,0,0,0,11.7,18.92,26.73,26.73,0,0,1-4.72,3,18.72,18.72,0,0,1-2.59,1.15,38.12,38.12,0,0,1-8.66-11.31,36.83,36.83,0,0,1-3.28-9.69,5.66,5.66,0,0,0-3.22-4L370,203.68a10.81,10.81,0,0,1-5.3-14.34l0,0a10.9,10.9,0,0,1,17-3.62,11.24,11.24,0,0,1,.93-3,10.83,10.83,0,1,1,19.64,9.15Z"
        transform="translate(-64 -176.42)"
      />
    </svg>
  );
};
