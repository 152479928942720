import { EditableItem } from "components/catalogues/components";

export const getItemById = (
  needle: string,
  haystack: EditableItem[],
): EditableItem | undefined => {
  for (const item of haystack) {
    if (item.id === needle) {
      return item;
    }
    const childrenItem = item.children
      ? getItemById(needle, item.children)
      : undefined;
    if (childrenItem) return childrenItem;
  }
  return undefined;
};
