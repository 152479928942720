import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export type AllowedColorKeys = keyof Pick<
  typeof theme.colors,
  "brand" | "accent" | "negative" | "warning" | "usp" | "positive" | "greyscale"
>;

export type AllowedSizeKeys = "medium" | "small" | "xsmall";

export const styles = StyleSheet({
  defaults: {
    color: theme.colors.light.base,
    padding: `${theme.spacing.xsmall} ${theme.spacing.small}`,
    fontWeight: "bold",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  brand: {
    backgroundColor: theme.colors.brand.base,
  },
  accent: {
    backgroundColor: theme.colors.accent.base,
    color: theme.colors.dark.base,
  },
  negative: {
    backgroundColor: theme.colors.negative.base,
  },
  warning: {
    backgroundColor: theme.colors.warning.base,
    color: theme.colors.dark.base,
  },
  usp: {
    backgroundColor: theme.colors.usp.base,
  },
  positive: {
    backgroundColor: theme.colors.positive.base,
    color: theme.colors.dark.base,
  },
  greyscale: {
    backgroundColor: theme.colors.greyscale.light,
  },

  medium: {
    ...theme.fontDefaults.xsmall,
    borderRadius: theme.spacing.xlarge,
  },
  small: {
    ...theme.fontDefaults.xsmall2,
    borderRadius: theme.spacing.small,
  },
  xsmall: {
    ...theme.fontDefaults.xsmall2,
    padding: `${theme.spacing.xsmall2} ${theme.spacing.xsmall}`,
    borderRadius: theme.spacing.small,
  },
  clickable: {
    cursor: "pointer",
  },
});
