/* eslint-disable */
import React, { createContext, useEffect, useRef, useState } from "react";

import { BasketItem } from "../models/basket";
import { CatalogueItem } from "../models/catalogueItem";
import { useBasket } from "../hooks/useBasket";
import {
  getItemFromCatalogueBySku,
  getItemsFromCatalogueByParentSku,
} from "../helpers/catalogue";
import { useCatalogueContext } from "../hooks";

const emptyItems: Array<BasketItem> = [];

interface ExtendedBasketItem extends BasketItem {
  isCalloutCharge?: boolean;
}

export interface IBasketContextState {
  items: ExtendedBasketItem[];
  catalogueId: string;
  addItem: (newItem: ExtendedBasketItem) => void;
  deleteItem: (itemSku: string) => void;
  clearItems: () => void;
  calculateTotal: (format: boolean) => string | number;
  hasItem: (
    itemSku: ExtendedBasketItem["sku"],
    searchForParentSku?: boolean,
  ) => boolean;
  getItem: (sku: string) => ExtendedBasketItem | null;
  updateItemPrice: (sku: string, price: number) => void;
  setBasketToken: (token: string) => void;
  setBasketOrderRef: (orderRef: string) => void;
  setCatalogueId: (catalogueId: string) => void;
  numberOfItems: number;
  hasItems: boolean;
  basketOrderType: number | null;
  basketOrderRef: string;
  basketToken: string;
}

const initialBasketContextState: IBasketContextState = {
  items: emptyItems,
  catalogueId: "",
  addItem: () => {},
  deleteItem: () => {},
  clearItems: () => {},
  getItem: () => null,
  updateItemPrice: () => {},
  calculateTotal: () => 0,
  hasItem: () => false,
  setBasketToken: () => {},
  setBasketOrderRef: () => {},
  setCatalogueId: () => {},
  numberOfItems: 0,
  hasItems: false,
  basketOrderType: null,
  basketToken: "",
  basketOrderRef: "",
};

export const basketContext = createContext(initialBasketContextState);

export const ProvideBasket = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [items, addItem, deleteItem, clearItems, getItem, updateItemPrice] =
    useBasket();

  const [basketOrderRef, setBasketOrderRef] = useState("");
  const [basketToken, setBasketToken] = useState("");
  const [catalogueId, setCatalogueId] = useState("");

  const [basketOrderType, setBasketOrderType] = useState<number | null>(null);
  const { catalogueItems } = useCatalogueContext();

  const calculateTotal = (format = false) => {
    let total = 0;

    if (items.length > 0) {
      total = items.reduce((runningTotal, current) => {
        return runningTotal + (current.price || 0) * current.quantity;
      }, 0);
    }

    return format ? total.toFixed(2) : total;
  };

  const numberOfItems = items.length;

  const hasItem = (
    itemSKU: BasketItem["sku"],
    searchForParentSku = false,
  ): boolean => {
    const inBasket = items.filter((item) => {
      if (searchForParentSku) {
        const emptyItems: Array<CatalogueItem> = [];
        const getCatalogueItem = getItemsFromCatalogueByParentSku(
          catalogueItems || emptyItems,
          itemSKU,
        );

        return getCatalogueItem.some(
          (obj) => obj.hasOwnProperty("sku") && obj.sku === item.sku,
        );
      }
      return item.sku === itemSKU;
    });
    return inBasket.length > 0;
  };

  useEffect(() => {
    if (items.length && catalogueItems) {
      const catItem = getItemFromCatalogueBySku(catalogueItems, items[0].sku);
      if (catItem) setBasketOrderType(catItem.orderTypeId || 0);
    }
  }, [items]);

  return (
    <basketContext.Provider
      value={{
        items,
        addItem,
        catalogueId,
        deleteItem,
        clearItems,
        getItem,
        updateItemPrice,
        calculateTotal,
        numberOfItems,
        hasItems: numberOfItems > 0,
        hasItem,
        basketOrderType,
        basketOrderRef,
        basketToken,
        setBasketToken,
        setCatalogueId,
        setBasketOrderRef,
      }}
    >
      {children}
    </basketContext.Provider>
  );
};
