import { theme } from "../../../theme";
import { StyleSheet, createResponsiveStyles } from "../../../utils";

export const styles = StyleSheet({
  socialLinksContainer: {
    display: "flex",
    listStyle: "none",
    marginTop: theme.spacing.large,
    padding: 0,
  },
  socialLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "3.5rem",
    height: "3.5rem",
    backgroundColor: "#ffbe0b",
    borderRadius: "9999px",
    color: theme.colors.dark.base,
    boxShadow: `0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%)`,
    transition: "transform 0.2s",

    ":hover": {
      transform: "scale(1.05)",
    },
  },
  secondaryLinksContainer: {
    display: "flex",
    listStyle: "none",
    justifyContent: "flex-end",
    padding: 0,
  },
  secondaryLink: {
    display: "flex",
    alignItems: "center",
  },
  secondaryLinksSeparator: {
    height: "1rem",
    border: "0px solid black",
    borderRightWidth: "1px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  textBold: {
    fontWeight: "bold",
  },
  conditionalLine: createResponsiveStyles({
    medium: {
      display: "none",
    },
  }),
  companyDetails: createResponsiveStyles({
    base: {
      fontSize: "1rem",
      marginTop: theme.spacing.xlarge2,
      lineHeight: "1.5rem",
      textAlign: "center",
    },

    xlarge: {
      textAlign: "left",
    },
  }),
  accreditation: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  accreditations: {
    display: "flex",
    listStyle: "none",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  sectionLinks: {
    listStyle: "none",
    padding: 0,
  },
  sectionLink: {
    paddingBottom: theme.spacing.xsmall,
  },
  mobileSectionContainer: createResponsiveStyles({
    base: {
      display: "flex",
      flexFlow: "row",
      alignItems: "center",
      width: "120%",
      marginTop: theme.spacing.medium,
      cursor: "pointer",
    },

    xsmall: {
      width: "310px",
    },
    small: {
      width: theme.screens.xsmall,
    },
  }),
  primaryLinksContainer: createResponsiveStyles({
    base: {
      display: "flex",
      justifyContent: "flex-end",
      flexFlow: "column",
    },

    large: {
      flexFlow: "row",
    },
  }),
  primaryLinks: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  openIcon: {
    marginTop: "5px",
    marginLeft: "auto",
    transition: "transform 0.2s",
  },
  mobileSectionLink: {
    listStyle: "none",
    padding: 0,
  },
  initiallyHidden: createResponsiveStyles({
    base: {
      display: "none",
    },

    large: {
      display: "initial",
    },
  }),
  initiallyShown: createResponsiveStyles({
    large: {
      display: "none",
    },
  }),
  footerLink: {
    ":hover": {
      textDecoration: "underline",
    },
  },
});
