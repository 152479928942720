import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { SuccessModal } from "../../../common/components";
import {
  useBasketContext,
  useMultiFormContext,
  useAuthContext,
  useCatalogueContext,
} from "hooks";
import { createBasketPaymentLink } from "api";
import { IFormState } from "../types";
import { Job } from "models";
import { IStripeWithBillingForm } from "../../../common/components/stripeWithBillingForm";
import { instance } from "@clearabee/ui-sdk";
import { StripeBillingForm } from "components/common/components/stripeBillingForm";
import { handleException } from "helpers/handleException";
import { Icon, Text } from "@clearabee/ui-library";

/**
 * Payment step component.
 */
export const PaymentConfirmation = (): React.ReactElement => {
  /**
   * State.
   */
  const [basketToken, setBasketToken] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  /**
   * Basket hook.
   */
  const { items, calculateTotal, clearItems } = useBasketContext();

  /**
   * Catalogue hook.
   */
  const { catalogueId } = useCatalogueContext();

  /**
   * Use auth hook to get user's company id.
   */
  const { getCurrentUserCurrentCompany } = useAuthContext();
  const { companyCode } = getCurrentUserCurrentCompany() ?? {};

  /**
   * Translations.
   */
  const [translate] = useTranslation("jobs");

  /**
   * Multi form hook.
   */
  const { formState, reset } = useMultiFormContext<IFormState>();
  const { job } = formState;
  const {
    ref,
    contactFirstName: firstName,
    contactLastName: lastName,
    contactEmail: email,
    contactPhoneNumber: phoneNumber,
    addressLine1,
    addressLine2,
    addressCity,
    addressCounty,
    addressPostcode,
    date,
  } = job as Job;
  const stripeBillingDetails: IStripeWithBillingForm["contact"] = {
    name: firstName || "",
  };
  // stripe dosen't like passing undefined props, so we leave out of object if not set
  if (phoneNumber) {
    stripeBillingDetails.phone = phoneNumber;
  }
  if (email) {
    stripeBillingDetails.email = email;
  }

  const {
    mutate: createPaymentLink,
    isLoading: isPaymentLinkLoading,
    isSuccess: paymentLinkSuccess,
  } = useMutation(
    () => {
      return createBasketPaymentLink(basketToken);
    },
    {
      onError: (error) => {
        handleException(
          error,
          {
            basketToken,
          },
          {
            type: "Additional Payments",
          },
          translate("errors.paymentLink"),
        );
      },
    },
  );
  // Set basket products
  const basketProducts = items.map((item) => {
    return {
      sku: item.sku,
      qty: item.quantity,
      price: item.price || undefined,
    };
  });
  const data = {
    catalogueId,
    items: basketProducts,
    deliveryAddress: {
      line1: addressLine1 || "",
      line2: addressLine2 || "",
      city: addressCity || "",
      county: addressCounty || "",
      postcode: addressPostcode || "",
    },
    date: new Date(date ? date : "").toISOString(),
    contact: {
      firstName: firstName || "",
      lastName: firstName || "",
      email,
      phoneNumber,
    },
    existingOrderRef: ref,
  };

  const { mutate: processBasket, isLoading } = useMutation(
    "additionalPaymentsCreateBasket",
    async () => {
      const basket = (await instance.catalogues.postBasket(data)).data;
      // Store basket info into local state.
      const { basketToken, orderRef } = basket;
      setBasketToken(basketToken);
      setOrderRef(orderRef);
      setClientSecret(basket.clientSecret ?? "");
    },
    {
      onError: (error) => {
        handleException(
          error,
          {
            basketToken,
            data,
          },
          {
            type: "Additional Payments",
          },
          "Failed to create the basket. Try again.",
        );
      },
    },
  );

  /**
   * Restart the job creation flow.
   */
  const restartCreateVTJob = () => {
    clearItems();
    reset(true);
  };

  /**
   * Create basket as we land on this page.
   */
  useEffect(() => {
    processBasket();
  }, []);

  return (
    <>
      <div className="mt-6">
        <div className="md:flex px-6 py-8 md:px-10 md:max-w-screen-md order-summary mx-auto shadow-filter bg-cover rounded-xl bg-pattern w-full pointer-events-auto is-visible">
          <div className="w-full md:pr-5 md:w-1/2">
            <div className="relative flex -mx-4 text-white">
              <div className="w-auto px-4 pr-16">
                <h4 className="text-xl font-bold mb-3">
                  {translate("create.orderSummary.titles.details")}
                </h4>
                <p className="text-2xs">
                  {translate("create.orderSummary.titles.name")}
                </p>
                <p className="leading-none mb-3">{`${firstName} ${lastName}`}</p>
                {phoneNumber && (
                  <>
                    <p className="text-2xs">
                      {translate("create.orderSummary.titles.phone")}
                    </p>
                    <p className="leading-none mb-3">{phoneNumber}</p>
                  </>
                )}
                {email && (
                  <>
                    <p className="text-2xs">
                      {translate("create.orderSummary.titles.email")}
                    </p>
                    <p className="leading-none mb-3">{email}</p>
                  </>
                )}
                <p className="text-2xs">
                  {translate("create.orderSummary.titles.collection")}
                </p>
                {addressLine1 && <p className="leading-none">{addressLine1}</p>}
                {addressLine2 && <p className="leading-none">{addressLine2}</p>}
                {addressCity && <p className="leading-none">{addressCity}</p>}
                {addressCounty && (
                  <p className="leading-none">{addressCounty}</p>
                )}
                {addressPostcode && (
                  <p className="leading-none">{addressPostcode}</p>
                )}
              </div>
            </div>
            <div className="my-8 border-b border-white opacity-25 w-full" />
            <div className="relative text-white">
              <>
                <h4 className="text-xl font-bold">
                  {translate("create.orderSummary.titles.order")}
                </h4>
                <div className="flex flex-row items-center text-2xs py-3">
                  <div className="order-summary-qty w-10 pr-2">
                    {translate("create.orderSummary.titles.quantity")}
                  </div>
                  <div className="w-full">
                    {translate("create.orderSummary.titles.product")}
                  </div>
                  <div className="order-summary-price text-right">
                    {translate("create.orderSummary.titles.price")}
                  </div>
                </div>
              </>
            </div>
            <div className="text-white">
              {items.map((item) => {
                const { quantity, price, sku } = item;
                return (
                  <div
                    key={`basket-${sku}`}
                    className="flex mb-2 text-xs pb-4 pt-2"
                  >
                    <div className="order-summary-qty w-10 pr-2">
                      {`x${quantity}`}
                    </div>
                    <div className="w-full truncate">{sku}</div>
                    <div className="order-summary-price text-right">
                      {`£${(price || 0).toFixed(2)}`}
                      <div className="mt-1 opacity-75 text-right">each</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="border-b my-8 md:my-0 border-white opacity-25 w-full visible md:invisible" />
          </div>
          <div className="w-full md:pl-5 md:w-1/2">
            <div className="mb-6">
              <h4 className="text-xl font-bold text-white mb-4">
                {translate("create.orderSummary.titles.billingDetails")}
              </h4>
              {isLoading && <Icon.Loading color="accent" className="mx-auto" />}
              {!isLoading && clientSecret && (
                <StripeBillingForm
                  clientSecret={clientSecret}
                  contact={stripeBillingDetails}
                  amount={calculateTotal(false) as number}
                  disableButton={
                    !basketToken || !orderRef || isPaymentLinkLoading
                  }
                  onPaymentSuccess={() => setSuccess(true)}
                  showCardholderNameField
                />
              )}
            </div>
            {email && (
              <div className="flex">
                <button
                  id="sendPaymentLinkButton"
                  className="mx-auto btn-secondary mb-6 btn-filled btn-medium transition ease-in duration-100"
                  disabled={!basketToken || isPaymentLinkLoading}
                  onClick={() => createPaymentLink()}
                >
                  {translate("create.sendPaymentLink")}
                </button>
              </div>
            )}
            <div className="border-b border-white opacity-25 w-full" />
            <div className="flex flex-row justify-between items-center lg:pt-5 lg:mt-5 text-white">
              <h4 className="text-lg md:text-xl font-bold">
                {translate("create.orderSummary.titles.total")}
              </h4>
              <div className="text-right">
                <h4 className="text-lg md:text-2xl font-bold leading-extra-tight">
                  £<span id="paymentTotal">{`${calculateTotal(true)}`}</span>
                </h4>
                <h6 className="text-2xs">
                  {translate("create.orderSummary.vat")}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-sm mx-auto px-2 pt-1 pb-4 mt-8 text-justify">
          <p>{translate("create.steps.confirmOrder.terms")}</p>
        </div>
      </div>
      <SuccessModal
        title={translate("modal.headings.createJobSuccess")}
        buttonOne={{
          label: translate("modal.buttons.labels.backToDashboard"),
          to: "/",
        }}
        visible={isSuccess}
      />
      <SuccessModal
        title={
          <>
            {translate("modal.headings.createPaymentLinkSuccess")}
            <br />
            {translate("modal.headings.ref")}
            <Text as="span" fontSize="small" data-testid="createJobSuccessRef">
              {orderRef}
            </Text>
          </>
        }
        buttonOne={{
          label: translate("modal.buttons.labels.backToDashboard"),
          to: "/",
        }}
        buttonTwo={{
          label: translate("modal.buttons.labels.bookAnother"),
          close: true,
        }}
        text={translate("modal.text.customerWillReceiveAnEmail")}
        hideCallback={restartCreateVTJob}
        visible={paymentLinkSuccess}
      />
    </>
  );
};
