import { StyleSheet, theme } from "@clearabee/ui-library";

export const styles = StyleSheet({
  hideButton: {
    backgroundColor: theme.colors.accent.base,
    color: theme.colors.greyscale.base,
    "&:hover": {
      backgroundColor: theme.colors.accent.dark,
    },
  },
});
