import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import components.
 */
import { Trail } from "../../core";

/**
 * Title component.
 */
const Title = () => {
  /**
   * i18n translation.
   */
  const [t] = useTranslation("auth");

  return (
    <h1 className="flex flex-row items-center text-primary leading-extra-tight">
      {t("title.reset")}
    </h1>
  );
};

/**
 * Components for trail animation.
 */
const components = [<Title />];

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const TitleReset = (): React.ReactElement => {
  return (
    <>
      <div className="flex flex-col mb-2 sm:mb-5">
        <Trail
          components={components}
          trailConfig={{
            config: {
              mass: 5,
              tension: 2000,
              friction: 200,
            },
            opacity: 1,
            xyz: [0, 0, 0],
            from: {
              opacity: 0,
              xyz: [0, 60, 0],
            },
          }}
        />
      </div>
    </>
  );
};
