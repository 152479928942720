import React from "react";
import cx from "classnames";

/**
 * Import images.
 */
import { JobStatus, Tick } from "../../../images";

/**
 * Import styles.
 */
import "./styles/progressBar.css";

/**
 * Interface.
 */
interface IProgressBar {
  items: string[];
  currentItem?: number;
  setStep: (step: number) => void;
}

export const ProgressBar: React.FC<IProgressBar> = ({
  items,
  currentItem = 1,
  setStep,
}) => {
  return (
    <div className="progress-bar bg-cover bg-no-repeat bg-top rounded-xl bg-primary px-8 py-4 shadow-filter">
      <div className="progress-bar-items flex flex-col sm:flex-row justify-around sm:items-center">
        {items.map((item: string, index: number) => {
          let isSelected = false;
          let icon = <JobStatus className="opacity-50" />;

          if (index < currentItem) {
            icon = (
              <button type="button" onClick={() => setStep(index)}>
                <div className="progress-bar-tick-border rounded-full bg-green-light flex items-center justify-center w-5 h-5 text-green-dark">
                  <Tick
                    stroke="currentColor"
                    classes={{
                      svg: "relative w-2 h-2",
                    }}
                  />
                </div>
              </button>
            );
          } else if (index === currentItem) {
            isSelected = true;
            icon = <JobStatus />;
          }

          const textClasses = cx("text-white text-sm", {
            "opacity-50": !isSelected,
          });

          return (
            <div
              key={`progress-${index}`}
              className={cx("flex justify-start items-center", {
                "mr-5 mb-2 sm:mb-0": index !== items.length - 1,
              })}
            >
              <div className="text-white mr-2">{icon}</div>
              <div className={textClasses}>{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
