import React from "react";
import cx from "classnames";

/**
 * Import template.
 */
import { BasicButton } from "./basic";

/**
 * Import hooks.
 */
import { useConfig } from "../../../hooks";

/**
 * Import types.
 */
import { TBasicButton } from "./types";

/**
 * Import styles.
 */
import "./styles/button.css";

type TFormButton = TBasicButton & {
  text: string;
  variant?: "primary" | "secondary" | "danger";
  filled?: boolean;
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const FormButton: React.FC<TFormButton> = ({
  name,
  text,
  type = "button",
  onClick,
  className,
  disabled,
  loading,
  variant = "primary",
  filled = false,
  loader = false,
}) => {
  /**
   * Create button classname.
   */
  const settings = useConfig("button");
  const classes = cx(className, settings(`classes.form.${variant}`), {
    "btn-filled": filled,
  });

  const button = {
    classes,
  };

  /**
   * Output the button.
   */
  return (
    <BasicButton
      name={name}
      className={button.classes}
      type={type}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      loader={loader}
    >
      {text}
    </BasicButton>
  );
};
