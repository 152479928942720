import * as Yup from "yup";
import { stringRequired } from "validation/common";

export const zoneInitialValues = {
  name: "",
  postcodes: [],
};

export const zoneValidationSchema = Yup.object().shape({
  name: stringRequired,
  postcodes: Yup.array().of(stringRequired),
  blackoutDays: Yup.array().of(Yup.number()),
  blackoutDates: Yup.array().of(Yup.string()),
});
