import { CatalogueItem } from "models";

export const getProductsFromCatalogue = (
  items: CatalogueItem[],
  ref: CatalogueItem[] = [],
): CatalogueItem[] => {
  for (const item of items) {
    if (item.type === "product") {
      ref.push(item);
    } else if (item.children?.length) {
      getProductsFromCatalogue(item.children, ref);
    }
  }
  return ref;
};
