import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Import images.
 */
import Calendar from "../../../images/calendar.svg";

/**
 * Import subcomponents.
 */
import { CellTemplate } from ".";
import { IDataRow } from "../types";

/**
 * Types.
 */
interface IBookingsCell {
  total: number;
  rows: IDataRow[];
  isLoading: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const BookingsCell = ({
  total,
  rows,
  isLoading,
}: IBookingsCell): React.ReactElement => {
  const [t] = useTranslation("leaderboard");

  return (
    <CellTemplate
      heading={t("headings.totalPhoneBookings")}
      subheading={String(total)}
      image={Calendar}
      data={rows}
      loading={isLoading}
    />
  );
};
