import React, { createContext, useReducer } from "react";

/**
 * Actions type.
 */
interface IActions {
  type: "SHOW_ACCOUNT_BUBBLE" | "HIDE_ACCOUNT_BUBBLE";
}

/**
 * Dispatch type.
 */
type TDispatch = (action: IActions) => void;

/**
 * State type.
 */
interface IState {
  accountBubbleVisible: boolean;
}

/**
 * Context type.
 */
export interface IAppContext {
  state: IState;
  dispatch: TDispatch;
}

/**
 * Initial state.
 */
const initialState: IState = {
  accountBubbleVisible: false,
};

/**
 * Creata app context.
 */
export const appContext = createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

/**
 * Reducer.
 */
const reducer = (state: IState, action: IActions) => {
  switch (action.type) {
    case "SHOW_ACCOUNT_BUBBLE":
      return { ...state, accountBubbleVisible: true };
    case "HIDE_ACCOUNT_BUBBLE":
      return { ...state, accountBubbleVisible: false };
    default:
      return { ...state };
  }
};

/**
 * Context provider component.
 */
export const AppProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};
