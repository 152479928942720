import { useState, useEffect, useCallback } from "react";

// eslint-disable-next-line
const JsonConfig = require("../config/settings.json");

/**
 * useConfig hook.
 *
 * @param {string} namespace The name of the component as decided in the .json file.
 *
 * @return {function} Getter function used to retrieve component settings.
 */
export const useConfig = (namespace: string) => {
  /**
   * Fixed settings function to retrieve config settings from namespace
   */
  const fixedSettings = useCallback(
    (component: string) => {
      const componentList = component.split(".");
      let value = JsonConfig[namespace];

      if (typeof value === "undefined") {
        return undefined;
      }

      componentList.forEach((comp) => {
        if (
          typeof value !== "undefined" &&
          typeof value[comp] !== "undefined"
        ) {
          value = value[comp];
        } else {
          value = undefined;
        }
      });

      return value;
    },
    [namespace],
  );

  /**
   * Fixed settings initial state
   */
  const [getConfig, setFixedSettings] = useState({ fixedSettings });

  /**
   * Update config getter function on namespace update
   */
  useEffect(() => {
    setFixedSettings({ fixedSettings });
  }, [namespace, fixedSettings]);

  return getConfig.fixedSettings;
};
