/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const SingleArrow: React.FC<TGenericImage> = ({
  className = "single-arrow",
  fill = "currentColor",
  width = 6,
  height = 9,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 6 9"
    >
      <path
        fill={fill}
        d="M0.561935933,8.89646294 C0.430367949,8.89646294 0.298271964,8.84635095 0.197471976,8.74531096 C-0.00383999954,8.54399898 -0.00383999954,8.21822302 0.197471976,8.01691104 L3.76636755,4.44825547 L0.197471976,0.879359895 C-0.00383999954,0.678047919 -0.00383999954,0.351983958 0.197471976,0.150959982 C0.398783952,-0.050351994 0.724847914,-0.050351994 0.92611189,0.150959982 L4.85894342,4.08379151 C5.0602554,4.28510349 5.0602554,4.61116745 4.85894342,4.81219143 L0.92611189,8.74526296 C0.825647902,8.84606295 0.693791917,8.89646294 0.561935933,8.89646294 Z"
        transform="translate(.6)"
      />
    </svg>
  );
};
