import React from "react";
import { useQuery, useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import { toasts } from "../../../helpers/toasts";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Heading,
  Input,
  Panel,
  Field,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { addRoleValidation, getInitialValues } from "./validation";

export const CreateRole = (): React.ReactElement => {
  const [translate] = useTranslation("roles");
  const dropdownOptions = [
    { value: "Clearabee", label: "Clearabee" },
    { value: "Company", label: "Company" },
    { value: "Supplier", label: "Supplier" },
  ];

  const { mutate, isLoading: mutateIsLoading } = useMutation(
    async (role: any) => await instance.users.postRole(role),

    {
      onError: () => {
        toasts.error({
          message: translate("errors.errorMessage"),
        });
      },
      onSuccess: () => {
        toasts.success({
          message: translate("success.successMessage"),
        });
      },
    },
  );

  const { data, isLoading: queryIsLoading } = useQuery(
    "permissions",
    async () => {
      return (await instance.users.getPermissions({ params: { limit: 1000 } }))
        .data;
    },
    {
      onError: () =>
        toasts.error({
          message: translate("errors.errorMessage"),
        }),
    },
  );

  const handleFormSubmit = async (
    values: typeof getInitialValues,
    { resetForm }: FormikHelpers<typeof getInitialValues>,
  ) => {
    mutate({
      ...values,
      permissions: values.permissions.map((item) => Number(item)),
    });
    resetForm();
  };

  if (!data?.items) return <></>;

  return (
    <>
      <div className="w-full py-5 sm:px-4 mx-auto">
        <Panel>
          <Heading fontSize="large" color="brand">
            {translate("headings.formHeading")}
          </Heading>
          <div className="border-t border-grey-200 mt-4 mb-2 flex flex-col" />

          <Form
            initialValues={getInitialValues}
            validationSchema={addRoleValidation}
            isInitialValid={false}
            onSubmit={handleFormSubmit}
          >
            {({ isValid, dirty }) => {
              return (
                <>
                  <div className="flex flex-col gap-x-6 items-center">
                    <div className="w-full md:flex-1 ">
                      <Field
                        name="name"
                        className="min-w-full"
                        label={translate("form.labels.name")}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={translate("form.labels.name")}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="w-full md:flex-1 mb-4">
                      <Field
                        name="type"
                        className="min-w-full"
                        label={translate("form.labels.type")}
                      >
                        {({ field }) => (
                          <Input.Select
                            {...field}
                            options={dropdownOptions}
                            placeholder={translate("form.labels.type")}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="flex w-full justify-start">
                      <Heading
                        styles={{
                          ...theme.fontDefaults.small,
                          fontWeight: 600,
                        }}
                        color="dark"
                      >
                        {translate("form.labels.permissions")}
                      </Heading>
                      {queryIsLoading && !data && (
                        <Icon.Loading
                          styles={{ margin: `${theme.spacing.xlarge} auto` }}
                        />
                      )}
                    </div>
                    {data && (
                      <div
                        role="group"
                        aria-labelledby="checkbox-group"
                        className="flex flex-row flex-wrap gap-2 md:gap-10"
                      >
                        {data.items
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((item) => (
                            <div className="flex w-full md:w-1/3 xl:w-1/5">
                              <Field name="permissions">
                                {({ field }) => {
                                  return (
                                    <Input.Checkbox
                                      {...field}
                                      label={item.name}
                                      value={item.id}
                                    />
                                  );
                                }}
                              </Field>
                            </div>
                          ))}
                      </div>
                    )}
                    <div>
                      <Button
                        color="accent"
                        type="submit"
                        className="flex justify-center gap-2 mt-6"
                        size="small"
                        disabled={mutateIsLoading || !isValid || !dirty}
                      >
                        {translate(
                          mutateIsLoading
                            ? "buttons.loading"
                            : "buttons.submitForm",
                        )}
                        {mutateIsLoading && (
                          <Icon.Loading size="small" color="brand.base" />
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              );
            }}
          </Form>
        </Panel>
      </div>
    </>
  );
};
