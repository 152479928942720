import { useState } from "react";

import { BasketItem } from "../models/basket";

type UseBasket = () => [
  Array<BasketItem>,
  (newItem: BasketItem) => void,
  (itemSku: BasketItem["sku"]) => void,
  () => void,
  (sku: string) => BasketItem | null,
  (sku: string, price: number) => void,
];

export const useBasket: UseBasket = () => {
  const [basket, setBasket] = useState<Array<BasketItem>>([]);

  const addToBasket = (newItem: BasketItem): void => {
    if (!newItem.quantity) return;

    const isAlreadyInBasket =
      basket.filter((basketItem) => basketItem.sku === newItem.sku).length > 0;
    const basketCopy = [...basket];

    if (newItem.quantity > 100) {
      newItem.quantity = 100;
    } else if (newItem.quantity < 0 && !isAlreadyInBasket) {
      throw Error("You cant add an item to the basket with negative quantity!");
    }

    if (isAlreadyInBasket) {
      // update quantity in basket
      const itemIndex = basketCopy.findIndex(
        (basketItem) => basketItem.sku === newItem.sku,
      );
      if (itemIndex === -1) {
        // failed to find item in array..
        throw Error(
          "Cant find the index of the item, even though it exists in our array",
        );
      }
      basketCopy[itemIndex].quantity = newItem.quantity;
      basketCopy[itemIndex].price = newItem.price;
      setBasket(basketCopy);
    } else {
      // add to basket
      setBasket([...basketCopy, newItem]);
    }
  };

  const deleteFromBasket = (itemSku: BasketItem["sku"]) => {
    const basketCopy = [...basket];

    const remainingItems = basketCopy.filter(
      (basketItem) => basketItem.sku !== itemSku,
    );

    setBasket(
      remainingItems.length === 1 && remainingItems[0].isCalloutCharge
        ? []
        : remainingItems,
    );
  };

  const clearBasket = () => {
    setBasket([]);
  };

  const getItem = (sku: string): BasketItem | null => {
    return basket.find((item) => item.sku === sku) || null;
  };

  const updateItemPrice = (sku: string, newPrice: number) => {
    const existing = getItem(sku);
    if (existing) {
      existing.price = newPrice;
      const filteredBasket = basket.filter(
        ({ sku: itemSku }) => sku !== itemSku,
      );
      setBasket([...filteredBasket, existing]);
    }
  };

  return [
    basket,
    addToBasket,
    deleteFromBasket,
    clearBasket,
    getItem,
    updateItemPrice,
  ];
};
