import {
  StyleSheet,
  theme,
  createResponsiveStyles,
} from "@clearabee/ui-library";

export const styles = StyleSheet({
  modalContentWrapper: {
    maxHeight: theme.screens.small,
    overflowY: "scroll",
  },
  divider: {
    borderTop: `1px solid ${theme.colors.greyscale.lightest}`,
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.medium,
    marginLeft: `-${theme.spacing.small}`,
    marginRight: `-${theme.spacing.small}`,
  },
  modal: createResponsiveStyles({
    medium: {
      padding: theme.spacing.small,
      paddingTop: theme.spacing.medium,
      paddingBottom: theme.spacing.medium,
      width: theme.screens.small,
    },
  }),
  smallText: {
    ...theme.fontDefaults.xsmall2,
    textAlign: "left",
    marginTop: theme.spacing.xsmall2,
    marginLeft: theme.spacing.xsmall2,
  },
  checkboxContainer: {
    ".checkbox-control::before": {
      position: "static",
    },
    display: "inline-block",
  },
  field: {
    marginTop: 0,
  },
  dataWrapper: {
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.small,
    textAlign: "left",
  },
  dataPin: {
    display: "inline-block",
    backgroundColor: theme.colors.greyscale.lightest,
    borderRadius: theme.spacing.xsmall2,
    fontWeight: 600,
    padding: `${theme.spacing.xsmall2} ${theme.spacing.small}`,
    margin: theme.spacing.xsmall2,
  },
  button: {
    height: theme.spacing.xlarge,
    width: theme.spacing.xlarge5,
  },
  successMessage: {
    backgroundColor: theme.colors.positive.base,
  },
  errorMessage: {
    backgroundColor: theme.colors.negative.base,
  },
});
