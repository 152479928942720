import React, { useEffect } from "react";

/**
 * Import components
 */
import { ModalBody } from "./components";
import { Transition } from "..";

/**
 * Import hooks.
 */
import { usePortal } from "../../../hooks";

/**
 * Import types
 */
import { IModal } from "./types";

import "./styles/modal.css";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Modal: React.FC<IModal> = ({
  visible,
  image,
  title,
  subtitle,
  text,
  textClass,
  buttons,
  children,
  width,
  close,
  portal = true,
  className,
}) => {
  /**
   * Portal hook.
   */
  const { isOpen, openPortal, closePortal, Portal } = usePortal();

  useEffect(() => {
    if (visible) {
      openPortal();
    } else {
      closePortal();
    }
  }, [visible, openPortal, closePortal]);

  /**
   * Get modal body component.
   */
  const getModalBody = () => {
    return (
      <ModalBody
        visible={visible}
        image={image}
        title={title}
        subtitle={subtitle}
        text={text}
        buttons={buttons}
        width={width}
        close={close}
        className={className}
        textClass={textClass}
      >
        {children}
      </ModalBody>
    );
  };

  /**
   * Output the modal
   */
  return (
    <Transition toggle={isOpen}>
      {portal ? <Portal>{getModalBody()}</Portal> : getModalBody()}
    </Transition>
  );
};
