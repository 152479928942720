import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Field,
  Text,
  Heading,
  Panel,
} from "@clearabee/ui-library";
import { userCompanies, readUsersTableResults } from "api";
import { SearchIcon } from "images";
import { useAuthContext } from "hooks";

interface QuoteFiltersFieldsProps {
  dirty: boolean;
  isFetching: boolean;
  clearFilters: () => void;
  onExpiredSwitchChange: (showExpired: boolean) => void;
}

export const QuoteFiltersFields = ({
  dirty,
  isFetching,
  clearFilters,
  onExpiredSwitchChange,
}: QuoteFiltersFieldsProps): React.ReactElement => {
  const [showExpired, setShowExpired] = useState<boolean>(false);
  const [translate] = useTranslation("quotes");
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeAdmin = doesUserHaveRole("Clearabee Administrator");
  const isCompanyAdmin = doesUserHaveRole("Company Administrator");

  /**
   * gets a list of companies, but only for clearabee admin
   */
  const companiesData = useQuery("companies", () => userCompanies(), {
    enabled: isClearabeeAdmin,
  });

  /**
   * gets a list of users, but only for clearabee and company admin
   */
  const usersData = useQuery(
    "users",
    () => readUsersTableResults("", 0, 1000),
    { enabled: isClearabeeAdmin || isCompanyAdmin },
  );

  const companiesOptions =
    companiesData.data?.map(({ name, companyCode }) => ({
      label: name,
      value: companyCode,
    })) ?? [];

  const usersOptions =
    usersData.data?.items.map(({ firstName, lastName, email }) => ({
      label: `${firstName} ${lastName}`,
      value: email,
    })) ?? [];

  /**
   * When the toggle for "show expired quotes" is set to false,
   * then it removes this option from the list of filterable status options.
   * @returns an array of options for the status dropdown
   */
  const statusOptions = [
    "all",
    "open",
    "quoted",
    "rejected",
    "accepted",
    "expired",
  ]
    .filter((status) => {
      if (!showExpired && status === "expired") {
        return false;
      }
      return true;
    })
    .map((status) => {
      return {
        label: translate(`form.statusOptions.${status}`),
        value: status,
      };
    });

  /**
   * When the showExpired toggle value changes, it calls
   * the onExpiredSwitchChange with a boolean state
   */
  useEffect(() => {
    onExpiredSwitchChange(showExpired);
  }, [showExpired]);

  const handleReset = () => {
    clearFilters();
    setShowExpired(false);
  };

  return (
    <Panel className="flex flex-col mb-8">
      <div className="flex flex-col md:flex-row mb-4 items-center border-b pb-4 border-grey-200 ">
        <Heading
          level={3}
          fontSize="large"
          color="brand"
          styles={{ marginRight: "auto" }}
        >
          {translate("headings.filterQuotes")}
        </Heading>
        <div className="flex items-center self-end space-x-3 mt-3 md:mt-0">
          <Text fontSize="small" as="label" htmlFor="showExpired">
            {translate("form.label.showExpiredQuotes")}
          </Text>
          <Input.Toggle
            name="showExpired"
            checked={showExpired}
            onChange={() => setShowExpired(!showExpired)}
          />

          {/* RESET BUTTON - clears filters and makes a fresh call without query params */}
          <Button
            type="reset"
            disabled={!dirty}
            onClick={handleReset}
            variant="outline"
            color="negative"
            size="small"
          >
            {translate("common:form.buttons.reset")}
          </Button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row -mx-2">
        {/* filter by company name - this option can only be seen by Admin */}
        {isClearabeeAdmin && (
          <div className="w-full px-2 mb-3 md:mb-0">
            <Field name="company" styles={{ margin: 0 }}>
              {({ field }) => (
                <Input.Select
                  options={companiesOptions}
                  isLoading={companiesData.isLoading}
                  disabled={!companiesOptions.length}
                  placeholder={translate("form.placeholder.selectCompany")}
                  isSearchable
                  isClearable
                  {...field}
                />
              )}
            </Field>
          </div>
        )}
        {/* filter by quote status dropdown */}
        <div className="w-full px-2 mb-3 md:mb-0">
          <Field name="status" styles={{ margin: 0 }}>
            {({ field }) => (
              <Input.Select
                options={statusOptions}
                placeholder={translate("form.placeholder.selectStatus")}
                isSearchable
                isClearable
                {...field}
              />
            )}
          </Field>
        </div>
        {/* filter by date dropdown  */}
        <div className="w-full px-2 mb-3">
          <Field name="date" styles={{ margin: 0 }}>
            {({ field }) => (
              <Input.Date
                placeholder={translate("form.placeholder.selectDate")}
                collapsable
                {...field}
              />
            )}
          </Field>
        </div>
        {/* filter by user name - This then searches by the user email which
                is on both the user profile and on the quotes, and this is more unique 
                than searching by name. Visible to Clearabee and Admin */}
        {(isClearabeeAdmin || isCompanyAdmin) && (
          <div className="w-full mb-3 px-2">
            <Field name="user" styles={{ margin: 0 }}>
              {({ field }) => (
                <Input.Select
                  options={usersOptions}
                  isLoading={usersData.isLoading}
                  disabled={!usersOptions.length}
                  placeholder={translate("form.placeholder.selectUser")}
                  isSearchable
                  isClearable
                  {...field}
                />
              )}
            </Field>
          </div>
        )}
        {/* SUBMIT BUTTON */}
        <div className="w-full md:flex-1 px-2">
          <Button
            id="submitQuoteFilters"
            type="submit"
            disabled={isFetching || !dirty}
            color="accent"
            size="small"
            className="w-full flex justify-center items-center"
          >
            <SearchIcon className="mr-2" />
            {translate("form.label.search")}
          </Button>
        </div>
      </div>
    </Panel>
  );
};
