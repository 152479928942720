import React from "react";
import cx from "classnames";

/**
 * Import subcomponents.
 */
import { LoadingSpinner } from "../../core";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */

type Loading = {
  isLoading?: boolean;
};

export const Loading: React.FC<Loading> = ({ isLoading }) => {
  const isAppLoading = isLoading || false;

  return (
    <div className="modal-bg top-0 left-0 z-100">
      <div
        className={cx({
          hidden: !isAppLoading,
          "fixed z-50": isAppLoading,
        })}
      >
        <div className="modal-window">
          <LoadingSpinner
            classes={{
              wrapper: "relative ml-auto mr-auto w-64 text-white",
              circle: "text-white",
            }}
            isLoading={isAppLoading}
          />
        </div>
      </div>
    </div>
  );
};
