import { Dispatch, useCallback, useEffect, useState } from "react";

/**
 * useLocalStorage hook.
 * - Get and set local storage values
 * - Code from: https://usehooks.com/useLocalStorage
 */
export const useLocalStorage = (
  key: string,
  initialValue = "",
): [string, Dispatch<string>] => {
  const [storedValue, setStoredValue] = useState<string>(() => {
    const item = window.localStorage.getItem(key);
    return item || initialValue;
  });

  /**
   * Set the value in local storage.
   *
   * @param {object} value Object to store in the local storage (object is stringifed automatically).
   *
   * @return {void} N/A
   */
  const setValue = (value: string) => {
    setStoredValue(value);
    window.localStorage.setItem(key, value);
  };

  /**
   * Removes and resets state and localstorage key.
   *
   * @return {void} N/A
   */
  // const clearStorage = () => {
  //   setStoredValue(initialValue)
  //   window.localStorage.removeItem(key)
  // }

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key === key && event.newValue !== storedValue) {
        setValue(event.newValue || initialValue);
      }
    },
    [storedValue],
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [handleStorage]);

  return [storedValue, setValue];
};
