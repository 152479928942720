import React from "react";
import { usePagination, Icon } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";

export interface PaginatedDataChildrenProps<DataType> {
  paginatedData: DataType[];
}

export interface PaginatedDataProps<DataType> {
  data: DataType[];
  children: (props: PaginatedDataChildrenProps<DataType>) => React.ReactElement;
  isLoading?: boolean;
  error?: unknown;
}

export function PaginatedData<DataType>({
  data,
  isLoading,
  error,
  children,
}: PaginatedDataProps<DataType>): React.ReactElement {
  const [translate] = useTranslation();
  const { PaginationComponent, paginatedData } = usePagination(data);

  return (
    <div className="relative">
      {!!paginatedData.length && children({ paginatedData })}

      {/* Fill space when no data is ready to show */}
      {!paginatedData.length && <div css={css({ height: 300 })}></div>}
      {!error && isLoading && (
        <div className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-white bg-opacity-75">
          <Icon.Loading color="brand" size="xlarge" data-testid="loading" />
        </div>
      )}

      <div className="mt-10">
        <PaginationComponent />
      </div>
    </div>
  );
}
