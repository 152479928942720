"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dashboard = void 0;
exports.dashboard = {
    headings: {
        dashboard: "Dashboard",
    },
    titles: {
        driverJobsScreen: "Driver Jobs",
    },
};
