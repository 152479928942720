import { statusMap } from "./updateWorksheetHelpers";
import type { IWorksheet } from "@clearabee/api-schemas";

export type InitialValues = {
  name: string;
  statusId?: keyof typeof statusMap | "";
  includeForSnsEvents: boolean;
  orderTypeIds: number[];
};

export const getInitialValues = (worksheet: IWorksheet): InitialValues => {
  return {
    name: worksheet.name ?? "",
    statusId: worksheet.statusId ?? "",
    includeForSnsEvents: worksheet.includeForSnsEvents ?? false,
    orderTypeIds: worksheet.orderTypes.map(({ id }) => id as number),
  };
};
