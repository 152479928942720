import { theme } from "../../theme";
import { StyleSheet } from "../../utils";

export const styles = StyleSheet({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    color: theme.colors.greyscale.base,
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.greyscale.lightest}`,
    " td:first-of-type": {
      paddingLeft: 0,
    },
    " td:last-child": {
      paddingRight: 0,
    },
  },
  tableHead: {
    paddingBottom: theme.spacing.xsmall2,
    textAlign: "left",
    " th:first-of-type": {
      paddingLeft: 0,
    },
    " th:last-child": {
      paddingRight: 0,
    },
  },
  tableHeading: {
    borderBottom: `2px solid ${theme.colors.greyscale.lightest}`,
    paddingBottom: theme.spacing.small,
    paddingRight: theme.spacing.xsmall,
    paddingLeft: theme.spacing.xsmall,
    minWidth: 100,
  },
  tableData: {
    paddingTop: theme.spacing.small,
    paddingRight: theme.spacing.xsmall,
    paddingBottom: theme.spacing.small,
    paddingLeft: theme.spacing.xsmall,
  },
  tableCellPrice: {
    fontWeight: "bold",
  },
});
