import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikContextType, useFormikContext, getIn } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Select from "react-select";
import { instance } from "@clearabee/ui-sdk";
import {
  Icon,
  Input,
  Table,
  Field,
  formatCurrency,
  Text,
  theme,
  Panel,
  Box,
  useModal,
  Button,
} from "@clearabee/ui-library";
import { InitialValues } from "../validation";
import { processBasketForInvoiceCustomer, readAddresses } from "api";
import { postcodeRegExp } from "validation/common";
import { useAuthContext, useCatalogueContext, useDebounce } from "hooks";
import roles from "constants/roles";
import { filterByBlackoutDate } from "helpers/catalogue";
import {
  useBasket,
  createOrUpdateBasket,
  Address,
  PostBasketResponse,
} from "@clearabee/ui-sdk";
import { DeleteModal } from "./deleteModal";
import { FailModal } from "./failModal";
import Warning from "../../../../images/warning.svg";

dayjs.extend(utc);

interface NewJobRowProps {
  index: number;
  paymentIndex: number;
  finishSubmit: (basket: PostBasketResponse | undefined) => void;
  companyCode: string;
  isLoading: boolean;
  catalogueId: string;
  emailRequired?: boolean;
  poNumberLabel?: string;
}
export const NewJobRow = ({
  index,
  paymentIndex,
  finishSubmit,
  companyCode,
  catalogueId,
  isLoading: isGlobalLoading,
  emailRequired,
  poNumberLabel,
}: NewJobRowProps): React.ReactElement | null => {
  const [translate] = useTranslation("jobs");
  const {
    items,
    isLoading: isCatalogueLoading,
    setCompanyCode,
  } = useCatalogueContext();
  const [poToCheck, setPoToCheck] = useState({ poNumber: "", postcode: "" });
  const [adjustQuantity, setAdjustQuantity] = useState(false);
  const [limitErrorShown, setLimitErrorShown] = useState(false);

  const [LimitModal, setIsLimitModalVisible] = useModal();
  const [DuplicateModal, setIsDuplicateModalVisible] = useModal();
  const { basket, create, update, isLoading: isBasketLoading } = useBasket();
  const {
    values,
    setValues,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  }: FormikContextType<InitialValues> = useFormikContext();
  const { doesUserHaveRole, getCurrentUserCurrentCompanySettings } =
    useAuthContext();

  const worksForClearabee = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const settings = getCurrentUserCurrentCompanySettings();

  const {
    postcode,
    address,
    date,
    product,
    poNumber,
    description,
    noContactDetails,
    name,
    email,
    contact,
    bigchangeProps,
  } = values.rows[index];

  useEffect(() => {
    if (noContactDetails === true) {
      setFieldValue(`rows[${index}].name`, "");
      setFieldTouched(`rows[${index}].name`, false);
      setFieldValue(`rows[${index}].contact`, "");
      setFieldTouched(`rows[${index}].contact`, false);
      setFieldValue(`rows[${index}].email`, "");
      setFieldTouched(`rows[${index}].email`, false);
    }
  }, [noContactDetails]);

  useEffect(() => {
    if (catalogueId === "PORTAL") {
      setCompanyCode("");
    }
  }, [catalogueId]);

  useEffect(() => {
    if (isSubmitting && index === paymentIndex) {
      mutate();
    }
  }, [isSubmitting, index, paymentIndex]);

  const getAddressFromString = () => {
    const json: Address & { lat: number | null; lng: number | null } =
      JSON.parse(address);

    return {
      line1: json.line_1,
      line2: json.line_2,
      county: json.county,
      city: json.town_or_city,
      postcode,
      lat: json.lat,
      lng: json.lng,
    };
  };

  const { isLoading: isAddressLoading, data: addressData } = useQuery(
    ["getAddressByPostcode", postcode],
    () => readAddresses(postcode),
    {
      enabled: !!postcode.match(postcodeRegExp) || postcode.length > 4,
    },
  );

  const addressOptions = addressData?.addresses.map((choice) => {
    const { line_1: line1, line_2: line2, town_or_city: city } = choice;
    return {
      label: line2 ? `${line1}, ${line2}, ${city}` : `${line1}, ${city}`,
      value: JSON.stringify({
        ...choice,
        lat: addressData.latitude || null,
        lng: addressData.longitude || null,
      }),
    };
  });

  const itemsFilteredByDate =
    !!items && !!date
      ? filterByBlackoutDate(
          items,
          dayjs.utc(date, "D/M/YYYY").toISOString(),
          postcode,
        )
      : [];

  const itemsOptions = itemsFilteredByDate?.map((item) => {
    const { title, sku } = item;
    return {
      label: title,
      value: sku,
      qty: 1,
      sku,
    };
  });

  const isCompanyCodeLV = !!companyCode && companyCode.includes("LV-");

  const isRiskAddressDifferentToCollectionAddress =
    bigchangeProps.cust_RiskAddressIsCollectionAddress;

  const deleteRow = (): void => {
    setFieldTouched(`rows[${index}]`, false);
    setValues({
      ...values,
      rows: values.rows.filter((_, i) => i !== index),
    });
  };

  const duplicateRow = (): void => {
    const selectedRow = values.rows.filter((_, i) => i == index);
    setValues({
      ...values,
      rows: [...values.rows, ...selectedRow],
    });
  };

  const handleBasketItem = async (): Promise<void> => {
    if (!postcode || !address || !date || !product) return;

    if (!bigchangeProps.cust_RiskAddressIsCollectionAddress) {
      bigchangeProps.cust_RiskPostcode = "";
    }

    const customFields = Object.entries(bigchangeProps)
      .map(([key, value]) => {
        if (
          isCompanyCodeLV &&
          (key === "cust_RiskAddressIsCollectionAddress" ||
            key === "cust_RiskPostcode")
        ) {
          return { [key]: String(value) };
        }

        if (key === "cust_OriginalRequestedDate") {
          return { [key]: String(value) };
        }
      })
      .filter(Boolean) as Array<Record<string, string>>;

    const body = {
      deliveryAddress: getAddressFromString(),
      date: dayjs.utc(date, "D/M/YYYY").toISOString(),
      items: product.flatMap(({ sku, qty }) => ({ sku, qty: Number(qty) })),
      description,
      companyCode,
      ...(noContactDetails
        ? { contact: null }
        : {
            contact: {
              phoneNumber: contact,
              firstName: name,
              lastName: name,
              email,
            },
          }),
      customFields,
      meta: {
        ...basket?.meta,
        page: "Book Multiple Jobs",
      },
    };

    await createOrUpdateBasket(body, {
      create,
      update,
      basket,
    }).catch((error) => {
      if (
        error.response.data.message.includes("limit for bookings") &&
        !limitErrorShown
      ) {
        setIsLimitModalVisible(true);
        setLimitErrorShown(true);
      }
    });
  };

  const {
    mutate,
    isLoading: isMutationLoading,
    isSuccess: isMutationSuccess,
    isError: isMutationError,
  } = useMutation(
    () => processBasketForInvoiceCustomer(basket?.basketToken ?? "", poNumber),
    {
      onSuccess: () => {
        finishSubmit(basket);
      },
    },
  );

  useEffect(() => {
    handleBasketItem();
  }, [
    postcode,
    address,
    date,
    product,
    noContactDetails,
    bigchangeProps.cust_RiskAddressIsCollectionAddress,
  ]);

  const isLoading =
    isAddressLoading ||
    isCatalogueLoading ||
    isBasketLoading ||
    isMutationLoading ||
    isGlobalLoading ||
    isMutationError ||
    isMutationSuccess;

  const debouncedPoValue = useDebounce(poToCheck.poNumber, 2000);

  const debouncedPostcodeValue = useDebounce(poToCheck.postcode, 2000);

  const { data: jobs, isLoading: jobsIsLoading } = useQuery(
    ["getJobs", debouncedPoValue, debouncedPostcodeValue],
    async () => {
      const jobs = (
        await instance.jobs.getJobs({
          params: {
            limit: 1,
            "companyCode:in": companyCode,
            "addressPostcode:eq": poToCheck.postcode.replaceAll(" ", ""),
            "purchaseOrder:in":
              poToCheck.poNumber || Number(poToCheck.poNumber),
          },
        })
      ).data.items;

      if (jobs.length) {
        setIsDuplicateModalVisible(true);
      }

      return jobs;
    },
    { refetchOnWindowFocus: false, retry: 1, enabled: !!debouncedPoValue },
  );

  return (
    <>
      <Table.Row>
        <Table.Cell>
          {isMutationLoading && <Icon.Loading color="brand" />}
          {isMutationSuccess && (
            <Icon.Checkbox size="medium" color="accent.dark" />
          )}
          {isMutationError && <Icon.Close size="medium" color="negative" />}
        </Table.Cell>
        <Table.Cell>
          <Box style={{ display: "flex", gap: theme.spacing.small }}>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].postcode`}
              label={translate("table.headings.postcode")}
              styles={{ flex: 1 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={translate("table.headings.postcode")}
                  disabled={
                    isGlobalLoading || isMutationSuccess || isMutationError
                  }
                  onChange={(event) => {
                    setPoToCheck({
                      postcode: event.target.value,
                      poNumber: getIn(
                        values,
                        `rows[${index}].poNumber`,
                        poNumber,
                      ),
                    });
                    field.onChange(event);
                  }}
                />
              )}
            </Field>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].address`}
              label={translate("table.headings.address")}
              styles={{ flex: 1 }}
            >
              {({ field }) => (
                <Input.Select
                  placeholder={translate("table.headings.address")}
                  disabled={isLoading || !addressOptions}
                  options={addressOptions ?? []}
                  isSearchable
                  defaultValue={address}
                  {...field}
                />
              )}
            </Field>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].date`}
              label={translate("table.headings.jobDate")}
              styles={{ flex: 1 }}
            >
              {({ field }) => (
                <Input.Date
                  {...field}
                  onChange={(event) => {
                    setFieldValue(`rows[${index}].product`, "");
                    field.onChange(event);
                  }}
                  disabled={isLoading || !addressOptions || !address}
                  initialValue={field.value ?? ""}
                  collapsable
                  disabledDays={[
                    {
                      before: new Date(),
                    },
                    { daysOfWeek: [0] },
                  ]}
                  placeholder={translate("table.headings.jobDate")}
                />
              )}
            </Field>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].product`}
              label={translate("table.headings.item")}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Select
                  {...field}
                  isSearchable
                  isClearable
                  isMulti
                  isDisabled={isLoading || !addressOptions || !address || !date}
                  options={date ? itemsOptions ?? [] : []}
                  onChange={(value) => field.onChange(value || [])}
                  placeholder={translate("table.headings.item")}
                  css={{ width: "100%" }}
                />
              )}
            </Field>
            {!emailRequired ? (
              <Field
                name={`rows[${index}].noContactDetails`}
                label={translate("table.headings.noContactDetails")}
                styles={{ flex: 1 }}
              >
                {({ field }) => (
                  <Input.Toggle
                    {...field}
                    disabled={isLoading}
                    styles={{
                      marginTop: "12px",
                    }}
                    defaultChecked={values.rows[index].noContactDetails}
                  />
                )}
              </Field>
            ) : (
              <Box />
            )}
          </Box>
          <Box style={{ display: "flex", gap: theme.spacing.small }}>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].description`}
              label={translate("table.headings.description")}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Textarea
                  {...field}
                  autoGrow
                  disabled={isLoading}
                  onBlur={(event) => {
                    field.onBlur(event);
                    handleBasketItem();
                  }}
                  placeholder={translate("table.headings.description")}
                />
              )}
            </Field>
            <Field
              name={`rows[${index}].name`}
              messageStyles={{ position: "absolute" }}
              required={!noContactDetails}
              label={translate("table.headings.name")}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isLoading || noContactDetails}
                  placeholder={translate("table.headings.name")}
                />
              )}
            </Field>

            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].contact`}
              label={translate("table.headings.contactPhone")}
              required={!noContactDetails}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isLoading || noContactDetails}
                  placeholder={translate("table.headings.contactPhone")}
                  onBlur={(event) => {
                    field.onBlur(event);
                    handleBasketItem();
                  }}
                />
              )}
            </Field>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].email`}
              label={translate("table.headings.email")}
              required={!noContactDetails}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isLoading || noContactDetails}
                  placeholder={translate("table.headings.email")}
                  onBlur={(event) => {
                    field.onBlur(event);
                    handleBasketItem();
                  }}
                />
              )}
            </Field>
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].poNumber`}
              styles={{ flex: 2 }}
              label={translate("table.headings.poNumber")}
            >
              {({ field }) => (
                <Box className="flex flex-row items-center justify-between gap-3">
                  <Box className="w-4/5">
                    <Input.Text
                      {...field}
                      placeholder={
                        poNumberLabel ?? translate("table.headings.poNumber")
                      }
                      disabled={isLoading}
                      onChange={(event) => {
                        setPoToCheck({
                          poNumber: event.target.value,
                          postcode: getIn(
                            values,
                            `rows[${index}].postcode`,
                            postcode,
                          ),
                        });
                        field.onChange(event);
                      }}
                      onBlur={(event) => {
                        field.onBlur(event);
                        handleBasketItem();
                      }}
                    />
                  </Box>
                  <Box className="w-1/5">
                    {jobsIsLoading && (
                      <Icon.Loading color="brand" size="medium" />
                    )}
                  </Box>
                </Box>
              )}
            </Field>
            <Box className="relative top-4">
              <Text fontSize="base" className="font-semibold pb-2">
                {translate("table.headings.price")}
              </Text>
              {isBasketLoading ? (
                <Icon.Loading color="accent" />
              ) : (
                <Text>
                  {basket &&
                  postcode &&
                  address &&
                  date &&
                  product &&
                  !isBasketLoading &&
                  (!settings?.hidePrices || worksForClearabee)
                    ? formatCurrency(basket.totalCost)
                    : ""}
                </Text>
              )}
            </Box>
          </Box>

          {isCompanyCodeLV && (
            <Box className="flex justify-start gap-4">
              <Field
                name={`rows[${index}].bigchangeProps.cust_RiskAddressIsCollectionAddress`}
                messageStyles={{ position: "absolute" }}
                label={translate(
                  "table.headings.isRiskAddressDifferentToCollectionAddress",
                )}
              >
                {({ field }) => (
                  <Input.Toggle {...field} disabled={isLoading} />
                )}
              </Field>

              {isRiskAddressDifferentToCollectionAddress && (
                <Field
                  name={`rows[${index}].bigchangeProps.cust_RiskPostcode`}
                  messageStyles={{ position: "absolute" }}
                  required={isRiskAddressDifferentToCollectionAddress}
                  label={translate("table.headings.riskPostcode")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={isLoading}
                      placeholder={translate("table.headings.riskPostcode")}
                      onBlur={(event) => {
                        field.onBlur(event);
                        handleBasketItem();
                      }}
                    />
                  )}
                </Field>
              )}
            </Box>
          )}

          {adjustQuantity && !!product?.length && (
            <Panel
              styles={{
                padding: `${theme.spacing.small} ${theme.spacing.large}`,
                maxWidth: theme.screens.small,
                margin: `${theme.spacing.small} auto`,
              }}
            >
              {product?.flatMap((item, ind) => (
                <Field
                  name={`rows[${index}]['product'][${ind}].qty`}
                  styles={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {({ field }) => (
                    <>
                      <Text>{item.label}</Text>
                      <Input.Quantity
                        {...field}
                        disabled={isLoading}
                        defaultValue={product[ind].qty}
                        min={1}
                        variant="small"
                      />
                    </>
                  )}
                </Field>
              ))}
            </Panel>
          )}
        </Table.Cell>
        <Table.Cell
          styles={{ display: "flex", paddingTop: theme.spacing.xlarge3 }}
        >
          <button
            type="button"
            disabled={isLoading}
            onClick={() => setAdjustQuantity(!adjustQuantity)}
            css={[
              {
                padding: theme.spacing.xsmall,
                backgroundColor: theme.colors.accent.base,
                borderRadius: "50%",
                color: theme.colors.light.base,
                marginRight: theme.spacing.xsmall,
              },
              isLoading && { cursor: "not-allowed" },
            ]}
          >
            <Icon.Controls color="dark" size="small" />
          </button>
          <FailModal
            isDisabled={isLoading}
            duplicateRow={duplicateRow}
            mutate={mutate}
            finishSubmit={finishSubmit}
            isMutationError={isMutationError}
          />
          <DeleteModal isDisabled={isLoading} deleteRow={deleteRow} />
        </Table.Cell>
      </Table.Row>
      <DuplicateModal
        styles={{
          "@media (min-width: 768px)": {
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.large,
          },
        }}
      >
        <div className="flex justify-center mb-6">
          <img src={Warning} alt="Warning" width={90} />
        </div>
        <Text fontSize="small">
          {translate("warningModal.withPostcode")}{" "}
          <span className="font-bold">{poToCheck.postcode}</span> <br />
          {translate("warningModal.withPo")}{" "}
          <span className="font-bold">{poToCheck.poNumber}</span> <br />
          {translate("warningModal.alreadyExists")}
        </Text>
        <Button
          className="mt-5"
          size="medium"
          color="warning"
          onClick={() => {
            setIsDuplicateModalVisible(false);
          }}
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      </DuplicateModal>
      <LimitModal
        styles={{
          "@media (min-width: 768px)": {
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.large,
          },
        }}
      >
        <div className="flex justify-center mb-6">
          <img src={Warning} alt="Warning" width={90} />
        </div>
        <Text fontSize="small">
          {translate("headings.bookingLimitReached")}
        </Text>
        <Button
          className="mt-5"
          size="medium"
          color="warning"
          onClick={() => {
            setIsLimitModalVisible(false);
          }}
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      </LimitModal>
    </>
  );
};
