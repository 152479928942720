import React from "react";
import { Icon, Loader, Text, theme } from "@clearabee/ui-library";
import cx from "classnames";

/**
 * Import context.
 */
import { useAuthContext, useBasketContext } from "../../../../hooks";
import { useTranslation } from "react-i18next";

/**
 * Interface
 */
interface BasketProps {
  excludedItems?: string[];
  isLoading?: boolean;
  editPrice?: boolean;
}

export const Basket = ({
  excludedItems = [],
  isLoading,
  editPrice = false,
}: BasketProps): React.ReactElement => {
  const {
    deleteItem,
    hasItems,
    items,
    updateItemPrice,
    calculateTotal: contextCaculateTotal,
  } = useBasketContext();
  const { getCurrentUserCurrentCompanySettings } = useAuthContext();
  const [translate] = useTranslation("jobs");

  const companySettings = getCurrentUserCurrentCompanySettings();
  const hiddenPrices = companySettings?.hidePrices;

  /**
   * If the array of items includes items that contain the sku
   * of the excludedItems, these will be filtered from
   * the output
   */
  const basketItemsWithItemRemoved = hasItems
    ? items.filter((item) => !excludedItems?.includes(item.sku))
    : [];

  return (
    <div
      data-testid="basketTotalsCell"
      className="bg-pattern mt-8 text-white shadow-xl px-10 py-8 rounded-lg relative"
    >
      <h2 className="text-2xl font-semibold">{translate("headings.basket")}</h2>
      {editPrice && (
        <>
          <Icon.Info
            size="small"
            styles={{
              marginRight: theme.spacing.xsmall,
              display: "inline-block",
            }}
          />
          <Text
            as="span"
            styles={{ display: "inline-block" }}
            fontSize="xsmall"
          >
            {translate("create.priceChange")}
          </Text>
        </>
      )}
      <div className="mt-4 mb-2">
        {isLoading && <Loader text={translate("common:loading2")} />}
        {!basketItemsWithItemRemoved.length && (
          <Text fontSize="small">{translate("create.noItems")}</Text>
        )}
        {!isLoading &&
          basketItemsWithItemRemoved.map((item, index) => {
            const { title, sku, price, quantity } = item;

            return (
              <div
                className={`flex flex-col ${
                  hiddenPrices &&
                  index !== basketItemsWithItemRemoved.length - 1 &&
                  "border-b border-white"
                } `}
                key={`basket-${sku}`}
              >
                <div className="flex mb-2 space-x-3 text-xs border-opacity-20 pb-4 pt-2">
                  <div className="w-6">{`x${quantity}`}</div>
                  <div className={!hiddenPrices ? "w-20 truncate" : ""}>
                    {title}
                    <div className="mt-1 font-semibold">
                      {(!item.isCalloutCharge || items.length === 1) && (
                        <button
                          className="opacity-50 hover:opacity-100 transition-all"
                          onClick={() => deleteItem(sku)}
                        >
                          {translate("buttons.labels.remove")}
                        </button>
                      )}
                    </div>
                  </div>
                  {!hiddenPrices && (
                    <div>
                      <div className="flex items-start">
                        <span
                          className={cx(
                            editPrice && "bg-white text-black",
                            "pl-1",
                          )}
                        >
                          £
                        </span>
                        <input
                          defaultValue={(price || 0).toFixed(2)}
                          onBlur={(e) => {
                            updateItemPrice(sku, Number(e.target.value));
                          }}
                          className={cx(
                            "w-12 text-right bg-transparent",
                            editPrice && "text-black bg-white",
                          )}
                          disabled={!editPrice}
                        />
                      </div>
                      <div className="block text-right">
                        {translate("each")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {!hiddenPrices && (
        <div className="mt-6 mb-2 space-x-2 flex items-center justify-between">
          <div>
            <h3 className="text-3xl">{translate("headings.total")}</h3>
          </div>
          <div>{`£${contextCaculateTotal(true)}`}</div>
        </div>
      )}
    </div>
  );
};
