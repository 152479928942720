import * as Yup from "yup";
import { fieldRequired } from "validation/common";

export const initialValues: {
  permissions: string[];
} = {
  permissions: [],
};

export const validationSchema = Yup.object().shape({
  permissions: Yup.array().min(1, fieldRequired),
});
