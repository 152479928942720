import axios from "axios";

export type Address = {
  line_1: string;
  line_2: string;
  town_or_city: string;
  county: string;
};

export type Addresses = {
  postcode: string;
  latitude: number;
  longitude: number;
  addresses: Array<Address>;
};

export const readAddresses = async (postcode: string): Promise<Addresses> => {
  try {
    const { data } = await axios.get(
      `/find/${postcode}?api-key=0y4OCyHIUUiDTDIVbUr_-Q25301&expand=true&sort=true`,
      {
        baseURL: "https://api.getaddress.io",
      },
    );

    return data;
  } catch (error) {
    throw error;
  }
};
