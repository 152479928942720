import React from "react";

export const UserCompany: React.FC = ({ row }: any): JSX.Element | null => {
  const { original } = row;
  const { companies } = original;

  if (companies && companies.length > 0) {
    return companies[0].name || null;
  }

  return null;
};
