"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFromPrice = void 0;
var getItems_1 = require("../utils/getItems");
/**
 * Get from price on a category/service item from a flat list of items.
 */
var getFromPrice = function (baseItems, filterCallback) {
    var _a;
    var baseCallout = baseItems.find(function (_a) {
        var sku = _a.sku;
        return ["CALLOUTCHARGE", "CALLOUT"].includes(sku);
    });
    var items = (_a = filterCallback === null || filterCallback === void 0 ? void 0 : filterCallback(baseItems)) !== null && _a !== void 0 ? _a : baseItems;
    if (!items.length)
        return 0;
    return items
        .flatMap(function (_a) {
        var _b, _c, _d;
        var price = _a.price, sku = _a.sku, noCalloutCharge = _a.noCalloutCharge, parentSku = _a.parentSku;
        if (sku.includes("CALLOUT"))
            return [];
        if (noCalloutCharge)
            return price !== null && price !== void 0 ? price : 0;
        var itemCalloutCharge = (_c = (_b = (0, getItems_1.getItems)(["".concat(parentSku, "CALLOUT")], items)) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : baseCallout;
        return (price !== null && price !== void 0 ? price : 0) + ((_d = itemCalloutCharge === null || itemCalloutCharge === void 0 ? void 0 : itemCalloutCharge.price) !== null && _d !== void 0 ? _d : 0);
    })
        .sort(function (a, b) { return a - b; })[0];
};
exports.getFromPrice = getFromPrice;
