/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const DashboardIcon: React.FC<TGenericImage> = ({
  className = "dashboard-icon",
  fill = "currentColor",
  width = 21,
  height = 21,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21 21"
    >
      <path
        fill={fill}
        d="M7.893 6.827h-6.4C.67 6.827 0 6.157 0 5.333v-3.84C0 .67.67 0 1.493 0h6.4c.824 0 1.494.67 1.494 1.493v3.84c0 .824-.67 1.494-1.494 1.494zm-6.4-5.547a.213.213 0 00-.213.213v3.84c0 .118.096.214.213.214h6.4a.214.214 0 00.214-.214v-3.84a.213.213 0 00-.214-.213h-6.4zm6.4 19.2h-6.4C.67 20.48 0 19.81 0 18.987v-8.96c0-.824.67-1.494 1.493-1.494h6.4c.824 0 1.494.67 1.494 1.494v8.96c0 .823-.67 1.493-1.494 1.493zm-6.4-10.667a.213.213 0 00-.213.214v8.96c0 .117.096.213.213.213h6.4a.213.213 0 00.214-.213v-8.96a.214.214 0 00-.214-.214h-6.4zM18.987 20.48h-6.4c-.824 0-1.494-.67-1.494-1.493v-3.84c0-.824.67-1.494 1.494-1.494h6.4c.823 0 1.493.67 1.493 1.494v3.84c0 .823-.67 1.493-1.493 1.493zm-6.4-5.547a.214.214 0 00-.214.214v3.84c0 .117.096.213.214.213h6.4a.213.213 0 00.213-.213v-3.84a.213.213 0 00-.213-.214h-6.4zm6.4-2.986h-6.4c-.824 0-1.494-.67-1.494-1.494v-8.96c0-.823.67-1.493 1.494-1.493h6.4c.823 0 1.493.67 1.493 1.493v8.96c0 .824-.67 1.494-1.493 1.494zm-6.4-10.667a.213.213 0 00-.214.213v8.96c0 .118.096.214.214.214h6.4a.213.213 0 00.213-.214v-8.96a.213.213 0 00-.213-.213h-6.4z"
      />
    </svg>
  );
};
