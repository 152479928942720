import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { instance } from "@clearabee/ui-sdk";
import * as Yup from "yup";
import {
  Form,
  Field,
  Heading,
  Input,
  Panel,
  Button,
} from "@clearabee/ui-library";
import { supplierInitial, supplierValidation } from "../../validation";
import { AddressFields } from "./components/addressFields/addressFields";
import { SchemaFields } from "./components/schemaFields/schemaFields";
import { Address } from "api/libs/getAddressIo";
import { IColumn, ISupplierType } from "components/suppliers/types";

interface SupplierTypeOption {
  label: string;
  value: string;
}

/**
 * LORENZO - some any's that need to be sorted out. Apologies for these!
 */
interface createUpdateSupplierTypeFormProps {
  mutateFunction: any;
  disableSubmitButton: boolean;
  supplierData?: any;
  title: string;
}

export const CreateUpdateSupplierForm = ({
  mutateFunction,
  disableSubmitButton,
  supplierData,
  title,
}: createUpdateSupplierTypeFormProps): React.ReactElement => {
  const [translate] = useTranslation("suppliers");
  const [supplierTypeOptions, setSupplierTypeOptions] = useState<
    SupplierTypeOption[]
  >([]);
  const [dynamicFieldNames, setDynamicFieldNames] = useState<string[] | []>([]);
  const [defaultManualAddress, setDefaultManualAddress] = useState(
    !!(supplierData && supplierData.address),
  );

  /**
   * This is the array of form fields, generated when a user selects
   * a supplier type
   */
  const [dynamicFormFields, setDynamicFormFields] = useState<
    [string, IColumn][]
  >([]);

  /**
   * This becomes true when the form schema has been updated
   */
  const [schemaUpdated, setSchemaUpdated] = useState(false);

  /**
   * the imported supplierValidation Schema at the top of the file - this is the base validation schema
   * before a user has selected a supplier type. After the user has selected
   * a supplier type, then extra key/value pairs are added to this,
   * constructed using the columns of the supplier type. The updated vaidation schema is then
   * stored below in this state
   */
  const [updatedValidation, setUpdatedValidation] = useState<any>(
    Yup.object().shape(supplierValidation),
  );

  /**
   * Becomes true if initial values are updated to reflect new dynamic schema fields
   */
  const [initialValuesUpdated, setInitialValuesUpdated] = useState(false);

  /**
   * The dynamically generated schema fields
   */
  const [updatedInitialValues, setUpdatedInitialValues] =
    useState(supplierInitial);

  useEffect(() => {
    if (supplierData && supplierData.address) {
      setDefaultManualAddress(true);
    }
  }, [supplierData]);

  /**
   * Retrieves a list of supplier types
   */
  const {
    data: supplierTypes,
    isLoading: isLoadingSupplierTypes,
    refetch,
  } = useQuery(
    ["readSupplierTypesForDropdown"],
    () => ({} as any),
    // instance.users.getSupplierTypes(),
  );

  /**
   * Refetch on page load, rather than using a cached list which may have now been updated
   */
  useEffect(() => {
    refetch();
  }, []);

  /**
   * This use effect creates dynamic initial values and validation schema when
   * the user selects a supplier Type from the dropdown
   * When the user selects a type, initial values are generated to populate
   * the form, allowing for proper validation.
   */
  // LORENZO - some any's I couldnt sort out. These will be dynamic objects
  useEffect(() => {
    const baseInitialValues: any = supplierInitial;
    const baseValidationSchema: any = supplierValidation;
    const arrayOfNewFieldNames: string[] = [];

    if (dynamicFormFields.length) {
      const number = null;
      const string = "";
      const boolean = false;

      const stringField = Yup.string();
      const booleanField = Yup.boolean();
      const numberField = Yup.number();

      dynamicFormFields.forEach(([name, column]: [string, IColumn]) => {
        arrayOfNewFieldNames.push(name);
        if (column.type === "textInput" || column.type === "textArea") {
          return (
            (baseInitialValues[name] = string),
            (baseValidationSchema[name] = column.required
              ? stringField.required().label(column.title)
              : stringField.label(column.title))
          );
        }

        // LORENZO - when a number input is created, it validates correctly and won't let the user
        // input letters, but when the form submits, it submits that number within a string, rather than a number.
        // This is something that needs to be sorted.
        if (column.type === "numberInput") {
          return (
            (baseInitialValues[name] = number),
            (baseValidationSchema[name] = column.required
              ? numberField.required().label(column.title)
              : numberField.label(column.title))
          );
        }
        if (column.type === "toggle") {
          return (
            (baseInitialValues[name] = boolean),
            (baseValidationSchema[name] = column.required
              ? booleanField.required().label(column.title)
              : booleanField.label(column.title))
          );
        }
      });

      // LORENZO - another any type to sort out. Another one on line 174 for you :/
      const withColumnsValidation: any =
        Yup.object().shape(baseValidationSchema);

      setDynamicFieldNames(arrayOfNewFieldNames);
      setUpdatedInitialValues(baseInitialValues);
      setUpdatedValidation(withColumnsValidation);
      setSchemaUpdated(true);
      setInitialValuesUpdated(true);
    }
  }, [dynamicFormFields]);

  /**
   * Generates a list of supplier type options for
   * the form supplier type dropdown
   */
  useEffect(() => {
    if (supplierTypes) {
      const options = supplierTypes.data.items.map((supplierType: any) => {
        /**
         * Sorts the object keys into alphabetical order, so that when
         * it is stringified, it matches
         * the values of the supllierType returned on supplierData
         */
        const typeObjectWithOrderedKeys = Object.keys(supplierType)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: supplierType[key],
            }),
            {},
          );

        return {
          label: supplierType.type,
          value: JSON.stringify(typeObjectWithOrderedKeys),
        };
      });
      setSupplierTypeOptions(options);
    }
  }, [supplierTypes]);

  /**
   * When the user visits this form after choosing to update a
   * supplier, then the form will be provided with initial values
   * generated from the supplier data, and it will populate
   * the form with values. This function takes the supplier data and arranges it to
   * fit the form.
   * LORENZO - another couple of anys I didn't sort
   */
  const updateSupplierInitialValues = () => {
    if (supplierData) {
      const allTheDynamicDataForThisSupplier: any = {};

      const listOfTypesThisSupplierHas = Object.keys(supplierData.columnValues);

      listOfTypesThisSupplierHas.forEach((type: string) => {
        const objectOfFields = supplierData.columnValues[type];
        const arrayOfDynamicFields = Object.entries(objectOfFields);
        arrayOfDynamicFields.forEach(([key, value]: [string, any]) => {
          allTheDynamicDataForThisSupplier[key] = value;
        });
      });

      // LORENZO - i have disabled eslint here, because I wanted to remove two keys from an object,
      // but then didn't need to use one of them. It throws an error for an unused variable
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { columnValues, address, ...supplierDataMinusColumnValues } =
        supplierData;

      return {
        ...address,
        ...supplierDataMinusColumnValues,
        ...allTheDynamicDataForThisSupplier,
        ...(supplierData.types && {
          types: JSON.stringify(supplierData.types[0]) || "{}",
        }),
      };
    }
  };

  const handleAddressSelection = (
    address: Address,
    setFieldValue: FormikHelpers<unknown>["setFieldValue"],
  ) => {
    setFieldValue("addressLine1", address.line_1);
    setFieldValue("addressLine2", address.line_2);
    setFieldValue("city", address.town_or_city);
    setFieldValue("county", address.county);
  };

  return (
    <Panel>
      <Heading level={3} fontSize="base" color="brand">
        {title}
      </Heading>
      <Form
        initialValues={updateSupplierInitialValues() || updatedInitialValues}
        onSubmit={(values) => {
          const type = JSON.parse(values.types) as ISupplierType;

          // LORENZO - sorry, more anys
          const columnValues: any = {};
          dynamicFieldNames.forEach((fieldName) => {
            columnValues[fieldName] = values[fieldName];
          });

          const typeValues: any = {};
          typeValues[type.type] = columnValues;

          mutateFunction({
            address: {
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              county: values.county,
              postcode: values.postcode,
            },
            name: values.name,
            supplierRef: values.supplierRef,
            phoneNumber: values.phoneNumber,
            types: [Number(type.id)],
            columnValues: typeValues,
          });
        }}
        validationSchema={updatedValidation}
      >
        {({ values }) => {
          {
            useEffect(() => {
              setDynamicFieldNames([]);
              setSchemaUpdated(false);
              setInitialValuesUpdated(false);
              if (values.types) {
                const objectOfColumns = (
                  JSON.parse(values.types) as ISupplierType
                ).columns;
                if (objectOfColumns) {
                  const columnsSchema = Object.entries(objectOfColumns);
                  setDynamicFormFields(columnsSchema);
                }
              }
            }, [values.types]);
          }

          return (
            <>
              <Field name="active" type="checkbox">
                {({ field }) => <Input.Toggle {...field} />}
              </Field>

              <Field name="supplierRef">
                {({ field }) => <Input.Text {...field} />}
              </Field>

              <Field name="name">
                {({ field }) => <Input.Text {...field} />}
              </Field>

              <Field name="phoneNumber">
                {({ field }) => <Input.Text {...field} />}
              </Field>

              <div className="border-t border-b border-gray-300 my-8 pb-3 pt-5">
                <Heading color="brand" level={4} fontSize="small">
                  {translate("suppliers.headings.address")}
                </Heading>
                {defaultManualAddress ? (
                  <AddressFields
                    defaultManualAddress={defaultManualAddress}
                    enabledSwitch={!defaultManualAddress}
                    onAddressSelection={handleAddressSelection}
                  />
                ) : (
                  <AddressFields
                    defaultManualAddress={false}
                    onAddressSelection={handleAddressSelection}
                  />
                )}
              </div>

              <div className="mt-8">
                <Field name="types">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      defaultValue={values.types}
                      placeholder={
                        isLoadingSupplierTypes
                          ? translate("suppliers.form.placeholders.loading")
                          : translate(
                              "suppliers.form.placeholders.selectSupplierType",
                            )
                      }
                      options={supplierTypeOptions}
                      disabled={
                        isLoadingSupplierTypes ||
                        (supplierData && supplierData.types)
                      }
                    />
                  )}
                </Field>
              </div>

              {/* Only show schema fields when a supplier type has been selected and when
              the form schema has been updated to accommodate these */}
              {dynamicFormFields && schemaUpdated && initialValuesUpdated && (
                <div className="mt-8">
                  <SchemaFields dynamicFormFields={dynamicFormFields || []} />
                </div>
              )}

              <Button
                type="submit"
                size="medium"
                className="mx-auto flex justify-center mt-10"
                disabled={disableSubmitButton}
              >
                {translate("supplierTypes.buttons.submit")}
              </Button>
            </>
          );
        }}
      </Form>
    </Panel>
  );
};
