import * as Yup from "yup";
import { phone, stringRequired } from "../../validation/common";
import { translate } from "@clearabee/i18n";

export const supplierValidation = {
  supplierRef: stringRequired.label(
    translate("portal.suppliers.suppliers.form.labels.reference"),
  ),
  name: stringRequired.label(
    translate("portal.suppliers.suppliers.form.labels.name"),
  ),
  active: Yup.boolean().label(
    translate("portal.suppliers.suppliers.form.labels.active"),
  ),
  phoneNumber: phone.label(
    translate("portal.suppliers.suppliers.form.labels.phone"),
  ),
  addressLine1: stringRequired,
  addressLine2: Yup.string(),
  city: Yup.string(),
  county: Yup.string(),
  postcode: stringRequired,
  types: stringRequired.label(
    translate("portal.suppliers.suppliers.form.labels.types"),
  ),
};

export const supplierInitial = {
  supplierRef: "",
  name: "",
  active: true,
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  county: "",
  postcode: "",
  types: "{}",
};
