"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.offTheRoadPanel = void 0;
exports.offTheRoadPanel = {
    markOffTheRoad: "Mark as off the road",
    wantToMark: "Are you sure you want to mark",
    asOffTheRoad: "as off the road?",
    markedOffTheRoad: "Yes. Mark as off the road",
    cancel: "No, Cancel",
};
