import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import {
  Icon,
  Modal,
  Button,
  theme,
  displayErrorMessage,
} from "@clearabee/ui-library";
import { createCatalogueTemplate } from "../../../api";
import { Template } from "models/template";
import {
  CreateUpdateItemForm,
  ItemForValuesWithUploadedImage,
} from "components/catalogues/components/createUpdateItemForm";
import { parseFormData } from "components/catalogues/parser";
import { DefaultErrorComponent } from "@clearabee/ui-library/src/Core/ErrorBoundary/DefaultErrorComponent";

export const CreateTemplate = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const [showModal, setShowModal] = useState(false);
  const history = useHistory<{ item: Template } | undefined>();

  const blueprint = history.location.state?.item;

  const { mutate, isLoading, error, reset } = useMutation(
    (template: Template) => createCatalogueTemplate(template),
    {
      onSuccess: () => setShowModal(true),
    },
  );

  const handleFormSubmit = async (values: ItemForValuesWithUploadedImage) => {
    const data = parseFormData(values);
    mutate(data);
  };

  return (
    <>
      <Link
        to="/catalogues/templates"
        className="inline-flex items-center mb-5"
      >
        <Icon.Chevron
          className="transform rotate-180"
          size="small"
          color="brand"
        />
        {translate("common:backTo", {
          location: translate("common:routes.catalogues/templates"),
        })}
      </Link>
      <CreateUpdateItemForm
        onSubmit={handleFormSubmit}
        heading={translate("headings.newProductTemplate")}
        disableSubmit={isLoading || showModal}
        includeSkuField
        blueprint={blueprint ?? undefined}
        cancelUrl="/catalogues/templates"
      />
      {displayErrorMessage(error, (props) => (
        <DefaultErrorComponent
          {...props}
          styles={{
            marginTop: theme.spacing.small,
            marginBottom: theme.spacing.small,
            maxWidth: theme.screens.small,
            marginRight: 0,
          }}
          onClose={reset}
        />
      ))}
      {showModal && (
        <Modal
          heading={translate("success.heading")}
          width={400}
          onClose={() => setShowModal(false)}
          actions={
            <Link to="/catalogues/templates">
              <Button size="medium" color="brand" type="button">
                {translate("common:backTo", {
                  location: translate("common:routes.catalogues/templates"),
                })}
              </Button>
            </Link>
          }
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("success.message")}
          </div>
        </Modal>
      )}
    </>
  );
};
