"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.global = void 0;
exports.global = {
    cookieNotice: {
        text: "Clearabee.co.uk uses cookies to enhance your experience on our site, as outlined in our {{link}}",
        link: "cookie policy",
        button: "Got it",
    },
};
