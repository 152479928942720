import React from "react";

/**
 * Import components.
 */
import { MultiForm } from "../../common/components";
import { AddressDetails, CollectionDetails } from "./steps";

/**
 * Import context.
 */
import { ProvideCatalogue, ProvideBasket } from "../../../contexts";

/**
 * Import form state initiaal values.
 */
import { formStateInitialValues } from "./validation";

import "./styles/weeekerbside.css";

const steps = [
  {
    id: "address-details",
    component: AddressDetails,
  },
  {
    id: "collection-details",
    component: CollectionDetails,
  },
];

export const CreateWeeeKerbsideJob: React.FC = () => {
  return (
    <ProvideCatalogue catalogueId="PORTAL" postcode={null} companyCode={null}>
      <ProvideBasket>
        <MultiForm steps={steps} initialValues={formStateInitialValues} />
      </ProvideBasket>
    </ProvideCatalogue>
  );
};
