import { theme } from "../../theme";
import { StyleSheet } from "../../utils/stylesheet";

export type AllowedColorKeys = keyof Omit<
  typeof theme.colors,
  "focus" | "positive"
>;

const linkColorCombinations = {
  brand: {
    text: "base",
    hover: "dark",
  },
  accent: {
    text: "base",
    hover: "dark",
  },
  usp: {
    text: "base",
    hover: "dark",
  },
  warning: {
    text: "base",
    hover: "dark",
  },
  negative: {
    text: "base",
    hover: "dark",
  },
  greyscale: {
    text: "base",
    hover: "dark",
  },
  dark: {
    text: "base",
    hover: "dark",
  },
  light: {
    text: "base",
    hover: "base",
  },
  positive: {
    text: "base",
    hover: "base",
  },
};

export const styles = StyleSheet({
  default: {
    ...theme.fontDefaults.small,
    cursor: "pointer",
    textDecoration: "none",

    "&:focus": {
      backgroundColor: theme.colors.focus.base,
      color: theme.colors.light.base,
      transition: "300ms",
    },

    "&:hover": {
      transition: "300ms",
    },
  },
  underline: {
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "100%",
      height: "2px",
    },
  },
});

export const colorStyles = StyleSheet({
  ...Object.assign(
    {},
    ...Object.entries(linkColorCombinations).map((input) => {
      // Casting required due to limited inference on Object.entries()
      const [accessor, colors] = input as unknown as [
        AllowedColorKeys,
        Record<
          keyof typeof linkColorCombinations[AllowedColorKeys],
          keyof typeof theme.colors[AllowedColorKeys]
        >,
      ];

      return {
        [accessor]: {
          position: "relative",
          color: theme.colors[accessor][colors.text],
          "&:hover": {
            color: theme.colors[accessor][colors.hover],
          },
          "&:before": {
            backgroundColor: theme.colors[accessor].base,
          },
        },
      };
    }),
  ),
  light: {
    color: theme.colors.light.base,
    "&:hover": {
      "&:before": {
        backgroundColor: theme.colors.light.base,
      },
    },
  },
});
