import React, { useState, useRef, useEffect } from "react";
import { theme } from "../../../../theme";
import { CustomInputProps, UserStylesProps } from "../../../../utils/types";
import { styles } from "./InputQuantity.styles";

type ButtonDetailedProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type InputDetailedProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type AllowedButtonColorKeys =
  | keyof Pick<
      typeof theme.colors,
      "brand" | "accent" | "warning" | "positive" | "usp"
    >;

export type AllowedNumberColorKeys =
  | keyof Pick<typeof theme.colors, "light" | "greyscale">;

export interface InputQuantityProps
  extends CustomInputProps,
    UserStylesProps<InputDetailedProps> {
  min?: number;
  max?: number;
  disabled?: boolean;
  defaultValue?: number;
  variant?: "small" | "large";
  textColor?: AllowedNumberColorKeys;
  buttonColor?: AllowedButtonColorKeys;
}

interface ControlProps extends ButtonDetailedProps {
  children: React.ReactNode;
  variant: NonNullable<InputQuantityProps["variant"]>;
  color?: AllowedButtonColorKeys;
}

//set native input ready to accept events
const setNativeValue = (element: HTMLInputElement, quantity: number): void => {
  Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(element),
    "value",
  )?.set?.call(element, quantity);
};

// Button for increasing/decreasing quantity
const Control = ({
  onClick,
  variant,
  color = "brand",
  ...props
}: ControlProps): React.ReactElement => {
  return (
    <button
      type="button"
      onClick={onClick}
      css={[
        styles.button,
        { backgroundColor: theme.colors[color].base },
        variant === "small" ? styles.buttonSmall : styles.buttonLarge,
      ]}
      {...props}
    />
  );
};

export const InputQuantity = ({
  min = 0,
  max = 99,
  disabled = false,
  defaultValue = 0,
  textColor = "greyscale",
  buttonColor = "brand",
  variant = "large",
  styles: userStyles,
  ...props
}: InputQuantityProps): React.ReactElement => {
  const [quantity, setQuantity] = useState(defaultValue);
  const nativeInput = useRef<HTMLInputElement>(null);

  // Used to pass events to attributes such as onChange
  useEffect(() => {
    const input = nativeInput.current;

    if (!input) return;

    setNativeValue(input, quantity);

    input.dispatchEvent(new Event("change", { bubbles: true }));
  }, [quantity]);

  return (
    <div>
      <Control
        data-testid="minus"
        variant={variant}
        disabled={disabled || quantity <= min}
        onClick={() => setQuantity(quantity - 1)}
        color={buttonColor}
      >
        −
      </Control>
      <input
        data-testid="number"
        disabled
        ref={nativeInput}
        type="text"
        inputMode="numeric"
        autoComplete="off"
        css={{ display: "none" }}
        {...props}
      />
      <span
        css={[
          styles.text,
          { color: theme.colors[textColor].base },
          variant === "small" ? styles.inputSmall : styles.inputLarge,
          userStyles,
        ]}
      >
        {quantity}
      </span>
      <Control
        data-testid="plus"
        variant={variant}
        disabled={disabled || quantity >= max}
        onClick={() => setQuantity(quantity + 1)}
        color={buttonColor}
      >
        +
      </Control>
    </div>
  );
};
