import React from "react";
import { css } from "@emotion/react";
import { CustomInputProps, UserStylesProps } from "../../../../utils";
import { styles } from "./InputCheckbox.styles";
import { Text } from "../../../Text/Text";

export interface InputCheckboxProps
  extends CustomInputProps,
    UserStylesProps<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
    > {
  label?: string;
  inverted?: boolean;
  labelPosition?: "top" | "right";
}

export const InputCheckbox = React.forwardRef<
  HTMLInputElement,
  InputCheckboxProps
>(
  (
    {
      label,
      error,
      success,
      inverted,
      styles: userStyles,
      labelPosition = "right",
      ...rest
    }: InputCheckboxProps,
    ref,
  ) => {
    return (
      <Text
        as="label"
        css={css(
          styles.defaults,
          error && styles.error,
          success && styles.success,
          inverted && styles.inverted,
          userStyles,
        )}
        data-testid="checkbox-component"
      >
        <input type="checkbox" css={css(styles.input)} {...rest} ref={ref} />
        <Text
          as="span"
          css={css(
            styles.checkboxControl,
            labelPosition === "top" && {
              ":before": {
                content: `'${label}'`,
              },
            },
          )}
          className="checkbox-control"
        />
        {labelPosition !== "top" && (
          <Text as="span" fontSize="small" css={css(styles.label)}>
            {label}
          </Text>
        )}
      </Text>
    );
  },
);
