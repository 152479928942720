import React from "react";

/**
 * Import components.
 */
import { FormInput } from "../../../../core";

/**
 * Interface.
 */
interface IInput {
  name: string;
  label: string;
  placeholder?: string;
  className?: string;
}

export const Input: React.FC<IInput> = ({
  name,
  label,
  placeholder,
  className = "mb-6 w-full",
}) => {
  return (
    <div className={className}>
      <FormInput
        classes="border-gray-input"
        name={name}
        floatingLabel={false}
        label={{
          text: label,
        }}
        placeholder={placeholder}
      />
    </div>
  );
};
