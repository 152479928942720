import React, { useState, useMemo } from "react";
import { Template } from "models/template";
import { Section } from "./section";
import { Button, Icon, InputText } from "@clearabee/ui-library";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { getQueriedResults } from "helpers";
import { styles } from "./sideBarContent.styles";
import { CatalogueEditorContext } from ".";
import { useScreenWidth } from "hooks";
import { Link } from "react-router-dom";

interface TemplateTypeSort {
  categories: Template[];
  products: Template[];
}

export const SideBarContent = (): React.ReactElement => {
  const [translate] = useTranslation("catalogues");
  const { templates } = useContext(CatalogueEditorContext);
  const { isMobile } = useScreenWidth();
  const [filterTemplates, setFilterTemplates] = useState<Template[]>();
  const { categories, products } =
    (filterTemplates ?? templates).reduce<TemplateTypeSort>(
      ({ categories, products }, element) =>
        element.type === "product"
          ? { categories, products: [...products, element] }
          : { categories: [...categories, element], products },
      { categories: [], products: [] },
    ) ?? {};

  const onFilterUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTemplates(
      getQueriedResults(templates, event.target.value, [
        "title",
        "sku",
        "description",
      ]),
    );
  };

  const sectionsMemo = useMemo(
    () => (
      <>
        <Section
          items={categories}
          label={translate("sidebar.categories")}
          isOpen={!!filterTemplates}
        />
        <div className="border-t pt-2" style={styles.divider} />
        <Section
          items={products}
          label={translate("sidebar.products")}
          isOpen={!isMobile}
        />
      </>
    ),
    [templates, filterTemplates],
  );

  if (!templates.length) {
    return <Icon.Loading className="m-auto mt-20" data-testid="loading" />;
  }

  return (
    <>
      <div css={styles.container} data-testid="sidebar">
        <div css={styles.section}>{sectionsMemo}</div>
        <div className="w-full text-center">
          <Link
            to="/catalogues/templates/create"
            className="mx-auto mb-4 inline-block"
          >
            <Button size="small" as="button" color="accent">
              Create new template
            </Button>
          </Link>
        </div>
      </div>
      <div
        className="2xl:absolute bottom-0 left-0 w-full"
        css={styles.searchContainer}
      >
        <InputText
          onChange={onFilterUpdate}
          styles={styles.searchBox}
          placeholder={translate("filters.searchItems")}
        />
      </div>
    </>
  );
};
