"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getItems = void 0;
var getItems = function (itemSkus, items) {
    if (typeof itemSkus === "string")
        return items.find(function (_a) {
            var sku = _a.sku;
            return sku === itemSkus;
        });
    var found = items.filter(function (_a) {
        var sku = _a.sku;
        return itemSkus.includes(sku);
    });
    return found.length
        ? found
        : undefined;
};
exports.getItems = getItems;
