import React from "react";
import { Twemoji as Emoji } from "react-emoji-render";

/**
 * Import vectors.
 */
import { BuzzingBee } from "../../../images";

interface IErrorMessage {
  heading?: string;
  content?: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const ErrorMessage: React.FC<IErrorMessage> = ({
  heading = "Oops, an error has occurred.",
  content = "Please bear with us while we fix the issue and try again soon.",
}) => {
  return (
    <div className="h-screen pt-20 pb-64">
      <div className="bg-white rounded-lg h-full p-4">
        <div className="relative bg-gray-100 overflow-hidden rounded-lg h-full flex-col flex flex-wrap items-center justify-center">
          <BuzzingBee className="absolute top-0 left-0 -mt-26 ml-64" />
          <h1 className="text-primary text-3xl font-bold relative">
            {heading}
          </h1>
          <p>{content}</p>
          <Emoji text=":slightly_frowning_face:" className="text-6xl mt-6" />
        </div>
      </div>
    </div>
  );
};
