import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useMemo } from "react";
import apiResources from "./resources";
import { useAuthContext } from "hooks";

// This interface is just for react query to correctly identify response types
export type ReactQueryResponse<T extends (...args: never) => unknown> =
  ReturnType<T> extends PromiseLike<infer U> ? U : T;

/**
 * Our own API error definition, for passing extra data back to RQ
 */
export type ApiError = any;
// export class CustomAPIError extends Error {
//   errors: ApiError[] = [];
//   status = 0;

//   constructor(errors: ApiError[], status: number) {
//     super("APIError");
//     // We can add any additional data we want to the error here
//     this.name = "APIError";
//     this.errors = errors;
//     this.status = status;
//   }
// }

export const responseInterceptor = (response: AxiosResponse): AxiosResponse => {
  return {
    ...response,
    // All successful API requests should return a data key in the body
    data: response?.data,
  };
};

export const errorInterceptor = (error: AxiosError): Promise<ApiError> => {
  // const defaultError = [
  //   {
  //     message: {
  //       key: "generic.error.unknown",
  //       translation: "generic.error.unknown",
  //     },
  //   },
  // ];

  console.log(error.response?.data);

  return Promise.reject(
    error.response?.data,
    // new CustomAPIError(
    //   error.response?.data?.errors || defaultError,
    //   error?.response?.status || 0,
    // ),
  );
};

/**
 * Create Axios instance
 */
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_DSA_API_URL}/`,
  timeout: 10000,
});

// Mapped type to define all API resources and their returned type
export type UseInstanceType = {
  [Key in keyof typeof apiResources]: ReturnType<typeof apiResources[Key]>;
} & {
  instance: AxiosInstance;
};

export const useInstance = (): UseInstanceType => {
  const { getCurrentUserAccessToken } = useAuthContext();

  instance.defaults.headers.common["Authorization"] = useMemo(() => {
    const token = getCurrentUserAccessToken();
    return token ? `Bearer ${token}` : undefined;
  }, []);

  // Destructure the apiResources, and pass the instance into each of them
  // Also returns the instance itself incase the consumer needs access to it
  return {
    instance,
    ...Object.assign(
      {},
      ...Object.entries(apiResources).map(([name, gen]) => ({
        [name]: gen(instance),
      })),
    ),
  };
};

/**
 * Ensure error responses are always in the format of our CustomAPIError
 */
instance.interceptors.response.use(responseInterceptor, errorInterceptor);
