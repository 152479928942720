import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useQuery, useMutation } from "react-query";
import { instance } from "@clearabee/ui-sdk";
import {
  Text,
  Icon,
  Modal,
  Button,
  theme,
  Loader,
  Message,
} from "@clearabee/ui-library";
import { toasts } from "helpers/toasts";
import { RouteLink } from "components/core";
import { CreateUpdateSupplierForm } from "../components";
import { ISupplier } from "../types";

export const UpdateSupplier = (): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);

  const [translate] = useTranslation("suppliers");
  const { id } = useParams<{ id: string }>();

  const {
    data: singleSupplierData,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    `readSupplier${id}`,
    () => ({} as any),
    // instance.users.getSupplier(id, {
    //   params: { includeTypes: true },
    // }),
  );

  useEffect(() => {
    refetch();
  }, []);

  const { mutate, isLoading: isUpdatingSupplierType } = useMutation(
    (values: ISupplier) => ({} as any),
    //instance.users.patchSupplier(id, values),
    {
      onError: () => {
        toasts.error({
          message: translate("suppliers.errors.errorUpdatingSupplier"),
        });
      },
      onSuccess: () => setShowModal(true),
    },
  );

  return (
    <div className="max-w-screen-lg py-5 ml-auto mr-auto">
      <RouteLink href="/suppliers">
        <Text
          className="flex flex-row gap-1 items-center cursor-pointer"
          fontSize="small"
        >
          <Icon.Chevron size="small" styles={{ transform: "rotate(180deg)" }} />
          {translate("suppliers.buttons.backToSuppliers")}
        </Text>
      </RouteLink>

      {!isLoading && singleSupplierData ? (
        <div className="mt-4">
          <CreateUpdateSupplierForm
            title={translate("suppliers.headings.updateSupplier")}
            disableSubmitButton={isUpdatingSupplierType}
            mutateFunction={mutate}
            supplierData={singleSupplierData.data}
          />
        </div>
      ) : (
        <>
          {isError ? (
            <Message className="mt-6" color="negative">
              {translate("suppliers.errors.cannotGetSupplierInformation")}
            </Message>
          ) : (
            <Loader color="brand" />
          )}
        </>
      )}

      {showModal && (
        <Modal
          heading={translate("suppliers.headings.success")}
          width={500}
          actions={
            <>
              <RouteLink href="/suppliers">
                <Button
                  size="medium"
                  color="brand"
                  type="button"
                  className="mr-3"
                >
                  {translate("suppliers.buttons.backToSuppliers")}
                </Button>
              </RouteLink>
            </>
          }
          onClose={() => setShowModal(false)}
        >
          <div
            css={{
              padding: `${theme.spacing.small} 0`,
            }}
          >
            {translate("suppliers.messages.supplierUpdated")}
          </div>
        </Modal>
      )}
    </div>
  );
};
