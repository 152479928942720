import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";

/**
 * Import images.
 */
import { ReactComponent as Coins } from "../../../images/manage-prices.svg";

/**
 * Import helpers.
 */
import { formatToPrice } from "../../../helpers";

/**
 * Types.
 */
interface ITotalNumbers {
  orders: number;
  value: number;
  isLoading: boolean;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const TotalNumbers = ({
  orders,
  value,
  isLoading,
}: ITotalNumbers): React.ReactElement => {
  const [t] = useTranslation("leaderboard");
  /**
   * Previous values.
   */
  const previousRef = useRef([0, 0]);

  const [ordersProps, setOrders] = useSpring<{
    number: number;
  }>(() => ({
    number: 0,
  }));
  const [valueProps, setValue] = useSpring<{
    number: number;
  }>(() => ({
    number: 0,
  }));

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const { current: prevValues } = previousRef;
    if (prevValues[0] !== orders) {
      previousRef.current[0] = orders;
      setOrders({
        number: orders,
        config: {
          duration: 700,
        },
      });
    }
    if (prevValues[1] !== value) {
      previousRef.current[1] = value;
      setValue({
        number: value,
        config: {
          duration: 700,
        },
      });
    }
  }, [orders, value, isLoading, setValue, setOrders]);

  return (
    <div className="p-8 h-full rounded-xl bg-pattern shadow-filter">
      <div className="relative">
        <div className="h-24 w-32 absolute bottom-0 right-0">
          <Coins />
        </div>
        <h3 className="leading-none mt-2 text-xl text-white">
          {t("headings.totalValue")}
        </h3>
        <animated.h2 className="text-4xl text-white font-bold">
          {valueProps.number.interpolate((val) => formatToPrice(val))}
        </animated.h2>
        <div className="border-b border-gray-border mt-3 mb-6 -ml-8 mr-40" />
        <h3 className="leading-none mt-2 text-xl text-white">
          {t("headings.totalOrders")}
        </h3>
        <animated.h2 className="text-4xl text-white font-bold">
          {ordersProps.number.interpolate((val) => Math.floor(val))}
        </animated.h2>
      </div>
    </div>
  );
};
