import { FilterListOption, Text, theme } from "@clearabee/ui-library";
import React from "react";
import { useTranslation } from "react-i18next";

interface ListViewProps {
  data: FilterListOption[];
  label: string;
}

const maxResults = 10;

export const ListView = ({
  data,
  label,
}: ListViewProps): React.ReactElement | null => {
  const [translate] = useTranslation("catalogues");

  if (!data.length) {
    return null;
  }

  return (
    <div
      style={{
        marginLeft: theme.spacing.small,
        marginTop: theme.spacing.large,
      }}
    >
      <Text
        as="span"
        fontSize="small"
        style={{
          display: "block",
          fontWeight: 600,
        }}
      >
        {label}
      </Text>
      <div style={{ marginLeft: theme.spacing.xsmall }}>
        {data
          .slice(0, maxResults < data.length ? maxResults - 2 : data.length)
          .map(({ label, value }) => (
            <div key={value}>{label}</div>
          ))}
        {data.length > maxResults && (
          <div style={{ marginTop: theme.spacing.xsmall2 }}>
            {translate("blackout.andMore", {
              count: data.length - maxResults + 2,
            })}
          </div>
        )}
      </div>
    </div>
  );
};
