import * as Yup from "yup";
import { fieldRequired } from "validation/common";

export const permissionsValidation = Yup.object().shape({
  companies: Yup.array().min(1, fieldRequired).typeError(fieldRequired),
  dateRange: Yup.string().required(fieldRequired),
});

export const defaultOptionValue = {
  label: "All Companies",
  value: "all",
};

export const permissionsInitialValues = {
  companies: [defaultOptionValue],
  dateRange: "",
};
