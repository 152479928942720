import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../../../utils";
import { styles } from "./InputRadio.styles";
import { Text } from "../../../Text/Text";

export interface InputRadioProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  label: string;
  error?: string;
  success?: boolean;
  inverted?: boolean;
}

export const InputRadio = React.forwardRef<HTMLInputElement, InputRadioProps>(
  (
    {
      label,
      error,
      success,
      inverted,
      styles: userStyles,
      ...rest
    }: InputRadioProps,
    ref,
  ) => {
    return (
      <Text
        as="label"
        css={css(
          styles.defaults,
          error && styles.error,
          success && styles.success,
          inverted && styles.inverted,
          userStyles,
        )}
      >
        <input type="radio" css={css(styles.input)} {...rest} ref={ref} />
        <span css={css(styles.radioControl)} className={"radio-control"} />
        <Text as="span" fontSize="small" css={css(styles.label)}>
          {label}
        </Text>
      </Text>
    );
  },
);
