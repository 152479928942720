"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.common = void 0;
exports.common = {
    loading: "Loading",
    importantInformation: "Important Information",
    form: {
        errors: {
            validPhone: "Phone number is not valid",
            formInvalid: "Required information is missing. \nPlease complete the highlighted questions.",
        },
        camera: {
            takePhoto: "Take a Photo",
            take: "Take Photo",
            flip: "Flip Camera",
        },
        buttons: {
            setCurrentTime: "Set to Current Time",
            sign: "Click to Sign",
            confirm: "Yes, I confirm",
            yes: "Yes",
            yesUnderstand: "Yes, I understand",
            no: "No",
            submit: "Submit",
            true: "True",
            false: "False",
            loading: "Loading...",
        },
        placeholders: {
            tapToType: "Tap to type",
        },
    },
    errors: {
        genericError: "An error occurred. Please try again.",
        noWorksheets: "No worksheets can be found for this job. Please navigate back to the Dashboard, and pull down to refresh.",
    },
    navigation: {
        backToLogin: "Back to Login",
        returnToDashboard: "Return to Dashboard",
    },
    screenHeader: {
        completedJobsTitle: "{{completed}} of {{total}} jobs",
    },
    downloading: "Downloading...",
    downloadUpdate: "Download Update",
};
