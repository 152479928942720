import React, { useContext, useEffect, useState } from "react";
import {
  FilterList,
  usePagination,
  Text,
  Heading,
} from "@clearabee/ui-library";
import { useQuery } from "react-query";
import { readCompanies } from "api";
import { ErrorMessage, FormLoader } from "components/common/components";
import { paginationStyles } from "components/common/resusablePaginationStyles";
import { useTranslation } from "react-i18next";
import { ICompany } from "api/types";
import { getQueriedResults } from "helpers";
import { CatalogueEditorContext } from "./catalogueEditor";

export const CompanySelection = (): React.ReactElement => {
  const [search, setSearch] = useState("");
  const [translate] = useTranslation("catalogues");
  const [results, setResults] = useState<ICompany[]>();
  const { data, isLoading, isError } = useQuery(["readCompanies", 1000], () =>
    readCompanies("", 0, 5000),
  );
  const { PaginationComponent, paginatedData } = usePagination(
    results ?? data?.items ?? [],
    //options
    undefined,
    // paginationStyles
    paginationStyles,
  );

  const { companies, setCompanies } = useContext(CatalogueEditorContext);

  useEffect(
    () =>
      setResults(
        getQueriedResults(data?.items, search, ["name", "companyCode"]),
      ),
    [search],
  );

  if (isLoading || !data || !companies) return <FormLoader />;
  if (isError) return <ErrorMessage />;

  return (
    <>
      <FilterList
        content={
          <>
            <Heading level={5} color="brand">
              {translate("catalogueEditing.tabs.companiesHeading")}
            </Heading>
            <Text>{translate("catalogueEditing.tabs.companiesContent")}</Text>
          </>
        }
        options={paginatedData.map(({ name, companyCode }) => ({
          label: name,
          value: companyCode,
        }))}
        searchPlaceholder={translate(
          "catalogueEditing.tabs.companiesSearchPlaceholder",
        )}
        onSearch={setSearch}
        onOptionsChange={(options) => {
          setCompanies(options.map(({ value }) => value));
        }}
        initialSelectedOptions={companies.map((code) => ({
          label: code,
          value: code,
        }))}
      />
      <PaginationComponent />
    </>
  );
};
