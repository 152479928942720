import { css } from "@emotion/react";
import React, { useRef, useState, useEffect } from "react";
import { UserStylesProps } from "../../../../utils";
import { Button } from "../../../Button/Button";
import { Text } from "../../../Text/Text";
import { styles } from "./InputFile.styles";

export interface CustomFile {
  dataUrl: string;
  file: File;
  key?: string;
  removeFile?: () => void;
}
type CustomFileList = CustomFile[];

export interface InputFileProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  > {
  defaultValues?: CustomFileList;
  accept?: string;
  isLoading?: boolean;
  uploadButtonText: string;
  deleteButtonText: string;
  onFileChange?: (files: CustomFile[]) => void;
}

/**
 * Generate Base64 string for files.
 */
const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.addEventListener("load", () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};

/**
 * Generate the image data list based on the uploaded files.
 */
const generateFileList = (
  files: FileList,
  removeFile: (key: string) => void,
  target: EventTarget & HTMLInputElement,
): Promise<CustomFileList> => {
  const promiseFiles: Array<Promise<string>> = [];

  for (let i = 0; i < files.length; i += 1) {
    promiseFiles.push(getBase64(files[i]));
  }

  return Promise.all(promiseFiles).then((fileListBase64: Array<string>) => {
    const fileList: CustomFileList = fileListBase64.map((base64, index) => {
      const key = `${new Date().getTime().toString()}-${files[index].name}`;
      return {
        dataUrl: base64,
        file: files[index],
        key,
        removeFile: (): void => removeFile(key),
        target,
      };
    });
    return fileList;
  });
};

export const InputFile = ({
  name = "fileUpload",
  accept = ".jpg,.jpeg,.png",
  defaultValues,
  uploadButtonText,
  deleteButtonText,
  isLoading = false,
  onFileChange,
  onChange,
  styles: userStyles,
  ...rest
}: InputFileProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState(defaultValues || []);
  const [images, setImages] = useState(fileList);
  const [error, setError] = useState("");
  const fileListRef = useRef(fileList);

  /**
   * Update ref of file list each time it changes.
   */
  useEffect(() => {
    fileListRef.current = fileList;
  }, [fileList]);

  /**
   * On upload file, click input to open file browser.
   */
  const uploadFile = (): void => {
    if (inputRef.current !== null && !isLoading) {
      inputRef.current.click();
    }
  };

  /**
   * Remove a file on click.
   */
  const removeFile = (): void => {
    const updatedList: CustomFileList = [];

    setFileList(updatedList);
    setImages(updatedList);
    onDataChange(updatedList);
    // reset value of input
    if (inputRef.current) inputRef.current.value = "";
  };

  /**
   * Upload or remove handlers
   */
  const handleOnClick = () => {
    return images?.length ? removeFile() : uploadFile();
  };

  /**
   * Actions to run when the data changes.
   */
  const onDataChange = (list: CustomFileList): void => {
    if (onFileChange) {
      const sData: CustomFileList = list.map((item) => ({
        file: item.file,
        dataUrl: item.dataUrl,
      }));

      onFileChange(sData);
    }
  };

  /**
   * Function to run when a new file (or files) are added to the uploader.
   */
  const onDrop = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const { files } = e.target;
    const { target } = e;
    setError("");

    if (files) {
      let newFileList = await generateFileList(files, removeFile, target);

      /**
       * Check for files now allowed.
       */
      newFileList.forEach(({ file }: CustomFile) => {
        const extension = file.name.split(".").pop()?.toLowerCase();

        if (typeof extension === "string") {
          if (!accept.toLowerCase().includes(extension)) {
            newFileList = [];
            setError(
              `You have tried to upload a file type which is not allowed (allowed: ${accept})`,
            );
          }
        }
      });

      /**
       * If new files exist, attempt to process them.
       */
      if (newFileList.length > 0) {
        const updatedFileList = [newFileList[0]];
        setFileList(updatedFileList);
        setImages(updatedFileList);
        onDataChange(updatedFileList);
      }
    }
  };

  return (
    <>
      <div css={css(styles.fileWrapper, userStyles)}>
        {!images?.length && (
          <div css={css(styles.placeholder.container)}>
            {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="82"
                height="76"
                viewBox="0 0 82 76"
                css={css(styles.placeholder.image)}
              >
                <g fill="#1D1D1B" opacity=".25">
                  <path d="M21.2901099,59.697144 C15.8362738,59.697144 10.985023,56.205142 9.223427,51.0064376 L9.10428236,50.6146944 C8.68883508,49.23798 8.51479632,48.0802172 8.51479632,46.9218304 L8.51479632,23.6942144 L0.250141598,51.2821548 C-0.812804416,55.3399388 1.60939009,59.5468096 5.673412,60.6677684 L58.3522428,74.7755072 C59.0097224,74.9458032 59.667202,75.0275204 60.3147008,75.0275204 C63.7075196,75.0275204 66.8077788,72.7756216 67.6767248,69.4570344 L70.745794,59.697144 L21.2901099,59.697144 Z M30.6588812,23.926266 C34.4166199,23.926266 37.471966,20.8702962 37.471966,17.1125574 C37.471966,13.3548187 34.4166199,10.2988491 30.6588812,10.2988491 C26.9011424,10.2988491 23.8451726,13.3548187 23.8451726,17.1125574 C23.8451726,20.8702962 26.9011424,23.926266 30.6588812,23.926266 Z" />
                  <path d="M73.242844,0.0785982004 L22.1415897,0.0785982004 C17.4475351,0.0785982004 13.6249218,3.90121156 13.6249218,8.59588972 L13.6249218,46.0697272 C13.6249218,50.7644052 17.4475351,54.5870188 22.1415897,54.5870188 L73.242844,54.5870188 C77.937522,54.5870188 81.7601356,50.7644052 81.7601356,46.0697272 L81.7601356,8.59588972 C81.7601356,3.90121156 77.937522,0.0785982004 73.242844,0.0785982004 Z M22.1415897,6.8923066 L73.242844,6.8923066 C74.1835264,6.8923066 74.9464268,7.65520744 74.9464268,8.59588972 L74.9464268,32.7804068 L64.1847224,20.2227974 C63.0431784,18.8841342 61.3907464,18.1686418 59.6160508,18.1280951 C57.8513356,18.1380758 56.1957848,18.9215618 55.0648452,20.2776914 L42.4117956,35.4645948 L38.2897608,31.3525408 C35.9598878,29.022668 32.1678404,29.022668 29.8410866,31.3525408 L20.4386303,40.751878 L20.4386303,8.59588972 C20.4386303,7.65520744 21.2015312,6.8923066 22.1415897,6.8923066 Z" />
                </g>
              </svg>
            }
          </div>
        )}
        {images?.map(({ dataUrl }, index) => (
          <div css={css(styles.imageContainer)} key={index}>
            <div
              role="img"
              css={css(styles.image)}
              style={{ backgroundImage: `url(${dataUrl})` }}
              data-testid="image-preview"
            />
          </div>
        ))}

        <Button
          size="medium"
          color={images?.length ? "negative" : "warning"}
          type="button"
          onClick={handleOnClick}
          data-testid="upload-button"
          css={css(styles.button)}
        >
          <Text fontSize="xsmall">
            {images?.length ? deleteButtonText : uploadButtonText}
          </Text>
        </Button>
        <div onClick={uploadFile} css={css(styles.clickable)} />
      </div>
      <input
        name={name}
        type="file"
        accept={accept}
        ref={inputRef}
        onChange={(event) => {
          onDrop(event);
          onChange?.(event);
        }}
        style={{ display: "none" }}
        data-testid="file-input"
        {...rest}
      />
      {error && <div css={css(styles.error)}>{error}</div>}
    </>
  );
};
