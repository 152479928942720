/* eslint-disable max-len */
import React from "react";

/**
 * Import generic image type.
 */
import { TGenericImage } from "./types";

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
const uniqueIdentifier = "DottedCircle";

export const DottedCircle: React.FC<TGenericImage> = ({
  className = "dotted-circle",
  fill = "none",
  stroke = "currentColor",
  width,
  height,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 250 237"
      xmlSpace="preserve"
      enableBackground="new 0 0 250 237"
      x="0"
      y="0"
    >
      <defs>
        <path
          id={`${uniqueIdentifier}A`}
          d="M-820.2 -315.8H619.8V537.2H-820.2z"
        />
      </defs>
      <clipPath>
        <use overflow="visible" xlinkHref={`#${uniqueIdentifier}A`} />
      </clipPath>
      <circle
        cx="124.5"
        cy="117.5"
        r="100"
        fill={fill}
        stroke={stroke}
        strokeDasharray="0,14"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </svg>
  );
};
