import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { styles, AllowedColorKeys } from "./Message.styles";
import { Icons } from "@clearabee/ui-library-base";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";

type ValidTypes = "info" | "warn" | "success" | "error";
export interface MessageProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  color?: AllowedColorKeys;
  small?: boolean;
  type?: ValidTypes;
  icon?: Lowercase<keyof Icons>;
  background?: boolean;
}

type TypeMap = {
  [key in ValidTypes]: Required<Pick<MessageProps, "icon" | "color">> & {
    background: {
      background: AllowedColorKeys;
      color: AllowedColorKeys;
    };
  };
};

const typeMap: TypeMap = {
  info: {
    icon: "attention",
    color: "greyscale",
    background: {
      color: "light",
      background: "greyscale",
    },
  },
  warn: {
    icon: "attention",
    color: "warning",
    background: {
      color: "dark",
      background: "warning",
    },
  },
  success: {
    icon: "tick",
    color: "positive",
    background: {
      color: "dark",
      background: "positive",
    },
  },
  error: {
    icon: "attention",
    color: "negative",
    background: {
      color: "light",
      background: "negative",
    },
  },
};

export const Message = ({
  children,
  styles: userStyles,
  type,
  color: userColor,
  icon: userIcon,
  small,
  background,
  ...rest
}: MessageProps): React.ReactElement => {
  let color =
    !userColor && type ? typeMap[type].color : userColor || "greyscale";
  if (background && !userColor && type) {
    color = typeMap[type].background.color;
  }
  const icon = !userIcon && type ? typeMap[type].icon : userIcon || "attention";
  const size = !small ? "medium" : "small";
  const fontSize = !small ? "base" : "xsmall2";

  const IconComponent =
    Icon[(icon.charAt(0).toUpperCase() + icon.slice(1)) as keyof Icons];

  return (
    <div
      role="alert"
      css={css(
        styles.defaults,
        userStyles,
        background && styles.background,
        background &&
          type &&
          styles.background[typeMap[type].background.background],
      )}
      {...rest}
    >
      <IconComponent
        size={size}
        color={color}
        css={css(styles.icon, styles[size])}
      />
      <Text fontSize={fontSize} css={css(styles[color])}>
        {children}
      </Text>
    </div>
  );
};
