import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { FieldArray } from "formik";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  UnstyledButton,
} from "@clearabee/ui-library";
import { TNewCompany } from "../../api/types";
import { createCompany } from "../../api";
import { SpringTransition, FormButton, FormInput, Checkbox } from "../core";
import { SuccessModal } from "../common/components";
import {
  createCompany as validation,
  constantDays,
  defaultDates,
  defaultDays,
} from "./validation";
import { toasts } from "../../helpers";

export const CreateCompany: React.FC = (): JSX.Element => {
  const [t] = useTranslation("companies");
  const [loading, setLoading] = useState(false);
  const [disableContactDetailsToggle, setDisableContactDetailsToggle] =
    useState(false);

  const initial: TNewCompany = {
    // required
    name: "",
    companyCode: "",
    companyPrefix: "",
    addressLine1: "",
    addressPostcode: "",
    contactName: "",
    contactEmail: "",
    contactPhoneNumber: "",
    settings: {
      canInvoice: false,
      hideInvoices: false,
      requireOrderNumber: false,
      orderNumberLabel: "",
      orderNumberValidation: "",
      orderNumberValidationMessage: "",
      requirePhoneAndEmail: false,
      requireContactDetails: false,
      hidePhotos: false,
      hideTransferNotes: false,
      identityProvider: "",
      ssoOnly: false,
    },
    active: false,
    // optional
    bookingLimitDates: [],
    bookingLimitDays: [],
    addressLine2: "",
    addressCity: "",
    addressCounty: "",
    sicCode: "",
    notes: "",
  };

  const { mutate, isSuccess } = useMutation(
    (newCompany: TNewCompany) => createCompany(newCompany),
    {
      onMutate: () => {
        // set loading true
        setLoading(true);
      },
      onError: () => {
        toasts.error({
          message: "Company could not be created. Try again",
        });
      },
      onSettled: () => {
        // set loading false
        setLoading(false);
      },
    },
  );

  return (
    <>
      <Box className="max-w-screen-lg py-5 ml-auto mr-auto">
        <SpringTransition>
          <Box className="flex justify-start mb-3">
            <Link
              to="/companies"
              className="btn-tiny mt-2 sm:mt-0 btn btn-primary hover:bg-primary hover:text-white transition ease-in duration-100"
            >
              &larr; Back
            </Link>
          </Box>
          <Heading level={4} className="text-lg text-primary font-bold">
            {t("form.headings.addCompany")}
          </Heading>
          <Form
            initialValues={initial}
            validationSchema={validation}
            onSubmit={(values: TNewCompany) => {
              mutate({
                ...values,
                bookingLimitDays: values.bookingLimitDays?.map((item) => ({
                  ...item,
                  day: Number(item.day),
                })),
              });
            }}
          >
            {({ errors, values, setFieldValue }) => {
              useEffect(() => {
                setDisableContactDetailsToggle(false);
                if (values.settings.requirePhoneAndEmail) {
                  setDisableContactDetailsToggle(true);
                  setFieldValue("settings.requireContactDetails", true);
                }
              }, [values.settings.requirePhoneAndEmail]);

              return (
                <Box className="bg-white p-6 pb-4 lg:pt-8 lg:pb-6 lg:px-8 shadow-filter rounded-xl mb-8 mt-3">
                  <Box className="flex flex-col md:flex-row justify-between md:space-x-8">
                    <Box className="w-full md:w-1/2">
                      <Heading
                        level={5}
                        className="text-base font-semibold text-primary"
                      >
                        {t("form.headings.company")}
                      </Heading>
                      <Box className="mt-6">
                        <FormInput
                          name="name"
                          floatingLabel={false}
                          label={{
                            text: `${t("form.labels.companyName")}*`,
                          }}
                        />
                      </Box>
                      <Box className="mt-6 flex flex-col sm:flex-row justify-between sm:space-x-8">
                        <Box className="w-full sm:w-1/2">
                          <FormInput
                            name="companyCode"
                            floatingLabel={false}
                            label={{
                              text: `${t("form.labels.companyCode")}*`,
                            }}
                          />
                        </Box>
                        <Box className="w-full mt-6 sm:mt-0 sm:w-1/2">
                          <FormInput
                            name="companyPrefix"
                            floatingLabel={false}
                            label={{
                              text: `${t("form.labels.companyPrefix")}*`,
                            }}
                          />
                        </Box>
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="addressLine1"
                          floatingLabel={false}
                          label={{
                            text: `${t("form.labels.addressLine1")}*`,
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="addressLine2"
                          floatingLabel={false}
                          label={{
                            text: t("form.labels.addressLine2"),
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="addressCity"
                          floatingLabel={false}
                          label={{
                            text: t("form.labels.townCity"),
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="addressCounty"
                          floatingLabel={false}
                          label={{
                            text: t("form.labels.county"),
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="addressPostcode"
                          floatingLabel={false}
                          label={{
                            text: `${t("form.labels.postcode")}*`,
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="sicCode"
                          floatingLabel={false}
                          label={{
                            text: t("form.labels.sicCode"),
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="notes"
                          floatingLabel={false}
                          label={{ text: t("form.labels.notes") }}
                        />
                      </Box>
                      <Box className="border-t border-gray-400 mt-8 pb-6" />
                      <FieldArray
                        name="bookingLimitDays"
                        render={({ remove, push }) => (
                          <>
                            <Box className="flex mt-3">
                              <Heading level={5} color="brand">
                                {t("form.headings.bookingLimitDays")}
                              </Heading>
                            </Box>
                            {values.bookingLimitDays?.map((item, index) => (
                              <Box
                                key={`bookingLimitDays-${index}-${item.day}`}
                                className="flex items-center"
                              >
                                <Box className="w-full h-24 mr-3">
                                  <Field
                                    label={t("filters.form.label.day")}
                                    name={`bookingLimitDays[${index}].day`}
                                  >
                                    {({ field }) => (
                                      <Input.Select
                                        {...field}
                                        placeholder={t(
                                          "filters.form.placeholder.selectDay",
                                        )}
                                        options={constantDays.filter(
                                          ({ value }) =>
                                            value === item.day.toString() ||
                                            !values.bookingLimitDays?.some(
                                              (item) =>
                                                item.day.toString() === value,
                                            ),
                                        )}
                                        defaultValue={String(item.day)}
                                      />
                                    )}
                                  </Field>
                                </Box>
                                <Box className="w-2/5 h-4 -mt-20 mr-3">
                                  <Field
                                    label={t("filters.form.label.limit")}
                                    name={`bookingLimitDays[${index}].limit`}
                                  >
                                    {({ field }) => (
                                      <Input.Text
                                        {...field}
                                        type="number"
                                        placeholder={t(
                                          "filters.form.label.limit",
                                        )}
                                      />
                                    )}
                                  </Field>
                                </Box>
                                <UnstyledButton
                                  className="mt-5"
                                  onClick={() => remove(index)}
                                >
                                  <Icon.Trash size="medium" color="negative" />
                                </UnstyledButton>
                              </Box>
                            ))}
                            <Box className="flex justify-start mt-5 pr-8">
                              <Button
                                size="small"
                                type="button"
                                disabled={
                                  values.bookingLimitDays &&
                                  values.bookingLimitDays?.length > 6
                                }
                                onClick={() => {
                                  push(defaultDays);
                                }}
                              >
                                {t("steps.productOptions.table.headings.add")}
                              </Button>
                            </Box>
                          </>
                        )}
                      />
                      {/* Divider */}
                      <Box className="border-t border-gray-400 mt-8 pb-6" />
                      <FieldArray
                        name="bookingLimitDates"
                        render={({ push, remove }) => (
                          <>
                            <Box className="flex mt-3">
                              <Heading level={5} color="brand">
                                {t("form.headings.bookingLimitDates")}
                              </Heading>
                            </Box>
                            {values.bookingLimitDates?.map((item, index) => (
                              <Box
                                key={`${index}-${item.date}`}
                                className="flex items-center"
                              >
                                <Box className="w-full h-24 mr-3">
                                  <Field
                                    label={t("filters.form.label.date")}
                                    name={`bookingLimitDates[${index}].date`}
                                  >
                                    {({ field }) => (
                                      <Input.Date
                                        {...field}
                                        dateFormat="YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                        collapsable
                                        disabledDays={(day) =>
                                          values.bookingLimitDates?.some(
                                            (item) =>
                                              dayjs(item.date).format(
                                                "YYYY-MM-DD",
                                              ) ===
                                              dayjs(day).format("YYYY-MM-DD"),
                                          )
                                        }
                                        initialValue={dayjs(item.date).format(
                                          "YYYY-MM-DD",
                                        )}
                                      />
                                    )}
                                  </Field>
                                </Box>
                                <Box className="w-2/5 h-4 -mt-20 mr-3">
                                  <Field
                                    label={t("filters.form.label.limit")}
                                    name={`bookingLimitDates[${index}].limit`}
                                  >
                                    {({ field }) => (
                                      <Input.Text
                                        {...field}
                                        type="number"
                                        defaultValue={item.limit}
                                      />
                                    )}
                                  </Field>
                                </Box>
                                <UnstyledButton
                                  className="mt-5"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <Icon.Trash size="medium" color="negative" />
                                </UnstyledButton>
                              </Box>
                            ))}
                            <Box className="flex justify-start mt-5 pr-8">
                              <Button
                                size="small"
                                type="button"
                                onClick={() => {
                                  push(defaultDates);
                                }}
                              >
                                {t("steps.productOptions.table.headings.add")}
                              </Button>
                            </Box>
                          </>
                        )}
                      />
                    </Box>
                    <Box className="w-full md:w-1/2">
                      <Heading
                        level={5}
                        className="text-base font-semibold text-primary mt-6 md:mt-0"
                      >
                        {t("form.headings.contact")}
                      </Heading>
                      <Box className="mt-6">
                        <FormInput
                          name="contactName"
                          floatingLabel={false}
                          label={{
                            text: `${t("form.labels.name")}*`,
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="contactEmail"
                          floatingLabel={false}
                          label={{
                            text: `${t("form.labels.emailAddress")}*`,
                          }}
                        />
                      </Box>
                      <Box className="mt-6">
                        <FormInput
                          name="contactPhoneNumber"
                          floatingLabel={false}
                          label={{
                            text: `${t("form.labels.phone")}*`,
                          }}
                        />
                      </Box>
                      <Box className="mt-6 p-8 bg-gray-200 rounded-lg">
                        <Heading
                          level={5}
                          className="text-base font-semibold text-primary"
                        >
                          {t("form.headings.settings")}
                        </Heading>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.canInvoice"
                            label="Can Invoice?"
                            onClick={() => {
                              setFieldValue(
                                "settings.canInvoice",
                                !values.settings.canInvoice,
                              );
                            }}
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="active"
                            label="Company Active?"
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.hideInvoices"
                            label={t("form.labels.hideInvoices")}
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.requireOrderNumber"
                            label="Require Order Number?"
                          />
                        </Box>
                        <Box className="mt-6">
                          <FormInput
                            name="settings.orderNumberLabel"
                            floatingLabel={false}
                            label={{
                              text: t("form.labels.redefineOrderNumberLabel"),
                            }}
                          />
                        </Box>
                        <Box className="mt-6">
                          <FormInput
                            name="settings.orderNumberValidation"
                            floatingLabel={false}
                            label={{
                              text: t("form.labels.orderNumberValidation"),
                            }}
                            placeholder={t(
                              "form.placeholders.orderNumberValidation",
                            )}
                          />
                        </Box>
                        <Box className="mt-6">
                          <FormInput
                            name="settings.orderNumberValidationMessage"
                            floatingLabel={false}
                            label={{
                              text: t(
                                "form.labels.orderNumberValidationMessage",
                              ),
                            }}
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            disabled={disableContactDetailsToggle}
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label: disableContactDetailsToggle
                                ? "inline-block order-2 ml-3 text-sm font-semibold text-gray-500 sm:mb-0"
                                : "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.requireContactDetails"
                            label="Require Contact Details?"
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.requirePhoneAndEmail"
                            label="Require BOTH Email And Phone?"
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.hideTransferNotes"
                            label={t("form.labels.hideTransferNotes")}
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.hidePhotos"
                            label={t("form.labels.hidePhotos")}
                          />
                        </Box>
                        <Box className="mt-6">
                          <FormInput
                            name="settings.identityProvider"
                            floatingLabel={false}
                            label={{
                              text: t("form.labels.identityProvider"),
                            }}
                          />
                        </Box>
                        <Box className="mt-6">
                          <Checkbox
                            classes={{
                              wrap: "flex mb-3",
                              labelWrap: "flex items-center",
                              label:
                                "inline-block order-2 ml-3 text-sm font-semibold sm:mb-0",
                            }}
                            name="settings.ssoOnly"
                            label={t("form.labels.ssoOnly")}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="flex justify-end border-t border-gray-200 pt-5 mt-5">
                    <FormButton
                      className="w-32"
                      text={t("form.buttons.labels.save")}
                      variant="secondary"
                      name="nextStep"
                      type="submit"
                      disabled={
                        Object.keys(errors).length > 0 || loading || isSuccess
                      }
                    />
                  </Box>
                </Box>
              );
            }}
          </Form>
        </SpringTransition>
      </Box>
      <SuccessModal
        title={t("modal.headings.createCompanySuccess")}
        buttonOne={{
          label: t("modal.buttons.labels.backToCompanies"),
          to: "/companies",
        }}
        visible={isSuccess}
      />
    </>
  );
};
