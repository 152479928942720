import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  Box,
  Button,
  Field,
  Form,
  Heading,
  Icon,
  Input,
  Message,
  Panel,
  displayErrorMessage,
  Link,
} from "@clearabee/ui-library";
import { ApiRequestData, instance } from "@clearabee/ui-sdk";
import { RouteLink } from "components/core";
import { initialValues, validationSchema } from "./validation";

type PostDriverUserRequestBody = ApiRequestData<
  typeof instance.users.postDriverUser
>;

export const DriverCreate = (): React.ReactElement => {
  const [translate] = useTranslation("drivers");

  /**
   * creating new driver account
   */
  const {
    mutate,
    isLoading: isLoadingCreateNewDriver,
    reset: resetCreateNewDriver,
    isError: isErrorCreateNewDriver,
    isSuccess,
  } = useMutation(
    "createNewDriver",
    async (values: PostDriverUserRequestBody) =>
      await instance.users.postDriverUser(values),
  );

  /**
   * handle submit form
   */
  const handleSubmit = (values: typeof initialValues) => {
    mutate({
      ...values,
      active: true,
      userPoolId: process.env.REACT_APP_DRIVERS_USER_POOL_ID || "",
      clientId: process.env.REACT_APP_DRIVERS_USER_POOL_WEB_CLIENT_ID || "",
    });
  };

  return (
    <Box className="max-w-screen-lg mx-auto py-10 relative">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={handleSubmit}
      >
        {({ resetForm }) => {
          return (
            <Panel
              className="flex flex-col gap-x-6 justify-between"
              shadow={false}
            >
              <Box className="flex justify-between">
                <Heading fontSize="large" color="brand">
                  {translate("create.headings.create")}
                </Heading>
                <Box className="flex flex-row gap-x-3 items-center">
                  {/* Loading */}
                  {isLoadingCreateNewDriver && (
                    <Icon.Loading size="large" color="brand" />
                  )}
                  <Button
                    size="small"
                    color="negative"
                    type="reset"
                    onClick={() => {
                      resetForm();
                      resetCreateNewDriver();
                    }}
                  >
                    {translate("create.buttons.reset")}
                  </Button>
                  <Button size="small" type="submit">
                    {translate("create.buttons.submit")}
                  </Button>
                </Box>
              </Box>
              <Box className="w-full gap-x-3 border-t border-grey-200 mt-4">
                <Box className="w-full">
                  <Field
                    name="email"
                    className="flex-1"
                    label={translate("create.form.headings.driverEmail")}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={translate(
                          "create.form.placeholders.driverEmail",
                        )}
                      />
                    )}
                  </Field>
                </Box>
                <Box className="w-full flex flex-row justify-between gap-x-3">
                  <Box className=" w-1/2">
                    <Field
                      name="firstName"
                      className="flex-1"
                      label={translate("create.form.headings.driverFirstName")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "create.form.placeholders.driverFirstName",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="w-1/2">
                    <Field
                      name="lastName"
                      className="flex-1 w-1/3"
                      label={translate("create.form.headings.driverLastName")}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "create.form.placeholders.driverLastName",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
                <Box className="w-full flex flex-row justify-between gap-x-3">
                  <Box className="w-1/2">
                    <Field
                      name="phoneNumber"
                      className="flex-1"
                      label={translate(
                        "create.form.headings.driverPhoneNumber",
                      )}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "create.form.placeholders.driverPhoneNumber",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box className="w-1/2">
                    <Field
                      name="temporaryPassword"
                      className="flex-1"
                      label={translate(
                        "create.form.headings.driverTemporaryPassword",
                      )}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={translate(
                            "create.form.placeholders.driverTemporaryPassword",
                          )}
                        />
                      )}
                    </Field>
                  </Box>
                </Box>
              </Box>
              <Box className="flex justify-center mt-3">
                <RouteLink href="/drivers/allocate">
                  <Link underline>{translate("create.links.allocate")}</Link>
                </RouteLink>
              </Box>
              <Box>
                {/* Display Errors if cannot create new driver account*/}
                {isErrorCreateNewDriver && (
                  <Box className="w-full mt-5">
                    {displayErrorMessage(
                      translate("create.errors.createDriver"),
                      ({ children }) => (
                        <Box className="flex items-center justify-start w-full mb-4 overflow-scroll">
                          <Message type="error" background>
                            {children}
                          </Message>
                        </Box>
                      ),
                    )}
                  </Box>
                )}
                {/* Display success message*/}
                {isSuccess && (
                  <Box className="w-full mt-2">
                    <Box className="flex items-center justify-start w-full mb-2 overflow-scroll">
                      <Message type="success" background color="light">
                        {translate("create.success.successCreatedNewDriver")}
                      </Message>
                    </Box>
                  </Box>
                )}
              </Box>
            </Panel>
          );
        }}
      </Form>
    </Box>
  );
};
