import * as Yup from "yup";
import { translate } from "@clearabee/i18n";
import {
  stringRequired,
  optionField,
  requiredOption,
  validPostcode,
  phone as phoneField,
  email as emailField,
  phoneRegExp,
  emailRegExp,
  name,
  postcodeRegExp,
} from "validation/common";
import { ISelectOption } from "../../core/select/simple";
import { ICreateJobFormState } from "./createJob";

const emailRequiredWithoutSpecialCharacters = emailField.matches(
  emailRegExp,
  translate("portal.common.form.errors.validEmail"),
);
/**
 * When an email or phone number is provided, the other becomes optional.
 * If one of these is left blank, then the other becomes required.
 * One of the two must be entered, if the noContactDetails is set to false.
 */
const email = Yup.string()
  .email(translate("portal.common.form.errors.validEmail"))
  .notRequired()
  .when("phoneNumber", {
    is: (phone: any) => !phone || phone.length === 0,
    then: emailRequiredWithoutSpecialCharacters,
  });
const phone = Yup.string()
  .matches(phoneRegExp, translate("portal.common.form.errors.validPhone"))
  .notRequired()
  .when("email", {
    is: (email: any) => !email || email.length === 0,
    then: phoneField,
  });

const blankValues = {
  collectionDate: "",
  collectionAddress: {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
    lat: null,
    lng: null,
  },
  meta: {
    poNumber: "",
    zendeskTicketId: "",
    wasteType: "",
  },
  customer: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  noContactDetails: false,
  wasteDescription: "",
  accessInformation: "",
  addressLookupPostcode: "",
  addressChoices: "",
  landType: "",
  images: [],
  basketToken: "",
  basketOrderRef: "",
  timeslot: "",
  timeslotPreference: "",
  timeslotCharge: 0,
  sicCode: "",
  useAdhoc: false,
  bigchangeProps: {
    cust_OriginalRequestedDate: "",
    cust_RiskAddressIsCollectionAddress: false,
    cust_RiskPostcode: "",
  },
};

export const getInitialValues = ({
  company,
}: {
  company: ISelectOption;
}): ICreateJobFormState => ({
  ...blankValues,
  company,
});

export const filterByCompany = Yup.object().shape({
  statusId: Yup.string().nullable(),
  companyId: stringRequired.nullable(),
});

export const filterByRef = Yup.object().shape({
  ref: stringRequired,
  postcode: stringRequired,
});

export const collectionDetailsInitialValues = {
  customer: blankValues.customer,
  meta: blankValues.meta,
  wasteDescription: blankValues.wasteDescription,
  accessInformation: blankValues.accessInformation,
  timeslot: "",
  timeslotPreference: "",
  noContactDetails: blankValues.noContactDetails,
};

interface IGetCollectionDetailsSchemaOptions {
  isSkip: boolean;
  hasTimeslots: boolean;
  orderNumberRequired?: boolean;
  requirePhoneAndEmail?: boolean;
  orderNumberValidation: string;
  orderNumberValidationMessage: string;
}

export const getCollectionDetailsSchema = ({
  isSkip,
  hasTimeslots,
  orderNumberRequired,
  requirePhoneAndEmail,
  orderNumberValidation,
  orderNumberValidationMessage,
}: IGetCollectionDetailsSchemaOptions) => {
  const notAllowedPONumberValues = ["'", '"', "&"];
  const regex = new RegExp(orderNumberValidation);

  return Yup.object().shape({
    noContactDetails: Yup.boolean(),
    customer: Yup.object({
      firstName: name,
      lastName: name,
      email: Yup.string(),
      phoneNumber: Yup.string(),
    }).when("noContactDetails", {
      is: false,
      then: Yup.object().shape(
        {
          firstName: stringRequired,
          lastName: stringRequired,
          email: requirePhoneAndEmail
            ? emailRequiredWithoutSpecialCharacters
            : email,
          phoneNumber: requirePhoneAndEmail ? phoneField : phone,
        },
        // An array of the possible optional fields must be added, to prevent cyclic dependency errors
        [["email", "phoneNumber"]],
      ),
    }),
    meta: Yup.object().shape({
      poNumber: orderNumberRequired
        ? Yup.string()
            .max(50, translate("portal.jobs.form.validation.fiftyMax"))
            .required(translate("portal.jobs.form.validation.requiredField"))
            .test(
              "no-spaces",
              translate("portal.jobs.form.validation.invalidChar"),
              async (val) =>
                val &&
                !notAllowedPONumberValues.some((item) => val.includes(item)),
            )
            .matches(regex, orderNumberValidationMessage ?? "Invalid field")
        : Yup.string()
            .max(50, translate("portal.jobs.form.validation.fiftyMax"))
            .test(
              "no-spaces",
              translate("portal.jobs.form.validation.invalidChar"),
              async (val) => {
                return val
                  ? !notAllowedPONumberValues.some((item) => val.includes(item))
                  : true;
              },
            )
            .matches(regex, orderNumberValidationMessage ?? "Invalid field"),
      zendeskTicketId: Yup.string(),
      wasteType: Yup.string(),
    }),
    wasteDescription: Yup.string(),
    accessInformation: Yup.string(),
    landType: isSkip ? stringRequired : Yup.string(),
    timeslotPreference: hasTimeslots ? stringRequired : Yup.string(),
    timeslot: hasTimeslots
      ? Yup.string().when("timeslotPreference", {
          is: "Choose a 2 hour timeslot",
          then: stringRequired,
        })
      : Yup.string(),
    sicCode: Yup.string(),
    bigchangeProps: Yup.object().shape(
      {
        cust_RiskAddressIsCollectionAddress: Yup.boolean(),
        cust_RiskPostcode: Yup.string()
          .matches(postcodeRegExp, validPostcode)
          .when("cust_RiskAddressIsCollectionAddress", {
            is: true,
            then: stringRequired,
            otherwise: Yup.string(),
          }),
      },
      [["cust_RiskAddressIsCollectionAddress", "cust_RiskPostcode"]],
    ),
  });
};

export interface IDateAddressFormValues {
  company: ICreateJobFormState["company"] | null;
  collectionDate: ICreateJobFormState["collectionDate"];
  collectionAddress: ICreateJobFormState["collectionAddress"];
  addressLookupPostcode: ICreateJobFormState["addressLookupPostcode"];
  addressChoices: ICreateJobFormState["addressChoices"];
  useAdhoc: boolean;
}

export const getDateAddressInitialValues = (
  formState: ICreateJobFormState,
  canSelectCompany: boolean,
  isAdhocChecked: boolean,
): IDateAddressFormValues => ({
  company:
    !formState.collectionDate &&
    !formState.collectionAddress.postcode &&
    canSelectCompany
      ? null
      : formState.company,
  collectionDate: formState.collectionDate,
  collectionAddress: formState.collectionAddress,
  addressLookupPostcode: formState.collectionAddress.postcode,
  addressChoices: formState.addressChoices,
  useAdhoc: isAdhocChecked,
});

export const getDateAddressSchema = (canSelectCompany: boolean) => {
  const manualAddressValidation = Yup.object().shape({
    line1: stringRequired,
    line2: Yup.string(),
    city: stringRequired,
    county: Yup.string(),
    postcode: stringRequired.min(3, validPostcode).max(11, validPostcode),
  });

  return Yup.object().shape({
    collectionDate: stringRequired,
    company: optionField.when("useAdhoc", {
      is: true,
      then: optionField.notRequired(),
      otherwise: canSelectCompany ? requiredOption : optionField,
    }),
    addressLookupPostcode: Yup.string().when("collectionAddress", {
      is: (values) => manualAddressValidation.isValid(values),
      then: Yup.string(),
      otherwise: stringRequired,
    }),
    useAdhoc: Yup.boolean(),
    collectionAddress: manualAddressValidation,
  });
};
