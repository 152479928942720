import React from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { FieldArray } from "formik";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { instance } from "@clearabee/ui-sdk";
import { toasts } from "../../../helpers/toasts";
import {
  Button,
  Form,
  Heading,
  Input,
  Panel,
  Field,
  Text,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { initialValues, validationSchema } from "./validation";

export const UpdateRole = (): React.ReactElement => {
  const [translate] = useTranslation("roles");

  const { id } = useParams<{ id: string }>();

  // Get Role
  const { data: roleData, isLoading: getRoleQueryIsLoading } = useQuery(
    "roles",
    async () => (await axios.get(`/roles/${id}`)).data,
    {
      onError: () =>
        toasts.error({
          message: translate("errors.errorMessage"),
        }),
    },
  );

  // Get Permissions
  const {
    refetch: refetchGetPermissions,
    isLoading: getPermissionsQueryIsLoading,
    data: permissionsData,
  } = useQuery(
    "permissions",
    async () => {
      return (await instance.users.getPermissions({ params: { limit: 1000 } }))
        .data;
    },
    {
      onError: () =>
        toasts.error({
          message: translate("errors.errorMessage"),
        }),
    },
  );

  // Update Role
  const { mutate, isLoading: updateRoleIsLoading } = useMutation(
    "updateRolePermission",
    async (permissions: any) =>
      await instance.users.putRolePermissions(id, permissions),
    {
      onError: () => {
        toasts.error({
          message: translate("errors.errorMessage"),
        });
      },
      onSuccess: () => {
        refetchGetPermissions(),
          toasts.success({
            message: translate("success.updateSuccess"),
          });
      },
    },
  );

  const getInitialValues = () => {
    if (getRoleQueryIsLoading && !roleData) {
      return initialValues;
    }

    return {
      ...initialValues,
      permissions: roleData?.permissions.map((item: any) => String(item.id)),
    };
  };

  const handleFormSubmit = (values: typeof initialValues) => {
    mutate(values.permissions.map((item) => Number(item)));
  };

  if ((getRoleQueryIsLoading && !roleData) || getPermissionsQueryIsLoading) {
    return (
      <Icon.Loading
        color="brand"
        styles={{ margin: `${theme.spacing.large} auto` }}
      />
    );
  }

  return (
    <>
      <Panel
        styles={{
          paddingTop: theme.spacing.medium,
          paddingBottom: theme.spacing.medium,
        }}
      >
        <Heading fontSize="large" color="brand">
          {translate("headings.updateHeading")}
        </Heading>
        <div className="border-t border-grey-200 my-4 flex flex-col" />

        <Form
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isValid, values }) => {
            return (
              <>
                <div className="flex flex-col gap-x-6 items-center">
                  <div className="flex flex-col md:flex-row md:justify-start w-full">
                    <div className="mb-6 md:mb-0 md:mr-12">
                      <Heading
                        level={5}
                        fontSize="base"
                        styles={{ fontWeight: 700 }}
                        color="brand"
                      >
                        {translate("form.labels.name")}
                      </Heading>
                      <Text>{roleData?.name || ""}</Text>
                    </div>
                    <div className="mb-6 md:mb-0 md:mr-12">
                      <Heading
                        level={5}
                        fontSize="base"
                        styles={{ fontWeight: 700 }}
                        color="brand"
                      >
                        {translate("form.labels.type")}
                      </Heading>
                      <Text>{roleData?.type || ""}</Text>
                    </div>
                    <div className="mb-6 md:mb-0 md:mr-12">
                      <Heading
                        level={5}
                        fontSize="base"
                        styles={{ fontWeight: 700 }}
                        color="brand"
                      >
                        {translate("form.labels.createdOn")}
                      </Heading>
                      <Text>
                        {dayjs(roleData?.createdOn).format(
                          "MMMM D, YYYY h:mm A",
                        ) || ""}
                      </Text>
                    </div>
                  </div>
                  <div className="w-full md:flex-1 mb-6">
                    <div className="border-t border-grey-200 my-4 flex flex-col" />
                    <Heading
                      level={5}
                      fontSize="base"
                      styles={{ fontWeight: 700 }}
                      color="brand"
                    >
                      {translate("form.labels.updatePermissions")}
                    </Heading>
                    <FieldArray
                      name="permissions"
                      render={() => (
                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className="flex flex-row flex-wrap gap-2 md:gap-10"
                        >
                          {permissionsData?.items
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((item, index) => (
                              <div
                                className="flex w-full md:w-1/3 xl:w-1/5"
                                key={index}
                              >
                                <Field name="permissions">
                                  {({ field }) => {
                                    return (
                                      <Input.Checkbox
                                        {...field}
                                        label={item.name}
                                        value={item.id}
                                        checked={values.permissions.includes(
                                          String(item.id),
                                        )}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            ))}
                        </div>
                      )}
                    />
                  </div>
                  <div>
                    <Button
                      color="accent"
                      type="submit"
                      className="flex justify-center gap-2 mt-6"
                      size="small"
                      disabled={updateRoleIsLoading || !isValid}
                    >
                      {translate(
                        updateRoleIsLoading
                          ? "buttons.loading"
                          : "buttons.updateRole",
                      )}
                      {updateRoleIsLoading && (
                        <Icon.Loading size="small" color="brand.base" />
                      )}
                    </Button>
                  </div>
                </div>
              </>
            );
          }}
        </Form>
      </Panel>
    </>
  );
};
