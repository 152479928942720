"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicleDetails = void 0;
exports.vehicleDetails = {
    currentJob: "Current Job:",
    currentLocation: "Current Location:",
    driverDetails: "Driver Details",
    schedule: "Schedule",
    viewJourney: "View Journey",
    viewRoute: "View Route",
    nearestVehicles: "Nearest Vehicles",
    streetView: "Street View",
    addressIsNotAvailable: "Address is not available",
    noCurrentJob: "No current job",
};
