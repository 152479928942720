import React from "react";
import {
  Panel,
  Heading,
  ColorKeyVariants,
  getColorFromVariantKey,
  Box,
} from "@clearabee/ui-library";
import { RouteLink } from "components/core";

interface LinkPanelWithImageProps {
  backgroundColor?: ColorKeyVariants;
  titleColor?: ColorKeyVariants;
  title: string;
  imageComponent?: React.ReactElement;
  path?: string;
  className?: string;
  isExternal?: boolean;
}

export const LinkPanelWithImage = ({
  backgroundColor = "brand.darker",
  titleColor = "light",
  title = "",
  path = "",
  imageComponent,
  className = "",
  isExternal = false,
}: LinkPanelWithImageProps): React.ReactElement => {
  const ContentPanel = React.forwardRef<HTMLDivElement>(
    (props, ref): React.ReactElement => {
      return (
        <Panel
          shadow={false}
          className="flex flex-row justify-between gap-6 h-full w-full items-center cursor-pointer"
          ref={ref}
          styles={{ background: getColorFromVariantKey(backgroundColor) }}
          {...props}
        >
          <Heading
            level={3}
            fontSize="xlarge"
            styles={{ color: getColorFromVariantKey(titleColor) }}
          >
            {title}
          </Heading>
          <Box
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {imageComponent}
          </Box>
        </Panel>
      );
    },
  );

  return (
    <Box
      className={`${className} ut-transition transform hover:opacity-80`}
      styles={{
        "&:hover": {
          transform: "scale(0.99)",
        },
      }}
    >
      {isExternal ? (
        <a target="_blank" rel="noopener noreferrer" href={path}>
          <ContentPanel />
        </a>
      ) : (
        <RouteLink href={path}>
          <ContentPanel />
        </RouteLink>
      )}
    </Box>
  );
};
