import * as Yup from "yup";
import { lettersAndColonOnlyRegExp, fieldRequired } from "validation/common";

export const permissionsValidation = Yup.object().shape({
  name: Yup.string().required(fieldRequired).matches(lettersAndColonOnlyRegExp),
});

export const permissionsInitialValues = {
  name: "",
};
