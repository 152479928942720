import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { useInstance } from "api/dsa";
import {
  Table,
  Input,
  Panel,
  Heading,
  Form,
  Button,
  theme,
  Icon,
} from "@clearabee/ui-library";
import { Vehicle } from "@clearabee/api";
import { VehicleRow } from "./components";
import { initialValues, validationSchema } from "./validation";

export const ApproveVehicles = (): React.ReactElement => {
  const api = useInstance();
  const [translate] = useTranslation("users");
  const [vehicles, setVehicles] = useState<Vehicle[] | []>([]);

  const {
    data: result,
    refetch: refetchVehicles,
    isLoading: isLoadingVehicles,
    isError,
  } = useQuery("vehiclesList", () => api.vehicles.list("withCompanies=true"), {
    onSuccess: ({ data }) => setVehicles(data),
  });

  const { mutate, isLoading, variables } = useMutation(
    ({
      id,
      data,
    }: {
      id: string;
      data: Parameters<typeof api.vehicles.update>[1];
    }) => api.vehicles.update(id, data),
    {
      onSuccess: () => refetchVehicles(),
    },
  );

  const updateStatus = (id: string, status: string) => {
    mutate({
      id,
      data: {
        status,
      },
    });
  };

  const subcontractors = Array.from(
    new Set(result?.data?.flatMap((a) => a.user?.company)),
  ).filter(Boolean);

  const handleSubmit = ({ status, subcontractor }: any) => {
    if (status && status !== "all" && !subcontractor)
      return setVehicles(
        result?.data?.filter((a) => a.status === status) ?? [],
      );

    if ((!status || status === "all") && subcontractor)
      return setVehicles(
        result?.data?.filter((a) => a.user?.company === subcontractor) ?? [],
      );

    if (subcontractor && status)
      return setVehicles(
        result?.data?.filter(
          (a) => a.user?.company === subcontractor && a.status === status,
        ) ?? [],
      );

    return setVehicles(result?.data ?? []);
  };

  return (
    <>
      <Panel shadow={false} className="mb-10 mt-10">
        <div className="flex flex-wrap flex-col-reverse sm:flex-row items-center">
          <Heading
            level={5}
            color="brand"
            styles={{ marginBottom: theme.spacing.small }}
          >
            {translate("filters.approveVehiclesHeading")}
          </Heading>
        </div>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ Field, isValid, resetForm, values }) => {
            const hasValues = values.status || values.subcontractor;

            return (
              <div className="flex flex-wrap sm:flex-no-wrap gap-x-6 border-t border-grey-200 pt-5 mt-4">
                <Field
                  styles={{ flex: 2, margin: 0 }}
                  label="Status"
                  name="status"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      isSearchable
                      options={[
                        { label: "All", value: "all" },
                        { label: "Approved", value: "approved" },
                        { label: "Pending", value: "pending" },
                      ]}
                    />
                  )}
                </Field>
                <Field
                  label="Subcontractor"
                  styles={{ flex: 2, margin: 0 }}
                  name="subcontractor"
                >
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      isSearchable
                      options={subcontractors.flatMap((a) =>
                        Object.assign({ label: a, value: a }),
                      )}
                    />
                  )}
                </Field>
                <div className="flex flex-1 gap-x-6 items-center mt-4 justify-between">
                  <Button
                    type="button"
                    size="medium"
                    variant={hasValues ? "fill" : "outline"}
                    color="negative"
                    disabled={!hasValues}
                    onClick={() => {
                      setVehicles(result?.data ?? []), resetForm();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    disabled={!isValid || !hasValues}
                    size="medium"
                    color="accent"
                    type="submit"
                  >
                    {translate("subcontractors.buttons.search")}
                  </Button>
                </div>
              </div>
            );
          }}
        </Form>
      </Panel>
      {isLoadingVehicles && (
        <Icon.Loading color="brand" styles={{ margin: "0 auto" }} />
      )}
      {!isLoadingVehicles && (!vehicles.length || isError) && (
        <Heading styles={{ textAlign: "center" }} color="negative" level={5}>
          No data
        </Heading>
      )}
      {!isLoadingVehicles && !!vehicles.length && (
        <Table
          styles={{
            tableLayout: "fixed",
          }}
        >
          <Table.Header
            headings={[
              "Name",
              "Registration",
              "Type",
              "Subcontractor",
              "Images",
              "Status",
              "Actions",
            ]}
          />

          <Table.Body>
            {vehicles.map((vehicle) => {
              return (
                <VehicleRow
                  id={variables?.id}
                  key={vehicle._id.toString()}
                  updateStatus={updateStatus}
                  refetchVehicles={refetchVehicles}
                  vehicle={vehicle}
                  isLoading={isLoading}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
