import { theme } from "../../../theme";
import { StyleSheet } from "../../../utils";

export const styles = StyleSheet({
  field: {
    display: "block",
    padding: 0,
    margin: `${theme.spacing.small} 0`,
    border: 0,
  },
  label: {
    ...theme.fontDefaults.small,
    display: "block",
    fontWeight: 600,
    marginBottom: theme.spacing.xsmall2,
  },
  message: {
    marginTop: theme.spacing.xsmall2,
  },
});
