import React from "react";

export const LoadingFilters: React.FC = () => {
  return (
    <div className="flex flex-col flex-wrap -mx-2">
      <div className="w-full px-2">
        <div className="flex-1 bg-white shadow-xl rounded-lg px-4 pt-12 pb-8 flex flex-col relative">
          <div className="animate-pulse flex flex-col items-center">
            <div className="w-full py-5 space-y-2">
              <div className="space-y-3 px-6">
                <div className="flex">
                  <div className="h-4 bg-purple-500 rounded-full w-4"></div>
                  <div className="h-4 bg-purple-400 ml-3 rounded w-2/3"></div>
                </div>
                <div className="flex">
                  <div className="h-4 bg-purple-500 rounded-full w-4"></div>
                  <div className="h-4 bg-purple-400 ml-3 rounded w-2/3"></div>
                </div>
                <div className="flex">
                  <div className="h-4 bg-purple-500 rounded-full w-4"></div>
                  <div className="h-4 bg-purple-400 ml-3 rounded w-2/3"></div>
                </div>
                <div className="flex">
                  <div className="h-4 bg-purple-500 rounded-full w-4"></div>
                  <div className="h-4 bg-purple-400 ml-3 rounded w-2/3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
