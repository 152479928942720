import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

/**
 * Import types.
 */
import {
  ITable,
  IMobileTable,
  ITableRows,
  TTableRow,
  ITableColumns,
  ITableColumn,
} from "./types";

/**
 * Calculate column classes.
 */
const getColumnClasses = (column: ITableColumn, heading = false) => {
  return cx(
    heading
      ? "tbl-header whitespace-no-wrap text-xs px-3 py-2 sm:py-3"
      : "relative text-sm px-3 py-2 sm:py-3",
    {
      "text-left":
        typeof column.textPosition === "undefined" ||
        column?.textPosition === "left",
      "text-center": column?.textPosition === "center",
      "text-right": column?.textPosition === "right",
      truncate: column?.truncate,
    },
  );
};

/**
 * Columns generator.
 */
const Headings = ({ columns, index }: ITableColumns) => {
  return (
    <tr className="tbl-row">
      {columns.map((column: ITableColumn) => {
        const columnClasses = getColumnClasses(column, true);

        return (
          <th
            key={`col-${index}-${column.name}`}
            className={columnClasses}
            style={
              typeof column.width !== "undefined" ? { width: column.width } : {}
            }
          >
            {column.name}
          </th>
        );
      })}
    </tr>
  );
};

/**
 * Row value.
 */
const RowValue = ({ value, flex = "start" }: any) => {
  const valueType = typeof value;

  if (valueType === "function") {
    return value();
  } else if (
    valueType === "string" ||
    valueType === "number" ||
    (valueType === "object" && !Array.isArray(value))
  ) {
    return value || "N/A";
  } else if (valueType === "boolean") {
    return value.toString();
  } else if (Array.isArray(value)) {
    const arrayLength = value.length;
    let width = "w-full";

    if (arrayLength === 2) {
      width = "w-1/2";
    } else if (arrayLength === 3) {
      width = "w-1/3";
    } else if (arrayLength === 4) {
      width = "w-1/4";
    } else {
      width = "w-auto";
    }

    return (
      <div
        className={
          flex === "start"
            ? "flex flex-row justify-start items-center"
            : "flex flex-row"
        }
      >
        {value.map((val: any) => {
          return (
            <div key={val} className={`tbl-button ${width} lg:w-auto mr-2`}>
              {val}
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

/**
 * Rows generator.
 */
const Rows = ({
  rows,
  columns,
  hideHeadings,
  headingClasses,
  alternate,
  hover,
  noResultsMessage,
}: ITableRows) => {
  const tableHeadings = [];

  for (let i = 0; i < rows.length; i += 1) {
    tableHeadings.push(
      <Headings columns={columns} index={i} key={`heading-${i}`} />,
    );
  }

  return rows.length > 0 ? (
    <>
      {hideHeadings ? null : (
        <thead className={headingClasses}>
          {tableHeadings.map((header: any) => header)}
        </thead>
      )}
      <tbody className="tbl-body">
        {rows.map((row: TTableRow, index: number) => {
          const rowClasses = cx("tbl-row", {
            [`${alternate?.classes}`]: alternate?.enable && index % 2 !== 0,
            [`${hover?.classes}`]: hover?.enable,
          });

          let dataIndex = 0;

          return (
            <tr key={`row-${index}`} className={rowClasses}>
              {Object.entries(row).map((data: any) => {
                const [key, value] = data;
                if (key === "link") {
                  return false;
                }

                const column = columns[dataIndex];
                const columnClasses = getColumnClasses(column);

                dataIndex += 1;

                return (
                  <td key={`row-data-${dataIndex}`} className={columnClasses}>
                    {row.hasOwnProperty("link") && key !== "actions" ? (
                      <Link
                        to={`${row.link}`}
                        className="tbl-link inline-block"
                      >
                        <RowValue value={value} />
                      </Link>
                    ) : (
                      <>
                        <RowValue value={value} />
                      </>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  ) : (
    <>
      {hideHeadings ? null : (
        <thead>
          <th className="w-full">
            <table className="tbl">
              <thead className={headingClasses}>
                <Headings columns={columns} index={0} key="heading-0" />
              </thead>
            </table>
          </th>
        </thead>
      )}
      <tbody className="tbl-body">
        <tr key="row-0">
          <td className="text-center pt-6 pb-2">{noResultsMessage}</td>
        </tr>
      </tbody>
    </>
  );
};

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const Table = ({
  tableClasses = "",
  columns = [],
  rows = [],
  hideHeadings = false,
  headingClasses = "bg-gray-300 sm:bg-transparent",
  alternate = {
    enable: false,
  },
  hover = {
    enable: false,
  },
  noResultsMessage = "No results found",
}: ITable): React.ReactElement => {
  /**
   * Set default alternating row classes.
   */
  if (alternate.enable && typeof alternate.classes === "undefined") {
    // eslint-disable-next-line
    alternate.classes = "";
  }

  /**
   * Set default hover row classes.
   */
  if (hover.enable && typeof hover.classes === "undefined") {
    // eslint-disable-next-line
    hover.classes = "sm:hover:bg-gray-300 sm:cursor-pointer";
  }

  return (
    <table className={`tbl ${tableClasses}`}>
      <Rows
        rows={rows}
        columns={columns}
        hideHeadings={hideHeadings}
        headingClasses={headingClasses}
        alternate={alternate}
        hover={hover}
        noResultsMessage={noResultsMessage}
      />
    </table>
  );
};

export const MobileTable = ({
  columns = [],
  rows = [],
  noResultsMessage = "No results found",
  noBox = false,
}: IMobileTable): React.ReactElement => {
  /**
   * No results.
   */
  if (rows.length === 0) {
    return (
      <div className="text-center px-4 py-6 text-sm font-semibold">
        {noResultsMessage}
      </div>
    );
  }

  return (
    <div
      className={
        noBox ? undefined : "bg-white p-6 pb-4 shadow-filter rounded-xl"
      }
    >
      {rows.map((row: any, rowIndex: number) => {
        let ourIndex = 0;
        let link = "";

        Object.entries(row).forEach((rw: any) => {
          const [key, value] = rw;

          if (key === "link") {
            link = value;
          }
        });

        return (
          <div
            key={`m-row-${rowIndex}`}
            className="mobile-table-result py-5 border-b"
          >
            {Object.entries(row).map((data: any) => {
              const column = columns[ourIndex];
              const hasName = typeof column !== "undefined";
              const [key, value] = data;

              if (key === "link") {
                return null;
              }

              ourIndex += 1;

              const ColWithName = () => (
                <div
                  key={`m-col-${key}`}
                  className="flex flex-row py-1 justify-between items-center"
                >
                  <div className="font-semibold text-xs sm:text-sm">
                    {column}
                  </div>
                  <div className="text-xs sm:text-sm">
                    <RowValue value={value} />
                  </div>
                </div>
              );

              return hasName && link !== "" ? (
                <Link key={`m-col-${key}`} to={`${link}`}>
                  <ColWithName />
                </Link>
              ) : hasName && link === "" ? (
                <React.Fragment key={`m-col-${key}`}>
                  <ColWithName />
                </React.Fragment>
              ) : (
                <div key={`m-col-${key}`} className="mt-3">
                  <RowValue value={value} flex="justify" />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
