"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapView = void 0;
exports.mapView = {
    closestVehicles: "closest vehicles",
    aVehicle: "a vehicle",
    vehicles: "Vehicles",
    errors: {
        noVehiclesFound: "No vehicles found",
        noJobsFound: "No jobs found",
        noRouteFound: "No route found",
        noVehicleJourneyFound: "No journey found",
        noFilterResults: "No results found for this filter combination",
        unknownJobType: "Unknown job type",
        unknownPostcode: "Unknown postcode",
        unknownJobNumber: "Unknown job number",
    },
};
