import React, { useState } from "react";
import cx from "classnames";

/**
 * Import components.
 */
import { Product } from ".";
import { Trail, Transition } from "../../../core";

/**
 * Import images.
 */
import { SingleArrow } from "../../../../images";

/**
 * Import types.
 */
import { Item } from "../../../../models/item";

/**
 * Product group interface.
 */
interface IProductGroup {
  group: IGroup;
  limit?: number;
}

type IGroup = Item & {
  children: Item[];
  img: string;
};

export const ProductGroup: React.FC<IProductGroup> = ({ group, limit }) => {
  /**
   * State.
   */
  const [showAll, setShowAll] = useState(false);

  /**
   * Set array of visible and hidden products based on limit prop.
   */
  const visibleProductRows: any = [];
  const hiddenProductRows: any = [];
  if (group.children) {
    group.children.forEach((product, index) => {
      if (limit && limit < index + 1) {
        hiddenProductRows.push(<Product {...product} />);
      } else {
        visibleProductRows.push(<Product {...product} />);
      }
    });
  }

  return (
    <div className="w-full md:px-10">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xs font-bold w-20">{group.title}</h3>
        <div className="flex justify-center items-center w-22 h-22 rounded-full">
          <img src={group.img} alt={group.title} />
        </div>
      </div>
      <Trail
        trailConfig={{
          from: {
            opacity: 0,
            xyz: [0, 10, 0],
          },
        }}
        components={visibleProductRows}
      />
      <Transition transition="slideInUp" toggle={showAll}>
        <Trail
          trailConfig={{
            from: {
              opacity: 0,
              xyz: [0, 10, 0],
            },
          }}
          toggle={!showAll}
          components={hiddenProductRows}
        />
      </Transition>
      {hiddenProductRows.length > 0 && (
        <button
          type="button"
          className="block m-auto mt-4 text-primary text-xs cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          Show more
          <SingleArrow
            width={18}
            height={14}
            className={cx("inline-block transform ut-transition ml-1", {
              "rotate-90": !showAll,
              "-rotate-90": showAll,
            })}
          />
        </button>
      )}
    </div>
  );
};
