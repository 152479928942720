import React, { useState, useEffect } from "react";
import {
  useFormikContext,
  FormikProps,
  FormikFormProps,
  FormikValues,
} from "formik";
import { useTranslation } from "react-i18next";

/**
 * Import hooks.
 */
import { useDebounce } from "../../../hooks";

/**
 * Import core components.
 */
import { FormInput } from "..";

/**
 * Import address dropdown component
 */
import { AddressDropdown } from ".";

/**
 * Set interface for component.
 */
interface IAddressLookup {
  postcodeField?: {
    containerClasses?: string;
    fieldClasses?: string;
    fieldName?: string;
  };
  addressDropdown?: {
    containerClasses?: string;
    fieldClasses?: string;
    disabledOnInit?: boolean;
    fieldName?: string;
  };
  updateAddress: (value: unknown) => void;
  disabled?: boolean;
  hideLabels?: boolean;
  menuPlacement?: "top" | "bottom";
}

/**
 * Set default props for each field.
 */
const postcodeDefaults = {
  containerClasses: "",
  fieldClasses: "text-white",
  fieldName: "addressLookupPostcode",
};

const addressDefaults = {
  containerClasses: "",
  fieldClasses: "larger-dropdown drop-left",
  disabledOnInit: true,
  fieldName: "addressChoices",
};

/**
 * Address lookup component.
 */
export const AddressLookup = ({
  postcodeField = {
    ...postcodeDefaults,
  },
  addressDropdown = {
    ...addressDefaults,
  },
  updateAddress,
  disabled = false,
  hideLabels = false,
  menuPlacement = "top",
}: IAddressLookup) => {
  const [t] = useTranslation("common");

  /**
   * Get Formik context.
   */
  const { handleBlur, setFieldValue }: FormikProps<FormikFormProps> =
    useFormikContext();

  const { errors, values }: FormikProps<FormikValues> = useFormikContext();

  const postcodeInput = postcodeField.fieldName || postcodeDefaults.fieldName;
  const addressList = addressDropdown.fieldName || addressDefaults.fieldName;
  const hasError = errors[postcodeInput];

  /**
   * Postcode saved in the state.
   */
  const [postcode, setPostcode] = useState("");

  /**
   * Use custom debounce hook to check when user has stopped typing.
   */
  const debouncedPostcode = useDebounce(values[postcodeInput], 500);

  /**
   * When postcode changes, update state and reset addressChoices
   */
  useEffect(() => {
    if (debouncedPostcode.length > 0 && !hasError) {
      setPostcode(debouncedPostcode);
    }
  }, [debouncedPostcode, setFieldValue, hasError]);

  return (
    <>
      <div className={postcodeField.containerClasses}>
        <FormInput
          name={postcodeInput}
          placeholder={t("form.placeholders.postcode")}
          classes={postcodeField.fieldClasses}
          floatingLabel={false}
          label={
            !hideLabels
              ? {
                  text: t("form.labels.postcode"),
                }
              : undefined
          }
          disabled={disabled}
          onBlur={handleBlur}
          onChange={(e: any) => {
            setFieldValue(postcodeInput, e.target.value);
          }}
        />
      </div>
      <div className={addressDropdown.containerClasses}>
        <AddressDropdown
          addressDropdown={{
            fieldName: addressList,
          }}
          postcode={postcode}
          updateAddress={updateAddress}
          disabled={disabled}
          hideLabel={hideLabels}
          menuPlacement={menuPlacement}
        />
      </div>
    </>
  );
};
