import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { toasts } from "../../../helpers/toasts";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Button,
  Heading,
  Panel,
  Table,
  theme,
  Link,
  Icon,
} from "@clearabee/ui-library";

export const Roles = (): React.ReactElement => {
  const [translate] = useTranslation("roles");

  const {
    data,
    isLoading: queryIsLoading,
    isFetching,
  } = useQuery(
    "roles",
    async () => {
      return await axios.get(`/roles`);
    },
    {
      onError: () =>
        toasts.error({
          message: translate("errors.errorMessage"),
        }),
      refetchOnMount: true,
    },
  );

  return (
    <>
      <Panel
        styles={{
          marginTop: theme.spacing.large,
        }}
      >
        <div className="flex flex-col md:flex-row justify-between">
          <Heading fontSize="large" color="brand">
            {translate("headings.roleHeading")}
          </Heading>
          <div className="flex flex-col md:flex-row justify-start items-center mt-4 md:mt-0">
            <Link href="/roles/create">
              <Button size="medium">{translate("buttons.createRole")}</Button>
            </Link>
          </div>
        </div>
      </Panel>
      {queryIsLoading && (
        <Icon.Loading styles={{ margin: `${theme.spacing.xlarge} auto` }} />
      )}
      {!!data?.data && !isFetching && (
        <Table className="mt-10" styles={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
          </colgroup>
          <Table.Header
            fontSize="small"
            headings={[
              translate("form.labels.name"),
              translate("form.labels.type"),
              translate("form.labels.createdOn"),
              translate("form.labels.editRole"),
            ]}
          />
          <Table.Body>
            {data?.data.items.map((item: any) => (
              <Table.Row key={`table-row-${item.name}`}>
                <Table.Cell.Text className="truncate">
                  {item.name}
                </Table.Cell.Text>
                <Table.Cell.Text className="truncate">
                  {item.type}
                </Table.Cell.Text>
                <Table.Cell.Text className="truncate">
                  {dayjs(item.createdOn).format("MMMM D, YYYY h:mm A")}
                </Table.Cell.Text>
                <Table.Cell className="truncate">
                  <Link href={`/roles/update/${item.id}`}>
                    <Button size="small">{translate("buttons.edit")}</Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
