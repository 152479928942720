import React, { useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { instance } from "@clearabee/ui-sdk";
import { Button, Icon, Text, Heading, Box, Panel } from "@clearabee/ui-library";
import { getPendingTimestamp, sortPendingJobsByAudit } from "helpers";
import { Timer } from "./components";
import { styles } from "./pendingJobs.styles";

export const PendingJobs = (): React.ReactElement => {
  const history = useHistory();
  const [translate] = useTranslation("jobs");

  const getPendingJobs = async () => {
    const { data } = await instance.jobs.getJobs({
      params: {
        status: "pending",
        join: "[audits]",
      },
    });

    // Sorting here to avoid flickering issue on refetch. (i.e. Instead of sorting inside 'select' function of react-query.)
    return sortPendingJobsByAudit(data.items);
  };

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery("getPendingJobs", {
    queryFn: getPendingJobs,
    refetchInterval: 5000, // refetch the query every 5 seconds
  });

  useLayoutEffect(() => {
    if (isLoading) return;

    // Required for when redirected back after job approval, or will show
    // job that has already been approved, as the query refetches every 5 seconds.
    refetch();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {isLoading && <Icon.Loading color="brand" />}

      {!isLoading && Boolean(!jobs?.length) && (
        <Text fontSize="xlarge" styles={styles.noJobsText}>
          {translate("pendingJobs.noPendingJobs")}
        </Text>
      )}

      {jobs && Boolean(jobs.length) && (
        <div className="max-w-screen-lg py-5 ml-auto mr-auto">
          {jobs.map((job) => {
            const pendingTimestamp = getPendingTimestamp(job);

            return (
              <Panel
                key={`table-row-${job.id}`}
                className="justify-between flex items-center mb-2"
                shadow={false}
                styles={{
                  padding: "12px",
                }}
              >
                <Box className="w-24">
                  <Heading level={3} fontSize="xsmall" className="pb-1">
                    {translate("table.headings.jobReference")}
                  </Heading>
                  <Text fontSize="xsmall">{job.ref}</Text>
                </Box>
                <Box className="w-24">
                  <Heading level={3} fontSize="xsmall" className="pb-1">
                    {translate("table.headings.contact")}
                  </Heading>
                  <Text fontSize="xsmall">{`${job.contactFirstName} ${job.contactLastName}`}</Text>
                </Box>
                <Box className="w-24">
                  <Heading level={3} fontSize="xsmall" className="pb-1">
                    {translate("table.headings.postcode")}
                  </Heading>
                  <Text fontSize="xsmall">{job.addressPostcode}</Text>
                </Box>
                <Box className="w-28">
                  {!!pendingTimestamp && (
                    <>
                      <Heading level={3} fontSize="xsmall" className="pb-1">
                        {translate("table.headings.autoApprovesIn")}
                      </Heading>
                      <Timer
                        pendingTimestamp={pendingTimestamp}
                        fontSize="xsmall"
                      />
                    </>
                  )}
                </Box>
                <Button
                  size="xsmall"
                  onClick={() =>
                    history.push({
                      pathname: `/jobs/pending/approve/${job.id}`,
                    })
                  }
                >
                  {translate("common:form.buttons.view")}
                </Button>
              </Panel>
            );
          })}
        </div>
      )}
    </div>
  );
};
