import { AxiosInstance, AxiosResponse } from "axios";
import { Job, CountResponse } from "@clearabee/api";

export type JobsInstance = {
  list: (query?: string) => Promise<AxiosResponse<Job[]>>;
  get: (id: string) => Promise<AxiosResponse<Job>>;
  add: (job: Omit<Job, "_id">) => Promise<AxiosResponse<Job>>;
  update: (id: string, job: Partial<Job>) => Promise<AxiosResponse<Job>>;
  count: () => Promise<AxiosResponse<CountResponse>>;
};

export const jobs = (instance: AxiosInstance): JobsInstance => ({
  list: (query = "") => instance.get(`jobs?${query}`),
  get: (id) => instance.get(`jobs/${id}`),
  add: (job) => instance.post("jobs", job),
  update: (id, job) => instance.patch(`jobs/${id}`, job),
  count: () => instance.get("jobs/count"),
});
