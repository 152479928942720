import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

/**
 * Import types.
 */
import { IDataRow } from "../types";

/**
 * Types.
 */
interface ICellTemplate {
  heading: string;
  subheading?: string;
  image?: string;
  data: IDataRow[];
  loading?: boolean;
  backgroundSize?: string;
  bgSpread?: string;
}

/**
 * Avoid using default exports for components, we prefer named exports.
 * - Why? Allows for multiple exports, and subsequently multiple imports elsewhere
 * - Default exports can be used where the component needs to be imported with a different name
 * - More information: http://bit.ly/named-vs-default-export
 */
export const CellTemplate = ({
  heading,
  subheading,
  image,
  data,
  loading,
  backgroundSize = "h-24 w-28",
  bgSpread = "bg-contain",
}: ICellTemplate): React.ReactElement => {
  const [t] = useTranslation("leaderboard");

  /**
   * Get ordinal suffix of number.
   */
  const toOrdinalSuffix = (num: number) => {
    const digits = [num % 10, num % 100];
    const ordinals = ["st", "nd", "rd", "th"];
    const oPattern = [1, 2, 3, 4];
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];

    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? num + ordinals[digits[0] - 1]
      : num + ordinals[3];
  };

  return (
    <div className="p-8 rounded-xl bg-white shadow-filter">
      <div className="-my-4 relative">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2 className="text-2xl font-bold text-primary truncate">
              {heading}
            </h2>
            {loading ? (
              <span className="text-xl font-bold ml-0 render-loading-text" />
            ) : (
              <h3 className="text-xl font-bold">{subheading}</h3>
            )}
          </div>
          <div
            className={cx(
              "bg-no-repeat bg-contain bg-right",
              bgSpread,
              backgroundSize,
            )}
            style={{ backgroundImage: image ? `url(${image})` : undefined }}
          />
        </div>
        <div className="border-b border-gray-border my-4" />
        <div className="overflow-scroll flex flex-wrap">
          {data.length ? (
            data.map((row, key) => (
              <div
                className={cx(
                  "flex w-full",
                  key === 0
                    ? "text-lg text-tertiary font-bold mb-4"
                    : "text-md mb-2",
                )}
                key={key}
              >
                <span className="inline-block font-bold mr-4">
                  {toOrdinalSuffix(key + 1)}
                </span>
                <span className="truncate capitalize mr-3">{row.title}</span>
                <span className="ml-auto inline-block">{row.value}</span>
              </div>
            ))
          ) : (
            <p className="font-semibold text-lg mb-2">{t("noData")}</p>
          )}
        </div>
      </div>
    </div>
  );
};
